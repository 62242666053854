import React, { Fragment, useEffect, useState } from 'react';
import {Button, ButtonGroup, Card, CardBody, Col, Container, Row} from "reactstrap";
import { connect } from 'react-redux';
import UserStoreDirectoryService
    from "../../../../../../Service/DashboardServices/UserProfileServices/UserStoreDirectoryService";
import UserDirectoryStoresService
    from "../../../../../../Service/DashboardServices/UserProfileServices/UserDirectoryStoresService";
import UserDirectoryStoreFirstView
    from "../../../../../../Service/DashboardServices/UserProfileServices/UserDirectoryStoreFirstView";
import UsersDirectoryTable from "./Components/UsersDirectoryTable";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import StoresDirectoryTable from "./Components/StoresDirectoryTable";
import UpdateCreditBalanceService
    from "../../../../../../Service/DashboardServices/UserProfileServices/UpdateCreditBalanceService";
import UpdateProductLimitService
    from "../../../../../../Service/DashboardServices/UserProfileServices/UpdateProductLimitService";
import RequestAmazonStoresService
    from "../../../../../../Service/DashboardServices/UserProfileServices/RequestAmazonStoresService";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';  
import ProductsDataTable from "./Components/ProductsDataTable";


function UserStoreDirectoryPage(props) {

    useEffect(() => {
        props.dispatch(UserStoreDirectoryService(props.token));
        //props.dispatch(UserDirectoryStoreFirstView(props.token));
    }, [])

    const handleStoreAsinView = async (email) => {
        let response = await UserDirectoryStoresService(props.token, email);
        
    }

    const handleCreditBalanceUpdate = async (email, newCreditBalance) => {
        let response = await UpdateCreditBalanceService(props.token, email, newCreditBalance);
        if (response) {
            props.dispatch(UserStoreDirectoryService(props.token));
        }
    }
    const handleProductLimits = async (email, newProductLimit) => {
        let response = await UpdateProductLimitService(props.token, email, newProductLimit);        
    }

    const handleRequestStores = async (email) => {
        console.log('in requestStores') 
        let response = await RequestAmazonStoresService(props.token, email);        
    }

    const [view, setView] = useState('Users');
    const ExportUsersCSV = (csvData, fileName) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        console.log('Download Called')
        const exportToCSV = (csvData, fileName) => {
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData);
                
            const wb = { Sheets: {'UsersData':ws }, SheetNames: ['UsersData'] };                
                 
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, 'SimpliworksUsers' + fileExtension);   
                           
            };

        exportToCSV(csvData, fileName);
            
        }  

const ExportStoresCSV = (csvData, fileName) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        console.log('Download Called')
        const exportToCSV = (csvData, fileName) => {
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData);
                            
            const wb = { Sheets: {'StoresData':ws }, SheetNames: ['StoresData'] };                
                 
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, 'SimpliworksStores' + fileExtension);   
                           
            };

        exportToCSV(csvData, fileName);
            
        } 		


    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="9" className="mbg-3">
                        <h4>{view} Directory</h4>
                    </Col>
                    <Col md="3">
                        <ButtonGroup size="sm">
                            <Button
                                className="btn-icon btn-icon-only"
                                color="secondary"
                                size="sm"
                                onClick={() => setView("Users")}
                            >
                                {/*<i*/}
                                {/*    className="lnr-layers btn-icon-wrapper"*/}
                                {/*    style={{fontSize: '20px'}}*/}
                                {/*>*/}
                                {/*</i>*/}
                                Users
                            </Button>
                            <Button
                                className="btn-icon btn-icon-only"
                                color="secondary"
                                size="sm"
                                onClick={() => setView("Stores")}
                            >
                                {/*<i*/}
                                {/*    className="lnr-list btn-icon-wrapper"*/}
                                {/*    style={{fontSize: '20px'}}*/}
                                {/*>*/}
                                {/*</i>*/}
                                Stores
                            </Button>
                            <Button
                                className="btn-icon btn-icon-only"
                                color="secondary"
                                size="sm"
                                onClick={() => setView("Products")}
                            >
                                {/*<i*/}
                                {/*    className="lnr-list btn-icon-wrapper"*/}
                                {/*    style={{fontSize: '20px'}}*/}
                                {/*>*/}
                                {/*</i>*/}
                                Products
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className="mbg-3">
                        <CustomLoadingOverlay
                            active={props.status === 'loading'}
                            text=""
                        >
                            {view === "Users" ?
                 
                 <Button
                     className="mb-3"
                     color="brand-blue-dark"
                     onClick={() => ExportUsersCSV(props.usersDirectory,'test')}  
                     
                                     
                 >
                     Download
                 </Button>
                 :
                 <Button
                     className="mb-3"
                     color="brand-blue-dark"
                     onClick={() => ExportStoresCSV(props.storesDirectory,'test')}  
                     
                                     
                 >
                     Download
                 </Button>
                 
                 }
                            <Card>
                                <CardBody>
                                    
                                    {view === "Users" ?
                                        <UsersDirectoryTable data={props.usersDirectory} updateProductLimit={handleProductLimits} requestStores={handleRequestStores} StoresAsinView={handleStoreAsinView} />
            //     }
                                        :
                                     view === "Stores" ?
                                      
                                         <StoresDirectoryTable allStoresData={props.storesDirectory} managedStoreData={props.managedStoreFirstDirectory} unmanagedStoreData={props.unmanagedStoreFirstDirectory}/>
            // }
            //                         
                                         :                               
                                        <ProductsDataTable data={props.allProductsDirectory} />
                                    }

                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    usersDirectory: state.profile.usersDirectory,
    storesDirectory: state.profile.storesDirectory,
    managedStoreFirstDirectory: state.profile.managedStoreFirstDirectory,
    unmanagedStoreFirstDirectory: state.profile.unmanagedStoreFirstDirectory,
    allProductsDirectory: state.profile.allProductsDirectory,
    status: state.profile.status,
})

export default connect(mapStateToProps)(UserStoreDirectoryPage);