import React, { Fragment, useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CardTitle,
    Label,
  } from "reactstrap";
import { connect } from "react-redux";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import PowerbiEmbed from "../../../Components/PowerBI/PowerbiEmbed";
function PrimeReportPageUi(props) {

 
  return(
    <>
        <PowerbiEmbed
        tbl_name={props.tbl_name}
        col_name={props.col_name}
        responseURL={props.responseURL}
        profileId={props.profileId}
      />
    </>
  )
}
const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      newUser: state.loggedUser.newUser,
      needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
      user: state.LWA.user,
      isAuth: state.LWA.isAuth,
      enableMobileMenu: state.theme.enableMobileMenu,
      enableClosedSidebar: state.theme.enableClosedSidebar,
      storeOverview: state.accountOverview.store.storeOverview,
    };
  };
  
  export default connect(mapStateToProps)(PrimeReportPageUi);