import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import actionType from '../../../../Redux/Actions/ActionTypes';
import {LoadAmazonDomains} from "../../../../Redux/Actions/Actions";

function FetchAmazonDomainsService(token) {
    
    return function(dispatch) {

        dispatch({ type: actionType.LOAD_AMAZON_DOMAINS_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/GetAllAmazonDomains`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadAmazonDomains(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_AMAZON_DOMAINS_FAILED });

        });
    }
}

export default  FetchAmazonDomainsService;