import React, { Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, CardHeader } from "reactstrap";
import { BroadcastChannel } from "broadcast-channel";

import regions from "../../../../../../../Utils/RegionAuthorization";
import AuthButtonSPAPI from "./AuthButtonSPAPI";


function SPAPICards(props) {

    // const regionButtons = () => {
    //     return regions.map(region => (
    //         <Col md="3">
    //             <Card className="align-items-center">
    //                 <CardHeader>{region.name}</CardHeader>
    //                 <CardBody>
    //                     {/*<CardTitle>{region.name}</CardTitle>*/}
    //                     <AuthButtonSPAPI pathname={region.pathname} region={region.name}/>
    //                 </CardBody>
    //             </Card>
    //         </Col>
    //     ));
    // };

    return regions.map(region => (
        <Col md="6">
            <Card className="align-items-center">
                <CardHeader>{region.name}</CardHeader>
                <CardBody>
                    {/*<CardTitle>{region.name}</CardTitle>*/}
                    <AuthButtonSPAPI pathname={region.pathname} region={region.name}/>
                </CardBody>
            </Card>
        </Col>
    ));
}

export default SPAPICards;