import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function UpdateCampaignStageService(token, campaignID, campaignStage, isPhrase) {
    return axios.post(`${CURRENT_API_URL}/campaign/changeSimpliworksStatus`, {
        campaignID: campaignID,
        simpliworksStage: campaignStage,
        isPhraseCampaign: isPhrase,
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Campaign Stage successfully updated!");
        return true;
    }).catch(() => {
        notify.NotifyError("There was an error while updating campaign stage, please try again.");
        return false;
    })
}

export default UpdateCampaignStageService;
