import actionType from '../Actions/ActionTypes';
import urls from '../../Utils/URLs'


const initialState = {
    projects: [],
    loading: false,
    storeDetails: [],
    menuItems: [],
    currentStore: null,
    initailStore: null,
    apiStatus: '',
    apiSuccessMsg: '',
    apiErrorMsg: ''
};


const ProjectReducer = (state = initialState, action) => {
    switch (action.type) {


        case actionType.VIEW_PROJECTS:
            return { ...state }

        case actionType.GET_STORE_DETAILS:
            let storeData = action.payload.filter(d => d.name)
            let menuItems = storeData.map(s => {
                return {
                    icon: 'pe-7s-graph',
                    label: s.name,
                    to: `${urls.BASE_PROJECTS_URL}/${s.integerID}`
                }
            })

            let currentStore = storeData[0] || null;
            return {
                ...state,
                storeDetails: storeData,
                menuItems: menuItems,
                initailStore: currentStore,
                currentStore: currentStore,
                loading: false,
                projects: [],
                apiErrorMsg: ''
            }

        case actionType.SET_CURRENT_STORE:
            let currentStore_ = state.storeDetails.find(s => s.integerID == action.payload.id)
            return {
                ...state,
                projects: [],
                currentStore: currentStore_
            }

        case actionType.SET_STORE_PROJECTS:
            let currentStore2 = state.storeDetails.find(s => s.integerID == action.payload.id)
            return {
                ...state,
                projects: action.payload.projects,
                currentStore: currentStore2,
                loading: false
            }

        case actionType.SET_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
                storeDetails: [],
                menuItems: [],
                initailStore: null,
                currentStore: null,
                projects: [],
                apiErrorMsg: ''
            }

        case actionType.SET_SUBMIT_PROJECT_LOADING:
            return {
                ...state,
                loading: action.payload.loading,
                apiErrorMsg: '',
                projects: []
            }

        case actionType.SET_PROJECT_API_STATUS:
            return {
                ...state,
                apiErrorMsg: action.payload.status,
                loading: false
            }

        case actionType.ADD_PROJECT:
            let storeData__ = action.payload.storeData.filter(d => d.name)
            let menuItems__ = storeData__.map(s => {
                return {
                    icon: 'pe-7s-graph',
                    label: s.name,
                    to: `${urls.BASE_PROJECTS_URL}/${s.integerID}`
                }
            })
            let currentStore__ = action.payload.storeId ? storeData__.find(s => s.integerID == action.payload.storeId) : null;
            let initailStore_ = action.payload.initialStoreId ? storeData__.find(s => s.integerID == action.payload.initialStoreId) : null;

            return {
                ...state,
                initialState: initailStore_,
                currentStore: currentStore__,
                storeDetails: storeData__,
                menuItems: menuItems__,
                loading: false,
                apiStatus: action.payload.msg,
                projects: []
            }

        case actionType.SET_CURRENT_STORE_ONLY:
            let currentStore_1 = state.storeDetails.find(s => s.integerID == action.payload.id)
            return {
                ...state,
                currentStore: currentStore_1
            }

        default:
            return { ...state }
    }
}

export default ProjectReducer;
