import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import actionType from '../../Redux/Actions/ActionTypes';
import {LoadSellerPartnerList} from "../../Redux/Actions/Actions";

function GetAllSellerPartnerListService(token) {
    
    return function(dispatch) {

        dispatch({ type: actionType.LOAD_SELLER_PARTNER_LIST_BEGIN });

        axios.get(`${CURRENT_API_URL}/sellerPartner/getAllSellerPartnerList`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadSellerPartnerList(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_SELLER_PARTNER_LIST_FAILED });

        });
    }
}

export {
    GetAllSellerPartnerListService,
}
