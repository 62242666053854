import React, { useState } from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import CustomTabs from "../../../../../../../Components/Nav/CustomTabs";
import ConvertingKeywordSummaryTable from "../../../../../../Components/Tables/ConvertingKeywordSummaryTable";
import CustomTabContent from "../../../../../../../Components/Nav/CustomTabContent";
import KeywordTable from "../../../../../../Components/Tables/KeywordTable";


function KeywordPerformanceTabs(props) {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tabId) => {
        setActiveTab(tabId);
    };

    let tabList = [
        <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>All Keywords</div>,
        <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>Top Converting</div>,
        <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>Impression Generating</div>,
    ];

    let tabContentList = [
        <KeywordTable
            data={props.allKeywords}
            simpleView={true}
        />,
        <ConvertingKeywordSummaryTable
            isTopKeywords={true}
            data={props.convertingKeywords}
        />,
        <ConvertingKeywordSummaryTable
            isTopKeywords={false}
            data={props.impressionKeywords}
        />,
    ];

    return (
        <Card className="mb-3">
            <CardHeader className="tabs-lg-alternate">
                <CustomTabs
                    activeTab={activeTab}
                    toggle={toggle}
                    tabList={tabList}
                />
            </CardHeader>
            <CustomTabContent
                activeTab={activeTab}
                tabList={tabContentList}
                renderTabPane={(tab) => <CardBody>{tab}</CardBody>}
            />
        </Card>
    )
}

export default KeywordPerformanceTabs;