import React from 'react';
import SelectableProductWidgetList from "../../../../../../Components/Widgets/Products/SelectableProductWidgetList";
import SelectableProductWidget from "../../../../../../Components/Widgets/Products/SelectableProductWidget";
import WidgetListCard from "../../../../../../../Components/Widgets/WidgetListCard";
import SelectableParentProductWidget from "../../../../../../Components/Widgets/Products/SelectableParentProductWidget";
import {
    FormatProductWidgetData
} from "../../../../../../Components/Widgets/Products/WidgetDataFunctions/FormatProductWidgetData";


function GroupedProductWidgetList(props) {

    let groupedWidgets = props.parentProducts.map(parent => {
        const {
            productTitle,
            asin,
            itemImage,
            isParentProduct,
        } = parent;

        if (!isParentProduct) return FormatProductWidgetData(parent);        // orphan product, not parent

        const details = {
            productVariation: asin,
        };

        let childVariations = props.childProducts.filter(child => child.parentProduct === parent.asin);

        return {
            productName: productTitle,
            productImage: itemImage,
            details: details,
            children: childVariations,
            isParentProduct: isParentProduct,
            numManagedChildren: childVariations.reduce((count, childProduct) => count + childProduct.isManaged, 0),
        };
    });

    groupedWidgets = groupedWidgets.filter(parent => !parent.isParentProduct || parent.children.length !== 0);

    const renderGroupedProductWidgets = (parent, key) => {
        if (parent.isParentProduct) return (
            <SelectableParentProductWidget
                {...parent}
                listItemKey={key}
                toggleSelect={props.toggleSelect}
                isSelected={props.selectedProducts.find(p => p.ASIN === parent.details.productVariation)
                }
                // isSelected={false}
                nestedList={(
                    <SelectableProductWidgetList
                        accessLevel={props.accessLevel}
                        data={parent.children}
                        updateProductManagement={props.updateProductManagement}
                        quickLaunch={props.quickLaunch}
                        handleQuickActions={props.handleQuickActions}
                        toggleSelect={props.toggleSelect}
                        selectedProducts={props.selectedProducts}
                        card={false}
                        storeIntegerID={props.storeIntegerID}
                        token={props.token}
                    />
                )}
            />
        )
        else return (
            <SelectableProductWidget
                {...parent}
                updateProductManagement={props.updateProductManagement}
                quickLaunch={props.quickLaunch}
                handleQuickActions={props.handleQuickActions}
                toggleSelect={props.toggleSelect}
                listItemKey={key}
                // isSelected={props.selectedProducts.find(p => p.ASIN === product.details.ASIN && p.SKU === product.details.SKU)}
                isSelected={props.selectedProducts.find(
                    (p) => p.ASIN === parent.details.ASIN
                )}
                accessLevel={props.accessLevel}
                storeIntegerID={props.storeIntegerID}
                token={props.token}
            />
        )
    }


    return (
        <WidgetListCard
            widgets={groupedWidgets}
            renderWidgetListItem={renderGroupedProductWidgets}
            card={props.card}
        />
    )
}

export default GroupedProductWidgetList;
