import axios from 'axios';
import { LoadAdProfiles } from "../../../../Redux/Actions/Actions";
import actionType from '../../../../Redux/Actions/ActionTypes';
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function StoreListService(token) {
    return function(dispatch) {
        console.log('In StoreListService')
        dispatch({
            type: actionType.LOAD_ADPROFILES_BEGIN,
        });

        axios.get(`${CURRENT_API_URL}/campaign/landing`, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadAdProfiles(res.data.adProfiles, res.data.totalSpend));
                console.log('after load profile In StoreListService')
            })
            .catch(() => {
                dispatch({
                    type: actionType.LOAD_ADPROFILES_FAILED,
                });
            })
    }
}

export default StoreListService;
