import React, { Fragment } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";


/**
 * Creates a breadcrumbs component from the given props. Breadcrumbs provide a way to navigate back to
 * previously visited pages
 * @param history - list of dictionaries that define previously visited pages (excluding current page). Each
 * dictionary contains a "to" and "label" field that define the hyperlink to the page and its name, respectively.
 * @param activeLabel - the name, or label, of the currently active page
 */
function CustomBreadcrumb({history, activeLabel}) {

    // iterates through the history list and returns a breadcrumb for each page
    const renderBreadcrumbs = (items) => (
        items.map((item, i) => (
            <BreadcrumbItem key={i} tag="a" href={item.to}>{item.label}</BreadcrumbItem>
        ))
    );

    return (
        <Fragment>
            <Breadcrumb tag="nav" listTag="div">
                { renderBreadcrumbs(history) }
                <BreadcrumbItem active>{activeLabel}</BreadcrumbItem>
            </Breadcrumb>
        </Fragment>
    );
}

export default CustomBreadcrumb;