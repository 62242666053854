import React from 'react';
import { Row, Col, Button, Label, CustomInput, Input } from "reactstrap";
import { DropdownList } from "react-widgets";
import { connect } from "react-redux";

import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import UpdateCampaignStageService
    from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/UpdateCampaignStageService";
import StoreOverviewService
    from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";


function UpdateCampaignStageModal(props) {

    const { isOpen, toggle, campaignUpdate, setCampaignUpdate} = props;

    let stages = [
        {
            id: "swarm",
            value: "Swarm",
        },
        {
            id: "explore",
            value: "Explore",
        },
        {
            id: "establish",
            value: "Establish",
        },
        {
            id: "winning",
            value: "Winning",
        },
    ];

    const handleCampaignStageUpdate = async () => {
        let response = await UpdateCampaignStageService(props.token, campaignUpdate.campaignID, campaignUpdate.campaignStage, campaignUpdate.isPhrase);
        if (response) {
            toggle();
            setCampaignUpdate({campaignID: '', campaignStage: '', isPhrase: false})
            props.onUpdate();
        }
    }

    return (
        <CustomModal
            isOpen={isOpen}
            toggle={toggle}
            header="Update Campaign Stage"
            footer={
                <Button
                    color="success"
                    onClick={() => handleCampaignStageUpdate()}
                    disabled={campaignUpdate.campaignStage === ''}
                >
                    Confirm Update
                </Button>
            }
        >
            <Row>
                <Col className="mb-2" md="12">
                    <DropdownList
                        data={stages}
                        dataKey='id'
                        value={campaignUpdate.campaignStage}
                        textField="value"
                        onChange={value => setCampaignUpdate(prevState => ({
                            ...prevState,
                            campaignStage: value,
                        }))}
                        placeholder="Campaign Stage"
                    />
                </Col>
                <Col md="12">
                    <CustomInput
                        type="checkbox"
                        id="isPhrase"
                        label="Phrase Campaign?"
                        checked={campaignUpdate.isPhrase}
                        onChange={e => {
                            let checked = e.target.checked;
                            setCampaignUpdate(prevState => ({
                                ...prevState,
                                isPhrase: checked,
                            }))
                        }}
                    />
                </Col>
            </Row>
        </CustomModal>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(UpdateCampaignStageModal);
