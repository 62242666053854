import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function RequestReportForStoreService(token,days,profileId) {
    notify.NotifySuccess("Advertising Reports are being requested for selected store.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/RequestReportForStore`, {
        profileId: profileId,  
        days:days    
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Advertising Reports have been requested for selected store. Please try saving report after 15 mins.");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while requesting reports, please try again.");
        return false;
    });
}

export default RequestReportForStoreService;