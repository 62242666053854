import axios from 'axios';

import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function ImportCampaignService(token, integerID, asin, campaignID, campaignStage, isPhrase) {
    return axios.post(`${CURRENT_API_URL}/campaign/campaignAdopt`, {
        integerID: integerID,
        asin: asin,
        campaignID: campaignID,
        simpliworksStage: campaignStage,
        isPhraseCampaign: isPhrase,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Your campaign was successfully imported!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("We've encountered an error while importing your campaign. Please try again later.");
            return false;
        })
}


export default ImportCampaignService;
