import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  CardTitle,
} from "reactstrap";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";
import DateRangePicker from "../../../../../../Components/Input/DateRangePicker";
import { GetAdvReportsStatusService } from "../../../../../../../Service/OPSServices/GetAdvReportsStatusService";
import { RetryParticularAdvReportService } from "../../../../../../../Service/OPSServices/RetryParticularAdvReportService";

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
 

function AdvReportsStatus(props) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [integerId, setIntegerId] = useState("");
  const [asin, setAsin] = useState("");

  const [loading, setLoading] = useState(false);
  const [allReports, setAllReports] = useState()
  const isInputValid = () => {
    if (dateRange.startDate === null || integerId == "") return false;
    return true;
  };
  const fetchAdvReportsStatus = (dataType) => {
    setLoading(true);

    let data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        integerId: integerId,
        dataType:dataType
    };

    GetAdvReportsStatusService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
        setAllReports(response.allReports["missing_SPAPI_Reports"])
        //let fileName = integerId +  dateRange.startDate + dateRange.endDate
      // Assuming startDate and endDate are JavaScript Date objects
        let startDateString = dateRange.startDate.toISOString().slice(0, 10);
        let endDateString =dateRange.endDate.toISOString().slice(0, 10);

        // Concatenate integerId and formatted date strings
        let fileName = integerId + "_" +response.allReports["store_Name"] + "_" +  response.allReports["sellerPartnerid"] + "_" + startDateString + "-" + endDateString;

      if ("store_summary" in response.allReports)
      {
          exportToCSV(response.allReports, fileName);}
      }
    });

    setLoading(false);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const isValidArray = (data) => Array.isArray(data) && data.length > 0;

    // Check if each property in csvData is a valid array
    const ws = isValidArray(csvData.store_summary) ? XLSX.utils.json_to_sheet(csvData.store_summary) : null;
    const ws1 = isValidArray(csvData.spapi) ? XLSX.utils.json_to_sheet(csvData.spapi) : null;
    const ws2 = isValidArray(csvData.billingRecords) ? XLSX.utils.json_to_sheet(csvData.billingRecords) : null;
    const ws3 = isValidArray(csvData.asinManagementStateHisList) ? XLSX.utils.json_to_sheet(csvData.asinManagementStateHisList) : null;
    const ws4 = isValidArray(csvData.asin_data_list) ? XLSX.utils.json_to_sheet(csvData.asin_data_list) : null;
    const ws5 = isValidArray(csvData.missing_SPAPI_Reports) ? XLSX.utils.json_to_sheet(csvData.missing_SPAPI_Reports) : null;
    const ws6 = isValidArray(csvData.all_Asins) ? XLSX.utils.json_to_sheet(csvData.all_Asins) : null;

    // Build workbook with valid sheets
    const wb = {
        Sheets: {
            'store_summary': ws,
            'spapi': ws1,
            'billingRecords': ws2,
            'asinManagementStateHisList': ws3,
            'asin_data_list': ws4,
            'missing_SPAPI_Reports': ws5,
            'all_Asins' : ws6
        },
        SheetNames: [
            'store_summary',
            'spapi',
            'billingRecords',
            'asinManagementStateHisList',
            'asin_data_list',
            'missing_SPAPI_Reports',
            'all_Asins'
        ]
    };

    // Remove null sheets from workbook
    wb.SheetNames = wb.SheetNames.filter(sheetName => wb.Sheets[sheetName] !== null);
    wb.Sheets = wb.SheetNames.reduce((obj, sheetName) => {
        obj[sheetName] = wb.Sheets[sheetName];
        return obj;
    }, {});

    // Convert workbook to Excel buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    // Save Excel file
    FileSaver.saveAs(data, fileName + fileExtension);
};



  const [jsonData, setJsonData] = useState();
  const [isJsonOpen, setIsJsonOpen] = useState();
  const [parsedJsonData, setParsedJsonData] = useState();

  const printTheJSONInPrettyFormat = (inputJson) => {
    var parseJSON = JSON.parse(inputJson);

    var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
    setParsedJsonData(parseJSON);
    setJsonData(JSONInPrettyFormat);
  };

  const retryParticularAdvReport = (value) =>{
    setLoading(true);

    let data = {
        reportDate: value.date,
        reportName: value.reportName,
        integerID : value.integerID,
        Asin : value.Asin 
    };

    RetryParticularAdvReportService(props.token, data).then((response) => {
      if (response) {
        console.log(response);
      }
    });

    setLoading(false);
  }
  const columns = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
        Header: "Report",
        accessor: "reportName",
    },
    {
      Header: "integerID",
      accessor: "integerID",
    },    
    {
      Header: "Store",
      accessor: "storeName",
    },
    {
      Header: "Asin",
      accessor: "Asin",
    },
    {
      Header: "Status",
      accessor: "reportStatus",
    },   
    {
      Header: "Updation Date",
      accessor: "updation_date",
    },
    
    {
      Header: "Updation Time",
      accessor: "updation_time",
    },
    {
      Header: "Report JSON",
      accessor: "reportJson",
        Cell: (cellInfo) => (
        <Button
          className="m-auto"
          color={"link"}
          onClick={() => {
            printTheJSONInPrettyFormat(cellInfo.value);
            setIsJsonOpen(!isJsonOpen);
          }}
        >
          View
        </Button>
      ),
    },
    {
        Header: "Action",
        Cell: (cellInfo) => (
        <Button
          className="m-auto"
          color={"link"}
          onClick={() => {
            // printTheJSONInPrettyFormat(cellInfo.original);
            // setIsJsonOpen(!isJsonOpen);
            retryParticularAdvReport(cellInfo.original)
          }}
        >
          Fetch Again
        </Button>
      ),
    },
    {
      Header: "Total SPAPI Sales",
      accessor: "totalSalesamount",
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <CardBody>
            <CardTitle>Date Range</CardTitle>
            <DateRangePicker
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <div className="divider" />
            <Label for={"storeID"}>Store Integer ID</Label>
            <Input
              className="mb-3"
              id={"storeID"}
              value={integerId}
              onChange={(e) => setIntegerId(e.target.value)}
              placeholder="Enter Store ID"
            />
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('AdvertisingData')}
              //disabled={!isInputValid()}
            >
              Advertising Data
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('SPAPI Asin Sales')}
              //disabled={!isInputValid()}
            >
              SPAPI Asin Reports
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('SPAPI Store Sales')}
              //disabled={!isInputValid()}
            >
              SPAPI Store Reports
            </LoadingButton>
            &nbsp;
            <LoadingButton
              className="mt-2"
              loading={loading}
              color={"primary"}
              onClick={() => fetchAdvReportsStatus('SW Commissions')}
              //disabled={!isInputValid()}
            >
              SW Commissions
            </LoadingButton>

          </CardBody>
        </Col>
      </Row>

      <ReactTable
        data={allReports}
        columns={columns}
        noDataText={"No Data Found."}
        className="-fixed -highlight -striped"
        defaultPageSize={5}
      />

      <CustomModal
        header={"Report status"}
        isOpen={isJsonOpen}
        toggle={() => setIsJsonOpen(!isJsonOpen)}
        size={"md"}
        className="curve-popup"
        footer={
          <Fragment>
            <Button color="link" onClick={() => setIsJsonOpen(!isJsonOpen)}>
              Cancel
            </Button>
          </Fragment>
        }
      >
        <Label>
          <p>
            <pre style={{ whiteSpace: "pre-wrap" }}>{jsonData}</pre>
          </p>
        </Label>
      </CustomModal>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  token: state.loggedUser.token,
});

export default connect(mapStateToProps)(AdvReportsStatus);
