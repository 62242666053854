import axios from 'axios';
import notify from '../../../../Notifications/ToastifyActions';
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadParentProductRankData } from "../../../../Redux/Actions/Actions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function ParentProductKeywordService(token, productVariation, startDate, endDate) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PARENT_PRODUCT_RANK_DATA_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/parentProductRankData`, {
            productVariation: productVariation,
            startDate: startDate,
            endDate: endDate,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadParentProductRankData(res.data))
            })
            .catch(() => {
                dispatch({ type: actionType.LOAD_PARENT_PRODUCT_RANK_DATA_FAILED });
                notify.NotifyError('We\'ve encountered an error while retrieving your product details, please try again later.');
            })
    }
}

export default ParentProductKeywordService;
