import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'reactstrap';
import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel
} from "../../../../../Components/Fileupload/file-upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const MAX_FILES_ALLOWED = 10;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const AmazonUploadField = ({ loading, fieldData, onUploadFile, onFileError, maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    maxFiles = MAX_FILES_ALLOWED, label, ...props }) => {

    const [isEdit, setIsEdit] = useState(false)
    const [filesList, setFilesList] = useState([])
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    useEffect(() => {
        if (fieldData && fieldData.id > 0) {
            setIsEdit(true)
        }
        console.log(fieldData)
    }, [fieldData])

    const uploadFile = () => {
        let payload = {
            id: 0,
            files: filesList,
            field_name: props.name
        }

        if (isEdit) {
            payload.id = fieldData.id
        }
        onUploadFile(payload)
    }

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!props.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        setFilesList(filesAsArray)

    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length && newFiles.length <= maxFiles) {
            let regex = new RegExp('[^.]+$');
            let extension = ""
            let fileNotImage = false
            for (let f of newFiles) {
                extension = f.name.match(regex)
                let acceptedFiles = props.accept.split(',').map(s => s.toLowerCase().trim())
                if (!acceptedFiles.includes(`.${extension[0].toLowerCase()}`)) {
                    //if(extension[0].toLowerCase() !== 'png' && extension[0].toLowerCase() !== 'jpg' && extension[0].toLowerCase() !== 'jpeg'){
                    fileNotImage = true;
                    break;
                }
            }

            if (fileNotImage) {
                onFileError(`Only ${props.accept} formats are allowed`);
                return;
            }
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
        else {
            onFileError(`Only ${maxFiles} images are allowed`);
        }
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };
    return (
        <Row>
            <Col md="12">
                {/* <FileUpload {...props} updateFilesCb={(files) => onUpload(files)}
                previousFiles={[]}
                onFileLengthError={(msg) => { onFileError(msg) }} /> */}

                <>
                    <FileUploadContainer>
                        <InputLabel>{label}</InputLabel>
                        <DragDropText>Drag and drop your files anywhere or</DragDropText>
                        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                            <i className="fas fa-file-upload" />
                            <span> Upload {props.multiple ? "files" : "a file"}</span>
                        </UploadFileBtn>
                        <FormField
                            type="file"
                            ref={fileInputField}
                            onChange={handleNewFileUpload}
                            title=""
                            value=""
                            {...props}
                        />
                    </FileUploadContainer>
                    <FilePreviewContainer>
                        {/* <span>To Upload</span> */}
                        <PreviewList>
                            {Object.keys(files).map((fileName, index) => {
                                let file = files[fileName];
                                let isImageFile = file.type.split("/")[0] === "image";
                                return (
                                    <PreviewContainer key={fileName}>
                                        <div>
                                            {isImageFile && (
                                                <ImagePreview
                                                    src={URL.createObjectURL(file)}
                                                    alt={`file preview ${index}`}
                                                />
                                            )}
                                            <FileMetaData isImageFile={isImageFile}>
                                                <span>{file.name}</span>
                                                <aside>
                                                    <span>{convertBytesToKB(file.size)} kb</span>
                                                    <RemoveFileIcon
                                                        className="fas fa-trash-alt"
                                                        onClick={() => removeFile(fileName)}
                                                    />
                                                </aside>
                                            </FileMetaData>
                                        </div>
                                    </PreviewContainer>
                                );
                            })}
                        </PreviewList>
                    </FilePreviewContainer>
                </>
            </Col>
            <Col md="12">
                {(filesList.length > 0 && !loading) ?
                    <Button type="button" color="primary" onClick={uploadFile}>Upload</Button>
                    : <Button type="button" color="primary" disabled>Upload</Button>
                }

            </Col>
        </Row>
    )
}

export default AmazonUploadField