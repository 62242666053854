import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import urls from '../../../../Utils/URLs'
import PageNotFound from "../../../PageNotFound";
import LaunchpadLayout from "./Components/LaunchpadLayout";
import CartOverviewPage from "./Scenes/CartOverview/CartOverviewPage";
import ReadyToLaunchPage from "./Scenes/ReadyToLaunch/ReadyToLaunchPage";
import ReadyCampaignListService
    from "../../../../Service/DashboardServices/AccountOverviewServices/LaunchpadServices/ReadyCampaignListService";


function LaunchpadPage(props) {

    useEffect(() => {
        props.dispatch(ReadyCampaignListService(props.token));
    }, []);

    return (
        <LaunchpadLayout>
            <Switch>
                <Route exact path={urls.LAUNCHPAD_CART_OVERVIEW_URL} component={CartOverviewPage} />
                <Route exact path={urls.LAUNCHPAD_READY_CAMPAIGNS_URL} component={ReadyToLaunchPage} />
                <Route component={PageNotFound} />
            </Switch>
        </LaunchpadLayout>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
});

export default connect(mapStateToProps)(LaunchpadPage);
