import React, { useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import urls from '../../../../../Utils/URLs'
import { Row, Col } from "reactstrap";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";

const ViewProjectPage = (props) => {


    let history = useHistory();

    useEffect(() => {
        if (props.initailStore) {
            history.push(`${urls.BASE_PROJECTS_URL}/${props.initailStore.integerID}`)
        }
    }, [props.initailStore])


    const renderFailure = () => {

        return (
            <Fragment>
                <h5 className="text-danger">An error occurred while retrieving your product details. Try Again
                </h5>
            </Fragment>

        )
    }

    return (
        <Fragment>

            <Row>
                <Col md="12">
                    <LoadingOverlay
                        active={props.loading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#fff',
                                opacity: 1
                            }),
                            content: (base) => ({
                                ...base,
                                color: "#000"
                            })
                        }}
                        spinner={<Loader active type="ball-pulse" />}
                        text='Loading...'
                    >
                        {
                            props.status && renderFailure()
                        }
                    </LoadingOverlay>

                </Col>
            </Row>

        </Fragment>

    )
}

const mapToProps = (state) => {
    return {
        products: state.project.projects,
        loading: state.project.loading,
        initailStore: state.project.initailStore,
        status: state.project.apiErrorMsg,
    }
}

export default connect(mapToProps)(ViewProjectPage);
