import { toast } from 'react-toastify';

/**
 * Creates an error notification with the given message
 * @param message
 */
function NotifyError(message) {
    toast.error(message, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

/**
 * Creates a success notification with the given message
 * @param message
 */
function NotifySuccess(message) {
    toast.success(message, {
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export default {
    NotifyError,
    NotifySuccess,
}
