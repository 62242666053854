import axios from 'axios';
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadStoreUnitsData } from '../../../../Redux/Actions/Actions';
import { CURRENT_API_URL } from '../../../../Utils/Constants';

function StoreUnitsDataService(token, integerID, daysBack) {

	return function(dispatch) {
		dispatch({ type: actionType.LOAD_STORE_UNITS_DATA_BEGIN });

		axios.post(
				`${CURRENT_API_URL}/myRealProfit/getUnitsSoldData`,
				{
					integerID: integerID,
					daysBack: daysBack,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((res) => {
				dispatch(LoadStoreUnitsData(res.data.unitsData));
			})
			.catch(() => {
				dispatch({
					type: actionType.LOAD_STORE_UNITS_DATA_FAILED,
				});
			});
	};
}

export default StoreUnitsDataService;
