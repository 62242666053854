import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
import { propTypes } from 'react-widgets/lib/Calendar';
import StoreHealthService from '../../../../../../Service/OPSServices/StoreHealthServices/StoreHealthService';
import { Container, Col, Row, Card, Input, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactTable from 'react-table';
import { values } from 'lodash';
import notify from '../../../../../../../src/Notifications/ToastifyActions';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import PowerbiEmbed from './Components/PowerbiEmbed';
function OpsResourceSkillSetPage(props) {

    return (
        <>
            <Row>
                <Col md={12}>
                    <Card className='p-3'>
                        <PowerbiEmbed
                            tbl_name={'newamazonadvertiserprofile'}
                            col_name={'store_name'}
                            responseURL={'https://pbi-simpliworks-production.azurewebsites.net/api/Production_Employee_Performance_Report?code=ejfyhVdjZ6hRTKlKGKD2GJ5GB50w-rX8kwOZxaesc47AAzFubVDBZw=='}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        adProfiles: state.accountOverview.landing.adProfiles,
    };
};

export default connect(mapStateToProps)(OpsResourceSkillSetPage);
