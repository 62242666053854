import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadProductCategories } from "../../../../Redux/Actions/Actions";


function ProductCategoryService(token, asin,amazonDomain) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_PRODUCT_CATEGORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/FetchProductCategoryForASIN/${asin}/${amazonDomain}`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadProductCategories(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_PRODUCT_CATEGORIES_FAILED });

        });
    }
}

export default ProductCategoryService;