import React, { Fragment } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Can from "../../../../../Components/Can";

const AmazonBackendKeywordsEdit = ({ backendKeywords, openComments, accessLevel, openTextField, approveOrReviseProject, isFinalCompleted }) => {


    const openCommentsModal = () => {
        openComments(backendKeywords, undefined)
    }


    const getBackendKeywords = () => {
        return (
            <Fragment>
                {
                    backendKeywords &&
                    <p className="features">{backendKeywords.field_value} </p >
                }

            </Fragment>
        )
    }

    return (
        <Fragment>
            <h4 className="heading">Backend Keywords {
                (backendKeywords && backendKeywords.approved) && <span className="green-badge green-badge-approve"><i className="fas fa-check-circle badge-green-icon" />  Approved</span>}
                {(backendKeywords && backendKeywords.status === 'REVISED') && <span className="badge badge-light-danger capitalize"><i className="fas fa-undo-alt badge-light-danger" />  Revision Needed</span>}
                {' '}

                {
                    backendKeywords ?
                        <span className="d-inline-block">{
                            !isFinalCompleted &&

                            <Fragment>
                                <Can
                                    role={accessLevel}
                                    perform={"project:edit"}
                                    yes={() => (
                                        !backendKeywords.approved &&
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="link">
                                                <i className="fas fa-ellipsis-v"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {
                                                    (backendKeywords.status === 'REVISED') &&
                                                    <DropdownItem onClick={openCommentsModal} title=" Add Comments">
                                                        <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                    </DropdownItem>

                                                }

                                                {(!backendKeywords.approved) &&
                                                <Fragment>
                                                    <DropdownItem onClick={() => {
                                                        let tempData = {
                                                            fieldData: backendKeywords,
                                                            name: 'backendKeywords',
                                                            placeholder: 'Backend Keywords'
                                                        }
                                                        openTextField(tempData, 'Edit Backend Keywords')
                                                    }} title="Edit Backend Keywords">
                                                        Edit Backend Keywords {' '}<i className="fas fa-edit blue ml-2"></i>

                                                    </DropdownItem>
                                                </Fragment>
                                                }


                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                />
                                {/*{*/}
                                {/*    (accessLevel === 0 && !backendKeywords.approved) &&*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle color="link">*/}
                                {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu right>*/}
                                {/*            {*/}
                                {/*                (backendKeywords.status === 'REVISED') &&*/}
                                {/*                <DropdownItem onClick={openCommentsModal} title=" Add Comments">*/}
                                {/*                    <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                {/*                </DropdownItem>*/}

                                {/*            }*/}

                                {/*            {(!backendKeywords.approved) &&*/}
                                {/*                <Fragment>*/}
                                {/*                    <DropdownItem onClick={() => {*/}
                                {/*                        let tempData = {*/}
                                {/*                            fieldData: backendKeywords,*/}
                                {/*                            name: 'backendKeywords',*/}
                                {/*                            placeholder: 'Backend Keywords'*/}
                                {/*                        }*/}
                                {/*                        openTextField(tempData, 'Edit Backend Keywords')*/}
                                {/*                    }} title="Edit Backend Keywords">*/}
                                {/*                        Edit Backend Keywords {' '}<i className="fas fa-edit blue ml-2"></i>*/}

                                {/*                    </DropdownItem>*/}
                                {/*                </Fragment>*/}
                                {/*            }*/}


                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*}*/}

                                <Can
                                    role={accessLevel}
                                    perform={"project:review"}
                                    yes={() => (
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="link">
                                                <i className="fas fa-ellipsis-v"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <Fragment>
                                                    {!backendKeywords.approved
                                                    &&
                                                    <DropdownItem onClick={() => approveOrReviseProject(backendKeywords, 'APPROVED', undefined)} title="Approve">
                                                        <i className="fas fa-check green mr-2"></i>  {' '} Approve
                                                    </DropdownItem>
                                                    }
                                                    {
                                                        backendKeywords.status !== 'REVISED' &&
                                                        <DropdownItem onClick={() => openComments(backendKeywords, undefined, true)} title="Revise">
                                                            <i className="fas fa-undo-alt comment-icon mr-2"></i>  {' '} Revise

                                                        </DropdownItem>
                                                    }
                                                </Fragment>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                />
                                {/*{*/}
                                {/*    accessLevel === 3 &&*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle color="link">*/}
                                {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu right>*/}
                                {/*            <Fragment>*/}
                                {/*                {!backendKeywords.approved*/}
                                {/*                    &&*/}
                                {/*                    <DropdownItem onClick={() => approveOrReviseProject(backendKeywords, 'APPROVED', undefined)} title="Approve">*/}
                                {/*                        <i className="fas fa-check green mr-2"></i>  {' '} Approve*/}
                                {/*                    </DropdownItem>*/}
                                {/*                }*/}
                                {/*                {*/}
                                {/*                    backendKeywords.status !== 'REVISED' &&*/}
                                {/*                    <DropdownItem onClick={() => openComments(backendKeywords, undefined, true)} title="Revise">*/}
                                {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i>  {' '} Revise*/}

                                {/*                    </DropdownItem>*/}
                                {/*                }*/}
                                {/*            </Fragment>*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*}*/}
                            </Fragment>


                        }

                        </span>
                        :
                        <span className="mx-2">
                            <Can
                                role={accessLevel}
                                perform={"project:edit"}
                                yes={() => (
                                    !isFinalCompleted &&
                                    <Button type="button" color="primary" className="btn-sm f07" onClick={() => {
                                        let tempData = {
                                            fieldData: backendKeywords,
                                            name: 'backendKeywords',
                                            placeholder: 'Backend Keywords'
                                        }
                                        openTextField(tempData, 'Edit Backend Keywords')
                                    }}><i className="fas fa-plus-circle"></i> Add Backend Keywords</Button>
                                )}
                            />
                            {/*{(accessLevel === 0 && !isFinalCompleted) &&*/}
                            {/*    <Button type="button" color="primary" className="btn-sm f07" onClick={() => {*/}
                            {/*        let tempData = {*/}
                            {/*            fieldData: backendKeywords,*/}
                            {/*            name: 'backendKeywords',*/}
                            {/*            placeholder: 'Backend Keywords'*/}
                            {/*        }*/}
                            {/*        openTextField(tempData, 'Edit Backend Keywords')*/}
                            {/*    }}><i className="fas fa-plus-circle"></i> Add Backend Keywords</Button>*/}
                            {/*}*/}

                        </span>

                }
            </h4>
            {
                getBackendKeywords()
            }

        </Fragment>
    )
}

export default AmazonBackendKeywordsEdit;
