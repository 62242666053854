import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function HandleBleederKeywordService(token, keywordID, action) {
    return axios.post(`${CURRENT_API_URL}/campaign/acknowledgeBleederAlert`, {
        keywordID: keywordID,
        action: action,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Keyword bleeder alert was handled successfully!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("There was an error processing your request, please try again.");
            return false;
        })
}

export default HandleBleederKeywordService;