import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
function DeleteFileAssetNewService(token,data) {
    return axios.post(`${CURRENT_API_URL}/assetgallery/deleteFileAssetNew`,data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        if(res.data.manyFiles === 'true')
        {
            notify.NotifySuccess('Editable file is not deleted because it is attached to other images.')
        }
        if(res.data.status === 'false' && res.data.manyFiles === 'false')
        {
            notify.NotifySuccess('File is not deleted, please try again.')
        }
        if(res.data.status === 'true' && res.data.manyFiles === 'false')
        {
            notify.NotifySuccess('File deleted successfully.')
        }
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching delete file. please try again later.");
        return false;
    });
}
export default DeleteFileAssetNewService;