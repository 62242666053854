
import { Container, Row, Col, Card, CardBody, Button, Label, Input, CardTitle } from 'reactstrap';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import React, {Fragment,useState,useEffect} from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";

function AllJobsListDataTable(props) {

    const [jsonData, setJsonData] = useState();
    const [isJsonOpen, setIsJsonOpen] = useState();
    const [parsedJsonData, setParsedJsonData] = useState();
    const [Th_Data, setThData] = useState();
    const [td_Data, settdData] = useState();
  
    const [isOpen, setIsOpen] = useState(false);
    const [summary, setSummary] = useState();
  
   
    const printTheJSONInPrettyFormat = (inputJson) => {

      var parseJSON = JSON.parse(inputJson);

      var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
      setParsedJsonData(parseJSON);
      setJsonData(JSONInPrettyFormat);

    };



    const columns = [
                

        {
            Header: 'Creation Date',
            accessor: 'Report Date',
        },

        {
            Header: 'Report Status',
            accessor: 'ReportStatus',
            Cell: cellInfo => (               
                <Button
                    className="m-auto"
                    color={"link"}
                    onClick={() => {                        
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
 
        {
            Header: 'Store Name',
            accessor: 'StoreName',
        },
        
        {
            Header: 'ProfileId',
            accessor: 'ProfileId',
        },
     
    ];

    return (
        <Fragment>
            <Row>
                    <Col>
                        <CardTitle>Advertising Job List</CardTitle>
                        <Card>
                        </Card>
                    </Col>
            </Row>

        <ReactTable
            data={props.data.allReports}
            columns={columns}
            noDataText={"No Data Found."}
            className="-fixed -highlight -striped"    
            defaultPageSize={5}                            
            />

            <CustomModal 
                header={"Report status"}
                isOpen={isJsonOpen}
                toggle={() => setIsJsonOpen(!isJsonOpen)}
                size={"md"}
                className='curve-popup'
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setIsJsonOpen(!isJsonOpen)}
                            >
                            Cancel
                        </Button>
                    </Fragment>
                }        
                    
            >  

                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{jsonData}</pre> </p></Label> 
 
             </CustomModal>
             
            &nbsp;                               

            {/* <Row>
                    <Col>                       
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    </Col>
            </Row> */}

            
            &nbsp;                               

          
        </Fragment>
    );
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps) (AllJobsListDataTable);