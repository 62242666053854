import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function UpdateCampaignBudgetService(token, campaignID, budget) {
    return axios.post(`${CURRENT_API_URL}/campaign/updateBudgetForCampaign`, {
        campaignID: campaignID,
        budget: budget,
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Campaign budget successfully updated!");
        return true;
    }).catch(() => {
        notify.NotifyError("There was an error while updating the campaign budget, please try again.");
        return false;
    })
}

export default UpdateCampaignBudgetService;