import React from 'react';
import './ProgressBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const ProgressBar = ({ progress,endJourney }) => {
  // const circles = [20,40, 60, 80, 100];
  const circles = [25,50, 75, 100];
  const circles1 = [1, 2, 3, 4];
  let i = 0;
  const heddings=["Registration", "Advertising Authorization", "SPAPI Authorization", "Store Setup","Products Selection"];

  return (
    <div className="progress-border">
     
      <div className="progress-bar">
        {circles.map((circle) => (
          <div
            className={`circle ${progress >= circle ? 'active' : ''}`}
            style={{ left: `${circle - 4}%` }}
          >
            {/* {circles1[i] === 5 ? 
            (
              <div><span className={progress >= circle ? 'heddings progressBar-active-text' : 'heddings'}>{heddings[i]}</span> <span className="circle-icon"><FontAwesomeIcon icon={faCheckCircle}  className={endJourney ? "circle-round-green": 'circle-round'} /></span></div>
            ) 
            : 
            (
              <div><span className={progress >= circle ? 'heddings progressBar-active-text' : 'heddings'}>{heddings[i]}</span> <span className="circle-text">{circles1[i] * 20}%</span></div>
            )
            } */}
            <div><span className={progress >= circle ? 'heddings progressBar-active-text' : 'heddings'}>{heddings[i]}</span> <span className="circle-text">{circles1[i] * 25}%</span></div>

            <span className="d-none">{i++}</span>
          </div>
        ))}
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};
    
    export default ProgressBar;
    