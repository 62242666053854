import React from 'react';

// General purpose widget layout, allows for more customization
function WidgetLayout(props) {

    return (
        <div className={props.className || "widget-content p-0"} {...props}>
            <div className="widget-content-wrapper">
                {props.children}
            </div>
        </div>
    );
}

export default WidgetLayout;
