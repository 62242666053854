import React, { Fragment, useState } from 'react';
import { Button, Label } from 'reactstrap';
import ReactTable from "react-table";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import { DropdownList } from "react-widgets";


function ProjectsTable(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedAgency, setSelectedAgency] = useState();

    const assignAgencyButton = cellInfo => (
            <Button
                color="link"
                onClick={() => {
                    setSelectedProject(cellInfo.original);
                    setIsOpen(!isOpen);
                }}
            >
                Assign Agency {'>'}
            </Button>
    );


    const columns = [
        {
            Header: 'Project',
            accessor: 'projectName',
        },
        {
            Header: 'Store',
            accessor: 'storeThatRequestedName',
        },
        {
            Header: 'Status',
            accessor: 'projectStatus',
        },
        {
            Header: 'Submission Date',
            accessor: 'submittedDate',
            Cell: props => new Date(props.value).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'}),
        },
        {
            Header: 'Assigned Agency',
            accessor: 'contentAgencyAssignedName',
            Cell: props => props.original.isContentAgencyAssigned ? props.value : "Project Not Assigned",
        },
        {
            Header: 'Assign',
            Cell: props => assignAgencyButton(props),
        },
    ];

    return (
        <Fragment>
            <ReactTable
                columns={columns}
                data={props.data}
                style={{
                    height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
                }}
                className="-striped -highlight -fixed"
            />
            <CustomModal
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                header={"Assign Project to Agency"}
                footer={(
                    <Button
                        color={"success"}
                        onClick={() => {
                            props.assignAgency(selectedProject.projectName, selectedAgency);
                            setIsOpen(!isOpen);
                        }}
                    >
                        Assign Project
                    </Button>
                )}
            >
                <DropdownList
                    data={props.contentAgencies}
                    dataKey='contentAgencyMasterId'
                    value={selectedAgency}
                    textField="contentAgencyName"
                    onChange={value => setSelectedAgency(value)}
                    placeholder="Select Agency"
                />
            </CustomModal>
        </Fragment>
    )
}

export default ProjectsTable;
