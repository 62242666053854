import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Formik } from 'formik';
import { submitAmazonOnBoardProjects } from '../../../../../../Service/DashboardServices/Projects/ProjectsService'
import * as Yup from 'yup';



const CreateProjectForm = ({ steps, dispatch, token, currentStore, initailStore, onAlert, onFileLimitError }) => {
    const [showPreviousBtn, setShowPreviousBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [compState, setCompState] = useState(0);

    const intialValues = {
        productName: '',
        productsku: '',
        productId: '',
        onAmazon: '',
        idType: '',
        variation: '',
        serviceSEOCopy: false,
        serviceGalleryPhotos: false,
        serviceAPlusContent: false,
        files: [],
        additionalDetails: "",
        linkToAssets: [
            {
                additionalDetails: "",
                comparabaleProducts: ""
            }
        ]
    }

    // const projectFormValidation = {
    //     productName: Yup.string()
    //         .required('Product Name is required'),
    //     onAmazon: Yup.string().required('On Amazon is required'),
    //     // productsku: Yup.string()
    //     //     .required('Product SKU is required'),
    //     productId: Yup.string()
    //         .required('Product ID is required'),
    //     idType: Yup.string().required('Id Type is required'),
    //     variation: Yup.string().required('Variation is required'),
    //     // serviceSEOCopy: Yup.boolean(),
    //     // serviceGalleryPhotos: Yup.boolean(),
    //     // serviceAPlusContent: Yup.boolean(),
    // }

    const onFileLimitErrorFromUpload = (msg) => {
        onFileLimitError(msg);
    }

    const projectFormValidation = Yup.lazy(values => {
        switch (values.onAmazon) {
            case 'Yes':
                return Yup.object({
                    productName: Yup.string()
                        .required('Product Name is required'),
                    onAmazon: Yup.string().required('On Amazon is required'),
                    productsku: Yup.string()
                        .required('Product SKU is required'),
                    productId: Yup.string()
                        .required('Product ID is required'),
                    idType: Yup.string().required('Id Type is required'),
                    variation: Yup.string().required('Variation is required'),
                    // serviceSEOCopy: Yup.boolean(),
                    // serviceGalleryPhotos: Yup.boolean(),
                    // serviceAPlusContent: Yup.boolean(),
                    additionalDetails: Yup.string(),
                    linkToAssets: Yup.array().of(
                        Yup.object().shape({
                            comparabaleProducts: Yup.string()
                        })
                    )
                })

            default:

                return Yup.object({
                    productName: Yup.string()
                        .required('Product Name is required'),
                    onAmazon: Yup.string().required('On Amazon is required'),
                    // productsku: Yup.string()
                    //     .required('Product SKU is required'),
                    productId: Yup.string()
                        .required('Product ID is required'),
                    idType: Yup.string().required('Id Type is required'),
                    variation: Yup.string().required('Variation is required'),
                    // serviceSEOCopy: Yup.boolean(),
                    // serviceGalleryPhotos: Yup.boolean(),
                    // serviceAPlusContent: Yup.boolean(),
                    additionalDetails: Yup.string(),
                    linkToAssets: Yup.array().of(
                        Yup.object().shape({
                            comparabaleProducts: Yup.string()
                        })
                    )
                })
        }
    })

    useEffect(() => {
    }, [currentStore])

    const checkNavState = (currentStep, stepsLength) => {
        if (currentStep > 0 && currentStep < stepsLength - 1) {
            return {
                showPreviousBtn: true,
                showNextBtn: true
            }
        } else if (currentStep === 0) {
            return {
                showPreviousBtn: false,
                showNextBtn: true
            }
        } else {
            return {
                showPreviousBtn: true,
                showNextBtn: false
            }
        }
    }

    const getNavStyles = (indx, length, stepIsValid) => {
        let styles = [];
        for (let i = 0; i < length; i++) {
            if (i < indx) {
                (stepIsValid && !stepIsValid[i]) ? styles.push('error') : styles.push('done');
            } else if (i === indx) {
                styles.push('doing');
            } else {
                styles.push('todo');
            }
        }

        return styles;
    }

    // sets all state fields based on the provided step index
    const setNavState = next => {
        if (next < steps.length) {
            setCompState(next)    // sets compState (idx of current step component)
        }
        let showNextPrev = checkNavState(next, steps.length)     // sets prev. and next button states
        setShowNextBtn(showNextPrev.showNextBtn);
        setShowPreviousBtn(showNextPrev.showPreviousBtn);
    }

    // allows user to press "enter" key to progress through form steps
    const handleKeyDown = evt => {
        if (evt.which === 13) {
            next()
        }
    }

    // allows user to click step component to progress through form
    const handleOnClick = evt => {
        if (
            evt.currentTarget.value === steps.length - 1 &&
            compState === steps.length - 1
        ) {
            setNavState(steps.length)
        } else {
            setNavState(evt.currentTarget.value)
        }
    }

    const next = () => {
        setNavState(compState + 1)
    }

    const previous = () => {
        if (compState > 0) {
            setNavState(compState - 1)
        }
    }

    const getStepStyles = (formik) => {
        const { errors } = formik;
        // holds whether a step's fields are valid (i.e. if stepIsValid = [false, true, true] then steps 2, 3 are valid and step 1 is invalid.)
        let stepIsValid = steps.map(step => {
            // return step.fields.every(stepField => !errors[stepField] && touched[stepField]);
            return step.fields.every(stepField => !errors[stepField]);
        });
        return getNavStyles(compState, steps.length, stepIsValid);
    }

    // changes class name of steps to adjust their styling based on what step of the form the user is on
    const getClassName = (className, i, styles) => {
        return className + '-' + styles[i];
    }

    // renders form steps with icon and name
    const renderSteps = (formik) => {

        let styles = getStepStyles(formik);

        return steps.map((s, i) => (
            <li
                className={getClassName('form-wizard-step', i, styles)}
                onClick={(e) => {
                    fieldsTouched(formik)
                    handleOnClick(e)
                }
                }
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span>{steps[i].name}</span>
            </li>
        ))
    }

    const renderStepComponent = (formik) => {

        let currentStep = steps[compState];

        if (!currentStep) return;

        return React.cloneElement(currentStep.component, {formik, currentStore, token, onFileLimitErrorFromUpload});
    }

    const submitForm = async (values, { setSubmitting, resetForm }) => {
        try {
            let data = { ...values, storeId: currentStore.integerID, storeName: currentStore.name, }
            let res = await submitAmazonOnBoardProjects(token, data, dispatch, initailStore.integerID)
            if (res) {

                setCompState(0);
                setNavState(0);
                resetForm();
                setSubmitting(false);
                onAlert('success')
                // setIsSuccess(true);

                // setTimeout(() => {
                //     setIsSuccess(false)
                // }, 5000)
            }
            else {
                onAlert('failure')
                // setIsFailure(true);

                // setTimeout(() => {
                //     setIsFailure(false)
                // }, 5000)
            }
            //dispatch({type: actionType.ADD_PROJECT, values})

        } catch (e) {
            console.log(e.message)
        }
    }

    const fieldsTouched = (formik) => {
        Object.keys(formik.values).forEach(key => {
            formik.setFieldTouched(key, true)
        })
    }

    return (
        <Fragment>
            <div onKeyDown={handleKeyDown}>
                <Formik
                    initialValues={{ ...intialValues }}
                    validationSchema={
                        projectFormValidation
                    }

                    onSubmit={submitForm}
                >
                    {formik => (

                        <form >
                            <ol className='forms-wizard'>
                                {renderSteps(formik)}
                            </ol>
                            { renderStepComponent(formik)}
                            <div className="divider" />
                            <div className="clearfix">
                                <Button color="secondary" className="btn-shadow float-left btn-wide btn-pill" outline
                                    style={showPreviousBtn ? {} : { display: 'none' }}
                                    onClick={() => {
                                        fieldsTouched(formik);
                                        previous();
                                    }}
                                >
                                    Previous
                            </Button>
                                <Button
                                    color="primary"
                                    className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
                                    style={showNextBtn ? {} : { display: 'none' }}
                                    onClick={() => {
                                        fieldsTouched(formik);
                                        next();
                                    }}
                                >
                                    Next
                            </Button>
                            </div>
                        </form>
                    )
                    }

                </Formik>
            </div>
        </Fragment>
    )

}


const mapToProps = (state) => {
    return {
        currentStore: state.project.currentStore,
        token: state.loggedUser.token,
        loading: state.project.loading,
        status: state.project.apiStatus,
        initailStore: state.project.initailStore
    }
}


export default connect(mapToProps)(CreateProjectForm);
