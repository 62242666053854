import React, { Fragment } from 'react';
import {
    Row, Col, FormGroup, Label, Input, FormFeedback, InputGroupAddon, InputGroup
} from 'reactstrap';
import { Field } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';

import CustomNumInput from "../../../../../../../Components/Input/CustomNumInput";
import HelpTooltip from "../../../../../../../Components/Tooltips/HelpTooltip";
import CustomSelectInput from "../../../../../../../Components/Input/CustomSelectInput";
import { COUNTRIES } from '../../../../../../../../Utils/Constants';


function ProductInfoStep(props) {

    return (
        <Fragment>
            <div className="form-wizard-content">
                <Row form>
                    <Col className="ml-4" md="5">
                        <h3 className="form-heading pt-3 pb-3">
                            Campaign Details
                        </h3>
                        <FormGroup>
                             <Label for="sellerProfileId">Select Store</Label>
                             {/*<CustomSelectInput name="sellerProfileId" tag={Field} data={props.sellerProfileIdList} />*/}

                            <CustomSelectInput
                                name="sellerProfileId"
                                tag={Field}
                                data={props.adProfiles}
                                textField={profile => `${profile.name} (${profile.location})`}
                                valueField='integerID'
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="country">Marketplace</Label>
                            <CustomSelectInput name="country" tag={Field} data={COUNTRIES} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="dailyBudget">
                                Daily Budget
                                <HelpTooltip
                                    placement="right"
                                    target="tooltip-dailyBudget"
                                    className="ml-1"
                                    style={{display: 'inline'}}
                                    iconStyle={{fontSize: '1.2rem'}}
                                >
                                    Amazon recommends a daily budget of $25 per campaign but we have found optimal growth with a spend of $50
                                </HelpTooltip>
                            </Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faDollarSign}/>
                                    </div>
                                </InputGroupAddon>
                                <CustomNumInput name="dailyBudget" tag={Field} min={1} precision={2} step={0.01} style={{wrap: {flex: 'auto'}}} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="productSKU">
                                Product SKU (Optional)
                                <HelpTooltip
                                    placement="right"
                                    target="tooltip-productSKU"
                                    className="ml-1"
                                    style={{display: 'inline'}}
                                    iconStyle={{fontSize: '1.2rem'}}
                                >
                                    <b>If you are an Amazon Vendor leave this field empty.</b> If you are an Amazon Seller entering your product SKU will allow us to start your campaign as soon
                                    as it is generated, leave this field blank if you would like to review your campaign before starting it manually on Seller Central.
                                </HelpTooltip>
                            </Label>
                            <Input
                                type="text"
                                tag={Field}
                                name="productSKU"
                            />
                        </FormGroup>
                    </Col>
                    <Col md="1"/>
                    <Col md="5">
                        <h3 className="form-heading pt-3 pb-3">
                            Bid Details
                        </h3>
                        <FormGroup>
                            <Label for="bidPrice">
                                Bid Price - Exact
                                <HelpTooltip
                                    placement="right"
                                    target="tooltip-bidPriceExact"
                                    className="ml-1"
                                    style={{display: 'inline'}}
                                    iconStyle={{fontSize: '1.2rem'}}
                                >
                                    Amazon recommends a minimum bid price of $1 per campaign but we recommend higher bid prices for high traffic times of year.
                                </HelpTooltip>
                            </Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faDollarSign}/>
                                    </div>
                                </InputGroupAddon>
                                <CustomNumInput name="bidPriceExact" tag={Field} min={0.02} precision={2} step={0.10} style={{wrap: {flex: 'auto'}}} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="bidPricePhrase">
                                Bid Price - Phrase
                                <HelpTooltip
                                    placement="right"
                                    target="tooltip-bidPricePhrase"
                                    className="ml-1"
                                    style={{display: 'inline'}}
                                    iconStyle={{fontSize: '1.2rem'}}
                                >
                                    Amazon recommends a minimum bid price of $1 per campaign but we recommend higher bid prices for high traffic times of year.
                                </HelpTooltip>
                            </Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faDollarSign}/>
                                    </div>
                                </InputGroupAddon>
                                <CustomNumInput name="bidPricePhrase" tag={Field} min={.02} precision={2} step={0.01} style={{wrap: {flex: 'auto'}}} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-3" check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="productTargetingEnabled"
                                    tag={Field}
                                />
                                Enable Product Targeting?
                            </Label>
                        </FormGroup>
                        { props.values.productTargetingEnabled &&
                            <FormGroup>
                                <Label for="bidPriceTargeting">
                                    Bid Price - Product Targeting
                                    <HelpTooltip
                                        placement="right"
                                        target="tooltip-bidPriceTargeting"
                                        className="ml-1"
                                        style={{display: 'inline'}}
                                        iconStyle={{fontSize: '1.2rem'}}
                                    >
                                        Amazon recommends a minimum bid price of $1 per campaign but we recommend higher bid prices for high traffic times of year.
                                    </HelpTooltip>
                                </Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <div className="input-group-text">
                                            <FontAwesomeIcon icon={faDollarSign}/>
                                        </div>
                                    </InputGroupAddon>
                                    <CustomNumInput name="bidPriceTargeting" tag={Field} min={0.02} precision={2} step={0.10} style={{wrap: {flex: 'auto'}}} />
                                </InputGroup>
                            </FormGroup>
                        }
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        sellerProfileIdList: state.loggedUser.sellerProfileIdList,
        adProfiles: state.accountOverview.landing.adProfiles,
    }
}

export default connect(mapStateToProps)(ProductInfoStep);
