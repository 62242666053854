import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadProductCategories } from "../../../Redux/Actions/Actions";
import { LoadResearchProductCategoryData } from "../../../Redux/Actions/Actions";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState } from 'react';

function LeadGenProdCatJobDataToolService(token, jobId) {

    const ExportCSV = (csvData, fileName) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        console.log('Download Called')
        const exportToCSV = (csvData, fileName) => {
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData.productList);
            const ws1 = XLSX.utils.json_to_sheet(csvData.sellerList);
            const ws2 = XLSX.utils.json_to_sheet(csvData.productSalesEstimatesList);
            const ws3 = XLSX.utils.json_to_sheet(csvData.top100productAnalysisList);
            const ws4 = XLSX.utils.json_to_sheet(csvData.jobSummaryData);   
            const ws5 = XLSX.utils.json_to_sheet(csvData.jobHistoryData); 
                
            const wb = { Sheets: {'jobSummaryData':ws4, 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2,'top100productAnalysisList': ws3,'jobHistoryData':ws5 }, SheetNames: ['jobSummaryData','productList','sellerList','productSalesEstimatesList','top100productAnalysisList','jobHistoryData'] };                
                   
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, 'MarketResearchData' + fileExtension);   
                           
            };           
            exportToCSV(csvData, fileName); 
        }       

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_PRODUCT_CATEGORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/ViewLeadGeneratiobJobResearchData/${jobId}`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            console.log(res.data)        
            dispatch(LoadResearchProductCategoryData(res.data),
            ExportCSV(res.data,'MarketResearchData'))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED });

        });
    }
}

export default LeadGenProdCatJobDataToolService;