import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'antd/dist/antd.css';
import { ToastContainer } from "react-toastify";
import axios from 'axios';
import { connect } from 'react-redux';

import MasterRoutes from "./MasterRoutes";
import './assets/base.scss';
import { CURRENT_STRIPE_PK } from './Utils/Constants';
import './App.css';
import './CustomStyle.css';
import { LogoutUser } from "./Redux/Actions/Actions";
import notify from 'Notifications/ToastifyActions';


const stripePromise = loadStripe(CURRENT_STRIPE_PK);

function App(props) {

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://growsurf.com/growsurf.js?v=2.0.0';
    //     script.setAttribute('grsf-campaign', 'deddph');
    //     script.async = true;
    //     document.head.appendChild(script);
    // });

    // ensures that the user is logged out upon receiving any 401 responses
    axios.interceptors.response.use(response => response, error => {
        if (error.response && error.response.status === 401) {
            props.dispatch(LogoutUser());
            notify.NotifyError("Your session has expired, please login first.");
        } else {
            return Promise.reject(error);
        }
    })


  return (
      <Elements stripe={stripePromise}>
          <div className="App">
              <Router>
                  <MasterRoutes />
              </Router>
              <ToastContainer
                  position="bottom-center"
                  autoClose={8000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
          </div>
      </Elements>

  );
}

export default connect()(App);
