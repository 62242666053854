import Swarm from '../assets/img/Swarm.png';
import Explore from '../assets/img//Explore.png';
import Defense from '../assets/img//Defense.png';

const products = {
    keys: ['SM', 'EN', 'DE'],
    SM: {
        short: 'SM',
        long: 'Swarm',
        active: true,
        image: Swarm,
        // live
        // priceId: 'price_1H6JVbGIqnst6pthCVDsPxZ8',
        // test
        priceId: 'price_1H6Jt6GIqnst6pthptGDJcak',
        // new Live
        // priceId: 'price_1HPi4EGIqnst6pth30CN2j25',
        // live with trial
        //priceId: 'price_1HPi5oGIqnst6pthqRDmGiyu',
        cost: 14,
        description: 'An aggressive campaign style best for driving impressions and ranks. Use this campaign to launch new products, scale your winners, or to move stale inventory.',
        color: 'success',
    },
    EN: {
        short: 'EN',
        long: 'Exploration',
        active: true,
        image: Explore,
        // live
        // priceId: 'price_1H6JWEGIqnst6pthdmKuIBRY',
        // test
        priceId:'price_1H6JtQGIqnst6pthp8IwBh8K',
        // live with trial
        //priceId: 'price_1HPi6IGIqnst6pth4myFrKQN',
        cost: 9,
        description: 'A balanced campaign best for discovering new selling opportunities and improving ranks of trending keyword themes. Use this campaign to test for additional growth opportunities.',
        color: 'warning',
    },
    DE: {
        short: 'DE',
        long: 'Defensive',
        active: true,
        image: Defense,
        // old live
        //priceId: 'price_1H6JX0GIqnst6pthPKQ4h2nU',
        // test
        priceId: 'price_1H6JtiGIqnst6pth44WutEH7',
        // new live
        //priceId: 'price_1HPi52GIqnst6pthpKwbQ2kZ',
        // live with trial
        //priceId: 'price_1HPi5oGIqnst6pthqRDmGiyu',
        cost: 14,
        description: 'A conservative campaign style best for defending organic positions and gradually improving those keyword ranks.',
        color: 'danger',
    },
}


export default products;

