import React, { useState, useEffect } from 'react';
import {Button, Card, CardBody, CardHeader,Col,Container,Label,Row,Collapse} from "reactstrap";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faUser,
  faEnvelope,
  faKey,
  faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import { Fragment } from 'react';
import PersonalInfo from './Components/PersonalInfo';
import ContactInfo from './Components/ContactInfo';
import SecurityInfo from './Components/SecurityInfo';
import SubscriptionInfo from './Components/SubscriptionInfo';
function UserProfileViewPage(props) {

    const [personalInfo, setPersonalInfoPage] = useState(true)
    const [contactInfo, setContactInfoPage] = useState(false)
    const [securityInfo, setSecurityInfoPage] = useState(false)
    const [subscriptionInfo, setSubscriptionInfoPage] = useState(false)


    const renderPersonalInfoPage = ()=>{
        return(
            <PersonalInfo/>
        )
    }
    const renderContactInfoPage = ()=>{
        return(
            <ContactInfo/>
        )
    }
    const renderSecurityInfoPage = ()=>{
        return(
            <SecurityInfo/>
        )
    }
    const renderSubscriptionInfoPage = ()=>{
        return(
            <SubscriptionInfo/>
        )
    }
    const pageNotFound = ()=>{
        return(
            <h2>Page Not Found. </h2>
        )
    }
    return (
      <Fragment >
        <Container fluid id="main-user-profile">
            <Row className='mt-3'>
                
                <Col md={2} className='right-border '>
                <div className='right-b'>
                    <div className='mt-2'>
                        {/* <h6 className='mb-20'>Home</h6> */}
                        <h6 className='mb-20'>User Profile</h6>
                        <ul className='sub-options'>
                            <a href='#personal'>
                            <li className={personalInfo? 'mb-20 list-color selected-list-option' : 'mb-20 list-color'}
                                onClick={()=>{
                                    setPersonalInfoPage(true)
                                    setContactInfoPage(false)
                                    setSecurityInfoPage(false)
                                    setSubscriptionInfoPage(false)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className={personalInfo ?"sidebar-icon selected-list-option":"sidebar-icon" }
                                />
                                Personal Info</li>
                            </a>
                           <a href='#contact'>
                           <li className={contactInfo? 'mb-20 list-color selected-list-option' : 'mb-20 list-color'}
                                onClick={()=>{
                                    setPersonalInfoPage(false)
                                    setContactInfoPage(true)
                                    setSecurityInfoPage(false)
                                    setSubscriptionInfoPage(false)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className={contactInfo ?"sidebar-icon selected-list-option":"sidebar-icon"}
                                />
                                Contact Info</li>
                           </a>
                            <a href='#security'>
                            <li className={securityInfo? 'mb-20 list-color selected-list-option' : 'mb-20 list-color'}
                                onClick={()=>{
                                    setPersonalInfoPage(false)
                                    setContactInfoPage(false)
                                    setSecurityInfoPage(true)
                                    setSubscriptionInfoPage(false)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faKey}
                                    className={securityInfo ?"sidebar-icon selected-list-option":"sidebar-icon"}
                                />
                                Security Info</li>
                            </a>
                           {/*  <a href='#subscription'>
                            <li className={subscriptionInfo? 'mb-20 list-color selected-list-option' : 'mb-20 list-color'}
                                onClick={()=>{
                                    setPersonalInfoPage(false)
                                    setContactInfoPage(false)
                                    setSecurityInfoPage(false)
                                    setSubscriptionInfoPage(true)
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faFileInvoiceDollar}
                                    className={subscriptionInfo ?"sidebar-icon selected-list-option":"sidebar-icon"}
                                />
                                Subscription Info</li>
                                </a> */}
                        </ul>
                    </div>
                    </div>
                </Col>
                
                <Col Col md={10}>
                    {/* {
                        personalInfo?renderPersonalInfoPage():
                        contactInfo?renderContactInfoPage():
                        securityInfo?renderSecurityInfoPage():
                        subscriptionInfo?renderSubscriptionInfoPage():pageNotFound()
                    } */}
                    <PersonalInfo/>
                </Col>
            </Row>
        </Container>
      </Fragment>
    );
  };

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    stores: state.accountOverview.landing.adProfiles,
})

export default connect(mapStateToProps)(UserProfileViewPage);
