import React, { useState, Fragment } from 'react';
import ReactQuill from 'react-quill';
import { Button } from 'reactstrap';
import 'react-quill/dist/quill.snow.css';
import CommentsOnPRoject from './CommentsOnProject';
import { Editor } from '../../../../../../Utils/Constants';
import { toast } from 'react-toastify';


const AmazonProjectViewComments = ({ postComments, fieldLevelComments, loading, isRevised }) => {

    const [htmlValue, setHtmlValue] = useState('')
    const [error, setError] = useState('');

    const commentChange = (e) => {
        if (!e)
            setError('This field is required')
        else {
            setError('');
        }
        setHtmlValue(e)
    }


    const submitComents = () => {
        if (error || htmlValue.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            toast.error('This field is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return;
        }
        postComments(htmlValue, isRevised)
    }

    const validateField = (e) => {
        if (!e) {
            setError(`This field is required`)
        }
    }

    return (
        <Fragment>
            <div className="quill-prod">
                <ReactQuill value={htmlValue} onChange={(e) => commentChange(e)} theme={'snow'}

                    modules={Editor.modules}
                    formats={Editor.formats}
                    bounds={'.quill-prod'}
                    onBlur={(previousRange, source, editor) => {
                        // console.log(previousRange)
                        // console.log(source)
                        // console.log(editor.getText());
                        validateField(editor.getText().trim());
                    }}
                    placeholder='Enter your comments...' />
                {
                    error &&
                    <span className="text-danger">
                        This is field required
                    </span>
                }
            </div>
            <div className="my-2">
                {!loading && htmlValue ?


                    <Button color="primary" className="float-right" type="button" onClick={submitComents}>{isRevised ? 'Revise' : 'Submit'}</Button> :
                    <Button color="primary" className="float-right" disabled type="button">{isRevised ? 'Revise' : 'Submit'}</Button>}

            </div>
            <br />
            <CommentsOnPRoject comments={fieldLevelComments} />
        </Fragment>
    )
}


export default AmazonProjectViewComments;
