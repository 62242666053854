import React from "react";
import { connect } from 'react-redux';

import urls from '../../../../../../Utils/URLs';
import DashboardLayout from "../../../../Layout/DashboardLayout";
import Can from "../../../../../../Scenes/Components/Can";
import StoreOverviewSidebar from "./StoreOverviewSidebar";


function StoreOverviewLayout(props) {

    const menuItems = [
        {
            icon: 'pe-7s-home',
            label: 'My Stores',
            to: urls.STORE_OVERVIEW_MY_STORES_URL,
        },
    ];

    const menuItemsStoreList = props.stores.length === 0 ? [
        {
            icon: 'pe-7s-home',
            label: 'My Stores',
            to: urls.STORE_OVERVIEW_MY_STORES_URL,
        },
    ]
    :
    [
        {
            icon: 'pe-7s-home',
            label: 'My Stores',
            content:  props.stores.map(store => ({
                    icon: 'pe-7s-home',
                    label: `${store.name} (${store.location})`,
                    to: `${urls.STORE_OVERVIEW_MY_STORES_URL}/${store.integerID}`,
                }))
        },
    ];

    // const menuItemsStoreList = props.stores.length === 0 ? [
    //         {
    //             icon: 'pe-7s-home',
    //             label: 'My Stores',
    //             to: urls.ACCOUNT_OVERVIEW_MY_STORES_URL,
    //         },
    //     ]
    //     :
    //     props.stores.map(store => ({
    //         icon: 'lnr-store icon-gradient bg-simpli-gradient',
    //         label: `${store.name} (${store.location})`,
    //         to: `${urls.ACCOUNT_OVERVIEW_MY_STORES_URL}/${store.name}/${store.location}`,
    //     }));

    return (
        <Can
            role={props.accessLevel}
            perform={"home:simple"}
            yes={() =>
            //     <DashboardLayout
            //     menuItems={menuItemsStoreList}
            //     heading="Store Overview 1"
            //     icon="lnr-map text-info"
            //     subheading="View all of your Store information in one place!"
            // >
            //     {props.children}
            // </DashboardLayout>
            // <UserDashboardSidebar />
                // <StoreOverviewSidebar
                // menuItems={menuItemsStoreList}
                // >
                    <>{props.children}</>
                // </StoreOverviewSidebar>
            }

            no={() =>
                <DashboardLayout
                    menuItems={menuItems}
                    heading="Store Overview 2"
                    icon="lnr-map text-info"
                    subheading="View all of your Store information in one place!"
                >
                    {props.children}
                </DashboardLayout>}
            />
    );
}

const mapStateToProps = state => ({
    stores: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(StoreOverviewLayout);
