import React from "react";
import ReactTable from "react-table";


function ParentProductKeywordTable(props) {
    const columns = [
        {
            Header: 'Keyword Text',
            accessor: 'keywordText',
        },
        // {
        //     Header: 'Match Type',
        //     accessor: 'matchType',
        // },
        // {
        //     Header: 'Cost',
        //     accessor: 'cost',
        //     Cell: props => !isNaN(props.value) ? `$ ${parseInt(props.value).toFixed(2)}` : 'unavailable',
        // },
        {
            Header: "Initial Rank",
            accessor: "initialRank",
        },
        {
            Header: "Current Rank",
            accessor: "currentRank",
            Cell: cellInfo => isNaN(cellInfo.value) ?
                    "unavailable"
                : (
                    <>
                        {cellInfo.value}
                        <div
                            className={cellInfo.original.changeInRank > 0 ? "ml-3 text-success" : cellInfo.original.changeInRank < 0 ? "ml-3 text-danger" : "ml-3 text-warning"}
                        >
                            <i className={cellInfo.original.changeInRank > 0 ? "lnr-chevron-up-circle" : cellInfo.original.changeInRank < 0 ? "lnr-chevron-down-circle" : "lnr-circle-minus"}> </i>
                            {cellInfo.original.changeInRank}
                        </div>

                    </>
                )
        },
        // {
        //     Header: 'Average Rank',
        //     accessor: 'averageRank',
        //     Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        // },
        {
            Header: 'Average Rank',
            accessor: 'averageRank',
            Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        },
        // {
        //     Header: 'ROAS',
        //     accessor: 'ROAS',
        //     Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        // },
        // {
        //     Header: 'Sales',
        //     accessor: 'numSales',
        //     Cell: props => !isNaN(props.value) ? `$ ${parseInt(props.value).toFixed(2)}` : 'unavailable',
        // },
        {
            Header: 'Conversions',
            accessor: 'numConversions',
            Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        },
        // {
        //     Header: 'Impressions',
        //     accessor: 'numImpressions',
        //     Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        // },
        // {
        //     Header: 'Clicks',
        //     accessor: 'numClicks',
        //     Cell: props => isNaN(props.value) ? "unavailable" : props.value,
        // },
    ]

    return (
        <ReactTable
            data={props.data}
            columns={columns}
            style={{
                height: "500px"
            }}
            className="-striped -highlight -fixed"
            noDataText="No keywords found"
        />
    )
}

export default ParentProductKeywordTable;