import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';

import ContentAgencyListService
    from "../../../../../../Service/DashboardServices/UserProfileServices/ContentAgencyListService";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import ContentAgencyTable from "./Components/ContentAgencyTable";
import CustomModal from "../../../../../Components/Modals/CustomModal";
import AddContentAgencyService
    from "../../../../../../Service/DashboardServices/UserProfileServices/AddContentAgencyService";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import Can from "../../../../../Components/Can";
import ProjectsTable from "./Components/ProjectsTable";
import ProjectsListService from "../../../../../../Service/DashboardServices/UserProfileServices/ProjectsListService";
import AssignContentAgencyService
    from "../../../../../../Service/DashboardServices/UserProfileServices/AssignContentAgencyService";


function ContentAgenciesPage(props) {

    useEffect(() => {
        props.dispatch(ContentAgencyListService(props.token));
        props.dispatch(ProjectsListService(props.token));
    }, [])

    const [isOpen, setIsOpen] = useState(false);
    const [newAgencyName, setNewAgencyName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleAddAgency = async () => {
        setLoading(true);
        let response = AddContentAgencyService(props.token, newAgencyName);
        if (response) {
            setIsOpen(!isOpen);
        }
        setLoading(false);
        props.dispatch(ContentAgencyListService(props.token));
    };

    const handleAssignAgency = async (projectName, agency) => {
        let response = await AssignContentAgencyService(props.token,
            projectName, agency.contentAgencyMasterId, agency.contentAgencyName);

        if (response) {
            props.dispatch(ProjectsListService(props.token));
        }
    }

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Content Agencies</h4>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Can
                                role={props.accessLevel}
                                perform={'contentAgencies:create'}
                                yes={() => (
                                    <Button
                                        className="mb-3"
                                        color="brand-blue-dark"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        Add Content Agency
                                    </Button>
                                )}
                            />
                            <Card>
                                <CardBody>
                                    <ContentAgencyTable data={props.contentAgencies} />
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>
                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Assign Projects</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                    <ProjectsTable
                                        data={props.projects}
                                        contentAgencies={props.contentAgencies}
                                        assignAgency={handleAssignAgency}
                                    />
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>
                <CustomModal
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                    header={"New Content Agency"}
                    footer={
                        <Fragment>
                            <Button color="link" onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
                            <LoadingButton disabled={newAgencyName === ''} loading={loading} color="success" onClick={() => handleAddAgency()}>Add Content Agency</LoadingButton>
                        </Fragment>
                    }
                >
                    <Label for="contentAgencyName">Agency Name</Label>
                    <Input id="contentAgencyName" value={newAgencyName} onChange={(e) => setNewAgencyName(e.target.value)} />
                </CustomModal>
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    contentAgencies: state.profile.contentAgencies,
    projects: state.profile.projects,
    status: state.profile.status,
})

export default connect(mapStateToProps)(ContentAgenciesPage);
