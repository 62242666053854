import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import notify from '../../../Notifications/ToastifyActions';
import { LoadDirectories } from "../../../Redux/Actions/Actions";


function UserStoreDirectoryService(token) {

    return function (dispatch) {
        dispatch({ type: actionType.LOAD_DIRECTORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/campaign/userDirectory`, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadDirectories(res.data.userFirstDirectory,res.data.storeFirstDirectory,res.data.managedStoreFirstDirectory,res.data.unmanagedStoreFirstDirectory,res.data.allProductsDirectory))
        }).catch(error => {
            dispatch({ type: actionType.LOAD_DIRECTORIES_FAILED });
        });
    }
}

export default UserStoreDirectoryService;