import React, { Fragment, useEffect, useState } from "react";
import {Row, Col, Container, Jumbotron, Button, Progress, Collapse} from 'reactstrap';
import ProjectItem from './ProjectItem';
import { useParams, Link } from "react-router-dom";
import { connect } from 'react-redux';
import URLs from "../../../../../../Utils/URLs";
import actionType from '../../../../../../Redux/Actions/ActionTypes';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { getAmazonOnBoardProjects } from '../../../../../../Service/DashboardServices/Projects/ProjectsService';
import CustomerDetails from "../../AgencyProjectView/CustomerDetails/CustomerDetails";

const ViewProjects = (props) => {

    const { id } = useParams();

    const [showStartProjectBtn, setShowStartProjectBtn] = useState(false);
    // const [isBrandProfileOpen, setIsBrandProfileOpen] = useState(false);

    useEffect(() => {
        if (id && props.storeDetails.length > 0) {
            // props.dispatch({
            //     type: actionType.SET_CURRENT_STORE,
            //     payload: { id }
            // })

            getAmazonOnBoardProjects(props.token, id, props.dispatch)

        }

        // if (id && props.token) {
        //     getAmzazonOnBoardProjects(props.token, id, props.dispatch);
        // }

    }, [id, props.storeDetails])


    //console.log(params)

    const refreshData = () => {
        getAmazonOnBoardProjects(props.token, id, props.dispatch)
    }

    const enableStartProjectBtn = (status) => {
      setShowStartProjectBtn(status)
    }

    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row>
                        {/*<Col md="12">*/}
                        {/*    <h4 className="mb-2">Brand Profile : {props.currentStore ? props.currentStore.name : ''}</h4>*/}
                        {/*    /!* { props.adProfiles.length === 0 &&*/}
                        {/*            <p className="invalid-feedback" style={{display: 'block', fontSize: '100%'}}>*/}
                        {/*                We could not find any profile IDs associated with your Amazon Seller account,*/}
                        {/*                please create a profile in Amazon Seller Central.*/}
                        {/*            </p>*/}
                        {/*        } *!/*/}
                        {/*</Col>*/}
                        <Col md="12" className="mbg-2 font-size-xlg">
                            <span>
                                { props.currentStore && props.currentStore.logo ?
                                    <img className="mr-2" width={100} src={props.currentStore.logo} alt="" />
                                    :
                                    <i className="lnr-store icon-gradient bg-simpli-gradient mr-2" style={{fontSize: "40px"}}>
                                    </i>
                                }
                                <h5 className="text-brand-blue-dark" style={{display: "inline", fontSize: "2.3rem"}}>{props.currentStore ? props.currentStore.name : ""}</h5>
                                {
                                    showStartProjectBtn ?
                                        <Button className="ml-5 mb-2" type="button" tag={Link} to={URLs.CREATE_PROJECTS_URL + '/' + props.currentStore.integerID} color="primary">Start a new Project</Button>
                                        :
                                        <Button className="ml-5 mb-2" type="button"  color="secondary" disabled title="Please complete brand profile sections to start a new project">Start a new Project</Button>
                                }
                            </span>

                        </Col>
                        {/*<Col xl="3" lg="4" md="6" className="mt-2">*/}
                        {/*    {*/}
                        {/*        showStartProjectBtn ?*/}
                        {/*            <Button type="button" tag={Link} to={URLs.CREATE_PROJECTS_URL + '/' + props.currentStore.integerID} color="primary">Start a new Project</Button>*/}
                        {/*            :*/}
                        {/*            <Button type="button"  color="secondary" disabled title="Please complete brand profile sections to start a new project">Start a new Project</Button>*/}
                        {/*    }*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="my-2 brand-info">
                                {
                                    props.currentStore &&

                                    <Jumbotron className="bg-white shadow">
                                        <Row>
                                            <Col md="12">
                                                <h4>Brand Profile</h4>
                                            </Col>
                                            {/*<Col md="2">*/}
                                            {/*    <img src={props.currentStore.logo ? props.currentStore.logo : 'https://getuikit.com/v2/docs/images/placeholder_600x400.svg'} className="img-fixed" />*/}
                                            {/*</Col>*/}
                                            {/*<Col md="3">*/}
                                            {/*    <h6 className="display-4">{props.currentStore.storeName}</h6>*/}
                                            {/*</Col>*/}
                                            {/*<Col md="4">*/}
                                            {/*    <p>Brand Profile Status: </p>*/}
                                            {/*    /!* <Row>*/}
                                            {/*      <Col md="10">*/}
                                            {/*        <Progress color="success" value="85" title="85 %" />*/}
                                            {/*      </Col>*/}
                                            {/*      <Col md="2">*/}
                                            {/*        <p className="bold">85 %</p>*/}
                                            {/*      </Col>*/}
                                            {/*    </Row> *!/*/}
                                            {/*</Col>*/}
                                            {/*<Col md="3" className="mt-4">*/}
                                            {/*    {*/}
                                            {/*      showStartProjectBtn ?*/}
                                            {/*      <Button type="button" tag={Link} to={URLs.CREATE_PROJECTS_URL + '/' + props.currentStore.integerID} color="primary">Start a new Project</Button>*/}
                                            {/*      :*/}
                                            {/*      <Button type="button"  color="secondary" disabled title="Please complete brand profile sections to start a new project">Start a new Project</Button>*/}
                                            {/*    }*/}

                                            {/*</Col>*/}

                                            <Col md="12">
                                                <CustomerDetails token={props.token} storeId={id} accessLevel={props.accessLevel} refreshData={refreshData} enableStartProjectBtn={enableStartProjectBtn}/>
                                                {/*<Collapse isOpen={isBrandProfileOpen}>*/}
                                                {/*    <CustomerDetails token={props.token} storeId={id} accessLevel={props.accessLevel} refreshData={refreshData} enableStartProjectBtn={enableStartProjectBtn}/>*/}
                                                {/*</Collapse>*/}
                                                {/*<Button onClick={() => setIsBrandProfileOpen(!isBrandProfileOpen)}>Open</Button>*/}

                                            </Col>
                                        </Row>


                                    </Jumbotron>
                                }

                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <LoadingOverlay
                                active={props.loading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: '#f1f4f6',
                                        opacity: 1
                                    }),
                                    content: (base) => ({
                                        ...base,
                                        color: "#000"
                                    })
                                }}
                                spinner={<Loader active type="ball-pulse" />}
                                text='Loading...'
                            >
                                <div className="product-items">
                                    {props.products.length > 0 &&
                                        <Fragment>
                                            <Row>
                                                {props.products.map((p, index) => {
                                                    return (<Col md="6" key={index}>
                                                        <ProjectItem project={p} accessLevel={props.accessLevel} />
                                                    </Col>)
                                                })}
                                            </Row>

                                        </Fragment>
                                    }
                                    {(props.products.length === 0 && !props.loading) &&
                                        <div className="text-danger text-center p-2">
                                            No Projects found
                                        </div>
                                    }

                                </div>
                            </LoadingOverlay>

                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    )
}

const mapToProps = (state) => {
    return {
        products: state.project.projects,
        loading: state.project.loading,
        currentStore: state.project.currentStore,
        token: state.loggedUser.token,
        user: state.loggedUser.user,
        storeDetails: state.project.storeDetails,
        accessLevel: state.loggedUser.accessLevel
    }
}

export default connect(mapToProps)(ViewProjects);
