import axios from "axios";
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function GetWorkflowMainCategoryService(token) {
    
    return axios.post(`${CURRENT_API_URL}/workflows/getAllMasterWorkflows`,'',{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            return res.data;
        }).catch(error => {  
            console.log(error)            
            notify.NotifyError("An error occurred while fetching master workflows, please try again later.");
            return false;
        
        });
}

export default GetWorkflowMainCategoryService