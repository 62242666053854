import React, {useEffect, useState} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { CURRENT_STRIPE_PK } from "../../../../../../Utils/Constants";
import pricingOptions from "../../../../../../Utils/PricingOptions";
import PricingOptionsCard from "./Components/PricingOptionsCard";
import notify from '../../../../../../Notifications/ToastifyActions';
import CreateSubscriptionService from "../../../../../../Service/PaymentServices/CreateSubscriptionService";
import CustomerPortalService from "../../../../../../Service/PaymentServices/CustomerPortalService";


const stripePromise = loadStripe(CURRENT_STRIPE_PK);

function PricingPage(props) {

    let history = useHistory();

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            notify.NotifySuccess("Success! Your subscription will be updated soon.");
        }
        if (query.get("canceled")) {
            notify.NotifyError("Your order has been canceled.");
        }
    }, []);

    const [pricingInterval, setPricingInterval] = useState('monthly');

    const handleCheckoutRedirect = async (priceID) => {
        const stripe = await stripePromise;
        const session = await CreateSubscriptionService(props.token, priceID);
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            notify.NotifyError(result.error.message);
        }
    }

    // const handleCustomerPortalRedirect = async () => {
    //     const portalURL = await CustomerPortalService(props.token);
    //     if (portalURL) {
    //         window.open(`${portalURL}`, "_self")
    //     }
    // }

    return (
        <>
            <Row className="mb-2">
                {/* <Col className={"mb-3"} md={{offset: 5}}>
                    <ButtonGroup size={"lg"}>
                        <Button
                            outline
                            color={"brand-blue-dark"}
                            onClick={() => setPricingInterval('monthly')}
                            active={pricingInterval === 'monthly'}
                        >
                            Monthly
                        </Button>
                        <Button
                            outline
                            color={"brand-blue-dark"}
                            onClick={() => setPricingInterval('annual')}
                            active={pricingInterval === 'annual'}
                        >
                            Annual
                        </Button>
                    </ButtonGroup>
                </Col> */}
                {/*<Col md={{offset: 3}}>*/}
                {/*    <Button*/}
                {/*        color={"link"}*/}
                {/*        onClick={handleCustomerPortalRedirect}*/}
                {/*    >*/}
                {/*        Manage Billing*/}
                {/*    </Button>*/}
                {/*</Col>*/}
            </Row>
            <Row>
                {
                    pricingOptions.map((bundle, idx) => (
                        <Col md={`${12 / pricingOptions.length}`} key={idx}>
                            <PricingOptionsCard
                                {...bundle}
                                monthly={pricingInterval === 'monthly'}
                                handleCheckout={handleCheckoutRedirect}
                                subscriptionLevel={props.subscriptionLevel}
                            />
                        </Col>
                    ))
                }
            </Row>

        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    subscriptionLevel: state.loggedUser.subscriptionLevel,
})

export default connect(mapStateToProps)(PricingPage);