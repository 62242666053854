import axios from 'axios';
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadStoreData } from "../../../../Redux/Actions/Actions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';

 
function GetCatalogueProductDetailsService(token,asin,profileId) {
    return axios.post(`${CURRENT_API_URL}/campaign/getCatalogueProductDetails`, {
        asin: asin,
        profileId: profileId,      
    }, {
        headers: {
            'Authorization': token,
        },
    }).then((res) => {
        console.log('Medha')
        console.log(Object.keys(res.data).length)
        if(Object.keys(res.data).length != 0){
            notify.NotifySuccess("Product Catalogue Details fetched successfully.");
            return res.data;
        }
        else{
            notify.NotifySuccess("Product Catalogue Details not available.");
            return false;
        }
    }).catch(() => {
        notify.NotifyError("Error while fetching Product Catalogue Details.");
        return false;
    });
}

export default GetCatalogueProductDetailsService;