import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function AddContentAgencyService(token, agencyName) {

    return axios.post(`${CURRENT_API_URL}/account/createAndViewContentAgencies`, {
        agencyName: agencyName,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(() => {
        notify.NotifySuccess("Content Agency was successfully added!");
        return true;
    }).catch(() => {
        notify.NotifyError("Error while adding new content agency, please try again.");
        return false;
    })
}

export default AddContentAgencyService;