import React from 'react';
import { Col } from "reactstrap";
import { Switch, Route } from 'react-router-dom'

import urls from '../../../../Utils/URLs';
import PasswordResetForm from "./Components/PasswordResetForm";
import PasswordConfirmForm from "./Components/PasswordConfirmForm";


function AccountRecoveryPage() {
    return (
        <div className="h-100  loginPage bg-animation">
            <div className="d-flex h-100 bg-img justify-content-center align-items-center">
                <Col md="8" className="mx-auto app-login-box">
                    <div className="modal-dialog w-100 mx-auto">
                        <Switch>
                            <Route exact path={urls.ACCOUNT_RECOVERY_PASSWORD_RESET} component={PasswordResetForm} />
                            <Route exact path={`${urls.ACCOUNT_RECOVERY_PASSWORD_CONFIRM}/:token`} component={PasswordConfirmForm} />
                        </Switch>
                    </div>
                    <div className="text-center text-white opacity-8 mt-3">
                        Copyright &copy; Simpliworks Incorporated 2023
                    </div>
                </Col>
            </div>
        </div>
    );
}

export default AccountRecoveryPage;
