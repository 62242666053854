import React from 'react';
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";
import {Col, Row} from "reactstrap";


function DeleteSubAccountModal({ isOpen, toggle, onSubmit, loading, account }) {
    return (
        <CustomModal
            isOpen={isOpen}
            toggle={toggle}
            footer={
            <LoadingButton
                color={"danger"}
                onClick={() => onSubmit(account)}
            >
                Delete Account
            </LoadingButton>
        }
        >
            <Row>
                <Col>
                    <h5>Are you sure you want to delete this sub-account?</h5>
                </Col>
            </Row>
        </CustomModal>
    )
}

export default DeleteSubAccountModal;