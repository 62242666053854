import React from 'react';
import ReactTable from 'react-table';


function RankTrackingStatusTable(props) {

    const columns = [
        {
            Header: "Keyword",
            accessor: "keyword",
        },
        {
            Header: "Page 1",
            accessor: "page1collected",
            Cell: cellInfo => cellInfo.value ? "True" : "False",
        },
        {
            Header: "Page 2",
            accessor: "page2collected",
            Cell: cellInfo => cellInfo.value ? "True" : "False",
        },
        {
            Header: "Date",
            accessor: "date",
            Cell: cellInfo => `${new Date(cellInfo.value).toLocaleDateString('en-US',
                {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }
            )}`,
        },
    ];

    return (
        <ReactTable
            columns={columns}
            data={props.data}
            className="-striped -highlight -fixed"
            noDataText={"No data found."}
            style={{height: "500px"}}
        />
    )
}

export default RankTrackingStatusTable;