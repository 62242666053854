import React, {useEffect, useState} from 'react';
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Row} from "reactstrap";
import Select from 'react-select';
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";


const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
    { value: 'blue', label: 'Blue', color: '#0052CC', disabled: true },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];


function ManageSubAccountStoresModal({ isOpen, toggle, onSubmit, assignedStores, unAssignedStores, allStores, selectedSubAccount, loading }) {

    const [storesToAssign, setStoresToAssign] = useState([]);
    const [storesToRemove, setStoresToRemove] = useState([]);

    useEffect(() => {
        setStoresToAssign([]);
        setStoresToRemove([]);
    }, [selectedSubAccount])

    return (
        <CustomModal
            isOpen={isOpen}
            toggle={toggle}
            size="md"
            header={"Manage Sub-Account Stores"}
            footer={
                <LoadingButton
                    onClick={() => onSubmit(storesToAssign, storesToRemove)}
                    color={"success"}
                    loading={loading}
                >
                    Submit Changes
                </LoadingButton>
            }
        >
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle>Assigned Stores</CardTitle>
                    <CardBody>
                        <Row>
                            {
                                assignedStores.length !== 0 ?

                                    assignedStores.map(assignedStore => (
                                        <Col md={"12"}>{assignedStore.label}</Col>
                                    ))
                                :
                                    <Col md={"12"}>No stores assigned.</Col>
                            }
                        </Row>
                    </CardBody>
                </CardBody>
            </Card>
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle>Assign Stores</CardTitle>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Select
                                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                                    isMulti
                                    name="addStores"
                                    options={unAssignedStores}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={storesToAssign}
                                    onChange={option => setStoresToAssign(option)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle>Remove Stores</CardTitle>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Select
                                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                                    isMulti
                                    name="removeStores"
                                    options={assignedStores}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={storesToRemove}
                                    onChange={option => setStoresToRemove(option)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </CustomModal>
    )
}

export default ManageSubAccountStoresModal;
