import React, { Fragment, useState, useEffect } from 'react';
import { SideBySideMagnifier } from 'react-image-magnifiers';
import {
    Button, UncontrolledPopover,
    PopoverHeader, PopoverBody, FormGroup, Label, Input, Popover
} from 'reactstrap';
import { toast } from 'react-toastify';
import ImagesFromCustomer from '../AmazonProductView/ImagesFromCustomer';
import Can, {check} from "../../../../../Components/Can";


const AmazonGalleryEdit = ({
    galleryPhotos,
    openComments,
    accessLevel,
    openFileUpload,
    deleteField,
    approveOrReviseProject,
    isFinalCompleted,
    sequenceOrderChange,
    imageUrls
}) => {

    const [curImg, setCurImg] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [fieldData, setFieldData] = useState(null);
    const [isEdit, setIsEdit] = useState(false)
    const [orderPopover, setOrderPopover] = useState(false);
    const [sequence, setSequence] = useState(0);
    const [ordering, setOrdering] = useState([]);
    const [imgPopover, setImgPopover] = useState(false)

    const toggleImgPopover = () => setImgPopover(!imgPopover)


    useEffect(() => {
        if (galleryPhotos && galleryPhotos.length > 0) {
            galleryPhotos = galleryPhotos.sort((a, b) => {
                return (a.field_sequence - b.field_sequence)
            })

            let tempOrdering = galleryPhotos.map(g => {
                return {
                    field_id: g.id,
                    order_no: g.field_sequence
                }
            })
            setOrdering(tempOrdering);

            setCurImg(galleryPhotos[0].field_value);
            setFieldData(galleryPhotos[0])
        }
    }, [galleryPhotos])

    const toggleOrderPopover = () => {
        if (!orderPopover) {
            setSequence(selectedIndex + 1);
        }
        setOrderPopover(!orderPopover)

    }

    const prevoius = (index) => {
        if (index === 0) {
            for (let i = galleryPhotos.length - 1; i >= 0; i--) {
                if (galleryPhotos[i].field_value) {
                    setSelectedIndex(i);
                    setCurImg(galleryPhotos[i].field_value);
                    setFieldData(galleryPhotos[i]);
                    break;
                }
            }
        }
        else {
            for (let i = index - 1; i >= 0; i--) {
                if (galleryPhotos[i].field_value) {
                    setSelectedIndex(i);
                    setCurImg(galleryPhotos[i].field_value);
                    setFieldData(galleryPhotos[i]);
                    break;
                }
            }
        }
    }

    const next = (index) => {
        if (index === galleryPhotos.length - 1) {
            for (let i = 0; i < galleryPhotos.length; i++) {
                if (galleryPhotos[i].field_value) {
                    setSelectedIndex(i);
                    setCurImg(galleryPhotos[i].field_value);
                    setFieldData(galleryPhotos[i]);
                    break;
                }
            }
        }
        else {
            for (let i = index + 1; i < galleryPhotos.length; i++) {
                if (galleryPhotos[i].field_value) {
                    setSelectedIndex(i);
                    setCurImg(galleryPhotos[i].field_value);
                    setFieldData(galleryPhotos[i]);
                    break;
                }
            }
        }
    }

    const previousClick = () => {

        prevoius(selectedIndex);
    }

    const nextClick = () => {

        next(selectedIndex);
    }

    const openCommentsModal = (fieldData) => {
        openComments(fieldData, selectedIndex)
    }

    const getApproveAllAccess = () => {
        let status = false;
        // if (accessLevel === 3) {
        //     if (galleryPhotos.length > 0)
        //         status = galleryPhotos.some(f => f.approved === false)
        // }
        if (check(accessLevel, "project:review")) {
            if (galleryPhotos.length > 0)
                status = galleryPhotos.some(f => f.approved === false)
        }


        return status
    }

    const approveSection = () => {
        approveOrReviseProject(undefined, 'APPROVED', 'GALLERY_PHOTOS')
    }

    const allApproved = () => {
        return galleryPhotos.every(g => g.approved)
    }

    const changeOrdering = () => {
        if (sequence > 0 && sequence <= galleryPhotos.length) {
            let updatedOrdering = [...swapElement([...ordering], selectedIndex, sequence - 1)]
            setOrdering(updatedOrdering);
            //console.log(updatedOrdering)
            sequenceOrderChange(updatedOrdering);
        }
        else {
            toast.error('Invalid Order number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    function swapElement(array, indexA, indexB) {
        // var tmp = array[indexA];
        // array[indexA] = array[indexB];
        // array[indexB] = tmp;

        var element = array[indexA];
        array.splice(indexA, 1);
        array.splice(indexB, 0, element);

        array = array.map((a, i) => {
            return {
                ...a,
                order_no: i + 1
            }
        })

        //arrangeActiveImg(array)
        return array

    }

    const revisionNeeded = () => {
        return galleryPhotos.some(g => g.status === 'REVISED')
    }

    const getCustomerImages = () => {
        return (
            <Fragment>
                <Can
                    role={accessLevel}
                    perform={"project:edit"}
                    yes={() => (
                        (imageUrls.length > 0) &&
                        <span className="mx-2">
                        (<Button color="link" id="customerImages2" type="button" className="p-0" title="Customer Images">
                            <i className="fas fa-user"></i>
                        </Button>)
                        <Popover placement="bottom" trigger="legacy" isOpen={imgPopover} target="customerImages2" popperClassName="shadow-lg" toggle={toggleImgPopover}>
                            <PopoverHeader>Customer Images</PopoverHeader>
                            <PopoverBody>
                                <ImagesFromCustomer images={imageUrls} />
                            </PopoverBody>
                        </Popover>
                    </span>
                    )}
                />
                {/*{*/}
                {/*    (accessLevel === 0 && imageUrls.length > 0) &&*/}
                {/*    <span className="mx-2">*/}
                {/*        (<Button color="link" id="customerImages2" type="button" className="p-0" title="Customer Images">*/}
                {/*            <i className="fas fa-user"></i>*/}
                {/*        </Button>)*/}
                {/*        <Popover placement="bottom" trigger="legacy" isOpen={imgPopover} target="customerImages2" popperClassName="shadow-lg" toggle={toggleImgPopover}>*/}
                {/*            <PopoverHeader>Customer Images</PopoverHeader>*/}
                {/*            <PopoverBody>*/}
                {/*                <ImagesFromCustomer images={imageUrls} />*/}
                {/*            </PopoverBody>*/}
                {/*        </Popover>*/}
                {/*    </span>*/}
                {/*}*/}
            </Fragment>
        )
    }


    return (
        <Fragment>
            <h4 className="heading">Gallery Photos {getCustomerImages()} {' '}
                {revisionNeeded() && <span className="badge badge-light-danger capitalize"><i className="fas fa-undo-alt badge-light-danger"></i>  Revision Needed</span>}
                {allApproved() && <span className="green-badge green-badge-approve"><i className="fas fa-check-circle badge-green-icon"></i>  Approved</span>}
                {(getApproveAllAccess() && !isFinalCompleted) && <Button type="button" color="link" onClick={approveSection} className="badge badge-approve fs-8 fw-bolder no-text-dec pointer">Approve All</Button>}
            </h4>
            <div className="d-flex">
                <ul className="thumb-cus">
                    {galleryPhotos.map((g, i) => (
                        <li key={i} className={(i === selectedIndex ? " border-highlight" : '')}>
                            <Button color="link" type="button" className="img-button" onMouseOver={() => { setCurImg(g.field_value); setFieldData(g); setSelectedIndex(i); }} ><img src={g.field_value} /></Button>

                            {
                                g.approved && <span style={{
                                    top: 0,
                                    right: 0,
                                    position: 'absolute',
                                    fontSize: '10px'
                                }}><i className="fas fa-check-circle green" title="Approved" /></span>
                            }

                            {
                                g.status === 'REVISED' && <span style={{
                                    top: 0,
                                    right: 0,
                                    position: 'absolute',
                                    fontSize: '10px',
                                    color: 'red'
                                }}><i className="fas fa-undo-alt" title="Revision Needed" /></span>
                            }

                        </li>
                    ))}
                    <Can
                        role={accessLevel}
                        perform={"project:edit"}
                        yes={() => (
                            (galleryPhotos.length < 9 && !isFinalCompleted) &&
                            <li >
                                <Button type="button" color="link" title="Add Image" onClick={() => {
                                    setIsEdit(false)
                                    let tempData = {
                                        fieldData: null,
                                        accept: ".jpg,.png,.jpeg",
                                        label: "",
                                        multiple: false,
                                        maxFileSizeInBytes: 20 * 1024 * 1024,
                                        maxFiles: 10 - galleryPhotos.length,
                                        name: 'galleryPhotos'
                                    }

                                    openFileUpload(tempData, 'Gallery Photo');
                                }}>
                                    <span><i className="fas fa-images t-5 f-23"></i></span>
                                </Button>
                            </li>
                        )}
                    />
                    {/*{*/}
                    {/*    (accessLevel === 0 && galleryPhotos.length < 9 && !isFinalCompleted) &&*/}
                    {/*    <li >*/}
                    {/*        <Button type="button" color="link" title="Add Image" onClick={() => {*/}
                    {/*            setIsEdit(false)*/}
                    {/*            let tempData = {*/}
                    {/*                fieldData: null,*/}
                    {/*                accept: ".jpg,.png,.jpeg",*/}
                    {/*                label: "",*/}
                    {/*                multiple: false,*/}
                    {/*                maxFileSizeInBytes: 20 * 1024 * 1024,*/}
                    {/*                maxFiles: 10 - galleryPhotos.length,*/}
                    {/*                name: 'galleryPhotos'*/}
                    {/*            }*/}

                    {/*            openFileUpload(tempData, 'Gallery Photo');*/}
                    {/*        }}>*/}
                    {/*            <span><i className="fas fa-images t-5 f-23"></i></span>*/}
                    {/*        </Button>*/}
                    {/*    </li>*/}
                    {/*}*/}
                </ul>
                <div style={{ position: 'relative' }}>
                    {
                        !isFinalCompleted && <Fragment>
                            {(fieldData && fieldData.field_value) &&
                                <Fragment>
                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            (fieldData.status === 'REVISED') &&
                                            <div className="deleteImage comment grey t-80 r-0">
                                                <Button color="link" type="button" onClick={() => openCommentsModal(fieldData)}>
                                                    <i className="fas fa-comments r-4 pos-ab t-0 comment-icon"></i>
                                                </Button>
                                            </div>
                                        )}
                                    />
                                    {/*{(accessLevel === 0 && fieldData.status === 'REVISED') &&*/}
                                    {/*    <div className="deleteImage comment grey t-80 r-0">*/}
                                    {/*        <Button color="link" type="button" onClick={() => openCommentsModal(fieldData)}>*/}
                                    {/*            <i className="fas fa-comments r-4 pos-ab t-0 comment-icon"></i>*/}
                                    {/*        </Button>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                    <Can
                                        role={accessLevel}
                                        perform={"project:review"}
                                        yes={() => (
                                            <Fragment>
                                                {!fieldData.approved &&
                                                <div className="deleteImage" style={{
                                                    top: '0',
                                                    right: '0'
                                                }}>
                                                    <Button color="link" type="button" onClick={() => {
                                                        approveOrReviseProject(fieldData, 'APPROVED', undefined)
                                                    }} title="Approve">
                                                        <i className="fas fa-check r-4 pos-ab t-1 green"></i>
                                                    </Button>
                                                </div>
                                                }
                                                {
                                                    fieldData.status !== 'REVISED' &&
                                                    <div className="deleteImage" style={{
                                                        top: '40px',
                                                        right: '0'
                                                    }}>
                                                        <Button color="link" type="button" onClick={() => {
                                                            openComments(fieldData, selectedIndex, true)
                                                        }} title="Revise">
                                                            <i className="fas fa-undo-alt r-4 pos-ab t-0 comment-icon"></i>
                                                        </Button>
                                                    </div>
                                                }

                                            </Fragment>
                                        )}
                                    />
                                    {/*{(accessLevel === 3) &&*/}
                                    {/*    <Fragment>*/}
                                    {/*        {!fieldData.approved &&*/}
                                    {/*            <div className="deleteImage" style={{*/}
                                    {/*                top: '0',*/}
                                    {/*                right: '0'*/}
                                    {/*            }}>*/}
                                    {/*                <Button color="link" type="button" onClick={() => {*/}
                                    {/*                    approveOrReviseProject(fieldData, 'APPROVED', undefined)*/}
                                    {/*                }} title="Approve">*/}
                                    {/*                    <i className="fas fa-check r-4 pos-ab t-1 green"></i>*/}
                                    {/*                </Button>*/}
                                    {/*            </div>*/}
                                    {/*        }*/}
                                    {/*        {*/}
                                    {/*            fieldData.status !== 'REVISED' &&*/}
                                    {/*            <div className="deleteImage" style={{*/}
                                    {/*                top: '40px',*/}
                                    {/*                right: '0'*/}
                                    {/*            }}>*/}
                                    {/*                <Button color="link" type="button" onClick={() => {*/}
                                    {/*                    openComments(fieldData, selectedIndex, true)*/}
                                    {/*                }} title="Revise">*/}
                                    {/*                    <i className="fas fa-undo-alt r-4 pos-ab t-0 comment-icon"></i>*/}
                                    {/*                </Button>*/}
                                    {/*            </div>*/}
                                    {/*        }*/}

                                    {/*    </Fragment>*/}
                                    {/*}*/}

                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            (!fieldData.approved) &&
                                            <Fragment>
                                                <div className="deleteImage tr-0">
                                                    <Button color="link" type="button" onClick={() => {
                                                        if (window.confirm(`Are you sure do you want to delete this file`))
                                                            deleteField(fieldData.id)
                                                    }}>
                                                        <i className="fas fa-trash-alt r-4 pos-ab grey t-1"></i>
                                                    </Button>
                                                </div>
                                                <div className="deleteImage sequence t-40 r-0">
                                                    <Button color="link" id="orderImg" type="button">
                                                        <i className="fas fa-images r-4 pos-ab grey t-1"></i>
                                                    </Button>
                                                    <UncontrolledPopover placement="bottom" isOpen={orderPopover} popperClassName="shadow-lg" trigger="legacy" target="orderImg" toggle={toggleOrderPopover}>
                                                        <PopoverHeader>Image Sequencing</PopoverHeader>
                                                        <PopoverBody>

                                                            <FormGroup>
                                                                <Label for="imgReordering">Enter Sequence No</Label>
                                                                <Input type="number" name="imgReordering" min="0" value={sequence} id="imgReordering" placeholder="Enter Sequence No" onChange={(e) => setSequence(e.target.value)} />
                                                            </FormGroup>

                                                            <Button type="button" color="primary" className="mt-2" onClick={() => { changeOrdering() }}>Submit</Button>


                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                </div>

                                                <div className="deleteImage t-120 r-0">
                                                    <Button onClick={() => {
                                                        setIsEdit(true)
                                                        let tempData = {
                                                            fieldData: fieldData,
                                                            accept: ".jpg,.png,.jpeg",
                                                            label: "",
                                                            multiple: false,
                                                            maxFiles: 10 - galleryPhotos.length,
                                                            name: 'galleryPhotos',
                                                            maxFileSizeInBytes: 20 * 1024 * 1024,
                                                        }

                                                        openFileUpload(tempData, 'Gallery Photo ' + (selectedIndex + 1));
                                                    }} color="link" title="Re Upload File">
                                                        <i className="fas fa-edit blue r-3 pos-ab t-1" />
                                                    </Button>
                                                </div>

                                            </Fragment>
                                        )}
                                    />

                                    {/*{(accessLevel === 0 && !fieldData.approved) &&*/}
                                    {/*    <Fragment>*/}
                                    {/*        <div className="deleteImage tr-0">*/}
                                    {/*            <Button color="link" type="button" onClick={() => {*/}
                                    {/*                if (window.confirm(`Are you sure do you want to delete this file`))*/}
                                    {/*                    deleteField(fieldData.id)*/}
                                    {/*            }}>*/}
                                    {/*                <i className="fas fa-trash-alt r-4 pos-ab grey t-1"></i>*/}
                                    {/*            </Button>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="deleteImage sequence t-40 r-0">*/}
                                    {/*            <Button color="link" id="orderImg" type="button">*/}
                                    {/*                <i className="fas fa-images r-4 pos-ab grey t-1"></i>*/}
                                    {/*            </Button>*/}
                                    {/*            <UncontrolledPopover placement="bottom" isOpen={orderPopover} popperClassName="shadow-lg" trigger="legacy" target="orderImg" toggle={toggleOrderPopover}>*/}
                                    {/*                <PopoverHeader>Image Sequencing</PopoverHeader>*/}
                                    {/*                <PopoverBody>*/}

                                    {/*                    <FormGroup>*/}
                                    {/*                        <Label for="imgReordering">Enter Sequence No</Label>*/}
                                    {/*                        <Input type="number" name="imgReordering" min="0" value={sequence} id="imgReordering" placeholder="Enter Sequence No" onChange={(e) => setSequence(e.target.value)} />*/}
                                    {/*                    </FormGroup>*/}

                                    {/*                    <Button type="button" color="primary" className="mt-2" onClick={() => { changeOrdering() }}>Submit</Button>*/}


                                    {/*                </PopoverBody>*/}
                                    {/*            </UncontrolledPopover>*/}
                                    {/*        </div>*/}

                                    {/*        <div className="deleteImage t-120 r-0">*/}
                                    {/*            <Button onClick={() => {*/}
                                    {/*                setIsEdit(true)*/}
                                    {/*                let tempData = {*/}
                                    {/*                    fieldData: fieldData,*/}
                                    {/*                    accept: ".jpg,.png,.jpeg",*/}
                                    {/*                    label: "",*/}
                                    {/*                    multiple: false,*/}
                                    {/*                    maxFiles: 10 - galleryPhotos.length,*/}
                                    {/*                    name: 'galleryPhotos',*/}
                                    {/*                    maxFileSizeInBytes: 20 * 1024 * 1024,*/}
                                    {/*                }*/}

                                    {/*                openFileUpload(tempData, 'Gallery Photo ' + (selectedIndex + 1));*/}
                                    {/*            }} color="link" title="Re Upload File">*/}
                                    {/*                <i className="fas fa-edit blue r-3 pos-ab t-1" />*/}
                                    {/*            </Button>*/}
                                    {/*        </div>*/}

                                    {/*    </Fragment>*/}
                                    {/*}*/}
                                </Fragment>


                            }
                        </Fragment>
                    }


                    {/* <img src={curImg} className="rounded p-p-10" /> */}
                    {
                        (curImg) &&
                        <SideBySideMagnifier
                            className="input-position"
                            style={{ order: "0" }}
                            imageSrc={curImg}
                            largeImageSrc={curImg}
                            alwaysInPlace={false}
                            overlayOpacity={0.6}
                            switchSides={false}
                            zoomPosition="left"
                            inPlaceMinBreakpoint={641}
                            fillAvailableSpace={true}
                            fillAlignTop={true}
                            fillGapTop={0}
                            fillGapRight={10}
                            fillGapBottom={10}
                            fillGapLeft={0}
                            zoomContainerBorder="1px solid #ccc"
                            zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.8)"
                        />
                    }

                    <br />
                    {curImg && <div className="arrows">
                        <div className="d-flex justify-content-center arrows w110">
                            <Button color="link" type="button" className="mr-4" onClick={previousClick}>
                                <i className="fas fa-arrow-circle-left fa-2x"></i>
                            </Button>
                            <Button color="link" type="button" onClick={nextClick}>
                                <i className="fas fa-arrow-circle-right fa-2x"></i>
                            </Button>
                        </div>
                    </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}

export default AmazonGalleryEdit;
