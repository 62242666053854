import React, {useState, useEffect} from 'react';
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import { connect } from 'react-redux';
import CustomWidget from "../../../../../../Components/Widgets/CustomWidget";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import CustomLoadingOverlay from "../../../../../../Components/Nav/CustomLoadingOverlay";
import DateRangePicker from "../../../../../../Components/Input/DateRangePicker";


const defaultWidgetStyles = [
    "bg-happy-green text-white",
    "bg-premium-dark text-white",
    "bg-night-fade text-white",
    "bg-arielle-smile",
];


function TimeFilteredDataDisplay(props) {


    const simpliworksDataFields = [
        {heading: "Sales", value: `$${props.data.asinData.sales}`},
        {heading: "Units Sold", value: `${props.data.asinData.conversions}`},
        {heading: "Advertising Spend", value: `$${props.data.asinData.cost}`},
        {heading: "ROAS", value: `${props.data.asinData.ROAS}`},
        {heading: "Impressions", value: `${props.data.asinData.impressions}`},
        {heading: "Clicks", value: `${props.data.asinData.clicks}`},
        {heading: "Units Ordered", value: `${props.data.asinData.unitsOrdered}`},
    ];
    const addNumCommas = (num) => {
        if (typeof num === "string" && num[0] === "$") {
            let newNum = parseFloat(num.replace(/\$/, ''))
            if (isNaN(newNum)) {
                newNum = 0;
            }
            return `$${newNum.toLocaleString("en-US")}`;
        } else {
            let newNum = parseFloat(num);
            if (isNaN(newNum)) {
                newNum = 0;
            }
            return newNum.toLocaleString("en-US");
        }
    }
   
    return (
        <>
        <Row>
                    <div className="border-bottom ml-2"> 
                        <h5 className="ml-3">Asin: {props.asin}</h5>
                        <p className="ml-3"><b>Start date:</b> {props.data.asinData.startdate} <b>End date:</b> {props.data.asinData.enddate}</p>
                    </div>
                    
                    <Col className="mb-3" md="12" lg="12">
                        {
                            simpliworksDataFields.map((item, idx) => (
                                <CustomWidget
                                    key={idx}
                                    className="card widget-content mb-2"
                                    heading={item.heading}
                                    content={
                                        <div className="widget-numbers">
                                            {addNumCommas(item.value)}
                                        </div>
                                    }
                                />
                            ))
                        }
                    </Col>
                    <Col className="mb-3" md="12" lg="12">
                        {props.children}
                    </Col>
                </Row>
            
          
        </>
    )
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 
export default connect(mapStateToProps)(TimeFilteredDataDisplay);
