import React from 'react';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from "reactstrap";

import StarRating from "../Rating/StarRating";


function SummaryCard(props) {

    const { header, image, rating, ratingVotes, title, body, footer } = props;

    return (
        <Card>
            <CardHeader>{header}</CardHeader>
            <CardBody>
                <Row>
                    <Col md="3">
                        <Row>
                            <Col md="12" className="mb-2">
                                <img width={150} src={image}
                                     alt=""/>
                            </Col>
                            { rating !== undefined && ratingVotes !== undefined &&
                                <Col md="12">
                                    <h6>
                                        <b>Rating:</b> <StarRating initialRating={rating} readonly stop={5}/> ({rating}) ({ratingVotes} reviews)
                                    </h6>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col md="9">
                        <h6 className="mb-3"><b>{title}</b></h6>
                        {
                            body.map((bodyItem, i) => (
                                <p key={i} style={{fontSize: "1rem", marginBottom: 0}}>
                                    {bodyItem.label}: <span className="widget-numbers-sm" style={{fontSize: "1.3rem"}}>{bodyItem.value}</span>
                                </p>
                            ))
                        }
                    </Col>
                </Row>
            </CardBody>
            { footer &&
                <CardFooter>
                    {footer}
                </CardFooter>
            }
        </Card>
    )
}

export default SummaryCard;