import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import StoreOverviewService
    from "../../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
import CampaignList from "../../../../../Components/CampaignList";



function ManagedCampaignsTab(props) {

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <h5 className="text-brand-green-dark">Simpliworks Managed Campaigns</h5>
                </Col>
                <Col md="11" className="ml-2 mb-3">
                    <h6>Campaigns under Simpliworks management appear below grouped by campaign status.</h6>
                </Col>
                <Col md="12">
                    <CampaignList
                        campaigns={props.storeCampaigns}
                        onCampaignStageUpdate={() => props.dispatch(StoreOverviewService(props.token, props.storeOverview.integerID, props.storeOverview.stringID))}
                    />
                </Col>
            </Row>
        </Fragment>

    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    storeOverview: state.accountOverview.store.storeOverview,
})

export default connect(mapStateToProps)(ManagedCampaignsTab);
