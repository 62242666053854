import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';
import { UpdateSubscriptionLevel } from "../../Redux/Actions/Actions";


function UserSubscriptionLevelService(token) {
    return function(dispatch) {
        axios.get(`${CURRENT_API_URL}/account/viewSubscriptionLevel`, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(UpdateSubscriptionLevel(
                res.data.subscriptionLevel,
                res.data.appSumoPlanId)
            );
        }).catch(() => {
            notify.NotifyError("An error occurred while retrieving plan details. Please try refreshing.");
        })
    }
}

export default UserSubscriptionLevelService;