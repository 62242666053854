import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadProductCategories } from "../../../Redux/Actions/Actions";
import { LoadResearchProductCategoryData } from "../../../Redux/Actions/Actions";
import { LoadLeadGenJobs } from "../../../Redux/Actions/Actions";


function LeadGenReasearchJobsToolService(token) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_LEAD_GEN_JOBS_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/viewAllLeadGenJobs`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            console.log(res.data)        
            dispatch(LoadLeadGenJobs(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_LEAD_GEN_JOBS_FAILED });

        });
    }
}

export default LeadGenReasearchJobsToolService;