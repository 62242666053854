import React from 'react';
import Chart from 'react-apexcharts';
import {mockData1} from "../../../../../../Utils/MockData1";


function AreaChart(props) {

    const chart = {
        options: {
            chart: {
                id: 'area-datetime',
                type: 'area',
                height: 350,
                zoom: {
                    autoScaleYaxis: true
                }
            },
            // annotations: {
            //     yaxis: [{
            //         y: 30,
            //         borderColor: '#999',
            //         label: {
            //             show: true,
            //             text: 'Support',
            //             style: {
            //                 color: "#fff",
            //                 background: '#00E396'
            //             }
            //         }
            //     }],
            //     xaxis: [{
            //         x: new Date('14 Nov 2012').getTime(),
            //         borderColor: '#999',
            //         yAxisIndex: 0,
            //         label: {
            //             show: true,
            //             text: 'Rally',
            //             style: {
            //                 color: "#fff",
            //                 background: '#775DD0'
            //             }
            //         }
            //     }]
            // },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
                style: 'hollow',
            },
            xaxis: {
                type: 'datetime',
                // min: new Date('01 Mar 2012').getTime(),
                tickAmount: 6,
            },
            tooltip: {
                x: {
                    format: 'dd MMM yyyy'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },
            noData: props.noData,
        },
        series: props.series,
        // series: [{
        //     data: mockData1
        // }]
    };

    return (
        <Chart
            options={chart.options}
            series={chart.series}
            type="area"
            width="100%"
        />
    );
}

export default AreaChart;