import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function CampaignLaunchStatus(token, campaignDetails) {

    // id in campaignDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/opsDash/campaignLaunch`, campaignDetails, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while checking campaign launch status, please try again later.");
            return false;
        });
}


export {
    CampaignLaunchStatus,
}