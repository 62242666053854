import React, { Fragment } from 'react';

import SelectableWidget from "../../../../Components/Widgets/SelectableWidget";


function SelectableCampaignWidget(props) {

    const { asin, name } = props;

    const subheading = () => (
        <Fragment>
            <div>Product ASIN: {asin}</div>
        </Fragment>
    );

    return (
        <SelectableWidget
            heading={name}
            subheading={subheading()}
            collapsible={false}
            toggleSelect={(isSelected) => props.toggleSelect(isSelected, props)}
            isSelected={props.isSelected}
            key={props.listItemKey}
        />
    )
}


export default SelectableCampaignWidget;