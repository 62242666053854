import React from "react";
import ReactTable from "react-table";
import SelectableCampaignWidgetList from "../../../../../../Components/Widgets/Campaigns/SelectableCampaignWidgetList";


function CampaignAnalyticsTable(props) {

    const columns = [
        {
          Header: 'Product',
            columns: [
                {
                    Header: 'ASIN',
                    accessor: 'asin',
                    width: 150,
                    style: {
                        fontWeight: "bold"
                    }
                },
                {
                    Header: 'Title',
                    accessor: 'title',
                },
            ],
        },
        {
            Header: 'View Product Campaigns',
            expander: true,
            width: 200,
            Expander: ({ isExpanded, ...rest }) =>
                <div>
                    {isExpanded
                        ? <i className="pe-7s-angle-down"> </i>
                        : <i className="pe-7s-angle-right"> </i>}
                </div>,
            style: {
                cursor: "pointer",
                fontSize: 35,
                padding: "0",
                textAlign: "center",
                userSelect: "none"
            },
        }
    ];

    return (
        <ReactTable
            data={props.data}
            columns={columns}
            defaultPageSize={20}
            collapseOnDataChange={false}
            collapseOnPageChange={false}
            collapseOnSortingChange={false}
            noDataText={"No campaigns found."}
            style={{
                height: "800px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            SubComponent={row => (
                <SelectableCampaignWidgetList data={row.original.campaigns} toggleSelect={props.toggleSelect} selectedCampaigns={props.selectedCampaigns} card={true} />
            )}
        />
    );
}

export default CampaignAnalyticsTable;