import products from "../../Utils/Products";
import actionType from '../Actions/ActionTypes';

function CartReducer(state = [], action) {
    if (action.type === actionType.ADD_TO_CART) {
        return [
            ...state,
            {
                ...action.campaign,
                itemId: action.itemId,
                cost: products[action.campaign.advertisingObjective].cost,
                priceId: products[action.campaign.advertisingObjective].priceId,
            },

        ];

    } else if (action.type === actionType.REMOVE_FROM_CART) {
        return state.filter(campaign => !action.campaigns.includes(campaign.itemId));

    } else if (action.type === actionType.CLEAR_CART) {
        return [];
    }

    return state;
}

export default CartReducer;
