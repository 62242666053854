import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row} from "reactstrap";
import AdvertisingAPICard from "./Components/AdvertisingAPICard";
import SPAPICards from "./Components/SPAPICards";
import Can from "../../../../../Components/Can";
import MRPCard from "./Components/MRPCard";
import AdvertisingAuthorization from '../../../RegistrationJourney/Components/AdvertisingAuthorization';
import AdvertisingAPIAuthorizationService from '../../../../../../Service/DashboardServices/UserProfileServices/AdvertisingAPIAuthorizationService';
import {RequireAuthAdvertisingAPI} from '../../../../../../../src/Redux/Actions/Actions'

function AuthorizationPage(props) {
    
    return (
        <Container fluid>
            <Row className="mbg-3">
                <Col className="mbg-3" md="12">
                    <h4>Step 1 : Advertising Authorization</h4>
                </Col>
                <Col md="6" xl="4">
                    {/* <AdvertisingAuthorization/> */}
                    <AdvertisingAPICard
                        token={props.token}
                        user={props.user}
                        isAuth={props.isAuth}   
                        needsAdvertisingAPIAuth = {props.needsAdvertisingAPIAuth}
                    />
                </Col>
                {/*<Col md="6" xl="4">*/}
                {/*    <MRPCard />*/}
                {/*</Col>*/}
            </Row>
            { <Can
                role={props.accessLevel}
                perform={"profile:spapiAuth"}
                yes={() => (
                    <Row  className="mbg-3">
                        <Col className="mb-3" md="12">
                            <h4>Step 2 : Store Authorization</h4>
                            <h6>Authorize Simpliworks for each region you sell in.</h6>
                        </Col>
                        
                        <SPAPICards />
                    </Row>
                )}
            /> }
        </Container>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
})

export default connect(mapStateToProps)(AuthorizationPage);