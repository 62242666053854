import actionType from '../../Actions/ActionTypes';


const initialState = {
    productOverview: {},
    productCampaigns: [],
    loading: false,
    status: '',
    metricData: {
        clicks: [],
        conversions: [],
        impressions: [],
        sales: [],
        spend: [],
        unitsSold: [],
    },
    keywordTableData: [],
    granularKeywordData: [],
    parentProductKeywordData: [],
    topKeywords: [],
    impressionGeneratingKeywords: [],
    campaignUpdates: [],
    creditPurchases: [],
    mrpData: {},
    performanceData: {},
    campaignData: [],
    convertingKeywords: [],
    impressionKeywords: [],
    allKeywords: [],
    bleederKeywords: [],
    idleKeywords: [],
}


function ProductReducer(state=initialState, action) {
    switch(action.type) {
        case actionType.LOAD_PRODUCT_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_PRODUCT_DATA_SUCCESS:
            return {
                ...state,
                productOverview: action.productOverview,
                productCampaigns: action.productCampaigns,
                metricData: action.metricData,
                keywordTableData: action.keywordTableData.map(keyword => ({
                    ...keyword,
                    bidPrice: parseFloat(keyword.bidPrice),
                    maxSuggestedBid: parseFloat(keyword.maxSuggestedBid),
                    midSuggestedBid: parseFloat(keyword.midSuggestedBid),
                    minSuggestedBid: parseFloat(keyword.minSuggestedBid),
                })),
                granularKeywordData: action.granularKeywordData,
                topKeywords: action.topKeywords,
                impressionGeneratingKeywords: action.impressionGeneratingKeywords,
                campaignUpdates: action.campaignUpdates,
                creditPurchases: action.creditPurchases,
                loading: false,
                status: 'success',
            }
        case actionType.LOAD_PRODUCT_DATA_FAILED:
            return {
                ...initialState,
                loading: false,
                status: 'failed',
            }
        case actionType.LOAD_PRODUCT_MRP_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                status: 'loading',
                mrpData: {},
            }
        case actionType.LOAD_PRODUCT_MRP_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                status: "success",
                mrpData: action.mrpData,
            }
        case actionType.LOAD_PRODUCT_MRP_DATA_FAILED:
            return {
                ...state,
                loading: false,
                status: "failed",
                mrpData: {},
            }
        case actionType.LOAD_PARENT_PRODUCT_RANK_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_PARENT_PRODUCT_RANK_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 'success',
                parentProductKeywordData: action.keywordData,
            }
        case actionType.LOAD_PARENT_PRODUCT_RANK_DATA_FAILED:
            return {
                ...state,
                loading: false,
                status: 'failed',
                parentProductKeywordData: [],
            }
        case actionType.LOAD_PRODUCT_PERFORMANCE_BEGIN:
            return {
                ...state,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_PRODUCT_PERFORMANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 'success',
                performanceData: action.performanceData,
                convertingKeywords: action.convertingKeywords,
                impressionKeywords: action.impressionKeywords,
                allKeywords: action.allKeywords,
                campaignData: action.campaignData,
            }
        case actionType.LOAD_PRODUCT_PERFORMANCE_FAILED:
            return {
                ...state,
                loading: false,
                status: 'failed',
                performanceData: {},
                convertingKeywords: [],
                impressionKeywords: [],
                allKeywords: [],
                campaignData: [],
            }
        case actionType.LOAD_PRODUCT_BLEEDER_KW_BEGIN:
            return {
                ...state,
                // loading: true,
                // status: 'loading',
            }
        case actionType.LOAD_PRODUCT_BLEEDER_KW_SUCCESS:
            return {
                ...state,
                // loading: false,
                // status: 'success',
                bleederKeywords: action.bleederKeywords,
            }
        case actionType.LOAD_PRODUCT_BLEEDER_KW_FAILED:
            return {
                ...state,
                // loading: false,
                // status: 'failed',
                bleederKeywords: [],
            }
        case actionType.LOAD_PRODUCT_IDLE_KW_BEGIN:
            return {
                ...state,
                // loading: true,
                // status: 'loading',
            }
        case actionType.LOAD_PRODUCT_IDLE_KW_SUCCESS:
            return {
                ...state,
                // loading: false,
                // status: 'success',
                idleKeywords: action.idleKeywords,
            }
        case actionType.LOAD_PRODUCT_IDLE_KW_FAILED:
            return {
                ...state,
                // loading: false,
                // status: 'failed',
                idleKeywords: [],
            }
        default:
            break;
    }

    return state;
}

export default ProductReducer;
