import actionType from '../../Actions/ActionTypes';

/**
 *
 */

const initialState = {
    campaignOverview: {},
    campaignType: '',
    keywords: [],
    targetedProducts: [],
    metricData: {
        clicks: [],
        conversions: [],
        impressions: [],
        sales: [],
        spend: [],
        unitsSold: [],
    },
    loading: false,
    status: '',
}

function CampaignReducer(state=initialState, action) {
    switch(action.type) {
        case actionType.LOAD_CAMPAIGN_DATA_BEGIN:
            return {
                ...initialState,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_CAMPAIGN_DATA_SUCCESS:
            return {
                campaignOverview: action.campaignOverview,
                campaignType: action.campaignType,
                keywords: action.keywords,
                targetedProducts: action.targetedProducts,
                metricData: action.metricData,
                loading: false,
                status: 'success',
            }
        case actionType.LOAD_CAMPAIGN_DATA_FAILED:
            return {
                ...initialState,
                loading: false,
                status: 'failed',
            }
        default:
            break;
    }

    return state;
}

export default CampaignReducer;