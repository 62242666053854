import React, { useState, Fragment } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import urls from "../../../../../../../Utils/URLs";
import ValidateContentAgencyService from "../../../../../../../Service/EntryServices/ValidateContentAgencyService";
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";
import ContentAgencyRegistrationService
    from "../../../../../../../Service/EntryServices/ContentAgencyRegistrationService";

function ContentAgencyRegistrationForm(props) {

    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        accessCode: '',
        contentAgency: {},
        isAccessCodeValid: false,
    });

    const [loading, setLoading] = useState(false);


    const handleChange = e => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    }

    const isInvalid = () => {
        for (let key in state) {
            if (state[key] === "") {
                return true;
            }
        }
        return (state.password !== state.confirmPassword);
    }

    const validateAccessCode = async () => {
        setLoading(true);
        let response = await ValidateContentAgencyService(state.accessCode);
        if (response) {
            setState(prevState => ({
                ...prevState,
                contentAgency: response,
                isAccessCodeValid: true,
            }))
        }
        setLoading(false);
    }

    const handleSubmit = e => {
        e.preventDefault();
        props.dispatch(ContentAgencyRegistrationService({
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
            password: state.password,
            contentAgencyId: state.accessCode,
        }));
    }

    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="app-logo mx-auto mb-3"/>
                <div className="h5 modal-title text-center">
                    <h4 className="mt-2">
                        <div className="text-dark">Welcome,</div>
                        {
                            !state.isAccessCodeValid ?
                            <span>Enter your access code to begin creating your new account!</span> :
                                <h4 className="text-primary">{state.contentAgency.contentAgencyName}</h4>
                        }
                    </h4>
                </div>
                {
                    !state.isAccessCodeValid &&
                    <Row form>
                        <Col md={{offset: 3, size: 6}} className="text-center">
                            <Label size={"lg"} for="accessCode" className="text-brand-blue-dark">Access Code</Label>
                            <Input
                                id="accessCode"
                                name="accessCode"
                                type="text"
                                value={state.accessCode}
                                onChange={handleChange}
                            />
                            <LoadingButton
                                className="mt-3"
                                color="brand-blue-dark"
                                onClick={() => validateAccessCode()}
                                loading={loading}
                            >
                                Validate
                            </LoadingButton>
                        </Col>
                    </Row>
                }
                {
                    state.isAccessCodeValid &&
                    <Fragment>
                        <Row className="divider"/>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={state.email}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        value={state.firstName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        value={state.lastName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirm Password</Label>
                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        value={state.confirmPassword}
                                        onChange={handleChange}
                                        invalid={state.password !== state.confirmPassword}
                                    />
                                    <FormFeedback>Passwords must match</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row className="divider"/>
                <h6 className="mb-0">
                    Already have an account?{' '}
                    <Link className="text-primary" to={urls.LOGIN_URL}>Sign in now</Link>
                    {' '} | {' '}
                    <Link className="text-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Recover Password</Link>
                </h6>
            </div>
            <div className="modal-footer d-block text-center">
                {state.isAccessCodeValid &&
                <Button
                    color="primary"
                    className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"
                    size="lg"
                    onClick={handleSubmit}
                    disabled={isInvalid()}
                >
                    Create Account
                </Button>
                }

                {/*{ !props.isAuth && <p>Make sure you have logged in with Amazon before creating an account.</p>}*/}
            </div>
        </div>
    )
}

// const mapStateToProps = state => {
//     return {
//         isAccessCodeValid: state.loggedUser.isAccessCodeValid,
//         contentAgency: state.loggedUser.contentAgency,
//         logged: state.loggedUser.logged,
//         status: state.loggedUser.status,
//     }
// }
//
// export default connect(mapStateToProps)(ContentAgencyRegistrationForm);
export default connect()(ContentAgencyRegistrationForm);