import React, { Fragment, useState, useEffect } from 'react';
import { Button, FormGroup, Input, Row, Col } from 'reactstrap';

const urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;

const AmazonTextField = ({ fieldData, loading, onSubmit, ...otherProps }) => {

    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);


    useEffect(() => {
        if (fieldData && fieldData.id > 0) {
            setIsEdit(true);
            setValue(fieldData.field_value)
        }
    }, [fieldData])

    const onChange = (e) => {
        setValue(e.target.value)
        if (otherProps.maxLength && e.target.value.length > otherProps.maxLength) {
            setError(`Only ${otherProps.maxLength} Characters are allowed`)
        }
        if (e.target.value) setError('')

        // if (otherProps.name === 'linkToAsset') {
        //     if (!urlRegex.test(e.target.value)) {
        //         setError('This is should be URL Link')
        //     }
        // }
    }

    const validateField = (e) => {
        if (!value) {
            setError(`This field is required`)
        }
    }

    const saveData = (e) => {
        let payload = {
            id: 0,
            field_value: value,
            field_name: otherProps.name
        }
        if (isEdit) {
            payload.id = fieldData.id;
        }

        onSubmit(payload)
    }

    const clearData = (e) => {
        setValue('');
        setError('');
    }

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <FormGroup>
                        <Input value={value} onChange={onChange} onBlur={validateField} {...otherProps} />
                        {error &&
                            <span className="text-danger">{error}</span>
                        }

                    </FormGroup>
                </Col>
                <Col md="12">
                    {
                        (value && !loading && !error) ?
                            <Fragment>
                                <Button type="button" color="primary" className="float-right" onClick={saveData}>Save</Button> {' '}
                                <Button type="button" color="link" className="text-danger float-right" onClick={clearData}>Clear</Button>
                            </Fragment>
                            :
                            <Fragment>
                                <Button type="button" color="primary" className="float-right" disabled>Save</Button> {' '}
                                <Button type="button" color="link" className="text-danger float-right" disabled>Clear</Button>
                            </Fragment>
                    }

                </Col>
            </Row>
        </Fragment>
    )
}

export default AmazonTextField;
