import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";

import CustomBreadcrumb from "../../../../../Components/Nav/CustomBreadcrumb";
import CampaignSummaryCard from "../../Components/Cards/CampaignSummaryCard";
import urls from "../../../../../../Utils/URLs";
import CampaignKeywordCard from "../../Components/Cards/CampaignKeywordCard";
import CampaignTargetedProductCard from "../../Components/Cards/CampaignTargetedProductCard";
import LaunchCampaignService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/LaunchpadServices/LaunchCampaignService";
import StoreListService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";


const breadcrumbs = [
    {
        label: 'Seller Profiles',
        to: urls.ACCOUNT_OVERVIEW_MY_STORES_URL,
    },
    {
        label: 'Product Overview',
        to: urls.ACCOUNT_OVERVIEW_PRODUCT_URL,
    }
]


function CampaignOverviewPage(props) {

    const handleLaunchCampaign = async (name, collectionID, selectedKeywords) => {
        let response = await LaunchCampaignService(props.token, name, collectionID, selectedKeywords);
        if (response) {
            props.dispatch(StoreListService(props.token));
        }
    }

    // const renderCampaignDetails = () => {
    //     if (!props.loading) {
    //         return (
    //             <Fragment>
    //                 <Row className="mb-3">
    //                     <Col md="10">
    //                         <CampaignSummaryCard
    //                             campaignOverview={props.campaignOverview}
    //                             handleLaunch={handleLaunchCampaign}
    //                         />
    //                     </Col>
    //                 </Row>
    //                 <Row className="mt-2 mb-2">
    //                     <Col md="5">
    //                         <CampaignKeywordCard
    //                             keywords={props.keywords}
    //                             status={props.campaignOverview.status}
    //                         />
    //                     </Col>
    //                     { props.campaignType === 'both' &&
    //                         <Col md="5">
    //                             <CampaignTargetedProductCard
    //                                 targetedProducts={props.targetedProducts}
    //                             />
    //                         </Col>
    //                     }
    //                 </Row>
    //             </Fragment>
    //         );
    //     }
    // }

    const renderCampaignDetails = () => (
        <Fragment>
            <Row className="mb-3">
                <Col md="10">
                    <CampaignSummaryCard
                        campaignOverview={props.campaignOverview}
                        handleLaunch={handleLaunchCampaign}
                    />
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col md="5">
                    <CampaignKeywordCard
                        keywords={props.keywords}
                        status={props.campaignOverview.status}
                    />
                </Col>
                {/*{ props.campaignType === 'both' &&*/}
                {/*<Col md="5">*/}
                {/*    <CampaignTargetedProductCard*/}
                {/*        targetedProducts={props.targetedProducts}*/}
                {/*    />*/}
                {/*</Col>*/}
                {/*}*/}
            </Row>
        </Fragment>
    );

    const renderFailure = () => (
        <h5>An error occurred while retrieving your campaign's details.</h5>
    )


    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <CustomBreadcrumb history={breadcrumbs} activeLabel="Campaign Overview" />
                        </div>
                        <h4 className="mbg-3">Campaign Overview</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <LoadingOverlay
                            active={props.loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#f1f4f6',
                                    opacity: 1
                                }),
                                content: (base) => ({
                                    ...base,
                                    color: "#000"
                                })
                            }}
                            spinner={<Loader active type="ball-pulse"/>}
                            text='Loading...'
                        >
                            { props.status === 'success' ?
                                renderCampaignDetails()
                                :
                                renderFailure()
                            }
                        </LoadingOverlay>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    campaignOverview: state.accountOverview.campaign.campaignOverview,
    campaignType: state.accountOverview.campaign.campaignType,
    keywords: state.accountOverview.campaign.keywords,
    targetedProducts: state.accountOverview.campaign.targetedProducts,
    loading: state.accountOverview.campaign.loading,
    status: state.accountOverview.campaign.status,
});

export default connect(mapStateToProps)(CampaignOverviewPage);
