import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


/**
 * Retrieves status of first and second page data collection by keyword for a given window of time
 */
function RankTrackingStatus(token, startDate, endDate) {
    return axios.post(`${CURRENT_API_URL}/opsDash/rankTrackingData`, {
        startDate: startDate,
        endDate: endDate,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(() => {
        notify.NotifyError("An error occurred while checking rank tracking status, please try again later.");
        return false;
    })
}

function AdvertisingDataStatus(token, startDate, endDate) {
    return axios.post(`${CURRENT_API_URL}/opsDash/advertisingData`, {
        startDate: startDate,
        endDate: endDate,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        console.log(res.data)
        return res.data;
    }).catch(() => {
        notify.NotifyError("An error occurred while checking advertising data collection status, please try again later.");
        return false;
    })
}


export {
    RankTrackingStatus,
    AdvertisingDataStatus,
}