import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";


function AddOnsCard({ title, price, description, priceID, handleCheckout, enablePurchaseButton }) {
    return (
        <Card>
            <CardHeader style={{justifyContent: "center"}}>{title}</CardHeader>
            <CardBody className="text-center">
                <h6  style={{maxWidth: "600px"}}>{description}</h6>
                <h3 className="text-dark mt-2 mb-3"><b>${price}</b></h3>

                {
                    enablePurchaseButton &&
                    <Button
                        onClick={() => handleCheckout(priceID)}
                        color={"primary"}
                    >
                        Purchase
                    </Button>
                }
            </CardBody>
        </Card>
    )
}

export default AddOnsCard;