import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadRegistrastionJournyData } from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function StoreHealthUpdateActiveFlagService(token,data) {
    console.log(token)
    return axios.post(`${CURRENT_API_URL}/account/storeHealthUpdateActiveFlag`,data, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        notify.NotifySuccess("Your request successfully updated. Thank you.");
        return res.data;
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while updating data. please try again later.");
        return false;
    });
}


export default StoreHealthUpdateActiveFlagService;