import React, { Fragment, useState } from 'react';
import { Button, Col, CustomInput, Input, Row, Label } from "reactstrap";
import { DropdownList } from "react-widgets";

import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";
import UnclaimedCampaignWidget from "./UnclaimedCampaignWidget";
import CustomModal from "../../../../Components/Modals/CustomModal";


function UnclaimedCampaignWidgetList(props) {

    const [importASIN, setImportASIN] = useState('');
    const [campaignStage, setCampaignStage] = useState('');
    const [isPhrase, setIsPhrase] = useState(false);
    const [importCampaign, setImportCampaign] = useState({
        productSKU: '',
        campaignID: '',
    });
    const [isOpen, setIsOpen] = useState(false);

    let stages = [
        {
            id: "swarm",
            value: "Swarm",
        },
        {
            id: "establish",
            value: "Establish",
        },
        {
            id: "explore",
            value: "Explore",
        },
        {
            id: "winning",
            value: "Winning",
        },
    ];

    const widgetsData = props.data.map(campaign => {
        const { dailyBudget, campaignID, name, country, state, type, productSKU } = campaign;

        const details = {
            dailyBudget: `$${dailyBudget}`,
            marketplace: country,
            productTargeting: type === 'both' ? 'Enabled' : 'Disabled',
            status: state === 'ready' ? 'Ready to Launch' : state === 'enabled' ? 'Active' : state,
        };

        return { name, campaignID, productSKU, state, details };
    });

    const renderUnclaimedCampaignWidget = (campaign) => (
        <UnclaimedCampaignWidget
            {...campaign}
            handleImportCampaign={props.handleImportCampaign}
            setImportCampaign={setImportCampaign}
            toggleModal={() => setIsOpen(!isOpen)}
        />
    );

    return (
        <Fragment>
            <WidgetListCard
                widgets={widgetsData}
                renderWidget={renderUnclaimedCampaignWidget}
                listHeading={props.listHeading}
                card={props.card}
            />
            <CustomModal
                header={"Import Campaign"}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                footer={
                    <Button
                    color="success"
                    onClick={() => props.handleImportCampaign(importASIN, importCampaign.campaignID, campaignStage.id, isPhrase)}
                    disabled={importASIN === '' || campaignStage === ''}
                >
                    Import Campaign
                </Button>
                }
            >
                <Row>
                    <Col className="mb-3" md="12">
                        <Label for="importASIN">Product ASIN</Label>
                        <Input
                            id="importASIN"
                            placeholder="Enter ASIN..."
                            value={importASIN}
                            onChange={(e) => setImportASIN(e.target.value)}
                        />
                    </Col>
                    <Col className="mb-3" md="12">
                        <DropdownList
                            data={stages}
                            dataKey='id'
                            value={campaignStage}
                            textField="value"
                            onChange={value => setCampaignStage(value)}
                            placeholder="Campaign Stage"
                        />
                    </Col>
                    <Col md="12">
                        <CustomInput
                            type="checkbox"
                            id="isPhrase"
                            label="Phrase Campaign?"
                            checked={isPhrase}
                            onChange={e => {
                                let checked = e.target.checked;
                                setIsPhrase(checked);
                            }}
                        />
                    </Col>
                </Row>
            </CustomModal>
        </Fragment>
    )
}

export default UnclaimedCampaignWidgetList;
