import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetDataForLastOneDayService(token, sellerPartnerDetails) {
    console.log(sellerPartnerDetails)
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/sellerPartnerOneDayData`, sellerPartnerDetails, {
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("SPAPI Sales data for the selected store is getting fetched."))
        .then(res => {
            notify.NotifySuccess("SPAPI Sales data for the selected store is fetched successfully.");
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while Seller Partner Data, please try again later.");
            return false;
        });
}


export {
    GetDataForLastOneDayService,
}

