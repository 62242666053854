import axios from 'axios';

import notify from '../../../../Notifications/ToastifyActions';
import {CURRENT_API_URL} from '../../../../Utils/Constants';

function RemoveProductService(token, asin, integerID) {
    return axios.post(
            `${CURRENT_API_URL}/campaign/removeProduct`,
            {
                asin: asin,
                integerID: integerID,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
        .then(() => {
            notify.NotifySuccess('Your product was deleted successfully.');
            return true;
        })
        .catch((error) => {
            notify.NotifyError(
                "An error occurred while deleting your product, please try again later."
            );
            return false;
        });
}

export default RemoveProductService;
