import React, { useState } from 'react';
import { Button, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import FileUpload from '../../../../../Components/Fileupload/Fileupload';
import { Field, FieldArray } from 'formik';

const maxFileSizeInBytes = 5 * 1024 * 1024;

const UploadProjectFiles = ({ formik, onFileLimitErrorFromUpload }) => {
  console.log(formik.values)
  const updateUploadedFiles = (files) => {

    formik.setFieldValue('files', files)
    //setNewUserInfo({ ...newUserInfo, profileImages: files });
  }

  const onFileLengthError = (msg) => {
    onFileLimitErrorFromUpload(msg);
  }

  const addComparables = (e, index) => {
    e.preventDefault();
    const lintToAssets = [...formik.values.linkToAssets]
    if (!lintToAssets[index].comparabaleProducts) {
      onFileLimitErrorFromUpload("Comparable Products on Amazon fields should not be empty");
      return;
    }
    if (lintToAssets.length >= 10) {
      onFileLimitErrorFromUpload("You can add only 10 Comparables");
      return;
    }
    lintToAssets.push({
      comparabaleProducts: ""
    })

    formik.setFieldValue('linkToAssets', lintToAssets)
  }

  const deleteComparable = (e, index) => {
    e.preventDefault();
    const lintToAssets = [...formik.values.linkToAssets]

    lintToAssets.splice(index, 1);
    formik.setFieldValue('linkToAssets', lintToAssets)

  }

  return (

    <div>
      <div>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label for="AdditionalDetails" className="bold">Additional Details</Label>
              <Input type="textarea" id="AdditionalDetails" name="additionalDetails" tag={Field} />
            </FormGroup>
          </Col>
        </Row>
        <FieldArray name="linkToAssets">
          {() => (formik.values.linkToAssets.map((asset, i) => {
            return (
              <div key={i}>
                <Row>
                  <Col md="9">
                    <FormGroup>
                      <Label for="productLink" className="bold">Comparable Products on Amazon</Label>
                      <Input type="text" id="productLink" placeholder="Product Link" name={`linkToAssets.${i}.comparabaleProducts`} tag={Field} />
                    </FormGroup>
                  </Col>
                  <Col md="3" style={{ 'paddingTop': '2.1rem' }}>
                    {(i === formik.values.linkToAssets.length - 1 && formik.values.linkToAssets.length < 10) &&
                      <a href="#" className="bold mr-2" onClick={(e) => addComparables(e, i)}>+ Add Comparables</a>
                    }

                    {(formik.values.linkToAssets.length !== 1) &&
                      <a href="#" className="bold text-danger" onClick={(e) => deleteComparable(e, i)}>Delete</a>
                    }
                  </Col>
                </Row>
              </div>
            );
          }
          ))}
        </FieldArray>

      </div>
      <FileUpload
        accept=".jpg,.png,.jpeg"
        label="Product Files"
        multiple
        maxFileSizeInBytes = {maxFileSizeInBytes}
        updateFilesCb={updateUploadedFiles}
        previousFiles={formik.values.files}
        onFileLengthError={onFileLengthError}
      />
    </div >
  )
}

export default UploadProjectFiles;
