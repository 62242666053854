import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcel = (xlsxData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    console.log(ws)
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1", true);
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});

    FileSaver.saveAs(data, fileName + fileExtension);

}

const groupBy = (list, key) => {
    return list.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


const calculateProgressPer = (size, index) => {

    let actPer = Math.floor(100 / size) * (index + 1);
    return actPer;
}


const getFilenameWithTimestamp = (filename) => {
    let regex = new RegExp('[^.]+$');
    let extension = filename.match(regex)
    return `${filename.slice(0, extension['index'] - 1)}_${new Date().getTime()}.${extension[0]}`;
}

const productLine = [
    {
        "type": "checkbox",
        "label": "Convenience Products- Product that requires little thought on the part of the customer",
        "value": "Convenience Products"
    },
    {
        "type": "checkbox",
        "label": "Specialty Products-Products that have unique characteristics or are associated with a particular brand",
        "value": "Specialty Products"
    },
    {
        "type": "checkbox",
        "label": "Shopping Products-Products that range in price, style, and quality and consumers often spend a greater deal of time comparing options",
        "value": "Shopping Products"
    },
    {
        "type": "checkbox",
        "label": "Niche Products-Products that consumers typically don't buy or wouldn't consider purchasing regularly",
        "value": "Niche Products"
    },
    {
        "type": "checkbox",
        "label": "Consumable product- products a customer would use on a daily basis and would need to repurchase to continue use.",
        "value": "Consumable product"
    },
]

const buyingBehaviour = [
    {
        "type": "checkbox",
        "label": "Extended Decision Making(Product examples:Expensive/status items, electronics, feature-differentiated products)",
        "value": "Extended Decision Making"
    },
    {
        "type": "checkbox",
        "label": "Limited Decision Making(Product examples:Niche products,limited availability or options,market dominating products)",
        "value": "Limited Decision Making"
    },
    {
        "type": "checkbox",
        "label": "Habitual buying behavior(Product examples:CPG products, convenience items, consumables)",
        "value": "Habitual buying behavior"
    },
    {
        "type": "checkbox",
        "label": "Variety-seeking buying behavior(Product examples: Apparel, collectibles, DIY products)",
        "value": "Variety"
    }
]

const setAttribute = {
    attribute_most_describes_1: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Traditional',
                value: "Traditional"
            },
            {
                label: 'Progressive',
                value: "Progressive"
            }
        ]
    },
    attribute_most_describes_2: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Exclusive',
                value: "Exclusive"
            },
            {
                label: 'Accessible',
                value: "Accessible"
            }
        ]
    },
    attribute_most_describes_3: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Corporate',
                value: "Corporate"
            },
            {
                label: 'Friendly',
                value: "Friendly"
            }
        ]
    },
    attribute_most_describes_4: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Serious',
                value: "Serious"
            },
            {
                label: 'Fun, playful',
                value: "Fun, playful"
            }
        ]
    },
    attribute_most_describes_5: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Bold',
                value: "Bold"
            },
            {
                label: 'Simple',
                value: "Simple"
            }
        ]
    },
    attribute_most_describes_6: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Complex',
                value: "Complex"
            },
            {
                label: 'City, urban',
                value: "City, urban"
            }
        ]
    },
    attribute_most_describes_7: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Natural',
                value: "Natural"
            },
            {
                label: 'Familiar',
                value: "Familiar"
            }
        ]
    },
    attribute_most_describes_8: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Disruptive',
                value: "Disruptive"
            },
            {
                label: 'Steady, stable',
                value: "Steady, stable"
            }
        ]
    },
    attribute_most_describes_9: {
        label: "Select which attribute most closely describes your brand?",
        options: [
            {
                label: 'Dynamic',
                value: "Dynamic"
            },
            {
                label: 'Steady, stable',
                value: "Steady, stable"
            }
        ]
    },
}

const setBrandImage = {
    image_characteristics_1: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Masculine',
                value: "Masculine"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Feminine',
                value: "Feminine"
            }
        ]
    },
    image_characteristics_2: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Simple',
                value: "Simple"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Intricate',
                value: "Intricate"
            }
        ]
    },
    image_characteristics_3: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Grey',
                value: "Grey"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Colorful',
                value: "Colorful"
            }
        ]
    },
    image_characteristics_4: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Conservative',
                value: "Conservative"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Extravagant',
                value: "Extravagant"
            }
        ]
    },
    image_characteristics_5: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Approachable',
                value: "Approachable"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Authoritative',
                value: "Authoritative"
            }
        ]
    },
    image_characteristics_6: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Necessity',
                value: "Necessity"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Luxury',
                value: "Luxury"
            }
        ]
    },
    image_characteristics_7: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Fun',
                value: "Fun"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Serious',
                value: "Serious"
            }
        ]
    },
    image_characteristics_8: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Professional',
                value: "Professional"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Casual',
                value: "Casual"
            }
        ]
    },
    image_characteristics_9: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Modern',
                value: "Modern"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Classic',
                value: "Classic"
            }
        ]
    },
    image_characteristics_10: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Sporty',
                value: "Sporty"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Elegant',
                value: "Elegant"
            }
        ]
    },
    image_characteristics_11: {
        label: "How does your brand’s image fall between these opposing characteristics?",
        options: [
            {
                label: 'Extreme',
                value: "Extreme"
            },
            {
                label: 'Neutral',
                value: "Neutral"
            },
            {
                label: 'Safe',
                value: "Safe"
            }
        ]
    },
}

const brandVoiceOpts = [
    {
        label: "Empowering and uplifting: Encourage your audience to improve, excel, and achieve any goal",
        value: "Empowering and uplifting"
    },
    {
        label: "Friendly and informative: Inject personality into your brand voice and tone. Upbeat with with a bounce, a pep to get you excited",
        value: "Friendly and informative"
    },
    {
        label: "Professional and ambitious: Establish your brand as a trusted thought leader by taking an academic approach",
        value: "Professional and ambitious"
    },
    {
        label: "Fresh and clean: Pleasant and comfortably crisp. Short but sweet copy. Descriptive without being overly flowery.",
        value: "Fresh and clean"
    },
    {
        label: "So far out there: Push beyond the traditional brand voice by embracing the weird, wacky, and irreverent",
        value: "So far out there"
    },
    {
        label: "Aggressive and in-your-face: Run full-speed at your customer, spear-tackle them, and shout in their face as they lie in the dust. Boastful tone that challenges the reader to show they are worthy.",
        value: "Aggressive and in-your-face"
    }
]

export {
    ExportExcel,
    groupBy,
    calculateProgressPer,
    getFilenameWithTimestamp,
    productLine,
    buyingBehaviour,
    brandVoiceOpts,
    setBrandImage,
    setAttribute,
}