import React from "react";

function PageNotFound() {
    return (
        <div>
            <h1>Page not found.</h1>
        </div>
    );
}

export default PageNotFound;
