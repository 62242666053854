import React, {useState, Fragment} from 'react';
import {connect} from 'react-redux';
import {loadStripe} from "@stripe/stripe-js";
import {CURRENT_STRIPE_PK} from "../../../../../../Utils/Constants";

import {
    Input,
    Card,
    CardTitle,
    CardBody,
    Col,
    Container,
    Row,
    Button,
} from 'reactstrap';

import CreditsPurchaseForm from './Components/CreditsPurchaseForm';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {MyHandle} from './Components/MyHandle';

import CustomCreditPurchaseService from '../../../../../../Service/PaymentServices/Credits/CustomCreditPurchaseService';

const stripePromise = loadStripe(CURRENT_STRIPE_PK);

function CreditsPurchasePage(props) {
    const [noOfProducts, setNoOfProducts] = useState(1);
    const [monthsOfManagement, setMonthsOfManagement] = useState(1);
    const [totalCredits, setTotalCredits] = useState(
        noOfProducts * monthsOfManagement * 30
    );
    const [costPerCredit, setCostPerCredit] = useState(6);
    const [purchasePackageLabel, setPurchasePackageLabel] = useState(
        'Your custom credit package for ' +
        totalCredits +
        '  credits will cost $' +
        totalCredits * costPerCredit
    );

    const handleSlider = (event, type) => {
        let newTotalCredits = 0;
        if (type === 0) {
            setNoOfProducts(event);
            newTotalCredits = monthsOfManagement * event * 30;
            setTotalCredits(newTotalCredits);
        } else {
            setMonthsOfManagement(event);
            newTotalCredits = noOfProducts * event * 30;
            setTotalCredits(newTotalCredits);
        }
        computeCostPerCredit(newTotalCredits);
    };

    const computeCostPerCredit = (newTotalCredits) => {
        if (totalCredits < 30) {
            setCostPerCredit(6);
            // setPurchasePackageLabel(
            //   'The "Starter" package is the ideal size to manage your product catalog with Simpliworks!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 6
            );
        }
        if (totalCredits >= 30 && totalCredits <= 150) {
            setCostPerCredit(5.3);
            // setPurchasePackageLabel(
            //   'The "Boost" package is the ideal size to manage your product catalog with Simpliworks!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 5.3
            );
        }
        if (newTotalCredits >= 150 && newTotalCredits <= 300) {
            setCostPerCredit(5);
            // setPurchasePackageLabel(
            //   'The "Pro" package is the ideal size to manage your product catalog with Simpliworks!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 5
            );
        }
        if (newTotalCredits >= 300 && newTotalCredits <= 750) {
            setCostPerCredit(4.8);
            // setPurchasePackageLabel(
            //   'The "Premier" package is the ideal size to manage your product catalog with Simpliworks!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 4.8
            );
        }
        if (newTotalCredits >= 751 && newTotalCredits <= 1500) {
            setCostPerCredit(4.5);
            // setPurchasePackageLabel(
            //   'The "Elite" package is the ideal size to manage your product catalog with Simpliworks!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 4.5
            );
        }
        if (newTotalCredits > 1500) {
            setCostPerCredit(4.5);
            // setPurchasePackageLabel(
            //   'Please contact us at hello@simpliworks.io for additional support in crafting your credit package!'
            // );
            setPurchasePackageLabel(
                'Your custom credit package for ' +
                newTotalCredits +
                '  credits will cost $' +
                newTotalCredits * 4.5
            );
        }
    };

    const handleInput = (event, type) => {
        const regex = /^[0-9\b]+$/;
        if (regex.test(event.target.value)) {
            if (type === 0) {
                setNoOfProducts(event.target.value);
                setTotalCredits(monthsOfManagement * event.target.value * 30);
            } else {
                setMonthsOfManagement(event.target.value);
                setTotalCredits(noOfProducts * event.target.value * 30);
            }
        } else {
            alert('Please input valid number');
        }
    };

    const handleCheckout = async () => {
        const stripe = await stripePromise;

        const session = await CustomCreditPurchaseService(
            props.token,
            'Custom',
            props.userName,
            props.userEmail,
            totalCredits
        );
        console.log('response', session);
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        // if (result.error) {
        //   // If `redirectToCheckout` fails due to a browser or network
        //   // error, display the localized error message to your customer
        //   // using `result.error.message`.
        //   notify.NotifyError(result.error.message);
        // }
    };

    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row className="mb-4">
                        <Col md={{offset: 0, size: 12}}>
                            {/*<Col md="8">*/}
                            <Card>
                                <CardBody>
                                    <CardTitle
                                        className="text-lg-center"
                                        style={{fontSize: '1rem'}}
                                    >
                                        How it works
                                    </CardTitle>
                                    <p className="text-center">
                                        Purchase credit packages to be used on any in-app service.
                                        Simpliworks credits do not expire and are only reduced when
                                        they are consumed. Larger packages provide increased savings
                                        across all service offerings.
                                    </p>
                                    <CardTitle
                                        className="text-lg-center mt-3"
                                        style={{fontSize: '1rem'}}
                                    >
                                        Cost of services
                                    </CardTitle>
                                    <p className="text-center">
                                        Sponsored Product Management = 1 credit / per product / per
                                        day
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={{offset: 0, size: 12}}>
                            {/*<Col md="8">*/}
                            <Card>
                                <CardBody>
                                    <CardTitle
                                        className="text-lg-center"
                                        style={{fontSize: '1rem'}}
                                    >
                                        Platform Features
                                    </CardTitle>
                                    <p className="text-center"> 1-Click Campaign Launcher</p>
                                    <p className="text-center"> Keyword Performance Tracking</p>
                                    <p className="text-center">
                                        {' '}
                                        Campaign and Keyword Level Performance Reports
                                    </p>
                                    {/* <p className="text-center"> True Profit Calculator</p> */}
                                    <p className="text-center">
                                        {' '}
                                        End-to-End Sponsored Product Management
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={{offset: 0, size: 12}}>
                            <Card>
                                <CardBody>
                                    <CardTitle
                                        className="text-lg-center"
                                        style={{fontSize: '1rem'}}
                                    >
                                        Flexible Payment Options
                                    </CardTitle>
                                    {/*  className="text-center" */}
                                    <Container>
                                        <Row className="mt-4">
                                            <Container
                                                style={{
                                                    display: `flex`,
                                                    justifyContent: `space-around`,
                                                }}
                                            >
                                                <Col xs="5">
                                                    <p>Number of Products</p>
                                                </Col>
                                                <Col xs="5">
                                                    <Slider
                                                        maximumTrackStyle={{
                                                            backgroundColor: '#DCDCDC',
                                                            height: 10,
                                                        }}
                                                        minimumTrackStyle={{
                                                            backgroundColor: '#1E90FF',
                                                            height: 10,
                                                        }}
                                                        handleStyle={{
                                                            borderColor: '#87CEFA',
                                                            height: 28,
                                                            width: 28,
                                                            marginLeft: -14,
                                                            marginTop: -9,
                                                            backgroundColor: 'white',
                                                        }}
                                                        handle={MyHandle}
                                                        min={1}
                                                        max={50}
                                                        value={noOfProducts}
                                                        onChange={(event) => handleSlider(event, 0)}
                                                    />
                                                </Col>
                                                <Col xs="2">
                                                    <Input
                                                        type="text"
                                                        pattern="[0-9.]+"
                                                        maxLength="3"
                                                        onChange={(event) => handleInput(event, 0)}
                                                        value={noOfProducts}
                                                    />
                                                </Col>
                                            </Container>
                                        </Row>
                                        <Row className="mt-4">
                                            <Container
                                                style={{
                                                    display: `flex`,
                                                    justifyContent: `space-around`,
                                                }}
                                            >
                                                <Col xs="5">
                                                    <p>Months of Management</p>
                                                </Col>
                                                <Col xs="5">
                                                    <Slider
                                                        tipProps={{overlayClassName: 'foo'}}
                                                        maximumTrackStyle={{
                                                            backgroundColor: '#DCDCDC',
                                                            height: 10,
                                                        }}
                                                        minimumTrackStyle={{
                                                            backgroundColor: '#1E90FF',
                                                            height: 10,
                                                        }}
                                                        handleStyle={{
                                                            borderColor: '#87CEFA',
                                                            height: 28,
                                                            width: 28,
                                                            marginLeft: -14,
                                                            marginTop: -9,
                                                            backgroundColor: 'white',
                                                        }}
                                                        handle={MyHandle}
                                                        min={1}
                                                        max={12}
                                                        value={monthsOfManagement}
                                                        onChange={(event) => handleSlider(event, 1)}
                                                    />
                                                </Col>
                                                <Col xs="2">
                                                    <Input
                                                        type="text"
                                                        pattern="[0-9.]+"
                                                        maxLength="2"
                                                        onChange={(event) => handleInput(event, 1)}
                                                        value={monthsOfManagement}
                                                    />
                                                </Col>
                                            </Container>
                                        </Row>
                                        <Row className="mt-4">
                                            <Container
                                                style={{
                                                    display: `flex`,
                                                    justifyContent: `space-around`,
                                                }}
                                            >
                                                <Col xs="2">
                                                    <Row>
                                                        <p>Total Credits</p>
                                                    </Row>
                                                    <Row>
                                                        <Input
                                                            type="number"
                                                            min="0"
                                                            value={totalCredits}
                                                            readOnly
                                                        />
                                                    </Row>
                                                </Col>
                                                <span style={{borderLeft: `1px solid #202020`}}>
                          {' '}
                        </span>
                                                <Col xs="2">
                                                    <Row>
                                                        <p>Cost per credit</p>
                                                    </Row>
                                                    <Row>
                                                        <Input
                                                            type="number"
                                                            min="0"
                                                            value={costPerCredit}
                                                            readOnly
                                                        />
                                                    </Row>
                                                </Col>
                                            </Container>
                                        </Row>
                                        <Row className="mt-4">
                                            <Container
                                                style={{
                                                    display: `flex`,
                                                    justifyContent: `space-around`,
                                                }}
                                            >
                                                {purchasePackageLabel}
                                            </Container>
                                        </Row>
                                        <Row className="mt-4">
                                            <Container
                                                style={{
                                                    display: `flex`,
                                                    justifyContent: `space-around`,
                                                }}
                                            >
                                                <Button
                                                    color="success"
                                                    onClick={() => handleCheckout()}
                                                >
                                                    Checkout Now
                                                </Button>
                                            </Container>
                                        </Row>
                                    </Container>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/*<Row className="mb-3">*/}
                    {/*    <Col md={{offset: 3, size: 6}}>*/}
                    {/*        <RedeemPromoCode />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        {/*<Col className="mb-3" md="12">*/}
                        {/*    <CreditsPurchaseForm />*/}
                        {/*</Col>*/}
                        <CreditsPurchaseForm/>
                        {/* <Col md="6">
                            <RedeemPromoCode />
                        </Col> */}
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    firstName: state.accountOverview.landing.readyCampaigns,
    userName: `${state.loggedUser.user.first_name} ${state.loggedUser.user.last_name}`,
    userEmail: state.loggedUser.user.email,
});

export default connect(mapStateToProps)(CreditsPurchasePage);
