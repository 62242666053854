import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Can from "../../../../../../Components/Can";
import TimeFilteredDataDisplay from "./TimeFilteredDataDisplay";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import KeywordTable from "../../../../../Components/Tables/KeywordTable";
import ParentProductKeywordService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ParentProductKeywordService";
import KeywordPerformanceTabs from "./Nav/KeywordPerformanceTabs";
import KeywordAlertTabs from "./Nav/KeywordAlertTabs";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import ProductBudgetCard from "./Cards/ProductBudgetCard";
import ParentProductKeywordTable from "../../../../../Components/Tables/ParentProductKeywordTable";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import ProductOverviewService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService";
import ProductBudgetAllocationCard from "./Cards/ProductBudgetAllocationCard";
import ProductStageCard from './Cards/ProductStageCard';
import {
    UnallocatedProductBudgetService,
    UpdateProductBudgetService
} from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductBudgetServices";
import {
    ProductBleederKeywordService, ProductIdleKeywordService,
    TimeRangeProductDataService
} from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductPerformanceDataServices";
import UpdateProductStageService from '../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductStageServices';



const campaignPerformanceStyles = [
    "bg-happy-green text-white",
    "bg-midnight-bloom text-white",
    "bg-amy-crisp text-white",
    "bg-deep-blue",
];


function ProductPerformancePane(props) {

    const { selectedProducts, performanceData } = props;
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
    const [unallocatedBudget, setUnallocatedBudget] = useState(undefined);
    const [productStage, setProductStage] = useState(undefined);

    const isParentProduct = () => selectedProducts[0].isParentProduct;

    const defaultEndDate = new Date();
    const defaultStartDate = new Date(defaultEndDate.getTime() - (7 * 24 * 60 * 60 * 1000));


    useEffect(() => {
        console.log('On Load ProductPerformancePane');
        console.log(props.productASIN);
        if (props.productASIN) {
            getProductPerformanceData(defaultStartDate, defaultEndDate);
        }
    }, [props.productASIN])

    useEffect(() => {
        if (props.productASIN, props.stage) {
            UpdateProductStageService(props.token, props.productASIN, props.stage).then(stage => {
                setProductStage(stage);
            })
        }
    }, [props.productASIN, props.stage, props.token])

    useEffect(() => {
        if (props.isProductManaged) {
            //UnallocatedProductBudgetService(props.token, props.productASIN).then(budget => {
            //    setUnallocatedBudget(budget);
            //})
        }
    }, [props.productBudget, props.productASIN, props.isProductManaged, props.token]);

    // if selected product is a parent product -> fetches its keywords for selected time range
    // if selected product is a child product -> fetches performance data for selected time range and keyword alerts
    const getProductPerformanceData = (startDate, endDate) => {
        if (isParentProduct()) {
            console.log('In getProductPerformanceData isParentProduct true')
            props.dispatch(ParentProductKeywordService(props.token, selectedProducts[0].ASIN, startDate, endDate));
        } else if (props.productASIN) {
            console.log('In getProductPerformanceData else')
            console.log(props.productASIN)
            props.dispatch(TimeRangeProductDataService(props.token, props.productASIN, startDate, endDate));
            //props.dispatch(ProductBleederKeywordService(props.token, props.productASIN));
            //props.dispatch(ProductIdleKeywordService(props.token, props.productASIN))
        }
    }

    const handleProductBudgetUpdate = (updatedBudget) => {
        UpdateProductBudgetService(props.token, props.storeIntegerID, props.productASIN, updatedBudget).then((isSuccess) => {
            if (isSuccess) {
                props.dispatch(ProductOverviewService(props.token, props.productASIN, props.storeIntegerID));
            }
        })
    }


    const simpliworksDataFields = [
        {heading: "Sales", value: `$${performanceData.sales}`},
        {heading: "Units Sold", value: `${performanceData.conversions}`},
        {heading: "Advertising Spend", value: `$${performanceData.cost}`},
        {heading: "ROAS", value: `${performanceData.ROAS}`},
        {heading: "Impressions", value: `${performanceData.impressions}`},
        {heading: "Clicks", value: `${performanceData.clicks}`},
        {heading: "Units Ordered", value: `${performanceData.unitsOrdered}`},
    ];

    return (
        <Can
            role={props.accessLevel}
            perform={'products:view'}
            yes={() => (
                <Fragment>
                    <LoadingOverlay
                        active={props.productLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#f1f4f6',
                                opacity: 0.95,
                            }),
                            content: (base) => ({
                                ...base,
                                color: '#000',
                            }),
                        }}
                        spinner={<Loader active type="ball-pulse"/>}
                        text="Loading product details..."
                    >
                        {
                            !isParentProduct() &&
                            <>
                                <div className="mb-4">
                                    <h4 className="mbg-3">Simpliworks Campaign Performance</h4>
                                    <TimeFilteredDataDisplay
                                        modalHeader={"Simpliworks Campaign Performance"}
                                        data={props.performanceData}
                                        fetchData={getProductPerformanceData}
                                        dataFields={simpliworksDataFields}
                                        hideDateSelection={false}
                                        widgetStyles={campaignPerformanceStyles}
                                        defaultStartDate={defaultStartDate}
                                        defaultEndDate={defaultEndDate}
                                    />
                                </div>
                                {
                                    (props.productBudget !== undefined && props.isProductManaged) &&
                                    <Row form>
                                        <Col md="auto">
                                            <Can
                                                role={props.accessLevel}
                                                perform={"products:updateBudget"}
                                                yes={() => (
                                                    <ProductBudgetCard
                                                        onBudgetUpdate={handleProductBudgetUpdate}
                                                        productBudget={props.productBudget}
                                                    />
                                                )}
                                            />

                                        </Col>
                                        <Col md="auto">
                                            <Can
                                                role={props.accessLevel}
                                                perform={ "products:updateStage"}
                                                yes={() => (
                                                    <ProductStageCard
                                                        //productStage={productStage}
                                                        asin = {props.productASIN}
                                                        productStage =  {props.productStage}
                                                    />
                                                )}
                                            />
                                        </Col>

                                        <Col md="auto">
                                            <Can
                                                role={props.accessLevel}
                                                perform={"products:allocateBudget"}
                                                yes={() => (
                                                    <ProductBudgetAllocationCard
                                                        unallocatedBudget={unallocatedBudget}
                                                    />
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </>
                        }
                        <span>
                        <h4 style={{display: 'inline'}}>Keyword Performance</h4>
                            {
                                isParentProduct() &&
                                <Row className="mt-3 mb-5">
                                    <Col md="12">
                                        <Card>
                                             <CardHeader>Product Variation Keywords</CardHeader>
                                            <CardBody>
                                                <ParentProductKeywordTable data={props.parentProductKeywordData} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                    </span>
                        {
                            !isParentProduct() &&
                            <Row className="mt-3 mb-5">
                                <Col md="12">
                                    {(props.isProductManaged && !props.productLoading) ? (
                                        <>
                                            <KeywordPerformanceTabs
                                                convertingKeywords={props.convertingKeywords}
                                                impressionKeywords={props.impressionKeywords}
                                                allKeywords={props.allKeywords}
                                            />
                                            <Can
                                                role={props.accessLevel}
                                                perform={"keywordAlerts:view"}
                                                yes={() => (
                                                    <>
                                                        {/* <KeywordAlertTabs
                                                            summaryView={true}
                                                            setIsModalOpen={setIsAlertModalOpen}
                                                        />
                                                        <CustomModal
                                                            isOpen={isAlertModalOpen}
                                                            toggle={() => setIsAlertModalOpen(!isAlertModalOpen)}
                                                            header={"Keyword Alerts"}
                                                            size={"xl"}
                                                        >
                                                            <KeywordAlertTabs
                                                                summaryView={false}
                                                            />
                                                        </CustomModal> */}
                                                    </>
                                                )}
                                            />
                                        </>

                                    ) : (
                                        <Card>
                                            <CardBody>
                                                <KeywordTable
                                                    data={props.keywordTableData}
                                                    simpleView={true}
                                                />
                                            </CardBody>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        }
                    </LoadingOverlay>

                </Fragment>
            )}
        />
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    productASIN: state.accountOverview.product.productOverview.asin,
    productBudget: state.accountOverview.product.productOverview.productBudget,
    productStage: state.accountOverview.product.productOverview.productStage,    
    parentProductKeywordData: state.accountOverview.product.parentProductKeywordData,
    keywordTableData: state.accountOverview.product.keywordTableData,
    productLoading: state.accountOverview.product.loading,
    topKeywords: state.accountOverview.product.topKeywords,
    impressionGeneratingKeywords: state.accountOverview.product.impressionGeneratingKeywords,
    isProductManaged: state.accountOverview.product.productOverview.isManaged,
    performanceData: state.accountOverview.product.performanceData,
    storeIntegerID: state.accountOverview.product.performanceData.integerID,
    convertingKeywords: state.accountOverview.product.convertingKeywords,
    impressionKeywords: state.accountOverview.product.impressionKeywords,
    allKeywords: state.accountOverview.product.allKeywords,
    campaignData: state.accountOverview.product.campaignData,
});

export default connect(mapStateToProps)(ProductPerformancePane);
