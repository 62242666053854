import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadProductCategories } from "../../../Redux/Actions/Actions";
import { LoadResearchProductCategoryData } from "../../../Redux/Actions/Actions";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState } from 'react';

function LeadGenProdCatRerunJobService(token, jobId) {

     

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_PRODUCT_CATEGORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/RerunPendingJobs/${jobId}`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            console.log(res.data)        
            dispatch(LoadResearchProductCategoryData(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED });

        });
    }
}

export default LeadGenProdCatRerunJobService;