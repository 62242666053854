import React, { Fragment } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import DashboardNav from "./DashboardNav";
import HeaderLogo from "../Header/HeaderLogo";
import {setEnableMobileMenu} from "../../../../Redux/Actions/Actions";
import urls from "../../../../Utils/URLs";


function DashboardSidebar(props) {

    const toggleMobileSidebar = () => {
        props.dispatch(setEnableMobileMenu(!props.enableMobileMenu));
    }

    const acctOverviewMenu = props.stores.length === 0 ? [
            {
                icon: 'pe-7s-home',
                label: 'My Stores',
                to: urls.ACCOUNT_OVERVIEW_MY_STORES_URL,
            },
        ]
        :
        [
            {
                icon: 'pe-7s-home',
                label: 'My Stores',
                content:  props.stores.map(store => ({
                    icon: 'pe-7s-home',
                    label: `${store.name} (${store.location})`,
                    to: `${urls.ACCOUNT_OVERVIEW_MY_STORES_URL}/${store.name}/${store.location}`,
                }))
            },
        ];

    const createCampaignMenu = [
        {
            icon: 'pe-7s-graph',
            label: 'New Campaign',
            to: urls.CREATE_CAMPAIGN_INFO_URL,
        },
    ];

    const launchpadMenu = [
        {
            icon: 'pe-7s-graph',
            label: 'Ready To Launch',
            to: urls.LAUNCHPAD_READY_CAMPAIGNS_URL,
        },
        {
            icon: 'pe-7s-graph',
            label: 'Campaign Cart',
            to: urls.LAUNCHPAD_CART_OVERVIEW_URL,
        },
    ];


    return (
        <Fragment>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/>
            <div className="app-sidebar bg-white sidebar-shadow">
                <HeaderLogo/>
                <PerfectScrollbar>
                    <div className="app-sidebar__inner">
                        <DashboardNav menuItems={props.menuItems} heading={props.heading} />
                        {/*<DashboardNav menuItems={acctOverviewMenu} heading={"Account Overview"} />*/}
                        {/*<DashboardNav menuItems={createCampaignMenu} heading={"Create Campaign"} />*/}
                        {/*<DashboardNav menuItems={launchpadMenu} heading={"Launchpad"} />*/}
                    </div>
                </PerfectScrollbar>
                <div
                    className="app-sidebar-bg">
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    enableMobileMenu: state.theme.enableMobileMenu,
    stores: state.accountOverview.landing.adProfiles,
});

export default connect(mapStateToProps)(DashboardSidebar);
