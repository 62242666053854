import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadProjects } from "../../../Redux/Actions/Actions";


function ProjectsListService(token) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PROJECTS_BEGIN });

        axios.get(`${CURRENT_API_URL}/onboarding/assignProject`, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadProjects(res.data.projects));
        }).catch(() => {
            dispatch({ type: actionType.LOAD_PROJECTS_FAILED });
        })
    }

}

export default ProjectsListService;
