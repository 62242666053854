import React, { useState, useEffect, Fragment } from 'react';
import {
    Form,
    TextField,
    SubmitButton,
    CustomCheckboxField,
    RangeField,
    MultiTextField,
    FileUpload,
    RadioField
} from './FieldElements';
import * as Yup from 'yup';
import {
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
} from '../../../../../../Scenes/Components/Fileupload/file-upload.styles';
import { getFilenameWithTimestamp, productLine, buyingBehaviour, brandVoiceOpts, setBrandImage, setAttribute } from '../../../../../../Utils/Utilities';


export const BrandOverviewSection = (props) => {

    const [isEdit, setIsEdit] = useState(false)
    const { sectionData, editSectionField } = props;

    const formData = {
        brand_name: "",
        completed_by_fn: "",
        completed_by_ln: "",
        website: "",
        products_market_place: [],
        products_market_place_other_text: "",
        social_links: [
            {
                socialLink: ""
            },
            {
                socialLink: ""
            },
            {
                socialLink: ""
            },
            {
                socialLink: ""
            },
        ],
        selling_products_count: 0,
        brand_mission: "",
        describe_your_brand: "",
        customer_contact_number: ""
    }

    useEffect(() => {
        if (sectionData && sectionData.is_brand_overview_submitted) {
            setIsEdit(true)
        }
        else{
          setIsEdit(false)
        }
    }, [sectionData])

    const validationSchema = Yup.object({
        brand_name: Yup.string().required('Brand Name is required').max(1000, 'Must be 1000 Characters or less'),
        completed_by_fn: Yup.string().required('First Name is required').max(500, 'Must be 500 Characters or less'),
        completed_by_ln: Yup.string().required('Last Name is required').max(500, 'Must be 500 Characters or less'),
        website: Yup.string().url('Invalid URL').required('Website is required').max(2000, 'Must be 2000 Characters or less'),
        products_market_place: Yup.array().required('This field is required'),
        social_links: Yup.array()
            .of(
                Yup.object().shape({
                    socialLink: Yup.string().url('Invalid URL').max(2000, 'Must be 2000 Characters or less')
                })
            ).required('This field is required'),
        selling_products_count: Yup.number().min(1, 'Its must be minimum of 1'),
        brand_mission: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
        describe_your_brand: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
        customer_contact_number: Yup.string().required('This field is required').max(20, 'Must be 20 Characters or less'),
    })



    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
        let payload = { ...values, social_links: values.social_links.map(s => s.socialLink), store_integer_id: props.storeId }
        props.sumbmitSection(payload, 'onboarding/saveBrandOverView', resetForm)
        setSubmitting(false);
    }

    const editField = (fieldSet) => {
        editSectionField(fieldSet)
    }

    const getSectionData = (_sectionData) => {
        let data = {
            brand_name: _sectionData.brand_name,
            completed_by_fn: _sectionData.completed_by_fn,
            completed_by_ln: _sectionData.completed_by_ln,
            website: _sectionData.website,
            products_market_place: _sectionData.products_market_place,
            products_market_place_other_text: _sectionData.products_market_place_other_text,
            social_links: _sectionData.social_links,
            selling_products_count: _sectionData.selling_products_count,
            brand_mission: _sectionData.brand_mission,
            describe_your_brand: _sectionData.describe_your_brand,
            customer_contact_number: _sectionData.describe_your_brand
        }

        return data;
    }

    return (
        <>
            {
                !isEdit &&
                <div className="App">
                    <Form
                        enableReinitialize
                        initialValues={formData}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >

                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="form-group">
                                    <TextField type="text" name="brand_name" label="Brand Name" placeholder="Brand Name" required={true} />
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group pb-0 mb-0">
                                    <label><strong>Completed By <sup className="text-danger">*</sup></strong></label>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <TextField type="text" name="completed_by_fn" label="First Name" placeholder="First Name" required={true} />
                            </div>
                            <div className="col-12 col-md-5">
                                <TextField type="text" name="completed_by_ln" label="Last Name" placeholder="Last Name" required={true} />
                            </div>
                            <div className="col-12 col-md-12">
                                <div className="form-group">
                                    <TextField type="url" name="website" label="Website" placeholder="Website" required={true} />
                                </div>
                                <div className="form-group">
                                    <CustomCheckboxField name="products_market_place" required={true} label="Where do you currently sell your products?"
                                        options={[
                                            {
                                                "type": "checkbox",
                                                "label": "Amazon",
                                                "value": "Amazon"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Walmart",
                                                "value": "Walmart"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Ebay",
                                                "value": "Ebay"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Website",
                                                "value": "Website"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Brick & Mortar Retail",
                                                "value": "Brick and Mortar Retail"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Wholesale",
                                                "value": "Wholesale"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "International Markets",
                                                "value": "International Markets"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Other",
                                                "value": "Other"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className="form-group">
                                    <MultiTextField required={true} name="social_links" label="Where can customers find your company?"
                                        options={
                                            [
                                                {
                                                    "type": "url",
                                                    "label": "Facebook",
                                                    "name": "socialLink",
                                                    "maxLength": 2000
                                                },
                                                {
                                                    "type": "url",
                                                    "label": "Instagram",
                                                    "name": "socialLink",
                                                    "maxLength": 2000
                                                },
                                                {
                                                    "type": "url",
                                                    "label": "Youtube",
                                                    "name": "socialLink",
                                                    "maxLength": 2000
                                                },
                                                {
                                                    "type": "url",
                                                    "label": "Other 3",
                                                    "name": "socialLink",
                                                    "maxLength": 2000
                                                }
                                            ]
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <RangeField name="selling_products_count" type="range" label="How many products do you actively sell across your channels?"
                                        min={1}
                                        max={200}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextField type="text" name="brand_mission" label="What is your Brand’s mission?" placeholder="" required={true} />
                                </div>
                                <div className="form-group">
                                    <TextField type="text" name="describe_your_brand" label="Describe your brand in just one or two sentences?" placeholder="" required={true} />
                                </div>
                                <div className="form-group">
                                    <TextField type="text" name="customer_contact_number" label="What is the best number to contact you regarding project questions?" placeholder="Phone number" required={true} />
                                </div>
                            </div>
                        </div>
                        {
                            !isEdit &&
                            <SubmitButton className="btn btn-primary btn-sm my-2 float-right"
                                title="Submit"
                            />
                        }


                    </Form>
                </div>

            }

            {
                (isEdit && sectionData) &&
                <div className="AppEdit">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Brand Name?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        brand_name: Yup.string().required('This field is required')
                                    })
                                    let formData = {
                                        'brand_name': sectionData.brand_name
                                    }
                                    editField({
                                        name: 'brand_name', label: 'Brand Name?', type: 'text', data: getSectionData(sectionData),
                                        inputType: 'text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">{sectionData.brand_name}</p>
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Completed by?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        completed_by_fn: Yup.string().required('First Name is required').max(500, 'Must be 500 Characters or less'),
                                        completed_by_ln: Yup.string().required('Last Name is required').max(500, 'Must be 500 Characters or less'),
                                    })
                                    let formData = {
                                        completed_by_fn: sectionData.completed_by_fn,
                                        completed_by_ln: sectionData.completed_by_ln,
                                    }
                                    editField({
                                        name: 'completed_by', label: 'Completed by?', type: 'text', data: getSectionData(sectionData),
                                        inputType: 'text_completeBy',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">{sectionData.completed_by_fn}, {sectionData.completed_by_ln}</p>
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Website?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        website: Yup.string().url('Invalid URL').required('Website is required').max(2000, 'Must be 2000 Characters or less'),
                                    })
                                    let formData = {
                                        website: sectionData.website
                                    }
                                    editField({
                                        name: 'website', label: 'Website?', type: 'url', data: getSectionData(sectionData),
                                        inputType: 'text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">{sectionData.website}</p>
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Where do you currently sell your products?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        products_market_place: Yup.array().required('This field is required'),
                                        products_market_place_other_text: Yup.string()
                                    })
                                    let formData = {
                                        products_market_place: sectionData.products_market_place.filter(p => p),
                                        products_market_place_other_text: sectionData.products_market_place_other_text
                                    }
                                    editField({
                                        name: 'products_market_place', label: 'Where do you currently sell your products?', type: 'checkbox', data: getSectionData(sectionData),
                                        inputType: 'custom_checkbox',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview',
                                        options: [
                                            {
                                                "type": "checkbox",
                                                "label": "Amazon",
                                                "value": "Amazon"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Walmart",
                                                "value": "Walmart"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Ebay",
                                                "value": "Ebay"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Website",
                                                "value": "Website"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Brick & Mortar Retail",
                                                "value": "Brick and Mortar Retail"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Wholesale",
                                                "value": "Wholesale"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "International Markets",
                                                "value": "International Markets"
                                            },
                                            {
                                                "type": "checkbox",
                                                "label": "Other",
                                                "value": "Other"
                                            }
                                        ]
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <div className="pb-1 mb-1">
                                <ul>
                                    {sectionData && sectionData.products_market_place && sectionData.products_market_place.map((d, key) =>
                                        <li key={key}>{d}</li>
                                    )}
                                    {
                                        sectionData.products_market_place_other_text &&
                                        <li>{sectionData.products_market_place_other_text}</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Where can customers find your company?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        social_links: Yup.array()
                                            .of(
                                                Yup.object().shape({
                                                    socialLink: Yup.string().url('Invalid URL').max(2000, 'Must be 2000 Characters or less')
                                                })
                                            ).required('This field is required')
                                    })
                                    let formData = {
                                        'social_links': sectionData.social_links.map(s => {
                                            return {
                                                socialLink: s
                                            }
                                        })
                                    }
                                    editField({
                                        name: 'social_links', label: 'Where can customers find your company?', type: 'url', data: getSectionData(sectionData),
                                        inputType: 'multi_text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview',
                                        options: [
                                            {
                                                "type": "url",
                                                "label": "Facebook",
                                                "name": "socialLink",
                                                "maxLength": 2000
                                            },
                                            {
                                                "type": "url",
                                                "label": "Instagram",
                                                "name": "socialLink",
                                                "maxLength": 2000
                                            },
                                            {
                                                "type": "url",
                                                "label": "Youtube",
                                                "name": "socialLink",
                                                "maxLength": 2000
                                            },
                                            {
                                                "type": "url",
                                                "label": "Other 3",
                                                "name": "socialLink",
                                                "maxLength": 2000
                                            }
                                        ]
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <div className="pb-1 mb-1">
                                <ul>
                                    {sectionData.social_links.map((d, key) =>
                                        <Fragment key={key}>
                                            {d && <li>{d}</li>}
                                        </Fragment>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>How many products do you actively sell across your channels?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        selling_products_count: Yup.number().min(1, 'Its must be minimum of 1')
                                    })
                                    let formData = {
                                        'selling_products_count': sectionData.selling_products_count
                                    }
                                    editField({
                                        name: 'selling_products_count', label: 'How many products do you actively sell across your channels?', type: 'range', data: getSectionData(sectionData),
                                        inputType: 'range_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.selling_products_count > 0 ? sectionData.selling_products_count : ''}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>What is your Brand’s mission</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        brand_mission: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less')
                                    })
                                    let formData = {
                                        'brand_mission': sectionData.brand_mission
                                    }
                                    editField({
                                        name: 'brand_mission', label: 'What is your Brand’s mission?', type: 'text', data: getSectionData(sectionData),
                                        inputType: 'text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.brand_mission}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>Describe your brand in just one or two sentences</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        describe_your_brand: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
                                    })
                                    let formData = {
                                        'describe_your_brand': sectionData.describe_your_brand
                                    }
                                    editField({
                                        name: 'describe_your_brand', label: 'Describe your brand in just one or two sentences', type: 'text', data: getSectionData(sectionData),
                                        inputType: 'text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.describe_your_brand}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="d-flex justify-content-between pb-0 mb-0">
                                <strong>What is the best number to contact you regarding project questions?</strong>
                                <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                    let validationSchema = Yup.object({
                                        customer_contact_number: Yup.string().required('This field is required').max(20, 'Must be 20 Characters or less'),
                                    })
                                    let formData = {
                                        'customer_contact_number': sectionData.customer_contact_number
                                    }
                                    editField({
                                        name: 'customer_contact_number', label: 'What is the best number to contact you regarding project questions?', type: 'text', data: getSectionData(sectionData),
                                        inputType: 'text_field',
                                        validationSchema: validationSchema,
                                        formData: formData,
                                        required: true,
                                        section: 'Brand Overview'
                                    })
                                }} title="Edit"><i className="fas fa-edit"></i></button>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.customer_contact_number}
                            </p>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}



export const BrandGuidelines = (props) => {

    const [isEdit, setIsEdit] = useState(false)
    const { sectionData, editSectionField } = props;

    const formData = {
        not_include_language: "",
        brand_guidlines_documents: [],
        brand_logo: [],
        branded_images: []
    }

    useEffect(() => {
        if (sectionData && sectionData.is_brand_guide_lines_submitted) {
            setIsEdit(true)
        }
        else{
          setIsEdit(false)
        }
    }, [sectionData])

    const validationSchema = Yup.object({
        not_include_language: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
        brand_guidlines_documents: Yup.array(),
        brand_logo: Yup.mixed(),
        branded_images: Yup.array()
    })

    const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
        let formData = new FormData()
        if (values.brand_guidlines_documents && values.brand_guidlines_documents.length > 0) {
            values.brand_guidlines_documents.forEach(file => {
                formData.append('brand_guidlines_documents', file, getFilenameWithTimestamp(file.name))
            });
        }

        if (values.brand_logo && values.brand_logo.length > 0) {
            formData.append('brand_logo', values.brand_logo[0], getFilenameWithTimestamp(values.brand_logo[0].name))
        }
        if (values.branded_images && values.branded_images.length > 0) {
            values.branded_images.forEach(file => {
                formData.append('branded_images', file, getFilenameWithTimestamp(file.name))
            });
        }
        formData.append('not_include_language', values.not_include_language);
        formData.append('store_integer_id', props.storeId)
        props.sumbmitSection(formData, 'onboarding/saveBrandGuideLines', resetForm)
        setSubmitting(false);
    }

    const getSectionData = (_sectionData) => {
        return {
            not_include_language: _sectionData.not_include_language,
            brand_guidlines_documents: _sectionData.brand_guidlines_documents ? _sectionData.brand_guidlines_documents : [],
            brand_logo: _sectionData.brand_logo,
            branded_images: _sectionData.branded_images ? _sectionData.branded_images : []
        }
    }

    const editField = (fieldSet) => {
        editSectionField(fieldSet)
    }

    return (
        <>
            {
                !isEdit &&
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <br />
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <FileUpload
                                    label="If you have your brand guidelines in a document, please upload here"
                                    name="brand_guidlines_documents"
                                    updateFilesCb={(files) => { }}
                                    previousFiles={[]}
                                    maxFileSizeInBytes={5 * 1024 * 1024}
                                    totalSizeAllowed={5 * 1024 * 1024}
                                    maxFiles={50}
                                    onFileError={(err) => { }}
                                    multiple={true}
                                    accept=".docx, .doc, .xls, .xlsx, .pdf"
                                />
                            </div>
                            <div className="form-group">
                                <TextField type="text"
                                    name="not_include_language"
                                    label="Are there any claims or specific language we should NOT use when describing your products?"
                                    placeholder="" required={true} />
                            </div>
                            <br />
                            <div className="form-group">
                                <FileUpload
                                    label="Brand Logo"
                                    name="brand_logo"
                                    updateFilesCb={(files) => { }}
                                    previousFiles={[]}
                                    maxFileSizeInBytes={5 * 1024 * 1024}
                                    totalSizeAllowed={5 * 1024 * 1024}
                                    maxFiles={50}
                                    onFileError={(err) => { }}
                                    multiple={false}
                                    accept=".png, .jpg, .jpeg"
                                />
                            </div>
                            <div className="form-group">
                                <FileUpload
                                    label="Branded images"
                                    name="branded_images"
                                    updateFilesCb={(files) => { }}
                                    previousFiles={[]}
                                    maxFileSizeInBytes={5 * 1024 * 1024}
                                    totalSizeAllowed={5 * 1024 * 1024}
                                    maxFiles={50}
                                    onFileError={(err) => { }}
                                    multiple={true}
                                    accept=".png, .jpg, .jpeg"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <SubmitButton className="btn btn-primary btn-sm my-2 float-right"
                                title="Submit"
                            />
                        </div>

                    </div>
                </Form>

            }

            {
                isEdit && sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>If you have your brand guidelines in a document, please upload here?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let allowedSize = 5 * 1024 * 1024;
                                let count = 0
                                if (sectionData.brand_guidlines_documents && sectionData.brand_guidlines_documents.length > 0) {

                                    sectionData.brand_guidlines_documents.forEach(doc => {
                                        if (doc)
                                            count += doc.file_size
                                    })

                                    allowedSize = allowedSize - count;
                                }
                                let validationSchema = Yup.object({
                                    brand_guidlines_documents: Yup.array()
                                })
                                let formData = {
                                    'brand_guidlines_documents': sectionData.brand_guidlines_documents ? sectionData.brand_guidlines_documents.filter(doc => doc) : []
                                }
                                editField({
                                    name: 'brand_guidlines_documents', label: 'If you have your brand guidelines in a document, please upload here?', type: 'file', data: getSectionData(sectionData),
                                    inputType: 'file_upload_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Guideline',
                                    accept: ".docx, .doc, .xls, .xlsx, .pdf",
                                    multiple: true,
                                    maxFileSizeInBytes: 5 * 1024 * 1024,
                                    totalSizeAllowed: allowedSize,
                                    maxFiles: 50,
                                    uploadedSize: (count / (1000 * 1000)).toFixed(2) + ' MB',
                                    previousFiles: sectionData.brand_guidlines_documents ? sectionData.brand_guidlines_documents.filter(doc => doc) : []

                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        {
                            sectionData.brand_guidlines_documents && sectionData.brand_guidlines_documents.length > 0 &&

                            <FilePreviewContainer>
                                <PreviewList>
                                    {
                                        sectionData.brand_guidlines_documents.map((file, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {
                                                        file &&
                                                        <PreviewContainer>
                                                            <FileMetaData isImageFile={false}>
                                                                <span>{file.file_name}</span>
                                                                <aside>
                                                                    <span>{Math.round(file.file_size / 1000)} kb</span>
                                                                    <a href={file.file_path} target="_blank" className="text-white bold">
                                                                        <RemoveFileIcon
                                                                            className="fas fa-file-download" />
                                                                    </a>
                                                                </aside>
                                                            </FileMetaData>
                                                        </PreviewContainer>
                                                    }

                                                </Fragment>

                                            )
                                        })

                                    }
                                </PreviewList>
                            </FilePreviewContainer>

                        }
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Are there any claims or specific language we should NOT use when describing your products?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema = Yup.object({
                                    not_include_language: Yup.string().required('This field is required')
                                })
                                let formData = {
                                    'not_include_language': sectionData.not_include_language
                                }
                                editField({
                                    name: 'not_include_language', label: 'Are there any claims or specific language we should NOT use when describing your products?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'text_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Guideline'
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <p className="pb-1 mb-1">
                            {sectionData.not_include_language}
                        </p>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Brand Logo</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let allowedSize = 5 * 1024 * 1024;
                                let count = 0
                                if (sectionData.brand_logo) {
                                    count = sectionData.brand_logo.file_size
                                    allowedSize = allowedSize - count;
                                }
                                let validationSchema = Yup.object({
                                    brand_logo: Yup.mixed()
                                })
                                let formData = {
                                    brand_logo: sectionData.brand_logo ? [sectionData.brand_logo] : []
                                }
                                editField({
                                    name: 'brand_logo', label: 'Brand Logo?', type: 'file', data: getSectionData(sectionData),
                                    inputType: 'file_upload_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Guideline',
                                    accept: ".png, .jpg, .jpeg",
                                    multiple: false,
                                    maxFileSizeInBytes: 5 * 1024 * 1024,
                                    totalSizeAllowed: allowedSize,
                                    maxFiles: 50,
                                    uploadedSize: (count / (1000 * 1000)).toFixed(2) + ' MB',
                                    previousFiles: sectionData.brand_logo ? [sectionData.brand_logo] : []

                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        {
                            sectionData.brand_logo &&

                            <FilePreviewContainer>
                                <PreviewList>
                                    <PreviewContainer>
                                        <div>
                                            <ImagePreview
                                                src={sectionData.brand_logo.file_path}
                                                alt={`file preview ${sectionData.brand_logo.file_name}`}
                                            />
                                            <FileMetaData isImageFile={true}>
                                                <span>{sectionData.brand_logo.file_name}</span>
                                                <aside>
                                                    <span>{Math.round(sectionData.brand_logo.file_size / 1000)} kb</span>
                                                    <a href={sectionData.brand_logo.file_path} target="_blank" className="text-white bold">
                                                        <RemoveFileIcon
                                                            className="fas fa-file-download" />
                                                    </a>
                                                </aside>
                                            </FileMetaData>
                                        </div>
                                    </PreviewContainer>
                                </PreviewList>
                            </FilePreviewContainer>

                        }
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Branded Images?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let allowedSize = 5 * 1024 * 1024;
                                let count = 0
                                if (sectionData.branded_images && sectionData.branded_images.length > 0) {

                                    sectionData.branded_images.forEach(doc => {
                                        if (doc)
                                            count += doc.file_size
                                    })

                                    allowedSize = allowedSize - count;
                                }
                                let validationSchema = Yup.object({
                                    branded_images: Yup.array()
                                })
                                let formData = {
                                    'branded_images': sectionData.branded_images ? sectionData.branded_images : []
                                }
                                editField({
                                    name: 'branded_images', label: 'If you have your brand guidelines in a document, please upload here?', type: 'file', data: getSectionData(sectionData),
                                    inputType: 'file_upload_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Guideline',
                                    accept: ".png, .jpg, .jpeg",
                                    multiple: true,
                                    maxFileSizeInBytes: 5 * 1024 * 1024,
                                    totalSizeAllowed: allowedSize,
                                    maxFiles: 50,
                                    uploadedSize: (count / (1000 * 1000)).toFixed(2) + ' MB',
                                    previousFiles: sectionData.branded_images ? sectionData.branded_images.filter(img => img) : []

                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        {
                            sectionData.branded_images && sectionData.branded_images.length > 0 &&

                            <FilePreviewContainer>
                                <PreviewList>
                                    {
                                        sectionData.branded_images.map((file, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {
                                                        file &&
                                                        <PreviewContainer >
                                                            <div>
                                                                <ImagePreview
                                                                    src={file.file_path}
                                                                    alt={`file preview ${file.file_name}`}
                                                                />
                                                                <FileMetaData isImageFile={true}>
                                                                    <span>{file.file_name}</span>
                                                                    <aside>
                                                                        <span>{Math.round(file.file_size / 1000)} kb</span>
                                                                        <a href={file.file_path} target="_blank" className="text-white bold">
                                                                            <RemoveFileIcon
                                                                                className="fas fa-file-download" />
                                                                        </a>
                                                                    </aside>
                                                                </FileMetaData>
                                                            </div>
                                                        </PreviewContainer>
                                                    }

                                                </Fragment>
                                            )
                                        })

                                    }
                                </PreviewList>
                            </FilePreviewContainer>

                        }
                    </div>
                </div>
            }

        </>
    )
}


export const Customer = (props) => {

    const [isEdit, setIsEdit] = useState(false)
    const { sectionData, editSectionField } = props;

    const formData = {
        product_line: [],
        customer_segment: "",
        buying_behaviour: [],
        brand_specialty: ""
    }



    useEffect(() => {
        if (sectionData && sectionData.is_customer_submitted) {
            setIsEdit(true)
        }
        else{
          setIsEdit(false)
        }
    }, [sectionData])

    const validationSchema = Yup.object({
        product_line: Yup.array().required('This field is required').max(2, 'You can select only 2 options'),
        customer_segment: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
        buying_behaviour: Yup.array().required('This field is required'),
        brand_specialty: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
    })

    const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
        let payload = { ...values, store_integer_id: props.storeId }
        props.sumbmitSection(payload, 'onboarding/saveCustomerSection', resetForm)
        setSubmitting(false);
    }

    const getSectionData = (_sectionData) => {
        let data = {
            product_line: _sectionData.product_line,
            customer_segment: _sectionData.customer_segment,
            buying_behaviour: _sectionData.buying_behaviour,
            brand_specialty: _sectionData.brand_specialty
        }

        return data;
    }

    const getCustomFields = (field, value) => {
        if (field === 'product_line') {
            let tempData_1 = productLine.find(p => p.value === value)
            if (tempData_1) return tempData_1.label;
            return ''
        }
        else if (field === 'buying_behaviour') {
            let tempData_2 = buyingBehaviour.find(p => p.value === value)
            if (tempData_2) return tempData_2.label;
            return ''
        }

        return ''
    }

    const editField = (fieldSet) => {
        editSectionField(fieldSet)
    }

    return (
        <>
            {
                !isEdit &&
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >

                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <CustomCheckboxField required={true}
                                    limitCount={2}
                                    name="product_line" label="Which attributes would most describe your product line?"
                                    options={productLine}
                                />
                            </div>
                            <div className="form-group">
                                <TextField required={true} name="customer_segment" label="How would you describe your top two customer segments?" placeholder="" />
                            </div>
                            <div className="form-group">
                                <CustomCheckboxField required={true}
                                    name="buying_behaviour"
                                    label="How would you describe your customers buying behaivior?"
                                    options={buyingBehaviour}
                                />
                            </div>
                            <div className="form-group">
                                <TextField required={true}
                                    name="brand_specialty"
                                    label="What specifically drives customers to your brand?" placeholder="" />
                            </div>

                        </div>
                        <div className="col-12 col-md-12">
                            <SubmitButton className="btn btn-primary btn-sm my-2 float-right"
                                title="Submit"
                            />
                        </div>
                    </div>
                </Form>
            }

            {
                isEdit && sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Which attributes would most describe your product line?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema = Yup.object({
                                    product_line: Yup.array().required('This field is required').max(2, 'You can select only 2 options'),
                                })
                                let formData = {
                                    'product_line': sectionData.product_line
                                }
                                editField({
                                    name: 'product_line', label: 'Which attributes would most describe your product line?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'custom_checkbox',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    limitCount: 2,
                                    section: 'Customer',
                                    options: productLine
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <div className="pb-1 mb-1">
                            <ul>
                                {
                                    sectionData.product_line.map(p => {
                                        return <li key={p} >{getCustomFields('product_line', p)}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you describe your top two customer segments?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema = Yup.object({
                                    customer_segment: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
                                })
                                let formData = {
                                    'customer_segment': sectionData.customer_segment
                                }
                                editField({
                                    name: 'customer_segment', label: 'How would you describe your top two customer segments?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'text_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Customer'
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <p className="pb-1 mb-1">{sectionData.customer_segment}</p>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you describe your customers buying behaivior?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema = Yup.object({
                                    buying_behaviour: Yup.array().required('This field is required')
                                })
                                let formData = {
                                    'buying_behaviour': sectionData.buying_behaviour
                                }
                                editField({
                                    name: 'buying_behaviour', label: 'How would you describe your customers buying behaivior?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'custom_checkbox',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Customer',
                                    options: buyingBehaviour
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <div className="pb-1 mb-1">
                            <ul>
                                {
                                    sectionData.buying_behaviour.map(p => {
                                        return <li key={p} >{getCustomFields('buying_behaviour', p)}</li>
                                    })
                                }
                            </ul>
                        </div>

                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>What specifically drives customers to your brand?</strong>
                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema = Yup.object({
                                    brand_specialty: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
                                })
                                let formData = {
                                    'brand_specialty': sectionData.brand_specialty
                                }
                                editField({
                                    name: 'brand_specialty', label: 'What specifically drives customers to your brand?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'text_field',
                                    validationSchema: validationSchema,
                                    formData: formData,
                                    required: true,
                                    section: 'Customer'
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <p className="pb-1 mb-1">{sectionData.brand_specialty}</p>
                    </div>
                </div>
            }

        </>
    )
}


export const BrandDetails = (props) => {


    const formData = {
        attribute_most_describes_1: "",
        attribute_most_describes_2: "",
        attribute_most_describes_3: "",
        attribute_most_describes_4: "",
        attribute_most_describes_5: "",
        attribute_most_describes_6: "",
        attribute_most_describes_7: "",
        attribute_most_describes_8: "",
        attribute_most_describes_9: "",

        describe_brand_voice: "",

        image_characteristics_1: "",
        image_characteristics_2: "",
        image_characteristics_3: "",
        image_characteristics_4: "",
        image_characteristics_5: "",
        image_characteristics_6: "",
        image_characteristics_7: "",
        image_characteristics_8: "",
        image_characteristics_9: "",
        image_characteristics_10: "",
        image_characteristics_11: "",

        other_words_about_your_brand: ""
    }

    const [isEdit, setIsEdit] = useState(false)
    const { sectionData, editSectionField } = props;
    const [brandVoice, setBrandVoice] = useState('')


    useEffect(() => {
        if (sectionData && sectionData.is_brand_details_submitted) {
            setIsEdit(true)
            let tempBrandV = brandVoiceOpts.find(b => b.value === sectionData.describe_brand_voice)
            if (tempBrandV) setBrandVoice(tempBrandV.label);

        }
        else{
          setIsEdit(false)
        }
    }, [sectionData])

    const validationSchema = Yup.object({
        attribute_most_describes_1: Yup.string().required('This field is required'),
        attribute_most_describes_2: Yup.string().required('This field is required'),
        attribute_most_describes_3: Yup.string().required('This field is required'),
        attribute_most_describes_4: Yup.string().required('This field is required'),
        attribute_most_describes_5: Yup.string().required('This field is required'),
        attribute_most_describes_6: Yup.string().required('This field is required'),
        attribute_most_describes_7: Yup.string().required('This field is required'),
        attribute_most_describes_8: Yup.string().required('This field is required'),
        attribute_most_describes_9: Yup.string().required('This field is required'),

        describe_brand_voice: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),

        image_characteristics_1: Yup.string().required('This field is required'),
        image_characteristics_2: Yup.string().required('This field is required'),
        image_characteristics_3: Yup.string().required('This field is required'),
        image_characteristics_4: Yup.string().required('This field is required'),
        image_characteristics_5: Yup.string().required('This field is required'),
        image_characteristics_6: Yup.string().required('This field is required'),
        image_characteristics_7: Yup.string().required('This field is required'),
        image_characteristics_8: Yup.string().required('This field is required'),
        image_characteristics_9: Yup.string().required('This field is required'),
        image_characteristics_10: Yup.string().required('This field is required'),
        image_characteristics_11: Yup.string().required('This field is required'),

        other_words_about_your_brand: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),

    })

    const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
        let payload = { ...values, store_integer_id: props.storeId }
        props.sumbmitSection(payload, 'onboarding/saveBrandDetails', resetForm)
        setSubmitting(false);
    }



    const getSectionData = (_sectionData) => {
        let data = {
            attribute_most_describes_1: _sectionData.attribute_most_describes_1,
            attribute_most_describes_2: _sectionData.attribute_most_describes_2,
            attribute_most_describes_3: _sectionData.attribute_most_describes_3,
            attribute_most_describes_4: _sectionData.attribute_most_describes_4,
            attribute_most_describes_5: _sectionData.attribute_most_describes_5,
            attribute_most_describes_6: _sectionData.attribute_most_describes_6,
            attribute_most_describes_7: _sectionData.attribute_most_describes_7,
            attribute_most_describes_8: _sectionData.attribute_most_describes_8,
            attribute_most_describes_9: _sectionData.attribute_most_describes_9,

            describe_brand_voice: _sectionData.describe_brand_voice,

            image_characteristics_1: _sectionData.image_characteristics_1,
            image_characteristics_2: _sectionData.image_characteristics_2,
            image_characteristics_3: _sectionData.image_characteristics_3,
            image_characteristics_4: _sectionData.image_characteristics_4,
            image_characteristics_5: _sectionData.image_characteristics_5,
            image_characteristics_6: _sectionData.image_characteristics_6,
            image_characteristics_7: _sectionData.image_characteristics_7,
            image_characteristics_8: _sectionData.image_characteristics_8,
            image_characteristics_9: _sectionData.image_characteristics_9,
            image_characteristics_10: _sectionData.image_characteristics_10,
            image_characteristics_11: _sectionData.image_characteristics_11,

            other_words_about_your_brand: _sectionData.other_words_about_your_brand,
        }

        return data;
    }

    const editField = (fieldSet) => {
        editSectionField(fieldSet)
    }

    return (
        <>
            {
                !isEdit &&
                <Form
                    enableReinitialize
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <h6 className="border-bottom bold pb-1"> Attributes</h6>
                    {
                        Object.keys(setAttribute).map((key, index) => {
                            return (
                                <div className="form-group" key={index}>
                                    <RadioField
                                        type="radio"
                                        required={true}
                                        name={key}
                                        label={setAttribute[key].label}
                                        options={setAttribute[key].options}
                                    />
                                </div>
                            )
                        })
                    }
                    <br />
                    <h6 className="border-bottom bold pb-1"> Voice</h6>
                    <div className="form-group">
                        <RadioField name="describe_brand_voice" label="How would you best describe your brand voice" type="radio" options={
                            brandVoiceOpts
                        }
                        />
                    </div>
                    <br />
                    <h6 className="border-bottom bold pb-1"> Image</h6>
                    {
                        Object.keys(setBrandImage).map((key, index) => {
                            return (
                                <div className="form-group" key={index}>
                                    <RadioField
                                        required={true}
                                        type="radio"
                                        name={key}
                                        label={setBrandImage[key].label}
                                        options={setBrandImage[key].options}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="form-group">
                        <TextField type="text"
                            required={true}
                            name="other_words_about_your_brand"
                            label="Would you use any other words to describe your brand?"
                            placeholder="" />
                    </div>

                    <div className="form-group">
                        <SubmitButton className="btn btn-primary btn-sm my-2 float-right"
                            title="Submit"
                        />
                    </div>
                </Form>

            }

            {
                isEdit && sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Attributes</h6>
                    </div>
                    {
                        Object.keys(setAttribute).map((key, index) => {
                            return (
                                <div className="col-12 col-md-12 mb-3" key={index}>
                                    <p className="d-flex justify-content-between pb-0 mb-0">
                                        <strong>{setAttribute[key].label}</strong>
                                        <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                            let validationSchema_ = Yup.object({
                                                [key]: Yup.string().required('This field is required')
                                            })
                                            let formData = {
                                                [key]: sectionData[key]
                                            }
                                            editField({
                                                name: key, label: setAttribute[key].label, type: 'radio', data: getSectionData(sectionData),
                                                inputType: 'radio_field',
                                                validationSchema: validationSchema_,
                                                formData: formData,
                                                required: true,
                                                section: 'Brand Details',
                                                options: setAttribute[key].options
                                            })
                                        }} title="Edit"><i className="fas fa-edit"></i></button>
                                    </p>
                                    <p className="pb-1 mb-1">{sectionData[key]}</p>
                                </div>
                            )
                        })
                    }

                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Voice</h6>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you best describe your brand voice?</strong>

                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema_ = Yup.object({
                                    describe_brand_voice: Yup.string().required('This field is required')
                                })
                                let formData = {
                                    describe_brand_voice: sectionData.describe_brand_voice
                                }
                                editField({
                                    name: 'describe_brand_voice', label: 'How would you best describe your brand voice?', type: 'radio', data: getSectionData(sectionData),
                                    inputType: 'radio_field',
                                    validationSchema: validationSchema_,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Details',
                                    options: brandVoiceOpts
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <p className="pb-1 mb-1">{brandVoice}</p>
                    </div>
                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Image</h6>
                    </div>
                    {
                        Object.keys(setBrandImage).map((key, index) => {
                            return (
                                <div className="col-12 col-md-12 mb-3" key={index}>
                                    <p className="d-flex justify-content-between pb-0 mb-0">
                                        <strong>{setBrandImage[key].label}</strong>
                                        <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                            let validationSchema_ = Yup.object({
                                                [key]: Yup.string().required('This field is required')
                                            })
                                            let formData = {
                                                [key]: sectionData[key]
                                            }
                                            editField({
                                                name: key, label: setBrandImage[key].label, type: 'radio', data: getSectionData(sectionData),
                                                inputType: 'radio_field',
                                                validationSchema: validationSchema_,
                                                formData: formData,
                                                required: true,
                                                section: 'Brand Details',
                                                options: setBrandImage[key].options
                                            })
                                        }} title="Edit"><i className="fas fa-edit"></i></button>
                                    </p>
                                    <p className="pb-1 mb-1">{sectionData[key]}</p>
                                </div>
                            )
                        })
                    }

                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Would you use any other words to describe your brand?</strong>

                            <button className="btn btn-link btn-sm" type="button" onClick={(e) => {
                                let validationSchema_ = Yup.object({
                                    other_words_about_your_brand: Yup.string().required('This field is required').max(1000, 'Must be 1000 Characters or less'),
                                })
                                let formData = {
                                    other_words_about_your_brand: sectionData.other_words_about_your_brand
                                }
                                editField({
                                    name: 'other_words_about_your_brand', label: 'Would you use any other words to describe your brand?', type: 'text', data: getSectionData(sectionData),
                                    inputType: 'text_field',
                                    validationSchema: validationSchema_,
                                    formData: formData,
                                    required: true,
                                    section: 'Brand Details',
                                })
                            }} title="Edit"><i className="fas fa-edit"></i></button>
                        </p>
                        <p className="pb-1 mb-1">{sectionData.other_words_about_your_brand}</p>
                    </div>


                </div>
            }

        </>
    )
}
