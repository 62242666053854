import React, {useState, useEffect} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import { connect } from 'react-redux';
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import {useHistory} from 'react-router-dom';

import LogoutService from "../../Service/EntryServices/LogoutService";
import urls from '../../Utils/URLs';
import actionType from '../../Redux/Actions/ActionTypes';
import CreateCampaignPage from "./Scenes/CreateCampaign/CreateCampaignPage";
import CreditsPage from "./Scenes/Credits/CreditsPage";
import DashboardHeader from "./Layout/Header/DashboardHeader";
import AccountOverviewPage from "./Scenes/AccountOverview/AccountOverviewPage";
import notify from "../../Notifications/ToastifyActions";
import LaunchpadPage from "./Scenes/Launchpad/LaunchpadPage";
import CreateProjectPage from './Scenes/CreateProject/CreateProjectPage';
import UserProfilePage from "./Scenes/UserProfile/UserProfilePage";
import SPAPIRedirectPage from "./Scenes/UserProfile/Scenes/Authorization/Components/SPAPIRedirectPage";
import ProtectedRoute from "../Components/Nav/ProtectedRoute";
import Can from "../Components/Can";
import TutorialModal from "./Components/Modals/Tutorial/TutorialModal";
import StoreListService from "../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
import SubscriptionEmailService from "../../Service/PaymentServices/SubscriptionEmailService";
import AdvertisingAPIAuthorizationService
    from "../../Service/DashboardServices/UserProfileServices/AdvertisingAPIAuthorizationService";
import OperationsPage from "./Scenes/Operations/OperationsPage";
import UserSubscriptionLevelService from "../../Service/PaymentServices/UserSubscriptionLevelService";
import SellerPartnerDataPage from "./Scenes/Operations/Scenes/SellerPartnerData/SellerPartnerDataPage";
import AdvertisingDataPage from "./Scenes/Operations/Scenes/AdvertisingData/AdvertisingDataPage";


function Dashboard(props) {

    let history = useHistory();

    const logout = () => {
        props.dispatch(LogoutService(props.token));
    }

    const [isTutorialOpen, setIsTutorialOpen] = useState(false);

    useEffect(() => {
        if (props.needsAdvertisingAPIAuth) {
            setIsTutorialOpen(true);
        }
    }, [props.needsAdvertisingAPIAuth])

    useEffect(() => {
        props.dispatch(UserSubscriptionLevelService(props.token));
    }, [])

    const handleTutorialConfirmation = () => {
        props.dispatch({type: actionType.CONFIRM_TUTORIAL});
        setIsTutorialOpen(false);
        props.dispatch(StoreListService(props.token));
        history.push(urls.PRICING_OPTIONS_URL);
    }

    const handleTutorialSubscriptionEmail = async (email) => {
        let response = await props.dispatch(SubscriptionEmailService(props.token, email));
        if (response) {
            setIsTutorialOpen(false);
        }
    }

    const handleAdvertisingAuth = () => {
        props.dispatch(AdvertisingAPIAuthorizationService(
            props.token,
            props.user._profile.id,
            props.user._token.accessToken,
            props.user._token.refreshToken,
            props.user._token.expiresAt,
        ));
    }

    return (
        // <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div
                    className={cx(
                        " app-theme-white",
                        { "fixed-header": true },
                        { "fixed-sidebar": true },
                        // { "fixed-sidebar": enableFixedSidebar || width < 1250 }
                        { "fixed-footer": true },
                        { "closed-sidebar": props.enableClosedSidebar || width < 1250 },
                        {
                            // "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                        },
                        { "sidebar-mobile-open": props.enableMobileMenu },
                        // { "body-tabs-shadow-btn": enablePageTabsAlt }
                    )}>
                    <DashboardHeader logout={logout}/>
                    { (props.status && props.status === 'LOGOUT_FAILED') &&
                    notify.NotifyError(props.error)
                    }
                    <Switch>

                        <Route path={urls.BASE_USER_PROFILE_URL}>
                            <UserProfilePage />
                        </Route>

                        <Route role={props.accessLevel}
                            perform={"ops:sellerPartnerData"}
                            exact={false} path={urls.OPS_SELLER_PARTNER_DATA_URL}>
                            <SellerPartnerDataPage />
                        </Route>
                        <Route role={props.accessLevel}
                            perform={"ops:advertisingData"}
                            exact={false} path={urls.OPS_ADVERTISING_DATA_URL}>
                            <AdvertisingDataPage/>
                        </Route>

                        {/*{props.newUser && <Redirect to={urls.USER_PROFILE_STORE_AUTH_URL}/>}*/}

                        {/*{*/}
                        {/*    props.needsAdvertisingAPIAuth &&*/}
                        {/*    <Redirect to={urls.USER_PROFILE_AUTH_URL} />*/}
                        {/*}*/}

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"stores:view"}
                            exact={false}
                            path={urls.BASE_ACCOUNT_OVERVIEW_URL}
                        >
                            <AccountOverviewPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"campaigns:create"}
                            exact={false}
                            path={urls.BASE_CREATE_CAMPAIGN_URL}
                        >
                            <CreateCampaignPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"campaigns:launch"}
                            exact={false}
                            path={urls.BASE_LAUNCHPAD_URL}
                        >
                            <LaunchpadPage/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"pricing:view"}
                            exact={false}
                            path={urls.BASE_PRICING_URL}
                        >
                            <CreditsPage/>
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform={"project:view"}
                            exact={false}
                            path={urls.BASE_PROJECTS_URL}
                        >
                            <CreateProjectPage />
                        </ProtectedRoute>

                        <ProtectedRoute
                            role={props.accessLevel}
                            perform="ops:view"
                            exact={false}
                            path={urls.BASE_OPS_URL}
                        >
                            <OperationsPage />
                        </ProtectedRoute>

                        <Route
                            path={urls.STORE_AUTH_REDIRECT_URL}
                        >
                            <SPAPIRedirectPage/>
                        </Route>
                        {/*Default landing page after login*/}
                        <Can
                            role={props.accessLevel}
                            perform={"stores:view"}
                            yes={() => <Route render={() => <Redirect to={{pathname: urls.ACCOUNT_OVERVIEW_MY_STORES_URL}} />} />}
                            no={() => <Route render={() => <Redirect to={{pathname: urls.BASE_PROJECTS_URL}} />} />}
                        />
                    </Switch>

                    <TutorialModal
                        isOpen={isTutorialOpen}
                        toggle={() => setIsTutorialOpen(!isTutorialOpen)}
                        handleConfirmation={handleTutorialConfirmation}
                        handleEmailSubmit={handleTutorialSubscriptionEmail}
                        isAuth={props.isAuth}
                        needsAdvertisingAPIAuth={props.needsAdvertisingAPIAuth}
                        handleAuth={handleAdvertisingAuth}
                    />
                </div>
            )}
        />
    );
}

const mapStateToProps = state => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
        newUser: state.loggedUser.newUser,
        needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        enableMobileMenu: state.theme.enableMobileMenu,
        enableClosedSidebar: state.theme.enableClosedSidebar,
    }
}

export default connect(mapStateToProps)(Dashboard);
