import React, {useEffect, useState} from 'react';
import { BroadcastChannel } from "broadcast-channel";
import { useLocation } from 'react-router-dom';

// https://app.simpliworks.io/register-user?spapi_oauth_code=ANJblZYxxYYnHBCUvWCX&state=state-example&selling_partner_id=A1NI553U7YZW9G

function SPAPIRedirectPage(props) {
    const sendChannel = new BroadcastChannel("spapi_auth");
    const receiveChannel = new BroadcastChannel("status");

    const [status, setStatus] = useState("loading");

    let search = useLocation().search;
    const oauthCode = new URLSearchParams(search).get("spapi_oauth_code");
    const state = new URLSearchParams(search).get("state");
    const sellingPartnerID = new URLSearchParams(search).get("selling_partner_id");

    useEffect(() => {
        if (status !== "success")
        sendChannel.postMessage({
            oauthCode: oauthCode,
            state: state,
            sellingPartnerID: sellingPartnerID,
        });
    }, [sendChannel, oauthCode, state, sellingPartnerID]);

    receiveChannel.onmessage = (msg) => setStatus(msg);

    if (status === "success") {
        sendChannel.close();
        receiveChannel.close();
    }

    return (
        <div className="text-lg-center mt-5">
            {
                status === 'loading' ?
                    
                
                    <h4>Please wait ...{status}</h4>
                :
                status === 'success' ?
                    <h4>Success! You may close this window.</h4>
                :
                    <h4>A problem occurred while processing your request, please close this window and try again.</h4>
            }
        </div>
    )
}

export default SPAPIRedirectPage;