import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetStoreLevelSalesService(token, sellerPartnerDetails) {
    console.log(sellerPartnerDetails)
    
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getStoreLevelSales`, sellerPartnerDetails, {
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Historical SPAPI Sales data task is getting submitted."))
        .then(res => {            
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while submitting Historical SPAPI Sales data fetch job, please try again later.");
            return false;
        });
}


export {
    GetStoreLevelSalesService,
}

