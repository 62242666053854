import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, UncontrolledButtonDropdown } from "reactstrap";
import { connect } from 'react-redux';
import FetchStoresForAsinService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/FetchStoresForAsinService"
import UpdateSKUForAsinStoreService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService"
import CustomModal from "../../../../../../Components/Modals/CustomModal";    
import FetchProductManagementHistory from '../../../../../../../Service/DashboardServices/UserProfileServices/FetchProductManagementHistory';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'; 

function ProductsDataTable(props) {

const [isStoresOpen, setIsStoresOpen] = useState(false);
const [stores, setStores] = useState();
const [selectedIntegerID , setSelectedIntegerID] = useState();
const [selectedAsin , setSelectedAsin] = useState();
const [productAsin, setProductAsin] = useState();
const [managementHistory, setManagementHistory] = useState();
const [managementHistoryOpen,setManagementHistoryOpen]= useState(false);
const  handleFetchStores = async (asin) => {
    console.log('in handleFetchStores');
    
    let response = await FetchStoresForAsinService(props.token, asin);  
    console.log (response);
    setStores(response);

};
const  handleUpdateSkus = async (asin,profileId) => {
    console.log('in handleUpdateSkus');   
    setSelectedAsin(asin)
    let response = await UpdateSKUForAsinStoreService(props.token, asin,profileId);  
    console.log (response);   
    if(response)
    {
        
        handleFetchStores(asin)
    }    

};

const outerColumns = [
    {
        Header: 'Asin',
        accessor: 'asin',
    },
    {
        Header: 'Name',
        accessor: 'productTitle',
    },      
    {
        Header: 'Marketplace',
        accessor: 'location',
    },       
    {
        Header: 'Price',
        accessor: 'itemPrice',
    },
    {
        Header: 'Rating',
        accessor: 'itemRating',
    },
    {
        Header: 'Is Managed',
        accessor: 'simpliworksManagementState',
    }, 
    // {
    //     Header: 'Stores',            
    //     Cell: props => (           
    //         <Fragment>                    
    //             <Button
    //                 className="m-auto"
    //                 color="link"
    //                 onClick={() => {                                                       
    //                     setIsStoresOpen(!isStoresOpen)
    //                     handleFetchStores(props.original.asin);                                                        //props.requestStores(selectedUser.email);
                        
    //                 }}
    //             >
    //                 View 
    //             </Button>                 
    //         </Fragment>
    //     )
    // },
    {
        Header: 'Actions',
        width: 100,
        Cell: (props) => productQuickActionsDropdown(props.original),
        filterable: false,
        
        // accessor: 'asin',
        // Cell: props => (
        //     <Fragment>                  
        //         <Button
        //             className=""  
        //             color="link"
        //             onClick={() => {
        //                 //handleRemoveProduct(props.value,props.original.integerID);
        //             }}
        //         >
        //             Delete
        //         </Button>
        //     </Fragment>
        // )
    },
    {
        Header: 'Link',
        accessor: 'link',
        Cell: props =><a          
                        href={props.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    > 
                        Click here 
                    </a>
        
    }, 
];

const storeColumns = [
    {
        Header: 'Asin',
        accessor: 'asin',
    },
    {
        Header: 'Integer ID',
        accessor: 'IntegerId',
    },       
    {
        Header: 'Store Name',
        accessor: 'StoreName',
    }, 
    {
        Header: 'Skus',
        accessor: 'skus',        
    },
    {
        Header: 'Skus',
        accessor: 'skus',
        Cell: props => (           
            <Fragment>                    
                <Button
                    className="m-auto"
                    color="link"
                    onClick={() => {                                                       
                       
                        handleUpdateSkus(props.original.asin,props.original.IntegerId);                                                         //props.requestStores(selectedUser.email);
                      
                    }}
                >
                    Update 
                </Button>                 
            </Fragment>
        )
    },   
];

    const [query, setQuery] = useState("");
    
    const search = (data) => {
        return props.data.filter((item) => item.asin.toLowerCase().includes(query.toLowerCase())||item.productTitle.toLowerCase().includes(query.toLowerCase()));
    };
    const historyColumns = [
        {
            Header: 'IntegerId',
            accessor: 'integerId',
        },
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        // {
        //     Header:'Is Managed',
        //     accessor: 'isManaged',
        //     Cell: props => (     
        //         props.original.isManaged ? <p style={{ marginLeft: '45%'}}>Yes</p> : <p style={{ marginLeft: '45%',padding: '5px',backgroundColor: "#ff4761",color:"white"}}>No</p> 
        //     ) 
        // },
        {
            Header:'Management State',
            accessor: 'simpliworksManagementState',
        },
        {
            Header:'Created Date',
            accessor: 'createdAt',
        },
        {
            Header:'Updated By',
            accessor: 'createdBy',
        },
        {
            Header:'Title',
            accessor: 'productTitle',
        }
    ]
    const productQuickActions = [
    
        {
            name: () => 'Management History',
            action: ({asin}) => {
                setProductAsin(asin)
                fetchProductManagementHistory(asin) 
            }
        },
        {
            name: ()=>'View Stores',            
            action: ({asin}) => {              
                setIsStoresOpen(!isStoresOpen)
                handleFetchStores(asin);                                                        //props.requestStores(selectedUser.email);
        }
    }

    ]
    const  fetchProductManagementHistory = async (asin) => {
        console.log('in handleUpdateProducts')
        let data = {
            asin:asin,
            integerId:''
        } 
        let response = await FetchProductManagementHistory(props.token, data); 
        if(response)
        {
            console.log (response)   
            setManagementHistory(response)
            setManagementHistoryOpen(true)
        }
    }
    const exportToCSV = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: {'Product management history':ws }, SheetNames: ['Product management history'] };                
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, fileName + fileExtension);   
    };
const exportProductManagementHistoryCSV = async(asin) => {
        const fileName = 'Product management history'
        let data = {
            asin:asin,
            integerId:''
        } 
        let response = await FetchProductManagementHistory(props.token, data); 
        if(response)
        {
            exportToCSV(response, fileName);
        }
        } 
    const productQuickActionsDropdown = actions => (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret color="link">
                Actions
            </DropdownToggle>
            <DropdownMenu>
                {
                    productQuickActions.map(qa => (
                        <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>{qa.name(actions)}</DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
    return (
        <Fragment>
        <div>
        <Row>
       
        <Col md="2" className="mb-2">
        
            <Input 
                id="inputValue"
                // style="width:80%"
                style={{width: "100%"}}
                placeholder='search text'
                className="Search"
                onChange={event => {setQuery(event.target.value);}}
            />
        </Col>
        </Row>
    </div>
        <ReactTable
            data={search(props.data)}
            columns={outerColumns}
            defaultPageSize={10}
            noDataText={"No stores found."}
            style={{
                height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"           
        />
    <CustomModal
                isOpen={isStoresOpen}
                toggle={() => setIsStoresOpen(!isStoresOpen)}
                header={`Stores`}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresOpen(!isStoresOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <div className="p-3">
            <ReactTable
                data={stores}
                columns={storeColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />


        </div>
    
                </Card>

                
            </CustomModal>
            <CustomModal 
                header={"All Product Management History"}
                isOpen={managementHistoryOpen}
                toggle={() => setManagementHistoryOpen(!managementHistoryOpen)}
                size={"xl"}
                className='curve-popup'
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setManagementHistoryOpen(!managementHistoryOpen)}
                            >
                            Cancel
                        </Button>
                    </Fragment>
                }        
            >  
                <Card className='curve-popup' >
                    <Row className='p-4'>
                    <Col md={12} className=' d-flex mb-3 justify-content-end'>
                        <Button
                        className ='btn btn-brand-blue-dark'
                         onClick ={()=> exportProductManagementHistoryCSV(productAsin)}
                         >
                            Download
                        </Button>
                        </Col>
                        <Col md={12}>
                            <ReactTable
                                data={managementHistory}
                                columns={historyColumns}
                                noDataText={"No Data Found."}
                                style={{height: "500px"}}
                                className="-fixed -highlight -striped"                                
                            />
                        </Col>
                    </Row>
                </Card>
            </CustomModal>
    </Fragment>
    )
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 



export default connect(mapStateToProps)(ProductsDataTable)