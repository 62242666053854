
import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import {LoadRegistrastionJournyData} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function GetAllProblemAreaPathwaysService(token,data) {
    console.log(data)
    return axios.post(`${CURRENT_API_URL}/swhelpdesk/getAllProblemAreaPathways`, data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching problem area pathways details., please try again later.");
        return false;
    });
}


export default GetAllProblemAreaPathwaysService;