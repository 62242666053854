import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";


function MRPCard(props) {
    return (
        <Card>
            <CardHeader style={{justifyContent: "center"}}>MyRealProfit</CardHeader>
            <CardBody className="text-center">
                Authorize MyRealProfit to simplify your performance reporting.
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
                <Button
                    className="ml-2"
                    color="brand-blue-dark"
                    onClick={() =>
                        window.open(
                            'https://simpliworks.myrealprofit.com'
                        )
                    }
                >
                    Authorize MyRealProfit
                </Button>
            </CardFooter>
        </Card>
    );
}

export default MRPCard;