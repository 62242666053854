import React, {Fragment, useState} from 'react';
import {Card, CardHeader, CardBody} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

import CustomTabs from '../../../../../../../Components/Nav/CustomTabs';
import CustomTabContent from '../../../../../../../Components/Nav/CustomTabContent';
import {filterByPermission} from '../../../../../../../Components/Can';
import StoreProductsTab from './StoreTabs/StoreProductsTab';
import CampaignAnalyticsTab from './StoreTabs/CampaignAnalyticsTab';
import ImportCampaignsTab from './StoreTabs/ImportCampaignsTab';
import ProductOverviewService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService';
import urls from '../../../../../../../../Utils/URLs';
import UpdateProductManagementService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/UpdateProductManagementService';
import StoreOverviewService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import AddStoreProductService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/AddStoreProductService';
import ImportCampaignService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/ImportCampaignService';
import SearchTermReportTab from './StoreTabs/SearchTermReportTab';
import ManagedCampaignsTab from './StoreTabs/ManagedCampaignsTab';
import QuickLaunchCampaignService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/QuickLaunchCampaignService';
import ProductTrackingService
    from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductTrackingService';
import RemoveProductService
    from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RemoveProductService";
import UpdateSKUForAsinStoreService
    from "../../../../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService"

function StoreTabs(props) {
    let history = useHistory();
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    // let { storeIntegerID } = props.match.params;

    const handleProductPageRedirect = (asin, sku) => {
        props.dispatch(
            ProductOverviewService(props.token, asin, props.storeIntegerID)
        );
        history.push(urls.ACCOUNT_OVERVIEW_PRODUCT_URL);
    };

    const updateProductManagement = async (asin, update) => {
        setLoadingUpdate(true);
        await UpdateProductManagementService(
            props.token,
            asin,
            update,
            props.storeIntegerID
        );
        props.dispatch(
            StoreOverviewService(
                props.token,
                props.storeIntegerID,
                props.storeAccountID
            )
        );
        setLoadingUpdate(false);
    };

    const handleAddProduct = async (asin, sku) => {
        // let response = await AddStoreProductService(props.token, asin, sku, props.storeIntegerID);
        let response = await AddStoreProductService(
            props.token,
            asin,
            props.storeIntegerID
        );
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
        return response;
    };

    const handleImportCampaign = async (
        asin,
        campaignID,
        campaignStage,
        isPhrase
    ) => {
        let response = await ImportCampaignService(
            props.token,
            props.storeIntegerID,
            asin,
            campaignID,
            campaignStage,
            isPhrase
        );
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    const handleQuickLaunchCampaign = async (asin, sku, dailyBudget) => {
        let response = await QuickLaunchCampaignService(
            props.token,
            props.storeIntegerID,
            asin,
            sku,
            dailyBudget
        );
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
        return response;
    };

    const updateProductTracking = async (asin, update) => {
        let response = await ProductTrackingService(props.token, asin, update);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    const handleRemoveProduct = async (asin, integerID) => {
        let response = await RemoveProductService(props.token, asin, integerID);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    const handleUpdateSkus = async (asin, integerID) => {
        let response = await UpdateSKUForAsinStoreService(props.token, asin, integerID);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    let tabList = [
        {
            perform: 'products:view',
            yes: (
                <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
                    Store Products
                </div>
            ),
        },
        // {
        //     perform: 'campaigns:view',
        //     yes: (
        //         <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
        //             Managed Campaigns
        //         </div>
        //     ),
        // },
        // {
        //     perform: 'home:standard',
        //     yes: (
        //         <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
        //             Campaign Analytics
        //         </div>
        //     ),
        // },
        // {
        //     perform: 'campaigns:import',
        //     yes: (
        //         <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
        //             Import Campaigns
        //         </div>
        //     ),
        // },
        // {
        //     perform: 'searchTerm:upload',
        //     yes: (
        //         <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
        //             Search Term Report
        //         </div>
        //     ),
        // },
    ];

    let tabContentList = [
        {
            perform: 'products:view',
            yes: (
                <StoreProductsTab
                    accessLevel={props.accessLevel}
                    products={props.storeProducts}
                    parentProducts={props.parentProducts}
                    childProducts={props.childProducts}
                    orphanProducts={props.orphanProducts}
                    storeIntegerID={props.storeIntegerID}
                    ProductPerformanceReport={props.ProductPerformanceReport}
                    ProductTargetingReport={props.ProductTargetingReport}
                    handleRedirect={handleProductPageRedirect}
                    updateProductManagement={updateProductManagement}
                    updateProductTracking={updateProductTracking}
                    handleAddProduct={handleAddProduct}
                    removeProduct={handleRemoveProduct}
                    updateSkus={handleUpdateSkus}
                    loading={loadingUpdate}
                    handleQuickLaunchCampaign={handleQuickLaunchCampaign}
                    handleQuickActions={props.handleQuickActions}
                    toggleSelect={props.toggleSelect}
                    selectedProducts={props.selectedProducts}
                    token={props.token}
                    linkedToMRP={props.linkedToMRP}
                />
            ),
        },
        // {
        //     perform: 'campaigns:view',
        //     yes: <ManagedCampaignsTab/>,
        // },
        {
            perform: 'home:standard',
            yes: (
                <CampaignAnalyticsTab
                    campaigns={props.storeCampaigns}
                    metricData={props.metricData}
                    products={props.storeProducts}
                />
            ),
        },
        {
            perform: 'campaigns:import',
            yes: (
                <ImportCampaignsTab
                    unclaimedCampaigns={props.unclaimedCampaigns}
                    handleImportCampaign={handleImportCampaign}
                />
            ),
        },
        {
            perform: 'searchTerm:upload',
            yes: (
                <SearchTermReportTab
                    token={props.token}
                    integerID={props.storeIntegerID}
                />
            ),
        },
    ];

    tabList = filterByPermission(tabList, props.accessLevel);
    tabContentList = filterByPermission(tabContentList, props.accessLevel);

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tabId) => {
        setActiveTab(tabId);
    };

    const renderTabPane = (tab) => <CardBody>{tab}</CardBody>;

    return (
        <Fragment>
            {tabList.length === 1 ? (
                <Card>
                    <CardBody>{tabContentList[0]}</CardBody>
                </Card>
            ) : (
                <Card className="mb-3">
                    <CardHeader className="tabs-lg-alternate">
                        <CustomTabs
                            activeTab={activeTab}
                            toggle={toggle}
                            tabList={tabList}
                            navItemStyles={{height: 'unset'}}
                            navLinkStyles={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        />
                    </CardHeader>
                    <CustomTabContent
                        activeTab={activeTab}
                        tabList={tabContentList}
                        renderTabPane={renderTabPane}
                    />
                </Card>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    storeProducts: state.accountOverview.store.storeProducts,
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    ProductTargetingReport: state.accountOverview.store.ProductTargetingReport,
    parentProducts: state.accountOverview.store.parentProducts,
    childProducts: state.accountOverview.store.childProducts,
    orphanProducts: state.accountOverview.store.orphanProducts,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
    metricData: state.accountOverview.store.metricData,
    linkedToMRP: state.accountOverview.store.storeOverview.linkedToMRP,
});

export default connect(mapStateToProps)(StoreTabs);
