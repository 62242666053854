import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import StoreOverviewDoThingsItems from "../../StoreOverviewDoThingsItems";
import CreateBrand from "../../../../../../../../designUtils/Storeoverview/BuildBrand/CreateBrand.svg"
import ArrowIcon_icon from "./../../../../../../../../designUtils/Logo/Vector_Arrow.svg"
import SubmitBrand from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BuildBrand Componants/SubmitBrand.svg"
import UploadBrand from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BuildBrand Componants/UploadBrand.svg"
import BookBrand from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BuildBrand Componants/BookBrand.svg"
import GetAllProblemAreaPathwaysService from "../../../../../../../../Service/DashboardServices/HeplDeskServices/GetAllProblemAreaPathwaysService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackButton from "../../../../../../../../designUtils/Logo/BackButton.svg"
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
function BuildYourBrandFrame2(props) {
  const [problemAreaPathwayList, setProblemAreaPathwayList] = useState();
  const [problemAreaPathwayId, setProblemAreaPathwayId] = useState();
  const [problemAreaPathwayName, setProblemAreaPathwayName] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [doThingItemPage, setDoThingItemPage] = useState(true);
//   const [ticketListPage, setTicketListPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  useEffect(() => {
    const data = {
      mainRequestTypeId: props.mainRequestTypeId,
      problemAreaId: props.problemAreaId,
    };
    const response = GetAllProblemAreaPathwaysService(props.token, data).then(
      (response) => {
        if (response) {
          setProblemAreaPathwayList(response);
        }
      }
    );
  }, []);

  const renderProblemAreaPathwayPage = () => {
    return (
      <>
      <Container>
        <Row className="justify-content-between">
          <Col md={12}>
          <h5 className="mt-1">  
              <button onClick={()=>{props.goBackPA();props.closeProblemArea()}} className="hover-btn-link back-btn"  >
            <img  className='m-2'src={BackButton} alt="not foun"/>
              </button>
            <img style={{height:40}} src={CreateBrand} />  {props.problemAreaName}
          {/* <Button className="float-right btn_class">Get In Touch</Button>  */}
          </h5>
          </Col>
            {problemAreaPathwayList
              ? problemAreaPathwayList.map((pathway) => (
                    <Col md={4} className="   mt-1 p-2">
                <a href='#'  onClick={()=>handleDoThingItemPage(pathway.id ,pathway.problemAreaPathway)} className="text-decoration-none text-reset"  >
                    <Card className=" card-of-problemarea "style={{height: 308}} >
                    <Col md={12} className='buildBrandsVisit p-0'>
                      <Col md={12}  >
                        <img className=' mx-auto d-block buildBrandsVisit1 mt-2 ' style={{height:150}}
                        src={pathway.problemAreaPathway==='Submit Your brand details'?SubmitBrand:
                        pathway.problemAreaPathway==='Upload your brand assets.'?UploadBrand:
                        pathway.problemAreaPathway==='Book a brand interview with a specialist.' ?BookBrand :''} alt='not found'/>
                      </Col>
                      <CardTitle className="mb-0 buildBrandsVisit2 mt-3 p-2" style={{height: 134}}>
                      <h4 className="text-center mt-3 " style={{color:"black"}}>{pathway.problemAreaPathway}</h4>
                      <img  className='rounded float-right mr-2' src={ArrowIcon_icon} />
                    </CardTitle>
                    </Col>

                   </Card>
                   </a>


                      </Col>
                    
                ))
              : ""}
          </Row>
          </Container>
      </>
    );
  };
  const goBackPAP = () => {
  setCurrentPage(false);
  }
  const handleDoThingItemPage = (id, name) => 
  {
    // setIsPopupOpen(true)
    alert('This module is under development.');
    // setProblemAreaPathwayId(id)
    // setProblemAreaPathwayName(name)
    // setCurrentPage(true);

  }
  const renderTicketListPage = () => {
    return (
      <>
      <StoreOverviewDoThingsItems goBack={props.goBack} goBackPA={props.goBackPA} goBackPAP={goBackPAP} mainRequestTypeId = {props.mainRequestTypeId} problemAreaId = {props.problemAreaId} problemAreaPathwayId={problemAreaPathwayId} problemAreaPathwayName={problemAreaPathwayName}/>
      </>
    );
  }
  return (
    
    <>
      <Container className="">
        <Row className="justify-content-between">
          <Col md={12} className='p-0'>
            {/* {doThingItemPage === true ? renderDoThingItemPage() : ticketListPage === true ? renderTicketListPage() :''} */}
            {/* { currentPage ? renderTicketListPage() : renderProblemAreaPathwayPage() } */}
            { renderProblemAreaPathwayPage() }
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(BuildYourBrandFrame2);
