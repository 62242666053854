import React, {useState,useEffect} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row, Button} from "reactstrap";
import { connect } from 'react-redux';

import DateRangePicker from "../../../../../Components/Input/DateRangePicker";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import {SellerPartnerDataService } from "../../../../../../Service/OPSServices/SellerDataPartnerServices";
import {GetDataForLastOneHourService} from  "../../../../../../Service/OPSServices/GetDataForLastOneHourService"
import {GetDataForLastOneDayService} from  "../../../../../../Service/OPSServices/GetDataForLastOneDayService"
import {GetDataForLastOneMonthService} from  "../../../../../../Service/OPSServices/GetDataForLastOneMonthService"
import {GetSellerPartnerListService} from "../../../../../../Service/OPSServices/GetSellerPartnerListService"

import {GetSellerPartnerSettingsService} from "../../../../../../Service/OPSServices/GetSellerPartnerSettingsService"

import SellerPartnerDataTable from "./Components/SellerPartnerDataTable";
import { DropdownList } from "react-widgets";
import SellerPartnerDetailsListTable from './Components/SellerPartnerDetailsListTable';
import PowerbiEmbed from './Components/PowerbiEmbed';


function SellerPartnerDataPage(props) {

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState([]);
    const [sellerPartnerId,setSellerPartnerId] = useState('');
    const [summary, setSummary] = useState();    
    

    console.log(productStatus);

    const fetchProductStatus = () => {
        setLoading(true);

        let isProduct = email !== "";

        let data = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        };

        if (isProduct) {
            data["email"] = email;
        } 

        SellerPartnerDataService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                setProductStatus(sellerPartnerDataStatus);
            }
        })

        setLoading(false);
    }

    useEffect(() => {
        props.dispatch(GetSellerPartnerListService(props.token));         

       GetSellerPartnerSettingsService(props.token).then(response => { 
            
           
        console.log('After response Line')
        console.log(response)
        if (response) {
            if(response['POWERBI_OVERALL_SALES_REPORT_URL'] != 'None'){                
                
                setSummary(<PowerbiEmbed data={response['POWERBI_OVERALL_SALES_REPORT_URL']}/>);
            }else
            {
                setSummary("Sales Report for this environment is under development and will be available soon.");
            }
        }
    } )
        
    }, [])

    const getDataForOneHour = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        GetDataForLastOneHourService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                setProductStatus(sellerPartnerDataStatus);
            }
        })

        setLoading(false);
    }

    const getDataForOneDay = () => {
        setLoading(true);        

        let data = {id : sellerPartnerId.id}
        
        GetDataForLastOneDayService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                setProductStatus(sellerPartnerDataStatus);
            }
        })

        setLoading(false);
    }

    const getDataForOneMonth = () => {
        setLoading(true);        

        let data = {sellerPartnerId : sellerPartnerId}
        
        GetDataForLastOneMonthService(props.token, data).then(sellerPartnerDataStatus => {
            if (sellerPartnerDataStatus) {
                setProductStatus(sellerPartnerDataStatus);
            }
        })

        setLoading(false);
    }

    const isInputValid = () => {
        //if (dateRange.startDate === null) return false;
        //if (integerId === "" && asin === "") return false;
        //if (integerId !== "" && asin !== "") return false;

        return true;
    }

    return (
        <>
            <Container fluid>
            <Row >
                    <Col >
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>Report Section</CardTitle>
                                        
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    
                    </CardBody>
                        </Card>
                    </Col>
                </Row>
            
            &nbsp;                               

        &nbsp;
                <Row className="mb-3">
                    <Col md="12" lg="6">
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>Seller Partner List </CardTitle>
                                <Row>
                                    <Col md="6" className="mb-2"> 
                                    <DropdownList
                                    data={props.sellerPartnerList}
                                    dataKey='id'                    
                                    textField='Name'                   
                                    placeholder="Select"
                                    value={sellerPartnerId}
                                    onChange={(value) => {
                                        setSellerPartnerId(value);
                                     }}
                                />   
                                    </Col>        
                                </Row>                                                    
                                         

                                &nbsp;
                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getDataForOneDay}                                    
                                >
                                    Get data for last day
                                </LoadingButton>

                                &nbsp;                               

                                &nbsp;
                                
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>                        
                        <Card>
                            <SellerPartnerDataTable data={productStatus} />
                            
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    sellerPartnerList:state.profile.sellerPartnerList,
})


export default connect(mapStateToProps)(SellerPartnerDataPage);