import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";


function PasswordResetService(userEmail) {

    return (
        axios.post(`${CURRENT_API_URL}/account/account-recovery/reset_password/`, { email: userEmail }, {
        headers: {
            'Content-Type': 'application/json',
        }
        })
            .then(res => true)
            .catch(error => false)
    );
}

function ValidateResetTokenService(resetToken) {

    return (
        axios.post(`${CURRENT_API_URL}/account/account-recovery/validate_token/`, {
            token: resetToken
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                return true;
            })
            .catch(error => {
                return false;
            })
    );
}

function PasswordResetConfirmService(resetToken, newPassword) {

    return (
        axios.post(`${CURRENT_API_URL}/account/account-recovery/reset-password/confirm/`, {
            token: resetToken,
            password: newPassword,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                return true;
            })
            .catch(error => {
                return false;
            })
    );
}


export {
    PasswordResetService,
    ValidateResetTokenService,
    PasswordResetConfirmService,
}
