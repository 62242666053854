import React from 'react';
import CustomWidget from "../../../../Components/Widgets/CustomWidget";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";


function CampaignWidget(props) {
    const { name, asin, campaignID, collectionID, state, details, handleRedirect, handleLaunch, handleQuickActions } = props;

    const viewCampaignDetailsButton = (key) => (
        <Button
            className="pl-0"
            key={key}
            color="link"
            onClick={() => handleRedirect(name, collectionID)}
        >
            Campaign Details {'>'}
        </Button>
    );

    const quickActionsDropdown = (key) => {
        if (handleQuickActions) {
            return (
                <UncontrolledButtonDropdown key={key}>
                    <DropdownToggle caret color="link">
                        Quick Actions
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            handleQuickActions.map(qa => (
                                <DropdownItem key={key + qa.name} onClick={() => qa.action(props)}>{qa.name(props)}</DropdownItem>
                            ))
                        }
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            );
        }
    };

    const launchCampaignButton = (key) => (
        <Button
            className="mr-2"
            outline
            key={key}
            color="success"
            size="md"
            onClick={() => handleLaunch(name, collectionID)}
        >
            Launch Campaign
        </Button>
    );


    return (
        <CustomWidget
            collapsible={true}
            heading={name}
            // subheading={`Product ASIN: ${asin}`}
            details={details}
            autoFormat={true}
            actions={ state === 'ready' ?
                [launchCampaignButton, quickActionsDropdown] :
                [quickActionsDropdown]
            }
        />
    )
}

export default CampaignWidget;
