import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function UpdateStoreProductsV3Service(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/UpdateStoreProductsDetails`, data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Store Products are getting updated."))      
        .then(res => {
            notify.NotifySuccess("Store Products have been saved in DB.")
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while Updating Store Products.");
            return false;
        });
}

export {
    UpdateStoreProductsV3Service,
}