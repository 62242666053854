import React from 'react';
import ReactTable from "react-table";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    UncontrolledButtonDropdown
} from "reactstrap";


function CampaignDataTable(props) {

    const quickActionsDropdown = campaign => (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret color="link">
                View
            </DropdownToggle>
            <DropdownMenu>
                {
                    props.quickActions.map(qa => (
                        <DropdownItem key={qa.name} onClick={() => qa.action(campaign)}>{qa.name(campaign)}</DropdownItem>
                    ))
                }
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );

    const rangeFilterInputs = ({ filter, onChange }) => (
        <div>
            <Input
                value={filter ? filter.value.min : ""}
                onChange={e => onChange({ min: e.target.value, max: filter ? filter.value.max : ""})}
            />
            {'-'}
            <Input
                value={filter ? filter.value.max : ""}
                onChange={e => onChange({ min: filter ? filter.value.min : "", max: e.target.value})}
            />
        </div>
    );

    const renderCampaignSelectButton = (cellInfo) => (
        <FormGroup check>
            <Label check>
                <Input
                    type={"checkbox"}
                    checked={props.campaignsToExport.includes(cellInfo.original.name)}
                    onChange={(e) => props.selectCampaignForExport(cellInfo.original.name, e.target.checked)}
                />
            </Label>
        </FormGroup>
    )

    const columns = [
        {
            Header: "",
            Cell: props => renderCampaignSelectButton(props),
            filterable: false,
            width: 30,
            show: props.isExportEnabled,
        },
        {
            Header: "Name",
            accessor: "name",
            width: 150,
            filterable: false,
        },
        {
            Header: "Budget",
            accessor: "dailyBudget",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : 'unavailable',
            Filter: rangeFilterInputs,
        },
        {
            Header: "Sales",
            accessor: "sales",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
            Filter: rangeFilterInputs,
        },
        {
            Header: "ROAS",
            accessor: "ROAS",
            Cell: cellInfo => !isNaN(cellInfo.value) ? cellInfo.value : 0,
            Filter: rangeFilterInputs,
        },
        {
            Header: "CPC",
            accessor: "cost",
            Cell: cellInfo => {
                let cpc = parseInt(cellInfo.value) / cellInfo.original.clicks;
                return !isNaN(cpc) ? `$${cpc.toFixed(2)}` : '$0';
            },
            Filter: rangeFilterInputs,
        },
        {
            Header: "Stage",
            accessor: "campaignStage",           
        },
        {
            Header: "Actions",
            width: 80,
            Cell: cellInfo => quickActionsDropdown(cellInfo.original),
            filterable: false,
        },
    ];


    return (
        <ReactTable
            columns={columns}
            filterable={false}
            defaultFilterMethod={(filter, row) => {
                if (!filter.value) return true; // no filter
                if (!filter.value.min && !filter.value.max) return true;    // blank min & max
                if (!filter.value.min && filter.value.max) return row[filter.id] <= parseFloat(filter.value.max);   // blank min
                if (filter.value.min && !filter.value.max) return row[filter.id] >= parseFloat(filter.value.min);   // blank max

                return row[filter.id] >= parseFloat(filter.value.min) && row[filter.id] <= parseFloat(filter.value.max);    // both range bounds provided
            }}
            data={props.data}
            style={{
                height: "500px"
            }}
            className="-striped -highlight -fixed"
            noDataText="No campaigns found"
        />
    )
}


export default CampaignDataTable;