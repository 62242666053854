import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
// import useGlobalFilter from "react-table";
// import usePagination from "react-table";
import { Button, Card, Col, Input, Label, Row } from "reactstrap";
// import { useTable,useAsyncDebounce,useSortBy } from 'react-table';

import CustomModal from "../../../../../../Components/Modals/CustomModal";
import UpdateCreditBalanceService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/RequestAmazonStoresService";
import RequestAmazonStoresService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/RequestAmazonStoresService";
import UserDirectoryStoresService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UserDirectoryStoresService";
import RequestStoresDataTable from './RequestStoresDataTable';
import StoresAsinDataTable from './StoresAsinDataTable';
import UpdateStoreProfile
    from "../../../../../../../Service/DashboardServices/UserProfileServices/UpdateStoreProfile";
import { connect } from 'react-redux';
import RemoveProductService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RemoveProductService";
function UsersDirectoryTable(props) {

    const [newCreditBalance, setNewCreditBalance] = useState();
    const [newProductLimit, setnewProductLimit] = useState();
    const [isStoresOpen, setIsStoresOpen] = useState(false);
    const [newStoresData, setNewStoresData] = useState();
    const [stores, setStores] = useState();
    const [managedProducts, setManagedProducts] = useState();
    
    const [isStoresAsinOpen, setIsStoresAsinOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({
        name: '',
        email: '',
    });
    
    const  handleUpdateProfiles = async (email) => {
        console.log('in handleUpdateProfile') 
        let response = await UpdateStoreProfile(props.token, email);  
        console.log (response)   
    }

    const  handleRequestStores = async (email) => {
        console.log('in requestStores') 
        let response = await RequestAmazonStoresService(props.token, email);  
        console.log (response)
        // props.requestStores(selectedUser.email, newStoresData);}
        if (response) {
         setNewStoresData(response)  }
    }
    const  handleStoresAsinData = async (email) => {
        console.log('in StoresAsinData') 
        let response = await UserDirectoryStoresService(props.token, email);  
        console.log (response)
        // props.requestStores(selectedUser.email, newStoresData);}
        if (response) {
            setStores(response['stores']);
            setManagedProducts(response['managedProducts'])  }
    }
    const handleRemoveProduct = async (asin,integerID) => {
        let response = await RemoveProductService(props.token, asin, integerID);
        if (response) {
            handleStoresAsinData(selectedUser.email)
        }
    };
       
    const outerColumns = [

        {
            Header: 'Company',
            accessor: 'companyName',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
        },
        {
            Header: 'Email',
            accessor: 'email',    
            Cell: cellInfo => {
                return (
                    <div className="text-center" style={{width: "100%"}}>
                       {cellInfo.value}
                    </div>
                );
            }        
        },
        {
            Header: 'Phone',
            accessor: 'phoneNumber',
        },
        // {
        //     Header: 'Credit Balance',
        //     accessor: 'creditBalance',
        //     Cell: props => (
        //         <Fragment>
        //             {props.value} Credits
        //             <Button
        //                 className="ml-auto"
        //                 color="link"
        //                 onClick={() => {
        //                     setSelectedUser({name: props.original.name, email: props.original.email});
        //                     setIsOpen(!isOpen);
        //                 }}
        //             >
        //                 Update
        //             </Button>
        //         </Fragment>
        //     )
        // },
        // {
        //     Header: 'No of Products',
        //     accessor: 'productsUnderManagement',
        // },
        {
            Header: 'Profile',
            accessor: 'email',
            Cell: props => (           
                <Fragment>                    
                    <Button
                        className="ml-auto"
                        color="link"
                        onClick={() => {                            
                           
                            handleUpdateProfiles(props.original.email);                                                         //props.requestStores(selectedUser.email);
                          
                        }}
                    >
                        Update 
                    </Button>                 
                </Fragment>
            )
        },
        {
            Header: 'Product Limits',
            //accessor: 'productsUnderManagementLimit',
            Cell: props => (
                <Fragment>                  
                    <Button
                        className="ml-auto"
                        color="link"
                        onClick={() => {
                            setSelectedUser({name: props.original.name, email: props.original.email});
                            setIsOpen(!isOpen);
                        }}
                    >
                        Update
                    </Button>
                </Fragment>
            )
        },           
        {
            Header: 'Store/Asin Data',
            Cell: props => (           
                <Fragment>
                    {props.value}    
                    <Button
                        className="m-auto"
                        color="link"
                        onClick={() => {
                            
                            setIsStoresAsinOpen(!isStoresAsinOpen)
                            setSelectedUser({name: props.original.name, email: props.original.email});   
                            console.log('before StoreAsinData');                        
                            handleStoresAsinData(props.original.email);                                                         //props.requestStores(selectedUser.email);
                            console.log('before StoreAsinData')
                        }}
                    >
                        View 
                    </Button>                 
                </Fragment>
            )
        },

        // {
        //     Header: 'No of Stores',
        //     // accessor: 'Stores',
        //     Cell: props => (           
        //         <Fragment>                     
        //             <Button
        //                 className="ml-auto"
        //                 color="link"
        //                 onClick={() => {
                            
        //                     setIsStoresOpen(!isStoresOpen)
        //                     setSelectedUser({name: props.original.name, email: props.original.email});   
        //                     console.log('before requestStores');                        
        //                     handleRequestStores(props.original.email);                                                         //props.requestStores(selectedUser.email);
        //                     console.log('before requestStores')
        //                 }}
        //             >
        //                 Request 
        //             </Button>                 
        //         </Fragment>
        //     )
        // },
             
        {
            Header: 'Authorized?',
            accessor: 'isAuthorized',
            Cell: props => (     
                props.value ? <p style={{ marginLeft: '40%'}}>Yes</p> : <p style={{ marginLeft: '40%',padding: '5px',backgroundColor: "#ff4761",color:"white"}}>No</p> 
            ) 
        },
        // {
        //     Header: "Join Date",
        //     accessor: "dateJoined",
        //     Cell: cellInfo => new Date(cellInfo.value).toLocaleDateString('en-US',
        //         {
        //             year: 'numeric',
        //             month: 'long',
        //             day: 'numeric',
        //             hour: 'numeric',
        //             minute: 'numeric',
        //         }
        //     ),
        // },       
        {
            Header: 'Enrollment Path',
            accessor: 'enrollmentPath',            
        },
        {
            Header: 'Subscription',
            accessor: 'subscriptionText',            
        },
    ];

    const innerColumns = [
        {
            Header: 'Store',
            accessor: 'name',
        },
        {
            Header: 'Integer ID',
            accessor: 'integerID',
        },
        {
            Header: 'Account ID',
            accessor: 'stringID',
        },
        {
            Header: 'Marketplace',
            accessor: 'location',
        },
        {
            Header: 'Currency',
            accessor: 'currencyCode',
        },
        {
            Header: 'Daily Budget',
            accessor: 'dailyBudget',
        },
    ];
    
    const subInnerColumns = [
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        {
            Header: 'Name',
            accessor: 'productTitle',
        },
        {
            Header: 'Integer ID',
            accessor: 'integerID',
        },
        {
            Header: 'Marketplace',
            accessor: 'location',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Price',
            accessor: 'itemPrice',
        },
        {
            Header: 'Rating',
            accessor: 'itemRating',
        },
        {
            Header: 'Is Managed',
            accessor: 'simpliworksManagementState',
        }, 
        {
            Header: 'Actions',
            accessor: 'asin',
            Cell: props => (
                <Fragment>                  
                    <Button
                        className=""  
                        color="link"
                        onClick={() => {
                            handleRemoveProduct(props.value,props.original.integerID);
                        }}
                    >
                        Delete
                    </Button>
                </Fragment>
            )
        },
        {
            Header: 'Link',
            accessor: 'link',
            Cell: props =><a          
                            href={props.value}
                            target="_blank"
                            rel="noopener noreferrer"
                        > 
                            Click here 
                        </a>
            
        }, 
    ];


   
    const [query, setQuery] = useState("");
    
    const search = (data) => {
        return props.data.filter((item) => item.firstName.toLowerCase().includes(query.toLowerCase()) || item.lastName.toLowerCase().includes(query.toLowerCase()) ||item.email.toLowerCase().includes(query.toLowerCase())  || item.companyName.toLowerCase().includes(query.toLowerCase())  || item.enrollmentPath.toLowerCase().includes(query.toLowerCase()));
    };

    return (
        <Fragment>
            <div>
                <Row>
                
                <Col md="2" className="mb-2">
                
                    <Input 
                        id="inputValue"
                        // style="width:80%"
                        style={{width: "100%"}}
                        placeholder='search text'
                        className="Search"
                        onChange={event => {setQuery(event.target.value);}}
                    />
                </Col>
                </Row>
            </div>

            <ReactTable                
                data={search(props.data)}
                columns={outerColumns}
                className="-striped -highlight -fixed"
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                defaultPageSize={10}
                noDataText={"No users found."}
                style={{
                    height: "800px" // This will force the table body to overflow and scroll, since there is not enough room
                }}
                
                
            />
            {/* <CustomModal
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                header={`Update ${selectedUser.name}'s Credit Balance`}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
                        <Button
                            color="success"
                            disabled={!newCreditBalance}
                            onClick={() => {
                                props.updateBalance(selectedUser.email, newCreditBalance);
                                setNewCreditBalance(undefined);
                                setIsOpen(!isOpen);
                            }}
                        >
                            Confirm Update
                        </Button>
                    </Fragment>
                }
            >
                <Row>
                    <Col md="12">
                        <Label for="newCreditBalance">Credit Balance</Label>
                        <Input className="input" id="newCreditBalance" placeholder={"Enter new credit balance"} value={newCreditBalance} onChange={e => setNewCreditBalance(e.target.value)} />
                    </Col>
                </Row>
            </CustomModal> */}
            <CustomModal
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                header={`Update ${selectedUser.name}'s Product Limits`}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
                        <Button
                            color="success"
                            disabled={!newProductLimit}
                            onClick={() => {
                                props.updateProductLimit(selectedUser.email, newProductLimit);
                                //props.requestStores(selectedUser.email);
                                setnewProductLimit(undefined);
                                setIsOpen(!isOpen);
                            }}
                        >
                            Confirm Update
                        </Button>
                    </Fragment>
                }
            >
                <Row>
                    <Col md="12">
                        <Label for="newProductLimit">Product Limits</Label>
                        <Input className="input" id="newProductLimit" placeholder={"Enter new product limit."} value={newProductLimit} onChange={e => setnewProductLimit(e.target.value)} />
                    </Col>
                </Row>
            </CustomModal>
            <CustomModal
                isOpen={isStoresOpen}
                toggle={() => setIsStoresOpen(!isStoresOpen)}
                header={`Amazon Stores for : ${selectedUser.name} `}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresOpen(!isStoresOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <RequestStoresDataTable                    
                    data={newStoresData}                  
                />
                </Card>

                
            </CustomModal>
            <CustomModal
                isOpen={isStoresAsinOpen}
                toggle={() => setIsStoresAsinOpen(!isStoresAsinOpen)}
                header={`Amazon Stores/Asin for : ${selectedUser.name} `}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresAsinOpen(!isStoresAsinOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <div className="p-3">
                <div>
                <h3>Stores</h3>
            </div>
            <ReactTable
                data={stores}
                columns={innerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />
            <p>
            
            &ensp;

            </p>
            <div>
                <h3>Products</h3>
            </div>
            <ReactTable
                data={managedProducts}
                columns={subInnerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />


        </div>
    
                </Card>

                
            </CustomModal>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 


export default connect(mapStateToProps)(UsersDirectoryTable)