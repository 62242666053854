import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";


function CreateSubscriptionService(token, priceID) {
    return axios.post(`${CURRENT_API_URL}/payment/create-subscription`, {
        priceId: priceID,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}


export default CreateSubscriptionService;