import React from 'react';
import {Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import DatePicker from 'react-datepicker';


/**
 * Date range input component that provides input fields for a start and end date using a calendar selector.
 * Dates are stored as JS Date objects in ISO format.
 * @param dateRange - state object containing startDate and endDate fields
 * @param setDateRange - callback function that modifies the date range state object
 * @param props - contains any other properties passed to this component
 */
function DateRangePicker({ dateRange, setDateRange, ...props }) {

    const handleDateChange = ({startDate, endDate}) => {
        startDate = startDate || dateRange.startDate;
        endDate = endDate || dateRange.endDate;

        if (startDate > endDate) {
            endDate = startDate;
        }

        setDateRange({startDate: startDate, endDate: endDate});
    }

    const handleChangeStart = startDate => handleDateChange({startDate});

    const handleChangeEnd = endDate => handleDateChange({endDate});


    return (
        <Form>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for="startDate" className="mr-sm-2">Start Date</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <div className="input-group-text">
                                    <FontAwesomeIcon icon={faCalendarAlt}/>
                                </div>
                            </InputGroupAddon>
                            <DatePicker
                                selected={dateRange.startDate}
                                selectsStart
                                className="form-control"
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={handleChangeStart}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="endDate" className="mr-sm-2">End Date</Label>
                        <DatePicker
                            selected={dateRange.endDate}
                            selectsEnd
                            className="form-control"
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={handleChangeEnd}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}

export default DateRangePicker;