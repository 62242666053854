import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import {LoadRegistrastionJournyData} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function OPSGetAllTicketsDataService(token,data) {
    // console.log(token)
    // notify.NotifySuccess("All tickets list is fetching. Please wait.");
    return axios.post(`${CURRENT_API_URL}/swhelpdesk/opsGetAllTicketsData`, data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        console.log(res.data)
        notify.NotifySuccess("Tickets list fetched successfully.");
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching tickets details. please try again later.");
        return false;
    });
}

export default OPSGetAllTicketsDataService
;