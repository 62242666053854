import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function ValidateContentAgencyService(accessCode) {
    // return function(dispatch) {
    //     dispatch({
    //         type: actionType.VALIDATE_CONTENT_AGENCY_BEGIN,
    //     })
    //
    //     axios.post(`${CURRENT_API_URL}/account/validateContentAgency`, {
    //         contentAgencyId: accessCode,
    //     }).then(res => {
    //         console.log(res)
    //         dispatch(LoadContentAgencyProfile(res.data));
    //     }).catch((error) => {
    //         console.log(error)
    //         dispatch({
    //             type: actionType.VALIDATE_CONTENT_AGENCY_FAILED,
    //         })
    //     })
    // }
    return axios.post(`${CURRENT_API_URL}/account/validateContentAgency`, {
        contentAgencyId: accessCode,
    }).then(res => {
        return res.data;
    }).catch((error) => {
        notify.NotifyError("The Access Code you entered was invalid.")
        return false;
    })
}

export default ValidateContentAgencyService;