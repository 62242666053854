import React from 'react';
import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";
import NewKeywordWidget from "./NewKeywordWidget";


function NewKeywordWidgetList(props) {

    const renderWidget = (keyword) => (
        <NewKeywordWidget
            keyword={keyword}
            handleRemove={props.handleRemove}
        />
    );

    if (props.data.length > 0) {
        return (
            <WidgetListCard
                widgets={props.data}
                renderWidget={renderWidget}
                listHeading="Added Keywords"
                card={props.card}
            />
        );
    } else return <></>;
}

export default NewKeywordWidgetList;