import axios from "axios";
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
function SaveTaskDetailsService(token,data) {
    return axios.post(`${CURRENT_API_URL}/workflows/saveTaskDetails`,data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            notify.NotifySuccess("Your task has been updated.")
            return res.data;
        }).catch(error => {  
            console.log(error)            
            notify.NotifyError("An error occurred while updating task, please try again later.");
            return false;
        
        });
}

export default SaveTaskDetailsService