import React, {Fragment, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader, CardTitle,
    Col,
    Container,
    Input,
    Label,
    Row,
} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import {connect} from 'react-redux';

import StoreOverviewService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import ProductOverviewService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService';
import urls from '../../../../../../Utils/URLs';
import StoreOverviewCard from './Components/Cards/StoreOverviewCard';
import Can from '../../../../../Components/Can';
import StoreTabs from './Components/Nav/StoreTabs';
import LoadingButton from '../../../../../Components/Buttons/LoadingButton';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import EditStoreNameService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/EditStoreNameService';
// import ProductPerformancePane from "./Components/ProductPerformancePane";
import StorePerformance from "./Components/StorePerformance";
// import ProductCampaigns from "./Components/ProductCampaigns";
import Search from "./Components/Img/search.svg";
import Campaign from "./Components/Img/campaign.svg";
import Target from "./Components/Img/target.svg";
import Weekly from "./Components/Img/weekly.svg";
import Product from "./Components/Img/product.svg";
import PowerbiEmbed from './Components/Cards/PowerbiEmbed';
import { height } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
function MyStoresPageLoki(props) {
    let {storeIntegerID} = props.match.params;

    const [store, setStore] = useState(props.adProfiles[0]);
    const history = useHistory();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reportpage, setReportpage] = useState(false);
    const [storeName, setStoreName] = useState('');
    const [newStoreName, setNewStoreName] = useState('');

    const [summary, setSummary] = useState();
	 const [reportName, setReportName] = useState();
	const [isOpen, setIsOpen] = useState(false);
    const [report, setReport] = useState('report4')

    var report1,report2,report3,report4,report5;

    if(store){
    switch (report) {
        case 'report1':
            var  table = 'AdvCampaignReportsSummary';
            var column = 'IntegerId';
            report1 = <PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.CampaignPerformanceReport} profileId={store.integerID} />
        break;
        case 'report2':
            var  table = 'SearchTerm_Report';
            var column = 'ProfileId';
            console.log(props.WeeklySearchTermReport)
            report2 = <PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.WeeklySearchTermReport} profileId={store.integerID} />;
        break;
        case 'report3':
            var  table = 'Merge1';
            var column = 'AdvTargetingSummary.ProfileId';
            report3 = <PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.TargetPerformanceReport} profileId={store.integerID} />;
        break;
        case 'report4':
            var  table = 'FinalReportMerge_ProfileId';
            var column = 'IntegerId';
            report4 = <PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.WeeklySalesReport} profileId={store.integerID} />;
        break;
        case 'report5':
            var  table = 'FinalReportMerge_ProfileId';
            var column = 'IntegerId';
            report5 = <PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.ProductPerformanceReport} profileId={store.integerID} />;
        break;

        default:
        var pageContent = <div>Report not found</div>;
    }
}
    

    // toggles product selection for later fetching of performance details
    const toggleSelect = (isSelected, asin, isParentProduct) => {
        console.log('In toggleSelect');
        console.log('isParentProduct');
        console.log(isParentProduct);
        console.log('isSelected');
        console.log(isSelected);
        console.log('asin');
        console.log(asin);
        console.log('selectedProducts')
        console.log(selectedProducts)
        if (isSelected) {
            if (isParentProduct) {
                setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}]); // if parent product selected, clear selectedProducts array and add parent product and dummy child product
            } else {
                if (selectedProducts.length > 0 && selectedProducts[0].isParentProduct) {   // if parent product is the only selection
                    setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, ...prevState])
                } else if (selectedProducts.length > 1 && selectedProducts[1].isParentProduct) {    // if a child product was already selected
                    setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, prevState[1]])
                } else {
                    setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}])   // no previous selection
                }
            }
        } else {
            if (isParentProduct) {
                setSelectedProducts([]); // clears array if parent product is unselected
            } else {
                setSelectedProducts(prevState => prevState.filter(product => product.ASIN !== asin));   // removes product with given ASIN from selectedProducts array
            }
        }
        console.log('selectedProducts')
        console.log(selectedProducts)
    };

    // handles waiting for store list data and properly rendering page upon visiting
    useEffect(() => {
        // redirect to first store in submenu if no store was selected
        if (!storeIntegerID && store) {
            history.push(
                `${urls.ACCOUNT_OVERVIEW_MY_STORES_URL}/${store.integerID}`
            );
        } else if (props.adProfiles && storeIntegerID) {
            setStore(
                props.adProfiles.find(
                    (profile) => profile.integerID == storeIntegerID
                )
            );
        } else if (props.adProfiles) {
            setStore(props.adProfiles[0]);
        }
    }, [history, store, props.adProfiles, storeIntegerID]);

    useEffect(() => {
        // redirect to first store in submenu if no store was selected
        if (!storeIntegerID && store) {
            history.push(
                `${urls.ACCOUNT_OVERVIEW_MY_STORES_RELAUNCH_URL}/${store.integerID}`
            );
        } else if (props.adProfiles && storeIntegerID) {
            setStore(
                props.adProfiles.find(
                    (profile) => profile.integerID == storeIntegerID
                )
            );
        } else if (props.adProfiles) {
            setStore(props.adProfiles[0]);
        }
    }, [history, store, props.adProfiles, storeIntegerID]);

    useEffect(() => {
        if (store) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    store.integerID,
                    store.details.accountID
                )
            );
            setStoreName(store.name);
        }
    }, [props.token, store]);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            // if selected product is not a parent product get rank data from product overview service
            if (!selectedProducts[0].isParentProduct) {
                props.dispatch(
                    ProductOverviewService(
                        props.token,
                        selectedProducts[0].ASIN,
                        store.integerID
                    )
                );
            }
        }
    }, [selectedProducts]);

    useEffect(() => {
        setSelectedProducts([]);
    }, [store]);


    const renderStoreDetails = () => (
        <Fragment>
            <Col md="12">
                <Col className="mb-3 p-0" md="12">
                    <Card>
                        <CardBody>
                            <StoreOverviewCard
                                profile={store}
                                SPAPIAuth={props.storeOverview}
                                weeklyReport={props.weeklyReport}
                                ProductPerformanceReport={props.ProductPerformanceReport}
                                WeeklySalesReport ={props.WeeklySalesReport}
                                SalesTrendsReport = {props.SalesTrendsReport}
                                TargetPerformanceReport={props.TargetPerformanceReport}
                                CampaignPerformanceReport={props.CampaignPerformanceReport}
                                WeeklySearchTermReport={props.WeeklySearchTermReport}
                                overviewData={props.storeOverview}
                                token={props.token}
                                accessLevel={props.accessLevel}
                            />
                        </CardBody>
                    </Card>
                </Col>
               {/* {props.storeOverview.SPAPIAuthorized == false ? '': */}
               <Col md="12" className="mb-2 p-0">
                    <StoreTabs
                        accessLevel={props.accessLevel}
                        storeIntegerID={store.integerID}
                        storeAccountID={store.details.accountID}
                        toggleSelect={toggleSelect}
                        selectedProducts={selectedProducts}
                    />
                </Col> 
                {/* } */}
                
            </Col>
          
        </Fragment>
    );
    
    const getCampaignPerformanceReport = () => {
        setReport('report1');
    }
    const getWeeklySearchTermReport = () => {
        setReport('report2');
    }
    const getTargetPerformanceReport = () => {
		setReport('report3');
    }
    const getWeeklySalesReport = () => {
        setReport('report4');
    }
    const getProductPerformanceReport = () => {
        setReport('report5');
    }

    const handleReportPage = () => 
    {
        setReportpage(true);
    }
    const handleStoreOverviewPage = () => 
    {
        setReportpage(false);
    }
        const renderWaitMessage = () => (
        <Col md="12">
            <h5>
                Your stores are getting loaded. Thank you for your patience.
            </h5>
        </Col>
    );
    const reportPage = () => (
        <Fragment>
            <Col md="12">
                <Col className="mb-3 p-0" md="12">
                    <Card>
                        <CardBody>
                           <Row className="justify-content-around">
                           <Col md="2" className="border p-6">
                                    <a onClick={getWeeklySalesReport}>
                                    <img src={Weekly} className="ml-2rem"/>
                                    <h6 className="text-center"><b>Weekly sales</b></h6>
                                    </a>
                                </Col>
                                <Col md="2" className="border p-6">
                                    <a onClick={getProductPerformanceReport}>
                                    <img src={Product} className="ml-2rem" />
                                    <h6 className="text-center"><b>Product performance</b></h6>
                                    </a>
                                </Col>
                                <Col md="2" className="border p-6">
                                    <a onClick={getCampaignPerformanceReport}>
                                    <img src={Campaign} className="ml-2rem"/>
                                    <h6 className="text-center"><b>Campaigns</b></h6>
                                    </a>
                                </Col>

                                <Col md="2" className="border p-6" >
                                    <a onClick={getWeeklySearchTermReport}>
                                    <img src={Search} className="ml-2rem"/>
                                    <h6 className="text-center"><b>Search Terms</b></h6>
                                    </a>
                                </Col>
                                <Col md="2" className="border p-6">
                                    <a onClick={getTargetPerformanceReport}>
                                    <img src={Target}className="ml-2rem"/>
                                    <h6 className="text-center"><b>Targeting</b></h6>
                                    </a>
                                </Col>
                                
                           </Row>
                          
                        </CardBody> 
                        
                    </Card>
                    
                </Col>
                        {/* {props.status === 'success'
                        ? (report === true ? allPBIReports(): '')
                        : ''} */}
                        {/* {report} */}
                        {/* {pageContent} */}
                <Col md="12">
                        <Label className="w-100"> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{report4}</pre> </p></Label>
                        <Label className="w-100"> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{report5}</pre> </p></Label>
                        <Label className="w-100"> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{report1}</pre> </p></Label>
                        <Label className="w-100"> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{report2}</pre> </p></Label>
                        <Label className="w-100"> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{report3}</pre> </p></Label>
                </Col>
                        
            </Col>
            <CustomModal
                header={reportName}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
        </Fragment>
    );
   
    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row>
                        <Col md="12" className="mbg-3 font-size-lg">
                        <nav className="navbar navbar-expand-lg" style={{backgroundColor:'white'}}>
                                <div className="navbar-nav">
                                <a className={ reportpage == false ? "nav-link pl-3 pr-3 active-nav" : "nav-link pl-3 pr-3" } href="#" onClick={handleStoreOverviewPage}>Store Overview</a>
                                <a className={ reportpage ? "nav-link pl-3 pr-3 active-nav" : "nav-link pl-3 pr-3" } href="#" onClick={handleReportPage}>Reports</a>
                                {/* <a className="nav-link pl-3 pr-3" href="#">Product List</a> */}
                                </div>
                        </nav>
                        </Col>
                        {/* <Col md="12" className="mbg-3 font-size-xlg">
                            {store && store.logo ? (
                                <img
                                    className="mr-2"
                                    width={100}
                                    src={store.logo}
                                    alt=""
                                />
                            ) : (
                                <i
                                    className="lnr-store icon-gradient bg-simpli-gradient mr-2"
                                    style={{fontSize: '35px'}}
                                ></i>
                            )}
                            <h5
                                className="text-brand-blue-dark"
                                style={{
                                    display: 'inline',
                                    fontSize: '1.6rem',
                                }}
                            >
                                {store ? storeName : ''}
                            </h5>

                        </Col> */}
                    </Row>
                    <LoadingOverlay
                        active={props.storeLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#f1f4f6',
                                opacity: 1,
                            }),
                            content: (base) => ({
                                ...base,
                                color: '#000',
                            }),
                        }}
                        spinner={<Loader active type="ball-pulse"/>}
                        text="Loading store details..."
                    >
                        <Row>
                            {props.status === 'success'
                                ? (reportpage === true ? reportPage(): renderStoreDetails())
                                : renderWaitMessage()}
                        </Row>
                    </LoadingOverlay>
                    {  }
                    {/*{ renderProductManagementModal() }*/}
                </Container>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    adProfiles: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
    storeOverview: state.accountOverview.store.storeOverview,
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
    WeeklySearchTermReport:state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport:state.accountOverview.store.CampaignPerformanceReport,
    sellerFeedback: state.accountOverview.store.sellerFeedback,
    storeProducts: state.accountOverview.store.storeProducts,
    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
    metricData: state.accountOverview.store.metricData,
    storeCampaignUpdates: state.accountOverview.store.campaignUpdates,
    storeCreditPurchases: state.accountOverview.store.creditPurchases,

    productOverview: state.accountOverview.product.productOverview,
    parentProductKeywordData: state.accountOverview.product.parentProductKeywordData,
    keywordTableData: state.accountOverview.product.keywordTableData,
    topKeywords: state.accountOverview.product.topKeywords,
    impressionGeneratingKeywords: state.accountOverview.product.impressionGeneratingKeywords,
    mrpData: state.accountOverview.product.mrpData,
    isProductManaged: state.accountOverview.product.productOverview.isManaged,
    productLoading: state.accountOverview.product.loading,
    productCampaigns: state.accountOverview.product.productCampaigns,
    productCampaignUpdates: state.accountOverview.product.campaignUpdates,
    productCreditPurchases: state.accountOverview.product.creditPurchases,
});

export default connect(mapStateToProps)(MyStoresPageLoki);
