import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function UserAuthorizationService(token, userAuthorization) {
    console.log(userAuthorization)
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/opsDash/userAuthorizations`, userAuthorization, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while checking product status, please try again later.");
            return false;
        });
}


export {
    UserAuthorizationService,
}