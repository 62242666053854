import React from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";



function StoreTable(props) {

    const viewStoreButton = (profile) => (
        <Button
            key={profile.integerID}
            color="link"
            onClick={() => {
                return props.showModal(profile);
            }}
        >
            View Store {'>'}
        </Button>
    );

    const columns = [
        {
            Header: 'Store Name',
            accessor: 'name', // accessor is the "key" in the data
        },
        {
            Header: 'Location',
            accessor: 'location',
        },
        {
            Header: 'Account ID',
            accessor: 'details.accountID',
        },
        {
            Header: 'Profile ID',
            accessor: 'integerID',
        },
        {
            Header: 'Actions',
            Cell: props => viewStoreButton(props.original),
        },
    ];

    return (
        <ReactTable
            data={props.data}
            columns={columns}
            defaultPageSize={20}
            noDataText="No Profiles found"
            style={{
                height: "600px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
        />
    );
}

export default StoreTable;
