import React from 'react';
import WidgetListCard from "../../../../../../../Components/Widgets/WidgetListCard";
import CartWidget from "./CartWidget";


function CartWidgetList(props) {

    // const widgetsData = props.data.map(campaign => {
    //     const { productASIN, advertisingObjective, dailyBudget, ...details } = campaign;
    //     return {
    //         productASIN,
    //         advertisingObjective,
    //         dailyBudget,
    //         details,
    //     }
    // });

    const renderCartWidget = (campaign) => (
        <CartWidget
            campaign={campaign}
            removeCampaign={props.removeCampaign}
            launchCampaign={props.launchCampaign}
            loading={props.loading}
        />
    )

    return (
        <WidgetListCard
            widgets={props.data}
            renderWidget={renderCartWidget}
            card={props.card}
        />
    )
}

export default CartWidgetList;