import React, {Fragment} from 'react';
import {Button, Col, Row} from "reactstrap";
import LoadingButton from "../../../../../../../Components/Buttons/LoadingButton";
import CustomModal from "../../../../../../../Components/Modals/CustomModal";


function ProductManagementModal(props) {

    const {
        isManagementOpen,
        setIsManagementOpen,
        managementProduct,
        updateProductManagement } = props;

    return (
        <CustomModal
            header="Product Management"
            isOpen={isManagementOpen}
            toggle={() => setIsManagementOpen(!isManagementOpen)}
            footer={
                <Fragment>
                    <Button
                        style={{fontSize: "small"}}
                        color="link"
                        onClick={() => setIsManagementOpen(!isManagementOpen)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={props.loading}
                        color="success"
                        onClick={() => {
                            setIsManagementOpen(!isManagementOpen);
                            return updateProductManagement(managementProduct.asin, 'pause');
                        }}
                    >
                        Pause Management
                    </LoadingButton>
                </Fragment>
            }
        >
            <Row>
                {/*<Col md="12">*/}
                {/*    For 1 Credit a day, Simpliworks can manage and optimize bids for this product's 1-Click campaigns to maximize ROAS (Return On Ad Spend).*/}
                {/*</Col>*/}
                <Col md="12">
                    Pausing Simpliworks management for a product will pause its 1-Click Campaign and bid optimizations.
                </Col>
            </Row>
        </CustomModal>
    )
}

export default ProductManagementModal;