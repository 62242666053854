import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function SaveSummaryReportsV3Service(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/SaveSummaryReportsV3FromUI`,data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Saving daily advertising reports."))      
            .then(res => {
                notify.NotifySuccess("Advertising reports have been saved in DB.");
                return true;
            }).catch(() => {
                notify.NotifyError("An error occurred while saving advertising reports.");
                return false;
            });
}
export {
    SaveSummaryReportsV3Service,
}