import axios from 'axios';
import { LoginUser, TriggerAmznLogout, RegistrationFailed } from "../../Redux/Actions/Actions";
import actionType from "../../Redux/Actions/ActionTypes";
import { CURRENT_API_URL } from "../../Utils/Constants";


function SellerRegistrationService(user) {
    return function (dispatch) {
        const emailPattern= /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if(!emailPattern.test(user.email)) {
            dispatch(RegistrationFailed("Invalid Email. Please enter valied Email address."));
            return false;
          }
        dispatch({
            type: actionType.LOGIN_BEGIN,
            newUser: true,
        });

        axios.post(`${CURRENT_API_URL}/account/register`, user)
            .then(res => {
                if (res.status === 201) {
                    dispatch(TriggerAmznLogout());
                    dispatch(LoginUser(
                        res.data.user,
                        res.data.token,
                        res.data.accessLevel,
                        res.data.profiles,
                        res.data.needsAdvertisingAPIAuth,
                        res.data.subscriptionLevel,
                        res.data.appSumoPlanId,
                        res.data.hasCampaigns,
                    ));
                }
                console.log(res)
            })
            .catch(error => {
                    if (error.response) {
                        console.log(error.response)
                        error.response.status === 409 ?
                            dispatch(RegistrationFailed("Please use an Amazon Seller account to register."))
                            :
                            dispatch(RegistrationFailed("A user with that e-mail address already exists."));
                    } else if (error.request) {
                        dispatch(RegistrationFailed("We are having trouble connecting to the server. Please try again later."));
                    } else {
                        dispatch(RegistrationFailed("We're sorry, but we're not sure what went wrong. Please try again later."));
                    }
                }
            );
    }
}

export default SellerRegistrationService;
