import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import notify from '../../../Notifications/ToastifyActions';


function StoreAuthorizationService(token, oauthCode, sellingPartnerID, region) {
    return function(dispatch) {
        if( oauthCode && oauthCode.trim() !== '')
        {
            notify.NotifySuccess("SPAPI Authorization is successful.Store set up process has been started in the background.Screen will be refreshed after process completion.");
        }

        axios.post(`${CURRENT_API_URL}/sellerPartner/authorize`, {
            authorizationCode: oauthCode,
            sellingPartnerID: sellingPartnerID,
            region: region,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(() => {
                dispatch({
                    type: actionType.STORE_AUTH_SUCCESS,
                });                
                window.location.reload(false);                
            })
            .catch(() => {
                notify.NotifyError("We've encountered an error while processing your request, please try again.");
            })
    }
}

export default StoreAuthorizationService;