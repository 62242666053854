import React, { Fragment } from 'react';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { IoIosPause } from "react-icons/all";

import CustomWidget from "../../../../Components/Widgets/CustomWidget";
import StarRating from "../../../Scenes/AccountOverview/Components/Rating/StarRating";
import LoadingButton from "../../../../Components/Buttons/LoadingButton";


const managementStates = {
    INACTIVE: "unManaged",
    ACTIVE: "managed",
    PAUSED: "paused",
};

function ProductWidget(props) {

    const { productTitle, productImage, productRating, numProductReadyCampaigns, ratingVotes, details, isManaged,
        managementStatus, handleRedirect, updateProductManagement, quickLaunch, handleQuickActions } = props;

    const viewProductDetailsButton = (key) => (
        <Button
            key={key}
            color="link"
            onClick={() => handleRedirect(details.ASIN, details.SKU)}
        >
            View Product Details {'>'}
        </Button>
    );

    const updateProductManagementButton = (key) => (
        (managementStatus !== managementStates.INACTIVE) &&
        <LoadingButton
            loading={props.loading}
            key={key}
            color="primary"
            onClick={() => updateProductManagement(details.ASIN, details.SKU, managementStatus === managementStates.ACTIVE ? "pause" : "resume")}
        >
            {managementStatus === managementStates.ACTIVE ? "Pause Management" : "Resume Management"}
        </LoadingButton>
    );


    const launchCampaignButton = (key) => (
        (managementStatus === managementStates.INACTIVE) &&
        <LoadingButton
            loading={props.loading}
            key={key}
            color="primary"
            onClick={() => quickLaunch(details.ASIN, details.SKU)}
        >
            1-Click Campaign
        </LoadingButton>
    );

    const quickActionsDropdown = (key) => {
        if (handleQuickActions) {
            return (
                <UncontrolledButtonDropdown key={key}>
                    <DropdownToggle caret color="link">
                        More Actions
                    </DropdownToggle>
                    <DropdownMenu>
                        {
                            handleQuickActions.map(qa => (
                                <DropdownItem key={key + qa.name} onClick={() => qa.action({productASIN: details.ASIN, productSKU: details.SKU, managementStatus: managementStatus})}>{qa.name(isManaged)}</DropdownItem>
                            ))
                        }
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            );
        }
    };

    const subheading = () => (
        <Fragment>
            { ratingVotes !== 0 ?
                <div>Rating: <StarRating initialRating={productRating} readonly stop={5}/> ({productRating}) ({ratingVotes} reviews)</div>
                :
                <div>No ratings available</div>
            }
            { numProductReadyCampaigns > 0 &&
                <div>{numProductReadyCampaigns} campaigns for this product are ready to launch</div>
            }
            {/*{ isManaged &&*/}
            {/*    <div className="mt-1 text-brand-blue-dark">*/}
            {/*        <i className="pe-7s-check" style={{fontSize: "1rem", color: "green", fontWeight: "bold"}}> </i> Managed by Simpliworks*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{ isManaged &&*/}
            {/*<div className="font-icon-wrapper mt-1 text-brand-blue-dark">*/}
            {/*    <IoIosPause fontSize="1rem"/> Management Paused*/}
            {/*</div>*/}
            {/*}*/}
            {
                managementStatus === managementStates.ACTIVE ?
                    <div className="mt-2 text-brand-blue-dark font-weight-bold">
                        <i className="pe-7s-check" style={{fontSize: "1rem", color: "green", fontWeight: "bold"}}> </i> Managed by Simpliworks
                    </div>
                    :
                    managementStatus === managementStates.PAUSED ?
                        <div className="mt-2 text-warning font-weight-bold">
                            <i className="pe-7s-attention text-warning" style={{fontSize: "1rem", fontWeight: "bold"}}> </i> Management Paused
                        </div>
                        :
                        <></>
            }
        </Fragment>
    );


    return (
        <CustomWidget
            collapsible={true}
            heading={productTitle}
            image={productImage}
            subheading={subheading()}
            details={details}
            autoFormat={true}
            actions={handleQuickActions ?
                [launchCampaignButton, quickActionsDropdown]
                : [updateProductManagementButton, viewProductDetailsButton]}
        />
    )
}

export default ProductWidget;