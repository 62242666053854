import React, { useState } from 'react';
import ReactTable from "react-table";
import CustomModal from "../../../Components/Modals/CustomModal";
import {Card} from "reactstrap";


function AppSumoTiersModal({data, isOpen, setOpen, ...props}) {

    const columns = [
        {
            Header: "Plan Tier",
            accessor: "tierName",
        },
        {
            Header: "Deal Price",
            accessor: "price",
            Cell: cellInfo => `$${cellInfo.value}`,
        },
        {
            Header: "Stores",
            accessor: "numStores",
            Cell: cellInfo => cellInfo.value < 0 ? "Unlimited" : cellInfo.value,
        },
        {
            Header: "Product families",
            accessor: "numProductFamilies",
        },
        {
            Header: "Users",
            accessor: "numUsers",
            Cell: cellInfo => cellInfo.value < 0 ? "Unlimited" : cellInfo.value,
        },
        {
            Header: "Sub-accounts",
            accessor: "numSubAccounts",
            Cell: cellInfo => cellInfo.value > 0 ? cellInfo.value : "",
        },
        {
            Header: "White-labeled reporting",
            accessor: "hasWhiteLabelReports",
            Cell: cellInfo => cellInfo.value ? "Yes" : "",
        },
    ];


    return (
        <CustomModal
            isOpen={isOpen}
            size={"xl"}
            toggle={() => setOpen(!isOpen)}
            header={"AppSumo Tiers"}
        >
            <Card>
                <ReactTable
                    columns={columns}
                    data={data}
                    defaultPageSize={5}
                    getTheadProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                overflowY: "auto",
                            }
                        }
                    }}
                    className="-striped -highlight -fixed"
                    style={{
                        height: "500px"
                    }}
                />
            </Card>
        </CustomModal>
    )

}

export default AppSumoTiersModal;