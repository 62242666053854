import axios from 'axios';
import { UpdateCreditBalance } from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';
import { CURRENT_API_URL } from "../../../Utils/Constants";


function CreditBalanceService(token) {
    return function(dispatch) {

        axios.post(`${CURRENT_API_URL}/campaign/userCreditAmount`, {},{
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(UpdateCreditBalance(res.data.numCredits));
            })
            .catch(error => {
                if (!error.response && error.request) {
                    notify.NotifyError('Network Error: unable to update credit balance');
                } else {
                    notify.NotifyError('Unknown Error: unable to update credit balance');
                }
            })
    }
}

export default CreditBalanceService;
