import axios from 'axios';

import notify from '../../../../Notifications/ToastifyActions';
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function UpdateBulkProductManagementService(token, asinArray, update, integerID) {
    return axios.post(`${CURRENT_API_URL}/campaign/updateBulkManagementStatus`, {
        asinArray: asinArray,
        update: update,
        integerId: integerID,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Your product's management status was updated successfully.");
            return true;
        })
        .catch(error => {
            if (error.response.status === 412) {
                notify.NotifyError("Product must have running 1-Click Campaign(s) before being added to management.");
                return false;
            } else {
                notify.NotifyError("We've encountered an error while updating your product's management status. Please try again later.");
                return false;
            }
        });
}

export default UpdateBulkProductManagementService;