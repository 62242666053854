import React, { Fragment } from 'react';
import { DropdownList } from 'react-widgets';
import { useField } from 'formik';


/**
 * Wrapper for DropdownList component to make it a formik input. All props are passed down to the DropdownList component.
 */
function CustomSelectInput(props) {

    const [field, meta, helpers] = useField(props);

    return (
        <Fragment>
            <DropdownList
                {...props}
                {...field}
                onChange={value => {
                    if (props.valueField) {
                        helpers.setValue(value[props.valueField]);
                    } else {
                        helpers.setValue(value);
                    }
                }}
                defaultValue={meta.initialValue}
                data={props.data}
                dropUp={props.dropUp}
            />
            { meta.touched && meta.error &&
                <p className="invalid-feedback" style={{display: 'block'}}>
                    {meta.error}
                </p>
            }
        </Fragment>

    );
}

export default CustomSelectInput;



