import React from 'react';
import ReactTable from "react-table";


function ContentAgencyTable(props) {
    const columns = [
        {
            Header: "Content Agency",
            accessor: "contentAgencyName",
        },
        {
            Header: "Master Access Code",
            accessor: "contentAgencyMasterId",
        },
        {
            Header: "User Access Code",
            accessor: "contentAgencyUserId",
        },
    ];


    return (
        <ReactTable
            columns={columns}
            data={props.data}
            style={{
                height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
        />
    )
}

export default ContentAgencyTable;