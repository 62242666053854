import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function CustomerPortalService(token) {
    return axios.get(`${CURRENT_API_URL}/payment/create-portal`, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(error => {
        notify.NotifyError("An error occurred while redirecting you to the customer portal, please try again.");
        return false;
    })
}

export default CustomerPortalService;