import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function UpdateProductLimitService(token, email, newProductLimit) {
    return axios.post(`${CURRENT_API_URL}/campaign/userDirectory`, {
        email: email,
        newProductLimit: newProductLimit,
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("User product limit successfully updated!");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while updating user product limit, please try again.");
        return false;
    });
}

export default UpdateProductLimitService;