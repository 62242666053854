import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function AdvertisingDataService(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getAdvertisingAPIData`, data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Advertising reports are getting generated.")).
        then(res => {
            return res.data;
        }).catch(error => {  
            console.log(error)            
            notify.NotifyError("An error occurred while generating report, please try again later.");
            return false;
        
        });
}
export {
    AdvertisingDataService,
}