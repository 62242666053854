import axios from 'axios';
import notify from "../../../../Notifications/ToastifyActions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function ProductListService(token) {
    return axios.get(`${CURRENT_API_URL}/campaign/profile`, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(() => {
        notify.NotifyError('We\'ve encountered an error while retrieving your product details, please try again later.');
        return null;
    })
}

export default ProductListService;