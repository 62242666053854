import React from 'react';
import Rating from 'react-rating';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function StarRating(props) {
    return (
        <Rating
            {...props}
            emptySymbol={<span className="text-warning mr-1 opacity-2"><FontAwesomeIcon
                icon={faStar}/></span>}
            fullSymbol={<span className="text-warning mr-1"><FontAwesomeIcon
                icon={faStar}/></span>}
        />
    )
}

export default StarRating;