import React from 'react';
import ReactTable from "react-table";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";


function SubAccountsTable({ data, manageStores, quickActions }) {
    const columns = [
        {
            Header: "Name",
            accessor: "subAccountName",
        },
        // {
        //     Header: "Email",
        //     accessor: "email",
        // },
        {
            Header: "Actions",
            width: 100,
            Cell: (cellInfo) => (
                <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="link">
                            View
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                quickActions.map(qa => (
                                    <DropdownItem key={qa.name} onClick={() => qa.action(cellInfo.original)}>{qa.name}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                </UncontrolledButtonDropdown>
                )
            // <Button
            //     color="link"
            //     onClick={() => manageStores(cellInfo.original)}
            // >
            //     Assign Stores >
            // </Button>

        }
    ];

    return (
        <ReactTable
            columns={columns}
            data={data}
            style={{
                height: "500px",
            }}
            className="-striped -highlight -fixed"
            noDataText="No accounts found."
        />
    )
}

export default SubAccountsTable;
