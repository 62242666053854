import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { IoIosContact } from "react-icons/io";


function UserProfileDropdown(props) {
    return (
        <div className={props.className}>
            <UncontrolledButtonDropdown>
                <DropdownToggle caret color="link" className="p-0">
                    <div className="text-primary font-weight-bold mr-2" style={{display: "inline-block"}}>{props.userData.firstName + ' ' + props.userData.lastName}</div>
                    <div className="font-icon-wrapper m-0 rounded-circle border-0 p-0" style={{display: "inline-block"}}>
                        <IoIosContact fontSize="40px" />
                    </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right">
                    {
                        props.buttons.map( (button, i) => (
                            <DropdownItem key={i} onClick={() => button.action()}>{button.name}</DropdownItem>
                        ))
                    }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>
    )
}

export default UserProfileDropdown;