import axios from 'axios';

import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function AddStoreProductService(token, asin, integerID) {
    return axios.post(`${CURRENT_API_URL}/campaign/addProductToStore`, {
        asin: asin,
        // sku: sku,
        integerId: integerID,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            notify.NotifySuccess("Your request was successfully processed and the product will be added to your store. Please refresh the page to view your updated product catalog.");
            return true;
        })
        .catch(() => {
            notify.NotifyError("We've encountered an error trying to add your product to the store. Please ensure the product ASIN is correct and associated to the selected store.");
            return false;
        })
}

export default AddStoreProductService;