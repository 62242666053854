import React, {Fragment} from 'react';
import {Button, Col, Row} from "reactstrap";
import LoadingButton from '../../../../../../Components/Buttons/LoadingButton';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
function ProductTrackingModal(props) {

    const {
        isTrackingOpen,
        setIsTrackingOpen,
        trackingProduct,
        updateProductTracking } = props;

    return (
        <CustomModal
            header="Product Tracking"
            isOpen={isTrackingOpen}
            toggle={() => setIsTrackingOpen(!isTrackingOpen)}
            footer={
                <Fragment>
                    <Button
                        style={{fontSize: "small"}}
                        color="link"
                        onClick={() => setIsTrackingOpen(!isTrackingOpen)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={props.loading}
                        color="success"
                        onClick={() => {
                            setIsTrackingOpen(!isTrackingOpen);
                            return updateProductTracking(trackingProduct.asin, trackingProduct.update);
                        }}
                    >
                        Start Product Tracking
                    </LoadingButton>
                </Fragment>
            }
        >
            <Row>
                {/*<Col md="12">*/}
                {/*    For 1 Credit a day, Simpliworks can manage and optimize bids for this product's 1-Click campaigns to maximize ROAS (Return On Ad Spend).*/}
                {/*</Col>*/}
                <Col md="12">
                </Col>
            </Row>
        </CustomModal>
    )
}


export default ProductTrackingModal;