import React from 'react';
import ReactTable from "react-table";


function TargetedProductTable(props) {

    const columns = [
        {
            Header: 'Product ASIN',
            accessor: 'asin',
        },
        {
            Header: 'Bid Price',
            accessor: 'bidPrice',
            Cell: props => props.value ? `$${props.value}` : "unavailable",
        },
    ];

    return (
        <ReactTable
            data={props.data}
            columns={columns}
            style={{
                height: "400px",
            }}
            className="-striped -highlight -fixed"
            noDataText="No products found"
        />
    );
}

export default TargetedProductTable;