import React, {Fragment, useState, useEffect} from 'react';
import {Container, Row, Col, Card, CardBody, Button, Label, Input} from 'reactstrap';
import {connect} from 'react-redux';
import ProductCategoryService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/ProductCategoryService";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import ProductCategoriesTable from "./Components/ProductCategoriesTable";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import CategoryProductsAndSellersService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/CategoryProductsAndSellersService";
import ResearchCategorySelection from "./Components/ResearchCategorySelection";
import AssignContentAgencyService
    from "../../../../../../Service/DashboardServices/UserProfileServices/AssignContentAgencyService";
import ProjectsListService from "../../../../../../Service/DashboardServices/UserProfileServices/ProjectsListService";
import FetchAmazonDomainsService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/FetchAmazonDomainsService"
import { DropdownList } from "react-widgets";

function LeadGenerationPage(props) {

    const [productASIN, setProductASIN] = useState('');
    const [amazonDomain,setamazonDomain] = useState('');
    const [message, setmessage] = useState('');

    const handleAssignAgency = async (projectName, agency) => {
        let response = await AssignContentAgencyService(props.token,
            projectName, agency.contentAgencyMasterId, agency.contentAgencyName);

        if (response) {
            props.dispatch(ProjectsListService(props.token));
        }
    }

    useEffect(() => {
        props.dispatch(FetchAmazonDomainsService(props.token)); 
    }, [])

    const handleResearchProduct = () => {
        if((!productASIN) & (!amazonDomain))
        return;
      
        props.dispatch(ProductCategoryService(props.token, productASIN,amazonDomain.Domain_name));
    }

    const handleResearchCategory = (categoryID) => {
        if (!categoryID) return;

        props.dispatch(CategoryProductsAndSellersService(props.token, categoryID));
    }

    return (
        <Fragment>
            <Container fluid>
            <Row>
                    <Col md="9" className="mb-2">
                        <h4>Research For Product</h4>
                    </Col>
                </Row>
            <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col md="4" className="mb-2"> 
                                    <DropdownList
                                    data={props.amazonDomains}
                                    dataKey='Domain_name'                    
                                    textField='Country'                   
                                    placeholder="Select Country"
                                    value={amazonDomain}
                                    onChange={(value) => {
                                        setamazonDomain(value);
                                    }}
                                />   
                                
                                </Col>        
                               
            </Row>            
            <Row>
                        <Col md="4" className="mb-2"> 
                        
                        <Input
                            id="inputASIN"
                            placeholder="Enter Product ASIN"
                            value={productASIN}
                            onChange={(e) =>
                                setProductASIN(e.target.value)
                            }
                        />

                    </Col>
                    <Col md="9" className="mb-2">
                        <LoadingButton
                            className="mb-3"
                            color="brand-blue-dark"
                            onClick={() => handleResearchProduct()}
                            loading={props.status === 'loading'}

                        >
                            Fetch Information
                        </LoadingButton>
                    </Col>
            </Row>
                                  
                                
                                
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>        
                <Row>
                        <h6 className="mt-3 text-primary" color="green">
                        {' '}
                            <b>{props.productCategories.message}</b></h6>                            
                    </Row > 
            
                    <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col md="9" className="mb-2"> 
                                    <h5 className="text-brand-green">Product Details</h5>
					           
                                    <h5>Asin :</h5><h6> {props.productCategories.asin}</h6>
                                    <h5>Title : </h5><h6>{props.productCategories.title}</h6>                                   
                                    <h5>Price :  </h5><h6>{props.productCategories.price}</h6>
                                    <h5>Manufacturer : </h5><h6>{props.productCategories.manufacturer}</h6>
                                    <h5>Amazon Link :</h5>
                                    
                                                <a
                                            className="mt-1"
                                            href={props.productCategories.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {props.productCategories.link}
                                        </a>
                                    <p></p>
                                    <h5>Parent Asin :  </h5><h6>{props.productCategories.parent_asin}</h6>
                                    <p></p>
                                    <h5 className="text-brand-green">Brand Details</h5>
                                    <h6>Name : {props.productCategories.brand}</h6>
                                    <h6>Store Id : {props.productCategories.brand_store}</h6>
                                    <h6>Amazon Link:</h6>
                                    
                                    <a
                                            className="mt-1"
                                            href={props.productCategories.brand_store_Link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {props.productCategories.brand_store_Link}
                                        </a>
                                    <p></p>
                                    
                                    <p></p>
                                    <h5 className="text-brand-green">Rating Details</h5>
                                    <h6>RATING : {props.productCategories.rating}</h6>
                                    <h6>RATINGS_TOTAL : {props.productCategories.ratings_total}</h6>
                                    <h6>BESTSELLERS_RANK : {props.productCategories.bestsellers_rank}</h6>
                                    <p></p>
                                    <h5 className="text-brand-green">Keyword Details</h5>
                                    <h6>Keywords : {props.productCategories.keywords}</h6>
                                    <h6>Amazon Choice Keywords : {props.productCategories.amazons_choice_Keywords}</h6>
                                    <p></p>
                                    <h5 className="text-brand-green">Sales Estimates</h5>
                                    <h6>Best Sellers Rank : {props.productCategories.bestseller_rank}</h6>
                                    <h6>Monthly : {props.productCategories.monthly_sales_estimate}</h6>
                                    <h6>Weekly : {props.productCategories.weekly_sales_estimate}</h6>
                                    
                                    <h6>Sales Estimation Category : {props.productCategories.sales_estimation_category}</h6>
                                    <h6>Total Market Size : {props.productCategories.total_market_size}</h6>
                                    <h6>Sales Estimation Message :</h6><h6> {props.productCategories.salesmessage}</h6>
                                    </Col>
                                    </Row>
                                    </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                        </Col>
                        </Row >

                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card className="mb-3">
                                <CardBody>
                                    <h5 className="text-brand-green">Categories</h5>
                                    <ProductCategoriesTable
                                        data={props.productCategories.categoryList}
                                        handleResearchCategory={handleResearchCategory}
                                    />
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>                
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    productCategories: state.profile.productCategories,
    amazonDomains:state.profile.amazonDomains,
    status: state.profile.status,
    contentAgencies: state.profile.contentAgencies,
    projects: state.profile.projects,
    status: state.profile.status,
})

export default connect(mapStateToProps)(LeadGenerationPage);
