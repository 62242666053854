import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";

function CampaignChangelogService(token, campaignID) {
    return axios.post(`${CURRENT_API_URL}/campaign/campaignChangeLog`, {
        campaignID: campaignID,
    }, {
        headers: {
            'Authorization': token,
        },
    }).then((res) => {
        return res.data['campaignUpdates'];
    }).catch(() => {
        notify.NotifyError("An error occurred while retrieving your campaign changelog, please try again.");
        return false;
    });
}

export default CampaignChangelogService;