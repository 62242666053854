import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

import SummaryCard from "../../../Components/Cards/SummaryCard";
import Can from "../../../../../../Components/Can";
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";


function ProductSummaryCard(props) {

    const { image, title, asin, sku, rating, ratingVotes, price, isManaged,
    } = props.productOverview;

    const header = () => (
        <Fragment>
            Product Summary
            <Can
                role={props.accessLevel}
                perform={"campaigns:create"}
                yes={() => (
                    <Button
                        color="link"
                        onClick={() => props.handleRedirect(asin, sku)}
                    >
                        Build new campaign for this product {'>'}
                    </Button>
                )}
            />
        </Fragment>
    );

    const footer = () => (
        <Fragment>
            { isManaged &&
            <div className="mt-1 text-brand-blue-dark">
                <i className="pe-7s-check" style={{fontSize: "1rem", color: "green", fontWeight: "bold"}}> </i> Managed by Simpliworks
            </div>
            }
            <LoadingButton
                loading={props.loading}
                className="ml-auto"
                color={isManaged ? "danger" : "brand-blue-dark"}
                outline={isManaged}
                onClick={() => props.updateProductManagement(asin, sku, isManaged ? "pause" : "resume")}
            >
                {isManaged ? "Pause Management" : "Resume Management"}
            </LoadingButton>
            <Button
                className="ml-2"
                color="success"
                onClick={() => props.upgradeListing()}
            >
                Upgrade Product Listing
            </Button>
        </Fragment>
    )

    return (
        <SummaryCard
            header={header()}
            image={image}
            rating={rating}
            ratingVotes={ratingVotes}
            title={title}
            body={[
                {label: "ASIN", value: asin},
                {label: "SKU", value: sku},
                {label: "Price", value: `$${price}`},
                {label: "Product Stage", value: `Swarm`},

            ]}
            footer={footer()}
        />
    );
}


export default ProductSummaryCard;