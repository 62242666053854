import  React, {Fragment, useEffect, useState} from 'react';
import {Col, Input, Row, Button, Label} from 'reactstrap';
import {useHistory} from 'react-router-dom';

import ProductWidgetList from '../../../../../../Components/Widgets/Products/ProductWidgetList';
import Can, {filterByPermission} from '../../../../../../../Components/Can';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';
import QuickLaunchCampaignModal from '../../Modals/QuickLaunchCampaignModal';
import ProductManagementModal from '../../Modals/ProductManagementModal';
import actionType from '../../../../../../../../Redux/Actions/ActionTypes';
import urls from '../../../../../../../../Utils/URLs';

import ProductTrackingModal from '../../Modals/ProductTrackingModal';
import GroupedProductWidgetList from '../../Widgets/GroupedProductWidgetList';
import ExportProductKeywordService
    from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ExportProductKeywordService";
import {ExportExcel} from "../../../../../../../../Utils/Utilities";
import {loadStripe} from "@stripe/stripe-js";
import {CURRENT_STRIPE_PK} from "../../../../../../../../Utils/Constants";
import notify from "../../../../../../../../Notifications/ToastifyActions";
import addOns from "../../../../../../../../Utils/AddOns";
import ProductTrackingAddOnService
    from "../../../../../../../../Service/PaymentServices/ProductTrackingAddOnService";
import PowerbiEmbed from '../../../../Components/PowerBI/PowerbiEmbed';

import TicketListPage from '../../../../../TroubleTickets/TicketListPage';
// 
import LoadingButton from '../../../../../../../Components/Buttons/LoadingButton';

import CampaignLaunchTutorialModal from '../../Modals/CampaignLaunchTutorialModal';
import { connect } from "react-redux";
import StartProductManagementModal from '../../Modals/StartProductManagementModal';
// 
// ToDo: revise quick actions -> each product widget is searching for update management function to ensure correct fcn is used in Loki and Odin
// import TicketListPage from
const stripePromise = loadStripe(CURRENT_STRIPE_PK);

function StoreProductsTab(props) {
    const managementStates = {
        INACTIVE: 'unManaged',
        ACTIVE: 'managed',
        PAUSED: 'paused',
    };

    let history = useHistory();

    // Modal Open States
    const [isAddProductOpen, setIsAddProductOpen] = useState(false);
    const [isCampaignOpen, setIsCampaignOpen] = useState(false);
    const [isCampaignTutorialOpen, setIsCampaignTutorialOpen] = useState(false);
    const [summary, setSummary] = useState();
	const [isOpen, setIsOpen] = useState(false);
    const [reportName, setReportName] = useState();
    const [hideCampaignTutorial, setHideCampaignTutorial] = useState(
        JSON.parse(localStorage.getItem('hide-campaign-tutorial')) || false
    );
    const [isManagementOpen, setIsManagementOpen] = useState(false);
    const [isStartManagementOpen, setIsStartManagementOpen] = useState(false);
    const [isTrackingOpen, setIsTrackingOpen] = useState(false);

    const [managedProducts, setManagedProducts] = useState(0);
    const [unManagedProducts, setUnManagedProducts] = useState(0);
    const [pausedProducts, setPausedProducts] = useState(0);
    // const [isTicketListOpen, setIsTicketListOpen] = useState(false);

    // Modal Data States
    const [newCampaignDetails, setNewCampaignDetails] = useState({
        productASIN: '',
        productSKU: '',
        skuList: [],
        dailyBudget: 50,
    });
    const [managementProduct, setManagementProduct] = useState({
        asin: '',
        sku: '',
    });
    const [trackingProduct, setTrackingProduct] = useState({
        asin: '',
        update: '',
    });
    const [newProductASIN, setNewProductASIN] = useState('');
    // const [newProductSKU, setNewProductSKU] = useState('');

    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        search: '',
        management: '',
    });

    const [filteredChildProducts, setFilteredChildProducts] = useState([]);
    const [filteredOrphanProducts, setFilteredOrphanProducts] = useState([]);

    const geProductPerformanceReport = () => {
        
        setSummary(<PowerbiEmbed responseURL = {props.ProductPerformanceReport} profileId={props.storeIntegerID} />);
        setIsOpen(true);
        
    }

    const getProductTargetingReport = () => {
        
        setSummary(<PowerbiEmbed responseURL = {props.ProductTargetingReport} profileId={props.storeIntegerID} />);
        setIsOpen(true);
        
    }
    useEffect(() => {
        localStorage.setItem('hide-campaign-tutorial', JSON.stringify(hideCampaignTutorial));
    }, [hideCampaignTutorial])

    useEffect(() => {
        if (props.childProducts) {
            setFilteredChildProducts(props.childProducts.sort(compareProducts));
        }
        if (props.orphanProducts) {
            setFilteredOrphanProducts(props.orphanProducts.sort(compareProducts));
        }
    }, [props.childProducts, props.orphanProducts])

    useEffect(() => {
        setFilteredChildProducts(filterProducts(props.childProducts));
        setFilteredOrphanProducts(filterProducts(props.orphanProducts));
    }, [filters]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            notify.NotifySuccess("Success! Your purchase will be updated soon.");
        }
        if (query.get("canceled")) {
            notify.NotifyError("Your order has been canceled.");
        }
    }, []);

    const handleProductTrackingCheckout = async (priceID, asin) => {
        const stripe = await stripePromise;
        const session = await ProductTrackingAddOnService(props.token, priceID, asin);
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            notify.NotifyError(result.error.message);
        }
    }

    const quickActions = filterByPermission(
        [
            {
                perform: "project:create",
                yes: {
                    id: "updateListing",
                    name: params => 'Upgrade Product Listing',
                    action: params => {
                        props.dispatch({
                            type: actionType.SET_CURRENT_STORE,
                            payload: {
                                id: props.storeIntegerID,
                            }
                        })
                        history.push(`${urls.CREATE_PROJECTS_URL}/${props.storeIntegerID}`)
                    },
                    show: false,
                }
            },
            {
                perform: 'products:edit',
                yes: {
                    id: "updateManagement",
                    name: ({ managementStatus }) =>
                        managementStatus === managementStates.ACTIVE
                            ? 'Pause Management'
                            : 'Resume Management',
                    action: (params) => {
                        if (params.managementStatus === managementStates.ACTIVE) {
                            toggleManagementModal(
                                params.productASIN,
                                params.productSKU,
                                params.managementStatus
                            );
                        } else {
                            toggleManagementModalNew(
                                params.productASIN,
                                params.productSKU,
                                params.managementStatus
                            )
                                // return props.updateProductManagement(
                                //     params.productASIN,
                                //     'resume',
                                //     "darade"
                                // );
                        
                        }
                    },
                    show: false,
                },
            },
            {
                perform: 'products:tracking',
                yes: {
                    id: "updateTracking",
                    name: ({ areKeywordsTracked }) =>
                        areKeywordsTracked ? 'Pause Tracking' : 'Product Tracking',
                    action: ({ areKeywordsTracked, productASIN }) => {
                        if (!areKeywordsTracked) {
                            toggleTrackingModal(productASIN, 'enable');
                        } else {
                            props.updateProductTracking(productASIN, 'disable');
                        }
                    },
                    show: true,
                },
            },
            {
                perform: "addOns:view",
                yes: {
                    id: "productTrackingAddOn",
                    name: () => "Keyword Tracking",
                    action: ({productASIN}) => {
                        let priceID = addOns.find(addOn => addOn.id === "productTracking");
                        if (priceID) {
                            handleProductTrackingCheckout(priceID, productASIN);
                        }
                    },
                    show: true,
                }
            },
            {
                perform: 'products:keywordExport',
                yes: {
                    id: "keywordExport",
                    name: () => 'Export Keywords',
                    action: ({ productASIN }) => {
                        ExportProductKeywordService(props.token, productASIN).then(keywords => {
                            ExportExcel(keywords, `${productASIN}_keywords`);
                        })
                    },
                    show: true,
                },
            },
            
            {
                perform: 'products:delete',
                yes: {
                    id: "delete",
                    name: () => "Delete Product",
                    action: ({productASIN, storeIntegerID}) => props.removeProduct(productASIN, storeIntegerID),
                    show: true,
                }
            },
            {
                perform: 'products:updateskus',
                yes: {
                    id: "delete",
                    name: () => "Update Skus",
                    action: ({productASIN, storeIntegerID}) => props.updateSkus(productASIN, storeIntegerID),
                    show: true,
                }
            },
            {
                perform: 'home:standard',
                yes: {
                    id: "viewDetails",
                    name: (params) => 'View Product Details',
                    action: (params) => props.handleRedirect(params.productASIN, params.productSKU),
                    show: true,
                },
            },
        ],
        props.accessLevel
    );

    const compareProducts = (product1, product2) => {
        if (product1.productTitle < product2.productTitle) return -1;
        else if (product1.productTitle > product2.productTitle) return 1;
        else return 0;
    };

    // let sortedProducts = props.products.sort(compareProducts);
    // let sortedChildProducts = props.childProducts.sort(compareProducts);
    let groupedProducts = [...props.parentProducts, ...filteredOrphanProducts];
    let sortedParentOrphanProducts = groupedProducts.sort(compareProducts);


    const filterFunctions = {
        search: (product) =>
            product.productTitle
                .toLowerCase()
                .includes(filters.search.toLowerCase()) ||
            product.asin.toLowerCase().includes(filters.search.toLowerCase()),
        // management: (product) => filters.management === 'managed' ? product.isManaged : !product.isManaged,
        management: (product) =>
            product.simpliworksManagementState === filters.management,
    };

    const filterProducts = (products) => {
        if (!products) return [];

        products = products.filter((product) =>
            Object.keys(filters).every((filterType) =>
                filters[filterType] ? filterFunctions[filterType](product) : true
            )
        );
        return products;
    };

    const toggleFilter = (filterType, value) => {
        if (filterType === 'management' || filterType === 'search') {
            setFilters((prevState) => ({
                ...prevState,
                [filterType]: prevState.management === value ? '' : value,
            }));
        } else {
            setFilters((prevState) => ({
                ...prevState,
                [filterType]: !prevState[filterType],
            }));
        }
    };

    const toggleCampaignModal = (asin, skuList) => {
        if (!hideCampaignTutorial) {
            setIsCampaignTutorialOpen(!isCampaignTutorialOpen);
        }

        setIsCampaignOpen(!isCampaignOpen);
        setNewCampaignDetails((prevState) => ({
            productSKU: skuList ? skuList[0] : '',
            productASIN: asin,
            skuList: skuList,
            dailyBudget: prevState.dailyBudget,
        }));
    };

    const toggleManagementModal = (asin, sku, managementStatus) => {
        setIsManagementOpen(!isManagementOpen);
        setManagementProduct({asin, sku, managementStatus});
    };
    const toggleManagementModalNew = (asin, sku, managementStatus) => {
        setIsStartManagementOpen(!isManagementOpen);
        setManagementProduct({asin, sku, managementStatus});
    };

    const toggleTrackingModal = (asin, update) => {
        setIsTrackingOpen(!isTrackingOpen);
        setTrackingProduct({asin, update});
    };

    const renderAddProductModal = () => (
        <Can
            role={props.accessLevel}
            perform="products:add"
            yes={() => (
                <Col xl="3" md="6">
                    <Button
                        className="btn-icon btn-icon-left"
                        color="brand-blue-dark"
                        style={{fontWeight: 400}}
                        onClick={() => setIsAddProductOpen(!isAddProductOpen)}
                    >
                        <i
                            className="lnr-plus-circle btn-icon-wrapper"
                            style={{paddingBottom: '1px'}}
                        >
                            {' '}
                        </i>
                        Add Product
                    </Button>
                    <CustomModal
                        header="Add a product to this store"
                        isOpen={isAddProductOpen}
                        toggle={() => setIsAddProductOpen(!isAddProductOpen)}
                    >
                        <Row>
                            <Col className="mb-3" md="12">
                                <Label for="newProductASIN">Product ASIN</Label>
                                <Input
                                    id="newProductASIN"
                                    placeholder="Enter product ASIN"
                                    value={newProductASIN}
                                    onChange={(e) => setNewProductASIN(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{offset: 4, size: 4}}>
                                <LoadingButton
                                    color="primary"
                                    onClick={async () => {
                                        setLoading(true);
                                        await props.handleAddProduct(newProductASIN);
                                        setLoading(false);
                                    }}
                                    disabled={newProductASIN === ''}
                                    loading={loading}
                                >
                                    Add Product
                                </LoadingButton>
                            </Col>
                        </Row>
                    </CustomModal>
                </Col>
            )}
        />
    );

    const renderProductList = () => (
        <Fragment>
            <Can
                role={props.accessLevel}
                perform={'home:simple'}
                yes={() => {
                    return (
                        <GroupedProductWidgetList
                            accessLevel={props.accessLevel}
                            parentProducts={sortedParentOrphanProducts}
                            childProducts={filteredChildProducts}
                            updateProductManagement={props.updateProductManagement}
                            quickLaunch={toggleCampaignModal}
                            handleQuickActions={quickActions}
                            toggleSelect={props.toggleSelect}
                            selectedProducts={props.selectedProducts}
                            card={true}
                            storeIntegerID={props.storeIntegerID}
                            token={props.token}
                        />
                    )
                    // if (props.linkedToMRP) {
                    //     return (
                    //         <GroupedProductWidgetList
                    //             accessLevel={props.accessLevel}
                    //             parentProducts={props.parentProducts}
                    //             childProducts={filterProducts()}
                    //             updateProductManagement={props.updateProductManagement}
                    //             // quickLaunch={props.quickLaunch}
                    //             quickLaunch={toggleCampaignModal}
                    //             handleQuickActions={quickActions}
                    //             toggleSelect={props.toggleSelect}
                    //             selectedProducts={props.selectedProducts}
                    //             card={true}
                    //             storeIntegerID={props.storeIntegerID}
                    //             token={props.token}
                    //         />
                    //     )
                    // } else {
                    //     return (
                    //         <SelectableProductWidgetList
                    //             accessLevel={props.accessLevel}
                    //             data={filterProducts()}
                    //             updateProductManagement={props.updateProductManagement}
                    //             // quickLaunch={props.quickLaunch}
                    //             quickLaunch={toggleCampaignModal}
                    //             handleQuickActions={quickActions}
                    //             toggleSelect={props.toggleSelect}
                    //             selectedProducts={props.selectedProducts}
                    //             card={true}
                    //             storeIntegerID={props.storeIntegerID}
                    //             token={props.token}
                    //         />
                    //     )
                    // }
                }}
                no={() => (
                    <ProductWidgetList
                        data={filterProducts()}
                        handleRedirect={props.handleRedirect}
                        updateProductManagement={props.updateProductManagement}
                        // quickLaunch={props.quickLaunch}
                        quickLaunch={toggleCampaignModal}
                        loading={props.loading}
                        handleQuickActions={quickActions}
                        card={true}
                    />
                )}
            />
            {
                (!filteredChildProducts.length && !filteredOrphanProducts.length) &&
                <h5>No products found.</h5>

            }
            <QuickLaunchCampaignModal
                isCampaignOpen={isCampaignOpen}
                setIsCampaignOpen={setIsCampaignOpen}
                newCampaignDetails={newCampaignDetails}
                setNewCampaignDetails={setNewCampaignDetails}
                isManagementOpen={isManagementOpen}
                setIsManagementOpen={setIsManagementOpen}
                handleQuickLaunchCampaign={props.handleQuickLaunchCampaign}
            />
            <CampaignLaunchTutorialModal
                isOpen={isCampaignTutorialOpen}
                setIsOpen={setIsCampaignTutorialOpen}
                hideTutorial={hideCampaignTutorial}
                setHideTutorial={setHideCampaignTutorial}
            />
            <ProductManagementModal
                isManagementOpen={isManagementOpen}
                setIsManagementOpen={setIsManagementOpen}
                managementProduct={managementProduct}
                updateProductManagement={props.updateProductManagement}
            />
            <StartProductManagementModal
                isStartManagementOpen={isStartManagementOpen}
                setIsStartManagementOpen={setIsStartManagementOpen}
                managementProduct={managementProduct}
                updateProductManagement={props.updateProductManagement}
            />
            <ProductTrackingModal
                isTrackingOpen={isTrackingOpen}
                setIsTrackingOpen={setIsTrackingOpen}
                trackingProduct={trackingProduct}
                updateProductTracking={props.updateProductTracking}
            />
        </Fragment>
    );
    
    useEffect(() => {
        var managed = 0;
        var unManaged = 0;
        var paused = 0;
        props.childProducts.filter(function(item){
        if (item.simpliworksManagementState === "managed") {
            managed = managed + 1
            
            setManagedProducts(managed)
        } 
        else if (item.simpliworksManagementState === "unManaged") {
            unManaged = unManaged + 1
            setUnManagedProducts(unManaged)
        }
        else if (item.simpliworksManagementState === "paused") {
            paused = paused + 1
            setPausedProducts(paused)
        }
    })
    
    },[]);
    
    // const renderTicketList = () =>{
    //     setIsTicketListOpen(true)
    //   }
    return (
        <Row>
            <Col className="mb-3" md="12">
                
                <Row className="mb-3">
                    <Col  md="7">
                        <Input
                            className="mb-2"
                            value={filters.search}
                            onChange={(e) => toggleFilter('search', e.target.value)}
                            placeholder="Search for product by title or ASIN"
                        />
                    </Col>
                        {renderAddProductModal()}
                    {/* <Col md="2">
                        <Button className="float-right btn_class" onClick={()=>renderTicketList()}>Tickets</Button>
                    </Col> */}
                    
                </Row>
                <h6 className="text-brand-green-dark mb-4">
                    Total Products ({props.childProducts.length})
                </h6>
                {/* Filter by:{' '} */}
                <Row>
                    {/*<Col className="pr-0" xl="1">*/}
                    {/*    Filter by:*/}
                    {/*</Col>*/}
                    <Col xl="11" md="12">
                        <Button
                            outline
                            onClick={() =>
                                toggleFilter('management', managementStates.ACTIVE)
                            }
                            active={filters.management === managementStates.ACTIVE}
                            color="light"
                            className=' mr-2 btn-border'
                        >
                            <span className="mt-1 text-brand-blue-dark">
                                <i
                                    className="pe-7s-check"
                                    style={{
                                        fontSize: '1rem',
                                        color: 'green',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                </i>{' '}
                                Managed by Simpliworks
                            </span>
                            <div className={'count-circle-1 active'} >
                                <div> <span className="count-circle-text">{managedProducts}</span></div>
                            </div>
                        </Button>
                        
                        <Button
                            outline
                            onClick={() =>
                                toggleFilter('management', managementStates.PAUSED)
                            }
                            active={filters.management === managementStates.PAUSED}
                            color="light"
                            className='ml-2 mr-2 btn-border'
                        >
                            <span className=" text-warning">
                                <i
                                    className="pe-7s-attention text-warning"
                                    style={{fontSize: '1rem', fontWeight: 'bold'}}
                                >
                                    {' '}
                                </i>{' '}
                                Paused
                            </span>
                            <div className={'count-circle-2 active'} >
                                <div> <span className="count-circle-text">{pausedProducts}</span></div>
                            </div>
                        </Button>
                        
                        <Button
                            outline
                            onClick={() =>
                                toggleFilter('management', managementStates.INACTIVE)
                            }
                            active={filters.management === managementStates.INACTIVE}
                            color="light"
                            className='ml-2 mr-2 btn-border'
                        >
                            Unmanaged
                            <div className={'count-circle-3 active'} >
                                <div> <span className="count-circle-text">{unManagedProducts}</span></div>
                            </div>
                        </Button>
                    </Col>
                </Row>

            </Col>
            <Col md="12">{renderProductList()}</Col>
        {/* <CustomModal
        header={"Tickets"}
        isOpen={isTicketListOpen}
        toggle={() => setIsTicketListOpen(!isTicketListOpen)}
        size={"lg"}
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsTicketListOpen(!isTicketListOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
         <TicketListPage requestTypeID={2} problemAreaId={1} requestSubTypeID={1} problemAreaPathwayId={1}/>
        </Label> 
        </CustomModal> */}
            <CustomModal
                header={reportName}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
        </Row>
        
    )
}

const mapStateToProps = (state) => {
    return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      storeOverview: state.accountOverview.store.storeOverview,
      childProducts: state.accountOverview.store.childProducts,
};
};
export default connect(mapStateToProps)(StoreProductsTab);