import React, {useState,useEffect} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row, Button} from "reactstrap";
import { connect } from 'react-redux';

import DateRangePicker from "../../../../../Components/Input/DateRangePicker";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import {SellerPartnerDataService } from "../../../../../../Service/OPSServices/SellerDataPartnerServices";
import { AdvertisingDataService } from '../../../../../../Service/OPSServices/AdvertisingDataServices';
import {GetDataForLastOneHourService} from  "../../../../../../Service/OPSServices/GetDataForLastOneHourService"
import {GetDataForLastOneDayService} from  "../../../../../../Service/OPSServices/GetDataForLastOneDayService"
import {GetDataForLastOneMonthService} from  "../../../../../../Service/OPSServices/GetDataForLastOneMonthService"
// import {GetSellerPartnerListService} from "../../../../../../Service/OPSServices/GetSellerPartnerListService"
import {GetAllSellerPartnerListService} from "../../../../../../Service/OPSServices/GetAllSellerPartnerListService"

import {GetSellerPartnerSettingsService} from "../../../../../../Service/OPSServices/GetSellerPartnerSettingsService"

import AdvertisingDataTable from "./Components/AdvertisingDataTable";
import AllReportsListDataTable from "./Components/AllReportsListDataTable";
import StoreProductDataTable from "./Components/StoreProductDataTable";
import { DropdownList } from "react-widgets";
// import SellerPartnerDetailsListTable from './Components/SellerPartnerDetailsListTable';
import PowerbiEmbed from './Components/PowerbiEmbed';
import { SetAutofillASIN } from '../../../../../../Redux/Actions/Actions';


function AdvertisingDataPage(props) {


    const defaultEndDate = new Date();
    const defaultStartDate = new Date(defaultEndDate.getTime() - (7 * 24 * 60 * 60 * 1000));

    const [dateRange, setDateRange] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });

    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState([]);
    const [sellerPartnerId,setSellerPartnerId] = useState('');
    const [summary, setSummary] = useState();
    const[pbiurl,setPbiurl] = useState();
    const[profileUserId,setProfileId] = useState();
    const[weeklyStoreReport,setWeeklyStoreReport] = useState();
    const[profileName,setProfileName] = useState();
    const[allReports,setAllreports] = useState();
    const[productList,setproductList] = useState();
    
    
    console.log(productStatus);

    const fetchProductStatus = () => {
        setLoading(true);

        let isProduct = email !== "";

        let data = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        };

        if (isProduct) {
            data["email"] = email;
        } 

        AdvertisingDataService(props.token, data).then(advertisingDataStatus => {
            if (advertisingDataStatus) {
                setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    useEffect(() => {
        props.dispatch(GetAllSellerPartnerListService(props.token));         

       GetSellerPartnerSettingsService(props.token).then(response => { 
            
           
        console.log('After response Line')
        console.log(response)
        if (response) {
            if(response['POWERBI_OVERALL_SALES_REPORT_URL'] != 'None'){                
                let inputProps={
                    responseURL:response['POWERBI_OVERALL_SALES_REPORT_URL'],
                    profileId:"Title"
                 };


                setSummary(<PowerbiEmbed responseURL = {response['POWERBI_OVERALL_SALES_REPORT_URL']} profileId={sellerPartnerId}/>);
            }else
            {
                setSummary("Sales Report for this environment is under development and will be available soon.");
            }
        }
    } )
        
    }, [])

    const getAdvertisingData = () => {
        setLoading(true);        

        let data = 
        {id : sellerPartnerId}
        
        AdvertisingDataService(props.token, data).then(response => {
            if (response) {

                // console.log("surya");
                // console.log(response);
                setPbiurl(response['pbiurl'])
                setProfileName(response['profileName'])
                setProfileId(response['profileId'])
                setProfileId(response['profileId'])
                setWeeklyStoreReport(response['weeklyStoreReport'])
                setAllreports(response['allReports'])
                 setproductList(response['productList'])
                // alert(profileUserId)
                // alert(pbiurl)
                // console.log('After setting url n profileid')
                // // console.log(response['profileId'])
                // console.log(profileUserId)
                // // console.log(Id)
                
                //setProductStatus(advertisingDataStatus);
            }
        })

        setLoading(false);
    }

    // const getDataForOneDay = () => {
    //     setLoading(true);        

    //     let data = {id : sellerPartnerId.id}
        
    //     GetDataForLastOneDayService(props.token, data).then(sellerPartnerDataStatus => {
    //         if (sellerPartnerDataStatus) {
    //             setProductStatus(sellerPartnerDataStatus);
    //         }
    //     })

    //     setLoading(false);
    // }

    // const getDataForOneMonth = () => {
    //     setLoading(true);        

    //     let data = {sellerPartnerId : sellerPartnerId}
        
    //     GetDataForLastOneMonthService(props.token, data).then(sellerPartnerDataStatus => {
    //         if (sellerPartnerDataStatus) {
    //             setProductStatus(sellerPartnerDataStatus);
    //         }
    //     })

    //     setLoading(false);
    // }

    // const isInputValid = () => {
    //     //if (dateRange.startDate === null) return false;
    //     //if (integerId === "" && asin === "") return false;
    //     //if (integerId !== "" && asin !== "") return false;

    //     return true;
    // }

    return (
        <>
            <Container fluid className="">
            
            

                <Row className="mb-3 margin-top">
                    <Col md="12" lg="6">
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle className="ml-2">Managed Store List </CardTitle>
                                <Row>
                                    <Col md="6" className="mb-2 ml-2"> 
                                    <DropdownList
                                    data={props.sellerPartnerList}
                                    dataKey='id'                    
                                    textField='Name'                   
                                    placeholder="Select"
                                    value={sellerPartnerId}
                                    onChange={(value) => {
                                        setSellerPartnerId(value);
                                     }}
                                />   
                                    </Col> 
                           
                                </Row>                                                    
                                <Col md={"12"} lg={"8"}>

                         &nbsp;
                        <CardTitle>Date Range</CardTitle>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />  
                    </Col>
                                
                                <LoadingButton
                                    className="mt-3 ml-2"
                                    loading={loading}
                                    color="brand-blue-dark"
                                    onClick={getAdvertisingData}
                                    disabled={dateRange.startDate === null}                                    
                                >
                                    Fetch Reports Data
                                </LoadingButton>

                                &nbsp;                               

                                &nbsp;
                                
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>                        
                        {/* <Card>
                            <AdvertisingDataTable data={productStatus} />
                            
                        </Card> */}
                        {/* <Card>
                            <AdvertisingAPIAllReports data={productStatus} />
                            
                        </Card> */}
                        <Card>
                            <StoreProductDataTable startDate={dateRange.startDate} endDate={dateRange.endDate} productList={productList} data={allReports} pbiurl = {pbiurl} profileId = {profileUserId} profileName = {profileName} profileIdNew={sellerPartnerId}/>       
                        </Card>
                        {/* <Card>
                            <AllReportsListDataTable productList={productList} token={props.token} data={allReports} pbiurl = {pbiurl} profileId = {profileUserId} profileName = {profileName} profileIdNew={sellerPartnerId}/>     
                        </Card> */}
                        
                    </Col>
                </Row>
                &nbsp;                               

&nbsp;
&nbsp;
                <Row>
                    <Col>                        
         
                    <Card className="main-card mb-3">
                            <CardBody>
                            {/* <CardTitle>Managed Store List </CardTitle> */}
                                <Row>
                                    <Col md="12" className="mb-2"> 
                                    <AllReportsListDataTable startDate={dateRange.startDate} endDate={dateRange.endDate} productList={productList} token={props.token} data={allReports} weeklyStoreReport={weeklyStoreReport} pbiurl = {pbiurl} profileId = {profileUserId} profileName = {profileName} profileIdNew={sellerPartnerId}/>       
                                    </Col>        
                                </Row>                                                    
  
                            </CardBody>
                        </Card>
                        
                    </Col>
                </Row>
                &nbsp;                               

                {/* <Row >
                    <Col >
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>Report Section</CardTitle>
                                        
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    
                    </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    sellerPartnerList:state.profile.sellerPartnerList,   
})


export default connect(mapStateToProps)(AdvertisingDataPage);