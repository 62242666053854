import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function SearchTermReportUploadService(token, integerID, file) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('integerID', integerID)

    return axios.post(`${CURRENT_API_URL}/campaign/searchTermUpload`, formData, {
        headers: {
            'Authorization': token,
            'Content-Type': 'multipart/form-data',
        },
    }).then(() => notify.NotifySuccess("Search Term Report uploaded successfully!"))
        .catch(() => notify.NotifyError("Error while uploading Search Term Report, please try again."))
}

export default SearchTermReportUploadService;