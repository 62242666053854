import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import React  from 'react';
import  useState  from 'react';



function FetchProductsForStores(token, profileId) {

    
    return axios.post(`${CURRENT_API_URL}/sellerPartner/userDirectoryProducts`, {
        profileId: profileId,       
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        notify.NotifySuccess("Store products are getting fetched.");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem while getting products.");
        return false;
    });
}

export default FetchProductsForStores;