import React from 'react';
import ReactTable from "react-table";


function UserAuthorizationTable(props) {

    const columns = [
        {    
            Header: "User Name",
            accessor: "userName",   
        },
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Authorization",
            accessor: "authorizationSuccessful",
        },
        {
            Header: 'Authorization Time',
            accessor: 'authorizationTime',
        },
        {
            Header: 'Stores',
            accessor: 'stores',
        },
             
    ];


    return (
        <ReactTable
            data={props.data}
            columns={columns}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped"
        />
    );
}


export default UserAuthorizationTable;