import React, { Fragment, useState } from 'react';
import { FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ProductsModal from './ProductsModal';

const ProjectDetails = ({ formik, currentStore, token }) => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const onSelectProduct = (product) => {
        setModal(false)
        formik.resetForm()
        formik.setFieldValue('productName', product.productTitle)
        formik.setFieldError('productName', '')
        if (product.asin) {
            formik.setFieldValue('idType', 'ASIN')
            formik.setFieldValue('productId', product.asin)
        }

        else {
            formik.setFieldValue('idType', '')
            formik.setFieldValue('productId', '')
        }

        if (product.sku) {
            formik.setFieldValue('productsku', product.sku)
            formik.setFieldValue('onAmazon', 'Yes')
        }
        else {
            formik.setFieldValue('productsku', '')
            formik.setFieldValue('onAmazon', '')
        }
    }

    return (
        <Fragment>
            <div className="form-wizard-content project-add">
                <Row>
                    <Col className="" md="12">
                        <h3 className="form-heading pt-3 pb-3 text-center">
                            Project Details
                        </h3>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="5">
                        <FormGroup>
                            <Input type="text" id="productName" placeholder="Product Name" {...formik.getFieldProps('productName')} />
                            {formik.touched.productName && formik.errors.productName ? (
                                <div className="text-danger">{formik.errors.productName}</div>
                            ) : null}
                        </FormGroup>

                        <Row className="mb-3">
                            <Col md="3">
                                <div style={{ marginTop: '3px' }}> On Amazon</div>
                            </Col>
                            <Col md="9">
                                <div onChange={
                                    () => {
                                        formik.setFieldValue('productsku', '')
                                    }
                                }>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="radio" id="onAmazonYes"

                                                {...formik.getFieldProps('onAmazon')} value="Yes"
                                                checked={formik.values.onAmazon === 'Yes'}

                                            /> Yes
                                    </Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="radio" id="onAmazonNo"

                                                {...formik.getFieldProps('onAmazon')} value="No"
                                                checked={formik.values.onAmazon === 'No'}

                                            /> No
                                    </Label>
                                    </FormGroup>
                                    {formik.touched.onAmazon && formik.errors.onAmazon ? (
                                        <div className="text-danger">{formik.errors.onAmazon}</div>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        {formik.values.onAmazon === 'Yes' && <FormGroup>
                            <Input type="text" id="productsku" placeholder="SKU" {...formik.getFieldProps('productsku')} />
                            {formik.touched.productsku && formik.errors.productsku ? (
                                <div className="text-danger">{formik.errors.productsku}</div>
                            ) : null}
                        </FormGroup>}

                        <FormGroup>
                            <Input type="text" id="productId" placeholder="Product Id" {...formik.getFieldProps('productId')} />
                            {formik.touched.productId && formik.errors.productId ? (
                                <div className="text-danger">{formik.errors.productId}</div>
                            ) : null}
                        </FormGroup>
                        <Row className="mb-3">
                            <Col md="3">
                                <div style={{ marginTop: '3px' }}> ID Type</div>
                            </Col>
                            <Col md="9">
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="idTypeUPC" {...formik.getFieldProps('idType')} value="UPC"
                                            checked={formik.values.idType === 'UPC'}
                                        /> UPC
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="idTypeEAN" {...formik.getFieldProps('idType')} value="EAN"
                                            checked={formik.values.idType === 'EAN'}
                                        /> EAN
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="idTypeGTIN" {...formik.getFieldProps('idType')} value="GTIN"
                                            checked={formik.values.idType === 'GTIN'}
                                        /> GTIN
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="idTypeASIN" {...formik.getFieldProps('idType')} value="ASIN"
                                            checked={formik.values.idType === 'ASIN'}
                                        /> ASIN
                                    </Label>
                                </FormGroup>
                                {formik.touched.idType && formik.errors.idType ? (
                                    <div className="text-danger">{formik.errors.idType}</div>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="3">
                                <div style={{ marginTop: '3px' }}> Variation</div>
                            </Col>
                            <Col md="9">
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="vriationYes" {...formik.getFieldProps('variation')} value="Yes"
                                            checked={formik.values.variation === 'Yes'}
                                        /> Yes
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input type="radio" id="vriationNo" {...formik.getFieldProps('variation')} value="No"
                                            checked={formik.values.variation === 'No'}
                                        /> No
                                    </Label>
                                </FormGroup>
                                {formik.touched.variation && formik.errors.variation ? (
                                    <div className="text-danger">{formik.errors.variation}</div>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col md="3">
                                <div style={{ top: '50%' }}>
                                    Services
                                </div>
                            </Col>
                            <Col md="9">
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" {...formik.getFieldProps('serviceSEOCopy')}
                                            checked={formik.values.serviceSEOCopy}
                                        /> SEO Copy
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" {...formik.getFieldProps('serviceGalleryPhotos')}
                                            checked={formik.values.serviceGalleryPhotos}
                                        /> Gallery Photos
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" {...formik.getFieldProps('serviceAPlusContent')}
                                            checked={formik.values.serviceAPlusContent}
                                        /> A+ Content
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>
                    <Col md="5">
                        <Button type="button" className="btn-sm ml-2" onClick={toggle}>Select Product</Button>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={modal} toggle={toggle} className="modal-lg" keyboard={false}  backdrop="static">
                <ModalHeader toggle={toggle}>Select Product</ModalHeader>
                <ModalBody>
                    <ProductsModal storeId={currentStore.integerID} token={token} onSelectProduct={onSelectProduct} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default ProjectDetails;
