import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function UpdateStoreProfile(token, email) {
    notify.NotifySuccess("User store profiles are being updated.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/UpdateStoreProfileV3APIFromUI`, {
        email: email,       
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("All user store profiles have been updated successfully.");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while updating store profiles, please try again.");
        return false;
    });
}

export default UpdateStoreProfile;