//SubmitProject.

import React, { Fragment } from 'react';
import { Button } from 'reactstrap';


const SubmitProject = ({ formik }) => {

    const handleRedirectToCheckout = async (e) => {
        e.preventDefault();
        await formik.handleSubmit();
        //history.push(urls.BASE_PROJECTS_URL);
    }

    const success = () => {
        return (
            <div className="no-results">
                <div className="sa-icon sa-success animate">
                    <span className="sa-line sa-tip animateSuccessTip" />
                    <span className="sa-line sa-long animateSuccessLong" />

                    <div className="sa-placeholder" />
                    <div className="sa-fix" />
                </div>
                <div className="results-subtitle mt-4">All done!</div>
                <div className="results-title">Click below to submit Product.</div>
                <div className="mt-3 mb-3" />
                <div className="text-center">
                    <Button
                        color="success"
                        size="lg"
                        className="btn-shadow btn-wide ml-2"
                        type="submit"
                        onClick={handleRedirectToCheckout}
                    >
                        Submit
                        </Button>

                </div>
            </div>
        );
    }

    const error = () => {
        return (
            <div className="no-results">
                <div className="sa-icon sa-error animateErrorIcon">
                    <span className="sa-x-mark animateXMark">
                        <span className="sa-line sa-left" />
                        <span className="sa-line sa-right" />
                    </span>
                    <div className="sa-placeholder" />
                    <div className="sa-fix" />
                </div>
                <div className="results-subtitle mt-4">Incomplete</div>
                <div className="results-title">Some fields have invalid responses. Please return to previous steps to correct them.</div>
                <div className="mt-3 mb-3" />
            </div>
        );
    }


    return (
        <Fragment>
            <div className="form-wizard-content">
                {formik.isValid ? success() : error()}
            </div>
        </Fragment>
    );
}

export default SubmitProject;