import React, { useState, Fragment } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row ,Container} from "reactstrap";
import { Link ,} from "react-router-dom";
import { connect } from "react-redux";
// import New_LOGO from "./../../../../../../../assets/utils/images/New_LOGO.png"
import New_LOGO from "./../../../../../../../assets/utils/images/New_LOGO.svg"
import SellerRegistrationService from "../../../../../../../Service/EntryServices/SellerRegistrationService";
import urls from "../../../../../../../Utils/URLs";

function SellerRegistrationForm(props) {
    const [state, setState] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        email: props.appSumoEmail ? props.email : '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
    });

    // useEffect(() => {
    //     if (props.isAuth) {
    //         setState({
    //             firstName: props.user.profile.firstName,
    //             lastName: props.user.profile.lastName,
    //             email: props.user.profile.email,
    //             password: '',
    //             confirmPassword: '',
    //             authorizationCode: props.user.token.authorizationCode,
    //             accessToken: props.user.token.accessToken,
    //             refreshToken: props.user.token.refreshToken,
    //             expiresAt: props.user.token.expiresAt,
    //             amznUserId: props.user._profile.id,
    //         })
    //     }
    //
    // }, [props.user]);

    const handleChange = e => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    }

    const isInvalid = () => {
        for (let key in state) {
            if (key == 'companyName') {
                continue;
            }
            if (state[key] === "") {
                return true;
            }
        }
        return (state.password !== state.confirmPassword);
    }

    const handleSubmit = e => {
        e.preventDefault();
        e.currentTarget.disabled = false;
        props.dispatch(SellerRegistrationService({
            company_name: state.companyName,
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
            phoneNumber: state.phoneNumber,
            password: state.password,
        }));
    }
    // If there is an appsumo email do not display the phone number field

    // No appsumo email so display the phone number
    if (props.email) {
        console.log(props.email)
        console.log("Appsumo email")
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="app-logo mx-auto mb-3" />
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div>Welcome,</div>
                            <span>Let's begin creating your new account!</span>
                        </h4>
                    </div>
                    <Fragment>
                        <Row className="divider" />
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="companyName">Company Name</Label>
                                    <Input
                                        id="companyName"
                                        name="companyName"
                                        type="text"
                                        value={state.companyName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        value={state.firstName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        value={state.lastName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={state.email}
                                        onChange={handleChange}
                                        disabled={props.isAuth || props.email}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col md={12}>
                                <FormGroup>
                                    <Label for="phoneNumber">Phone Number</Label>
                                    <Input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="tel"
                                        value={state.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirm Password</Label>
                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        value={state.confirmPassword}
                                        onChange={handleChange}
                                        invalid={state.password !== state.confirmPassword}
                                    />
                                    <FormFeedback>Passwords must match</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Fragment>
                    <Row className="divider" />
                    <h6 className="mb-0">
                        Already have an account?{' '}
                        <Link className="text-primary" to={urls.LOGIN_URL}>Sign in now</Link>
                        {' '} | {' '}
                        <Link className="text-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Recover Password</Link>
                    </h6>
                </div>
                <div className="modal-footer d-block text-center">
                    <Button
                        color="primary"
                        className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"
                        size="lg"
                        onClick={handleSubmit}
                    >
                        Create Account
                    </Button>

                    {/*{props.isAuth &&*/}
                    {/*<Button*/}
                    {/*    color="primary"*/}
                    {/*    className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"*/}
                    {/*    size="lg"*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    disabled={isInvalid()}*/}
                    {/*>*/}
                    {/*    Create Account*/}
                    {/*</Button>*/}
                    {/*}*/}

                    {/*{ !props.isAuth && <p>Make sure you have logged in with Amazon before creating an account.</p>}*/}
                </div>
            </div>
        )
    }

    // appsumo email .. do not display phone number
    else {
        console.log(props.email)
        console.log("No appsumo email")
        return (
            <Container fluid>

            <Row class="justify-content-sm-center">
                <div>
                <img src={New_LOGO} width={230} height={60} className=''/>
                </div>
                <div className="app-progess-bar"/>

                <Col md={12}>
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div>Welcome,</div>
                            <span>Let's begin creating your new account!</span>
                        </h4>
                    </div>
                    <Container >
                        <Col  className='pl-0 pr-0' md={12}>
                            <FormGroup>
                                <Label for="companyName">Company Name</Label>
                                <Input
                                    id="companyName"
                                    name="companyName"
                                    type="text"
                                    value={state.companyName}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Row className='justify-content-sm-between' md={12}>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="firstName">First Name <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        value={state.firstName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="lastName">Last Name <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        value={state.lastName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='justify-content-sm-between' md={12}>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="email">Email <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={state.email}
                                        onChange={handleChange}
                                        disabled={props.isAuth || props.email}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="phoneNumber">Phone Number <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="tel"
                                        value={state.phoneNumber}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='justify-content-sm-between' md={12}>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="password">Password <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={state.password}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirm Password <span style={{color:'red'}}><b>*</b></span></Label>
                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        value={state.confirmPassword}
                                        onChange={handleChange}
                                        invalid={state.password !== state.confirmPassword}
                                    />
                                    <FormFeedback>Passwords must match</FormFeedback>
                                </FormGroup>
                            </Col>

                        </Row>
                        {/* <Row className="divider"/> */}
                        {/* <div className="justify-content-md-center">
                            <h6 className="mb-0">
                                Already have an account?{' '}
                                <Link className="text-primary" to={urls.LOGIN_URL}>Sign in now</Link>
                                {' '} | {' '}
                                <Link className="text-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Recover Password</Link>
                            </h6>
                        </div> */}

                        <div className="modal-footer d-block text-center">
                            <Button
                                color="primary"
                                className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"
                                size="lg"
                                onClick={handleSubmit}
                                disabled={!(state.confirmPassword && state.firstName && state.lastName && state.email && state.phoneNumber && state.password)}
                            >
                                Create Account
                            </Button>
                            <div className="justify-content-md-center">
                            <h6 className="mb-0">
                                Already have an account?{' '}
                                <Link className="text-primary" to={urls.LOGIN_URL}>Log in now</Link>
                                {' '} 
                            </h6>
                        </div>


                        </div>
                        </Container>

                </Col>
            </Row>
            </Container>








            //     <div className="modal-content">
            //         <div className="modal-body">
            //             <div className="app-logo mx-auto mb-3"/>
            // <div className="h5 modal-title text-center">
            //     <h4 className="mt-2">
            //         <div>Welcome,</div>
            //         <span>Let's begin creating your new account!</span>
            //     </h4>
            // </div>
            //             <Fragment>
            //                 <Row className="divider"/>
            //                 <Row form>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="companyName">Company Name</Label>
            //                             <Input
            //                                 id="companyName"
            //                                 name="companyName"
            //                                 type="text"
            //                                 value={state.companyName}
            //                                 onChange={handleChange}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="firstName">First Name</Label>
            //                             <Input
            //                                 id="firstName"
            //                                 name="firstName"
            //                                 type="text"
            //                                 value={state.firstName}
            //                                 onChange={handleChange}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="lastName">Last Name</Label>
            //                             <Input
            //                                 id="lastName"
            //                                 name="lastName"
            //                                 type="text"
            //                                 value={state.lastName}
            //                                 onChange={handleChange}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="email">Email</Label>
            //                             <Input
            //                                 id="email"
            //                                 name="email"
            //                                 type="email"
            //                                 value={state.email}
            //                                 onChange={handleChange}
            //                                 disabled={props.isAuth || props.email}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="phoneNumber">Phone Number</Label>
            //                             <Input
            //                                 id="phoneNumber"
            //                                 name="phoneNumber"
            //                                 type="tel"
            //                                 value={state.phoneNumber}
            //                                 onChange={handleChange}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="password">Password</Label>
            //                             <Input
            //                                 id="password"
            //                                 name="password"
            //                                 type="password"
            //                                 value={state.password}
            //                                 onChange={handleChange}
            //                             />
            //                         </FormGroup>
            //                     </Col>
            //                     <Col md={12}>
            //                         <FormGroup>
            //                             <Label for="confirmPassword">Confirm Password</Label>
            //                             <Input
            //                                 id="confirmPassword"
            //                                 name="confirmPassword"
            //                                 type="password"
            //                                 value={state.confirmPassword}
            //                                 onChange={handleChange}
            //                                 invalid={state.password !== state.confirmPassword}
            //                             />
            //                             <FormFeedback>Passwords must match</FormFeedback>
            //                         </FormGroup>
            //                     </Col>
            //                 </Row>
            //             </Fragment>
            //         <Row className="divider"/>
                    // <h6 className="mb-0">
                    //     Already have an account?{' '}
                    //     <Link className="text-primary" to={urls.LOGIN_URL}>Sign in now</Link>
                    //     {' '} | {' '}
                    //     <Link className="text-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Recover Password</Link>
                    // </h6>
            //     </div>
            //     <div className="modal-footer d-block text-center">
            //         <Button
            //             color="primary"
            //             className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"
            //             size="lg"
            //             onClick={handleSubmit}
            //             disabled={!state.confirmPassword}
            //         >
            //             Create Account
            //         </Button>

            //         {/*{props.isAuth &&*/}
            //         {/*<Button*/}
            //         {/*    color="primary"*/}
            //         {/*    className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"*/}
            //         {/*    size="lg"*/}
            //         {/*    onClick={handleSubmit}*/}
            //         {/*    disabled={isInvalid()}*/}
            //         {/*>*/}
            //         {/*    Create Account*/}
            //         {/*</Button>*/}
            //         {/*}*/}

            //         {/*{ !props.isAuth && <p>Make sure you have logged in with Amazon before creating an account.</p>}*/}
            //     </div>
            // </div>

        )
    }

    // return (
    //     <div className="modal-content">
    //         <div className="modal-body">
    //             <div className="app-logo mx-auto mb-3"/>
    //             <div className="h5 modal-title text-center">
    //                 <h4 className="mt-2">
    //                     <div>Welcome,</div>
    //                     <span>Let's begin creating your new account!</span>
    //                 </h4>
    //             </div>
    //             <Fragment>
    //                 <Row className="divider"/>
    //                 <Row form>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="firstName">First Name</Label>
    //                             <Input
    //                                 id="firstName"
    //                                 name="firstName"
    //                                 type="text"
    //                                 value={state.firstName}
    //                                 onChange={handleChange}
    //                             />
    //                         </FormGroup>
    //                     </Col>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="lastName">Last Name</Label>
    //                             <Input
    //                                 id="lastName"
    //                                 name="lastName"
    //                                 type="text"
    //                                 value={state.lastName}
    //                                 onChange={handleChange}
    //                             />
    //                         </FormGroup>
    //                     </Col>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="email">Email</Label>
    //                             <Input
    //                                 id="email"
    //                                 name="email"
    //                                 type="email"
    //                                 value={state.email}
    //                                 onChange={handleChange}
    //                                 disabled={props.isAuth || props.email}
    //                             />
    //                         </FormGroup>
    //                     </Col>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="phoneNumber">Phone Number</Label>
    //                             <Input
    //                                 id="phoneNumber"
    //                                 name="phoneNumber"
    //                                 type="tel"
    //                                 value={state.phoneNumber}
    //                                 onChange={handleChange}
    //                             />
    //                         </FormGroup>
    //                     </Col>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="password">Password</Label>
    //                             <Input
    //                                 id="password"
    //                                 name="password"
    //                                 type="password"
    //                                 value={state.password}
    //                                 onChange={handleChange}
    //                             />
    //                         </FormGroup>
    //                     </Col>
    //                     <Col md={12}>
    //                         <FormGroup>
    //                             <Label for="confirmPassword">Confirm Password</Label>
    //                             <Input
    //                                 id="confirmPassword"
    //                                 name="confirmPassword"
    //                                 type="password"
    //                                 value={state.confirmPassword}
    //                                 onChange={handleChange}
    //                                 invalid={state.password !== state.confirmPassword}
    //                             />
    //                             <FormFeedback>Passwords must match</FormFeedback>
    //                         </FormGroup>
    //                     </Col>
    //                 </Row>
    //             </Fragment>
    //             <Row className="divider"/>
    //             <h6 className="mb-0">
    //                 Already have an account?{' '}
    //                 <Link className="text-primary" to={urls.LOGIN_URL}>Sign in now</Link>
    //                 {' '} | {' '}
    //                 <Link className="text-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Recover Password</Link>
    //             </h6>
    //         </div>
    //         <div className="modal-footer d-block text-center">
    //             <Button
    //                 color="primary"
    //                 className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"
    //                 size="lg"
    //                 onClick={handleSubmit}
    //                 disabled={isInvalid()}
    //             >
    //                 Create Account
    //             </Button>

    //             {/*{props.isAuth &&*/}
    //             {/*<Button*/}
    //             {/*    color="primary"*/}
    //             {/*    className="btn-wide btn-pill btn-shadow btn-hover-shine mb-2"*/}
    //             {/*    size="lg"*/}
    //             {/*    onClick={handleSubmit}*/}
    //             {/*    disabled={isInvalid()}*/}
    //             {/*>*/}
    //             {/*    Create Account*/}
    //             {/*</Button>*/}
    //             {/*}*/}

    //             {/*{ !props.isAuth && <p>Make sure you have logged in with Amazon before creating an account.</p>}*/}
    //         </div>
    //     </div>
    // )
}

const mapStateToProps = state => {
    return {
        user: state.LWA.user,
        isAuth: state.LWA.isAuth,
        logged: state.loggedUser.logged,
    }
}

export default connect(mapStateToProps)(SellerRegistrationForm);
