import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function SaveReportForStoreService(token,profileId) {
    notify.NotifySuccess("Advertising Reports are getting saved for a selected store.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/SaveReportForStore`, {
        profileId: profileId,      
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Advertising Reports have been saved for a selected store.");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while saving reports, please try again.");
        return false;
    });
}

export default SaveReportForStoreService;