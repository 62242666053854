import React from 'react';
import ReactTable from "react-table";


function ProductsTable(props) {
    const columns = [
        {
            Header: "ASIN",
            accessor: "asin",
        },
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "Link",
            accessor: "link",
            Cell: cellInfo => <a
                className="mt-1"
                href={cellInfo.value}
                target="_blank"
                rel="noopener noreferrer"
            >
                View on Amazon
            </a>
        },
        {
            Header: "Categories",
            accessor: "categories",
        },
        {
            Header: "Rating",
            accessor: "rating",
        },
        {
            Header: "Ratings Total",
            accessor: "ratings_total",
        },
        {
            Header: "Sold by Amazon",
            accessor: "soldbyAmazon",
        },
        {
            Header: "Total Seller Count",
            accessor: "sellerCount",
        },
    ];


    return (
        <ReactTable
            columns={columns}
            data={props.data}
            style={{
                height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            noDataText="No Data found."
        />
    )
}

export default ProductsTable;