import React, { Fragment } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, UncontrolledDropdown } from 'reactstrap';
import Can from "../../../../../Components/Can";


const AiFilesEdit = ({
    aiFiles,
    openComments,
    accessLevel,
    openTextField,
    openFileUpload,
    deleteField,
    approveOrReviseProject,
    isFinalCompleted
}) => {


    const getFilename = (filename) => {
        filename = decodeURIComponent(filename);
        let tempFilenameArr = filename.split('/');
        let tempFilename = tempFilenameArr[tempFilenameArr.length - 1]
        let regex = new RegExp('[^.]+$');
        let extension = tempFilename.match(regex)
        return `${tempFilename.slice(0, extension['index'] - 1)}`;
    }

    const openCommentsModal = (fieldData, index) => {
        openComments(fieldData, index)
    }


    return (

        <>
            <h4>.AI Files</h4>
            <div>
                <Can
                    role={accessLevel}
                    perform={"project:edit"}
                    yes={() => (
                        (aiFiles.length < 10 && !isFinalCompleted) &&
                        <Button type="button" color="primary" className="btn-sm f07" size="sm" onClick={() => {
                            let tempData = {
                                fieldData: null,
                                accept: ".ai",
                                label: "",
                                multiple: true,
                                maxFiles: 10 - aiFiles.length,
                                name: 'aiFiles',
                                maxFileSizeInBytes: 5 * 1024 * 1024,
                            }

                            openFileUpload(tempData, 'AI Files');
                        }}><i className="fas fa-file-upload"></i> Add More AI Files</Button>
                    )}
                />
                {/*{*/}
                {/*    (accessLevel === 0 && aiFiles.length < 10 && !isFinalCompleted) &&*/}
                {/*    <Button type="button" color="primary" className="btn-sm f07" size="sm" onClick={() => {*/}
                {/*        let tempData = {*/}
                {/*            fieldData: null,*/}
                {/*            accept: ".ai",*/}
                {/*            label: "",*/}
                {/*            multiple: true,*/}
                {/*            maxFiles: 10 - aiFiles.length,*/}
                {/*            name: 'aiFiles',*/}
                {/*            maxFileSizeInBytes: 5 * 1024 * 1024,*/}
                {/*        }*/}

                {/*        openFileUpload(tempData, 'AI Files');*/}
                {/*    }}><i className="fas fa-file-upload"></i> Add More AI Files</Button>*/}

                {/*}*/}
            </div>
            <section className="img-preview">
                <section className="img-container">
                    {aiFiles.map((file, i) =>

                        <section className="img-card" key={i}>
                            <div>
                                <a href={file.field_value} target="_blank">
                                    <div className="img" alt={getFilename(file.field_value)} >
                                        <span style={{
                                            fontSize: '14px'
                                        }}>{getFilename(file.field_value)}</span>
                                    </div>
                                </a>
                                {
                                    (!isFinalCompleted && false) &&
                                    <div className="deleteImage " style={{
                                        right: '2px',
                                        top: '7px'
                                    }}>
                                        <span className="d-inline-block">

                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <Can
                                                        role={accessLevel}
                                                        perform={"project:edit"}
                                                        yes={() => (
                                                            (file.status === 'REVISED') &&
                                                            <DropdownItem onClick={() => openCommentsModal(file, i)} title=" Add Comments">
                                                                <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                            </DropdownItem>
                                                        )}
                                                    />
                                                    {/*{*/}
                                                    {/*    (accessLevel === 0 && file.status === 'REVISED') &&*/}
                                                    {/*    <DropdownItem onClick={() => openCommentsModal(file, i)} title=" Add Comments">*/}
                                                    {/*        <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                                    {/*    </DropdownItem>*/}
                                                    {/*}*/}


                                                    <Can
                                                        role={accessLevel}
                                                        perform={"project:review"}
                                                        yes={() => (
                                                            <Fragment>
                                                                {!file.approved
                                                                &&
                                                                <DropdownItem onClick={() => approveOrReviseProject(file, 'APPROVED', undefined)} title="Approve">
                                                                    <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                                </DropdownItem>
                                                                }
                                                                {
                                                                    file.status !== 'REVISED' &&
                                                                    <DropdownItem onClick={() => openComments(file, i, true)} title="Revise">
                                                                        <i className="fas fa-undo-alt mr-2 comment-icon"></i> {' '} Revise

                                                                    </DropdownItem>
                                                                }
                                                            </Fragment>
                                                        )}
                                                    />
                                                    {/*{*/}
                                                    {/*    (accessLevel === 3) &&*/}
                                                    {/*    <Fragment>*/}
                                                    {/*        {!file.approved*/}
                                                    {/*            &&*/}
                                                    {/*            <DropdownItem onClick={() => approveOrReviseProject(file, 'APPROVED', undefined)} title="Approve">*/}
                                                    {/*                <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                                    {/*            </DropdownItem>*/}
                                                    {/*        }*/}
                                                    {/*        {*/}
                                                    {/*            file.status !== 'REVISED' &&*/}
                                                    {/*            <DropdownItem onClick={() => openComments(file, i, true)} title="Revise">*/}
                                                    {/*                <i className="fas fa-undo-alt mr-2 comment-icon"></i> {' '} Revise*/}

                                                    {/*            </DropdownItem>*/}
                                                    {/*        }*/}
                                                    {/*    </Fragment>*/}
                                                    {/*}*/}

                                                    <Can
                                                        role={accessLevel}
                                                        perform={"project:edit"}
                                                        yes={() => (
                                                            !file.approved &&
                                                            <Fragment>
                                                                <DropdownItem onClick={() => {
                                                                    if (window.confirm(`Are you sure do you want to delete this file`))
                                                                        deleteField(file.id)
                                                                }} title="Delete File">
                                                                    <i className="fas fa-trash-alt mr-2" /> {' '} Delete File

                                                                </DropdownItem>
                                                            </Fragment>
                                                        )}
                                                    />

                                                    {/*{(accessLevel === 0 && !file.approved) &&*/}
                                                    {/*    <Fragment>*/}
                                                    {/*        <DropdownItem onClick={() => {*/}
                                                    {/*            if (window.confirm(`Are you sure do you want to delete this file`))*/}
                                                    {/*                deleteField(file.id)*/}
                                                    {/*        }} title="Delete File">*/}
                                                    {/*            <i className="fas fa-trash-alt mr-2" /> {' '} Delete File*/}

                                                    {/*        </DropdownItem>*/}
                                                    {/*    </Fragment>*/}
                                                    {/*}*/}


                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </span>
                                    </div>
                                }
                            </div>
                        </section>
                    )}

                </section>
            </section>

        </>
    )

}





export default AiFilesEdit;