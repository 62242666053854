import React, { Fragment } from 'react';
import { Col } from "reactstrap";
import { connect } from 'react-redux';

import notify from "../../../../../../Notifications/ToastifyActions";
import ContentAgencyRegistrationForm from "./Components/ContentAgencyRegistrationForm";


function ContentAgencyRegistrationPage(props) {

    return (
        <Fragment>
            <div className="h-100 bg-simpli-gradient-inverse">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        {/*<div className="app-logo mx-auto mb-3"/>*/}
                        <div className="modal-dialog w-100">
                            <ContentAgencyRegistrationForm />
                            {/*HELLO*/}
                        </div>
                        <div className="text-center text-white opacity-8 mt-3">
                            Copyright &copy; Simpliworks Incorporated 2022
                        </div>
                    </Col>
                </div>
            </div>
            { (props.status && props.status === 'REGISTRATION_FAILED') &&
            notify.NotifyError(props.error)
            }
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        status: state.loggedUser.status,
        error: state.loggedUser.error,
    }
}

export default connect(mapStateToProps)(ContentAgencyRegistrationPage);