import React, { Fragment } from 'react';
import {
    FormGroup, Label, Input, FormFeedback, Row, Col,
} from 'reactstrap';
import { Field } from "formik";

import products from '../../../../../../../../Utils/Products';
import ProductSelectorModal from "../ProductSelectorModal";


function SelectCampaignStep(props) {

    const currentValue = props.values.advertisingObjective;

    const renderRadioButtons = () => {
        return (
            products.keys.map(advObjKey => (
                <Col md="4" key={advObjKey}>
                    <Label check key={advObjKey}>
                        <div className={`card mb-3 widget-chart widget-chart2 text-left card-btm-border border-${products[advObjKey].color}`}>
                            <FormGroup check>
                                <h3 className="product-long"><b>{products[advObjKey].long}</b></h3>
                                <div className="form-check">
                                    <Label check>
                                        <Input
                                            type="radio"
                                            tag={Field}
                                            name="advertisingObjective"
                                            value={advObjKey}
                                            checked={currentValue === advObjKey}
                                            disabled={!products[advObjKey].active}
                                        />
                                        {products[advObjKey].description}
                                    </Label>
                                    <img src={products[advObjKey].image} width="100%" height="30%"/>
                                </div>
                            </FormGroup>
                        </div>
                    </Label>
                </Col>
            ))
        );
    }

    return (
        <Fragment>
            <div className="form-wizard-content">
                <Row>
                    <Col md="12">
                        <h3 className="form-heading pt-3 pb-3">
                            Product ASIN
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md="9">
                        <FormGroup>
                            <Input
                                type="text"
                                tag={Field}
                                name="productASIN"
                                invalid={props.errors.productASIN && props.touched.productASIN}
                                placeholder="Enter this campaign's product ASIN"
                            />
                            <FormFeedback>{props.errors.productASIN}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col className="pl-0" md="3">
                        <span className="text-secondary text-lg-center mr-3">OR</span>
                        <ProductSelectorModal setValues={props.setValues} values={props.values}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="form-heading pt-3 pb-3">
                            Curated Advertising Plans
                            <p>Select the advertising plan that best fits your budget.</p>
                        </h3>

                        <FormGroup tag="fieldset">
                            <Row>
                                {renderRadioButtons()}
                            </Row>

                            {props.errors.advertisingObjective && props.touched.advertisingObjective &&
                            <p className="invalid-feedback" style={{display: 'block', fontSize: '100%'}}>
                                You must select an advertising style.
                            </p>
                            }
                        </FormGroup>
                    </Col>
                </Row>

            </div>
        </Fragment>
    );
}

export default SelectCampaignStep;
