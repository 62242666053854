import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Container, Input, Label, Row, Table } from "reactstrap";
function CatalogueItemDetails(props) {
  console.log(props);
  return (
    <>
      <Fragment>
        <Container>
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <div>
                <table id="catalogue-item">
                    <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Release Date
                    </td>
                    <td className="font-18">: {props.data.ReleaseDate}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Marketplace Id
                    </td>
                    <td className="font-18 width-350">: {props.data.MarketplaceId}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Publisher
                    </td>
                    <td className="font-18">: {props.data.Publisher}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Manufacturer
                    </td>
                    <td className="font-18 ">: {props.data.Manufacturer}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Studio
                    </td>
                    <td className="font-18">: {props.data.Studio}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Binding
                    </td>
                    <td className="font-18 ">: {props.data.Binding}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Brand
                    </td>
                    <td className="font-18">: {props.data.Brand}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Color
                    </td>
                    <td className="font-18">: {props.data.Color}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Flavor
                    </td>
                    <td className="font-18 ">: {props.data.Flavor}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Label
                    </td>
                    <td className="font-18">: {props.data.Label}</td>
                  </tr>
                  {/* <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Languages
                    </td>
                    <td className="font-18">: {props.data.Languages} </td>
                  </tr> */}
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      List Price
                    </td>
                    <td className="font-18">
                      : {props.data.ListPrice ? props.data.ListPrice.Amount : ''}
                      {props.data.ListPrice ? props.data.ListPrice.CurrencyCode:''}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Model
                    </td>
                    <td className="font-18">: {props.data.Model}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Number Of Items
                    </td>
                    <td className="font-18">: {props.data.NumberOfItems}</td>
                  </tr>
                  
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Package Quantity
                    </td>
                    <td className="font-18">: {props.data.PackageQuantity}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Part Number
                    </td>
                    <td className="font-18">: {props.data.PartNumber}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Product Group
                    </td>
                    <td className="font-18">: {props.data.ProductGroup}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Product Type Name
                    </td>
                    <td className="font-18">: {props.data.ProductTypeName}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">Size</td>
                    <td className="font-18">: {props.data.Size}</td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Item Dimensions
                    </td>
                    <td className="font-18 ">
                    : {props.data.ItemDimensions ? props.data.ItemDimensions.Height? <>{props.data.ItemDimensions.Height.value.toFixed(2)} &#215; {props.data.ItemDimensions.Width?<>{props.data.ItemDimensions.Width.value.toFixed(2)}  &#215; {props.data.ItemDimensions.Length ? <>{props.data.ItemDimensions.Length.value.toFixed(2)} {props.data.ItemDimensions.Height.Units?props.data.ItemDimensions.Height.Units:''}; {props.data.ItemDimensions.Weight?<>{props.data.ItemDimensions.Weight.value.toFixed(2)} {props.data.ItemDimensions.Weight.Units?props.data.ItemDimensions.Weight.Units:''}</>:''}</>:''}</>:""}</> :'': ''}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-18 width-250 font-weight-bold">
                      Package Dimensions
                    </td>
                    <td className="font-18 ">
                    : {props.data.PackageDimensions ? props.data.PackageDimensions.Height? <>{props.data.PackageDimensions.Height.value.toFixed(2)} &#215; {props.data.PackageDimensions.Width?<>{props.data.PackageDimensions.Width.value.toFixed(2)}  &#215; {props.data.PackageDimensions.Length ? <>{props.data.PackageDimensions.Length.value.toFixed(2)} {props.data.PackageDimensions.Height.Units?props.data.PackageDimensions.Height.Units:''}; {props.data.PackageDimensions.Weight?<>{props.data.PackageDimensions.Weight.value.toFixed(2)} {props.data.PackageDimensions.Weight.Units?props.data.PackageDimensions.Weight.Units:''}</>:''}</>:''}</>:""}</> :'': ''}
                    </td>
                  </tr>                  
                  
                  {/* <tr>
                    <td className="font-18 width-250 font-weight-bold">
                    Sales Rankings
                    </td>
                    <td>
                    <p className="font-18">
                    {props.data.SalesRankings ? props.data.SalesRankings.map((ranking)=>(
                        <><span> #</span>{ranking.Rank} <span>in</span> {ranking.ProductCategoryId}, </>
                    )) : ''}
                    </p>
                    
                    </td>
                  </tr> */}
                  
                </table>
                <p className="font-18 mb-0 mt-2"><b>Sales Rankings :</b></p>
                    {props.data.SalesRankings ? props.data.SalesRankings.map((ranking)=>(
                        <p className="font-18 mb-0"><span> #</span>{ranking.Rank} <span>in</span> {ranking.ProductCategoryId}, </p>
                    )) : ''}
                    
              </div>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
}

export default CatalogueItemDetails;
