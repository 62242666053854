import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function uploadAssetsService(token,data) {
    return axios.post(`${CURRENT_API_URL}/assetgallery/uploadAssets`,data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        console.log(res.data)
        notify.NotifySuccess("Asset uploaded successfully.");
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while uploading assets, please try again later.");
        return false;
    });
}

export default uploadAssetsService;