import React, { Fragment, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from '../../../../../../Utils/Constants';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

const AmazonQuillField = ({ fieldData, loading, onSubmit, ...otherProps }) => {

    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (fieldData && fieldData.id > 0) {
            setIsEdit(true);
            setValue(fieldData.field_value)
        }
    }, [fieldData])

    const onChange = (e) => {
        setValue(e)
        if (otherProps.maxLength && e.length > otherProps.maxLength) {
            setError(`Only ${otherProps.maxLength} Characters are allowed`)
        }
        if (e) setError('')
    }

    const validateField = (e) => {
        if (!e) {
            setError(`This field is required`)
        }
    }

    const saveData = (e) => {
        if (error || value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            toast.error('This field is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            return;
        }
        if (!error) {
            let payload = {
                id: 0,
                field_value: value,
                field_name: otherProps.name
            }
            if (isEdit) {
                payload.id = fieldData.id
            }
            onSubmit(payload)
        }
        else {
            toast.error('This field is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }

    }

    const clearData = (e) => {
        setValue('');
        setError('');
    }
    return (
        <Fragment>
            <div className="quill-prod">
                <ReactQuill value={value} onChange={onChange} theme={'snow'}

                    modules={Editor.modules}
                    formats={Editor.formats}
                    bounds={'.quill-prod'}
                    onBlur={(previousRange, source, editor) => {
                        // console.log(previousRange)
                        // console.log(source)
                        // console.log(editor.getText());
                        validateField(editor.getText().trim())
                    }}
                    placeholder='Enter your comments...' />

                {
                    error &&
                    <span className="text-danger">
                        This is field required
                    </span>
                }
            </div>
            <div className="my-2">
                {(value && !loading) ?
                    <Button color="primary" type="button" className="float-right" onClick={saveData}>Submit</Button>
                    :
                    <Button color="primary" type="button" className="float-right" disabled>Submit</Button>
                }


            </div>
        </Fragment>
    )
}

export default AmazonQuillField;
