import React from "react";

import SocialLogin from "../../../../LWA";

class SocialButton extends React.Component {

    render() {
        return (
            <input type="image" id="LoginWithAmazon" border="0" alt="Login with Amazon"
                   src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                   width="156" height="32" onClick={this.props.triggerLogin}  />
        );
    }

}

export default SocialLogin(SocialButton);
