import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStore, faCaretDown, faCaretRight, faBox } from '@fortawesome/free-solid-svg-icons';
import StoreInformationCard from "./StoreInformationCard";
import Group from "./../../../../../../assets/img/StoreOverview_Img/Group.png"

function StoreOverviewSidebar(props) {

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [areStoresOpen, setStoresOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('home');

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleStores = () => {
    setStoresOpen(!areStoresOpen);
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
    <div className="app_">
    <div className="sidebar mt-5 pt-5">
    <StoreInformationCard menuItems={props.menuItems}/>
    <hr/>
    <h6 style={{ color: 'black'}}><b>Store Overview</b></h6>
        <ul style={{ listStyle: 'none',padding:'10px'}}>
          <li
            className={selectedOption === 'home' ? 'selected' : ''}
            onClick={() => handleOptionClick('home')}
          >
            <FontAwesomeIcon icon={faHome} className={selectedOption === 'home' ? 'selected-icon' : 'sidebar-icon'} /> <span className="ml-3">Home</span>
          </li>
          {/* <li
            className={selectedOption === 'stores' ? 'selected' : ''}
            onClick={() => {
              toggleStores();
              handleOptionClick('stores');
            }}
          >
            <FontAwesomeIcon icon={faStore} /> Stores
            <FontAwesomeIcon icon={areStoresOpen ? faCaretDown : faCaretRight} className="collapsible-icon" />
            {areStoresOpen && (
              <ul className={`sub-menu ${areStoresOpen ? 'open' : 'closed'}`}>
                <li>Store 1</li>
                <li>Store 2</li>
              </ul>
            )}
          </li> */}
          <li
            className={selectedOption === 'dothings' ? 'selected ' : 'inline '}
            onClick={() => handleOptionClick('dothings')}
          >
            <FontAwesomeIcon icon={faStore} className={selectedOption === 'dothings' ? 'selected-icon' : 'sidebar-icon'} /> <span className="ml-3"> Do things</span>
          </li>
          <li
            className={selectedOption === 'products' ? 'selected' : ''}
            onClick={() => handleOptionClick('products')}
          >
            <FontAwesomeIcon icon={faStore} className={selectedOption === 'products' ? 'selected-icon' : 'sidebar-icon'} /> <span className="ml-3"> Products</span>
          </li>
          <li
            className={selectedOption === 'reports' ? 'selected' : ''}
            onClick={() => handleOptionClick('reports')}
          >
            {/* <img src={Group} width={50} /> */}
            <FontAwesomeIcon icon={faStore}className={selectedOption === 'reports' ? 'selected-icon' : 'sidebar-icon'} /> <span className="ml-3"> Reports</span>
          </li>
        </ul>
      </div>
      <div className="content mt-4 pt-5">

        {props.children}
      </div>
      </div>
       </>
    );
}

const mapStateToProps = state => ({
    stores: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(StoreOverviewSidebar);
