import React, { Fragment, useState} from 'react';
// import CampaignLaunchProgressBar from "./CampaignLaunchProgressBar";
import ReactTable from "react-table";
import CustomModal from '../../../../../../Components/Modals/CustomModal'
import { DropdownList } from "react-widgets";
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import { CampaignRelaunchStatus } from '../../../../../../../Service/OPSServices/CampaignRelaunchServices';
import {Progress} from "reactstrap";
import { connect } from 'react-redux';
import CampaignLaunchProgressBar from '../../CampaignLaunch/Components/CampaignLaunchProgressBar';
import moment from 'moment';


function CampaignLaunchStatusTable(props) {

    const getCompetitiveLandscapeSteps = (campaignStatus) => {
        const {
            initialKeywordsCollected,
            competitorsCollected,
            titleListGenerated,
            productInfoRetrieved,
            competitorKeywordsCollected,
            masterCorpusGenerated,
        } = campaignStatus;

        return {
            "Product Keywords": initialKeywordsCollected,
            "Competitors": competitorsCollected,
            "Generate Title List": titleListGenerated,
            "Product Listing": productInfoRetrieved,
            "Competitor Keywords": competitorKeywordsCollected,
            "Master Corpus": masterCorpusGenerated,
        }
    }

    const getCampaignGenerationSteps = (campaignStatus) => {
        const {
            marketCampaignGenerated,
            baseCampaignGenerated,
            amazonCampaignGenerated,
            productCampaignGenerated,
        } = campaignStatus;

        return {
            "Market Campaign": marketCampaignGenerated,
            "Base Campaign": baseCampaignGenerated,
            "Amazon Campaign": amazonCampaignGenerated,
            "Product Campaign": productCampaignGenerated,
        }
    }

    const getCampaignDeploymentSteps = (campaignStatus) => {
        const {
            marketCampaignDeployed,
            baseCampaignDeployed,
            amazonCampaignDeployed,
            productCampaignDeployed,
        } = campaignStatus;

        return {
            "Market Campaign": marketCampaignDeployed,
            "Base Campaign": baseCampaignDeployed,
            "Amazon Campaign": amazonCampaignDeployed,
            "Product Campaign": productCampaignDeployed,
        }
    }

    const handleSubmit = () => {

        let data = {
            asin: asin,
            integerId: integerId,
            sku:sku,
            campaignType:launchDropdown,
        };

        CampaignRelaunchStatus(props.token,data);     
        setRelaunchOpen(!relaunchOpen);       
    
    }

    const [summary, setSummary] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [relaunch, setRelaunch] = useState();
    const [relaunchOpen, setRelaunchOpen] = useState(false);
    const [launchDropdown, setLaunchDropdown] = useState('');
    const [integerId, setIntegerId] = useState("");
    const [asin, setAsin] = useState("");
    const [sku, setSku] = useState('');

    let launch = [
        {
            id: "product",
            value: "product",
        },
        {
            id: "market",
            value: "market",
        },
        {
            id: "theme",
            value: "theme",
        },
    ];
    
    const formatDateAndTime = (isoString) => {
        return moment(isoString).format('YYYY-DD-MM hh:mm:ss');
    };
   
    const columns = [
        {
            Header: 'Date',
            accessor: 'startTime',
            Cell: cellInfo => (               
               <p>{formatDateAndTime(cellInfo.original.startTime)}</p>
            
        )
        },
        // {
        //     Header: 'Store',
        //     columns: [
        //         {
        //             Header: 'Name',
        //             accessor: 'store',
        //         },
        //         {
        //             Header: 'ID',
        //             accessor: 'integerId',
        //         },
        //     ],
        // },
        // {
        //     Header: 'Product',
        //     columns: [
        //         {
        //             Header: 'ASIN',
        //             accessor: 'asin',
        //         },
        //         {
        //             Header: 'SKU',
        //             accessor: 'sku',
        //         },
        //     ],
        // },
        {
            Header: 'ASIN',
            accessor: 'asin',
        },
        {
            Header: 'SKU',
            accessor: 'sku',
        },
        {
            Header: 'Budget',
            accessor: 'budget',
            Cell: cellInfo => `$${cellInfo.value}`,
        },
        {
            Header: 'Competitive Landscape',
            Cell: cellInfo => {
                let steps = getCompetitiveLandscapeSteps(cellInfo.original);
                return (
                    <div className="text-center" style={{width: "100%"}}>
                        <CampaignLaunchProgressBar
                            stepNames={Object.keys(steps)}
                            stepValues={Object.values(steps)}
                            id={`competitiveLandscape_${cellInfo.original.campaignCreationId}`}
                            header={"Competitive Landscape"}
                        />
                    </div>
                );
            }
        },
        {
            Header: 'Generate Campaigns',
            Cell: cellInfo => {
                let steps = getCampaignGenerationSteps(cellInfo.original);
                return (
                    <div className="text-center" style={{width: "100%"}}>
                        <CampaignLaunchProgressBar
                            stepNames={Object.keys(steps)}
                            stepValues={Object.values(steps)}
                            id={`campaignGeneration_${cellInfo.original.campaignCreationId}`}
                            header={"Campaign Generation"}
                        />
                    </div>
                );
            }
        },
        {
            Header: 'Deploy Campaigns',
            Cell: cellInfo => {
                let steps = getCampaignDeploymentSteps(cellInfo.original);
                return (
                    <div className="text-center" style={{width: "100%"}}>
                        <CampaignLaunchProgressBar
                            stepNames={Object.keys(steps)}
                            stepValues={Object.values(steps)}
                            id={`campaignDeployment_${cellInfo.original.campaignCreationId}`}
                            header={"Campaign Deployment"}
                        />
                    </div>
                );
            }
        },
        {
            Header: "Logs",
            accessor: "additionalInformation",
            Cell: cellInfo => (               
                    <Button
                        color={"link"}
                        onClick={() => {
                            setSummary(cellInfo.value)    
                            setIsOpen(!isOpen)                       
                        }}
                    >
                        View 
                    </Button>
                
            )
        },
        // {
        //     Header: "Campaign",
           
        //     Cell: props => (
                             
        //             <Button
                        
        //                 color={"link"}
        //                 onClick={() => {
        //                     setRelaunch(props.value);   
        //                     setRelaunchOpen(!isOpen);
        //                     setAsin(props.original.asin);
        //                     setSku(props.original.sku);
        //                     setIntegerId(props.original.integerId);

        //                 }}
        //             >
        //                 Relaunch 
        //             </Button>
                
        //     )
        // },
    ];


    return (
        <Fragment>
        <ReactTable
            columns={columns}
            data={props.data}
            style={{height: "500px"}}
            className="-striped -fixed -highlight"
            noDataText={"No data found."}
        />
        <CustomModal
                header={"Logs"}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                className='curve-popup'
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
            <CustomModal
                header={"Relaunch"}
                isOpen={relaunchOpen}
                toggle={() => setRelaunchOpen(!relaunchOpen)}
                footer={(
                    <>
                       {/* <Button color={"link"} onClick={() => setRelaunchOpen(!relaunchOpen)}> Cancel </Button> */}
                       <Button
                            color="success"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </>
                )}
                className='curve-popup'
            >
                <Label>Relaunch State</Label>
                <Col className="mb-3" md="12">
                        <DropdownList
                            data={launch}
                            dataKey='id'
                            value={launchDropdown}
                            textField="value"
                            onChange={value => setLaunchDropdown(value.value)}
                            placeholder="Launch State"
                        />
                    </Col>                
            </CustomModal>
            </Fragment>
    );
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,

})

export default connect(mapStateToProps)(CampaignLaunchStatusTable)
