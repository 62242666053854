import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function CampaignRelaunchStatus(token, data) {

    // id in campaignDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/opsDash/campaignRelaunch`, data, {
        headers: {
            'Authorization': token,
        
        }
    })
        .then(res => {
            notify.NotifySuccess("Campaign relaunched successfully.")
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while relaunching  campaign , please try again later.");
            return false;
        });
}


export {
    CampaignRelaunchStatus,
}