import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';

function ChangeUserPasswordService(token,data) {

    return axios.post(`${CURRENT_API_URL}/account/changeUserPassword`, data, 
    {
        headers: {
                    'Authorization': token,
            }
    }).then((res) => {
        if (res.data["status"] == true){
            notify.NotifySuccess(res.data['Message']);
        }
        else
            {
                notify.NotifyError(res.data['Message'])
            }
        return (res.data);
    }).catch(() => {
        notify.NotifyError("Error while updating  user password, please try again.");
        return false;
    })
}

export default ChangeUserPasswordService;