import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import {LoadRegistrastionJournyData} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';
import { LoadAdProfiles } from "../../../Redux/Actions/Actions";

function GetUserRegistrationJourneyDataService(token,profileId) {
    return axios.post(`${CURRENT_API_URL}/account/getUserRegistrationJourneyData`, {
        profileId: profileId,      
    },{

        headers: {
            'Authorization': token,
        }
    }).then(res => {
            
            
            LoadAdProfiles(res.data.storesList, 0);
            if(res.data.RegJournneySelectedManagedProducts == false)
            {
                if(res.data.AmazonAdvAuthorized == true){
                    if (res.data.SPAPIAuthorized == true && res.data.RegJournneyStoreSetUpComplete == false) {
                        notify.NotifySuccess("You've successfully completed SPAPI authorization! We are setting up your store in our system. It may take few minutes. Please refresh screen after 15 mins. Thanks for being patient.");
                    }
                    else if(res.data.RegJournneyStoreSetUpComplete == true && res.data.RegJournneySelectedManagedProducts == false)
                    {
                        notify.NotifySuccess("You've successfully completed SPAPI authorization! Please select products for simpliworks management.");
                    }
                }
        }
            return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching Registration journey details., please try again later.");
        return false;
    });
}

export default GetUserRegistrationJourneyDataService;