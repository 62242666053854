import React , { Fragment, useState } from 'react';
import ReactTable from "react-table";
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import {Button,Label} from 'reactstrap';
import { connect } from 'react-redux';
import AddStoreProductService
    from '../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/AddStoreProductService';

function ProductEnrollmentTable(props) {

    const handleSubmit = async (asin,integerId) => {
        
        let response = await AddStoreProductService(
            props.token,
            asin,
            integerId
        );
    }

    const [selectedUser, setSelectedUser] = useState({
        integerId: '',
        asin: '',
    });
    const [isStoresOpen, setIsStoresOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [summary, setSummary] = useState();

    const columns = [
        {
            Header: "Product",
            columns: [
                {
                    Header: "ASIN",
                    accessor: "asin",
                },
            ],
        },
        {
            Header: 'Amazon',
            // accessor: 'asin'
            Cell: props => (
                <div className="text-center" style={{width: "100%"}}>
                    <Button
                        className="ml-auto"
                        color="link"
                        onClick={() => {
                            
                            console.log('before handleSubmit');                        
                            handleSubmit(props.original.asin,props.original.integerId);                                                         
                            console.log('after handleSubmit')
                        }}
                    >Readd</Button>
                    </div>                 
               
            )
        },        
        {
            Header: "Store",
            columns: [
                {
                    Header: "Name",
                    accessor: "store",
                },
                {
                    Header: "ID",
                    accessor: "integerId",
                },
            ],
        },
        
        {
            Header: "Product Added?",
            accessor: "isCompleted",
            Cell: cellInfo => cellInfo.value ? "Yes" : "No",
        },
        {
            Header: "Enrollment ID",
            accessor: "addProductId",
        },
        {
            Header: "Information",
            accessor: "additionalInformation",
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => {
                        setSummary(cellInfo.value)    
                        setIsOpen(!isOpen)                       
                    }}
                >
                    View 
                </Button>
            
        )
        },
    ];


    return (
        <Fragment>        
            <ReactTable
            data={props.data}
            columns={columns}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped"
        />
        <CustomModal
                header={"Product Enrollment Additional Information"}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
        </Fragment>

    );
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps) (ProductEnrollmentTable);