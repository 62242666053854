import React, { Fragment, useState, useRef } from 'react';
import {
    Button, UncontrolledPopover,
    PopoverHeader, PopoverBody, FormGroup, Label, Input
} from 'reactstrap';

const AmazonFileUpload = (props) => {

    const [files, setFiles] = useState([null]);
    const [curImg, setCurImg] = useState('');
    const fileInputField = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [orderPopover, setOrderPopover] = useState(false);
    const [sequence, setSequence] = useState(0);
    const [activeImages, setActiveImages] = useState([]);

    const handleNewFileUpload = (e, i) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            checkImgeDimesions(newFiles[0], i)
        }
    }

    const deleteSelectedImage = () => {

        let updatedFiles = [...files];
        updatedFiles.splice(selectedIndex, 1)
        // updatedFiles[selectedIndex] = null;
        props.formik.setFieldValue('galleryImages', updatedFiles.filter(f => {
            if (f) return true;
            return false;
        }))
        arrangeActiveImg(updatedFiles)
        setFiles([...updatedFiles]);
        setCurImg('')
    }

    const checkImgeDimesions = (file, i) => {
        var reader = new FileReader();

        //Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = function (e) {

            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            image.src = e.target.result;

            //Validate the File Height and Width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                // if (height < 1500 || width < 1500) {
                //     props.fileErrorMsg('Images should have minimum of 1500 X 1500 dimensions')
                //     return false;
                // }

                files[i] = file;
                let regex = new RegExp('[^.]+$');
                let extension = "";
                extension = files[i].name.match(regex)
                if (extension[0].toLowerCase() !== 'png' && extension[0].toLowerCase() !== 'jpg' && extension[0].toLowerCase() !== 'jpeg') {
                    props.fileErrorMsg('Only PNG, JPG and JPEG images are allowed')
                    return false;
                }
                props.formik.setFieldValue('galleryImages', files.filter(f => {
                    if (f) return true;
                    return false;
                }))
                arrangeActiveImg(files)
                if (files.length < 9) {
                    files.push(null)

                }

                setFiles([...files]);
                return true;
            };
        };
    }

    const toggleOrderPopover = () => {
        if (!orderPopover) {
            setSequence(selectedIndex + 1);
        }
        setOrderPopover(!orderPopover)

    }

    const changeGalleryOrdering = () => {
        if (sequence > 0 && sequence <= files.length) {
            let updatedFiles = [...swapElement([...files], selectedIndex, sequence - 1)]
            setFiles(updatedFiles)
            props.formik.setFieldValue('galleryImages', updatedFiles.filter(f => {
                if (f) return true;
                return false;
            }))
            setSequence(0);
            setOrderPopover(false)
            setSelectedIndex(sequence - 1)
            //setCurImg(URL.createObjectURL(files[sequence - 1]));
            return;
        }

        props.fileErrorMsg('Sequence number should be in the range of 1 to 9');
        return
    }

    function swapElement(array, indexA, indexB) {
        // var tmp = array[indexA];
        // array[indexA] = array[indexB];
        // array[indexB] = tmp;

        var element = array[indexA];
        array.splice(indexA, 1);
        array.splice(indexB, 0, element);

        arrangeActiveImg(array)
        return array

    }

    const arrangeActiveImg = (files) => {
        let activeImages__ = []
        files.forEach((f, i) => {

            if (f) activeImages__.push(i)
        })

        setActiveImages([...activeImages__])
    }

    const prevoius = (index) => {
        if (index === 0) {
            for (let i = activeImages.length - 1; i >= 0; i--) {
                if (files[activeImages[i]]) {
                    setSelectedIndex(activeImages[i]);
                    setCurImg(URL.createObjectURL(files[activeImages[i]]));
                    break;
                }
            }
        }
        else {
            for (let i = index - 1; i >= 0; i--) {
                if (files[activeImages[i]]) {
                    setSelectedIndex(activeImages[i]);
                    setCurImg(URL.createObjectURL(files[activeImages[i]]));
                    break;
                }
            }
        }
    }

    const next = (index) => {
        if (index === activeImages.length - 1) {
            for (let i = 0; i < activeImages.length; i++) {
                if (files[activeImages[i]]) {
                    setSelectedIndex(activeImages[i]);
                    setCurImg(URL.createObjectURL(files[activeImages[i]]));
                    break;
                }
            }
        }
        else {
            for (let i = index + 1; i < activeImages.length; i++) {
                if (files[activeImages[i]]) {
                    setSelectedIndex(activeImages[i]);
                    setCurImg(URL.createObjectURL(files[activeImages[i]]));
                    break;
                }
            }
        }
    }

    const previousClick = () => {
        // debugger
        let index = activeImages.findIndex(a => a === selectedIndex)
        prevoius(index);
    }

    const nextClick = () => {
        let index = activeImages.findIndex(a => a === selectedIndex)
        next(index);
    }

    return (
        <Fragment>
            <ul className="thumb">
                {files.map((file, i) => {
                    return (
                        <li key={i} className={(i === selectedIndex ? " border-highlight" : '')}>
                            {file ?

                                <Button color="link" type="button" className="img-button" onMouseOver={() => { setCurImg(URL.createObjectURL(file)); setSelectedIndex(i); }} ><img src={URL.createObjectURL(file)} /></Button>
                                :
                                <section className="upload-button">
                                    <Button type="button" color="link" onClick={() => fileInputField.current.click()}>

                                        <span><i className="fas fa-images t-5 f-23"></i> </span>
                                    </Button>
                                    <input
                                        type="file"
                                        ref={fileInputField}
                                        accept=".jpg,.png,.jpeg"
                                        onChange={(e) => handleNewFileUpload(e, i)}
                                        title=""
                                        value=""
                                    />
                                </section>
                            }
                        </li>
                    )
                })}
            </ul>

            <div className="imgBox">
                {curImg &&
                    <Fragment>
                        <div className="deleteImage">
                            <Button color="link" onClick={deleteSelectedImage}>
                                <i className="fas fa-trash-alt r-4 pos-ab red t-1"></i>
                            </Button>
                        </div>
                        <div className="deleteImage sequence">
                            <Button color="link" id="orderImg" type="button">
                                <i className="fas fa-images r-4 pos-ab green t-1"></i>
                            </Button>
                            <UncontrolledPopover placement="bottom" isOpen={orderPopover} trigger="legacy" target="orderImg" toggle={toggleOrderPopover}>
                                <PopoverHeader>Image Sequencing</PopoverHeader>
                                <PopoverBody>

                                    <FormGroup>
                                        <Label for="imgReordering">Enter Sequence No</Label>
                                        <Input type="number" name="imgReordering" min="0" value={sequence} id="imgReordering" placeholder="Enter Sequence No" onChange={(e) => setSequence(e.target.value)} />
                                    </FormGroup>

                                    <Button type="button" color="primary" className="mt-2" onClick={changeGalleryOrdering}>Submit</Button>


                                </PopoverBody>
                            </UncontrolledPopover>
                        </div>

                        <img src={curImg} className="rounded p-p-10" />
                    </Fragment>}
                {!curImg &&
                    <Fragment>
                        <h2 className="top50 center grey"><i className="fas fa-image"></i> Add Images
                        </h2>
                    </Fragment>}
                {
                    activeImages.length > 0 &&
                    <div className="arrows">
                        <div className="d-flex justify-content-center arrows w96">
                            <Button color="link" type="button" className="mr-4" onClick={previousClick}>
                                <i className="fas fa-arrow-circle-left fa-2x"></i>
                            </Button>
                            <Button color="link" type="button" onClick={nextClick}>
                                <i className="fas fa-arrow-circle-right fa-2x"></i>
                            </Button>
                        </div>
                    </div>
                }

            </div>

        </Fragment>
    )
}


export default AmazonFileUpload;
