import React, { useState } from 'react';

import { Button, Col, Input, Label, ButtonDropdown, ButtonToolbar,DropdownMenu, Row } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import NumericInput from 'react-numeric-input';
import StarRating from '../../../../Components/Rating/StarRating';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';
import LoadingButton from '../../../../../../../Components/Buttons/LoadingButton';
import RefreshSalesDataService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/RefreshSalesDataService';
import Can,{filterByPermission} from '../../../../../../../Components/Can';
import RefreshStoreCampaignService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/RefreshStoreCampaignService';
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import PowerbiEmbed from './PowerbiEmbed';


function StoreOverviewCard(props) {
	const { profile, overviewData } = props;
	const [isSalesRefreshOpen, setIsSalesRefreshOpen] = useState(false);
	const [daysBack, setDaysBack] = useState(60);
	const [isCampaignRefreshOpen, setIsCampaignRefreshOpen] = useState(false);
	const [campaignRefreshType, setCampaignRefreshType] = useState();
	const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState();
    const [summaryLink, setSummaryLink] = useState();
	 const [reportName, setReportName] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenLink, setIsOpenLink] = useState(false);

	const onSalesRefreshSubmit = async () => {
		setLoading(true);

		let response = await RefreshSalesDataService(
			props.token,
			profile.integerID,
			daysBack
		);
		if (response) {
			setIsSalesRefreshOpen(false);
		}

		setLoading(false);
	};
	
	const getProductPerformanceReport = () => {
        var  table = 'FinalReportMerge_ProfileId';
		var column = 'IntegerId';
        setSummary(<PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.ProductPerformanceReport} profileId={profile.integerID} />);
		setReportName("PRODUCT PERFORMANCE REPORT")
		setIsOpen(true);
    }
	const getTargetPerformanceReport = () => {
        var  table = 'Merge1';
		var column = 'AdvTargetingSummary.ProfileId';
        setSummary(<PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.TargetPerformanceReport} profileId={profile.integerID} />);
        setReportName("TARGET PERFORMANCE REPORT")
		setIsOpen(true);
    }
	const getCampaignPerformanceReport = () => {
        var  table = 'AdvCampaignReportsSummary';
		var column = 'IntegerId';
        setSummary(<PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.CampaignPerformanceReport} profileId={profile.integerID} />);
		setReportName("CAMPAIGN PERFORMANCE REPORT")
		setIsOpen(true);
    }
	const getWeeklySalesReport = () => {
        var  table = 'FinalReportMerge_ProfileId';
		var column = 'IntegerId';
        setSummary(<PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.WeeklySalesReport} profileId={profile.integerID} />);
        setReportName("WEEKLY SALES REPORT")
		setIsOpen(true);
    }
	const getWeeklySearchTermReport = () => {
        var  table = 'Merge_newproduct_searchterm';
		var column = 'ProfileId';
        setSummary(<PowerbiEmbed tbl_name={table} col_name={column} responseURL = {props.WeeklySearchTermReport} profileId={profile.integerID} />);
        setReportName("WEEKLY SEARCH TERM REPORT")
		setIsOpen(true);
    }
	const publicLink = (link) => {
        
        setSummaryLink(<iframe src="https://app.powerbi.com/view?r=eyJrIjoiNDYyYWI1YzItMzZhZS00OTQ1LWFhNWUtYTZmYzQxNGE4NmRhIiwidCI6IjlkZjdhMmQxLThlZTItNGFlZi04M2ZlLWM4ZjNhODU5MWZlMiIsImMiOjF9&pageName=ReportSectioner" width="100%" height="500"></iframe>);
        // setSummaryLink(<PowerbiEmbed responseURL = {link} profileId={profile.integerID} />);
        setIsOpenLink(true);
    }

	
     
	const onCampaignRefreshSubmit = async () => {
		setLoading(true);

		let response = await RefreshStoreCampaignService(
			props.token,
			profile.integerID,
			campaignRefreshType.value
		);
		if (response) {
			setIsCampaignRefreshOpen(false);
		}

		setLoading(false);
	};

	if (profile && overviewData) {
		return (
			<>
				<Row>
					<Col md="12" className="mb-2 pr-0">
						<h5 className="text-brand-green">Store Overview</h5>
					</Col>
				</Row>
				<Row className="ml-1 mb-3">
					<Col md="12">
						<h6>Account ID: {profile.integerID}</h6>
						<h6>Location: {profile.location}</h6>
						{profile.ratingTotal < 1 ? (
							<h6>No ratings available</h6>
						) : (
							<>
								<h6>
									Rating:{' '}
									<StarRating
										initialRating={profile.rating}
										readonly
										stop={5}
									/>{' '}
									({profile.rating}) ({profile.ratingTotal}{' '}
									reviews)
								</h6>
								<h6>
									{profile.ratingTotalPercentage}% positive
									reviews in the last 12 months
								</h6>
							</>
						)}
						{overviewData.link && (
							<a
								className="mt-1"
								href={overviewData.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								Visit store on Amazon
							</a>
						)}
						<h6 className="mt-3 text-primary">
							This store currently has{' '}
							<b>{overviewData.managedProducts}</b> product{(overviewData.managedProducts !== 1) && 's'} under management.
						</h6>
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md={{ span: 6 }}>
						<Can
							role={props.accessLevel}
							perform={'stores:refreshData'}
							yes={() => (
								<>
								<Row>
								
								
									{/* <Button
										color={'brand-blue-dark'}
										onClick={() =>
											setIsSalesRefreshOpen(
												!isSalesRefreshOpen
											)
										}
									>
										Refresh Sales Data
									</Button>
									<Button
										className={'ml-2'}
										color={'brand-blue-dark'}
										onClick={() =>
											setIsCampaignRefreshOpen(
												!isCampaignRefreshOpen
											)
										}
									>
										Refresh Store Campaigns
									</Button> */}
									</Row>
									<br/>
									<Row>
									
				  
										<LoadingButton
										color={'brand-blue-dark'}
										className="mt-2 ml-4"
										onClick={() =>
											
											getCampaignPerformanceReport()
																			}
									>
										Campaigns								
										</LoadingButton>
										
										
										&nbsp; 
										
										<LoadingButton
										color={'brand-blue-dark'}
										className="mt-2 ml-2"
										onClick={() =>
											
											getWeeklySearchTermReport()
																			}
									>
										Search Terms								
										</LoadingButton>
									
										&nbsp; 									
										<LoadingButton
										color={'brand-blue-dark'}
										className="mt-2 ml-2"
										
										onClick={() =>
											
											getTargetPerformanceReport()
																			}
									>
										Targeting							
										</LoadingButton>




										</Row>
										<Row>

										
										
										<LoadingButton
										color={'brand-blue-dark'}
										className="mt-2 ml-4"
										onClick={() =>
											
											getWeeklySalesReport()
																			}
									>
										Weekly Sales									
										</LoadingButton>
										<LoadingButton
										color={'brand-blue-dark'}
										className="mt-2 ml-2"
										onClick={getProductPerformanceReport}
											
									>
										Product Performance									
										</LoadingButton>
										

										&nbsp; 
										
										</Row>
										
										{/* <Button
										color={'brand-blue-dark'}
										className={'mt-2 ml-2'}
										onClick={() =>
											// setIsSalesRefreshOpen(!isSalesRefreshOpen)
											publicLink("https://app.powerbi.com/view?r=eyJrIjoiNDYyYWI1YzItMzZhZS00OTQ1LWFhNWUtYTZmYzQxNGE4NmRhIiwidCI6IjlkZjdhMmQxLThlZTItNGFlZi04M2ZlLWM4ZjNhODU5MWZlMiIsImMiOjF9&pageName=ReportSectioner")
																			}
									>
										Public Report Link	
									
										</Button> */}
									<CustomModal
										isOpen={isSalesRefreshOpen}
										toggle={() =>
											setIsSalesRefreshOpen(
												!isSalesRefreshOpen
											)
										}
										header={'Refresh Sales Data'}
										footer={
											<LoadingButton
												loading={loading}
												color={'success'}
												onClick={() =>
													onSalesRefreshSubmit()
												}
											>
												Update
											</LoadingButton>
										}
									>
										<Label>
											Retrieve sales data for the past
										</Label>{' '}
										<NumericInput
											min={0}
											max={60}
											value={daysBack}
											onChange={(valueAsNumber) =>
												setDaysBack(valueAsNumber)
											}
											format={(num) => `${num} days`}
										/>
									</CustomModal>
									<CustomModal
										isOpen={isCampaignRefreshOpen}
										toggle={() =>
											setIsCampaignRefreshOpen(
												!isCampaignRefreshOpen
											)
										}
										header={'Refresh Store Campaigns'}
										footer={
											<LoadingButton
												loading={loading}
												color={'success'}
												disabled={
													campaignRefreshType ===
													undefined
												}
												onClick={() =>
													onCampaignRefreshSubmit()
												}
											>
												Update
											</LoadingButton>
										}
									>
										<DropdownList
											data={[
												{
													id: 'keep',
													value:
														'Keep current campaigns and add new ones',
												},
												{
													id: 'delete',
													value:
														'Delete current campaign data and retrieve new ones from Amazon',
												},
											]}
											dataKey={'id'}
											textField={'value'}
											value={campaignRefreshType}
											placeholder={
												'Select a refresh method'
											}
											onChange={(value) =>
												setCampaignRefreshType(value)
											}
										/>
									</CustomModal>
								</>
							)}
						/>
						{/*{!overviewData.linkedToMRP && (*/}
						{/*	<Button*/}
						{/*		className="ml-2"*/}
						{/*		color="success"*/}
						{/*		onClick={() =>*/}
						{/*			window.open(*/}
						{/*				'https://simpliworks.myrealprofit.com'*/}
						{/*			)*/}
						{/*		}*/}
						{/*	>*/}
						{/*		Authorize MyRealProfit*/}
						{/*	</Button>*/}
						{/*)}*/}
					</Col>
				</Row>
				<CustomModal
                header={reportName}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
			<CustomModal
                header={reportName}
                isOpen={isOpenLink}
                toggle={() => setIsOpenLink(!isOpenLink)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpenLink(!isOpenLink)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summaryLink}</pre> </p></Label>                
            </CustomModal>
			</>
		);
	} else {
		return <h4>No data available.</h4>;
	}
}

export default StoreOverviewCard;
