import React, { Fragment } from 'react';

import StoreWidget from "./StoreWidget";
import WidgetListCard from "../../../../../../../Components/Widgets/WidgetListCard";


function StoreWidgetList(props) {

    // formatting seller profiles data has been delegated to the reducer to ensure format is consistent across widget list and table
    // const widgetsData = props.data.map( item => {
    //     const { integerID, stringID, name, location, status, logo, rating, rating_total, rating_total_percentage } = item;
    //     const details = {
    //         accountID: stringID,
    //         status: status,
    //     }
    //     return {
    //         integerID,
    //         name,
    //         location,
    //         logo,
    //         rating,
    //         ratingTotal: rating_total,
    //         ratingTotalPercentage: rating_total_percentage,
    //         details,
    //     };
    // });

    const renderSellerProfileWidget = ({ integerID, name, location, logo, details, rating, ratingTotal, ratingTotalPercentage }) => {

        return (
            <StoreWidget
                integerID={integerID}
                name={name}
                location={location}
                logo={logo}
                details={details}
                rating={rating}
                ratingTotal={ratingTotal}
                ratingTotalPercentage={ratingTotalPercentage}
                showModal={props.showModal}
            />
        );
    }

    return (
        <Fragment>
            <WidgetListCard
                widgets={props.data}
                renderWidget={renderSellerProfileWidget}
                card={props.card}
            />
        </Fragment>

    );
}

export default StoreWidgetList;
