import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import React  from 'react';
import  useState  from 'react';



function UpdateStoreVisibilityService(token,data) {

    
    return axios.post(`${CURRENT_API_URL}/account/updateStoreVisibility`, data, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        notify.NotifySuccess("Store visibility hab been changed successfully.Please refresh the page to view changes.");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem while updating store visibility status, please try again.");
        return false;
    });
}

export default UpdateStoreVisibilityService;