import React from "react";
import { connect } from 'react-redux';

import Dashboard from "./Scenes/Dashboard/Dashboard";
import RegistrationJourneyDashboard from "./Scenes/Dashboard/RegistrationJourneyDashboard";
import Entry from "./Scenes/Entry/Entry";
import SPAPICards
    from "./Scenes/Dashboard/Scenes/UserProfile/Scenes/Authorization/Components/SPAPICards";


function MasterRoutes(props) {


    if (props.logged) {
        return (
            // <Dashboard />
            <RegistrationJourneyDashboard />
        );
        // if (props.newUser) {
        //     return (
        //         <SPAPICards />
        //     );
        // } else return (
        //     <Dashboard />
        // );
    } else {
        return (
            <Entry />
        );
    }
}


const mapStateToProps = state => {
    return {
        logged: state.loggedUser.logged,
        newUser: state.loggedUser.newUser,
    }
}

export default connect(mapStateToProps)(MasterRoutes);
