import React from 'react';
import { Button } from "reactstrap";

import CustomWidget from "../../../../Components/Widgets/CustomWidget";


function UnclaimedCampaignWidget(props) {

    const { name, campaignID, productSKU, details } = props;

    const importButton = (key) => (
        <Button
            key={key}
            color="primary"
            onClick={() => {
                props.setImportCampaign({productSKU, campaignID});
                props.toggleModal();
            }}
        >
            Import Campaign
        </Button>
    );

    return (
        <CustomWidget
            heading={name}
            collapsible={true}
            details={details}
            autoFormat={true}
            actions={[importButton]}
        />
    );
}


export default UnclaimedCampaignWidget;
