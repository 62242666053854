import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";

function CustomCreditPurchaseService(token, creditPackage, name, email, numberOfCredits) {
    return (
        axios.post(`${CURRENT_API_URL}/payment/buy-credits`, {
            email: email,
            name: name,
            creditPackage: "Custom",
            numberOfCredits: numberOfCredits,

        }, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            return res.data;
        })
    );
}

export default CustomCreditPurchaseService;