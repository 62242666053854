import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetCatalogueItemDetailsService(token, sellerPartnerDetails) {
    console.log(sellerPartnerDetails)
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getSPAPICatelogItemDetailsTask`, sellerPartnerDetails, {
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Update task for SPAPI Catalogue Item Details is getting submitted."))
        .then(res => {
            notify.NotifySuccess("Update task for SPAPI Catalogue Item Details submitted successfully.");
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while submitting Update task for SPAPI Catalogue Item Details, please try again later.");
            return false;
        });
}


export {
    GetCatalogueItemDetailsService,
}

