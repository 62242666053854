import axios from 'axios'
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';
import actionType from "../../../../Redux/Actions/ActionTypes";
import {LoadProductBleederKW, LoadProductIdleKW, LoadProductPerformanceData} from "../../../../Redux/Actions/Actions";


function TimeRangeProductDataService(token, asin, startDate, endDate) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PRODUCT_PERFORMANCE_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/asinPerformance`, {
            asin: asin,
            startDate: startDate,
            endDate: endDate,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                console.log(res.data)
                dispatch(LoadProductPerformanceData(
                    res.data.asinData,
                    res.data.convertingKeywords,
                    res.data.impressionKeywords,
                    res.data.allKeywords,
                    res.data.campaignData,
                ))
            })
            .catch(() => {
                notify.NotifyError("An error occurred while retrieving product performance data. Please try again later.")

                dispatch({ type: actionType.LOAD_PRODUCT_PERFORMANCE_FAILED });
            })
    }
}


function ProductBleederKeywordService(token, asin) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PRODUCT_BLEEDER_KW_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/viewBleederAlert`, {
            asin: asin,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadProductBleederKW(res.data));
            })
            .catch(() => {
                dispatch({ type: actionType.LOAD_PRODUCT_BLEEDER_KW_FAILED });
            })
    }
}


function ProductIdleKeywordService(token, asin) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PRODUCT_IDLE_KW_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/viewForceAlert`, {
            asin: asin,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadProductIdleKW(res.data));
            })
            .catch(() => {
                dispatch({ type: actionType.LOAD_PRODUCT_IDLE_KW_FAILED });
            })
    }
}


export {
    TimeRangeProductDataService,
    ProductBleederKeywordService,
    ProductIdleKeywordService,
}