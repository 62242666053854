import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetMerchantListingsService(token, sellerPartnerDetails) {
    console.log(sellerPartnerDetails)
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getMerchantListings`, sellerPartnerDetails, {
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Task for SPAPI Merchant all listings is getting submitted."))
        .then(res => {
            notify.NotifySuccess("Task for SPAPI Merchant all listings submitted successfully.");
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while submitting Task for SPAPI Merchant all listings, please try again later.");
            return false;
        });
}


export {
    GetMerchantListingsService,
}

