import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function FetchAllCategoryAndSubCategoryService(token) {
    return axios.post(`${CURRENT_API_URL}/assetgallery/fetchAllCategoryAndSubCategory`,'',{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        console.log(res.data)
        // notify.NotifySuccess("Asset note fetched successfully.");
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching category list, please try again later.");
        return false;
    });
}

export default FetchAllCategoryAndSubCategoryService;