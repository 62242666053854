import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import Loader from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';

import StoreOverviewCard from '../Cards/StoreOverviewCard';
import StoreOverviewService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import StoreTabs from '../Nav/StoreTabs';

function StoreModal(props) {
	const { profile } = props;

	useEffect(() => {
		if (props.isOpen) {
			props.dispatch(
				StoreOverviewService(
					props.token,
					profile.integerID,
					profile.details.accountID
				)
			);
		}
	}, [props.isOpen, props.token, profile]);

	const renderStoreDetails = () => (
		<Fragment>
			<Col md="12">
				<StoreOverviewCard
					profile={profile}
					overviewData={props.storeOverview}
					token={props.token}
					accessLevel={props.accessLevel}
				/>
				<div className="divider" />
			</Col>
			<Col md="12">
				<StoreTabs
					accessLevel={props.accessLevel}
					storeIntegerID={profile.integerID}
					storeAccountID={profile ? profile.details.accountID : ''}
				/>
			</Col>
		</Fragment>
	);

	const renderFailureMessage = () => (
		<Col md="12">
			<h5>
				We've encountered a problem while loading your store
				information. Please try again later.
			</h5>
		</Col>
	);

	return (
		<Modal
			isOpen={props.isOpen}
			toggle={props.toggle}
			className={props.className}
			size="xl"
		>
			<ModalHeader toggle={props.toggle}>
				{profile.logo ? (
					<img
						className="mr-2"
						width={100}
						src={profile.logo}
						alt=""
					/>
				) : (
					<i
						className="lnr-store icon-gradient bg-simpli-gradient mr-2"
						style={{ fontSize: '40px' }}
					></i>
				)}
				{profile.name}
			</ModalHeader>
			<ModalBody>
				<LoadingOverlay
					active={props.loading}
					styles={{
						overlay: (base) => ({
							...base,
							background: '#fff',
							opacity: 1,
						}),
						content: (base) => ({
							...base,
							color: '#000',
						}),
					}}
					spinner={<Loader active type="ball-pulse" />}
					text="Loading store details..."
				>
					<Row>
						{props.status === 'success'
							? renderStoreDetails()
							: renderFailureMessage()}
						{/*{renderStoreDetails()}*/}
					</Row>
				</LoadingOverlay>
			</ModalBody>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	token: state.loggedUser.token,
	accessLevel: state.loggedUser.accessLevel,
	storeOverview: state.accountOverview.store.storeOverview,
	sellerFeedback: state.accountOverview.store.sellerFeedback,
	storeProducts: state.accountOverview.store.storeProducts,
	loading: state.accountOverview.store.loading,
	status: state.accountOverview.store.status,
	storeCampaigns: state.accountOverview.store.storeCampaigns,
	unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
	metricData: state.accountOverview.store.metricData,
});

export default connect(mapStateToProps)(StoreModal);
