import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';

function UpdateUserProfileDataService(token,data) {
// console.log(token)
// console.log(data)
    return axios.post(`${CURRENT_API_URL}/account/updateUserProfileData`, data, 
    {
        headers: {
                    'Authorization': token,
            }
    }).then((res) => {
        return (res.data);
    }).catch(() => {
        notify.NotifyError("Error while updating  user social media urls, please try again.");
        return false;
    })
}

export default UpdateUserProfileDataService;