import React, { useEffect, useState } from 'react'
import { Container, Button, Col, Row, Card, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, Input, Label, } from 'reactstrap'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import ReactTable from "react-table";
import CustomModal from '../../../../../../../../Components/Modals/CustomModal';

function CampaignAssetGallery(props) {
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([])
  const [fileUrl, setFileUrl] = useState('')
  const [showFile, setShowFile] = useState(false)


  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);



  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const columns = [
    {
      Header: "Date",
      width: 150,
      accessor: "createdAt",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Assets",
      width: 350,
      accessor: "actualFileName",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0 comment-paragraph">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "View",
      width: 111,
      accessor: "attachmentFile",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100" style={{ cursor: "pointer" }}>
          <p className="m-0 text-center" >
            <FontAwesomeIcon icon={faEye} onClick={() => openFile(cellInfo.value)} />
          </p>
        </div>
      ),
    }
  ]
  const openFile = (url) => {
    // const separatedPath = url.split("/static/");
    // const ext = separatedPath[1].split(".")[1];
    // if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
    //   setFileUrl(url)
    //   setShowFile(true)
    //   setTimeout(() => {
    //     setShowFile(false);
    //   }, 500);
    // } else {
    //   setFileUrl(url)
    //   setShowFile(true)
    // }

  }

  return (
    <>
      {/* <Card className='card-min-height-additional'>
        <Row className="justify-content-between">
          <Col md={4} className='col-containt-fit-additional pl-4 pr-3'>
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}>


              <label htmlFor="fileInput" className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""}`}>
                <h5>
                  <FontAwesomeIcon icon={faCloudUploadAlt} style={{ fontSize: "30px" }} />
                </h5>
                <h5>Drag &amp; Drop Files </h5>
                <h5>OR</h5>
                <input type="file" id="fileInput" name="ticketAttachment" className="custom-brand-file" onChange={handleFileChange} style={{ display: "none" }} />
                <Button onClick={() => document.getElementById("fileInput").click()} style={{ background: "#B2A1FF" }}>
                  Browse Files
                </Button>
              </label>
            </div>
            <p style={{ fontSize: "13px" }}>Attached file List</p>
            <ul style={{ color: "green", fontSize: "13px" }}>
              {selectedFiles.length > 0 ? (
                selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <button className="btn" onClick={() => handleRemoveFile(file)}>
                      <FontAwesomeIcon color="red" size="15px" icon={faWindowClose} />
                    </button>
                  </li>
                ))
              ) : (
                <li>No files attached</li>
              )}
            </ul>
          </Col>
          <Col md={8} className='col-containt-fit-additional  pl-4 pr-4'>
            <ReactTable
              // data={filterAssets}
              columns={columns}
              className="-fixed -highlight -striped"
              getTheadProps={() => {
                return {
                  style: {
                    background: "#B1FA63",
                    overflowY: "hidden",
                    color: "#243837",
                  },
                };
              }}
              defaultPageSize={5}
              noDataText={"No Data Found."}
            />
          </Col>
          <Col md={12} className='text-center mt-3 mb-2'>
            <Button
              className='btn-background-color-Proceed-gallery'>
              save
            </Button>
          </Col>
        </Row>
      </Card> */}
      <CustomModal
        header={"View Image/File"}
        isOpen={showFile}
        toggle={() => setShowFile(!showFile)}
        size={"lg"}
        className='curve-popup '

      >
        <Label>
          <div>
            <iframe
              src={fileUrl ? fileUrl : ''}
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeOverview: state.accountOverview.store.storeOverview,
  };
};

export default connect(mapStateToProps)(CampaignAssetGallery);
