import React from 'react';

import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";
import SelectableCampaignWidget from "./SelectableCampaignWidget";


function SelectableCampaignWidgetList(props) {

    const renderSelectableCampaignWidget = (campaign, key) => (
        <SelectableCampaignWidget
            { ...campaign }
            toggleSelect={props.toggleSelect}
            listItemKey={key}
            isSelected={props.selectedCampaigns.includes(campaign.name)}
        />
    );

    return (
        <WidgetListCard
            className=""
            widgets={props.data}
            renderWidgetListItem={renderSelectableCampaignWidget}
            card={props.card}
        />
    )
}

export default SelectableCampaignWidgetList;