import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

import { PasswordResetService } from '../../../../../Service/EntryServices/AccountRecoveryService';
import notify from '../../../../../Notifications/ToastifyActions';
import New_LOGO from '../../../../../assets/utils/images/New_LOGO.svg'
// import New_LOGO from '../../../../../assets/utils/images/New_LOGO.png'

function PasswordResetForm() {

    const [email, setEmail] = useState('');
    const [isSent, setIsSent] = useState(false);

    const handleSubmit = () => {
        let isComplete = PasswordResetService(email);
        if (isComplete) {
            setEmail('');
            setIsSent(!!isComplete);
        } else {
            notify.NotifyError("We've encountered an issue while completing your request. Please try again later.")
        }
    }

    return (
        <div className="modal-content">
            <div className="modal-body">
                <div className="mx-auto text-center  mb-3">
                     <img src={New_LOGO} style={{height:60, width:230}}/>
                </div>
                <div className="h5  text-center">
                    <h4 className="mt-2">
                        <div className="mb-2" style={{color:'black', fontWeight:'600', fontSize:'24px'}}>Forgot your password? </div>
                        
                        <span>We will send instructions on how to reset your password if there is an account associated with the email you enter below.</span>
                    </h4>
                </div>
                <Form>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="email"> <b>E-mail</b></Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(evt) => setEmail(evt.target.value)}
                                />
                            </FormGroup>
                            <p className="text-success" style={!isSent ? {display: 'none'} : {}}>Successfully sent! Please check your email for instructions to reset your password.</p>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="mb-4 ml-4 mr-4  mt-0 pt-0 clearfix">
                <div className="text-center">
                    <Button
                       className=' w-100 login-btn btn btn-secondary btn-lg'
                        size="lg"
                        onClick={handleSubmit}
                        disabled={email === ''}
                    >
                        Send Email
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PasswordResetForm;
