import React, { Fragment } from 'react';
import { Col, Row, Card, Button, CardTitle, CardText, CardGroup, CardSubtitle, CardBody, CardFooter } from 'reactstrap';

import creditPackages, {odinPackage} from "../../../../../../../Utils/CreditPackages";
import Can from "../../../../../../Components/Can";


function CreditPackageCards({handlePurchase, ...props}) {

    const renderBundleCard = ({bundleType, numCredits, subtitle, description, price}) => (
        <Card style={{height: "100%"}}>
            <CardBody>
                <CardTitle>
                    {bundleType} Bundle {numCredits > 1 ? `(${numCredits} Credits)` : `(${numCredits} Credit)`}
                </CardTitle>
                <CardSubtitle
                    tag="h6"
                    className="text-muted"
                >
                    {subtitle}
                </CardSubtitle>
                <CardText>
                    {description}
                </CardText>
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
                <Button
                    color="success"
                    onClick={() => handlePurchase(bundleType)}
                >
                    Checkout for ${price}
                </Button>
            </CardFooter>
        </Card>
    );

    return (
        <Fragment>
            {
                Object.keys(creditPackages).map(key => (
                    <Col className="mb-3" md="3" style={{minWidth: "300px"}}>
                        {renderBundleCard(creditPackages[key])}
                    </Col>
                ))
            }
            <Can
                role={props.accessLevel}
                perform={"odinPackage:purchase"}
                yes={() => (
                    <Col className="mb-3" md="3" style={{minWidth: "300px"}}>
                        {renderBundleCard(odinPackage)}
                    </Col>
                )}
            />
        </Fragment>
    );
}

export default CreditPackageCards;