import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import React  from 'react';
import  useState  from 'react';



function RequestAmazonStoresService(token, email) {

    
    return axios.post(`${CURRENT_API_URL}/account/manualCheckStores`, {
        email: email,        
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        notify.NotifySuccess("Requested Amazon Stores updated in DB!");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem while getting stores from Amazon.");
        return false;
    });
}

export default RequestAmazonStoresService;