import { CURRENT_ENV } from "./Constants";

const testPriceIDs = {
    unlimitedProducts: "price_1LP9LGGIqnst6pthraVh4C0H",
    productTracking: "price_1LP9NdGIqnst6pthDkem8NJh",
}

const livePriceIDs = {
    unlimitedProducts: "price_1LP9McGIqnst6pthYRY2LsUl",
    productTracking: "price_1LP9NqGIqnst6ptheJOR83Fp",
}

const priceIDs = CURRENT_ENV === 'PRODUCTION' ? livePriceIDs : testPriceIDs;

const addOns = [
    {
        id: "unlimitedProducts",
        title: "Unlimited Product Licenses",
        price: 197,
        priceID: priceIDs.unlimitedProducts,
        description: "Unlimited licenses for product management by Simpliworks.",
    },
    {
        id: "productTracking",
        title: "Single Product Tracking",
        price: 15,
        priceID: priceIDs.productTracking,
        description: 'Keyword tracking for the product of your choice. Just navigate to your store, select the product, and choose "Keyword Tracking" under More Actions!',
    },
];

export default addOns;