import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Card,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';
import GetFileListByIdServices from '../../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetFileListByIdServices';
import CustomModal from '../../../../../../../../Components/Modals/CustomModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
function ViewFileList(props) {
    const [fileList, setFileList] = useState()
    useEffect(() => {
        console.log(props.questionInfo)
        let data = {
            id: props.questionInfo.answerObject[0] ? props.questionInfo.answerObject[0].id : 0
        };
        GetFileListByIdServices(props.token, data).then((response) => {
            if (response) {
                setFileList(response.fileList);
                console.log(response);
            }
        });
    }, [])
    const [fileUrl, setFileUrl] = useState('')
    const [showFile, setShowFile] = useState(false)
    const openFile = (url) => {
        setFileUrl(url)
        setShowFile(true)
    }
    return (
        <>
                    <Card  className='card-of-problemarea' >
                        <Row>
                            <Col md={12} className='ml-5 mt-2 mb-2'>
                                <h6> List Of The File</h6>
                                {fileList ? fileList.map((file) =>
                                (<>
                                    <Link
                                        onClick={() => openFile(file.attachmentFile)}
                                        className
                                    >
                                       <p> {file.fileName}</p>
                                    </Link>
                                </>)
                                ) : ''}

                            </Col>
                        </Row>

                    </Card>
            <CustomModal
                header={"View File"}
                isOpen={showFile}
                toggle={() => setShowFile(!showFile)}
                size={"lg"}
                className='curve-popup '

            >
                <Label>
                    <div>
                        <iframe
                            src={fileUrl ? fileUrl : ''}
                            title="File Display"
                            width="100%"
                            height="500"
                        ></iframe>
                    </div>
                </Label>
            </CustomModal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
    };
};

export default connect(mapStateToProps)(ViewFileList);