import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";

import SellerRegistrationForm from "./Components/SellerRegistrationForm";
import notify from '../../../../../../Notifications/ToastifyActions';


function SellerRegistrationPage(props) {

    const email = props.match ? props.match.params['email'] : undefined;

    return (
        <Fragment>
            <div className="h-100 ">
                    <Col >
                        {/*<div className="app-logo mx-auto mb-3"/>*/}
                        <div >
                            <SellerRegistrationForm email={email} />
                        </div>
                        <div className="text-center ">
                            Copyright &copy; Simpliworks Incorporated 2023
                        </div>
                    </Col>
                </div>
            { (props.status && props.status === 'REGISTRATION_FAILED') &&
                notify.NotifyError(props.error)
            }
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        isAuth: state.LWA.isAuth,
        user: state.LWA.user,
        status: state.loggedUser.status,
        error: state.loggedUser.error,
    }
}

export default connect(mapStateToProps)(SellerRegistrationPage);
