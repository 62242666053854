import React from 'react';
import {connect} from 'react-redux';
import StoreUnitsDataService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreUnitsDataService";
import StoreSalesService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreSalesService";
import TimeFilteredDataDisplay from "./TimeFilteredDataDisplay";
import StoreAdPerformanceService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreAdPerformanceService";


function StorePerformance(props) {

    const { unitsData, salesData, advertisingData } = props;
    const mrpData = { ...unitsData, ...salesData };

    const dataFields = [
        {heading: "Total Sales", value: `$${mrpData.totalSales}`},
        {heading: "Total Units Sold", value: mrpData.totalUnitsSold},
        {heading: "Advertising Sales", value: `$${mrpData.adSales}`},
        {heading: "Organic Sales", value: `$${mrpData.organicSales}`},
        {heading: "Coupon Units Sold", value: mrpData.totalCouponSales},
        {heading: "Full Price Units Sold", value: mrpData.totalFullPriceSales},
        {heading: "Simpliworks Advertising Spend", value: `$${advertisingData.cost}`},
    ];

    const getStoreUnitsData = (daysBack) => {
        props.dispatch(
            StoreUnitsDataService(
                props.token,
                props.integerID,
                daysBack,
            )
        );
    };

    const getStoreSalesData = (daysBack) => {
        props.dispatch(
            StoreSalesService(
                props.token,
                props.integerID,
                daysBack,
            )
        )
    };

    const getStoreAdData = (daysBack) => {
        props.dispatch(
            StoreAdPerformanceService(
                props.token,
                props.integerID,
                daysBack,
            )
        )
    };

    const getStoreData = (daysBack) => {
        getStoreUnitsData(daysBack);
        getStoreSalesData(daysBack);
        getStoreAdData(daysBack);
    }

    return (
        <>
            <h5 className="text-brand-green">Store Performance</h5>
            <TimeFilteredDataDisplay
                modalHeader="Store Performance"
                data={mrpData}
                fetchData={getStoreData}
                dataFields={dataFields}
            />
        </>
    )

}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    unitsData: state.accountOverview.store.unitsData,
    salesData: state.accountOverview.store.salesData,
    advertisingData: state.accountOverview.store.advertisingData,
});

export default connect(mapStateToProps)(StorePerformance);