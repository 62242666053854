import axios from "axios";
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
function GetWorkflowTaskService(token,data) {
    return axios.post(`${CURRENT_API_URL}/workflows/getAllWorkflowsTaskList`,data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            return res.data;
        }).catch(error => {  
            console.log(error)            
            notify.NotifyError("An error occurred while fetching tasklist, please try again later.");
            return false;
        
        });
}

export default GetWorkflowTaskService