import React, {Fragment, useEffect, useState} from "react";
import { Container, Row, Col, Card, CardBody,  CardFooter, CardHeader } from "reactstrap";
import AuthImg from "./img/registration_auth.png";
import LWAButton from "../../../Components/Buttons/LWAButton";
function AdvertisingAuthorization() {
  return (
    <>
      <Container fluid>
        <Row className="justify-content-around ">
          <Col md="6" className=" mt-2">
            <h2 className="conaitent-heading"><b>Grant advertising access:</b></h2>
            <p className="text-font mb-5">
                Authorize our platform to access your accounts for advanced advertising features. 
                Streamline campaign management and gain valuable insights to optimize
                your advertising performance. Enhance your advertising 
                efforts by granting us advertising account access.
            </p>
            <Card>
            <CardHeader style={{justifyContent: "center"}}>Amazon Advertising</CardHeader>
            <CardBody className="text-center">
                Authorize Amazon Advertising to launch and manage your Amazon campaigns.
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
                <LWAButton />
            </CardFooter>
        </Card>
          </Col>
          <Col md="5" className="">
            <img src={AuthImg}></img>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdvertisingAuthorization;
