import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

function ContactInfo(props)
{
    return(
        <>
        contact
        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    stores: state.accountOverview.landing.adProfiles,
})

export default connect(mapStateToProps)(ContactInfo);