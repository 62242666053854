import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function ExportCampaignKeywordService(token, campaignNames) {
     return axios.post(`${CURRENT_API_URL}/campaign/exportCampaign`, {
        campaignNames: campaignNames,
    }, {
         headers: {
             'Authorization': token,
         }
     })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while exporting the campaign. Please try again later.");
            return [];
        })
}

export default ExportCampaignKeywordService;