import React, {Fragment, useState} from 'react';
import {Button, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, UncontrolledDropdown} from 'reactstrap';

import {Document, Page, pdfjs} from 'react-pdf';
import Can from "../../../../../Components/Can";

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const AmazonAPlusContentEdit = ({
                                    linkToAsset,
                                    pdfFile,
                                    openComments,
                                    accessLevel,
                                    openTextField,
                                    openFileUpload,
                                    deleteField,
                                    approveOrReviseProject,
                                    isFinalCompleted
                                }) => {

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({numPages: nextNumPages}) => {
        setNumPages(nextNumPages);
    }

    const getFilename = (filename) => {
        filename = decodeURIComponent(filename);
        let tempFilenameArr = filename.split('/');
        let tempFilename = tempFilenameArr[tempFilenameArr.length - 1]
        let regex = new RegExp('[^.]+$');
        let extension = tempFilename.match(regex)
        return `${tempFilename.slice(0, extension['index'] - 1)}`;
    }

    const openCommentsModal = (fieldData, index) => {
        openComments(fieldData, index)
    }

    const allApproved = () => {
        let status = false;
        // if ((linkToAsset && linkToAsset.field_value) && aiFiles.length > 0)
        //     status = linkToAsset.approved && aiFiles.every(f => f.approved)
        // else if(!linkToAsset && aiFiles.length > 0)
        //   status = aiFiles.every(f => f.approved)
        // else if((linkToAsset && linkToAsset.field_value) && aiFiles.length === 0)
        //   status = linkToAsset.approved

        if (linkToAsset && linkToAsset.field_value && pdfFile)
            status = linkToAsset.approved && pdfFile.approved
        return status
    }

    const revisionNeeded = () => {
        let status = false;
        // if ((linkToAsset && linkToAsset.field_value) && aiFiles.length > 0)
        //     status = linkToAsset.status === 'REVISED' || aiFiles.some(f => f.status === 'REVISED')
        // else if(!linkToAsset && aiFiles.length > 0)
        //   status = aiFiles.some(f => f.status === 'REVISED')
        // else if((linkToAsset && linkToAsset.field_value) && aiFiles.length === 0)
        //   status = linkToAsset.status === 'REVISED'
        if (linkToAsset && linkToAsset.field_value && pdfFile)
            status = linkToAsset.status === 'REVISED' || pdfFile.status === 'REVISED'
        return status
    }

    const getPdfFile = () => {
        return (
            <Fragment>
                {
                    (pdfFile) ?
                        <Col md="12">
                            <div className="pdfViwer">
                                <div className="pdfViwer__container">

                                    <div className="pdfViwer__container__document">

                                        <Fragment>
                                            {
                                                !isFinalCompleted &&
                                                <Fragment>
                                                    <Can
                                                        role={accessLevel}
                                                        perform={"project:edit"}
                                                        yes={() => (
                                                            !pdfFile.approved &&
                                                            <div className="deleteImage t-0">
                                                        <span className="d-inline-block">

                                                            <UncontrolledDropdown>
                                                                <DropdownToggle color="link">
                                                                    <i className="fas fa-ellipsis-v pos-rel b-2"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    {
                                                                        (pdfFile.status === 'REVISED') &&
                                                                        <DropdownItem onClick={() => {
                                                                            openCommentsModal(pdfFile, undefined)
                                                                        }} title=" Add Comments">
                                                                            <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add
                                                                            Comments

                                                                        </DropdownItem>
                                                                    }
                                                                    {(!pdfFile.approved) &&
                                                                    <Fragment>
                                                                        <DropdownItem onClick={() => {
                                                                            let tempData = {
                                                                                fieldData: pdfFile,
                                                                                accept: ".pdf",
                                                                                label: "",
                                                                                multiple: false,
                                                                                name: 'pdfFile',
                                                                                maxFileSizeInBytes: 5 * 1024 * 1024,
                                                                            }

                                                                            openFileUpload(tempData, 'Re - Upload PDF file');
                                                                        }} title="Re Upload File">
                                                                            <i className="fas fa-edit blue mr-2"></i> {' '} Re-Upload

                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={() => {
                                                                            if (window.confirm(`Are you sure do you want to delete this file`))
                                                                                deleteField(pdfFile.id)

                                                                        }} title="Delete File">
                                                                            <i className="fas fa-trash-alt mr-2"/> {' '} Delete
                                                                            File

                                                                        </DropdownItem>
                                                                    </Fragment>
                                                                    }


                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </span>
                                                            </div>
                                                        )}
                                                    />
                                                    {/*{*/}
                                                    {/*    (accessLevel === 0 && !pdfFile.approved) &&*/}

                                                    {/*    <div className="deleteImage t-0">*/}
                                                    {/*        <span className="d-inline-block">*/}

                                                    {/*            <UncontrolledDropdown>*/}
                                                    {/*                <DropdownToggle color="link">*/}
                                                    {/*                    <i className="fas fa-ellipsis-v pos-rel b-2"></i>*/}
                                                    {/*                </DropdownToggle>*/}
                                                    {/*                <DropdownMenu right>*/}
                                                    {/*                    {*/}
                                                    {/*                        (pdfFile.status === 'REVISED') &&*/}
                                                    {/*                        <DropdownItem onClick={() => {*/}
                                                    {/*                            openCommentsModal(pdfFile, undefined)*/}
                                                    {/*                        }} title=" Add Comments">*/}
                                                    {/*                            <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                                    {/*                        </DropdownItem>*/}
                                                    {/*                    }*/}
                                                    {/*                    {(!pdfFile.approved) &&*/}
                                                    {/*                        <Fragment>*/}
                                                    {/*                            <DropdownItem onClick={() => {*/}
                                                    {/*                                let tempData = {*/}
                                                    {/*                                    fieldData: pdfFile,*/}
                                                    {/*                                    accept: ".pdf",*/}
                                                    {/*                                    label: "",*/}
                                                    {/*                                    multiple: false,*/}
                                                    {/*                                    name: 'pdfFile',*/}
                                                    {/*                                    maxFileSizeInBytes: 5 * 1024 * 1024,*/}
                                                    {/*                                }*/}

                                                    {/*                                openFileUpload(tempData, 'Re - Upload PDF file');*/}
                                                    {/*                            }} title="Re Upload File">*/}
                                                    {/*                                <i className="fas fa-edit blue mr-2"></i> {' '} Re-Upload*/}

                                                    {/*                            </DropdownItem>*/}
                                                    {/*                            <DropdownItem onClick={() => {*/}
                                                    {/*                                if (window.confirm(`Are you sure do you want to delete this file`))*/}
                                                    {/*                                    deleteField(pdfFile.id)*/}

                                                    {/*                            }} title="Delete File">*/}
                                                    {/*                                <i className="fas fa-trash-alt mr-2" /> {' '} Delete File*/}

                                                    {/*                            </DropdownItem>*/}
                                                    {/*                        </Fragment>*/}
                                                    {/*                    }*/}


                                                    {/*                </DropdownMenu>*/}
                                                    {/*            </UncontrolledDropdown>*/}
                                                    {/*        </span>*/}
                                                    {/*    </div>*/}

                                                    {/*}*/}
                                                    <Can
                                                        role={accessLevel}
                                                        perform={"project:review"}
                                                        yes={() => (
                                                            <div className="deleteImage t-0">
                                                        <span className="d-inline-block">

                                                            <UncontrolledDropdown>
                                                                <DropdownToggle color="link">
                                                                    <i className="fas fa-ellipsis-v pos-rel b-2"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu right>
                                                                    <Fragment>
                                                                        {!pdfFile.approved
                                                                        &&
                                                                        <DropdownItem
                                                                            onClick={() => approveOrReviseProject(pdfFile, 'APPROVED', undefined)}
                                                                            title="Approve">
                                                                            <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                                        </DropdownItem>
                                                                        }
                                                                        {
                                                                            pdfFile.status !== 'REVISED' &&
                                                                            <DropdownItem
                                                                                onClick={() => openComments(pdfFile, undefined, true)}
                                                                                title="Revise">
                                                                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                                            </DropdownItem>
                                                                        }
                                                                    </Fragment>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        </span>
                                                            </div>
                                                        )}
                                                    />
                                                    {/*{*/}
                                                    {/*    accessLevel === 3 &&*/}
                                                    {/*    <div className="deleteImage t-0">*/}
                                                    {/*        <span className="d-inline-block">*/}

                                                    {/*            <UncontrolledDropdown>*/}
                                                    {/*                <DropdownToggle color="link">*/}
                                                    {/*                    <i className="fas fa-ellipsis-v pos-rel b-2"></i>*/}
                                                    {/*                </DropdownToggle>*/}
                                                    {/*                <DropdownMenu right>*/}
                                                    {/*                    <Fragment>*/}
                                                    {/*                        {!pdfFile.approved*/}
                                                    {/*                            &&*/}
                                                    {/*                            <DropdownItem onClick={() => approveOrReviseProject(pdfFile, 'APPROVED', undefined)} title="Approve">*/}
                                                    {/*                                <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                                    {/*                            </DropdownItem>*/}
                                                    {/*                        }*/}
                                                    {/*                        {*/}
                                                    {/*                            pdfFile.status !== 'REVISED' &&*/}
                                                    {/*                            <DropdownItem onClick={() => openComments(pdfFile, undefined, true)} title="Revise">*/}
                                                    {/*                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                                    {/*                            </DropdownItem>*/}
                                                    {/*                        }*/}
                                                    {/*                    </Fragment>*/}
                                                    {/*                </DropdownMenu>*/}
                                                    {/*            </UncontrolledDropdown>*/}


                                                    {/*        </span>*/}
                                                    {/*    </div>*/}

                                                    {/*}*/}
                                                </Fragment>

                                            }
                                            {pdfFile && pdfFile.approved &&
                                            <div className="deleteImage t-50 z-10">
                                                <Button color="link" type="button">
                                                    <i className="fas fa-check-circle pos-ab t-0 r-3 f-16 text-success"
                                                       title="Approved"/>
                                                </Button>

                                                {/* {(pdfFile && pdfFile.status === 'REVISED') && <Button color="link" type="button">
                                                <i className="fas fa-undo-alt pos-ab t-0 r-3 f-16 text-danger" />
                                            </Button>} */}
                                            </div>
                                            }

                                            {
                                                (pdfFile && pdfFile.status === 'REVISED') &&

                                                <div className="deleteImage t-50 z-10">
                                                    <Button color="link" type="button">
                                                        <i className="fas fa-undo-alt pos-ab t-0 r-3 f-16 text-danger"
                                                           title="Revision Needed"/>
                                                    </Button>
                                                </div>
                                            }
                                            <Document
                                                file={pdfFile.field_value}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                {
                                                    Array.from(
                                                        new Array(numPages),
                                                        (el, index) => (
                                                            <Page
                                                                key={`page_${index + 1}`}
                                                                pageNumber={index + 1}
                                                                scale={1.5}
                                                            />
                                                        ),
                                                    )
                                                }
                                            </Document>
                                        </Fragment>


                                    </div>
                                </div>
                            </div>
                        </Col>
                        :
                        <Col md="12">
                            <div>
                                <Can
                                    role={accessLevel}
                                    perform={"project:edit"}
                                    yes={() => (
                                        !isFinalCompleted &&
                                        <Button type="button" color="primary" size="sm" className="btn-sm f07"
                                                onClick={() => {
                                                    let tempData = {
                                                        fieldData: pdfFile,
                                                        accept: ".pdf",
                                                        label: "",
                                                        multiple: false,
                                                        name: 'pdfFile',
                                                        maxFileSizeInBytes: 5 * 1024 * 1024,
                                                    }

                                                    openFileUpload(tempData, 'Re - Upload PDF file');
                                                }}><i className="fas fa-file-upload"></i> Upload PDF File</Button>
                                    )}
                                />
                                {/*{*/}
                                {/*    (accessLevel === 0 && !isFinalCompleted) &&*/}
                                {/*    <Button type="button" color="primary" size="sm" className="btn-sm f07" onClick={() => {*/}
                                {/*        let tempData = {*/}
                                {/*            fieldData: pdfFile,*/}
                                {/*            accept: ".pdf",*/}
                                {/*            label: "",*/}
                                {/*            multiple: false,*/}
                                {/*            name: 'pdfFile',*/}
                                {/*            maxFileSizeInBytes: 5 * 1024 * 1024,*/}
                                {/*        }*/}

                                {/*        openFileUpload(tempData, 'Re - Upload PDF file');*/}
                                {/*    }}><i className="fas fa-file-upload"></i> Upload PDF File</Button>*/}
                                {/*}*/}
                            </div>
                        </Col>
                }
            </Fragment>
        )
    }


    const getLinkToAssets = () => {

        return (
            <Fragment>
                {linkToAsset ?
                    <Col md="12">
                        <Fragment>
                            {linkToAsset.field_value &&
                            <a href={linkToAsset.field_value} target="_blank">{linkToAsset.field_value} {' '}
                            </a>}
                            {' '}
                            {linkToAsset.approved &&
                            <i className="fas fa-check-circle f-16 text-success" title="Approved"/>}
                            {linkToAsset.status === 'REVISED' &&
                            <i className="fas fa-undo-alt f-16 text-danger" title="Revision Needed"/>}
                        </Fragment>

                        <span className="d-inline-block">
                            {
                                !isFinalCompleted &&
                                <Fragment>
                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            !linkToAsset.approved &&
                                            <UncontrolledDropdown direction="right">
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {
                                                        (linkToAsset.status === 'REVISED') &&
                                                        <DropdownItem
                                                            onClick={() => openCommentsModal(linkToAsset, undefined)}
                                                            title=" Add Comments">
                                                            <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add
                                                            Comments
                                                        </DropdownItem>
                                                    }

                                                    {(!linkToAsset.approved) &&
                                                    <Fragment>
                                                        <DropdownItem onClick={() => {
                                                            let tempData = {
                                                                fieldData: linkToAsset,
                                                                name: 'linkToAsset',
                                                                placeholder: 'Link to google drive or dropbox (Maximum 1000 characters...)'
                                                            }
                                                            openTextField(tempData, 'Edit Link To Assets')
                                                        }} title="Edit Link to asset">
                                                            <i className="fas fa-edit blue mr-2"></i> {' '} Edit Link to
                                                            asset

                                                        </DropdownItem>
                                                    </Fragment>
                                                    }


                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    (accessLevel === 0 && !linkToAsset.approved) &&*/}
                                    {/*    <UncontrolledDropdown direction="right">*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu>*/}
                                    {/*            {*/}
                                    {/*                (linkToAsset.status === 'REVISED') &&*/}
                                    {/*                <DropdownItem*/}
                                    {/*                    onClick={() => openCommentsModal(linkToAsset, undefined)}*/}
                                    {/*                    title=" Add Comments">*/}
                                    {/*                    <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add*/}
                                    {/*                    Comments*/}
                                    {/*                </DropdownItem>*/}
                                    {/*            }*/}

                                    {/*            {(!linkToAsset.approved) &&*/}
                                    {/*            <Fragment>*/}
                                    {/*                <DropdownItem onClick={() => {*/}
                                    {/*                    let tempData = {*/}
                                    {/*                        fieldData: linkToAsset,*/}
                                    {/*                        name: 'linkToAsset',*/}
                                    {/*                        placeholder: 'Link to google drive or dropbox (Maximum 1000 characters...)'*/}
                                    {/*                    }*/}
                                    {/*                    openTextField(tempData, 'Edit Link To Assets')*/}
                                    {/*                }} title="Edit Link to asset">*/}
                                    {/*                    <i className="fas fa-edit blue mr-2"></i> {' '} Edit Link to*/}
                                    {/*                    asset*/}

                                    {/*                </DropdownItem>*/}
                                    {/*            </Fragment>*/}
                                    {/*            }*/}


                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}

                                    <Can
                                        role={accessLevel}
                                        perform={"project:review"}
                                        yes={() => (
                                            <UncontrolledDropdown direction="right">
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <Fragment>
                                                        {!linkToAsset.approved
                                                        &&
                                                        <DropdownItem
                                                            onClick={() => approveOrReviseProject(linkToAsset, 'APPROVED', undefined)}
                                                            title="Approve">
                                                            <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                        </DropdownItem>
                                                        }
                                                        {
                                                            linkToAsset.status !== 'REVISED' &&
                                                            <DropdownItem
                                                                onClick={() => openComments(linkToAsset, undefined, true)}
                                                                title="Revise">
                                                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                            </DropdownItem>
                                                        }
                                                    </Fragment>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    accessLevel === 3 &&*/}
                                    {/*    <UncontrolledDropdown direction="right">*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu>*/}
                                    {/*            <Fragment>*/}
                                    {/*                {!linkToAsset.approved*/}
                                    {/*                &&*/}
                                    {/*                <DropdownItem*/}
                                    {/*                    onClick={() => approveOrReviseProject(linkToAsset, 'APPROVED', undefined)}*/}
                                    {/*                    title="Approve">*/}
                                    {/*                    <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                    {/*                </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*                {*/}
                                    {/*                    linkToAsset.status !== 'REVISED' &&*/}
                                    {/*                    <DropdownItem*/}
                                    {/*                        onClick={() => openComments(linkToAsset, undefined, true)}*/}
                                    {/*                        title="Revise">*/}
                                    {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*            </Fragment>*/}
                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}
                                </Fragment>

                            }

                        </span>
                    </Col>

                    :
                    <span className="m-l-1rem">
                        <Can
                            role={accessLevel}
                            perform={"project:edit"}
                            yes={() => (
                                !isFinalCompleted &&
                                <Button type="button" color="primary" className="btn-sm f07" onClick={() => {
                                    let tempData = {
                                        fieldData: linkToAsset,
                                        name: 'linkToAsset',
                                        placeholder: 'Link to google drive or dropbox (Maximum 1000 characters...)'
                                    }
                                    openTextField(tempData, 'Link To Assets')
                                }}><i className="fas fa-plus-circle"></i> Add Link To Asset</Button>
                            )}
                        />
                        {/*{(accessLevel === 0 && !isFinalCompleted) &&*/}
                        {/*<Button type="button" color="primary" className="btn-sm f07" onClick={() => {*/}
                        {/*    let tempData = {*/}
                        {/*        fieldData: linkToAsset,*/}
                        {/*        name: 'linkToAsset',*/}
                        {/*        placeholder: 'Link to google drive or dropbox (Maximum 1000 characters...)'*/}
                        {/*    }*/}
                        {/*    openTextField(tempData, 'Link To Assets')*/}
                        {/*}}><i className="fas fa-plus-circle"></i> Add Link To Asset</Button>}*/}
                    </span>
                }
            </Fragment>
        )
    }


    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <h4 className="heading">A+ Content {' '}
                        {revisionNeeded() && <span className="badge badge-light-danger capitalize"><i
                            className="fas fa-undo-alt badge-light-danger" title="Revision Needed"></i>  Revision Needed</span>}
                        {allApproved() && <span className="green-badge green-badge-approve"><i
                            className="fas fa-check-circle badge-green-icon"/>  Approved</span>}</h4>
                </Col>
                {
                    getLinkToAssets()

                }
                {
                    getPdfFile()
                }

            </Row>

        </Fragment>
    )
}

export default AmazonAPlusContentEdit;
