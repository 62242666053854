import React, { useState, useEffect } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import './PowerbiEmbed.css';
function PowerbiEmbed(props) {
  const [responseConfig, setResponseConfig] = useState({});
  
  const basicFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: props.tbl_name,
      column: props.col_name
    },
    operator: "In",
    values: [props.profileId],//[props.profileId],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true
  };
  useEffect(() => {
    fetch(props.responseURL,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setResponseConfig(response);
        console.log('Medha')
        console.log(props.responseURL)
        console.log(props.profileId)        
        console.log(response)
      })
      .catch((error) => console.log(error));
  }, []);
  
  return (
    <div className="PowerBI-App">
      <header className="PowerBI-App-header">
             
      <PowerBIEmbed
          embedConfig={{
            //hostname: "https://app.powerbigov.us/",
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: responseConfig.ReportId,
            embedUrl: responseConfig.EmbedUrl,
            accessToken: responseConfig.EmbedToken,
            tokenType: models.TokenType.Embed,
            filters: [basicFilter],
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: {
                  visible: true,
                },
              },
              background: models.BackgroundType.PowerBIEmbed,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
               function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"PowerBI-Embed-container"}
        />
      </header>
    </div>
  );
}
export default PowerbiEmbed;