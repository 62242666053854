import axios from 'axios';
import { CURRENT_API_URL, CUSTOMER_SUPPORT_EMAIL } from "../../../Utils/Constants";


function BuildCampaignService(token, cart) {

    let campaigns = cart.map(campaign => {
        return {
            productASIN: campaign.productASIN,
            dailyBudget: campaign.dailyBudget,
            bidPriceExact: campaign.bidPriceExact,
            bidPricePhrase: campaign.bidPricePhrase,
            productTargetingEnabled: campaign.productTargetingEnabled,
            bidPriceTargeting: campaign.bidPriceTargeting,
            integerID: campaign.sellerProfileId,
            productSKU: campaign.productSKU,
            country: campaign.country,
        };
    });

    return (
        axios.post(`${CURRENT_API_URL}/campaign`, {
            campaigns,
        }, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
                if (res.status === 201) {   //created
                    return {
                        error: false,
                        message: "Your campaigns were successfully created! Campaigns can take up to 10 minutes to appear in the Launch Pad. Click below to view them.",
                    }
                }
            }).catch(error => {
                if (error.response) {
                     if (error.response.status === 402) {
                        return {
                            error: true,
                            message: `It seems that you do not have enough credits for launching your campaign(s).`,
                        }
                    }
                }
                return {
                    error: true,
                    message: `We've encountered an error when trying to build your campaigns. Please contact support at ${CUSTOMER_SUPPORT_EMAIL}`,
                }
            })
    );
}

export default BuildCampaignService;
