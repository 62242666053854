import React from 'react';
import { Route, Switch } from 'react-router-dom';

import urls from '../../../../Utils/URLs';
import SellerRegistrationPage from "./Scenes/SellerRegistration/SellerRegistrationPage";
import ContentAgencyRegistrationPage from "./Scenes/ContentAgencyRegistration/ContentAgencyRegistrationPage";


function RegistrationPage(props) {
    return (
        <Switch>
            <Route
                exact
                path={`${urls.REGISTER_SELLER_URL}/appSumo+email=:appSumoEmail?`}
                component={SellerRegistrationPage}
            />
            <Route exact path={urls.REGISTER_SELLER_URL}>
                <SellerRegistrationPage />
            </Route>

            <Route exact path={urls.REGISTER_CONTENT_AGENCY_URL}>
                <ContentAgencyRegistrationPage />
            </Route>
        </Switch>
    )
}

export default RegistrationPage;
