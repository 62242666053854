import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';

function UpdateProductStageService(token,  asin, stage) {
    return axios.post(`${CURRENT_API_URL}/campaign/setProductStage`, {
        
        asin: asin,
        stage: stage,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            notify.NotifySuccess("Your product's stage was updated successfully!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while updating your product's stage. Please try again later.");
            return false;
        })
}

export default UpdateProductStageService;