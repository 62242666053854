import React,  { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Container, Input } from "reactstrap";

function ColorPalate(props) {
  const [inputValuesPrimery1, setInputValuesPrimery1] = useState("#FE7733");
  const [inputValuesPrimery2, setInputValuesPrimery2] = useState('#B2A1FF');
  const [inputValuesPrimery3, setInputValuesPrimery3] = useState('#CCC9DC');

  const [inputValuesSecondary1, setInputValuesSecondary1] = useState('#141115');
  const [inputValuesSecondary2, setInputValuesSecondary2] = useState('#F8FFF0');
  const [inputValuesSecondary3, setInputValuesSecondary3] = useState('#5D576D');

  const [inputValuesTertiary1, setInputValuesTertiary1] = useState('#D0EFB1');
  const [inputValuesTertiary2, setInputValuesTertiary2] = useState('#FFDDCC');
  const [inputValuesTertiary3, setInputValuesTertiary3] = useState('#E8E2FF');

  const [activeSlide, setActiveSlide] = useState(11);
  // const data = {
  //   primery: [inputValuesPrimery1 == "#FE7733" , inputValuesPrimery2 == '#B2A1FF', inputValuesPrimery3 == '#CCC9DC' ],
  //   secondary: [inputValuesSecondary1 == '#141115', inputValuesSecondary2 == '#F8FFF0', inputValuesSecondary3 == '#5D576D'],
  //   tertiary: [inputValuesTertiary1 == '#D0EFB1', inputValuesTertiary2 == '#FFDDCC', inputValuesTertiary3 == '#E8E2FF']
  // };
  const data = {
    primery: [inputValuesPrimery1 != "#FE7733" ? inputValuesPrimery1 :"#FE7733", inputValuesPrimery2 != '#B2A1FF'?inputValuesPrimery2 :'#B2A1FF', inputValuesPrimery3 != '#CCC9DC' ? inputValuesPrimery3:'#CCC9DC'],
    secondary: [inputValuesSecondary1 != '#141115'?inputValuesSecondary1 :'#141115', inputValuesSecondary2 != '#F8FFF0'?inputValuesSecondary2 :'#F8FFF0', inputValuesSecondary3 != '#5D576D'? inputValuesSecondary3:'#5D576D'],
    tertiary: [inputValuesTertiary1 != '#D0EFB1'?inputValuesTertiary1 :'#D0EFB1', inputValuesTertiary2 != '#FFDDCC'?inputValuesTertiary2 :'#FFDDCC', inputValuesTertiary3 != '#E8E2FF'? inputValuesTertiary3:'#E8E2FF']
  }  
  useEffect(()=>{
    if(props.questionInfo)
    {
      if(props.questionInfo.answerObject[0])
      {
        // console.log(JSON.parse(props.questionInfo.answerObject[0].answerText))
        const colors = JSON.parse(props.questionInfo.answerObject[0].answerText.replace(/'/g, '"'));
        setInputValuesPrimery1(colors.primery[0]);
        setInputValuesPrimery2(colors.primery[1]);
        setInputValuesPrimery3(colors.primery[2]);
        setInputValuesSecondary1(colors.secondary[0]);
        setInputValuesSecondary2(colors.secondary[1]);
        setInputValuesSecondary3(colors.secondary[2]);
        setInputValuesTertiary1(colors.tertiary[0]);
        setInputValuesTertiary2(colors.tertiary[1]);
        setInputValuesTertiary3(colors.tertiary[2]);

      }
    }
  },[])
  let data1 = {
                'primery': ['#ff5500', '#B2A1FF', '#CCC9DC'], 
                'secondary': ['#141115', '#88ff00', '#5D576D'], 
                'tertiary': ['#D0EFB1', '#ffad85', '#E8E2FF']
              }
  const handleSlideClick = (index) => {
    setActiveSlide(index);
  };
  const handleColorChange1 = (e) => {
    const colorCode = e.target.value;
    setInputValuesPrimery1(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange2 = (e) => {
    const colorCode = e.target.value;
    setInputValuesPrimery2(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange3 = (e) => {
    const colorCode = e.target.value;
    setInputValuesPrimery3(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange4 = (e) => {
    const colorCode = e.target.value;
    setInputValuesSecondary1(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange5 = (e) => {
    const colorCode = e.target.value;
    setInputValuesSecondary2(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange6 = (e) => {
    const colorCode = e.target.value;
    setInputValuesSecondary3(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange7 = (e) => {
    const colorCode = e.target.value;
    setInputValuesTertiary1(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange8 = (e) => {
    const colorCode = e.target.value;
    setInputValuesTertiary2(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  const handleColorChange9 = (e) => {
    const colorCode = e.target.value;
    setInputValuesTertiary3(colorCode);
    props.setSubmitQuestionAnswerData(props.questionInfo,'Colors',data,'Colors')
  };
  return (
    <>
      <Container className='mb-5'>
        <Row>
          <Col md={12} className="d-flex mt-3">
            <div className="mr-4 mt-4">
              <h5>Primary : </h5>
            </div>
            &nbsp;&nbsp;
            <div>
              <input
                type="color"
                name={"primaryColor1"}
                onClick={(e) => handleSlideClick(11)}
                value={inputValuesPrimery1}
                className={`slide text-box-color ${
                  activeSlide === 11 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange1(e);
                  setInputValuesPrimery1(e.target.value);
                }}
              />
              {activeSlide === 11 ? (
                <input
                  type="text"
                  name={"primaryColor1"}
                  className={`width-50`}
                  value={inputValuesPrimery1}
                  onChange={(e) => {
                    handleColorChange1(e);
                    setInputValuesPrimery1(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(12)}
                value={inputValuesPrimery2}
                className={`slide text-box-color ${
                  activeSlide === 12 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange2(e);
                  setInputValuesPrimery2(e.target.value);
                }}
              />
              {activeSlide === 12 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesPrimery2}
                  onChange={(e) => {
                    handleColorChange2(e);
                    setInputValuesPrimery2(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(13)}
                value={inputValuesPrimery3}
                className={`slide text-box-color ${
                  activeSlide === 13 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange3(e);
                  setInputValuesPrimery3(e.target.value);
                }}
              />
              {activeSlide === 13 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesPrimery3}
                  onChange={(e) => {
                    handleColorChange3(e);
                    setInputValuesPrimery3(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
         {/* second */}
          <Col md={12} className="d-flex mt-3">
          <div className="mt-4">
              <h5>Secondary : </h5>
            </div>
             &nbsp;&nbsp;
            <div>
              <input
                type="color"
                name={"primaryColor1"}
                onClick={(e) => handleSlideClick(21)}
                value={inputValuesSecondary1}
                className={`slide text-box-color ${
                  activeSlide === 21 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange4(e);
                  setInputValuesSecondary1(e.target.value);
                }}
              />
              {activeSlide === 21 ? (
                <input
                  type="text"
                  name={"primaryColor1"}
                  className={`width-50`}
                  value={inputValuesSecondary1}
                  onChange={(e) => {
                    handleColorChange4(e);
                    setInputValuesSecondary1(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(22)}
                value={inputValuesSecondary2}
                className={`slide text-box-color ${
                  activeSlide === 22 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange5(e);
                  setInputValuesSecondary2(e.target.value);
                }}
              />
              {activeSlide === 22 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesSecondary2}
                  onChange={(e) => {
                    handleColorChange5(e);
                    setInputValuesSecondary2(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(23)}
                value={inputValuesSecondary3}
                className={`slide text-box-color ${
                  activeSlide === 23 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange6(e);
                  setInputValuesSecondary3(e.target.value);
                }}
              />
              {activeSlide === 23 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesSecondary3}
                  onChange={(e) => {
                    handleColorChange6(e);
                    setInputValuesSecondary3(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
          {/* thired */}
          <Col md={12} className="d-flex mt-3">
          <div className="mr-4 mt-4">
              <h5>Tertiary : </h5>
            </div>
            &nbsp;&nbsp;
            <div>
              <input
                type="color"
                name={"primaryColor1"}
                onClick={(e) => handleSlideClick(31)}
                value={inputValuesTertiary1}
                className={`slide text-box-color ${
                  activeSlide === 31 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange7(e);
                  setInputValuesTertiary1(e.target.value);
                }}
              />
              {activeSlide === 31 ? (
                <input
                  type="text"
                  name={"primaryColor1"}
                  className={`width-50`}
                  value={inputValuesTertiary1}
                  onChange={(e) => {
                    handleColorChange7(e);
                    setInputValuesTertiary1(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(32)}
                value={inputValuesTertiary2}
                className={`slide text-box-color ${
                  activeSlide === 32 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange8(e);
                  setInputValuesTertiary2(e.target.value);
                }}
              />
              {activeSlide === 32 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesTertiary2}
                  onChange={(e) => {
                    handleColorChange8(e);
                    setInputValuesTertiary2(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <input
                type="color"
                name={"primaryColor2"}
                onClick={(e) => handleSlideClick(33)}
                value={inputValuesTertiary3}
                className={`slide text-box-color ${
                  activeSlide === 33 ? "active" : "in-active"
                }`}
                onChange={(e) => {
                  handleColorChange9(e);
                  setInputValuesTertiary3(e.target.value);
                }}
              />
              {activeSlide === 33 ? (
                <input
                  type="text"
                  name={"primaryColor2"}
                  className={`width-50`}
                  value={inputValuesTertiary3}
                  onChange={(e) => {
                    handleColorChange9(e);
                    setInputValuesTertiary3(e.target.value);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
  };
};

export default connect(mapStateToProps)(ColorPalate);
