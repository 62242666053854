import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row} from "reactstrap";

import AddKeywordTrackingHubSpotPaymentDetailsService from "../../../../../../Service/DashboardServices/UserProfileServices/AddKeywordTrackingHubSpotPaymentDetailsService"
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";




function AddKeyWordsTrackingPage(props) {

    useEffect(() => {
        props.dispatch(AddKeywordTrackingHubSpotPaymentDetailsService(props.token)); 
    }, [])

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    return (
        <Container fluid>
            <Row className="mbg-3">
                <Col className="mbg-3" md="12">
                    <h3>Add Keyword Rank Tracking</h3>
                </Col>
                
                <Col md="6" xl="4">
                    
                </Col>
                {/*<Col md="6" xl="4">*/}
                {/*    <MRPCard />*/}
                {/*</Col>*/}
            </Row>
           
                
            <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                <Row>                                  
                                    
                                    <Col md="9" className="mb-2"> 
                                        
                                                                 
                                        <h4 className="text-brand-green">AppSumo Licence Details</h4>
                                        <h5> Plan :</h5>
                                        <h6>{props.addKeywordTrackingHubSpotPaymentDetails.appSumoPlanId}</h6>
                                        <p></p>
                                        <h5> Appsumo Email :</h5>
                                        <h6>{props.addKeywordTrackingHubSpotPaymentDetails.email}</h6>
                                        <p></p>                                        
                                        <Button
                                        className={'ml-2'}
                                        color={'brand-blue-dark'} 
                                        onClick={() => openInNewTab(props.addKeywordTrackingHubSpotPaymentDetails.paymentURL)}                                                                         
                                        >

                                        View Details & Upgrade
                            
                                        </Button>
                                        <Button
                                        className={'ml-2'}
                                        color={'brand-blue-dark'} 
                                        onClick={() => openInNewTab(props.addKeywordTrackingHubSpotPaymentDetails.GET_IN_TOUCH_MEETING_LINK)}                                                                         
                                        >

                                        Get in Touch
                            
                                        </Button>

                                    </Col>

                                    </Row>

                                    </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                        </Col>
                        </Row >
            
        </Container>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    addKeywordTrackingHubSpotPaymentDetails: state.profile.addKeywordTrackingHubSpotPaymentDetails
})

export default connect(mapStateToProps)(AddKeyWordsTrackingPage);