import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";


function ProductTrackingAddOnService(token, priceID, asin) {
    return axios.post(`${CURRENT_API_URL}/payment/create-tracking-subscription`, {
        priceId: priceID,
        asin: asin,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}


export default ProductTrackingAddOnService;