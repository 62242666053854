import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function CreateSubAccount(token, { firstName, lastName, email, password, subAccountName }) {
    return axios.post(`${CURRENT_API_URL}/account/createSubAccount`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        subAccountName: subAccountName,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Sub-account created successfully!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while creating the sub-account. Please try again later.");
            return false;
        })
}

function GetSubAccounts(token) {
    return axios.get(`${CURRENT_API_URL}/account/viewSubAccount`, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            return [];
        })
}

function DeleteSubAccount(token, subAccountName) {
    return axios.post(`${CURRENT_API_URL}/account/deleteSubAccount`, {
        subAccountName: subAccountName,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Successfully deleted the sub-account.");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while deleting the sub-account. Please try again.");
            return false;
        })
}

function GetSubAccountStores(token, subAccountName) {
    return axios.post(`${CURRENT_API_URL}/account/returnStoresForSubAccount `, {
        subAccountName: subAccountName,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            return [];
        })
}

function AssignStoresToSubAccount(token, stores, subAccount) {

    return axios.post(`${CURRENT_API_URL}/account/assignStoresToSubAccount`, {
        integerID: stores,
        subAccount: subAccount,
        action: "addStore",
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            notify.NotifySuccess("Successfully assigned store(s) to sub-account!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while assigning store(s) to sub-account. Please try again.");
            return false;
        })
}

function RemoveStoresFromSubAccount(token, stores, subAccount) {

    return axios.post(`${CURRENT_API_URL}/account/assignStoresToSubAccount`, {
        integerID: stores,
        subAccount: subAccount,
        action: "removeStore",
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            notify.NotifySuccess("Successfully removed store(s) from sub-account!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while removing store(s) from sub-account. Please try again.");
            return false;
        })
}


export {
    CreateSubAccount,
    DeleteSubAccount,
    GetSubAccounts,
    GetSubAccountStores,
    AssignStoresToSubAccount,
    RemoveStoresFromSubAccount,
}
