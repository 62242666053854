import React from 'react';
import {Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem} from "reactstrap";


function PricingOptionsCard(props) {

    const {
        title,
        subtitle,
        monthlyPrice,
        annualPrice,
        monthlyPriceID,
        annualPriceID,
        numManagedProducts,
        numManagedProductFamilies,
        level,
        managedProductsDescription,
        features,
    } = props

    const price = props.monthly ? monthlyPrice : annualPrice;
    const priceID = props.monthly ? monthlyPriceID : annualPriceID;
    console.log(price)

    
    return (
        <Card  style={{height: "100%"}}>
            <CardHeader style={{justifyContent: "center"}}>
                {title}
            </CardHeader>
            <CardBody className="text-center">
                <h6>{subtitle}</h6>
                <div className="mt-5 mb-4">
                    <h3 className="text-dark">
                        <b>
                            {
                                price === 0 ?
                                    'Custom Pricing'
                                    :
                                    <p>{`$${price}`}<span style={{color: "#c7c7c7"}}>/mo</span></p>
                            }
                        </b>
                    </h3>
                    {
                        (!props.monthly && price !== 0 ) &&
                        <p><i>(billed annually)</i></p>
                    }
                    {
                        price !== 0 ?
                        <p>Billed Quarterly</p>
                        :
                        <p></p>
                    }
                </div>
                {/* {
                    (props.subscriptionLevel === level) &&
                    <Button
                        className={"mb-8"}
                        disabled={true}
                    >
                        Active
                    </Button>
                } */}
                {

                    price == 197 ?
                    <Button
                        className={"mb-8"}
                        color={"primary"}
                        onClick={() => props.handleCheckout(priceID)}
                    >
                       Free Trial Active
                    </Button>
                    :
                    <Button
                    className={"mb-8"}
                    color={"primary"}
                    onClick={() => window.open("https://meetings.hubspot.com/noah-grady/learn-more-about-simpliworks-chatbot")}
                    >
                    Contact Sales
                    </Button>
                }
                <ListGroup className="text-center">
                    {
                        features.map((feature, idx) => (
                            <ListGroupItem style={{borderWidth: 0}} key={idx}>
                                {/*<i className="pe-7s-check text-primary" style={{fontWeight: "bold"}}> </i>*/}
                                {' '}<span style={{fontSize: "15px"}}>{feature}</span>
                            </ListGroupItem>
                        ))
                    }
                </ListGroup>
            </CardBody>
        </Card>
    )

}

export default PricingOptionsCard;