import React from 'react';
import ReactTable from "react-table";
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import LeadGenProdCatJobDataToolService from "../../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatJobDataToolService";
import { connect } from 'react-redux';
import ViewAmazonProductCategoriesService from '../../../../../../../Service/DashboardServices/UserProfileServices/ViewAmazonProductCategoriesService';


function AmazonProductCategories(props) {

    

    const handleSubmit = (id) => {
    props.dispatch(ViewAmazonProductCategoriesService(props.token, id));}

    const columns = [
        {
            Header: "Category Id",
            accessor: "category_id",
            // Cell: cellInfo => <Button className="mt-1" color="link" onClick={() => handleSubmit(cellInfo.value)}>{cellInfo.value}</Button>
        },
        {
            Header: "Name",
            accessor: "name",
        },      
       
        {
            Header: "Path",
            accessor: "path",
        },        
        {
            Header: "Type",
            accessor: "type",
        },
        {
            Header: "Parent Id",
            accessor: "parent_id",
        },
        
    ];

    


    return (
        <ReactTable
            columns={columns}
            data={props.data}            
            style={{
                height: "800px", // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            noDataText="No Data found."
        />
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(AmazonProductCategories)