import React, { Fragment } from 'react';
import { Col, Row } from "reactstrap";
import { connect } from 'react-redux';

import CustomModal from "../../../../../../Components/Modals/CustomModal";
import CampaignSummaryCard
    from "../../../../AccountOverview/Components/Cards/CampaignSummaryCard";
import CampaignKeywordCard
    from "../../../../AccountOverview/Components/Cards/CampaignKeywordCard";
import CampaignTargetedProductCard
    from "../../../../AccountOverview/Components/Cards/CampaignTargetedProductCard";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";


function ReadyCampaignDetailsModal(props) {

    const toggle = () => {
        props.setIsOpen(!props.isOpen);
    }

    const renderCampaignDetails = () => {
        if (!props.loading) {
            return (
                <Fragment>
                    <Row className="mb-3">
                        <Col md="12">
                            <CampaignSummaryCard campaignOverview={props.campaignOverview} handleLaunch={props.handleLaunch} />
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col md="6">
                            <CampaignKeywordCard keywords={props.keywords} status={props.campaignOverview.status} />
                        </Col>
                        { props.campaignType === 'both' &&
                        <Col md="6">
                            <CampaignTargetedProductCard targetedProducts={props.targetedProducts}  />
                        </Col>
                        }
                    </Row>
                </Fragment>
            );
        }
    }

    return (
        <CustomModal
            isOpen={props.isOpen}
            toggle={toggle}
            size="lg"
            header="Campaign Overview"
        >
            <Row>
                <Col md="12">
                    <LoadingOverlay
                        active={props.loading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#f1f4f6',
                                opacity: 1
                            }),
                            content: (base) => ({
                                ...base,
                                color: "#000"
                            })
                        }}
                        spinner={<Loader active type="ball-pulse"/>}
                        text='Loading...'
                    >
                        {renderCampaignDetails()}
                    </LoadingOverlay>
                </Col>
            </Row>
        </CustomModal>
    )
}

const mapStateToProps = state => ({
    campaignOverview: state.accountOverview.campaign.campaignOverview,
    campaignType: state.accountOverview.campaign.campaignType,
    keywords: state.accountOverview.campaign.keywords,
    targetedProducts: state.accountOverview.campaign.targetedProducts,
    loading: state.accountOverview.campaign.loading,
    status: state.accountOverview.campaign.status,
});

export default connect(mapStateToProps)(ReadyCampaignDetailsModal);