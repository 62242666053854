import React from "react";
import { Route, Switch } from "react-router-dom";

import urls from '../../../../Utils/URLs'
// import MarketAnalysisPage from "./Scenes/MarketAnalysis/MarketAnalysisPage";
import CreateCampaignLayout from "./Components/CreateCampaignLayout";
import NewCampaignPage from "./Scenes/NewCampaign/NewCampaignPage";
import PageNotFound from "../../../PageNotFound";


function CreateCampaignPage() {


    return (
        <CreateCampaignLayout>
            <Switch>
                <Route path={urls.CREATE_CAMPAIGN_INFO_URL}>
                    <NewCampaignPage/>
                </Route>
                <Route component={PageNotFound}/>
            </Switch>
        </CreateCampaignLayout>
    );
}

export default CreateCampaignPage;
