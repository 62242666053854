import React, { Fragment } from 'react';
import {
    FormGroup, Input
} from 'reactstrap';
import { Field, ErrorMessage } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from '../../../../../../Utils/Constants';



const AmazonProductDetails = () => {
    return (
        <Fragment>
            <br />
            <h4 className="heading">Product Description</h4>
            <FormGroup>
                <div className="quill-prod">
                    <Field name="productDescription">
                        {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} theme={'snow'}

                            modules={Editor.modules}
                            formats={Editor.formats}
                            bounds={'.quill-prod'}

                            placeholder='Maximum 2000 characters...' />}
                    </Field>
                </div>
                <span className="text-danger">
                    <ErrorMessage name="productDescription" />
                </span>
            </FormGroup>
            {/* <FormGroup>
                <Field as="textarea" className="form-control" rows="6" maxLength="2000" name="productDescription" id="prodDescription" placeholder="Maximum 2000 characters..." />
                 <Input tag = {Field} type="textarea" rows="10" name="productDescription" id="prodDescription"  placeholder="Product Description" /> 
                <span className="text-danger">
                    <ErrorMessage name="productDescription" />
                </span>
            </FormGroup>
            */}
            <h4 className="heading p-t-10">Backend Keywords</h4>
            <FormGroup>
                <Input type="text" name="backendKeywords" id="backendKeywords" tag={Field} maxLength="250" placeholder="Maximum 250 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="backendKeywords" />
                </span>
            </FormGroup>
            <h4 className="heading p-t-10">Subject Matter</h4>
            <FormGroup>
                <Input type="text" name="subjectMatter1" id="subjectMatter1" tag={Field} maxLength="50" placeholder="Maximum 50 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="subjectMatter1" />
                </span>
            </FormGroup>
            <FormGroup>
                <Input type="text" name="subjectMatter2" id="subjectMatter2" tag={Field} maxLength="50" placeholder="Maximum 50 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="subjectMatter2" />
                </span>
            </FormGroup>
            <FormGroup>
                <Input type="text" name="subjectMatter3" id="subjectMatter3" tag={Field} maxLength="50" placeholder="Maximum 50 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="subjectMatter3" />
                </span>
            </FormGroup>
            <FormGroup>
                <Input type="text" name="subjectMatter4" id="subjectMatter4" tag={Field} maxLength="50" placeholder="Maximum 50 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="subjectMatter4" />
                </span>
            </FormGroup>
            <FormGroup>
                <Input type="text" name="subjectMatter5" id="subjectMatter5" tag={Field} maxLength="50" placeholder="Maximum 50 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="subjectMatter5" />
                </span>
            </FormGroup>
            <h4 className="heading p-t-10">A+ Content</h4>
            <FormGroup>
                <Input type="text" name="linkToAsset" id="aPlusContent" tag={Field} maxLength="1000" placeholder="Link to google drive or dropbox (Maximum 1000 characters...)" />
                <span className="text-danger">
                    <ErrorMessage name="linkToAsset" />
                </span>
            </FormGroup>
        </Fragment>
    )
}





export default AmazonProductDetails;
