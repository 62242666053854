
import ReactTable from "react-table";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, CardTitle } from 'reactstrap';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
// import { models } from "powerbi-client";
// import { PowerBIEmbed } from "powerbi-client-react";
import PowerbiEmbed from './PowerbiEmbed';
import React, {Fragment,useState,useEffect} from 'react';


function SellerPartnerDataTable(props) {

    const [jsonData, setJsonData] = useState();
    const [isJsonOpen, setIsJsonOpen] = useState();
    // const reduxData = props.data;
    // const reduxDataString = JSON.parse(jsonData);
    const [isOpen, setIsOpen] = useState(false);
    const [summary, setSummary] = useState();    

    const printTheJSONInPrettyFormat=(inputJson)=> { 
        var parseJSON = JSON.parse(inputJson);
        var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
        setJsonData(JSONInPrettyFormat)
    }
    

    const columns = [
                
        {
            Header: "Seller Partner ID",
            accessor: "sellerPartnerID",
        },
        {
            Header: 'Creation Date',
            accessor: 'creation_date',
        },
        {
            Header: 'Object Name',
            accessor: 'object_name',
        },
        {
            Header: 'Json Data',
            accessor: 'jsonResponse',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => {
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
        {
            Header: 'Report',
            accessor: 'powerbi',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => { 
                        
                        setSummary(<PowerbiEmbed data={props.data.powerBIReport}/>)  
                        //setIsOpen(!isOpen)                       
                    }}
                >
                    View 
                </Button>
            
        )
        }
             
    ];

    const innerColumns = [
        {    
            Header: "Amazon Order Id",
            accessor: "AmazonOrderId",   
        },
        {
            Header: "Order type",
            accessor: "OrderType",
        },
        {
            Header: "Order Status",
            accessor: "OrderStatus",
        },
        {
            Header: 'Order Total',
            accessor: 'OrderTotal',
        },
        {
            Header: 'Sales Channel',
            accessor: 'SalesChannel',
        },
        {
            Header: 'No of Items Shipped',
            accessor: 'NumberOfItemsShipped',
        },
        {
            Header: 'No of Items Unshipped',
            accessor: 'NumberOfItemsUnshipped',
        },
       
        {
            Header: 'Json Data',
            accessor: 'jsonResponse',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => {
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
    ];

    const innerMostColumns = [
        {    
            Header: "ASIN",
            accessor: "ASIN",   
        },
        {
            Header: "Title",
            accessor: "Title",
        },
        {
            Header: 'Item Price',
            accessor: 'ItemPrice',
        },
        {
            Header: "Quantity Ordered",
            accessor: "QuantityOrdered",
        },
        {
            Header: 'Quantity Shipped',
            accessor: 'QuantityShipped',
        },
       
        {
            Header: 'Json Data',
            accessor: 'jsonResponse',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => {
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },      
    ];

    const Outercolumns = [
        
        {
            Header: "Job ID",
            accessor: "jobId",
        },
        {
            Header: "Seller Partner ID",
            accessor: "sellerPartnerID",
        },
        {
            Header: 'Creation Date',
            accessor: 'creation_date',
        },
        {
            Header: 'Object Name',
            accessor: 'object_name',
        },
        {
            Header: 'Json Data',
            accessor: 'jsonResponse',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => {
                        printTheJSONInPrettyFormat(cellInfo.value)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
        {
            Header: 'PowerBI Result',
            accessor: 'powerbi',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    onClick={() => { 
                        
                        setSummary(<PowerbiEmbed/>)  
                        setIsOpen(!isOpen)                       
                    }}
                >
                    Show 
                </Button>
            
        )
        }
             
    ];

    const salesAsinColumns = [
        {    
            Header: "ASIN",
            accessor: "asin",   
        },
        {
            Header: "Title",
            accessor: "title",
        },    
    ];

    const salescolumns = [
        
        {
            Header: "Interval",
            accessor: "interval",
        },
        {
            Header: "Unit Count",
            accessor: "unitCount",
        },
        {
            Header: 'Order Item Count',
            accessor: 'orderItemCount',
        },
        {
            Header: 'Average Unit Price',
            accessor: 'averageUnitPrice',
        },       
        {
            Header: 'Total Sales',
            accessor: 'totalSales',
        },     
             
    ];

    const productFeescolumns = [
        
        {
            Header: "Field",
            accessor: "Field",
        },
        {
            Header: "Value",
            accessor: "Value",
        },           
             
    ];

    const SKUcolumns = [        
        {
            Header: "SKU",
            accessor: "sku",
        }    
    ];

    const salesSKUInnercolumns = [
        
        {
            Header: "Interval",
            accessor: "interval",
        },
        {
            Header: "Unit Count",
            accessor: "unitCount",
        },
        {
            Header: 'Order Item Count',
            accessor: 'orderItemCount',
        },
        {
            Header: 'Average Unit Price',
            accessor: 'averageUnitPrice',
        },       
        {
            Header: 'Total Sales',
            accessor: 'totalSales',
        },     
             
    ];

    return (
        <Fragment>
            <Row>
                    <Col>
                        <CardTitle>Orders Data</CardTitle>
                        <Card>
                        </Card>
                    </Col>
            </Row>

        <ReactTable
            data={props.data.orders}
            columns={columns}
            className="-fixed -highlight -striped"
            getTheadProps={() => {
                return {
                    style: {
                        overflowY: 'hidden',
                    }
                }
            }}
            defaultPageSize={5}
            noDataText={"No Data Found."}
            style={{
                height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            
            SubComponent={row => (
                <div className="p-5">
                    <ReactTable
                        data={row.original.orderDetails}
                        columns={innerColumns}
                        defaultPageSize={5}
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: 'hidden'
                                }
                            }
                        }}
                        className="-striped -highlight -fixed"
                        noDataText={"No Orders found."}
                        SubComponent={row1 => (
                            <div className="p-5">
                                <ReactTable
                                    data={row1.original.orderitemList}
                                    columns={innerMostColumns}
                                    defaultPageSize={5}
                                    getTheadProps={() => {
                                        return {
                                            style: {
                                                overflowY: 'hidden'
                                            }
                                        }
                                    }}
                                    className="-striped -highlight -fixed"
                                    noDataText={"No Orders found."}
                                    
                                    SubComponent={row2 => (
                                        <div className="p-5">                                            
                                            <ReactTable
                                                data={row2.original.productFees}
                                                columns={productFeescolumns}
                                                defaultPageSize={5}
                                                getTheadProps={() => {
                                                    return {
                                                        style: {
                                                            overflowY: 'hidden'
                                                        }
                                                    }
                                                }}
                                                className="-striped -highlight -fixed"
                                                noDataText={"No data found."}
                                                
                                                
                                            />

                                        </div>
                                    )}
                                    
                                />
                            </div>
                        )}
                    />
                </div>
            )}
        />    
          &nbsp;    
          &nbsp;    
          &nbsp;    
          &nbsp;    
          <Row>
                    <Col>
                        <CardTitle>Sales By ASIN</CardTitle>
                        <Card>
                        </Card>
                    </Col>
                </Row>
        <ReactTable
            data={props.data.sales}
            columns={salesAsinColumns}
            className="-fixed -highlight -striped"
            getTheadProps={() => {
                return {
                    style: {
                        overflowY: 'hidden',
                    }
                }
            }}
            defaultPageSize={5}
            noDataText={"No Data Found."}
            style={{
                height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            SubComponent={row2 => (
                <div className="p-5">                                            
                    <ReactTable
                        data={row2.original.salesListInner}
                        columns={salescolumns}
                        defaultPageSize={5}
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: 'hidden'
                                }
                            }
                        }}
                        className="-striped -highlight -fixed"
                        noDataText={"No data found."}
                        
                        
                    />

                </div>)}
            
            />
            &nbsp;    
            &nbsp;    
            &nbsp;    
            &nbsp;
            <Row>
                    <Col>
                        <CardTitle>Sales By SKU</CardTitle>
                        <Card>
                        </Card>
                    </Col>
                </Row>
                <ReactTable
            data={props.data.salesSku}
            columns={SKUcolumns}
            className="-fixed -highlight -striped"
            getTheadProps={() => {
                return {
                    style: {
                        overflowY: 'hidden',
                    }
                }
            }}
            defaultPageSize={5}
            noDataText={"No Data Found."}
            style={{
                height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            SubComponent={row2 => (
                <div className="p-5">                                            
                    <ReactTable
                        data={row2.original.salesListInner}
                        columns={salesSKUInnercolumns}
                        defaultPageSize={5}
                        getTheadProps={() => {
                            return {
                                style: {
                                    overflowY: 'hidden'
                                }
                            }
                        }}
                        className="-striped -highlight -fixed"
                        noDataText={"No data found."}
                        
                        
                    />

                </div>)}
            
            />
        
             <CustomModal 
                header={"Json Data"}
                isOpen={isJsonOpen}
                toggle={() => setIsJsonOpen(!isJsonOpen)}
                size={"xl"}
                footer={
                    <Fragment>

                        <Button 
                            color="link" 
                            onClick={() => setIsJsonOpen(!isJsonOpen)}
                            >
                                Cancel
                            </Button>
                    </Fragment>
                }        
                    
            >  

                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{jsonData}</pre> </p></Label> 
 
             </CustomModal>
             <CustomModal
                header={"PowerBI Results"}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
            &nbsp;                               

            <Row>
                    <Col>                       
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    </Col>
            </Row>
            </Fragment>
    );
}


export default SellerPartnerDataTable;