import React from "react";
import { Card, Row, Col, Button } from 'reactstrap';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import URLs from '../../../../../../Utils/URLs'
import Can from "../../../../../Components/Can";

const ProjectItem = ({ project, accessLevel }) => {

  const getFormattedDate = (date) => {
    try {
      return format(new Date(date), 'MM/DD/YYYY')
    } catch (e) {
      return '';
    }
  }


  const getProjectId = (project) => {
    let id = '';
    if (project.asin) id = project.asin;
    else if (project.ean) id = project.ean;
    else if (project.gtin) id = project.gtin;
    else if (project.sku) id = project.sku;
    else if (project.upc) id = project.upc;

    return id;
  }

  const getProjectType = (project) => {
    let type = "";
    if (project.asin) type = "ASIN";
    else if (project.ean) type = "EAN";
    else if (project.gtin) type = "GTIN";
    else if (project.sku) type = "SKU";
    else if (project.upc) type = "UPC";

    return type;
  }

  const getProjectStatusClass = (status) => {
    let className = '';
    if (status === 'ready') {
      className = 'badge badge-light-info';
    } else if (status === 'completed') {
      className = 'badge badge-light-success';
    } else if (status === 'inProgress') {
      className = 'badge badge-light-orange';
    } else if (status === 'drafted') {
      className = 'badge badge-dark-black';
    }
    return className;
  }

  const getProjectStatus = (status) => {
    let name = '';
    if (status === 'ready') {
      name = 'Ready for Review';
    } else if (status === 'completed') {
      name = 'Completed';
    } else if (status === 'inProgress') {
      name = 'In Progress';
    }
    else if (status === 'drafted') {
      name = 'Draft'
    }
    return name;
  }

  return (
    <Card className="card card-custom gutter-b card-stretch">
      <div className="card-body">
        <Row>
          <Col md="12">
            <span className={"card-label font-weight-bolder badge-border font-1 capitalize " + getProjectStatusClass(project.projectStatus)}>{getProjectStatus(project.projectStatus)}</span>
          </Col>
        </Row>
        <Row>
          <Col md="3" className="img-container-thumb">
            <img src={project.itemUrl ? project.itemUrl : "http://www.cyberscriptsolutions.com/wp-content/uploads/2017/10/default_product_icon.png"} alt="Test" className="img-thumb-fit" />
          </Col>
          <Col md="6" className="products-list">
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-dark-75 font-weight-bolder mr-2">ProductID:</span>
              <span className="text-muted font-weight-bold">{getProjectId(project)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center m-t-10">
              <span className="text-dark-75 font-weight-bolder mr-2">Type:</span>
              <span className="text-muted font-weight-bold">{getProjectType(project)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center m-t-10">
              <span className="text-dark-75 font-weight-bolder mr-2">Submitted On:</span>
              <span className="text-muted font-weight-bold">{getFormattedDate(project.submittedDate)}</span>
            </div>

          </Col>
          <Col md="3">
            <Can
              role={accessLevel}
              perform={"project:edit"}
              yes={() => (
                  project.projectStatus !== 'drafted' ?
                      <Button type="button" className="w100" color="success" tag={Link} to={`/project/${project.storeThatRequestedIntegerID}/agency-project-view/${project.id}`}>Edit</Button>
                      :
                      <Button type="button" className="w100" color="success" disabled>Edit</Button>
              )}
            />
            {/*{(accessLevel === 0) && project.projectStatus !== 'drafted' ?*/}
            {/*  <Button type="button" className="w100" color="success" tag={Link} to={`/project/${project.storeThatRequestedIntegerID}/agency-project-view/${project.id}`}>LAUNCH</Button>*/}
            {/*  :*/}
            {/*  <>*/}
            {/*    {(accessLevel === 0) &&*/}
            {/*      <Button type="button" className="w100" color="success" disabled>LAUNCH</Button>*/}
            {/*    }*/}

            {/*  </>*/}
            {/*}*/}
            <Can
              role={accessLevel}
              perform={"project:review"}
              yes={() => (
                  project.projectStatus !== 'drafted' ?

                      <Button type="button" className="m-t-10 w100" color="secondary" tag={Link} to={`/project/${project.storeThatRequestedIntegerID}/project-view/${project.id}`}>Review</Button>
                      :
                      <Button type="button" className="w100" color="success" disabled>Review</Button>
              )}
            />
            {/*{*/}
            {/*  (accessLevel === 3) && project.projectStatus !== 'drafted' ?*/}

            {/*    <Button type="button" className="m-t-10 w100" color="secondary" tag={Link} to={`/project/${project.storeThatRequestedIntegerID}/project-view/${project.id}`}>Review</Button>*/}
            {/*    :*/}
            {/*    <>*/}
            {/*      {(accessLevel === 3) &&*/}
            {/*        <Button type="button" className="w100" color="success" disabled>REVIEW</Button>*/}
            {/*      }*/}

            {/*    </>*/}
            {/*}*/}

          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default ProjectItem;
