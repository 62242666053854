import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import {AuthAdvertisingAPI} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';


function AdvertisingAPIAuthorizationService(
    token,
    amznUserId,
    amznAccessToken,
    amznRefreshToken,
    expiration_time,
) {

    return function(dispatch) {
        console.log(amznUserId);
        console.log(amznAccessToken);
        console.log(amznRefreshToken);        
        console.log(expiration_time);
        notify.NotifySuccess("Amazon advertising authorization is successful. Screen will be automatically refreshed once stores are fetched successfully.");
        axios.post(`${CURRENT_API_URL}/account/populateSimpliworksProfile`, {
            amznUserId: amznUserId,
            amznAccessToken: amznAccessToken,
            amznRefreshToken: amznRefreshToken,
            expiration_time: expiration_time,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(() => {
                dispatch(AuthAdvertisingAPI());
                // notify.NotifySuccess("Amazon Advertising was authorized successfully!");
                window.location.reload(false);
            })
            .catch(error => {
                console.log(error);                
                notify.NotifyError("An error occurred while authorizing the Amazon Advertising API, please try again.");
            })
    }
}

export default AdvertisingAPIAuthorizationService;