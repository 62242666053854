import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import React  from 'react';
import  useState  from 'react';



function UpdateStoreManagementStatusService(token, profileId, isManaged) {

    
    return axios.post(`${CURRENT_API_URL}/campaign/updateStoreManagementStatus`, {
        profileId:profileId, isManaged:isManaged
        
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        notify.NotifySuccess("Store management status was updated successfully.Please refresh the page to view changes.");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem while updating store management status, please try again.");
        return false;
    });
}

export default UpdateStoreManagementStatusService;