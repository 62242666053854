import React, {Fragment} from "react";
import MetisMenu from 'react-metismenu';
import RouterLink from 'react-metismenu-router-link';


function DashboardNav(props) {
    return (
        <Fragment>
            <h5 className="app-sidebar__heading">{props.heading}</h5>
            <MetisMenu
                content={props.menuItems}
                // activeLinkId
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
                LinkComponent={RouterLink}
            />
        </Fragment>
    );
}

export default DashboardNav;
