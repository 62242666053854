import axios from 'axios';
import notify from '../../../../Notifications/ToastifyActions';
import { CURRENT_API_URL } from "../../../../Utils/Constants";



function LaunchCampaignService(token, name, collectionID, selectedKeywords) {
    return axios.post(`${CURRENT_API_URL}/campaign/launch`, {
        name: name,
        collectionID: collectionID,
        selectedKeywords: selectedKeywords,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            if (res.status === 200) {
                notify.NotifySuccess("Your campaign was successfully launched!");
                return true;
            }
        })
        .catch(error => {
            notify.NotifyError("We've encountered an error launching your campaign, please try again later.");
            console.log(error);
            return false;
        });
}

export default LaunchCampaignService;