import React, { useState } from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Container, FormGroup, Row} from "reactstrap";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { connect } from 'react-redux';

import DateRangePicker from "../../../../../Components/Input/DateRangePicker";
import RankTrackingStatusTable from "./Components/Tables/RankTrackingStatusTable";
import {AdvertisingDataStatus, RankTrackingStatus} from "../../../../../../Service/OPSServices/DataCollectionServices";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import CustomTabs from "../../../../../Components/Nav/CustomTabs";
import CustomTabContent from "../../../../../Components/Nav/CustomTabContent";
import AdvertisingDataStatusTable from "./Components/Tables/AdvertisingDataStatusTable";


function DataCollectionPage(props) {

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const [rankDataStatus, setRankDataStatus] = useState([]);
    const [adDataStatus, setAdDataStatus] = useState([]);

    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tabID) => {
        setActiveTab(tabID);
    }


    const fetchRankDataStatus = () => {
        setLoading(true);

        RankTrackingStatus(props.token, dateRange.startDate, dateRange.endDate).then(rankStatus => {
            if (rankStatus) {
                setRankDataStatus(rankStatus);
            }
        });

        AdvertisingDataStatus(props.token, dateRange.startDate, dateRange.endDate).then(adStatus => {
            if (adStatus) {
                setAdDataStatus(adStatus);
            }
        });

        setLoading(false);
    }

    return (
        <>
            <Container fluid>
                <Row className="mb-3">
                    <Col md={"12"} lg={"6"}>
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Date Range</CardTitle>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                                <LoadingButton
                                    loading={loading}
                                    color={"primary"}
                                    onClick={fetchRankDataStatus}
                                    disabled={dateRange.startDate === null}
                                >
                                    Submit
                                </LoadingButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={"12"} lg={"10"}>
                        <Card>
                            <CardHeader className="tabs-lg-alternate">
                                <CustomTabs
                                    activeTab={activeTab}
                                    toggle={toggleTab}
                                    tabList={[
                                        <div className="widget-numbers-sm">Rank Tracking</div>,
                                        <div className="widget-numbers-sm">Advertising Data</div>,
                                    ]}
                                />
                            </CardHeader>
                            <CustomTabContent
                                activeTab={activeTab}
                                tabList={[
                                    <RankTrackingStatusTable data={rankDataStatus}/>,
                                    <AdvertisingDataStatusTable data={adDataStatus} />,
                                ]}
                                renderTabPane={(tab) => <CardBody>{tab}</CardBody>}
                            />
                        </Card>
                        {/*<CardTitle>Rank Data Collection Status</CardTitle>*/}
                        {/*<Card>*/}
                        {/*    */}
                        {/*</Card>*/}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(DataCollectionPage);