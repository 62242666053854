import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardTitle,Container,Button, Col, Input, Label, ButtonDropdown, ButtonToolbar,DropdownMenu, Row } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import NumericInput from 'react-numeric-input';
import StarRating from '../../../../Components/Rating/StarRating';
import CustomModal from '../../../../../../../Components/Modals/CustomModal';
import LoadingButton from '../../../../../../../Components/Buttons/LoadingButton';
import RefreshSalesDataService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/RefreshSalesDataService';
import Can,{filterByPermission} from '../../../../../../../Components/Can';
import RefreshStoreCampaignService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/RefreshStoreCampaignService';
import StoreListService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService';
import StoreOverviewService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import PowerbiEmbed from './PowerbiEmbed';
import Group from "../Img/Group.png";
import Arrow from "../Img/arrow.svg";
import Visit from "../Img/visit.svg";
import No_ratings from "../Img/No_rating.svg";
import { Link } from 'react-router-dom';
import urls from '../../../../../../../../Utils/URLs';
function StoreOverviewCard(props) {
	const { profile, overviewData } = props;

	if (profile && overviewData) {
		return (
			<>
				<Row className="ml-1 mb-3">
				<Col md="3">
						<div>
							<div className={profile.logo ? 'mt-5' : ''}>
								<p className='text-center'><img src={profile.logo ? profile.logo : Group}  width={profile.logo ? '' : 80}/></p>
								
							</div>
							<hr style={{ borderTop: '1px solid #333', margin: '1em 0' }} />
							<h4 className='text-center text-blue' ><b>{profile.name}</b></h4>
							<p className='text-center'>{overviewData.link && (
								<a
									className="mt-1 text-center"
									href={overviewData.link}
									target="_blank"
									rel="noopener noreferrer"
								>

									Visit <img src={Visit}  width={14} className='flip-img'></img>
								</a>
							)}</p>
						</div>
					</Col>
					<Col md="5" style={{borderLeft: '1px solid #333'}}>
						

						{profile.ratingTotal < 1 ? (
							<>
							<p className='text-center'>
							<img src={No_ratings} width={200}/></p>
							</>
						) : (
							<>
								<h3 className='text-center'>
									<StarRating
										initialRating={profile.rating}
										readonly
										stop={5}
									/>
								</h3>
								<h6 className='text-center text-blue' >{profile.rating}</h6>
								<h4 className='text-center text-blue' ><b>{profile.ratingTotal}</b> Reviews</h4>
								<h4 className='text-center positive-reviews'>{profile.ratingTotalPercentage}% positive reviews <img src={Arrow}  width={25}/></h4>
								<p className='text-center text-blue'>In last 12 months.</p>
							</>
						)}
					

					</Col>
					<Col md="4" style={{borderLeft: '1px solid #333'}}>

						<h6 className='mt-5'><b>Seller Partner Id:</b> {profile.details.accountID}</h6>
						<h6><b>Profile ID:</b> {profile.integerID}</h6>
						<h6><b>Location:</b> {profile.location}</h6>
						{ props.SPAPIAuth.SPAPIAuthorized == false ? <Link to={`${urls.BASE_REGISTRATION_JOURNEY_URL}/${profile.integerID}`}><Button color={"brand-blue-dark"}>Authorize</Button></Link> : '' }
						
						{/* <h6 className="mt-3 text-primary">
							This store currently has{' '}
							<b>{overviewData.managedProducts}</b> product{(overviewData.managedProducts !== 1) && 's'} under management.
						</h6> */}
					</Col>
				</Row>
			</>
		);
	} else {
		return <h4>No data available.</h4>;
	}
}

export default StoreOverviewCard;
