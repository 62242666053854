import React from "react";
import { Button, Spinner } from 'reactstrap';


/**
 * Button wrapper for the Reactstrap Button component. Takes in a loading param that when true, displays a spinner, and
 * when false, displays the button text. All other params are passed through to the Reactstrap Button.
 */
function LoadingButton(props) {
    const { loading, ...restProps } = props;
    return (
            <Button {...restProps}>
                { loading ?
                    <Spinner as="span" animation="border" size="sm" />
                :
                    props.children
                }
            </Button>
    );
}

export default LoadingButton;
