import React from "react";
import ReactTable from "react-table";
import { Label, CustomInput } from "reactstrap";

function ReadyCampaignKeywordTable(props) {

    const selectedCheckbox = (cellInfo) => (
        <Label check>
            <CustomInput
                type="checkbox"
                id={cellInfo.original.keyword + cellInfo.original.status}
                checked={cellInfo.original.selected}
                disabled={!props.isEditActive}
                onChange={e => {
                    let keywords = props.updatedKeywords.map(keyword => Object.assign({}, keyword));
                    keywords[cellInfo.index][cellInfo.column.id] = e.target.checked;
                    props.setUpdatedKeywords(keywords);
                }}
            />
        </Label>
    );

    const columns = [
        {
            Header: 'Keyword',
            accessor: 'keyword',
        },
        {
            Header: 'Type',
            accessor: 'status',
            Cell: props => props.value === "Theme" ?
                "phrase-match" : props.value === "Keyword" ?
                    "exact-match" : "unavailable",
        },
        // {
        //     Header: 'Bid Price',
        //     accessor: 'bidPrice',
        //     Cell: props => props.value === null ? "unavailable" : `$${props.value}`,
        // },
        // {
        //     Header: 'Selected',
        //     accessor: 'selected',
        //     Cell: props => selectedCheckbox(props)
        // },
    ];

    return (
        <ReactTable
            data={props.isEditActive ? props.updatedKeywords : props.data}
            columns={columns}
            style={{
                height: "400px",
            }}
            className="-striped -highlight -fixed"
            noDataText="No keywords found"
        />
    );
}

export default ReadyCampaignKeywordTable;
