import React from "react";
import ReactTable from "react-table";
import {Form, InputGroup, CustomInput, Label, Input, FormGroup, Button} from "reactstrap";
import NumericInput from 'react-numeric-input';
import {BugTwoTone} from "@ant-design/icons";

function KeywordTable(props) {

    const selectedCheckbox = (cellInfo) => (
            <Label check>
                <CustomInput
                    type="checkbox"
                    id={cellInfo.original.keywordText + cellInfo.original.keywordID + 'select'}
                    checked={cellInfo.original.selected}
                    disabled={!props.isEditActive}
                    onChange={e => {
                        // e.stopPropagation();
                        console.log("selected")
                        let selected = e.target.checked;
                        let keywords = props.updatedKeywords.map(keyword => Object.assign({}, keyword));
                        keywords[cellInfo.index][cellInfo.column.id] = selected;
                        props.setUpdatedKeywords(keywords);
                    }}
                />
                enabled
            </Label>
    );

    const editableBidPrice = (cellInfo) => {
        if (props.isEditActive) {
            return (
                <InputGroup>
                    <NumericInput
                        className="form-control"
                        id={cellInfo.original.keywordText + cellInfo.original.status + 'bid'}
                        min={0.02}
                        precision={2}
                        step={0.01}
                        value={cellInfo.original.bidPrice}
                        format={num => `$${num}`}
                        parse={str => str.replace(/^\$/, "")}
                        onChange={value => {
                            let keywords = props.updatedKeywords.map(keyword => Object.assign({}, keyword));
                            keywords[cellInfo.index][cellInfo.column.id] = value;
                            props.setUpdatedKeywords(keywords);
                        }}
                    />
                </InputGroup>
            );
        } else return `$ ${cellInfo.value.toFixed(2)}`;
    }

    const updateToSuggestedBid = (cellInfo, whichBid, newBid) => {
        let keywords = props.updatedKeywords.map(keyword => Object.assign({}, keyword));

        if (cellInfo.original.whichBid === whichBid) {
            delete keywords[cellInfo.index]['whichBid'];
        } else {
            keywords[cellInfo.index]['whichBid'] = whichBid;
            // keywords[cellInfo.index]['bidPrice'] = newBid;
        }

        props.setUpdatedKeywords(keywords);
    }

    const simpleViewColumns = [
        {
            Header: 'Keyword Text',
            accessor: 'keywordText',
            width: 300,
        },
        {
            Header: 'Match Type',
            accessor: 'matchType',
            // Cell: props => props.value === "phrase" ?
            //     "phrase-match" : props.value === "exact" ?
            //         "exact-match" : "unavailable",
            Cell: props => props.value ? props.value : "unavailable",
        },
        {
            Header: 'Bid Price',
            accessor: 'bidPrice',
            Cell: props => props.value === null ? "unavailable" : editableBidPrice(props),
        },
    ];

    const columns = [
        {
            Header: 'Keyword Info',
            columns: [
                {
                    Header: 'Keyword Text',
                    accessor: 'keywordText',
                    width: 300,
                },
                {
                    Header: 'Match Type',
                    accessor: 'matchType',
                    // Cell: props => props.value === "phrase" ?
                    //     "phrase-match" : props.value === "exact" ?
                    //         "exact-match" : "unavailable",
                    Cell: props => props.value ? props.value : "unavailable",
                },
                {
                    Header: 'Bid Price',
                    accessor: 'bidPrice',
                    Cell: props => props.value === null ? "unavailable" : editableBidPrice(props),
                },
                {
                    Header: 'Enabled',
                    accessor: 'selected',
                    Cell: props => selectedCheckbox(props),
                    // width: 75,
                },
            ]
        },
        {
            Header: 'Keyword Analytics',
            columns: [
                {
                    Header: 'Sales',
                    accessor: 'sales',
                    Cell: props => props.value !== undefined ? `$ ${props.value.toFixed(2)}` : 'unavailable',
                },
                {
                    Header: 'Spend',
                    accessor: 'spend',
                    Cell: props => props.value !== undefined ? `$ ${props.value.toFixed(2)}` : 'unavailable',
                    // width: 75,
                },
                // {
                //     Header: 'Clicks',
                //     accessor: 'clicks',
                //     // width: 65,
                // },
                // {
                //     Header: 'Impressions',
                //     accessor: 'impressions',
                // },
                {
                    Header: 'Conversions',
                    accessor: 'conversions',
                },
            ],
        },
        {
            Header: 'Suggested Bids',
            columns: [
                {
                    Header: 'Low',
                    accessor: 'minSuggestedBid',
                    Cell: cellInfo => props.isEditActive ?
                        <Button
                            outline
                            size="sm"
                            color="success"
                            active={cellInfo.original.whichBid === 'lowBid'}
                            onClick={() => updateToSuggestedBid(cellInfo, 'lowBid', cellInfo.value)}
                        >
                            $ {cellInfo.value.toFixed(2)}
                        </Button> :
                        `$ ${cellInfo.value.toFixed(2)}`,
                },
                {
                    Header: 'Medium',
                    accessor: 'midSuggestedBid',
                    Cell: cellInfo => props.isEditActive ?
                        <Button
                            outline
                            size="sm"
                            color="success"
                            active={cellInfo.original.whichBid === 'medBid'}
                            onClick={() => updateToSuggestedBid(cellInfo, 'medBid', cellInfo.value)}
                        >
                            $ {cellInfo.value.toFixed(2)}
                        </Button> :
                        `$ ${cellInfo.value.toFixed(2)}`,
                },
                {
                    Header: 'High',
                    accessor: 'maxSuggestedBid',
                    Cell: cellInfo => props.isEditActive ?
                        <Button
                            outline
                            size="sm"
                            color="success"
                            active={cellInfo.original.whichBid === 'highBid'}
                            onClick={() => updateToSuggestedBid(cellInfo, 'highBid', cellInfo.value)}
                        >
                            $ {cellInfo.value.toFixed(2)}
                        </Button> :
                        `$ ${cellInfo.value.toFixed(2)}`,
                },
            ],
        },
    ];

    const productViewColumns = [
        columns[0],
        columns[2],
    ];

    return (
        <Form>
            <ReactTable
                data={props.isEditActive ? props.updatedKeywords : props.data}
                columns={props.simpleView ? simpleViewColumns : props.productKeywords ? productViewColumns : columns}
                style={{
                    height: props.simpleView ? "500px" : "600px",
                }}
                className="-striped -highlight -fixed"
                noDataText="No keywords found"
            />
        </Form>

    );
}

export default KeywordTable;
