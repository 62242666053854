import React, { Fragment } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Can from "../../../../../Components/Can";


const AmazonProductDescriptionEdit = ({ productDescription, openComments, accessLevel, openQuillField, approveOrReviseProject, isFinalCompleted }) => {

    const createMarkup = (html) => {
        return { __html: html };
    }

    const openCommentsModal = () => {
        openComments(productDescription, undefined)
    }


    const getPrdDesc = () => {
        return (
            <Fragment>
                {
                    (productDescription && productDescription.field_value) &&
                    <div className="d-inline-block">
                        <p className="features" dangerouslySetInnerHTML={createMarkup(productDescription.field_value)} />
                    </div>
                }
            </Fragment>
        )
    }


    return (
        <Fragment>
            <h4 className="heading">Product Description {
                (productDescription && productDescription.approved) && <span className="green-badge green-badge-approve"><i className="fas fa-check-circle badge-green-icon" />  Approved</span>}
                {(productDescription && productDescription.status === 'REVISED') && <span className="badge badge-light-danger capitalize"><i className="fas fa-undo-alt badge-light-danger" />  Revision Needed</span>}

                {
                    productDescription ? <span className="d-inline-block">
                        {
                            !isFinalCompleted &&
                            <Fragment>
                                <Can
                                    role={accessLevel}
                                    perform={"project:edit"}
                                    yes={() => (
                                        !productDescription.approved &&
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="link">
                                                <i className="fas fa-ellipsis-v"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {
                                                    (productDescription.status === 'REVISED') &&
                                                    <DropdownItem onClick={() => openCommentsModal()} title=" Add Comments">
                                                        <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                    </DropdownItem>
                                                }

                                                {(!productDescription.approved) &&
                                                <Fragment>
                                                    <DropdownItem onClick={() => openQuillField(productDescription)} title="Edit Product Description">
                                                        <i className="fas fa-edit blue mr-2"></i> {' '} Edit Product Description

                                                    </DropdownItem>
                                                </Fragment>

                                                }


                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                />
                                {/*{*/}
                                {/*    (accessLevel === 0 && !productDescription.approved) &&*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle color="link">*/}
                                {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu right>*/}
                                {/*            {*/}
                                {/*                (productDescription.status === 'REVISED') &&*/}
                                {/*                <DropdownItem onClick={() => openCommentsModal()} title=" Add Comments">*/}
                                {/*                    <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                {/*                </DropdownItem>*/}
                                {/*            }*/}

                                {/*            {(!productDescription.approved) &&*/}
                                {/*                <Fragment>*/}
                                {/*                    <DropdownItem onClick={() => openQuillField(productDescription)} title="Edit Product Description">*/}
                                {/*                        <i className="fas fa-edit blue mr-2"></i> {' '} Edit Product Description*/}

                                {/*                    </DropdownItem>*/}
                                {/*                </Fragment>*/}

                                {/*            }*/}


                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}

                                {/*}*/}
                                <Can
                                    role={accessLevel}
                                    perform={"project:review"}
                                    yes={() => (
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="link">
                                                <i className="fas fa-ellipsis-v"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <Fragment>
                                                    {!productDescription.approved
                                                    &&
                                                    <DropdownItem onClick={() => approveOrReviseProject(productDescription, 'APPROVED', undefined)} title="Approve">
                                                        <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                    </DropdownItem>
                                                    }
                                                    {
                                                        productDescription.status !== 'REVISED' &&
                                                        <DropdownItem onClick={() => openComments(productDescription, undefined, true)} title="Revise">
                                                            <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                        </DropdownItem>
                                                    }
                                                </Fragment>



                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                />
                                {/*{*/}
                                {/*    accessLevel === 3 &&*/}
                                {/*    <UncontrolledDropdown>*/}
                                {/*        <DropdownToggle color="link">*/}
                                {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu right>*/}
                                {/*            <Fragment>*/}
                                {/*                {!productDescription.approved*/}
                                {/*                    &&*/}
                                {/*                    <DropdownItem onClick={() => approveOrReviseProject(productDescription, 'APPROVED', undefined)} title="Approve">*/}
                                {/*                        <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                {/*                    </DropdownItem>*/}
                                {/*                }*/}
                                {/*                {*/}
                                {/*                    productDescription.status !== 'REVISED' &&*/}
                                {/*                    <DropdownItem onClick={() => openComments(productDescription, undefined, true)} title="Revise">*/}
                                {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                {/*                    </DropdownItem>*/}
                                {/*                }*/}
                                {/*            </Fragment>*/}



                                {/*        </DropdownMenu>*/}
                                {/*    </UncontrolledDropdown>*/}
                                {/*}*/}
                            </Fragment>

                        }

                    </span>

                        :
                        <span className="mx-2">
                            <Can
                                role={accessLevel}
                                perform={"project:edit"}
                                yes={() => (
                                    !isFinalCompleted &&
                                    <Button type="button" color="primary" className="btn-sm f07" onClick={() => {

                                        openQuillField(productDescription)
                                    }}><i className="fas fa-plus-circle"></i> Add Product Description</Button>
                                )}
                            />
                            {/*{*/}
                            {/*    (accessLevel === 0 && !isFinalCompleted) &&*/}
                            {/*    <Button type="button" color="primary" className="btn-sm f07" onClick={() => {*/}

                            {/*        openQuillField(productDescription)*/}
                            {/*    }}><i className="fas fa-plus-circle"></i> Add Product Description</Button>*/}
                            {/*}*/}

                        </span>
                }
            </h4>
            {
                getPrdDesc()
            }
        </Fragment>
    )
}

export default AmazonProductDescriptionEdit;
