import React, { Fragment } from 'react';
import { UncontrolledTooltip } from 'reactstrap';


/**
 * Wrapper for UncontrolledTooltip component to specifically render a Help tooltip.
 * This component renders a question mark icon which, on hover, displays a message.
 * @param target - id of the component that this tooltip provides clarification for
 * @param className - the className prop that is passed onto the div component that contains the icon component.
 * @param style - the style object prop passed to the div component wrapping the icon.
 * @param iconStyle - the style object prop passed to the icon component.
 * @param props - the remaining props passed to the UncontrolledTooltip component (refer to reactstrap docs).
 */
function HelpTooltip({ target, className, style, iconStyle, ...props }) {
    return (
        <Fragment>
            <div id={target} className={className} style={style}>
                <i className="pe-7s-help1" style={iconStyle} />
            </div>
            <UncontrolledTooltip className="tooltip-light" placement={props.placement} target={target}>
                {props.children}
            </UncontrolledTooltip>
        </Fragment>

    );
}

export default HelpTooltip;
