import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";
import actionType from '../../../../Redux/Actions/ActionTypes';
import CampaignOverviewService from "./CampaignOverviewService";


function EditKeywordService(token, addWords, activateWords, editWords, suggestedBidWords) {
    return function(dispatch) {
        dispatch({ type: actionType.KEYWORD_UPDATE_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/updateCampaignLive`, {
            addWords: addWords,
            activateWords: activateWords,
            editWords: editWords,
            suggestedBidWords: suggestedBidWords,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch({ type: actionType.KEYWORD_UPDATE_SUCCESS });
                notify.NotifySuccess("Your keyword changes were updated successfully!");
                // dispatch(CampaignOverviewService(token, name, collectionID));
            })
            .catch(() => {
                dispatch({ type: actionType.KEYWORD_UPDATE_FAILED });
                notify.NotifyError("We've encountered an error updating your keyword changes. Please try again later.");
            })
    }
}

export default EditKeywordService;