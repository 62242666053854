import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";

import DashboardSidebar from "./Sidebar/DashboardSidebar";
import PageTitle from "./PageTitle";
import DashboardFooter from "./Footer/DashboardFooter";


function DashboardLayout(props) {

    let location = useLocation();
    let history = useHistory();

    const [menuURLs, setMenuURLs] = useState([]);

    useEffect(() => {
        setMenuURLs(Array.from(props.menuItems, item => item.to));
    }, [props.menuItems]);

    const currentPageIndex = menuURLs.indexOf(location.pathname);
    const hasNextPage = (currentPageIndex > -1) && (currentPageIndex < menuURLs.length - 1);
    const hasPreviousPage = (currentPageIndex > 0)

    const nextPage = () => {
        const nextPageIndex = currentPageIndex + 1;
        if (nextPageIndex === menuURLs.length) return;
        history.push(menuURLs[nextPageIndex]);
    }

    const previousPage = () => {
        const previousPageIndex = currentPageIndex - 1;
        if (previousPageIndex < 0) return;
        history.push(menuURLs[previousPageIndex]);
    }

    return (
        <Fragment>
            <div className="app-main">
                <DashboardSidebar menuItems={props.menuItems} heading={props.heading} />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {/* <PageTitle
                            heading={props.heading}
                            subheading={props.subheading}
                            icon={props.icon}
                        > */}
                            {/*<div className="page-title-actions">*/}
                            {/*    <Button*/}
                            {/*        className="mr-2 btn-shadow btn-icon btn-icon-only"*/}
                            {/*        color="link"*/}
                            {/*        size="lg"*/}
                            {/*        style={{marginLeft: 'auto', padding: 0}}*/}
                            {/*        onClick={previousPage}*/}
                            {/*        disabled={!hasPreviousPage}*/}
                            {/*    >*/}
                            {/*        <i*/}
                            {/*            className="lnr-arrow-left-circle btn-icon-wrapper"*/}
                            {/*            style={{fontSize: '40px'}}*/}
                            {/*        >*/}
                            {/*        </i>*/}
                            {/*    </Button>*/}
                            {/*    <Button*/}
                            {/*        className="mr-2 btn-shadow btn-icon btn-icon-only"*/}
                            {/*        color="link"*/}
                            {/*        size="lg"*/}
                            {/*        style={{marginLeft: 'auto', padding: 0}}*/}
                            {/*        onClick={nextPage}*/}
                            {/*        disabled={!hasNextPage}*/}
                            {/*    >*/}
                            {/*        <i*/}
                            {/*            className="lnr-arrow-right-circle btn-icon-wrapper"*/}
                            {/*            style={{fontSize: '40px'}}*/}
                            {/*        >*/}
                            {/*        </i>*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        {/* </PageTitle> */}
                        {props.children}
                    </div>
                    <DashboardFooter />
                </div>
            </div>
        </Fragment>
    );
}

export default DashboardLayout;
