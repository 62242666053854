import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Input, Label, Row } from "reactstrap";
import GetWorkflowTaskService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowTaskService";
import GetWorkflowMainCategoryService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowMainCategoryService";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faWindowClose,
  faTimes,
  faEye, faPaperclip, faDownload, faCheck
} from "@fortawesome/free-solid-svg-icons";
import GetTaskHistoryDataService from "../../../../../../../Service/OPSServices/WorkflowsService/GetTaskHistoryDataService";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import ReactTable from "react-table";
import SaveTaskDetailsService from "../../../../../../../Service/OPSServices/WorkflowsService/SaveTaskDetailsService";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor, SetInnerHtmlWithClassReplacement } from '../../../../../../../Utils/Constants';

function TaskListDetailsPriviewModeComponents(props) {
  const [isOpneDetails, setIsOpneDetails] = useState(false);

  const [taskList, setTaskList] = useState();
  const [uploadFileOpen, setUploadFileOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [taskDetails, setTaskDetails] = useState();
  const [isHistoryPopup, setIsHistoryPopup] = useState(false);
  const [historyListDetails, setHistoryListDetails] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filePath, setFilePath] = useState();
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)
  const [member, setMember] = useState()
  const [status, setStatus] = useState('')
  const [flag, setFlag] = useState(0)
  const [comment, setComment] = useState("");
  const [prevComment, setPrevComment] = useState("");
  const [loading1, setLoading1] = useState(false)
  const [satusLoading, setSatusLoading] = useState(false)
  const [assignedLoading, setAssingedLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [latestComment, setLatestComment] = useState(false);
  const [latestCommentPopup, setLatestCommentPopup] = useState(false);
  const [fileList, setFileList] = useState([])
  const [isOpenFilePopup, setIsOpenFilePopup] = useState(false);
  const [isOpenFileListPopup, setIsOpenFileListPopup] = useState(false);
  const [openCommentsPopup,setOpenCommentsPopup]=useState(false) 
  const [fullComment,setFullComment]=useState('')

  const handleTaksDetails = (task) => {
    setIsOpneDetails(true);
    setTaskDetails(task)
    setUploadFileOpen(false)
    setComment(task.answerObject[0] && task.answerObject[0].memo ? task.answerObject[0].memo : '')
    setPrevComment(task.answerObject[0] && task.answerObject[0].memo ? task.answerObject[0].memo : '')
    setSelectedFiles([])
    console.log(task)
    fetchTaskHistory(task)
    setTimeout(() => {
      setLoading1(false)
    }, 300)
  };


  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    setFlag(1);
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleFileChange = (e) => {
    setFlag(1);
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const fetchTaskHistory = (taskDetails) => {
    setHistoryListDetails([])
    let data = {
      taskId: taskDetails.id,
      integerId: props.selectedStoreData.integerId,
      workflowMasterId: props.selectedWorkflowMasterId,
      workflowPhaseId: taskDetails.WorkflowPhases
    }

    GetTaskHistoryDataService(props.token, data).then((res) => {
      if (res) {
        console.log(res)
        setHistoryListDetails(res.historyList)
      }
    })
    // setIsHistoryPopup(true)
  };
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
  const flattenedFileList = fileList && fileList.flatMap(item => item);
  const viewIssueDescription=(value)=>{
    setOpenCommentsPopup(true)
    setFullComment(value)
  }
  const columns = [
    {
      Header: "Date",
      accessor: "createdAt_formated",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Comments",
      accessor: "memo",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p  className="m-0 comment-paragraph"
           style={{ color: "#3D85C6", cursor: "pointer" }}
           onClick={() =>
             viewIssueDescription(
               cellInfo.value,
             )}>{stripHtmlTags(cellInfo.value)}</p>
        </div>
      ),
    },
    {
      Header: "Assigned to",
      accessor: "executorName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "createdByUserName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
  ]
  const handleStatus = (e) => {
    setFlag(1);
    setStatus(e.target.value)
  };
  const handleAssigned = (e) => {
    setFlag(1);
    setMember(e.target.value)
  }
  const saveTaskDetails = (type, value, taskDetails) => {
    if (!taskDetails) {
      return true;
    }
    let data;
    if (type == 'assignedTo') {
      data = {
        taskId: taskDetails.id,
        taskText: 'assignedTo',
        workflowMasterId: props.selectedWorkflowMasterId,
        assignedTo: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
        workflowPhaseId: taskDetails.WorkflowPhases,
        integerId: props.selectedStoreData.integerId,
        status: taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
        attachmentFile: []
      }
    }
    else if (type == 'status') {
      data = {
        taskId: taskDetails.id,
        taskText: 'status',
        workflowMasterId: props.selectedWorkflowMasterId,
        assignedTo: taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
        workflowPhaseId: taskDetails.WorkflowPhases,
        integerId: props.selectedStoreData.integerId,
        status: value ? value : taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
        attachmentFile: []
      }
    }
    else {
      return true
    }

    SaveTaskDetailsService(props.token, data).then((res) => {
      if (res.status) {
        console.log(res)
        setTaskDetails()
        props.setTaskList(res.tasklist.tasklist)
        setStatus('')
        setFlag(0)
        setMember()
        setComment('')
        setSelectedFiles([])
        setTaskList(res.tasklist.tasklist)
        props.setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
        props.setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
        setAssingedLoading()
        setSatusLoading()

      }
    })
  }
  const saveAllTaskDetails = () => {
    setLoadingButton(true)
    if (flag === 0) {
      return true;
    }
    let data;
    const formData = new FormData();
    if (selectedFiles.length > 0) {

      formData.append("taskId", taskDetails.id);
      formData.append("taskText", (comment != prevComment) ? comment : 'Task has been updated.');
      formData.append("workflowMasterId", props.selectedWorkflowMasterId);
      formData.append("workflowPhaseId", taskDetails.WorkflowPhases);
      formData.append("integerId", props.selectedStoreData.integerId);
      formData.append("assignedTo", member ? member : taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0);

      formData.append("status", status ? status : taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0);
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("attachmentFile", selectedFiles[i]);
      }
      // console.log("formDataFile" + JSON.stringify(formData))
      // return true
      SaveTaskDetailsService(props.token, formData).then((res) => {
        if (res.status) {
          console.log(res)
          setComment('')
          setStatus('')
          setFlag(0)
          setSelectedFiles([])
          props.setTaskList(res.tasklist.tasklist)
          setTaskList(res.tasklist.tasklist)
          props.setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
          props.setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
          setLoadingButton(false)
          setIsOpneDetails(false)
          // props.handleBackTaskList()
        }
      })
    }
    else {
      data = {
        taskId: taskDetails.id,
        taskText: (comment != prevComment) ? comment : 'Task has been updated.',
        workflowMasterId: props.selectedWorkflowMasterId,
        assignedTo: member ? member : taskDetails.answerObject[0] ? taskDetails.answerObject[0].executor : 0,
        workflowPhaseId: taskDetails.WorkflowPhases,
        integerId: props.selectedStoreData.integerId,
        status: status ? status : taskDetails.answerObject[0] ? taskDetails.answerObject[0].status : 0,
        attachmentFile: []
      }
      // console.log(data)
      // return true
      SaveTaskDetailsService(props.token, data).then((res) => {
        if (res.status) {
          console.log(res)
          setStatus('')
          setFlag(0)
          setComment('')
          setSelectedFiles([])
          setTaskList(res.tasklist.tasklist)
          props.setTaskList(res.tasklist.tasklist)
          props.setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
          props.setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
          setLoadingButton(false)
          setIsOpneDetails(false)
          // props.handleBackTaskList()
        }
      })
    }


  }
  const handleStatusChange = (e, task) => {
    setStatus(e.target.value)
    saveTaskDetails('status', e.target.value, task)
  };
  const handleAssignedToChange = (e, task) => {
    setMember(e.target.value)
    saveTaskDetails('assignedTo', e.target.value, task)
  }
  const filteredTasktHistory = historyListDetails.filter((row) => {
    const historyComment = row.memo.toLowerCase();
    const tasktHistoryCreateBy = (row.createdByUserName || '').toLowerCase();
    const taskHistoryStatus = (row.status || '').toLowerCase();
    const taskAssinged = (row.executorName || '').toLowerCase();
    return historyComment.includes(searchText.toLowerCase()) || tasktHistoryCreateBy.includes(searchText.toLowerCase()) || taskHistoryStatus.includes(searchText.toLowerCase()) || taskAssinged.includes(searchText.toLowerCase());
  });
  const downloadFile = async (file) => {
    console.log(file)
    if (true) {
      try {
        let fileName = file.filePath.split('images/')[1]
        fileName = fileName.split('.')[0]
        const response = await fetch(file.filePath);
        if (!response.ok) throw new Error(`Network response was not ok for ${file.id}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'downloaded_file';
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Failed to download files:', error);
      }
    }
  }
  const show = (path) => {
    const separatedPath = path.split("images/");
    console.log("first");
    console.log(separatedPath[1]);
    const ext = separatedPath[1].split(".")[1];
    console.log(ext);
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
  // const handleTextChange = (e) => {
  //   setComment(e.target.value)
  //   setFlag(1);
  // };
  const handleQuillChange=(value)=>{
    setComment(value)
    setFlag(1)
  }
  const viewLatestComment = (text) => {
    setLatestCommentPopup(true);
    setLatestComment(text);
  };
  const showFilePopUp = (filelist) => {
    setFileList(filelist)
    if (filelist.length <= 1) {
      filelist.map((files) => {
        if (files.length <= 1) {

          showFile(files[0].filePath)
        }
        else {
          files.map((file) => {
            const separatedPath = file.filePath.split("images/");
            console.log("first");
            console.log(separatedPath[1]);
            const ext = separatedPath[1].split(".")[1];
            console.log(ext);
            if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
              setIsOpenFileListPopup(true)
              setTimeout(() => {
                setIsOpenFilePopup(false);
              }, 300);
            }
            setIsOpenFileListPopup(true)
          })
        }
      })
    }
    else {
      setIsOpenFileListPopup(true)
    }
    console.log(filelist)


  }
  const showFile = (path) => {
    const separatedPath = path.split("images/");
    console.log("first");
    console.log(separatedPath[1]);
    const ext = separatedPath[1].split(".")[1];
    console.log(ext);
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path)
      setIsOpenFilePopup(true)
      setTimeout(() => {
        setIsOpenFilePopup(false);
      }, 300);
    }
    setFilePath(path)
    setIsOpenFilePopup(true)

  }
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const replaceLatestComment = SetInnerHtmlWithClassReplacement(latestComment);
  const gridComments = SetInnerHtmlWithClassReplacement(fullComment);

  return (
    <>
      {!isOpneDetails ? (
        <Card className=" card-min-height-workflows p-2 card-of-problemarea">
          <Col md={12} className="m-3">
            <h5>
              <b>All Tasks</b>
            </h5>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Task</th>
                  <th scope="col">Status</th>
                  <th scope="col">Assigned To</th>
                  <th scope="col">Last Update</th>
                  <th scope="col">Latest Comment</th>
                  {/* <th scope="col">Attachment</th> */}
                </tr>
              </thead>
              <tbody>
                {
                  props.workflowPhases && props.workflowPhases.map((wp) => (
                    <>{
                      wp.WorkflowsMaster == props.selectedWorkflowMasterId
                        ? <>
                          <h6 className="mt-2 mb-2"><b>{wp.name}</b></h6>

                          {props.taskList &&
                            props.taskList.map((task) => (
                              <tr key={task.taskId} className="p-3">
                                {task.WorkflowPhases === wp.id && (
                                  <>
                                    <td
                                      style={{ cursor: "pointer", width: "25%" }}
                                      onClick={() => handleTaksDetails(task)}
                                    >
                                      <a href="#">{task.taskText}</a>
                                    </td>

                                    <td style={{ width: '20%' }}>
                                      {satusLoading === task.id ? (
                                        <p className="mb-0" style={{ color: '#81e94b' }}>Updating..</p>
                                      ) : (
                                        <>
                                          {task.answerObject[0] && task.answerObject[0].status === 6 ? (
                                            <div className="mt-1">
                                              <span className="signedoff-dropdown"> Signed Off <FontAwesomeIcon style={{ color: '#fff' }} icon={faCheck} />  </span>
                                              <p className="ml-2 mt-2 mb-0">{task.answerObject[0].updatedByName && task.answerObject[0].updatedByName.split(' ')[0] + ' ' + task.answerObject[0].updatedByName.split(' ')[1][0] + '.'}</p>
                                            </div>
                                          ) : (
                                            <select
                                              className={`form-control ${task.answerObject[0] ?
                                                task.answerObject[0].status === 3 ? 'inprogress-dropdown' :
                                                  task.answerObject[0].status === 5 ? 'completed-dropdown' :
                                                    task.answerObject[0].status === 2 ? 'notstarted-dropdown' :
                                                      task.answerObject[0].status === 1 ? 'notapplicable-dropdown' :
                                                        task.answerObject[0].status === 4 ? 'postpond-dropdown' :
                                                          '' : ''}`}
                                              name="status"
                                              id="status"
                                              onChange={(e) => { handleStatusChange(e, task); setSatusLoading(task.id); }}
                                            >
                                              <option style={{ background: '#fff' }} value={0}>-- Select status --</option>
                                              {props.statusList &&
                                                props.statusList.map((status) => (
                                                  <option
                                                    style={{ background: '#fff' }}
                                                    key={status.id} value={status.id} selected={status.id === (task.answerObject[0] && task.answerObject[0].status) ? true : false}
                                                  >
                                                    {status.status}
                                                  </option>
                                                ))}
                                            </select>
                                          )}
                                        </>
                                      )}
                                    </td>

                                    <td style={{ width: '20%' }}>
                                      {task.answerObject[0] && task.answerObject[0].status === 6 ? (
                                        <h6 className="ml-2">{task.answerObject[0].executorName}</h6>
                                      ) : (
                                        <>
                                          {assignedLoading === task.id ? (
                                            <p className="mb-0" style={{ color: '#81e94b' }}>Updating..</p>
                                          ) : (
                                            <select
                                              className="form-control"
                                              name="assignedto"
                                              id="assignedto"
                                              onChange={(e) => { handleAssignedToChange(e, task); setAssingedLoading(task.id); }}
                                            >
                                              <option value={0}>-- Assigned to --</option>
                                              {props.teamMembers &&
                                                props.teamMembers.map((member) => (
                                                  <option key={member.employeeId} value={member.employeeId} selected={member.employeeId === (task.answerObject[0] && task.answerObject[0].executor) ? true : false}>
                                                    {member.employeeName}
                                                  </option>
                                                ))}
                                            </select>
                                          )}
                                        </>
                                      )}
                                    </td>

                                    <td style={{ width: '20%' }}>{task.lastModified}</td>

                                    <td style={{ width: '20%' }}>
                                      <p
                                        className="m-0 comment-paragraph-workflows"
                                        style={{ color: "#3D85C6", cursor: "pointer" }}
                                        onClick={() => viewLatestComment(task.answerObject[0] && task.answerObject[0].memo)}
                                      >
                                        {stripHtmlTags(task.answerObject[0] && task.answerObject[0].memo)}
                                      </p>
                                    </td>

                                    <td style={{ width: '20%' }}>
                                      <FontAwesomeIcon
                                        className="mr-2"
                                        // style={{ cursor: 'pointer' }}
                                        title={task.attachmentList.length == 0 ? 'File not available' : 'Open file'}
                                        icon={faPaperclip}
                                        style={task.attachmentList.length == 0 ? { color: '#c5c3c3' } : { cursor: 'pointer' }}
                                        disabled={task.attachmentList.length == 0 ? true : false}
                                        onClick={() => showFilePopUp(task.attachmentList)}
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>

                            ))}
                        </> : ''
                    }</>
                  ))
                }
              </tbody>
            </table>
          </Col>
        </Card>
      ) : (
        <Card className=" p-4 card-of-problemarea">
          <Row>
            <Col md={5}>

              {
                props.workflowPhases && props.workflowPhases.map((wp) => (
                  <>{
                    wp.WorkflowsMaster == props.selectedWorkflowMasterId
                      ? <>
                        <h5><b>{wp.name}</b></h5>
                        <hr className="mt-0"></hr>
                        <ul>
                          {props.taskList &&
                            props.taskList.map((task) => (
                              <>
                                {task.WorkflowPhases == wp.id ?
                                  <li
                                    style={{ cursor: "pointer" }}
                                    className='mb-2'
                                    onClick={() => { handleTaksDetails(task) }}
                                  >
                                    {taskDetails.taskText == task.taskText ? <a href="#"> <b><u>{task.taskText}</u></b></a> : task.taskText}
                                  </li>
                                  : ''}</>
                            ))}
                        </ul>
                      </> : ''
                  }</>
                ))
              }
            </Col>
            <Col md={7}>
              <LoadingOverlay
                active={loading1}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 1,
                  }),
                  content: (base) => ({
                    ...base,
                    color: '#000',
                  }),
                }}
                spinner={<Loader active type="ball-pulse" />}
                text="Loading ..."
              >
                {taskDetails.answerObject[0] && taskDetails.answerObject[0].status == 6 ?
                  <Card className="p-4 card-of-problemarea">
                    <Row>
                      <Col md={9} className='pl-0'>
                        <h6 className="ml-3">
                          <b>{taskDetails.taskText}</b>
                        </h6>
                      </Col>
                      <Col md={3}>
                        <h6 className="d-flex justify-content-end">
                          {/* <Button
                            onClick={() => fetchTaskHistory()}
                            className="btn-background-color"
                            title="History"
                          >History</Button>&nbsp; */}
                          <Button
                            onClick={() => { setIsOpneDetails(false); setUploadFileOpen(false) }}
                            className="info-button-background-none"
                            title="Close"
                          >
                            <FontAwesomeIcon style={{ color: "#000", }}
                              icon={faTimes}
                            />
                          </Button>
                        </h6>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col md={4}>
                        <h6 >
                          <b>Status</b>
                        </h6>
                        <p className="signedoff-dropdown "> Signed Off &nbsp;<FontAwesomeIcon style={{ color: '#fff', float: 'right', marginTop: '2px' }} icon={faCheck} />
                        </p>
                      </Col>
                      <Col md={4}>
                        <h6>
                          <b>Assigned To</b>
                        </h6>
                        <p>{taskDetails.answerObject[0].executorName}</p>
                      </Col>
                      <Col md={4}>
                        <h6>
                          <b>Last Updated</b>
                        </h6>
                        <p>{taskDetails.lastModified}</p>

                      </Col>
                      <Col md={12}>
                        <h6 >
                          <b>Last Updated By : </b> <span>{taskDetails.answerObject[0].updatedByName && taskDetails.answerObject[0].updatedByName.split(' ')[0] + ' ' + taskDetails.answerObject[0].updatedByName.split(' ')[1][0] + '.'}</span>
                        </h6>
                      </Col>
                    </Row>
                    <Row className='p-3, mt-4'>
                      <Col md={12}>
                        <h5><b>EXECUTION HISTORY </b></h5>
                      </Col>
                      <Col md={12}>
                        <Input
                          type='text'
                          className='w-50'
                          placeholder='Search history'
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </Col>
                      <Col md={12}>
                        <ReactTable
                          data={filteredTasktHistory}
                          columns={columns}
                          className="-fixed -highlight -striped mt-2"
                          getTheadProps={() => {
                            return {
                              style: {
                                overflowY: "hidden",
                                background: "#B1FA63",
                                color: "#243837",
                              },
                            };
                          }}
                          defaultPageSize={5}
                          SubComponent={(row) => (
                            <div className="p-1">
                              {console.log(row.original.files)}
                              {row.original.files.length > 0 ? (
                                <ReactTable
                                  data={row.original.files}
                                  columns={[
                                    {
                                      Header: "Name",
                                      accessor: "actualFileName",
                                      Cell: (cellInfo) => (
                                        <div className="d-flex justify-content-center w-100">
                                          {cellInfo.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: "Action",
                                      accessor: "filePath",
                                      Cell: (cellInfo) => (
                                        <div className="d-flex justify-content-center w-100">
                                          {
                                            // fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                            fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                                              <FontAwesomeIcon
                                                className="ml-3"
                                                style={{ cursor: 'pointer' }}
                                                icon={faDownload}
                                                onClick={() => downloadFile(cellInfo.original)}
                                              />
                                            ) : (
                                              <>
                                                <FontAwesomeIcon
                                                  className="ml-3"
                                                  style={{ cursor: 'pointer' }}
                                                  icon={faEye}
                                                  onClick={() => show(cellInfo.value)}
                                                />
                                                <FontAwesomeIcon
                                                  className="ml-3"
                                                  style={{ cursor: 'pointer' }}
                                                  icon={faDownload}
                                                  onClick={() => downloadFile(cellInfo.original)}
                                                />
                                              </>
                                            )
                                          }
                                        </div>
                                      ),
                                    },
                                  ]}
                                  getTheadProps={() => {
                                    return {
                                      style: {
                                        overflowY: "hidden",
                                        background: "#B1FA63",
                                        color: "#243837",
                                      },
                                    };
                                  }}
                                  className="-striped -highlight"
                                  defaultPageSize={row.original.files.length}
                                  noDataText="No Attachments available"
                                />
                              ) : (
                                <p className="text center">No Attachments.</p>
                              )}
                            </div>
                          )}
                          noDataText={"No Data Found."}
                        />
                      </Col>
                    </Row>
                  </Card> :
                  <Card className="p-4 card-of-problemarea">
                    <Row>
                      <Col md={9} className='pl-0'>
                        <h6 className="ml-3">
                          <b>{taskDetails.taskText}</b>
                        </h6>
                      </Col>
                      <Col md={3}>
                        <h6 className="d-flex justify-content-end">
                          {/* <Button
                            onClick={() => fetchTaskHistory()}
                            className="btn-background-color"
                            title="History"
                          >History</Button>&nbsp; */}

                          <Button
                            onClick={() => setIsOpneDetails(false)}
                            className="info-button-background-none"
                            title="Close"
                          >
                            <FontAwesomeIcon style={{ color: "#000", }}
                              icon={faTimes}
                            />
                          </Button>
                        </h6>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                      <Col md={4}>
                        <h6 >
                          <b>Status</b>
                        </h6>
                        {
                          taskDetails.answerObject[0] && taskDetails.answerObject[0].status == 6 ?

                            <p className="signedoff-dropdown "> Signed Off &nbsp;<FontAwesomeIcon style={{ color: '#fff', float: 'right', marginTop: '2px' }} icon={faCheck} />  </p>
                            :
                            <select
                              className={` ${taskDetails.answerObject[0] ?
                                taskDetails.answerObject[0].status == 3 ? 'inprogress-dropdown form-control' :
                                  taskDetails.answerObject[0].status == 5 ? 'completed-dropdown form-control' :
                                    taskDetails.answerObject[0].status == 2 ? 'notstarted-dropdown form-control' :
                                      taskDetails.answerObject[0].status == 1 ? 'notapplicable-dropdown form-control' :
                                        taskDetails.answerObject[0].status == 4 ? 'postpond-dropdown form-control' :
                                          'form-control' : 'form-control'}`}
                              onChange={(e) => handleStatus(e)}
                              name="status"
                              id="status"
                            >
                              <option style={{ background: '#fff' }}>-Select Status-</option>
                              {props.statusList &&
                                props.statusList.map((status) => (
                                  <option style={{ background: '#fff' }} key={status.id} value={status.id} selected={status.id === (taskDetails.answerObject[0] && taskDetails.answerObject[0].status) ? true : false}>
                                    {status.status}
                                  </option>
                                ))}

                            </select>
                        }

                      </Col>
                      <Col md={4}>
                        <h6>
                          <b>Assigned To</b>
                        </h6>
                        <select
                          className="form-control"
                          name="assignedto"
                          id="assignedto"
                          onChange={(e) => handleAssigned(e)}
                        >
                          <option value={0}>-- Assigned to --</option>
                          {props.teamMembers &&
                            props.teamMembers.map((member) => (
                              <option key={member.employeeId} value={member.employeeId} selected={member.employeeId === (taskDetails.answerObject[0] && taskDetails.answerObject[0].executor) ? true : false}>
                                {member.employeeName}
                              </option>
                            ))}
                        </select>
                      </Col>
                      <Col md={4}>
                        <h6>
                          <b>Last Updated</b>
                        </h6>
                        <p>{taskDetails.lastModified}</p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={12}>
                        {/* <Input
                          id="Comment"
                          placeholder="Add Your Comments Here."
                          value={comment}
                          rows={3}
                          type="textarea"
                          name="ticketHistoryComment"
                          onChange={(e) => handleTextChange(e)}
                        /> */}
                         <ReactQuill
                              theme={'snow'}
                              modules={Editor.modules}
                              formats={Editor.formats}
                              value={comment}
                              onChange={handleQuillChange}
                              bounds={'.quill-prod'}
                              placeholder="Add Your Comments Here."/>
                      </Col>
                      <Col md={12} className='d-flex mt-3 justify-content-center'>
                        <h6
                          onClick={() => setUploadFileOpen(!uploadFileOpen)}
                          style={{ cursor: 'pointer', textdecoration: 'underline' }}
                        >
                          Upload Files
                          <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            style={{ fontSize: "20px", marginLeft: "10px" }}
                          />
                        </h6>
                      </Col>
                      <Col md={12} className='mt-3'>
                        {uploadFileOpen ? (
                          <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragEnter={handleDragEnter}
                            onDragLeave={handleDragLeave}
                          >
                            <label
                              htmlFor="fileInput"
                              className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""
                                }`}
                            >
                              <h5>
                                <FontAwesomeIcon
                                  icon={faCloudUploadAlt}
                                  style={{ fontSize: "30px" }}
                                />
                              </h5>
                              <h5>Drag &amp; Drop Files </h5>
                              <h5>OR</h5>
                              <input
                                type="file"
                                id="fileInput"
                                name="ticketAttachment"
                                className="custom-brand-file"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              <Button
                                onClick={() =>
                                  document.getElementById("fileInput").click()
                                }
                                style={{ background: "#B2A1FF" }}
                              >
                                Browse Files
                              </Button>
                            </label>
                            <p style={{ fontSize: "13px" }}>Attached File List</p>
                            <ul style={{ color: "green", fontSize: "13px" }}>
                              {selectedFiles.length > 0 ? (
                                selectedFiles.map((file, index) => (
                                  <li key={index}>
                                    {file.name}
                                    <button
                                      className="btn"
                                      onClick={() => handleRemoveFile(file)}
                                    >
                                      <FontAwesomeIcon
                                        color="red"
                                        size="15px"
                                        icon={faWindowClose}
                                      />
                                    </button>
                                  </li>
                                ))
                              ) : (
                                <li>No files attached</li>
                              )}
                            </ul>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="text-center">
                          <Button
                            variant="primary"
                            className="btn-background-color "
                            onClick={() => { saveAllTaskDetails() }}
                            disabled={flag != 0 ? loadingButton ? true : false : true}
                          >
                            {loadingButton ? 'Submitting...' : 'Submit'}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className='p-3, mt-3'>
                      <Col md={12}>
                        <h5><b>EXECUTION HISTORY </b></h5>
                      </Col>
                      <Col md={12}>
                        <Input
                          type='text'
                          className='w-50'
                          placeholder='Search history'
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </Col>
                      <Col md={12}>
                        <ReactTable
                          data={filteredTasktHistory}
                          columns={columns}
                          className="-fixed -highlight -striped mt-2"
                          getTheadProps={() => {
                            return {
                              style: {
                                overflowY: "hidden",
                                background: "#B1FA63",
                                color: "#243837",
                              },
                            };
                          }}
                          defaultPageSize={5}
                          SubComponent={(row) => (
                            <div className="p-1">
                              {console.log(row.original.files)}
                              {row.original.files.length > 0 ? (
                                <ReactTable
                                  data={row.original.files}
                                  columns={[
                                    {
                                      Header: "Name",
                                      accessor: "actualFileName",
                                      Cell: (cellInfo) => (
                                        <div className="d-flex justify-content-center w-100">
                                          {cellInfo.value}
                                        </div>
                                      ),
                                    },
                                    {
                                      Header: "Action",
                                      accessor: "filePath",
                                      Cell: (cellInfo) => (
                                        <div className="d-flex justify-content-center w-100">
                                          {
                                            // fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                            fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                                              <FontAwesomeIcon
                                                className="ml-3"
                                                style={{ cursor: 'pointer' }}
                                                icon={faDownload}
                                                onClick={() => downloadFile(cellInfo.original)}
                                              />
                                            ) : (
                                              <>
                                                <FontAwesomeIcon
                                                  className="ml-3"
                                                  style={{ cursor: 'pointer' }}
                                                  icon={faEye}
                                                  onClick={() => show(cellInfo.value)}
                                                />
                                                <FontAwesomeIcon
                                                  className="ml-3"
                                                  style={{ cursor: 'pointer' }}
                                                  icon={faDownload}
                                                  onClick={() => downloadFile(cellInfo.original)}
                                                />
                                              </>
                                            )
                                          }
                                        </div>
                                      ),
                                    },
                                  ]}
                                  getTheadProps={() => {
                                    return {
                                      style: {
                                        overflowY: "hidden",
                                        background: "#B1FA63",
                                        color: "#243837",
                                      },
                                    };
                                  }}
                                  className="-striped -highlight"
                                  defaultPageSize={row.original.files.length}
                                  noDataText="No Attachments available"
                                />
                              ) : (
                                <p className="text center">No Attachments.</p>
                              )}
                            </div>
                          )}
                          noDataText={"No Data Found."}
                        />
                      </Col>
                    </Row>
                  </Card>
                }

              </LoadingOverlay>
            </Col>
          </Row>
        </Card>
      )}
      <CustomModal
        // header={"Attachment"}
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'filePath': filePath, 'id': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className='curve-popup'
      >
        <div>
          <iframe
            src={
              filePath ? filePath : ''
            }
            title="File Display"
            width="100%"
            height="500"
          ></iframe>
        </div>
      </CustomModal>
      <CustomModal
        header={"Latest Comment"}
        isOpen={latestCommentPopup}
        toggle={() => setLatestCommentPopup(!latestCommentPopup)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setLatestCommentPopup(!latestCommentPopup)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
        <p className="issue-discription-set" dangerouslySetInnerHTML={{__html : replaceLatestComment}}/>
          {/* <p>{latestComment}</p> */}
        </Label>
      </CustomModal>
      <CustomModal
        header={"Task Attachments List"}
        isOpen={isOpenFileListPopup}
        toggle={() => setIsOpenFileListPopup(!isOpenFileListPopup)}
        size={"md"}
        className='curve-popup'
      >
        <Card>
          <ReactTable
            data={flattenedFileList}
            columns={[
              {
                Header: "Name",
                accessor: "actualFileName",
                Cell: (cellInfo) => (
                  <div className="d-flex justify-content-left w-100 text-wrap text-break">
                    {cellInfo.value}
                  </div>
                ),
              },
              {
                Header: "Action",
                accessor: "filePath",
                width:150,
                Cell: (cellInfo) => (
                  <div className="d-flex justify-content-center w-100">
                    {
                      // fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                      fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                        <FontAwesomeIcon
                          className="ml-5"
                          style={{ cursor: 'pointer' }}
                          icon={faDownload}
                          onClick={() => downloadFile(cellInfo.original)}
                        />
                      ) : (
                        <>
                          <FontAwesomeIcon
                            className="ml-3"
                            style={{ cursor: 'pointer' }}
                            icon={faEye}
                            onClick={() => show(cellInfo.value)}
                          />
                          <FontAwesomeIcon
                            className="ml-3"
                            style={{ cursor: 'pointer' }}
                            icon={faDownload}
                            onClick={() => downloadFile(cellInfo.original)}
                          />
                        </>
                      )
                    }
                  </div>
                ),
              },
            ]}
            getTheadProps={() => {
              return {
                style: {
                  overflowY: "hidden",
                  background: "#B1FA63",
                  color: "#243837",
                },
              };
            }}
            className="-striped -highlight"
            defaultPageSize={flattenedFileList.length}
            noDataText="No Attachments available"
          />
        </Card>
      </CustomModal>
      <CustomModal
        // header={"Task Attachments List"}
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'filePath': filePath, 'id': 0 })} />
        </>}
        isOpen={isOpenFilePopup}
        toggle={() => setIsOpenFilePopup(!isOpenFilePopup)}
        size={"lg"}
        className='curve-popup'
      >
        <Card>
          <Col md={12}>
            <iframe
              src={
                filePath ? filePath : ''
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>

          </Col>
        </Card>
      </CustomModal>
      <CustomModal
        header={"Comments"}
        // header={<> Attachment
        //   <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'filePath': filePath, 'id': 0 })} />
        // </>}
        isOpen={openCommentsPopup}
        toggle={() => setOpenCommentsPopup(!openCommentsPopup)}
        size={"lg"}
        className='curve-popup'
      >
        <div>
         <p className="issue-discription-set" dangerouslySetInnerHTML={{__html : gridComments }}/>
          
        </div>
      </CustomModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(TaskListDetailsPriviewModeComponents);
