import React, { useState } from 'react';
import {Button, Card, CardBody, CardHeader, CardTitle, FormGroup, InputGroup} from "reactstrap";
import ParentProductKeywordTable from "../../../../../../Components/Tables/ParentProductKeywordTable";
import NumericInput from "react-numeric-input";
import HelpTooltip from "../../../../../../../Components/Tooltips/HelpTooltip";


function ProductBudgetCard(props) {
    const {
        productBudget,
        onBudgetUpdate,
    } = props;

    const [isEdit, setIsEdit] = useState(false);
    const [newBudget, setNewBudget] = useState(productBudget)

    return (
        <Card className="mb-3">
            <CardBody>
                <div>
                    <CardTitle style={{display: "inline-block"}}>Product Budget</CardTitle>
                    <HelpTooltip
                        className={"ml-1"}
                        target="productBudget"
                        placement={"auto"}
                        style={{display: "inline"}}
                    >
                        <p>
                            The total budget that will be automatically allocated across all Simpliworks managed campaigns for this product.
                        </p>
                    </HelpTooltip>
                </div>

                {
                    isEdit ?
                        <FormGroup style={{display: "inline-block"}}>
                            <InputGroup>
                                <NumericInput
                                    className={"form-control"}
                                    style={{input: {fontSize: "initial"}}}
                                    min={0.02}
                                    precision={2}
                                    step={0.01}
                                    value={newBudget}
                                    format={num => `$${num}`}
                                    parse={str => str.replace(/^\$/, "")}
                                    onChange={value => setNewBudget(value)}
                                />
                            </InputGroup>
                        </FormGroup>
                    :
                        <p
                            className="widget-numbers-sm mr-5"
                            style={{display: "inline-block"}}
                        >
                            ${productBudget.toFixed(2)}
                        </p>
                }

                <Button
                    outline={isEdit}
                    className="ml-3"
                    style={{display: "inline-block"}}
                    color={isEdit ? "secondary" : "brand-blue-dark"}
                    onClick={() => setIsEdit(!isEdit)}
                >
                    {isEdit ? "Cancel" : "Update"}
                </Button>

                {
                    isEdit &&
                    <Button
                        className="ml-1"
                        color={"success"}
                        onClick={() => {
                            onBudgetUpdate(newBudget);
                            setIsEdit(false);
                        }}
                    >
                        Confirm
                    </Button>
                }
            </CardBody>
        </Card>
    );

}


export default ProductBudgetCard;