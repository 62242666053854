import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";


function CreateOneTimePaymentService(token, priceID) {
    return axios.post(`${CURRENT_API_URL}/payment/create-payment`, {
        priceId: 'price_1LP9McGIqnst6pthYRY2LsUl',
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        return res.data;
    }).catch(error => {
        console.log(error);
    })
}


export default CreateOneTimePaymentService;