import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
// import useGlobalFilter from "react-table";
// import usePagination from "react-table";
import { Card, Button, Col, Input, Label, Row } from "reactstrap";
// import { useTable,useAsyncDebounce,useSortBy } from 'react-table';
import { connect } from 'react-redux';


import CustomModal from "../../../../../../Components/Modals/CustomModal";
import UpdateCreditBalanceService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/RequestAmazonStoresService";
import RequestAmazonStoresService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/RequestAmazonStoresService";
    

function RequestStoresDataTable(props) {



        const innerColumns = [
            
            {
                Header: 'Market place Id',
                accessor: 'accountInfo',
                Cell: props => (
                    <Fragment> {props.original.accountInfo.marketplaceStringId}</Fragment>
                )
            },
            {
                Header: 'Name',
                accessor: 'accountInfo',
                Cell: props => (
                    <Fragment> {props.original.accountInfo.name}</Fragment>
                )
            },
            {
                Header: 'Type',
                accessor: 'accountInfo',
                Cell: props => (
                    <Fragment> {props.original.accountInfo.type}</Fragment>
                )
            }, 
            {
                Header: 'Country Code',
                accessor: 'countryCode',
            },
            
            {
                Header: 'Currency',
                accessor: 'currencyCode',
            },
            {
                Header: 'Time Zone',
                accessor: 'timezone',
            },
            
            
        ];


    return (
        
            
                    
                        
                        <ReactTable
                           
                            data={props.data}
                            columns={innerColumns}
                            defaultPageSize={5}
                            getTheadProps={() => {
                                return {
                                    style: {
                                        overflowY: "auto",
                                    }
                                }
                            }}
                            className="-striped -highlight -fixed"
                            noDataText={"No stores found."}
                            style={{
                                height: "500px" // This will force the table body to overflow and scroll, since there is not enough room
                            }}
                        />
                        
                    
            
            )
        }

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})        

export default connect(mapStateToProps)(RequestStoresDataTable)