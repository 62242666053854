import React, {useState} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row} from "reactstrap";
import { connect } from 'react-redux';

import DateRangePicker from "../../../../../Components/Input/DateRangePicker";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import {UserAuthorizationService} from "../../../../../../Service/OPSServices/UserAuthorizationServices";
import UserAuthorizationTable from "./Components/UserAuthorizationTable";


function UserAuthorizationPage(props) {

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const [email, setEmail] = useState("");

    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState([]);

    console.log(productStatus);

    const fetchProductStatus = () => {
        setLoading(true);

        let isProduct = email !== "";

        let data = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        };

        if (isProduct) {
            data["email"] = email;
        } 

        UserAuthorizationService(props.token, data).then(authorizationStatus => {
            if (authorizationStatus) {
                setProductStatus(authorizationStatus);
            }
        })

        setLoading(false);
    }

    const isInputValid = () => {
        if (dateRange.startDate === null) return false;
        //if (integerId === "" && asin === "") return false;
        //if (integerId !== "" && asin !== "") return false;

        return true;
    }

    return (
        <>
            <Container fluid>
                <Row className="mb-3">
                    <Col md="12" lg="6">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Date Range</CardTitle>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                                <div className="divider"/>
                                <Label for={"email"}>User Email</Label>
                                <Input
                                    className="mb-3"
                                    id={"email"}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter User Email"
                                />
                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color={"primary"}
                                    onClick={fetchProductStatus}
                                    disabled={!isInputValid()}
                                >
                                    Submit
                                </LoadingButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardTitle>User Authorization Status</CardTitle>
                        <Card>
                            <UserAuthorizationTable data={productStatus} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})


export default connect(mapStateToProps)(UserAuthorizationPage);