import React, { Fragment } from 'react';
import SearchProductWidget from "./SearchProductWidget";
import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";


function SearchProductWidgetList(props) {

    const renderSearchProductWidget = ({ title, image, asin, integerID, storeName, countryName, sku }) => {
        return (
            <SearchProductWidget
                title={title}
                image={image}
                asin={asin}
                handleSelectProduct={props.handleSelectProduct}
                storeId={integerID}
                storeName={storeName}
                location={countryName}
                sku={sku}
            />
        )
    }

    return (
        <Fragment>
            <WidgetListCard
                widgets={props.data}
                renderWidget={renderSearchProductWidget}
                card={props.card}
            />
        </Fragment>
    )
}

export default SearchProductWidgetList;