/** Entry URLs **/

// Authentication
const LOGIN_URL = '/login-user';
const BASE_REGISTER_URL = '/register-user';
const REGISTER_SELLER_URL = `${BASE_REGISTER_URL}/seller`;
const REGISTER_CONTENT_AGENCY_URL = `${BASE_REGISTER_URL}/contentAgency`;

// Account Recovery
const BASE_ACCOUNT_RECOVERY_URL = '/account-recovery';
const ACCOUNT_RECOVERY_PASSWORD_RESET = `${BASE_ACCOUNT_RECOVERY_URL}/password-reset`;
const ACCOUNT_RECOVERY_PASSWORD_CONFIRM = `${BASE_ACCOUNT_RECOVERY_URL}/password-confirm`;


/** Dashboard URLs **/

// Create Campaign
const BASE_CREATE_CAMPAIGN_URL = '/create-campaign'
const CREATE_CAMPAIGN_INFO_URL = `${BASE_CREATE_CAMPAIGN_URL}/info`;
const CREATE_CAMPAIGN_MARKET_ANALYSIS_URL = `${BASE_CREATE_CAMPAIGN_URL}/market-analysis`;
const CREATE_CAMPAIGN_COST_ANALYSIS_URL = `${BASE_CREATE_CAMPAIGN_URL}/cost-analysis`;

// Checkout
const BASE_CHECKOUT_URL = '/checkout'
const CHECKOUT_CART_OVERVIEW_URL = `${BASE_CHECKOUT_URL}/cart-overview`;
const CHECKOUT_PAYMENT_INFO_URL = `${BASE_CHECKOUT_URL}/payment-information`;
const CHECKOUT_CONFIRM_ORDER_URL = `${BASE_CHECKOUT_URL}/confirm-order`;

// Launchpad
const BASE_LAUNCHPAD_URL = '/launchpad'
const LAUNCHPAD_CART_OVERVIEW_URL = `${BASE_LAUNCHPAD_URL}/cartOverview`;
const LAUNCHPAD_READY_CAMPAIGNS_URL = `${BASE_LAUNCHPAD_URL}/readyToLaunch`;

// Registration journey
const BASE_REGISTRATION_JOURNEY_URL = '/registration-journey';

// TROUBLE TICKET

const BASE_TROUBLE_TICKET_URL = '/trouble-ticket';
const TROUBLE_TICKET_TICKET_LIST_URL = `${BASE_TROUBLE_TICKET_URL}/all-ticket-list`;
const TROUBLE_TICKET_CREATE_TICKET_URL = `${BASE_TROUBLE_TICKET_URL}/create-ticket`;

// Account Overview
const BASE_ACCOUNT_OVERVIEW_URL = '/account-overview';
const ACCOUNT_OVERVIEW_MY_CAMPAIGNS_URL = `${BASE_ACCOUNT_OVERVIEW_URL}/myCampaigns`;
const ACCOUNT_OVERVIEW_MY_STORES_URL = `${BASE_ACCOUNT_OVERVIEW_URL}/myStores`;
const ACCOUNT_OVERVIEW_PRODUCT_URL = `${BASE_ACCOUNT_OVERVIEW_URL}/productOverview`;
const ACCOUNT_OVERVIEW_CAMPAIGN_URL = `${BASE_ACCOUNT_OVERVIEW_URL}/campaignOverview`;

// Account Overview relaunch
// const BASE_ACCOUNT_OVERVIEW_RELAUNCH_URL = '/store-overview';
// const ACCOUNT_OVERVIEW_MY_STORES_RELAUNCH_URL = `${BASE_ACCOUNT_OVERVIEW_RELAUNCH_URL}/myStores`;


// new store overview
const BASE_STORE_OVERVIEW_URL = '/store-overview-v1';
const STORE_OVERVIEW_MY_STORES_URL = `${BASE_STORE_OVERVIEW_URL}/myStores`;

// new store overview
const BASE_USER_PROFILE_DATA_URL = '/userProfileData';
const USER_PROFILE_DATA_URL = `${BASE_USER_PROFILE_DATA_URL}/userProfile`;

// Credits System
const BASE_PRICING_URL = '/pricing';
const PRICING_OPTIONS_URL = `${BASE_PRICING_URL}/options`;
const CREDITS_OVERVIEW_PAGE_URL = `${BASE_PRICING_URL}/overview`;
const CREDITS_TRANSACTIONS_PAGE_URL = `${BASE_PRICING_URL}/transactions`;
const CREDITS_PURCHASE_PAGE_URL = `${BASE_PRICING_URL}/get-credits`;
const CREDITS_CHECKOUT_PAGE_URL = `${BASE_PRICING_URL}/checkout`;
const PRICING_ADDONS_URL = `${BASE_PRICING_URL}/addOns`;

// Projects
const BASE_PROJECTS_URL = '/project';
const CREATE_PROJECTS_URL = `${BASE_PROJECTS_URL}/create`;
const VIEW_PROJECTS_URL = `${BASE_PROJECTS_URL}/view`;
const AGENCY_PROJECT_VIEW_URL = `${BASE_PROJECTS_URL}/agency-project-view/:id`

// User Profile
const BASE_USER_PROFILE_URL = '/userProfile';
const USER_PROFILE_AUTH_URL = `${BASE_USER_PROFILE_URL}/authorization`;
const STORE_AUTH_REDIRECT_URL = '/storeAuthRedirect';
const USER_PROFILE_DIRECTORY_URL = `${BASE_USER_PROFILE_URL}/directory`;
const USER_PROFILE_CONTENT_AGENCIES_URL = `${BASE_USER_PROFILE_URL}/contentAgencies`;
const USER_PROFILE_SUB_ACCOUNTS_URL = `${BASE_USER_PROFILE_URL}/subAccounts`;
const USER_PROFILE_LEAD_GEN_URL = `${BASE_USER_PROFILE_URL}/leadGeneration`;
const USER_PROFILE_LEAD_GEN_RESEARCH_PRODUCT_CATEGORY_URL = `${BASE_USER_PROFILE_URL}/LeadGenResearchProductCategory`;
const USER_PROFILE_LEAD_GEN_JOBS_URL = `${BASE_USER_PROFILE_URL}/LeadGenJobs`;
const USER_PROFILE_AMA_PROD_CAT_URL = `${BASE_USER_PROFILE_URL}/MarketResearchProudctCategory`;
const USER_PROFILE_ADD_KEYWORDS_TRACKING =  `${BASE_USER_PROFILE_URL}/AddKeywordsTracking`;

// Operations
const BASE_OPS_URL = '/ops';
const OPS_DATA_COLLECTION_URL = `${BASE_OPS_URL}/dataCollection`;
const OPS_CAMPAIGN_LAUNCH_URL = `${BASE_OPS_URL}/campaignLaunch`;
const OPS_PRODUCT_ENROLLMENT_URL = `${BASE_OPS_URL}/productEnrollment`;
const OPS_PRODUCT_BUDGET_UPDATE_URL = `${BASE_OPS_URL}/productBudgetUpdate`;
const OPS_USER_AUTHORIZATION_URL = `${BASE_OPS_URL}/userAuthorization`;
const OPS_SELLER_PARTNER_DATA_URL = `${BASE_OPS_URL}/sellerPartnerData`;
const OPS_ADVERTISING_DATA_URL = `${BASE_OPS_URL}/advertisingData`;
const OPS_ADVERTISING_JOB_URL = `${BASE_OPS_URL}/advertisingJob`;
const OPS_TICKETLIST_URL = `${BASE_OPS_URL}/ticketList`;
const OPS_WORKFLOWS_URL = `${BASE_OPS_URL}/workflows`;
const OPS_STOREHEALTH_URL=`${BASE_OPS_URL}/storehealth`;
const OPS_RESOURCESKILLSET_URL=`${BASE_OPS_URL}/resource-skill-set`;





export default {
    LOGIN_URL,
    BASE_REGISTER_URL,
    REGISTER_SELLER_URL,
    REGISTER_CONTENT_AGENCY_URL,
    BASE_ACCOUNT_RECOVERY_URL,
    ACCOUNT_RECOVERY_PASSWORD_RESET,
    ACCOUNT_RECOVERY_PASSWORD_CONFIRM,

    BASE_CREATE_CAMPAIGN_URL,
    CREATE_CAMPAIGN_INFO_URL,
    CREATE_CAMPAIGN_MARKET_ANALYSIS_URL,
    CREATE_CAMPAIGN_COST_ANALYSIS_URL,

    BASE_CHECKOUT_URL,
    CHECKOUT_CART_OVERVIEW_URL,
    CHECKOUT_PAYMENT_INFO_URL,
    CHECKOUT_CONFIRM_ORDER_URL,

    BASE_LAUNCHPAD_URL,
    LAUNCHPAD_CART_OVERVIEW_URL,
    LAUNCHPAD_READY_CAMPAIGNS_URL,

    BASE_ACCOUNT_OVERVIEW_URL,
    ACCOUNT_OVERVIEW_MY_CAMPAIGNS_URL,
    ACCOUNT_OVERVIEW_MY_STORES_URL,
    ACCOUNT_OVERVIEW_PRODUCT_URL,
    ACCOUNT_OVERVIEW_CAMPAIGN_URL,

    // BASE_ACCOUNT_OVERVIEW_RELAUNCH_URL,
    // ACCOUNT_OVERVIEW_MY_STORES_RELAUNCH_URL,
    
    BASE_PRICING_URL,
    PRICING_OPTIONS_URL,
    CREDITS_OVERVIEW_PAGE_URL,
    CREDITS_TRANSACTIONS_PAGE_URL,
    CREDITS_PURCHASE_PAGE_URL,
    CREDITS_CHECKOUT_PAGE_URL,
    PRICING_ADDONS_URL,

    BASE_PROJECTS_URL,
    CREATE_PROJECTS_URL,
    VIEW_PROJECTS_URL,
    AGENCY_PROJECT_VIEW_URL,

    BASE_USER_PROFILE_URL,
    USER_PROFILE_AUTH_URL,
    USER_PROFILE_ADD_KEYWORDS_TRACKING,
    STORE_AUTH_REDIRECT_URL,
    USER_PROFILE_DIRECTORY_URL,
    USER_PROFILE_CONTENT_AGENCIES_URL,
    USER_PROFILE_SUB_ACCOUNTS_URL,
    USER_PROFILE_LEAD_GEN_URL,
    USER_PROFILE_LEAD_GEN_RESEARCH_PRODUCT_CATEGORY_URL,
    USER_PROFILE_LEAD_GEN_JOBS_URL,
    USER_PROFILE_AMA_PROD_CAT_URL,

    BASE_OPS_URL,
    OPS_DATA_COLLECTION_URL,
    OPS_CAMPAIGN_LAUNCH_URL,
    OPS_PRODUCT_ENROLLMENT_URL,
    OPS_PRODUCT_BUDGET_UPDATE_URL,
    OPS_USER_AUTHORIZATION_URL,
    OPS_SELLER_PARTNER_DATA_URL,
    OPS_ADVERTISING_DATA_URL,
    OPS_ADVERTISING_JOB_URL,
    OPS_WORKFLOWS_URL,
    OPS_STOREHEALTH_URL,
    OPS_TICKETLIST_URL,
    OPS_RESOURCESKILLSET_URL,

    // Registration journey
    BASE_REGISTRATION_JOURNEY_URL,

    BASE_TROUBLE_TICKET_URL,
    TROUBLE_TICKET_TICKET_LIST_URL,
    TROUBLE_TICKET_CREATE_TICKET_URL,

    // new store overview
    BASE_STORE_OVERVIEW_URL,
    STORE_OVERVIEW_MY_STORES_URL,

    // User Profile data 
    BASE_USER_PROFILE_DATA_URL,
    USER_PROFILE_DATA_URL,
}
