import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetAdvertisingReportService(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/GetAdvertisingReport`, data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Advertising report data is getting collected.")).
        then(res => {
            return res.data;
        }).catch(error => {  
            notify.NotifyError(error.response);
            notify.NotifyError("An error occurred while creating report, please try again later.");
            return false;
        
        });
}
export {
    GetAdvertisingReportService,
}