import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import urls from '../../../../Utils/URLs'
import CreditsLayout from "./Components/CreditsLayout";
import CreditsPurchasePage from "./Scenes/CreditsPurchase/CreditsPurchasePage";
import PageNotFound from "../../../PageNotFound";
import PricingPage from "./Scenes/Pricing/PricingPage";
import ProtectedRoute from "../../../Components/Nav/ProtectedRoute";
import AddOnsPage from "./Scenes/AddOns/AddOnsPage";

function CreditsPage({ accessLevel }) {


    return (
        <CreditsLayout>
            <Switch>
                {/*<Route path={urls.CREDITS_PURCHASE_PAGE_URL}>*/}
                {/*    <CreditsPurchasePage/>*/}
                {/*</Route>*/}
                <ProtectedRoute
                    role={accessLevel}
                    perform={"pricingOptions:view"}
                    exact={true}
                    path={urls.PRICING_OPTIONS_URL}
                >
                    <PricingPage />
                </ProtectedRoute>
                <ProtectedRoute
                    role={accessLevel}
                    perform={"addOns:view"}
                    exact={true}
                    path={urls.PRICING_ADDONS_URL}
                >
                    <AddOnsPage />
                </ProtectedRoute>
                <Route component={PageNotFound}/>
            </Switch>
        </CreditsLayout>
    );
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(CreditsPage);