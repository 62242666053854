import React, { Fragment } from 'react';
import { Button } from 'reactstrap';

import CustomWidget from '../../../../../../../Components/Widgets/CustomWidget';
import StarRating from '../../../../Components/Rating/StarRating';

function StoreWidget(props) {
  const viewStoreButton = (key) => (
    <Button
      key={key}
      color="link"
      onClick={() => {
        const { showModal, ...profile } = props;
        return showModal(profile);
      }}
    >
      View Store {'>'}
    </Button>
  );

  const defaultStoreIcon = () => (
    <i
      className="lnr-store icon-gradient bg-simpli-gradient"
      style={{ fontSize: '40px' }}
    ></i>
  );

  const subheading = () => (
    <Fragment>
      <div>{`Location: ${props.location}`}</div>
      {props.ratingTotal < 1 ? (
        <div>{`No ratings available`}</div>
      ) : (
        <div>
          {/*{`Rating: ${props.rating}/5 (${props.ratingTotal} reviews, ${props.ratingTotalPercentage}% positive)`}*/}
          Rating: <StarRating initialRating={props.rating} readonly stop={5} />{' '}
          ({props.rating}) ({props.ratingTotal} reviews,{' '}
          {props.ratingTotalPercentage}% positive)
        </div>
      )}
    </Fragment>
  );

  return (
    <CustomWidget
      collapsible={true}
      image={props.logo}
      defaultIcon={defaultStoreIcon()}
      heading={props.name}
      subheading={subheading()}
      details={props.details}
      actions={[viewStoreButton]}
      autoFormat={true}
    />
  );
}

export default StoreWidget;
