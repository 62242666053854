import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
import { Card, Button, Col, Input, Label, Row } from "reactstrap";
import { connect } from 'react-redux';

function SalesDataTable(props) {

    // console.log("Creating surya")

    console.log(props.data.sales1d);
    const innerColumns = [
        
        {
            Header: 'Campaign Name',
            accessor: 'campaignName',
        }, 
        {
            Header: 'Campaign Status',
            accessor: 'campaignStatus',
        },     
        {
            Header: 'Sales',
            accessor: 'sales1d',
        },
        {
            Header: 'Spend',
            accessor: 'spend',
        },
        {
            Header: 'Impressions',
            accessor: 'impressions',
        },
        {
            Header: 'Clicks',
            accessor: 'clicks',
        },
        {
            Header: 'CTR',
            accessor: 'CTR',
        },
        {
            Header: 'CPC',
            accessor: 'CPC',
        },
        {
            Header: 'Budget',
            accessor: 'campaignBudgetAmount',
        },
        {
            Header: 'Purchases',
            accessor: 'purchases1d',
        },
        {
            Header: 'ROAS',
            accessor: 'ROAS',
        },
        {
            Header: 'Budget Utilization',
            accessor: 'BudgetUtil',
        },
        {
            Header: 'Ad CVR',
            accessor: 'AdCVR',
        },
        
        {
            Header: 'Average Order($)',
            accessor: 'AvOrder',
        },
        
    ];


    return (
        
        <div className="p-1">
            <div className="border rounded p-2 mb-2">
                <h4 className='text-center txt-blue'><span className=''>Title: </span><b>{props.asin}</b></h4>
                <div className="">
                <span className='txt-blue'>Date Range: </span> <b> {props.data.startdate } To {props.data.enddate }</b>
                </div>
                <div className="">
                   <span className='txt-blue'>Total One Day Sale:</span> <b> {props.data.sales1d}</b>
                </div>
                <div className="">
                <span className='txt-blue'>Total Seven Days Sale:</span>  <b>{props.data.sales7d}</b> 
                </div>
                <div className="">
                <span className='txt-blue'>Total Fourteen Days Sale: </span> <b> {props.data.sales14d}</b>
                </div>
                <div className="">
                <span className='txt-blue'>Total Thirty Days Sale: </span>  <b>{props.data.sales30d}</b> 
                </div>
            </div>
            <ReactTable
                data={props.data.asinSalesDataList}
                columns={innerColumns}
                defaultPageSize={10}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed "
                noDataText={"No data found."}
            />
        

        </div>
    
);
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 



export default connect(mapStateToProps)(SalesDataTable);


