import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Timeline from "../../../../../../../../Components/Timeline";
import CustomLoadingOverlay from "../../../../../../../../Components/Nav/CustomLoadingOverlay";

function CampaignChangelog({ campaignUpdates, ...props }) {

    const changeTypes = {
        keywordSpendIncrease: "Keyword Spend Increased",
        keywordSpendReduce: "Keyword Spend Reduced",
        keywordAdd: "Keyword Added to Campaign",
        keywordSuggestedBid: "Keyword Updated to use Suggested Bid Successfully",
        campaignCreated: "Campaign created",
    };

    const changeReasons = {
        optimization: ["Force Campaign Trigger Rule", "Kill Bleeders Rule", "Collect Data Rule", "Search Term Report Upload", "Suggested Bid Rule"],
        creation: ["One Click Campaign Created", "Swarm Campaign Created", "Explore One Campaign Created", "Explore Two Campaign Created"],
    };

    const formatCampaignUpdates = (campaignUpdates) => {
        let timelineUpdates = {
            optimization: [],
            creation: [],
        };

        if (!campaignUpdates) return timelineUpdates;

        const formatUpdate = (update, time, idx) => {
            switch(update['changeType']) {
                case changeTypes.keywordSpendIncrease:
                    return {
                        header: update.changeType,
                        color: "success",
                        dateTime: update.changeTime,
                        time: time,
                        body:
                            <Fragment>
                                <p>Keyword <b>{update.associatedKeywordText}</b> in campaign <b>{update.campaignName}</b> spend increased from <b>$ {update.oldBidValue}</b> to <b>$ {update.newBidValue}</b></p>
                                <p className="text-primary">Triggered by: {update.changeReason}</p>
                            </Fragment>,
                    }
                case changeTypes.keywordSpendReduce:
                    return {
                        header: update.changeType,
                        color: "danger",
                        dateTime: update.changeTime,
                        time: time,
                        body:
                            <Fragment>
                                <p>Keyword <b>{update.associatedKeywordText}</b> in campaign <b>{update.campaignName}</b> spend reduced from <b>$ {update.oldBidValue}</b> to <b>$ {update.newBidValue}</b></p>
                                <p className="text-primary">Triggered by: {update.changeReason}</p>
                            </Fragment>,
                    }
                case changeTypes.keywordAdd:
                    return {
                        header: update.changeType,
                        color: "primary",
                        dateTime: update.changeTime,
                        time: time,
                        body:
                            <Fragment>
                                <p>Keyword <b>{update.associatedKeywordText}</b> added to campaign <b>{update.campaignName}</b> at price <b>$ {update.newBidValue}</b></p>
                                <p className="text-primary">Triggered by: {update.changeReason}</p>
                            </Fragment>,
                    }
                case changeTypes.keywordSuggestedBid:
                    return {
                        header: update.changeType,
                        color: "warning",
                        dateTime: update.changeTime,
                        time: time,
                        body:
                            <Fragment>
                                <p>Keyword <b>{update.associatedKeywordText}</b> in campaign <b>{update.campaignName}</b> updated to use suggested bid of <b>$ {update.newBidValue}</b></p>
                                <p className="text-primary">Triggered by: {update.changeReason}</p>
                            </Fragment>,
                    }
                case changeTypes.campaignCreated:
                    return {
                        header: update.changeType,
                        color: "brand-blue-dark",
                        dateTime: update.changeTime,
                        time: time,
                        body:
                            <Fragment>
                                <p>Campaign <b>{update.campaignName}</b> created for product with ASIN <b>{update.asin}</b></p>
                                <p className="text-primary">Triggered by: {update.changeReason}</p>
                            </Fragment>,
                    }
                default:
                    return {
                        header: "Invalid",
                        color: "danger",
                        dateTime: update.changeTime,
                        time: time,
                        body: `Update corrupted at index ${idx}`,
                    };
            }
        }

        campaignUpdates.forEach((update, idx) => {
            const time = new Date(update.changeTime).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            let formattedUpdate = formatUpdate(update, time, idx);

            changeReasons.optimization.includes(update.changeReason) ? timelineUpdates.optimization.push(formattedUpdate) :
                timelineUpdates.creation.push(formattedUpdate);
        });

        return timelineUpdates;
    }

    const groupByDate = timelineUpdates => {
        if (!timelineUpdates) return [];

        const groups = timelineUpdates.reduce((groups, update) => {
            const date = update.dateTime.split('T')[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(update);
            return groups;
        }, {});

        return Object.keys(groups).map((date) => ({
            date,
            items: groups[date]
        }));
    }

    let timelineUpdates = formatCampaignUpdates(campaignUpdates);

    const campaignTimelines = {
        optimization: groupByDate(timelineUpdates.optimization),
        creation: groupByDate(timelineUpdates.creation),
    };

    return (
        <Fragment>
            <Row>
                <Col md="6">
                    <Card className="main-card mt-3 ml-3 mb-3 mr-0">
                        <CardBody>
                            <CardTitle>Campaign Creation</CardTitle>
                            <Timeline style={{height: "60vh"}} data={campaignTimelines.creation}/>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="6">
                    <Card className="main-card mt-3 mr-3 mb-3 ml-0">
                        <CardBody>
                            <CardTitle>Campaign Optimization</CardTitle>
                            <Timeline style={{height: "60vh"}} data={campaignTimelines.optimization}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
}

export default CampaignChangelog;