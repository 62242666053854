import actionType from '../../Actions/ActionTypes';


const initialState = {
    readyCampaigns: [],
    adProfiles: [],
    loading: false,
    status: '',
}

const compareStores = (store1, store2) => {
    if (store1.name < store2.name) return -1;
    else if (store1.name > store2.name) return 1;
    else return 0;
}

function SellerProfilesReducer(state= initialState, action) {
    switch(action.type) {
        case actionType.LOAD_ADPROFILES_BEGIN:
            return {
                ...state,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_ADPROFILES_SUCCESS:
            return {
                ...state,
                adProfiles: action.adProfiles.map( item => {
                    const { integerID, stringID, name, location, status, logo, rating, rating_total, rating_total_percentage } = item;
                    const details = {
                        accountID: stringID,
                        status: status,
                    }
                    return {
                        integerID,
                        name,
                        location,
                        logo,
                        rating,
                        ratingTotal: rating_total,
                        ratingTotalPercentage: rating_total_percentage,
                        details,
                    };
                }).sort(compareStores),
                loading: false,
                status: 'success',
                totalSpend: action.totalSpend,
            }
        case actionType.LOAD_ADPROFILES_FAILED:
            return {
                ...state,
                loading: false,
                status: 'failed',
            }
        case actionType.LOAD_READY_CAMPAIGNS_BEGIN:
            return {
                ...state,
                loading: true,
                status: 'loading',
            }
        case actionType.LOAD_READY_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 'success',
                readyCampaigns: action.readyCampaigns,
            }
        case actionType.LOAD_READY_CAMPAIGNS_FAILED:
            return {
                ...state,
                loading: false,
                status: 'failed',
            }
        default:
            break;
    }

    return state;
}

export default SellerProfilesReducer;
