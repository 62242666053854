import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import { connect } from 'react-redux';
import LWAButton from "../../../../../Components/Buttons/LWAButton";
import AdvertisingAPIAuthorizationService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/AdvertisingAPIAuthorizationService";
// export default ;


function AdvertisingAPICard(props) {


    // useEffect(() => {
    //     if (props.isAuth) {
    //         handleAuth();
    //     }
    // }, [props.isAuth])

    // const handleAuth = () => {
    //     props.dispatch(AdvertisingAPIAuthorizationService(
    //         props.token,
    //         props.user._profile.id,
    //         props.user.token.accessToken,
    //         props.user.token.refreshToken,
    //         props.user.token.expiresAt,
    //     ))
    // }


    return (
        <Card>
            <CardHeader style={{justifyContent: "center"}}>Amazon Advertising</CardHeader>
            <CardBody className="text-center">
                Authorize Amazon Advertising to launch and manage your Amazon campaigns.
            </CardBody>
            <CardFooter style={{justifyContent: "center"}}>
                <LWAButton />
                
            </CardFooter>
        </Card>
    )
}


export default connect()(AdvertisingAPICard);