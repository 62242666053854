import React, { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Carousel, Col, Input, Row } from 'reactstrap'
import GetBrandProfileListService from '../../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetBrandProfileListService';
import { connect } from 'react-redux';
import GetFileListByCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListByCategoryService';
import BackButton from "../../../../.././../../../../designUtils/Logo/BackButton.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faAngleDown, faAngleUp, faCloudUploadAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import RenameFileAssetNewService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetNewService';
import TickMark from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/TickMark.svg'
import Rename from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Rename.svg'
import Download from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Download.svg'
import Close from '../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Close.svg'
import CustomModal from '../../../../../../../../Components/Modals/CustomModal';
import GetFileListBySubCategoryService from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/GetFileListBySubCategoryService';
import ImageAndLogo from './Components/Brand/ImageAndLogo';
// import UploadBrandImages from './SubComponents/UploadBrandImages';
import UploadAllTypeOfAssetsFile from './Components/UploadAllTypeOfAssetsFile';
import UploadImagesAndOriginalFiles from './Components/UploadImagesAndOriginalFiles';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loaders";
import OtherBrandAsset from './Components/Brand/OtherBrandAsset';
import FetchAllCategoryAndSubCategory from '../../../../../../../../../Service/DashboardServices/AssetGalleryServices/FetchAllCategoryAndSubCategoryService';

function BrandDetails(props) {
    const [subCategoryId, setSubCategoryId] = useState()
    const [brandProfile, setBrandProfile] = useState();
    const [fileListOfAssets, setFileListOfAssets] = useState(false);
    const [allFileListOfAssets, setAllFileListOfAssets] = useState(false);
    const [assetImagePage, setAssetImagePage] = useState(false);
    const [brand, setBrand] = useState();
    const [isInfoTabOpen, setIsInfoTabOpen] = useState(false)
    const [imageUrl, setImageUrl] = useState(false)
    const [uploadImagesPage, setUploadImagesPage] = useState(false)
    const [subCategoryText, setSubCategoryText] = useState()
    const [loadScreen, setLoadScreen] = useState(false)
    const [changeTabs,setChangeTabs]=useState(false)
    const [defaultSubCatid, setdefaultSubCatid] = useState();


console.log(fileListOfAssets)
    useEffect(() => {
        FetchAllCategoryAndSubCategory(props.token).then((response) => {
        if (response) {
            const filteredSubCategories = response.subCategory && response.subCategory.filter((subCat) => subCat.assetCategory === props.catId);
            console.log('filteredSubCategories');
            console.log(filteredSubCategories);
            if(filteredSubCategories.length > 0)
            {
                setdefaultSubCatid(filteredSubCategories[0].id)
                setSubCategoryText(filteredSubCategories[0].subCategoryName)

                // GetFileListBySubCategory(filteredSubCategories[0].id)
                setSubCategoryId(filteredSubCategories[0].id)
            }         
            }})
        let data = {
            integerId: props.storeOverview.integerID
        }
        GetBrandProfileListService(props.token, data).then((response) => {
            if (response) {
                setBrandProfile(response.brandList);
                console.log(response);
            }
        });
        // 
    }, []);

    const GetFileListBySubCategory = (id,bname) => {
      setLoadScreen(true)
        let data = {
            integerId: props.storeOverview.integerID,
            assetCategory:props.catId,
            subCategoryId: id ? id : '',
            assetIdentifier:bname?bname:''
        };
        GetFileListBySubCategoryService(props.token, data).then((response) => {
            if (response) {
                const imageFiles = response.fileList.filter(file => {
                    const extension = file.fileName.toLowerCase().split('.').pop();
                    return ['jpg', 'png', 'jpeg'].includes(extension);
                });
                console.log(response)
                setFileListOfAssets(imageFiles);
                setAllFileListOfAssets(response.fileList)
                console.log(response.fileList);
                // setAssetImagePage(true)
                setLoadScreen(false)
            }
        });
    }

    const handleBackbutton = () => {
        setSubCategoryId(defaultSubCatid)
        setAssetImagePage(false)
    }
    const setBrandInfo = (brandId) => {
        const brandInfo =  brandProfile && brandProfile.filter((brand) => brand.id == brandId)
        console.log(brandInfo)
        setBrand(brandInfo[0])
        GetFileListBySubCategory(defaultSubCatid,brandInfo[0].brandName)
    }
    const handleUploadAssetsUi = () => {
        setUploadImagesPage(true);
        setIsInfoTabOpen(false);
    }
    const handleBackFromUploadAssets = (file, flag) => {
        console.log(file)
        if(flag ===2){
        console.log(file.fileList)
            setAllFileListOfAssets(file.fileList)
        }
        setUploadImagesPage(false);

    }
    const handleBackFromUploadAssetsImages=()=>{
        setUploadImagesPage(false)
       }
    const handleSubCategory = (tab) => {
        console.log(tab)
        setSubCategoryId(tab.id);
        setIsInfoTabOpen(false);
        setSubCategoryText(tab.subCategoryName)
        // GetFileListBySubCategory(tab.id)
    }
    const renderUploadAssetsPage = () => {
        if (subCategoryText == 'Images' || subCategoryText == 'Logo') {
            return (
                <><UploadImagesAndOriginalFiles
                    subCategoryId={subCategoryId}
                    handleBackFromUploadAssetsImages={handleBackFromUploadAssetsImages}
                    assetIdentifier={brand.brandName} 
                    setFileListOfAssets={setFileListOfAssets}
                    assetCategory = {props.catId} /></>
            )
        }

        else {
            return (
                <> <UploadAllTypeOfAssetsFile
                    subCategoryId={subCategoryId}
                    handleBackFromUploadAssets={handleBackFromUploadAssets}
                    flagNumber={20}
                    assetIdentifier={brand.brandName} 
                    setFileListOfAssets={setFileListOfAssets}
                    assetCategory = {props.catId}/>
                    
                    </>
            )
        }

    }
    const handleOpenFileInfo = () => {
        setIsInfoTabOpen(!isInfoTabOpen)   
    }
    return (
        <>
            {uploadImagesPage ? renderUploadAssetsPage() :
                <Row>
                    {!brand ?
                        <Col>
                            <Card className='p-2 child-card '>
                                <h6><b>Choose your brand</b></h6>
                                <select
                                    className="form-control w-25 mb-3"
                                    onChange={(e) => { setBrandInfo(e.target.value) }}
                                >
                                    <option >--Select Brand --</option>
                                    {brandProfile && brandProfile.map((brand) => (
                                        <option
                                            key={brand.id}
                                            value={brand.id}>{brand.brandName}</option>
                                    ))}
                                </select>
                            </Card>
                        </Col>
                        :
                        <>
                            <Col md={12}>
                                <Card className='p-2 mb-3 child-card' >
                                    <Row>
                                        <Col md={12}>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex '>
                                                    <button onClick={() => { handleBackbutton(); setBrand();setIsInfoTabOpen(false);setSubCategoryText('Images') }} className="hover-btn-link back-btn ml-2 mb-3 mr-1 "  >
                                                        <img src={BackButton} alt="not found" />
                                                    </button>
                                                    <h5 className=''><b>{brand.brandName}</b></h5>
                                                </div>
                                                {subCategoryId &&
                                                    (
                                                        <Button
                                                            onClick={() => handleUploadAssetsUi()}
                                                            className='btn-background-color-Proceed-gallery  ml-2'
                                                            disabled={!brand}
                                                        >
                                                            Upload Assets
                                                        </Button>
                                                    )}
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className='d-flex mt-1'>
                                                {props.subCategory && props.subCategory.map((sub) =>
                                                (<>
                                                    {sub.assetCategory == props.catId ?
                                                        <h5 className={`${sub.id === subCategoryId ? 'selected-tab-assets-tab ' : 'assets-tab-not-selected'}`}
                                                            onClick={() => { handleSubCategory(sub); GetFileListBySubCategory(sub.id,brand.brandName); setChangeTabs(true) }}
                                                        >
                                                            <b>{sub.subCategoryName}</b>
                                                        </h5>
                                                        : ''
                                                    }</>)
                                                )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col md={12}>
                            <LoadingOverlay
                              active={loadScreen}
                              styles={{
                                overlay: (base) => ({
                                  ...base,
                                  background: '#f1f4f6',
                                  opacity: 1,
                                }),
                                content: (base) => ({
                                  ...base,
                                  color: '#000',
                                }),
                              }}
                              spinner={<Loader active type="ball-pulse" />}
                              text="Loading asset details..."
                            >
                           
                                {subCategoryId < 3 ?<ImageAndLogo
                                    changeTabs={changeTabs}
                                    brand={brand}
                                    handleOpenFileInfo={handleOpenFileInfo}
                                    isInfoTabOpen={isInfoTabOpen}
                                    setIsInfoTabOpen={setIsInfoTabOpen}
                                    subCategoryId={subCategoryId} 
                                    fileListOfAssets={fileListOfAssets} 
                                    setFileListOfAssets={setFileListOfAssets}
                                    assetCategory={props.catId}
                                    />
                                :
                                 <OtherBrandAsset
                                 changeTabs={changeTabs}
                                 allFileListOfAssets={allFileListOfAssets}
                                 brand={brand}
                                 subCategoryId={subCategoryId} 
                                 fileListOfAssets={fileListOfAssets} 
                                 setAllFileListOfAssets={setAllFileListOfAssets}
                                 setFileListOfAssets={setFileListOfAssets}
                                 assetCategory={props.catId}/>
                                 }
                            </LoadingOverlay>
                            </Col>
                        </>
                    }
                </Row>}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};

export default connect(mapStateToProps)(BrandDetails)