import React, {useEffect} from "react";
import { Switch, Route } from 'react-router-dom';
import { connect } from "react-redux";

import urls from '../../../../Utils/URLs'
import AccountOverviewLayout from "./Components/AccountOverviewLayout";
import MyStoresPage from "./Scenes/MyStores/MyStoresPage";
import ProductOverviewPage from "./Scenes/ProductOverview/ProductOverviewPage";
import PageNotFound from "../../../PageNotFound";
import CampaignOverviewPage from "./Scenes/CampaignOverview/CampaignOverviewPage";
import MyStoresPageLoki from "./Scenes/MyStores/MyStoresPageLoki";
import StoreListService
    from "../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
import Can from "../../../Components/Can";
import ReadyCampaignListService
    from "../../../../Service/DashboardServices/AccountOverviewServices/LaunchpadServices/ReadyCampaignListService";


function AccountOverviewPage(props) {

    useEffect(() => {
        props.dispatch(StoreListService(props.token));
        props.dispatch(ReadyCampaignListService(props.token));
    }, []);

    return (
        <AccountOverviewLayout>
            <Can
                role={props.accessLevel}
                perform={"home:simple"}
                yes={() => (
                    <Switch>
                    <Route exact path={`${urls.ACCOUNT_OVERVIEW_MY_STORES_URL}/:storeIntegerID?`} component={MyStoresPageLoki} />
                    <Route exact path={urls.ACCOUNT_OVERVIEW_CAMPAIGN_URL} component={CampaignOverviewPage} />
                    <Route exact path={`${urls.ACCOUNT_OVERVIEW_PRODUCT_URL}/:storeId?/:productASIN?`} component={ProductOverviewPage} />
                    <Route component={PageNotFound} />
                    </Switch>
                )}
                no={() => (
                    <Switch>
                        <Route exact path={urls.ACCOUNT_OVERVIEW_MY_STORES_URL} component={MyStoresPage} />
                        <Route exact path={urls.ACCOUNT_OVERVIEW_CAMPAIGN_URL} component={CampaignOverviewPage} />
                        <Route exact path={`${urls.ACCOUNT_OVERVIEW_PRODUCT_URL}/:storeId?/:productASIN?`} component={ProductOverviewPage} />
                        <Route component={PageNotFound} />
                    </Switch>
                )}
            />
        </AccountOverviewLayout>
    )
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
    token: state.loggedUser.token,
});

export default connect(mapStateToProps)(AccountOverviewPage);
