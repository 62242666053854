import React, { Fragment } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Can, {check} from "../../../../../Components/Can";

const AmazonTitleAndFeaturesEdit = ({ title, features, openComments, accessLevel, openTextField, approveOrReviseProject, isFinalCompleted }) => {

    const getOrderList = (list) => {
        return list.sort((a, b) => {
            return (a.id - b.id)
        })
    }

    const openCommentsModal = (fieldData) => {
        openComments(fieldData, undefined)
    }

    const revisionNeeded = () => {
        let status = false;
        if (title && features.length > 0) {
            status = title.status === 'REVISED' || features.some(f => f.status === 'REVISED')
        }
        return status;
    }

    const getTitle = (title) => {
        return (
            <Fragment>
                {(title) ?
                    <div className="project-title bold grey">{title.field_value} {' '} {title.approved && <i className="fas fa-check-circle f-16 text-success" title="Approved" />}
                        {title.status === 'REVISED' && <i className="fas fa-undo-alt f-16 text-danger" title="Revision Needed" />}
                        {' '}

                        <span className="d-inline-block">
                            {
                                !isFinalCompleted &&
                                <Fragment>
                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            (!title.approved) &&
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {
                                                        (title.status === 'REVISED') &&
                                                        <DropdownItem onClick={() => openCommentsModal(title)} title=" Add Comments">
                                                            <i className="fas fa-comments f-16 comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                        </DropdownItem>
                                                    }

                                                    {(!title.approved) &&
                                                    <Fragment>

                                                        <DropdownItem onClick={() => {
                                                            let tempData = {
                                                                fieldData: title,
                                                                name: 'title',
                                                                placeholder: "Title",
                                                                rows: 2,
                                                                type: 'textarea'
                                                            }
                                                            openTextField(tempData)
                                                        }} title="Edit title">
                                                            Edit Title {' '}<i className="fas fa-edit blue ml-2"></i>

                                                        </DropdownItem>
                                                    </Fragment>
                                                    }


                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    (accessLevel === 0 && !title.approved) &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}
                                    {/*            {*/}
                                    {/*                (title.status === 'REVISED') &&*/}
                                    {/*                <DropdownItem onClick={() => openCommentsModal(title)} title=" Add Comments">*/}
                                    {/*                    <i className="fas fa-comments f-16 comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                    {/*                </DropdownItem>*/}
                                    {/*            }*/}

                                    {/*            {(!title.approved) &&*/}
                                    {/*                <Fragment>*/}
                                    {/*                    */}
                                    {/*                    <DropdownItem onClick={() => {*/}
                                    {/*                        let tempData = {*/}
                                    {/*                            fieldData: title,*/}
                                    {/*                            name: 'title',*/}
                                    {/*                            placeholder: "Title",*/}
                                    {/*                            rows: 2,*/}
                                    {/*                            type: 'textarea'*/}
                                    {/*                        }*/}
                                    {/*                        openTextField(tempData)*/}
                                    {/*                    }} title="Edit title">*/}
                                    {/*                        Edit Title {' '}<i className="fas fa-edit blue ml-2"></i>*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                </Fragment>*/}
                                    {/*            }*/}


                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}
                                    <Can
                                        role={accessLevel}
                                        perform={"project:review"}
                                        yes={() => (
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <Fragment>
                                                        {!title.approved
                                                        &&
                                                        <DropdownItem onClick={() => approveOrReviseProject(title, 'APPROVED', undefined)} title="Approve">
                                                            <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                        </DropdownItem>
                                                        }
                                                        {
                                                            title.status !== 'REVISED' &&
                                                            <DropdownItem onClick={() => openComments(title, undefined, true)} title="Revise">
                                                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                            </DropdownItem>
                                                        }
                                                    </Fragment>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    accessLevel === 3 &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}
                                    {/*            <Fragment>*/}
                                    {/*                {!title.approved*/}
                                    {/*                    &&*/}
                                    {/*                    <DropdownItem onClick={() => approveOrReviseProject(title, 'APPROVED', undefined)} title="Approve">*/}
                                    {/*                        <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*                {*/}
                                    {/*                    title.status !== 'REVISED' &&*/}
                                    {/*                    <DropdownItem onClick={() => openComments(title, undefined, true)} title="Revise">*/}
                                    {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*            </Fragment>*/}
                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}
                                    {/*}*/}
                                </Fragment>

                            }

                        </span>
                    </div>
                    :
                    <div className="my-2">
                        <Can
                            role={accessLevel}
                            perform={"project:edit"}
                            yes={() => (
                                (!isFinalCompleted) &&
                                <Button type="button" color="primary" className="btn-sm f07" onClick={() => {
                                    let tempData = {
                                        fieldData: null,
                                        name: 'title',
                                        placeholder: 'Title',
                                        rows: 2,
                                        type: 'textarea'
                                    }
                                    openTextField(tempData, 'Title')
                                }}><i className="fas fa-plus-circle"></i> Add Title</Button>
                            )}
                        />
                        {/*{*/}
                        {/*    (accessLevel === 0 && !isFinalCompleted) &&*/}
                        {/*    <Button type="button" color="primary" className="btn-sm f07" onClick={() => {*/}
                        {/*        let tempData = {*/}
                        {/*            fieldData: null,*/}
                        {/*            name: 'title',*/}
                        {/*            placeholder: 'Title',*/}
                        {/*            rows: 2,*/}
                        {/*            type: 'textarea'*/}
                        {/*        }*/}
                        {/*        openTextField(tempData, 'Title')*/}
                        {/*    }}><i className="fas fa-plus-circle"></i> Add Title</Button>*/}
                        {/*}*/}

                    </div>

                }
            </Fragment>
        )

    }

    const getFeatures = (feature, i) => {
        return (
            <li key={i} className="features p-l-10">
                <div className="row">
                    <div className="col-md-11 col-11-width">
                        {feature.field_value} {' '}
                        {feature.approved && <i className="fas fa-check-circle f-16 text-success" title="Approved" />}
                        {feature.status === 'REVISED' && <i className="fas fa-undo-alt f-16 text-danger" title="Revision Needed" />}
                        {' '}

                    </div>
                    <div className="col-md-1 col-1-width">
                        <span className="d-inline-block">

                            {!isFinalCompleted &&

                                <Fragment>
                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            (!feature.approved) &&
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className={"fas fa-ellipsis-v"}></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {
                                                        (feature.status === 'REVISED') &&
                                                        <DropdownItem onClick={() => openCommentsModal(feature)} title=" Add Comments">
                                                            <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                        </DropdownItem>
                                                    }

                                                    {(!feature.approved) &&
                                                    <Fragment>
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => {

                                                            let tempData = {
                                                                fieldData: feature,
                                                                name: 'feature' + (i + 1),
                                                                placeholder: 'Feature ' + (i + 1)
                                                            }
                                                            openTextField(tempData, 'Feature ' + (i + 1))

                                                        }} title={"Edit Feature " + (i + 1)}>
                                                            <i className="fas fa-edit blue ml-2"></i> {' '} {"Edit Feature " + (i + 1)}

                                                        </DropdownItem>
                                                    </Fragment>
                                                    }


                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    (accessLevel === 0 && !feature.approved) &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className={"fas fa-ellipsis-v"}></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}
                                    {/*            {*/}
                                    {/*                (feature.status === 'REVISED') &&*/}
                                    {/*                <DropdownItem onClick={() => openCommentsModal(feature)} title=" Add Comments">*/}
                                    {/*                    <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                    {/*                </DropdownItem>*/}
                                    {/*            }*/}

                                    {/*            {(!feature.approved) &&*/}
                                    {/*                <Fragment>*/}
                                    {/*                    <DropdownItem divider />*/}
                                    {/*                    <DropdownItem onClick={() => {*/}

                                    {/*                        let tempData = {*/}
                                    {/*                            fieldData: feature,*/}
                                    {/*                            name: 'feature' + (i + 1),*/}
                                    {/*                            placeholder: 'Feature ' + (i + 1)*/}
                                    {/*                        }*/}
                                    {/*                        openTextField(tempData, 'Feature ' + (i + 1))*/}

                                    {/*                    }} title={"Edit Feature " + (i + 1)}>*/}
                                    {/*                        <i className="fas fa-edit blue ml-2"></i> {' '} {"Edit Feature " + (i + 1)}*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                </Fragment>*/}
                                    {/*            }*/}


                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}
                                    <Can
                                        role={accessLevel}
                                        perform={"project:review"}
                                        yes={() => (
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className={"fas fa-ellipsis-v"}></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>

                                                    <Fragment>
                                                        {!feature.approved
                                                        &&
                                                        <DropdownItem onClick={() => approveOrReviseProject(feature, 'APPROVED', undefined)} title="Approve">
                                                            <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                        </DropdownItem>
                                                        }
                                                        {
                                                            feature.status !== 'REVISED' &&
                                                            <DropdownItem onClick={() => openComments(feature, undefined, true)} title="Revise">
                                                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                            </DropdownItem>
                                                        }
                                                    </Fragment>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    accessLevel === 3 &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className={"fas fa-ellipsis-v"}></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}

                                    {/*            <Fragment>*/}
                                    {/*                {!feature.approved*/}
                                    {/*                    &&*/}
                                    {/*                    <DropdownItem onClick={() => approveOrReviseProject(feature, 'APPROVED', undefined)} title="Approve">*/}
                                    {/*                        <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*                {*/}
                                    {/*                    feature.status !== 'REVISED' &&*/}
                                    {/*                    <DropdownItem onClick={() => openComments(feature, undefined, true)} title="Revise">*/}
                                    {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*            </Fragment>*/}
                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}
                                    {/*}*/}
                                </Fragment>

                            }

                        </span>
                    </div>
                </div>


            </li>
        )
    }

    const getApproveAllAccess = () => {
        let status = false;
        // if (accessLevel === 3) {
        //     if ((title && title.field_value) || features.length > 0)
        //         status = !title.approved || features.some(f => f.approved === false)
        // }
        if (check(accessLevel, "project:review")) {
            if ((title && title.field_value) || features.length > 0)
                status = !title.approved || features.some(f => f.approved === false)
        }


        return status
    }

    const allApproved = () => {
        let status = false;
        if ((title && title.field_value) && features.length > 0)
            status = title.approved && features.every(f => f.approved)
        return status
    }
    const approveSection = () => {
        approveOrReviseProject(undefined, 'APPROVED', 'TITLE_AND_FEATURES')
    }

    return (
        <Fragment>
            <h4 className="heading">Title And Features
                {' '}
                {revisionNeeded() && <span className="badge badge-light-danger capitalize"><i className="fas fa-undo-alt badge-light-danger"></i>  Revision Needed</span>}
                {allApproved() && <span className="green-badge green-badge-approve"><i className="fas fa-check-circle badge-green-icon"></i>  Approved</span>}
                {(getApproveAllAccess() && !isFinalCompleted) && <Button color="link" type="button" onClick={approveSection} className="badge badge-approve fs-8 fw-bolder no-text-dec pointer">Approve All</Button>}
            </h4>
            <br />
            {getTitle(title)}
            <Fragment>
                {(features && features.length > 0) &&
                    <ul>
                        {
                            getOrderList(features).map((feature, i) => {
                                return (
                                    <Fragment key={i}>
                                        {
                                            feature &&

                                            <Fragment>
                                                {
                                                    getFeatures(feature, i)
                                                }

                                            </Fragment>}


                                    </Fragment>
                                )
                            })
                        }

                    </ul>
                }
                <Can
                    role={accessLevel}
                    perform={"project:edit"}
                    yes={() => (
                        (features.length < 5 && !isFinalCompleted) &&

                        <div className="my-2">
                            <Button type="button" color="primary" className="btn-sm f07" size="sm" onClick={() => {
                                let tempData = {
                                    fieldData: null,
                                    name: 'feature' + (features && features.length ? features.length : 1),
                                    placeholder: 'Feature ' + (features && features.length ? features.length : 1)
                                }
                                openTextField(tempData, 'Feature ' + (features && features.length ? features.length : 1))
                            }}><i className="fas fa-plus-circle"></i> Add Feature</Button>
                        </div>
                    )}
                />
                {/*{*/}
                {/*    (accessLevel === 0 && features.length < 5 && !isFinalCompleted) &&*/}

                {/*    <div className="my-2">*/}
                {/*        <Button type="button" color="primary" className="btn-sm f07" size="sm" onClick={() => {*/}
                {/*            let tempData = {*/}
                {/*                fieldData: null,*/}
                {/*                name: 'feature' + (features && features.length ? features.length : 1),*/}
                {/*                placeholder: 'Feature ' + (features && features.length ? features.length : 1)*/}
                {/*            }*/}
                {/*            openTextField(tempData, 'Feature ' + (features && features.length ? features.length : 1))*/}
                {/*        }}><i className="fas fa-plus-circle"></i> Add Feature</Button>*/}
                {/*    </div>*/}
                {/*}*/}
            </Fragment>


        </Fragment>
    )
}

export default AmazonTitleAndFeaturesEdit;
