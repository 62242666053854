import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import CampaignOverviewService
    from "../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/CampaignOverviewService";
import urls from "../../../../../Utils/URLs";
import EditKeywordModal from "../../../Components/Modals/EditKeywordModal";
import CampaignTargetedProductModal from "../../../Components/Modals/CampaignTargetedProductModal";
import UpdateCampaignStageModal from "../Scenes/MyStores/Components/Modals/UpdateCampaignStageModal";
import CustomTabs from "../../../../Components/Nav/CustomTabs";
import CustomTabContent from "../../../../Components/Nav/CustomTabContent";
import UpdateCampaignBudgetModal from "../Scenes/MyStores/Components/Modals/UpdateCampaignBudgetModal";
import PauseCampaignService
    from "../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/PauseCampaignService";
import CampaignChangelogService
    from "../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/CampaignChangelogService";
import ChangelogModal from "../Scenes/MyStores/Components/Modals/Changelog/ChangelogModal";
import CampaignChangelog from "../Scenes/MyStores/Components/Modals/Changelog/CampaignChangelog";
import CampaignDataTable from "../Scenes/MyStores/Components/Tables/CampaignDataTable";

import ExportCampaignKeywordService
    from "../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/ExportCampaignKeywordService";
import {ExportExcel} from "../../../../../Utils/Utilities";


function CampaignList(props) {

    let history = useHistory();
    const [keywordIsOpen, setKeywordIsOpen] = useState(false);
    const [productIsOpen, setProductIsOpen] = useState(false);
    const [stageIsOpen, setStageIsOpen] = useState(false);
    const [budgetIsOpen, setBudgetIsOpen] = useState(false);
    const [changelogIsOpen, setChangelogIsOpen] = useState(false);
    const [campaignsToExport, setCampaignsToExport] = useState([]);
    const [isExportEnabled, setIsExportEnabled] = useState(false);
    const [activeTab, setActiveTab] = useState("1");

    const [campaignUpdate, setCampaignUpdate] = useState({
        campaignID: '',
        campaignStage: '',
        isPhrase: false,
    });

    const [campaigns, setCampaigns] = useState({
        active: [],
        paused: [],
        archived: [],
        waiting: [],
        ready: [],
    });

    const [campaignChangelog, setCampaignChangelog] = useState([]);

    useEffect(() => {
        setCampaigns({
            active: props.campaigns.filter(campaign => campaign.state === 'enabled'),
            paused: props.campaigns.filter(campaign => campaign.state === 'paused'),
            archived: props.campaigns.filter(campaign => campaign.state === 'archived'),
            waiting: props.campaigns.filter(campaign => campaign.state === 'waiting'),
            ready: props.campaigns.filter(campaign => campaign.state === 'ready'),
        })
    }, [props.campaigns]);


    const quickActions = [
        {
            name: ({ state }) => state === 'enabled' ? 'Pause Campaign' : 'Resume Campaign',
            action: ({ name, campaignID, state }) => {
                let update = state === 'enabled' ? 'pause' : 'resume'
                PauseCampaignService(props.token, campaignID, update).then(() => loadCampaignInfo(name, campaignID));
            }
        },
        {
            name: () => 'Manage Keywords',
            action: ({ name, campaignID }) => {
                loadCampaignInfo(name, campaignID);
                setKeywordIsOpen(!keywordIsOpen);
            },
        },
        {
            name: () => 'Update Campaign Stage',
            action: ({ campaignID }) => {
                setCampaignUpdate(prevState => ({
                    ...prevState,
                    campaignID: campaignID,
                }));
                setStageIsOpen(!stageIsOpen);
            }
        },
        {
            name: () => 'Update Campaign Budget',
            action: ({ name, campaignID }) => {
                loadCampaignInfo(name, campaignID);
                setBudgetIsOpen(!budgetIsOpen);
            },
        },
        {
            name: () => 'View Changelog',
            action: ({ campaignID }) => {
                loadCampaignChangelog(campaignID).then(() => setChangelogIsOpen(!changelogIsOpen));
            }
        },
        {
            name: () => "Export Keywords",
            action: ({ name }) => {
                ExportCampaignKeywordService(props.token, [name]).then(exportedKeywords => {
                    ExportExcel(exportedKeywords, name);
                });
            }
        }
    ];

    const loadCampaignInfo = (name, campaignID) => {
        props.dispatch(CampaignOverviewService(props.token, name, campaignID));
    }

    const handleCampaignDetailsRedirect = (name, collectionId) => {
        loadCampaignInfo(name, collectionId);
        history.push(urls.ACCOUNT_OVERVIEW_CAMPAIGN_URL);
    }

    const loadCampaignChangelog = async (campaignID) => {
        let response = await CampaignChangelogService(props.token, campaignID);
        if (response) {
            setCampaignChangelog(response);
        }
    }

    const selectCampaignForExport = (campaignName, isSelected) => {
        if (isSelected) {
            setCampaignsToExport(prevState => [...prevState, campaignName]);
        } else {
            setCampaignsToExport(prevState => prevState.filter(campaign => campaign !== campaignName));
        }

    }

    if (props.campaigns.length === 0) {
        return (
            <h5 className="mt-3">No campaigns found.</h5>
        );
    }

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <Card tabs={true}>
                        <CardHeader>
                            <CustomTabs
                                activeTab={activeTab}
                                toggle={(tab) => setActiveTab(tab)}
                                tabList={["Active", "Paused", "Archived", "Deploying", "Ready to Launch"]}
                            />
                        </CardHeader>
                        {/*<Button*/}
                        {/*    className="btn-icon btn-icon-only ml-auto mt-2 mr-3 p-1"*/}
                        {/*    outline*/}
                        {/*    // color="light"*/}
                        {/*    style={{borderWidth: 0}}*/}
                        {/*    // onClick={() => setIsExportEnabled(!isExportEnabled)}*/}
                        {/*>*/}
                        {/*    <IoIosCloudDownload fontSize="1.5rem"/>*/}
                        {/*</Button>*/}
                        <CustomTabContent
                            activeTab={activeTab}
                            tabList={Object.keys(campaigns).map(key => (
                                <CardBody>
                                    {/*<CampaignWidgetList*/}
                                    {/*    data={campaigns[key]}*/}
                                    {/*    handleRedirect={handleCampaignDetailsRedirect}*/}
                                    {/*    handleQuickActions={quickActions}*/}
                                    {/*    card={false}*/}
                                    {/*/>*/}
                                    <CampaignDataTable
                                        data={campaigns[key]}
                                        quickActions={quickActions}
                                        isExportEnabled={isExportEnabled}
                                        campaignsToExport={campaignsToExport}
                                        selectCampaignForExport={selectCampaignForExport}
                                    />
                                </CardBody>

                            ))}
                            renderTabPane={tab => <Fragment>{tab}</Fragment>}
                        />
                    </Card>
                </Col>
            </Row>
            <EditKeywordModal
                isOpen={keywordIsOpen}
                toggle={() => setKeywordIsOpen(!keywordIsOpen)}
                keywords={props.keywords}
            />
            <CampaignTargetedProductModal
                isOpen={productIsOpen}
                toggle={() => setProductIsOpen(!productIsOpen)}
            />
            <UpdateCampaignStageModal
                isOpen={stageIsOpen}
                toggle={() => setStageIsOpen(!stageIsOpen)}
                campaignUpdate={campaignUpdate}
                setCampaignUpdate={setCampaignUpdate}
                onUpdate={() => props.onCampaignStageUpdate()}
            />
            <UpdateCampaignBudgetModal
                isOpen={budgetIsOpen}
                toggle={() => setBudgetIsOpen(!budgetIsOpen)}
            />
            <ChangelogModal
                isOpen={changelogIsOpen}
                toggle={() => setChangelogIsOpen(!changelogIsOpen)}
                modalHeader={"Campaign"}
                changelogs={[
                    {
                        header: "Campaign Updates",
                        body: <CampaignChangelog campaignUpdates={campaignChangelog} />
                    },
                ]}
            />
        </Fragment>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    keywords: state.accountOverview.campaign.keywords,
    productASIN: state.accountOverview.product.productOverview.asin,
})

export default  connect(mapStateToProps)(CampaignList);
