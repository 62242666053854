import React, { useState, useEffect } from 'react';
import { Button, Progress, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
    BrandOverviewSection,
    BrandGuidelines,
    Customer,
    BrandDetails
} from './CustomerDetailSection';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { toast } from 'react-toastify';
import { customerDetailsSumbmitSection, getCustomerDetails } from '../../../../../../Service/DashboardServices/Projects/ProjectsService';
import {
    Form,
    TextField,
    SubmitButton,
    CustomCheckboxField,
    RangeField,
    MultiTextField,
    FileUpload,
    RadioField
} from './FieldElements';

import { getFilenameWithTimestamp } from '../../../../../../Utils/Utilities';
import {
    BrandOverviewView,
    BrandGuidelinesView,
    CustomerView,
    BrandDetailsView
} from './CustomerDetailsView'
import Can from "../../../../../Components/Can";



const fieldProps = [
    'brand_name',
    'completed_by_fn',
    'completed_by_ln',
    'website',
    'products_market_place',
    'social_links',
    'selling_products_count',
    "brand_mission",
    "describe_your_brand",
    "customer_contact_number",
    "not_include_language",
    "brand_guidlines_documents",
    "brand_logo",
    "branded_images",
    "product_line",
    "customer_segment",
    "buying_behaviour",
    "brand_specialty",
    "attribute_most_describes_1",
    "attribute_most_describes_2",
    "attribute_most_describes_3",
    "attribute_most_describes_4",
    "attribute_most_describes_5",
    "attribute_most_describes_6",
    "attribute_most_describes_7",
    "attribute_most_describes_8",
    "attribute_most_describes_9",
    "describe_brand_voice",
    "image_characteristics_1",
    "image_characteristics_2",
    "image_characteristics_3",
    "image_characteristics_4",
    "image_characteristics_5",
    "image_characteristics_6",
    "image_characteristics_7",
    "image_characteristics_8",
    "image_characteristics_9",
    "image_characteristics_10",
    "image_characteristics_11",
    "other_words_about_your_brand"
]

const CustomerDetails = ({ token, storeId, accessLevel, refreshData, enableStartProjectBtn }) => {

    const [section, setSection] = useState('Brand Overview')
    const [loading, setLoading] = useState(false)
    const [customerData, setCustomerData] = useState(null);
    const [brandStatus, setBrandStatus] = useState(0);
    const [modal, setModal] = useState(false);
    const [fieldSet, setFieldSet] = useState({
        name: '',
        label: '',
        type: '',
        data: null,
        inputType: '',
        validationSchema: null,
        required: false,
        formData: null,
        section: '',
        options: [],
        accept: '',
        multiple: false
    })

    const [formData, setFormData] = useState({})
    const [validationSchema, setValidationSchema] = useState({})
    const [isInitial, setIsInitial] = useState(false)

    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (storeId > 0) {
            setIsInitial(false)
            getCustomerDetailsAllSections();
        }
    }, [storeId])

    const showAlert = (msg, type) => {
        if (type === 'error') {
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        else if (type === 'success') {
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const sumbmitSection = async (payload, url, resetForm) => {
        try {
            setLoading(true)
            const data = await customerDetailsSumbmitSection(payload, url, token)
            resetForm();
            showAlert(data.message, 'success')
            refreshData()
            getCustomerDetailsAllSections()
            setLoading(false)
            if (modal) {
                setModal(!modal)
            }
        } catch (e) {
            setLoading(false)
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }
    }

    const getCustomerDetailsAllSections = async () => {
        try {

            setLoading(true);
            const data = await getCustomerDetails(token, storeId)
            setCustomerData(data)
            setSection('Brand Overview')
            setStatusAndSection(data)
            setLoading(false)
            if (data && !data.is_brand_overview_submitted &&
                !data.is_brand_guide_lines_submitted &&
                !data.is_customer_submitted &&
                !data.is_brand_details_submitted) {
                setIsInitial(true)
            }
        } catch (e) {
            setLoading(false)
            setCustomerData(null)
            setSection('Brand Overview')
              enableStartProjectBtn(false)
            setBrandStatus(0)
            if (e.response) {
                if (e.response.status === 404) {
                    setIsInitial(true)
                }
            }
        }
    }


    const setStatusAndSection = (data) => {
        enableStartProjectBtn(false)
        let count = 0

        // Set Active Section
        if (!data.is_brand_overview_submitted) {
            setSection('Brand Overview');
        }
        else if (!data.is_brand_guide_lines_submitted) {
            setSection('Brand Guideline');
        }
        else if (!data.is_customer_submitted) {
            setSection('Customer');
        }
        else if (!data.is_brand_details_submitted) {
            setSection('Brand Details');
        }
        else {
            setSection('Brand Overview');
        }

        // Set Brand Status
        if (data) {
            fieldProps.forEach(key => {
                if (Array.isArray(data[key])) {
                    if (data[key].length > 0)
                        count++;
                }
                else if (data[key]) {
                    count++
                }

            })
            let statusCount = Math.round((100 / fieldProps.length) * count)
            if(statusCount > 0)
              enableStartProjectBtn(true)
            setBrandStatus(statusCount)

        }


    }

    const editField = (_fieldSet) => {
        setFieldSet(_fieldSet);
        setFormData(_fieldSet.formData);
        setValidationSchema(_fieldSet.validationSchema);
        setModal(true)
    }

    const onFormSubmit = (values, { setSubmitting, resetForm, setStatus }) => {

        let url = '';

        if (fieldSet.section === 'Brand Overview') {
            url = 'onboarding/saveBrandOverView'
        }
        else if (fieldSet.section === 'Brand Guideline') {
            url = 'onboarding/saveBrandGuideLines'
        }
        else if (fieldSet.section === 'Customer') {
            url = 'onboarding/saveCustomerSection'
        }
        else if (fieldSet.section === 'Brand Details') {
            url = 'onboarding/saveBrandDetails'
        }

        if (fieldSet && fieldSet.data) {
            if (fieldSet.inputType === 'file_upload_field' && fieldSet.section === 'Brand Guideline') {

                let formData = new FormData();
                if (values.brand_guidlines_documents && values.brand_guidlines_documents.length > 0) {
                    values.brand_guidlines_documents.forEach(file => {
                        if (file instanceof File)
                            formData.append('brand_guidlines_documents', file, getFilenameWithTimestamp(file.name))
                    });
                }

                if (values.brand_logo && values.brand_logo.length > 0 && values.brand_logo[0] instanceof File) {
                    formData.append('brand_logo', values.brand_logo[0], getFilenameWithTimestamp(values.brand_logo[0].name))
                }
                if (values.branded_images && values.branded_images.length > 0) {
                    values.branded_images.forEach(file => {
                        if (file instanceof File)
                            formData.append('branded_images', file, getFilenameWithTimestamp(file.name))
                    });
                }

                if (values.not_include_language) {
                    formData.append('not_include_language', values.not_include_language)
                }
                else {
                    formData.append('not_include_language', fieldSet.data.not_include_language)
                }
                if (fieldSet.name === 'brand_guidlines_documents') {
                    formData.append('guideline_docs_links', JSON.stringify(values['brand_guidlines_documents'].filter(f => !(f instanceof File))))
                    formData.append('brand_logo_link', JSON.stringify(fieldSet.data['brand_logo'] && !(fieldSet.data['brand_logo'] instanceof File) ? fieldSet.data['brand_logo'] : null))
                    formData.append('branded_images_links', JSON.stringify(fieldSet.data['branded_images'].filter(f => !(f instanceof File))))

                }
                else if (fieldSet.name === 'brand_logo') {
                    formData.append('guideline_docs_links', JSON.stringify(fieldSet.data['brand_guidlines_documents'].filter(f => !(f instanceof File))))
                    formData.append('brand_logo_link', JSON.stringify(values['brand_logo'] && !(values['brand_logo'] instanceof File) ? values['brand_logo'] : null))
                    formData.append('branded_images_links', JSON.stringify(fieldSet.data['branded_images'].filter(f => !(f instanceof File))))
                }
                else if (fieldSet.name === 'branded_images') {
                    formData.append('guideline_docs_links', JSON.stringify(fieldSet.data['brand_guidlines_documents'].filter(f => !(f instanceof File))))
                    formData.append('brand_logo_link', JSON.stringify(fieldSet.data['brand_logo'] && !(fieldSet.data['brand_logo'] instanceof File) ? fieldSet.data['brand_logo'] : null))
                    formData.append('branded_images_links', JSON.stringify(values['branded_images'].filter(f => !(f instanceof File))))
                }

                formData.append('store_integer_id', storeId)
                sumbmitSection(formData, url, resetForm)

            }
            else if (fieldSet.inputType === 'custom_checkbox') {
                if (fieldSet.name === 'products_market_place') {
                    let payload = {
                        ...fieldSet.data, [fieldSet.name]: values[fieldSet.name], store_integer_id: storeId,
                        products_market_place_other_text: values['products_market_place_other_text']
                    }

                    sumbmitSection(payload, url, resetForm)
                }
                else {
                    let payload = {
                        ...fieldSet.data, [fieldSet.name]: values[fieldSet.name], store_integer_id: storeId
                    }

                    sumbmitSection(payload, url, resetForm)
                }
            }
            else if (fieldSet.name === 'social_links') {
                let payload = {
                    ...fieldSet.data,
                    social_links: values['social_links'].map(s => s.socialLink), store_integer_id: storeId
                }
                sumbmitSection(payload, url, resetForm)
            }
            else {
                let payload = {
                    ...fieldSet.data, [fieldSet.name]: values[fieldSet.name], store_integer_id: storeId
                }

                sumbmitSection(payload, url, resetForm)
            }
        }

    }

    const getFormElement = ({ inputType, type, name, label, required, options, data, ...rest }) => {

        if (inputType === 'text_field') {
            return <TextField type={type} name={name} placeholder="" label={label} required={required} />
        }
        else if (inputType === 'text_completeBy') {
            return (
                <div className="row">
                    <div className="col-12 col-md-2">
                        <div className="form-group pb-0 mb-0">
                            <label><strong>Completed By <sup className="text-danger">*</sup></strong></label>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <TextField type="text" name="completed_by_fn" label="First Name" placeholder="First Name" required={true} />
                    </div>
                    <div className="col-12 col-md-5">
                        <TextField type="text" name="completed_by_ln" label="Last Name" placeholder="Last Name" required={true} />
                    </div>
                </div>
            )
        }
        else if (inputType === 'custom_checkbox') {
            return <CustomCheckboxField name={name} options={options} label={label} required={required} limitCount={rest.limitCount} />
        }
        else if (inputType === 'multi_text_field') {
            return <MultiTextField name={name} label={label} required={required} options={options} />
        }
        else if (inputType === 'range_field') {
            return <RangeField name={name} label={label} min={1} max={200} type={type} />
        }

        else if (inputType === 'radio_field') {
            return <RadioField name={name} label={label} required={required} options={options} type={type} />
        }

        else if (inputType === 'file_upload_field') {
            return <FileUpload label={label} name={name}
                updateFilesCb={(files) => { }}
                previousFiles={rest.previousFiles}
                maxFileSizeInBytes={rest.maxFileSizeInBytes}
                totalSizeAllowed={rest.totalSizeAllowed}
                maxFiles={rest.maxFiles}
                onFileError={(err) => { }}
                multiple={rest.multiple}
                accept={rest.accept}
            />
        }


    }

    // if (isInitial && accessLevel === 0
    // ) {
    //     return (
    //         <>
    //             <div className="col-md-12 text-center">
    //                 <span className="badge badge-light-danger fs-8 fw-bolder text-capitalize">Customer yet to fill Brand Profile details</span>
    //             </div>
    //         </>
    //     )
    // }


    return (
        <>
            <LoadingOverlay
                active={loading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: '#f1f4f6',
                        opacity: 1
                    }),
                    content: (base) => ({
                        ...base,
                        color: "#000"
                    })
                }}
                spinner={<Loader active type="ball-pulse" />}
                text='Loading...'
            >
                <Can
                    role={accessLevel}
                    perform={"brandProfile:edit"}
                    no={() => (
                        isInitial &&
                        <>
                            <div className="col-md-12 text-center">
                                <span className="badge badge-light-danger fs-8 fw-bolder text-capitalize">Customer yet to fill Brand Profile details</span>
                            </div>
                        </>
                    )}
                />

                <>
                    <Row>
                        <Col md="5"></Col>
                        <Col md="3">
                            <Progress color="success" value={brandStatus} title={brandStatus + ' %'} />
                        </Col>
                        <Col md="2">
                            <p className="bold">{brandStatus} %</p>
                        </Col>
                        <Col md="2"></Col>
                    </Row>
                    <div className="row border mx-1 customer-section mb-2">

                        <div className="col-12 col-md-3 b-d-r mb-2">
                            <div className="p-0 mt-3">

                                <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Overview' ? "tab-blue" : 'tab-grey')} onClick={(e) => {
                                    setSection('Brand Overview');
                                }}>
                                    <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Overview' ? "tab-blue bg-primary" : 'tab-grey')}></span>
                                    <div className="flex-grow-1">
                                        <button type="button" id="brandOverview" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-warehouse p-r-5"></i> Brand Overview</button>
                                    </div>
                                    {
                                        section === 'Brand Overview' &&
                                        <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>
                                    }

                                </div>
                                <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Guideline' ? "tab-blue" : 'tab-grey')} onClick={(e) => {
                                    setSection('Brand Guideline')
                                }}>
                                    <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Guideline' ? "tab-blue bg-primary" : 'tab-grey')}></span>
                                    <div className="flex-grow-1">
                                        <button type="button" className=" btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-file-contract p-r-5"></i> Brand Guidelines</button>
                                    </div>
                                    {
                                        section === 'Brand Guideline' &&
                                        <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>
                                    }
                                </div>
                                <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Customer' ? "tab-blue" : 'tab-grey')} onClick={(e) => {
                                    setSection('Customer')
                                }}>
                                    <span className={"bullet bullet-vertical h-40px " + (section === 'Customer' ? "tab-blue bg-primary" : 'tab-grey')}></span>
                                    <div className="flex-grow-1">
                                        <button type="button" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-user-tie p-r-5"></i> Customer</button>
                                    </div>
                                    {
                                        section === 'Customer' &&
                                        <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>
                                    }
                                </div>
                                <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Details' ? "tab-blue" : 'tab-grey')} onClick={(e) => {
                                    setSection('Brand Details')
                                }}>
                                    <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Details' ? "tab-blue bg-primary" : 'tab-grey')}></span>
                                    <div className="flex-grow-1">
                                        <button type="button" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-file-invoice p-r-5"></i> Brand Details</button>
                                    </div>
                                    {
                                        section === 'Brand Details' &&
                                        <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-9 fixed-section mb-2">
                            <Can
                                role={accessLevel}
                                perform={"brandProfile:edit"}
                                yes={() => (
                                    <div className="p-0 my-3">
                                        {
                                            (section === 'Brand Overview') &&
                                            <BrandOverviewSection sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />
                                        }

                                        {
                                            (section === 'Brand Guideline') &&
                                            <BrandGuidelines sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />
                                        }
                                        {
                                            section === 'Customer' &&
                                            <Customer sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />
                                        }
                                        {
                                            section === 'Brand Details' &&
                                            <BrandDetails sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />
                                        }
                                    </div>
                                )}
                                no={() => (
                                    <div className="p-0 my-3">
                                        {
                                            (section === 'Brand Overview') &&
                                            <BrandOverviewView sectionData={customerData} />
                                        }
                                        {
                                            (section === 'Brand Guideline') &&
                                            <BrandGuidelinesView sectionData={customerData} />
                                        }
                                        {
                                            (section === 'Customer') &&
                                            <CustomerView sectionData={customerData} />
                                        }
                                        {
                                            (section === 'Brand Details') &&
                                            <BrandDetailsView sectionData={customerData} />
                                        }
                                    </div>
                                )}
                            />
                        </div>

                    </div>

                </>

                {/*{*/}
                {/*    isInitial && accessLevel === 0 ?*/}
                {/*        <>*/}
                {/*            <div className="col-md-12 text-center">*/}
                {/*                <span className="badge badge-light-danger fs-8 fw-bolder text-capitalize">Customer yet to fill Brand Profile details</span>*/}
                {/*            </div>*/}
                {/*        </>*/}
                {/*        :*/}
                {/*        <>*/}
                {/*            <Row>*/}
                {/*                <Col md="5"></Col>*/}
                {/*                <Col md="3">*/}
                {/*                    <Progress color="success" value={brandStatus} title={brandStatus + ' %'} />*/}
                {/*                </Col>*/}
                {/*                <Col md="2">*/}
                {/*                    <p className="bold">{brandStatus} %</p>*/}
                {/*                </Col>*/}
                {/*                <Col md="2"></Col>*/}
                {/*            </Row>*/}
                {/*            <div className="row border mx-1 customer-section mb-2">*/}

                {/*                <div className="col-12 col-md-3 b-d-r mb-2">*/}
                {/*                    <div className="p-0 mt-3">*/}

                {/*                        <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Overview' ? "tab-blue" : 'tab-grey')} onClick={(e) => {*/}
                {/*                            setSection('Brand Overview');*/}
                {/*                        }}>*/}
                {/*                            <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Overview' ? "tab-blue bg-primary" : 'tab-grey')}></span>*/}
                {/*                            <div className="flex-grow-1">*/}
                {/*                                <button type="button" id="brandOverview" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-warehouse p-r-5"></i> Brand Overview</button>*/}
                {/*                            </div>*/}
                {/*                            {*/}
                {/*                                section === 'Brand Overview' &&*/}
                {/*                                <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>*/}
                {/*                            }*/}

                {/*                        </div>*/}
                {/*                        <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Guideline' ? "tab-blue" : 'tab-grey')} onClick={(e) => {*/}
                {/*                            setSection('Brand Guideline')*/}
                {/*                        }}>*/}
                {/*                            <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Guideline' ? "tab-blue bg-primary" : 'tab-grey')}></span>*/}
                {/*                            <div className="flex-grow-1">*/}
                {/*                                <button type="button" className=" btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-file-contract p-r-5"></i> Brand Guidelines</button>*/}
                {/*                            </div>*/}
                {/*                            {*/}
                {/*                                section === 'Brand Guideline' &&*/}
                {/*                                <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                        <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Customer' ? "tab-blue" : 'tab-grey')} onClick={(e) => {*/}
                {/*                            setSection('Customer')*/}
                {/*                        }}>*/}
                {/*                            <span className={"bullet bullet-vertical h-40px " + (section === 'Customer' ? "tab-blue bg-primary" : 'tab-grey')}></span>*/}
                {/*                            <div className="flex-grow-1">*/}
                {/*                                <button type="button" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-user-tie p-r-5"></i> Customer</button>*/}
                {/*                            </div>*/}
                {/*                            {*/}
                {/*                                section === 'Customer' &&*/}
                {/*                                <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                        <div className={"d-flex align-items-center mb-8 pointer " + (section === 'Brand Details' ? "tab-blue" : 'tab-grey')} onClick={(e) => {*/}
                {/*                            setSection('Brand Details')*/}
                {/*                        }}>*/}
                {/*                            <span className={"bullet bullet-vertical h-40px " + (section === 'Brand Details' ? "tab-blue bg-primary" : 'tab-grey')}></span>*/}
                {/*                            <div className="flex-grow-1">*/}
                {/*                                <button type="button" className="btn btn-link text-gray-800 text-hover-primary fw-bolder fs-6 p-l-10 no-text-dec"><i className="fas fa-file-invoice p-r-5"></i> Brand Details</button>*/}
                {/*                            </div>*/}
                {/*                            {*/}
                {/*                                section === 'Brand Details' &&*/}
                {/*                                <span className="badge badge-light-primary fs-8 fw-bolder pointer"><i className="fas fa-chevron-right fa-2x"></i></span>*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                    </div>*/}

                {/*                </div>*/}
                {/*                <div className="col-12 col-md-9 fixed-section mb-2">*/}
                {/*                    {*/}
                {/*                        accessLevel === 3 &&*/}
                {/*                        <div className="p-0 my-3">*/}
                {/*                            {*/}
                {/*                                (section === 'Brand Overview') &&*/}
                {/*                                <BrandOverviewSection sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />*/}
                {/*                            }*/}

                {/*                            {*/}
                {/*                                (section === 'Brand Guideline') &&*/}
                {/*                                <BrandGuidelines sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                section === 'Customer' &&*/}
                {/*                                <Customer sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                section === 'Brand Details' &&*/}
                {/*                                <BrandDetails sumbmitSection={sumbmitSection} storeId={storeId} sectionData={customerData} editSectionField={editField} />*/}
                {/*                            }*/}
                {/*                        </div>*/}

                {/*                    }*/}
                {/*                    {*/}
                {/*                        accessLevel === 0 &&*/}
                {/*                        <div className="p-0 my-3">*/}
                {/*                            {*/}
                {/*                                (section === 'Brand Overview') &&*/}
                {/*                                <BrandOverviewView sectionData={customerData} />*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                (section === 'Brand Guideline') &&*/}
                {/*                                <BrandGuidelinesView sectionData={customerData} />*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                (section === 'Customer') &&*/}
                {/*                                <CustomerView sectionData={customerData} />*/}
                {/*                            }*/}
                {/*                            {*/}
                {/*                                (section === 'Brand Details') &&*/}
                {/*                                <BrandDetailsView sectionData={customerData} />*/}
                {/*                            }*/}
                {/*                        </div>*/}
                {/*                    }*/}

                {/*                </div>*/}

                {/*            </div>*/}

                {/*        </>*/}

                {/*}*/}

            </LoadingOverlay>

            <div>
                <Modal isOpen={modal} toggle={toggle} className="modal-lg" keyboard={false} backdrop="static">
                    <ModalHeader toggle={toggle}>{fieldSet.section}</ModalHeader>
                    <ModalBody>

                        {
                            fieldSet && fieldSet.uploadedSize &&
                            <span className="bold text-warning my-3 py-3">You have uploaded {fieldSet.uploadedSize} of file(s) </span>
                        }

                        {
                            fieldSet.data &&
                            <div className="">
                                <br />
                                <Form
                                    enableReinitialize
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={onFormSubmit}
                                >
                                    <div className="form-group">
                                        {getFormElement(fieldSet)}
                                    </div>

                                    <SubmitButton className="btn btn-primary btn-sm my-2 float-right"
                                        title="Submit"
                                    />
                                </Form>
                            </div>
                        }


                    </ModalBody>
                </Modal>
            </div>

        </>
    )
}

export default CustomerDetails;
