import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { BroadcastChannel } from "broadcast-channel";
import { Entropy, charset64 } from 'entropy-string';
import { connect } from 'react-redux';

import { SPAPI_APP_ID } from "../../../../../../../Utils/Constants";
import StoreAuthorizationService
    from "../../../../../../../Service/DashboardServices/UserProfileServices/StoreAuthorizationService";


const entropy = new Entropy({ charset: charset64 });
const state = entropy.token();
const redirect = "https://app.simpliworks.io/storeAuthRedirect";
//const redirect = "https://raiden.simpliworks.io/storeAuthRedirect";
//const redirect = "https://dev.simpliworks.io/storeAuthRedirect";

function AuthButtonSPAPI(props) {

    const receiveChannel = new BroadcastChannel("spapi_auth");
    const sendChannel = new BroadcastChannel("status");

    const handleStoreAuth = (oauthCode, sellingPartnerID) => {
        props.dispatch(StoreAuthorizationService(props.token, oauthCode, sellingPartnerID, props.region));
    }

    receiveChannel.onmessage = msg => {
        if (msg.state === state) {
            handleStoreAuth(msg.oauthCode, msg.sellingPartnerID);
            sendChannel.postMessage("success").then(() => {
                sendChannel.close();
                receiveChannel.close();
            });
        } else {
            sendChannel.postMessage("failure");
        }
    }

    return (
        <Link
            to={{
                pathname: props.pathname,
                //search: `application_id=${SPAPI_APP_ID}&redirect_uri=${redirect}&state=${state}`,
                search: `application_id=${SPAPI_APP_ID}&redirect_uri=${redirect}&state=${state}&version=beta`,
            }}
            target="_blank"
        >
            <Button color="brand-blue-dark">Authorize</Button>
        </Link> 
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(AuthButtonSPAPI);