import React, { Fragment, useState, useEffect } from 'react';
import { getPorducts } from '../../../../../../Service/DashboardServices/Projects/ProjectsService';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import {
    Row, Col,
} from 'reactstrap';
import ProductItem from './ProductItem'

const ProductsModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])

    const getPorducts__ = async () => {
        setLoading(true);
        const data = await getPorducts(props.token, props.storeId)
        setProducts(data);
        setLoading(false);
    }

    const onSelectProduct = (product) => {
        props.onSelectProduct(product);
    }

    useEffect(() => {
        getPorducts__()
    }, [])



    if (loading) {
        return (
            <Row>
                <Col md="12">
                    <LoadingOverlay
                        active={loading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#f1f4f6',
                                opacity: 1,
                                zIndex: 10000
                            }),
                            content: (base) => ({
                                ...base,
                                color: "#000"
                            })
                        }}
                        spinner={<Loader active type="ball-pulse" />}
                        text='Loading...'
                    >
                    </LoadingOverlay>

                </Col>
            </Row>
        )
    }

    return (
        <Fragment>
            <div>
                {products.map((product, index) => {
                    return <ProductItem product={product} key={index} onSelectProduct={onSelectProduct} />
                })}

                {products.length === 0 &&
                    <div className="text-danger text-center p-2">
                        No Products Found
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default ProductsModal;