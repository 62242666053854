import React from 'react';
import { Row, Col } from 'reactstrap';

import UnclaimedCampaignWidgetList from "../../../../../../../Components/Widgets/Campaigns/UnclaimedCampaignWidgetList";


function ImportCampaignsTab(props) {
    return (
        <Row>
            <Col md="12">
                <h5 className="text-brand-green-dark">Unclaimed Campaigns</h5>
            </Col>
            <Col md="11" className="ml-2 mb-3">
                <h6>Campaigns that were not launched through Simpliworks appear here. Click on a campaign and enter the product ASIN
                    associated with it to import it to your Simpliworks account.</h6>
            </Col>
            <Col md="12">
                <UnclaimedCampaignWidgetList
                    data={props.unclaimedCampaigns}
                    handleImportCampaign={props.handleImportCampaign}
                    card={true}
                />
            </Col>
        </Row>
    )
}

export default ImportCampaignsTab;