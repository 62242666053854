import React, {useEffect} from 'react';
import {loadStripe} from "@stripe/stripe-js";
import { connect } from 'react-redux';
import {CURRENT_STRIPE_PK} from "../../../../../../Utils/Constants";
import notify from "../../../../../../Notifications/ToastifyActions";
import CreateSubscriptionService from "../../../../../../Service/PaymentServices/CreateSubscriptionService";
import {Col, Row} from "reactstrap";
import addOns from "../../../../../../Utils/AddOns";
import AddOnsCard from "./Components/AddOnsCard";
import CreateOneTimePaymentService from "../../../../../../Service/PaymentServices/CreateOneTimePaymentService";


const stripePromise = loadStripe(CURRENT_STRIPE_PK);

function AddOnsPage(props) {

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            notify.NotifySuccess("Success! Your purchase will be updated soon.");
        }
        if (query.get("canceled")) {
            notify.NotifyError("Your order has been canceled.");
        }
    }, []);

    const handleCheckoutRedirect = async (priceID) => {
        const stripe = await stripePromise;
        const session = await CreateOneTimePaymentService(props.token, priceID);
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            notify.NotifyError(result.error.message);
        }
    }

    return (
        <Row>
            {
                addOns.map((addOn, idx) => (
                    <Col md={"auto"} key={idx}>
                        <AddOnsCard
                            {...addOn}
                            handleCheckout={handleCheckoutRedirect}
                            enablePurchaseButton={addOn.id !== "productTracking"}
                        />
                    </Col>
                ))
            }
        </Row>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(AddOnsPage);