import React, { Fragment } from 'react';
import CustomWidget from "../../../../Components/Widgets/CustomWidget";
import { Button } from "reactstrap";


function SearchProductWidget(props) {
    const { handleSelectProduct, ...searchProduct } = props;
    const { title, image, asin, storeName, location } = searchProduct;

    const selectProductButton = () => (
        <Button
            key={asin}
            color="primary"
            onClick={() => handleSelectProduct(searchProduct)}
            size="sm"
        >
            Select
        </Button>
    )

    const subheading = () => (
        <Fragment>
            <div>ASIN: {asin}</div>
            <div>Store: {storeName}</div>
            <div>Location: {location}</div>
        </Fragment>
    )


    return (
        <CustomWidget
            collapsible={false}
            heading={title}
            image={image}
            subheading={subheading()}
            autoFormat={true}
            content={selectProductButton()}
        />
    )
}

export default SearchProductWidget;