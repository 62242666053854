import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function RetryErrorReportV3Service(token, data) {
    
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/RetryErrorReportV3FromUI`, data, {
        
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Error Report are getting requested again."))      
        .then(res => {
            notify.NotifySuccess("Error Report are requested again.")
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while requestting Error Reports.");
            return false;
        });
}

export {
    RetryErrorReportV3Service,
}