import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";

import ProductListService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductListService";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import ProductSelector from "../../../../../Components/ProductSelector";
import { SetAutofillFields } from "../../../../../../../Redux/Actions/Actions";


function ProductSelectorModal(props) {

    const [productList, setProductList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        if (isOpen) {
            if (productList === null) {
                setLoading(true);

                async function getProductList() {
                    let productList = await ProductListService(props.token);
                    setProductList(productList);
                }

                getProductList().then(() => setLoading(false));
            } else {
                setLoading(false);
            }
        } else {
            setProductList(null);
        }
    }, [productList, props.token, isOpen]);

    const handleSelectProduct = ({ asin, sku, storeId, location}) => {
        // props.dispatch(SetAutofillFields({
        //     productASIN: asin,
        //     productSKU: sku,
        //     sellerProfileId: integerID,
        //     country: country,
        // }));
        props.setValues({
            ...props.values,
            productASIN: asin,
            productSKU: sku,
            sellerProfileId: storeId,
            country: location,
        });
        toggle();
    }


    return (
        <Fragment>
            <Button
                color="secondary"
                onClick={() => toggle()}
            >
                Select a product
            </Button>
            <CustomModal isOpen={isOpen} toggle={toggle} header="Which product would you like to build a campaign for?" size="lg">
                <LoadingOverlay
                    active={loading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 1
                        }),
                        content: (base) => ({
                            ...base,
                            color: "#000"
                        })
                    }}
                    spinner={<Loader active type="ball-pulse"/>}
                    text='Loading store details...'
                >
                    { (!loading && productList !== null)  &&
                        <ProductSelector data={productList} handleSelectProduct={handleSelectProduct} />
                    }
                </LoadingOverlay>
            </CustomModal>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps)(ProductSelectorModal);