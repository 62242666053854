import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetHistSPAPIDataTaskService(token, sellerPartnerDetails) {
    console.log(sellerPartnerDetails)
    // id in productDetails is either a field called asin or a field called integerId
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getHistoricalSPAPIDataTask`, sellerPartnerDetails, {
        headers: {
            'Authorization': token,
        }
    }).then( notify.NotifySuccess("Historical SPAPI Sales data task is getting submitted."))
        .then(res => {
            notify.NotifySuccess("Historical SPAPI Sales data fetch job submitted successfully.");
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while submitting Historical SPAPI Sales data fetch job, please try again later.");
            return false;
        });
}


export {
    GetHistSPAPIDataTaskService,
}

