import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function PauseCampaignService(token, campaignID, update) {
    return axios.post(`${CURRENT_API_URL}/campaign/pauseCampaign`, {
        campaignID: campaignID,
        update: update
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Campaign paused successfully!");
        return true;
    }).catch(() => {
        notify.NotifyError("There was an error while updating pausing your campaign, please try again.");
        return false;
    })
}

export default PauseCampaignService;