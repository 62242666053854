import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import SummaryCard from "./SummaryCard";


function CampaignSummaryCard(props) {

    const { image, title, asin, dailyBudget, sku, status, type, collectionID, name } = props.campaignOverview;

    const header = () => (
        <Fragment>
            Campaign Summary
            { status === 'ready' &&
            <Button
                className="ml-4"
                outline
                color="success"
                onClick={() => props.handleLaunch(name, collectionID)}
            >
                Launch Campaign
            </Button>
            }
        </Fragment>
    );

    return (
        <SummaryCard
            header={header()}
            image={image}
            title={title}
            body={[
                {label: "ASIN", value: asin},
                {label: "SKU", value: sku},
                {label: "Daily Budget", value: `$${dailyBudget}`},
                {label: "Status", value: status === 'ready' ? 'Ready to Launch' : status === 'enabled' ? 'Active' : status},
                {label: "Product Targeting", value: type === 'both' ? 'Enabled' : 'Disabled'},
            ]}
        />
    );
}


export default CampaignSummaryCard;