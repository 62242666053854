import React, { useEffect, useState, Fragment, useRef } from 'react';
import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
    useFormik
} from 'formik';

import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel
} from "../../../../../../Scenes/Components/Fileupload/file-upload.styles";


export const Form = (props) => {
    return (
        <Formik
            {...props}
        >
            <FormikForm className="needs-validation" noValidate="">
                {props.children}
            </FormikForm>
        </Formik>)
}

export const TextField = (props) => {
    const { name, type, label, placeholder, required, ...rest } = props
    return (
        <>
            {label && <label htmlFor={name}>
                <strong>{label}
                    {
                        required &&
                        <sup className="text-danger">*</sup>
                    }

                </strong>
            </label>}
            <Field
                className="form-control"
                type={type}
                name={name}
                id={name}
                placeholder={placeholder || ""}
                {...rest}
            />
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}

export const SelectField = (props) => {
    const { name, label, options, required } = props
    return (
        <>
            {label && <label htmlFor={name}>
                <strong>{label}
                    {
                        required &&
                        <sup className="text-danger">*</sup>
                    }
                </strong>
            </label>}
            <Field
                as="select"
                id={name}
                name={name}
            >
                <option value="" >Choose...</option>
                {options.map((optn, index) => <option value={optn.value} label={optn.label || optn.value} />)}
            </Field>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    )
}


export const CustomCheckboxField = (props) => {
    const { name, type, label, placeholder, limitCount, required, options } = props

    const { values } = useFormikContext();

    return (
        <>
            {label && <label className="d-block mt-2" htmlFor={name}>
                <strong>{label}
                    {
                        required &&
                        <sup className="text-danger">*</sup>
                    }
                </strong>
            </label>}
            <div className="my-2 d-block">
                {
                    options.map((option, index) =>
                        <div key={index} className="form-check">
                            <Field type="checkbox" className="form-check-input" disabled={limitCount > 0 && !values[name].includes(option.value) && limitCount === values[name].length} id={`${name}_${index}`} name={name} value={option.value} />
                            <label className="form-check-label" htmlFor={`${name}_${index}`}>
                                {option.label}
                            </label>
                        </div>
                    )
                }
            </div>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />

            {
                (values && values[name] && values[name].includes('Other')) &&
                (
                    <div className="form-group">
                        <TextField type='text' name="products_market_place_other_text" label="Others" />
                    </div>
                )
            }

        </>
    )
}

export const RangeField = (props) => {
    const { values } = useFormikContext()
    const { name, type, label, placeholder, required, ...rest } = props
    return (
        <>
            {label && <label className="mt-2" htmlFor={name}><strong>{label}
                {
                    required &&
                    <sup className="text-danger">*</sup>
                }
            </strong></label>}
            <div className="row">
                <div className="col-12 col-md-11 pt-1">
                    <Field
                        className="w-100"
                        type={type}
                        name={name}
                        id={name}
                        min={rest.min}
                        max={rest.max}
                        placeholder={placeholder || ""}
                        {...rest}
                    />

                    <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
                </div>
                <div className="col-12 col-md-1">
                    {
                        values && values.selling_products_count > 0 &&
                        <span className="bold">{values && values.selling_products_count}</span>
                    }

                </div>
            </div>
        </>
    )
}

export const MultiTextField = (props) => {
    const { name, type, label, placeholder, required, options } = props
    const [socialLinks, setSocialLinks] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setSocialLinks(options)
    }, [options])

    const addNewField = (index) => {
        let tempLinks = socialLinks;
        tempLinks.push({
            "type": "url",
            "label": "Other " + (index + 1),
            "name": "socialLink",
            "maxLength": 2000
        })

        setSocialLinks(tempLinks);
        setCurrentIndex(index);
    }

    const removeField = (index) => {
        let tempLinks = [...socialLinks];
        tempLinks.splice(index, 1)
        setSocialLinks(tempLinks)
        setCurrentIndex(index)
    }

    return (
        <>
            {label && <label className="mt-2" htmlFor={name}><strong>{label}
                {
                    required &&
                    <sup className="text-danger">*</sup>
                }
            </strong></label>}

            <div className="my-2 row">
                {
                    socialLinks.map((option, index) =>
                        <Fragment key={index}>
                            <div className="col-12 col-md-8 mb-3">
                                <TextField type={option.type} name={`${name}.${index}.${option.name}`} label={option.label} />
                            </div>
                            <div className="col-12 col-md-4 mt-4">
                                {
                                    index >= 3 &&
                                    <button type="button" onClick={(e) => addNewField(index)} className="btn btn-link btn-sm mr-2">Add</button>
                                }
                                {
                                    index > 3 &&
                                    <button type="button" onClick={(e) => removeField(index)} className="btn btn-link text-danger btn-sm mr-2">Remove</button>
                                }

                            </div>
                        </Fragment>
                    )
                }
            </div>
            {/* <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} /> */}

        </>
    )
}

export const RadioField = (props) => {
    const { name, type, label, placeholder, required, options } = props

    return (
        <>
            {label && <label className="d-block mt-2" htmlFor={name}><strong>{label}
                {
                    required &&
                    <sup className="text-danger">*</sup>
                }
            </strong></label>}

            <div className="my-2 d-block">
                {
                    options.map((option, index) =>
                        <div key={index} className="form-check form-check-inline">
                            <Field type="radio" className="form-check-input" id={`${name}_${index}`} name={name} value={option.value} />
                            <label className="form-check-label" htmlFor={`${name}_${index}`}>
                                {option.label}
                            </label>
                        </div>
                    )
                }

                <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
            </div>
        </>
    )
}


export const SubmitButton = (props) => {
    const { title, ...rest } = props;
    const { isSubmitting, errors, submitForm } = useFormikContext();

    return (
        <button type="submit" {...rest}

            disabled={isSubmitting}>{title}</button>
    )
}


export const FileUpload = (props) => {
    const KILO_BYTES_PER_BYTE = 1000;
    const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
    const MAX_FILES_ALLOWED = 10;

    const formik = useFormikContext();

    const convertNestedObjectToArray = (nestedObj) =>
        Object.keys(nestedObj).map((key) => nestedObj[key]);

    const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

    const {
        label,
        name,
        updateFilesCb,
        previousFiles,
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        totalSizeAllowed,
        maxFiles = MAX_FILES_ALLOWED,
        onFileError,
        required,
        ...otherProps
    } = props

    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);


        //formik.setFieldValue(name, [...filesAsArray, ...prevFiles])
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length && newFiles.length <= maxFiles) {
            let regex = new RegExp('[^.]+$');
            let extension = ""
            let fileNotImage = false
            let totalSize = 0
            for (let f of newFiles) {
                extension = f.name.match(regex)
                let acceptedFiles = otherProps.accept.split(',').map(s => s.toLowerCase().trim())
                if (!acceptedFiles.includes(`.${extension[0].toLowerCase()}`)) {
                    //if(extension[0].toLowerCase() !== 'png' && extension[0].toLowerCase() !== 'jpg' && extension[0].toLowerCase() !== 'jpeg'){
                    fileNotImage = true;
                    break;
                }

                totalSize = totalSize + f.size
            }

            if (fileNotImage) {
                formik.setFieldError(name, `Only ${otherProps.accept} formats are allowed`)
                onFileError(`Only ${otherProps.accept} formats are allowed`);
                return;
            }

            if (totalSizeAllowed && totalSizeAllowed > 0 && totalSize > totalSizeAllowed) {
                formik.setFieldError(name, `Only ${convertBytesToKB(totalSizeAllowed)} MB is allowed`)
                onFileError(`Only ${convertBytesToKB(totalSizeAllowed)} MB is allowed`);
                return;
            }
            let updatedFiles = addNewFiles(newFiles);

            formik.setFieldValue(name, [...formik.values[name], ...newFiles])
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
        else {
            onFileError(`Only ${maxFiles} images are allowed`);
        }
    };

    const removeFile = (index) => {
        // delete files[fileName];
        // setFiles({ ...files });
        // callUpdateFilesCb({ ...files });
        let tempFiles = formik.values[name]
        tempFiles.splice(index, 1)
        formik.setFieldValue(name, tempFiles)
    };

    return (
        <>
            <FileUploadContainer>
                <InputLabel><strong>{label}
                    {
                        required &&
                        <sup className="text-danger">*</sup>
                    }
                </strong></InputLabel>
                <DragDropText>Drag and drop your files anywhere or</DragDropText>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <i className="fas fa-file-upload" />
                    <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadContainer>
            <FilePreviewContainer>
                {/* <span>To Upload</span> */}
                <PreviewList>{
                    formik.values[name] &&
                    <>
                        {formik.values[name].map((file, index) => {
                            let fileName = file.name;
                            let isImageFile = false;
                            let isFile = false;
                            if (file instanceof File || file instanceof Blob) {
                                isFile = true;
                                isImageFile = file.type.split("/")[0] === "image";
                            }
                            else if (file instanceof Object) {
                                isFile = false;
                                isImageFile = ['image/png', 'image/jpg', 'image/jpeg'].includes(file.file_type)
                            }
                            return (
                                <PreviewContainer key={index}>
                                    {
                                        isFile ?
                                            <div>
                                                {isImageFile && (
                                                    <ImagePreview
                                                        src={URL.createObjectURL(file)}
                                                        alt={`file preview ${index}`}
                                                    />
                                                )}
                                                <FileMetaData isImageFile={isImageFile}>
                                                    <span>{file.name}</span>
                                                    <aside>
                                                        <span>{convertBytesToKB(file.size)} kb</span>
                                                        <RemoveFileIcon
                                                            className="fas fa-trash-alt"
                                                            onClick={() => removeFile(fileName)}
                                                        />
                                                    </aside>
                                                </FileMetaData>
                                            </div>

                                            :
                                            <div>
                                                {isImageFile && (
                                                    <ImagePreview
                                                        src={file.file_path}
                                                        alt={`file preview ${index}`}
                                                    />
                                                )}
                                                <FileMetaData isImageFile={isImageFile}>
                                                    <span>{file.file_name}</span>
                                                    <aside>
                                                        <span>{convertBytesToKB(file.file_size)} kb</span>
                                                        <RemoveFileIcon
                                                            className="fas fa-trash-alt"
                                                            onClick={() => {
                                                                let tempFiles = [...formik.values[name]];
                                                                tempFiles.splice(index, 1);
                                                                formik.setFieldValue(name, tempFiles)
                                                                // removeFile(file.file_name)
                                                            }}
                                                        />
                                                    </aside>
                                                </FileMetaData>
                                            </div>
                                    }

                                </PreviewContainer>
                            );
                        })}
                    </>
                }
                </PreviewList>
            </FilePreviewContainer>
            <ErrorMessage name={name} render={msg => <div style={{ color: 'red' }} >{msg}</div>} />
        </>
    );
}
