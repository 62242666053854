import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';


/**
 * Props:
 * activeTab - the currently selected tab, determines which tab pane is shown
 * toggle - callback function used when a tab is clicked, takes in a tab id and sets the activeTab to the one with the given id
 * tabList - array of components to render as each tab
 * renderTabs (optional) - if provided, this callback function is used to render the tabs. Function should take in a tabList and return the rendered tabs.
 * emptyMessage - the message to display when tabList has a length of zero
 */
function CustomTabs(props) {

    const { activeTab, toggle } = props;

    const renderTabs = (tabList) => {
        if (tabList.length) {
            return (
                <Nav justified>
                    { tabList.map( (tab, i) => (
                        <NavItem key={i+1} style={props.navItemStyles ? props.navItemStyles : {}}>
                            <NavLink
                                className={activeTab === `${i+1}` ? "active" : ""}
                                onClick={() => toggle(`${i+1}`)}
                                style={props.navLinkStyles ? props.navLinkStyles : {}}
                            >
                                {tab}
                            </NavLink>
                        </NavItem>
                        ))
                    }
                </Nav>
            );
        }
    }

    return (
        props.tabList.length > 0 ?
                props.renderTabs ? props.renderTabs(props.tabList) : renderTabs(props.tabList)
                :
                <h6 className="mb-2 mt-2">{props.emptyMessage}</h6>
    );
}


export default CustomTabs;
