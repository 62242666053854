import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadCategoryProductsSellers } from "../../../../Redux/Actions/Actions";


function CategoryProductsAndSellersService(token, categoryID) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_CATEGORY_PRODUCTS_SELLERS_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/FetchAndSaveAllProductsSellersForCategory/${categoryID}`,
            {
                headers: {
                    'Authorization': token,
                }
            })
            .then(res => {
                console.log(res.data)
                dispatch(LoadCategoryProductsSellers(res.data.productList, res.data.sellerList))
            })
            .catch(() => {
                dispatch({ type: actionType.LOAD_CATEGORY_PRODUCTS_SELLERS_FAILED });
            });

    }
}

export default CategoryProductsAndSellersService;