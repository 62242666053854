import React, {useState, useEffect} from 'react';
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import CustomWidget from "../../../../../../Components/Widgets/CustomWidget";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import CustomLoadingOverlay from "../../../../../../Components/Nav/CustomLoadingOverlay";
import DateRangePicker from "../../../../../../Components/Input/DateRangePicker";


const defaultWidgetStyles = [
    "bg-happy-green text-white",
    "bg-premium-dark text-white",
    "bg-night-fade text-white",
    "bg-arielle-smile",
];


function TimeFilteredDataDisplay(props) {

    const {
        data,
        dataFields,
        fetchData,
        defaultStartDate,
        defaultEndDate,
    } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [daysBack, setDaysBack] = useState(data.timeInterval ? data.timeInterval : 7);
    const [dateRange, setDateRange] = useState({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    });

    const widgetStyles = props.widgetStyles ? props.widgetStyles : defaultWidgetStyles;

    // useEffect(() => {
    //     if (!props.hideDateSelection) {
    //         fetchData(daysBack);
    //     }
    // }, [daysBack])


    const addNumCommas = (num) => {
        if (typeof num === "string" && num[0] === "$") {
            let newNum = parseFloat(num.replace(/\$/, ''))
            if (isNaN(newNum)) {
                newNum = 0;
            }
            return `$${newNum.toLocaleString("en-US")}`;
        } else {
            let newNum = parseFloat(num);
            if (isNaN(newNum)) {
                newNum = 0;
            }
            return newNum.toLocaleString("en-US");
        }
    }

    const timeIntervalOptions = [7, 14, 30, 60];

    const renderDateSelectionButtons = () => (
        <ButtonGroup size="md">
            {
                timeIntervalOptions.map((interval, idx) => (
                    <Button
                        key={idx}
                        color="brand-blue-dark"
                        outline
                        active={data.timeInterval === interval}
                        onClick={() => setDaysBack(interval)}
                    >
                        {interval}-day
                    </Button>
                ))
            }
        </ButtonGroup>
    );

    const renderDateRangeSelection = () => (
        <DateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
        />
    )

    return (
        <>
            {
                !props.hideDateSelection &&
                <Row>
                    <Col className="mb-3" md="auto">
                        {renderDateRangeSelection()}
                    </Col>
                    <Col className="mb-3" md="auto">
                        <Button
                            className="mt-4"
                            color={"brand-blue-dark"}
                            onClick={() => fetchData(dateRange.startDate, dateRange.endDate)}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
            }
            <Row>
                {
                    widgetStyles.map((widgetStyle, idx) => (
                        <Col className="mb-3" lg="12" xl="6" key={idx}>
                            <CustomWidget
                                className={`card widget-content ${widgetStyle}`}
                                heading={dataFields[idx].heading}
                                content={
                                    <div className="widget-numbers">
                                        {addNumCommas(dataFields[idx].value)}
                                    </div>
                                }
                            />
                        </Col>
                    ))
                }
            </Row>
            <Row>
                <Col md={{size: "auto"}}>
                    <Button
                        color={"light"}
                        outline
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        More Details
                    </Button>
                </Col>
            </Row>

            <CustomModal
                header={props.modalHeader}
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
            >
                <Row>
                    <Col className="mb-3" md="12" lg="12">
                        {
                            dataFields.map((item, idx) => (
                                <CustomWidget
                                    key={idx}
                                    className="card widget-content mb-2"
                                    heading={item.heading}
                                    content={
                                        <div className="widget-numbers">
                                            {addNumCommas(item.value)}
                                        </div>
                                    }
                                />
                            ))
                        }
                    </Col>
                    <Col className="mb-3" md="12" lg="12">
                        {props.children}
                    </Col>
                </Row>
            </CustomModal>
        </>
    )
}

export default TimeFilteredDataDisplay;
