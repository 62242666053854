import React from 'react';
import ReactTable from "react-table";


function ProductBudgetUpdateTable(props) {

    const columns = [
        {
            Header: "Product",
            columns: [
                {
                    Header: "ASIN",
                    accessor: "asin",
                },
            ],
        },
        {
            Header: "Store",
            columns: [
                {
                    Header: "Name",
                    accessor: "store",
                },
                {
                    Header: "ID",
                    accessor: "integerId",
                },
            ],
        },        
        {
            Header: "Budget Update Id",
            accessor: "productBudgetUpdateId",
        },      
        {
            Header: "Old Budget",
            accessor: "oldBudget",
        },
        {
            Header: "New Budget",
            accessor: "newBudget",
        },
        {
            Header: "Update Time",
            accessor: "updateTime",
        },
    ];


    return (
        <ReactTable
            data={props.data}
            columns={columns}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped"
        />
    );
}


export default ProductBudgetUpdateTable;