import actionType from '../Actions/ActionTypes';


const initialState = {
    autofillFields: {
        productASIN: '',
        advertisingObjective: '',
        dailyBudget: 50.00,
        bidPriceExact: 1.00,
        bidPricePhrase: 0.75,
        sellerProfileId: '',
        productSKU: '',
        country: '',
        productTargetingEnabled: false,
        bidPriceTargeting: 1.00,
    },
};

function CampaignBuilderReducer(state=initialState, action) {
    switch(action.type) {
        case actionType.SET_AUTOFILL_FIELD:
            return {
                ...state,
                autofillFields: {
                    ...state.autofillFields,
                    ...action.autofill,
                }
            }
        case actionType.RESET_AUTOFILL_FIELD:
            return {
                ...initialState,
            }
        default:
            break;
    }

    return state;
}

export default CampaignBuilderReducer;