import React, { Fragment } from 'react';
import rules from "../../Utils/rbacRules";

/**
 * Checks if the user has a
 * @param role
 * @param action
 * @param data
 * @returns {boolean|*}
 */
export const check = (role, action, data) => {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule not provided for action
        return true;
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

export const filterByPermission = (array, role, data) => {
    let filtered = array.map(item => check(role, item.perform, data) ? item.yes : item.no ? item.no : undefined);
    return filtered.filter(item => item !== undefined);
}


function Can(props) {
    return (
        <Fragment>
            {
                check(props.role, props.perform, props.data)
                ? props.yes()
                : props.no()
            }
        </Fragment>

    )
}


Can.defaultProps = {
    yes: () => null,
    no: () => null,
};

export default Can;