import actionType from '../Actions/ActionTypes';


const initialState = {
    user: {},
    provider: '',
    isAuth: false,
}

function LWAReducer(state = initialState, action) {
    if (action.type === actionType.AMZN_LOGIN) {
        return {
            user: action.user,
            provider: action.currentProvider,
            isAuth: action.isAuth,
        };
        // keep user and provider, but signal that we want to begin amazon logout by changing isAuth to false
    } else if (action.type === actionType.TRIGGER_AMZN_LOGOUT) {
        return {
            user: state.user,
            provider: state.provider,
            isAuth: action.isAuth,
        };
        // LWA button has completed amazon logout through the sdk, can safely clear the user and provider
    } else if (action.type === actionType.FINISH_AMZN_LOGOUT) {
        return {
            user: action.user,
            provider: action.currentProvider,
            isAuth: action.isAuth,
        };
    }

    return state;
}

export default LWAReducer;
