import React, { Fragment } from 'react';
import { Row, Col, TabContent, TabPane } from 'reactstrap';


/**
 * Props:
 * activeTab - the id of the currently selected tab
 * tabList - array of objects used to render the contents of each tab pane
 * renderTabContent (optional) - if provided, this callback function is used to render each item in tabList within each TabPane
 * renderTabPane (required if renderTabContent is not provided) - function that renders the contents of the tab pane
 * emptyMessage - a message to display when tabList has a length of zero
 */
function CustomTabContent(props) {

    const { activeTab } = props;

    const renderTabContent = (tabList) => {
        if (!tabList || !props.renderTabPane) return;   // exit if either prop is invalid

        return tabList.map( (tab, i) => (
            <TabPane tabId={`${i+1}`} key={i}>
                {props.renderTabPane(tab)}
            </TabPane>
        ));
    }

    return (
        <Fragment>
            { props.tabList.length > 0 ?
                <TabContent activeTab={activeTab}>
                    { props.renderTabContent ? props.renderTabContent(props.tabList) : renderTabContent(props.tabList) }
                </TabContent>
                :
                <Row>
                    <Col className="mt-5 text-center" md="12">
                        <h5>{props.emptyMessage}</h5>
                    </Col>
                </Row>
            }
        </Fragment>
    );
}

export default CustomTabContent;
