import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
import { Card, Button, Col, Input, Label, Row } from "reactstrap";
import { connect } from 'react-redux';

function StoresAsinDataTable(props) {

    const innerColumns = [
        {
            Header: 'Store',
            accessor: 'name',
        },
        {
            Header: 'Integer ID',
            accessor: 'integerID',
        },
        {
            Header: 'Account ID',
            accessor: 'stringID',
        },
        {
            Header: 'Marketplace',
            accessor: 'location',
        },
        {
            Header: 'Currency',
            accessor: 'currencyCode',
        },
        {
            Header: 'Daily Budget',
            accessor: 'dailyBudget',
        },
    ];
    
    const subInnerColumns = [
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        {
            Header: 'Name',
            accessor: 'productTitle',
        },
        {
            Header: 'Integer ID',
            accessor: 'integerID',
        },
        {
            Header: 'Marketplace',
            accessor: 'location',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Price',
            accessor: 'itemPrice',
        },
        {
            Header: 'Rating',
            accessor: 'itemRating',
        },
        {
            Header: 'Link',
            accessor: 'link',
            Cell: props =><a          
                            href={props.value}
                            target="_blank"
                            rel="noopener noreferrer"
                        > 
                            {props.value} 
                        </a>
            
        }, 
    ];


    return (
        
        <div className="p-5">
            <ReactTable
                data={props.stores}
                columns={innerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />
            <p>
            
            &ensp;

            </p>
            <ReactTable
                data={props.managedProducts}
                columns={subInnerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />


        </div>
    


);
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
}) 



export default connect(mapStateToProps)(StoresAsinDataTable)


