import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import actionType from "../../Redux/Actions/ActionTypes";
import { LoginUser, RegistrationFailed } from "../../Redux/Actions/Actions";


function ContentAgencyRegistrationService(user) {
    return function (dispatch) {
        dispatch({
            type: actionType.LOGIN_BEGIN,
            newUser: false,
        });

        axios.post(`${CURRENT_API_URL}/account/registerContentAgency`, user)
            .then(res => {
                if (res.status === 201) {
                    dispatch(LoginUser(res.data.user, res.data.token, res.data.accessLevel, []));
                }
            })
            .catch(error => {
                    if (error.response) {
                        error.response.status === 400 ?
                            dispatch(RegistrationFailed("A user with that e-mail address already exists."))
                            :
                            dispatch(RegistrationFailed("An error occurred while creating your account, please try again."));
                    } else if (error.request) {
                        dispatch(RegistrationFailed("We are having trouble connecting to the server. Please try again later."));
                    } else {
                        dispatch(RegistrationFailed("We're sorry, but we're not sure what went wrong. Please try again later."));
                    }
                }
            );
    }
}

export default ContentAgencyRegistrationService;