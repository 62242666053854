import React, { Fragment, useEffect, useState } from 'react';
import {
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
} from '../../../../../../Scenes/Components/Fileupload/file-upload.styles';
import { productLine, buyingBehaviour, brandVoiceOpts, setBrandImage, setAttribute } from '../../../../../../Utils/Utilities';


export const BrandOverviewView = (props) => {

    const { sectionData } = props;

    return (
        <>
            {
                sectionData &&
                <div className="AppEdit">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Brand Name?</strong>
                            </p>
                            <p className="pb-1 mb-1">{sectionData.brand_name ? sectionData.brand_name : 'N/A'}</p>
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Completed by?</strong>
                            </p>
                            {
                                sectionData.completed_by_fn && sectionData.completed_by_ln &&
                                <p className="pb-1 mb-1">{sectionData.completed_by_fn} {' '} {sectionData.completed_by_ln}</p>
                            }

                            {
                                !sectionData.completed_by_fn && !sectionData.completed_by_ln &&
                                <p className="pb-1 mb-1">N/A</p>
                            }
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Website?</strong>
                            </p>
                            <p className="pb-1 mb-1">{sectionData.website ? sectionData.website : 'N/A'}</p>
                        </div>
                        <br />
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Where do you currently sell your products?</strong>
                            </p>
                            <div className="pb-1 mb-1">
                                {
                                    sectionData.products_market_place && sectionData.products_market_place.length > 0 ?
                                        <ul>
                                            {sectionData.products_market_place.map((d, key) =>
                                                <li key={key}>{d}</li>
                                            )}
                                            {
                                                sectionData.products_market_place_other_text &&
                                                <li>{sectionData.products_market_place_other_text}</li>
                                            }
                                        </ul>
                                        :
                                        <p className="pb-1 mb-1">N/A</p>

                                }

                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Where can customers find your company?</strong>
                            </p>
                            <div className="pb-1 mb-1">
                                {
                                    sectionData.social_links && sectionData.social_links.length > 0 ?
                                        <ul>
                                            {sectionData.social_links.map((d, key) =>
                                                <Fragment key={key}>
                                                    {d && <li>{d}</li>}
                                                </Fragment>
                                            )}
                                        </ul>
                                        :
                                        <p className="pb-1 mb-1">N/A</p>

                                }

                            </div>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>How many products do you actively sell across your channels?</strong>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.selling_products_count > 0 ? sectionData.selling_products_count : 'N/A'}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>What is your Brand’s mission</strong>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.brand_mission ? sectionData.brand_mission : 'N/A'}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>Describe your brand in just one or two sentences</strong>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.describe_your_brand ? sectionData.describe_your_brand : 'N/A'}
                            </p>
                        </div>
                        <div className="col-12 col-md-12 mb-3">
                            <p className="pb-0 mb-0">
                                <strong>What is the best number to contact you regarding project questions?</strong>
                            </p>
                            <p className="pb-1 mb-1">
                                {sectionData.customer_contact_number ? sectionData.customer_contact_number : 'N/A'}
                            </p>
                        </div>
                    </div>
                </div>

            }
        </>

    )
}


export const BrandGuidelinesView = (props) => {
    const { sectionData } = props;


    return (
        <>
            {
                sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <p className="pb-0 mb-0">
                            <strong>If you have your brand guidelines in a document, please upload here?</strong>
                        </p>
                        {
                            sectionData.brand_guidlines_documents && sectionData.brand_guidlines_documents.length > 0 ?

                                <FilePreviewContainer>
                                    <PreviewList>
                                        {
                                            sectionData.brand_guidlines_documents.map((file, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            file &&
                                                            <PreviewContainer>
                                                                <FileMetaData isImageFile={false}>
                                                                    <span>{file.file_name}</span>
                                                                    <aside>
                                                                        <span>{Math.round(file.file_size / 1000)} kb</span>
                                                                        <a href={file.file_path} target="_blank" className="text-white bold">
                                                                            <RemoveFileIcon
                                                                                className="fas fa-file-download" />
                                                                        </a>
                                                                    </aside>
                                                                </FileMetaData>
                                                            </PreviewContainer>
                                                        }

                                                    </Fragment>

                                                )
                                            })

                                        }
                                    </PreviewList>
                                </FilePreviewContainer>
                                :
                                <p className="pb-1 mb-1">N/A</p>
                        }
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="pb-0 mb-0">
                            <strong>Are there any claims or specific language we should NOT use when describing your products?</strong>
                        </p>
                        <p className="pb-1 mb-1">
                            {sectionData.not_include_language ? sectionData.not_include_language : 'N/A'}
                        </p>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="pb-0 mb-0">
                            <strong>Brand Logo</strong>
                        </p>
                        {
                            sectionData.brand_logo ?

                                <FilePreviewContainer>
                                    <PreviewList>
                                        <PreviewContainer>
                                            <div>
                                                <ImagePreview
                                                    src={sectionData.brand_logo.file_path}
                                                    alt={`file preview ${sectionData.brand_logo.file_name}`}
                                                />
                                                <FileMetaData isImageFile={true}>
                                                    <span>{sectionData.brand_logo.file_name}</span>
                                                    <aside>
                                                        <span>{Math.round(sectionData.brand_logo.file_size / 1000)} kb</span>
                                                        <a href={sectionData.brand_logo.file_path} target="_blank" className="text-white bold">
                                                            <RemoveFileIcon
                                                                className="fas fa-file-download" />
                                                        </a>
                                                    </aside>
                                                </FileMetaData>
                                            </div>
                                        </PreviewContainer>
                                    </PreviewList>
                                </FilePreviewContainer>
                                :
                                <p className="pb-1 mb-1">N/A</p>
                        }
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="pb-0 mb-0">
                            <strong>Branded Images?</strong>
                        </p>
                        {
                            sectionData.branded_images && sectionData.branded_images.length > 0 ?

                                <FilePreviewContainer>
                                    <PreviewList>
                                        {
                                            sectionData.branded_images.map((file, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            file &&
                                                            <PreviewContainer >
                                                                <div>
                                                                    <ImagePreview
                                                                        src={file.file_path}
                                                                        alt={`file preview ${file.file_name}`}
                                                                    />
                                                                    <FileMetaData isImageFile={true}>
                                                                        <span>{file.file_name}</span>
                                                                        <aside>
                                                                            <span>{Math.round(file.file_size / 1000)} kb</span>
                                                                            <a href={file.file_path} target="_blank" className="text-white bold">
                                                                                <RemoveFileIcon
                                                                                    className="fas fa-file-download" />
                                                                            </a>
                                                                        </aside>
                                                                    </FileMetaData>
                                                                </div>
                                                            </PreviewContainer>
                                                        }

                                                    </Fragment>
                                                )
                                            })

                                        }
                                    </PreviewList>
                                </FilePreviewContainer>
                                :
                                <p className="pb-1 mb-1">N/A</p>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export const CustomerView = (props) => {
    const { sectionData } = props

    const getCustomFields = (field, value) => {
        if (field === 'product_line') {
            let tempData_1 = productLine.find(p => p.value === value)
            if (tempData_1) return tempData_1.label;
            return ''
        }
        else if (field === 'buying_behaviour') {
            let tempData_2 = buyingBehaviour.find(p => p.value === value)
            if (tempData_2) return tempData_2.label;
            return ''
        }

        return ''
    }

    return (
        <>
            {
                sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Which attributes would most describe your product line?</strong>

                        </p>
                        <div className="pb-1 mb-1">
                            {
                                sectionData.product_line && sectionData.product_line.length > 0 ?
                                    <ul>
                                        {
                                            sectionData.product_line.map(p => {
                                                return <li key={p} >{getCustomFields('product_line', p)}</li>
                                            })
                                        }
                                    </ul>
                                    :
                                    <p className="pb-1 mb-1">N/A</p>
                            }



                        </div>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you describe your top two customer segments?</strong>

                        </p>
                        <p className="pb-1 mb-1">{sectionData.customer_segment ? sectionData.customer_segment : 'N/A'}</p>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you describe your customers buying behaivior?</strong>

                        </p>
                        <div className="pb-1 mb-1">
                            {
                                sectionData.buying_behaviour && sectionData.buying_behaviour.length > 0 ?
                                    <ul>
                                        {
                                            sectionData.buying_behaviour.map(p => {
                                                return <li key={p} >{getCustomFields('buying_behaviour', p)}</li>
                                            })
                                        }
                                    </ul>
                                    :
                                    <p className="pb-1 mb-1">N/A</p>
                            }

                        </div>

                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>What specifically drives customers to your brand?</strong>
                        </p>
                        <p className="pb-1 mb-1">{sectionData.brand_specialty ? sectionData.brand_specialty : 'N/A'}</p>
                    </div>
                </div>
            }
        </>
    )
}


export const BrandDetailsView = (props) => {

    const { sectionData } = props;
    const [brandVoice, setBrandVoice] = useState('')

    useEffect(() => {
        if (sectionData && sectionData.is_brand_details_submitted) {
            let tempBrandV = brandVoiceOpts.find(b => b.value === sectionData.describe_brand_voice)
            if (tempBrandV) setBrandVoice(tempBrandV.label);

        }
    }, [sectionData])


    return (
        <>
            {
                sectionData &&
                <div className="row">
                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Attributes</h6>
                    </div>
                    {
                        Object.keys(setAttribute).map((key, index) => {
                            return (
                                <div className="col-12 col-md-12 mb-3" key={index}>
                                    <p className="d-flex justify-content-between pb-0 mb-0">
                                        <strong>{setAttribute[key].label}</strong>
                                    </p>
                                    <p className="pb-1 mb-1">{sectionData[key] ? sectionData[key] : 'N/A'}</p>
                                </div>
                            )
                        })
                    }

                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Voice</h6>
                    </div>
                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>How would you best describe your brand voice?</strong>
                        </p>
                        <p className="pb-1 mb-1">{brandVoice ? brandVoice : 'N/A'}</p>
                    </div>
                    <div className="col-12 col-md-12">
                        <h6 className="border-bottom bold pb-1">Image</h6>
                    </div>
                    {
                        Object.keys(setBrandImage).map((key, index) => {
                            return (
                                <div className="col-12 col-md-12 mb-3" key={index}>
                                    <p className="d-flex justify-content-between pb-0 mb-0">
                                        <strong>{setBrandImage[key].label}</strong>
                                    </p>
                                    <p className="pb-1 mb-1">{sectionData[key] ? sectionData[key] : 'N/A'}</p>
                                </div>
                            )
                        })
                    }

                    <div className="col-12 col-md-12 mb-3">
                        <p className="d-flex justify-content-between pb-0 mb-0">
                            <strong>Would you use any other words to describe your brand?</strong>
                        </p>
                        <p className="pb-1 mb-1">{sectionData.other_words_about_your_brand ? sectionData.other_words_about_your_brand : 'N/A'}</p>
                    </div>


                </div>
            }
        </>
    )

}