import { combineReducers } from 'redux';

import SellerProfilesReducer from './SellerProfilesReducer';
import StoreReducer from './StoreReducer';
import ProductReducer from './ProductReducer';
import CampaignReducer from './CampaignReducer';

export default combineReducers({
	landing: SellerProfilesReducer,
	store: StoreReducer,
	product: ProductReducer,
	campaign: CampaignReducer,
});
