import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Input, Button, CardFooter } from 'reactstrap'
import { connect } from 'react-redux';
import CustomModal from '../../../../../../../../../../Components/Modals/CustomModal';
import Rename from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Rename.svg'
import UploadButton_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/UploadButton_Dark.svg'
import Comment_Dark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Comment_Dark.svg'
import TickMark from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/TickMark.svg'
import Download from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Download.svg'
import Close from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Close.svg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTimes, faAngleDown, faAngleUp, faCloudUploadAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Send from '../../../../../../../../../../../designUtils/Storeoverview/BuildBrand/AssetsGallery/Send.svg'
import addAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/addAssetNoteService';
import getAssetNoteService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/getAssetNoteService';
import RenameFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/RenameFileAssetNewService';
import DeleteFileAssetNewService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/DeleteFileAssetNewService';
import linkImageToGAFileService from '../../../../../../../../../../../Service/DashboardServices/AssetGalleryServices/linkImageToGAFileService';


function AmazonProductLiveImages(props) {
    const [imageUrl, setImageUrl] = useState(false)
    const [isOpengalleryPopUp, setIsOpengalleryPopUp] = useState(false)
    const [isAssetPopUp, setsAssetPopUp] = useState(false)
    const [selectedFileObject, setSelectedFileObject] = useState();
    const [editedFileName, setEditedFileName] = useState();
    const [isClickEditName, setIsClickEditName] = useState(false);
    const [isSaveButton, setIsSaveButton] = useState(true)
    const [isDeletePopUpOPen, setIsDeletePopUpOPen] = useState()
    const [isCommentsPopUpOPen, setIsCommentsPopUpOPen] = useState()
    const [isExpandAiFile, setIsExpandAiFile] = useState(false)
    const [isUploadAiFIlesUi, setIsUploadAiFIlesUi] = useState(false)
    const [selectedGraphicFiles, setSelectedGraphicFiles] = useState([]);
    const [dragging, setDragging] = useState(false);
    const [alertMessageAi, setAlertMessageAi] = useState('');
    const [allOriginalFiles, setAllOriginalFiles] = useState([]);
    const [handleLinkFile, setHandleLinkFile] = useState(false);
    const [note, setNote] = useState();
    const [noteList, setNoteList] = useState([])
    const [fileForDelete, setFileForDelete] = useState('');
    const [fileType, setFileType] = useState();
    const [isDeleteConfirmPopUpOPen, setIsDeleteConfirmPopUpOPen] = useState();
console.log(props)
  
    return (
        <>
                    <Row className=''>
                        {props.filteredProductList && Object.values(props.filteredProductList).map((asset) => (
                            <>
                            {
                                 props.assetIdentifier === asset.asin?(
                                    <Col sm={4} md={3} onClick={() => { setSelectedFileObject(asset);}} >

                                    <Card 
                                        style={{ backgroundColor: selectedFileObject && selectedFileObject.id === asset.id ? '#D0EFB1' : '' }} className={` p-2 mt-2 mb-1 `}
                                    >
                                        <div>
                                        <a href={asset.itemImage} target='_blank'> <img src={asset.itemImage} alt='not found' width={'100%'} height={'100%'} className='amazon-live-images' /></a>
                                        </div>
                                        <hr/>
                                        <a href={asset.link} target='_blank'><p className='text-center'>{asset.asin}</p></a>
                                    </Card>
                                    </Col>
                                 ):''
                                  
                            }
                            </>
                           
                          
                        ))
                        }
                    </Row>

        </>
  )
}
const mapStateToProps = (state) => {
    return {
        token: state.loggedUser.token,
        accessLevel: state.loggedUser.accessLevel,
        storeOverview: state.accountOverview.store.storeOverview,
    };
};
export default connect(mapStateToProps)(AmazonProductLiveImages)
