import React from 'react';
import { Button, Col, Input, Label, Row } from "reactstrap";
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import {DropdownList} from "react-widgets";
import HelpTooltip from '../../../../../../Components/Tooltips/HelpTooltip';
import { UpdateAllAsinSkusTaskService } from '../../../../../../../Service/OPSServices/UpdateAllAsinSkusTaskService';

function QuickLaunchCampaignModal(props) {

    const {
        isCampaignOpen,
        setIsCampaignOpen,
        newCampaignDetails,
        setNewCampaignDetails,
        handleQuickLaunchCampaign,
        token,integerId } = props;

    const onLaunch = async () => {
        setIsCampaignOpen(!isCampaignOpen);
        let response = await handleQuickLaunchCampaign(newCampaignDetails.productASIN, newCampaignDetails.productSKU, newCampaignDetails.dailyBudget);
        if (response.isFirstCampaign) {

        } else if (response) {
            setIsCampaignOpen(!isCampaignOpen);
        }
    }
    const updateSKUs=async()=>{
        let data = {id : props.integerId}
        let response = await UpdateAllAsinSkusTaskService(props.token,data);  
        if(response)
        {
            console.log(response)   
        }
    }
    return (
        <CustomModal
            header="1-Click Campaign"
            isOpen={isCampaignOpen}
            toggle={() => setIsCampaignOpen(!isCampaignOpen)}
            className='curve-popup'
            footer={
                <Button
                    style={{ backgroundColor: '#533FD1', color: '#fff' }} 
                    onClick={() => onLaunch()}
                    disabled={newCampaignDetails.productSKU === '' || newCampaignDetails.dailyBudget === 0}
                >
                    Launch Campaign
                </Button>
            }
        >
            <Row>
                <Col className="mb-3" md="12">
                    <h6>Simpliworks will manage and optimize bids for this product's 1-Click campaigns to maximize ROAS (Return On Ad Spend).</h6>
                </Col>
                <Col className="mb-3" md="12">
                    <Label>Select Product SKU <span><a onClick={()=>updateSKUs()} href='#'>&#40;<u>Update Skus</u>&#41;</a></span></Label>
                    <DropdownList
                        data={newCampaignDetails.skuList ? newCampaignDetails.skuList : []}
                        value={newCampaignDetails.productSKU}
                        textField="value"
                        onChange={value => setNewCampaignDetails(prevState => ({
                            ...prevState,
                            productSKU: value,
                        }))}
                        placeholder="Product SKU"
                    />
                </Col>
                <Col md="12">
                    <Label for="dailyBudget">Total Daily Budget ($)</Label>
                    <HelpTooltip
                        className={"ml-1"}
                        target="dailyBudget"
                        placement={"auto"}
                        style={{display: "inline"}}
                    >
                        <p>
                            The total budget that will be automatically allocated across all Simpliworks managed campaigns for this product.
                        </p>
                    </HelpTooltip>
                    <Input
                        id="dailyBudget"
                        placeholder="Enter the new campaign's daily budget"
                        value={newCampaignDetails.dailyBudget}
                        onChange={(e) => {
                            let value = e.target.value;
                            setNewCampaignDetails(prevState => ({
                                ...prevState,
                                dailyBudget: value,
                            }))
                        }}
                    />
                </Col>
            </Row>
        </CustomModal>
    )
}

export default QuickLaunchCampaignModal;