import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row, FormFeedback } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import { ValidateResetTokenService, PasswordResetConfirmService } from "../../../../../Service/EntryServices/AccountRecoveryService";
import urls from "../../../../../Utils/URLs";
import notify from '../../../../../Notifications/ToastifyActions';


function PasswordConfirmForm(props) {

    let history = useHistory();

    const [state, setState] = useState({
        resetToken: props.match.params.token,
        isTokenValid: false,
        newPassword: '',
        confirmNewPassword: '',
    });

    const validateToken = async () => {
        let response = await ValidateResetTokenService(state.resetToken);
        setState(prevState => ({
            ...prevState,
            isTokenValid: response,
        }));
    }

    useEffect( () => {
        validateToken();
    }, []);

    const handleChange = e => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    }

    const handleSubmit = async () => {
        let isResetSuccess = await PasswordResetConfirmService(state.resetToken, state.newPassword);
        if (isResetSuccess) {
            notify.NotifySuccess("Your password was reset successfully! You can now login with your new password.")
            history.push(urls.LOGIN_URL);
        } else {
            notify.NotifyError("We've encountered a problem while resetting your password. Please try again later.")
        }
    }

    const renderNewPasswordForm = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="app-logo mx-auto mb-3"/>
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div className="mb-2">Password Reset</div>
                            <span>Please enter your new password below.</span>
                        </h4>
                    </div>
                    <div className="divider" />
                    <Form>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="newPassword">New Password</Label>
                                    <Input
                                        id="newPassword"
                                        name="newPassword"
                                        type="password"
                                        value={state.newPassword}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="confirmNewPassword">Confirm New Password</Label>
                                    <Input
                                        id="confirmNewPassword"
                                        name="confirmNewPassword"
                                        type="password"
                                        value={state.confirmNewPassword}
                                        onChange={handleChange}
                                        invalid={state.newPassword !== state.confirmNewPassword}
                                    />
                                    <FormFeedback>Passwords must match</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="modal-footer clearfix">
                    <div className="float-right">
                        <Button
                            color="primary"
                            size="lg"
                            onClick={handleSubmit}
                            disabled={state.newPassword === '' || state.newPassword !== state.confirmNewPassword}
                        >
                            Reset Password
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderExpiredTokenError = () => {
        return (
            <div className="modal-content">
                <div className="modal-body">
                    <div className="app-logo mx-auto mb-3"/>
                    <div className="h5 modal-title text-center">
                        <h4 className="mt-2">
                            <div className="mb-2">Password Reset</div>
                            <span>This password reset link is invalid or expired.</span>
                        </h4>
                    </div>
                </div>
                <div className="modal-footer clearfix">
                    <div className="float-right">
                        <Link className="btn-lg btn btn-primary" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>
                            Request New Link
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        state.isTokenValid ? renderNewPasswordForm() : renderExpiredTokenError()
    );
}

export default PasswordConfirmForm;


