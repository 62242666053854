import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetSellerPartnerSettingsService(token) {
   
    // id in productDetails is either a field called asin or a field called integerId
    return axios.get(`${CURRENT_API_URL}/sellerPartner/getsellerPartnerSettings`,{
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while Seller Partner Data, please try again later.");
            return false;
        });
}


export {
    GetSellerPartnerSettingsService,
}

