import React, { useEffect, useState } from "react";
import {  Container,  Row,  Col,  Card,  CardBody,  Button,  Label,  Input,  CardTitle,} from "reactstrap";
import {connect} from 'react-redux';
import LoadingOverlay from "react-loading-overlay";
import Loader from 'react-loaders';
import { useParams, useHistory } from "react-router-dom";
import urls from "../../../../../../Utils/URLs";

import PowerbiEmbed from "../../Components/PowerBI/PowerbiEmbed";
import ProductOverviewService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService";
import StoreOverviewCard from "../../../AccountOverviewRelaunch/Scenes/MyStores/Components/Cards/StoreOverviewCard";
import StoreTabs from "../../../AccountOverviewRelaunch/Scenes/MyStores/Components/Nav/StoreTabs";

import StoreOverviewService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";

function StoreOverviewHome(props) {
  const [store, setStore] = useState(props.store);
  const [storeIntegerID,setProfileId] = useState()

  // let { storeIntegerID } = props.match.params;
  const [storeName, setStoreName] = useState("");
  const history = useHistory();

  var table = "FinalReportMerge_ProfileId";
  var column = "IntegerId";
  useEffect(()=>{
    setProfileId(props.storeIntegerID)
  },[])
  const showReport = () =>{ 
    return(<PowerbiEmbed
      tbl_name={table}
      col_name={column}
      responseURL={props.StoreOverviewReport}
      profileId={props.storeIntegerID}
    />)
  }
  const [selectedProducts, setSelectedProducts] = useState([]);
  // toggles product selection for later fetching of performance details
  const toggleSelect = (isSelected, asin, isParentProduct) => {
    console.log('In toggleSelect');
    console.log('isParentProduct');
    console.log(isParentProduct);
    console.log('isSelected');
    console.log(isSelected);
    console.log('asin');
    console.log(asin);
    console.log('selectedProducts')
    console.log(selectedProducts)
    if (isSelected) {
        if (isParentProduct) {
            setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}]); // if parent product selected, clear selectedProducts array and add parent product and dummy child product
        } else {
            if (selectedProducts.length > 0 && selectedProducts[0].isParentProduct) {   // if parent product is the only selection
                setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, ...prevState])
            } else if (selectedProducts.length > 1 && selectedProducts[1].isParentProduct) {    // if a child product was already selected
                setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, prevState[1]])
            } else {
                setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}])   // no previous selection
            }
        }
    } else {
        if (isParentProduct) {
            setSelectedProducts([]); // clears array if parent product is unselected
        } else {
            setSelectedProducts(prevState => prevState.filter(product => product.ASIN !== asin));   // removes product with given ASIN from selectedProducts array
        }
    }
    console.log('selectedProducts')
    console.log(selectedProducts)
};
useEffect(() => {
if (selectedProducts.length > 0) {
    // if selected product is not a parent product get rank data from product overview service
    if (!selectedProducts[0].isParentProduct) {
        props.dispatch(
            ProductOverviewService(
                props.token,
                selectedProducts[0].ASIN,
                store.integerID
            )
        );
    }
}
}, [selectedProducts]);
useEffect(() => {
  if (store) {
    props.dispatch(
      StoreOverviewService(
        props.token,
        store.integerID,
        store.details.accountID
      )
    );
    setStoreName(store.name);
  }
}, [props.token, store]);
// useEffect(() => {
//   // redirect to first store in submenu if no store was selected
//   if (!storeIntegerID && store) {
//     history.push(`${urls.STORE_OVERVIEW_MY_STORES_URL}/${store.integerID}`);
//   } else if (props.adProfiles && storeIntegerID) {
//     setStore(
//       props.adProfiles.find((profile) => profile.integerID == storeIntegerID)
//     );
//   } else if (props.adProfiles) {
//     setStore(props.adProfiles[0]);
//   }
// }, [history, store, props.adProfiles, storeIntegerID]);
  return (
    <>
     
      <LoadingOverlay
            active={props.storeLoading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#f1f4f6",
                opacity: 1,
              }),
              content: (base) => ({
                ...base,
                color: "#000",
              }),
            }}
            spinner={<Loader active type="ball-pulse" />}
            text="Loading store details..."
          >
      <Col md="12">
                <Col className="mb-3 p-0" md="12">
                    <Card>
                        <CardBody>
                            <StoreOverviewCard
                                profile={store}
                                weeklyReport={props.weeklyReport}
                                ProductPerformanceReport={props.ProductPerformanceReport}
                                WeeklySalesReport ={props.WeeklySalesReport}
                                SalesTrendsReport = {props.SalesTrendsReport}
                                TargetPerformanceReport={props.TargetPerformanceReport}
                                CampaignPerformanceReport={props.CampaignPerformanceReport}
                                WeeklySearchTermReport={props.WeeklySearchTermReport}
                                overviewData={props.storeOverview}
                                token={props.token}
                                accessLevel={props.accessLevel}
                            />
                        </CardBody>
                    </Card>
                </Col>
               
                {/* <Col md="12" className="mb-2 p-0">
                    <StoreTabs
                        accessLevel={props.accessLevel}
                        storeIntegerID={store.integerID}
                        storeAccountID={store.details.accountID}
                        toggleSelect={toggleSelect}
                        selectedProducts={selectedProducts}
                    />
                </Col> */}
            </Col>
            </LoadingOverlay>
            <div>
        {showReport()}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  token: state.loggedUser.token,
  adProfiles: state.accountOverview.landing.adProfiles,
  accessLevel: state.loggedUser.accessLevel,
  storeOverview: state.accountOverview.store.storeOverview,
  weeklyReport: state.accountOverview.store.weeklyReport,
  ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
  TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
  WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
  SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
  WeeklySearchTermReport:state.accountOverview.store.WeeklySearchTermReport,
  CampaignPerformanceReport:state.accountOverview.store.CampaignPerformanceReport,
  sellerFeedback: state.accountOverview.store.sellerFeedback,
  storeProducts: state.accountOverview.store.storeProducts,
  storeLoading: state.accountOverview.store.loading,
  status: state.accountOverview.store.status,
  storeCampaigns: state.accountOverview.store.storeCampaigns,
  unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
  metricData: state.accountOverview.store.metricData,
  storeCampaignUpdates: state.accountOverview.store.campaignUpdates,
  storeCreditPurchases: state.accountOverview.store.creditPurchases,

  productOverview: state.accountOverview.product.productOverview,
  parentProductKeywordData: state.accountOverview.product.parentProductKeywordData,
  keywordTableData: state.accountOverview.product.keywordTableData,
  topKeywords: state.accountOverview.product.topKeywords,
  impressionGeneratingKeywords: state.accountOverview.product.impressionGeneratingKeywords,
  mrpData: state.accountOverview.product.mrpData,
  isProductManaged: state.accountOverview.product.productOverview.isManaged,
  productLoading: state.accountOverview.product.loading,
  productCampaigns: state.accountOverview.product.productCampaigns,
  productCampaignUpdates: state.accountOverview.product.campaignUpdates,
  productCreditPurchases: state.accountOverview.product.creditPurchases,
});

export default connect(mapStateToProps)(StoreOverviewHome);
