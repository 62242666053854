import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import products from '../../../../../../../Utils/Products';
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";


function CampaignPreviewModal(props) {
    const {
        productASIN,
        productSKU,
        advertisingObjective,
        itemId,
        dailyBudget,
        sellerProfileId,
        country,
        bidPriceExact,
        bidPricePhrase,
        productTargetingEnabled,
        bidPriceTargeting,
    } = props.campaign;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const handleRemoveCampaign = () => {
        props.removeCampaign(itemId);
        toggle();
    }

    return (
        <div>
            <Button color="grey" onClick={toggle}>Preview</Button>
            <Modal isOpen={isOpen} toggle={toggle} className={itemId}>
                <ModalHeader toggle={toggle}>{products[advertisingObjective].long + " Campaign"}</ModalHeader>
                <ModalBody>
                    <h5>Campaign Details</h5>
                    <h6>Product ASIN: {productASIN}</h6>
                    <h6>Product SKU: {productSKU}</h6>
                    <h6>
                        Advertising Objective: { products[advertisingObjective] ?
                        products[advertisingObjective].long
                        :
                        advertisingObjective}
                    </h6>
                    <h6>Daily Budget: ${dailyBudget}</h6>
                    <h6>Store: {sellerProfileId}</h6>
                    <h6>Marketplace: {country}</h6>
                    <h5>Bid Details</h5>
                    <h6>Bid Price - Exact: ${bidPriceExact}</h6>
                    <h6>Bid Price - Phrase: ${bidPricePhrase}</h6>
                    <h6>Product Targeting: {productTargetingEnabled ? "Enabled" : "Disabled"}</h6>
                    {productTargetingEnabled &&
                        <h6>Bid Price - Product Targeting: ${bidPriceTargeting}</h6>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        id={itemId} color="danger"
                        onClick={handleRemoveCampaign}
                    >
                        Remove Campaign
                    </Button>{' '}
                    <LoadingButton
                        color="primary"
                        className="btn-shadow"
                        loading={props.loading}
                        onClick={() => props.launchCampaign([props.campaign])}
                    >
                        Build
                    </LoadingButton>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CampaignPreviewModal;