import React from "react";
import NumericInput from 'react-numeric-input';
import { useField } from "formik";


/**
 * Wrapper for the NumericInput component to make it a formik input. All params are passed through to the NumericInput component.
 * @param label - the form label for the input
 * @param style - CSS styling for the component
 * @param props - all other props to pass along to NumericInput component
 */
function CustomNumInput({ label, style, ...props }) {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta, helpers] = useField(props);
    const { onChange, ...restField } = field;
    return (
        <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <NumericInput
                className="form-control"
                onChange={(valueAsNumber) => helpers.setValue(valueAsNumber)}
                style={style}
                {...restField}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{display: "block"}}>{meta.error}</div>
            ) : null}
        </>
    );
}

export default CustomNumInput;
