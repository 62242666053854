import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TimeRangeCampaignDataService
    from "../../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/TimeRangeCampaignDataService";
import CampaignList from "../../../Components/CampaignList";
import CampaignDataTable from "./Tables/CampaignDataTable";
import {Button, ButtonGroup, Card, CardBody} from "reactstrap";


function ProductCampaigns(props) {

    const [combinedCampaignData, setCombinedCampaignData] = useState([]);

    useEffect(() => {
        let mergedData = props.productCampaigns.map(campaign => {
            let rankData = props.campaignData.find(campaignData => campaign.campaignID === campaignData.campaignId)

            // if no rank data available for campaign
            if (!rankData) return campaign;

            // merge campaign and its rank data
            return Object.assign({}, campaign, rankData);
        });

        setCombinedCampaignData(mergedData);
    }, [props.productCampaigns, props.campaignData])

    return (
        <>
            <CampaignList
                campaigns={combinedCampaignData}
                onCampaignStageUpdate={props.onCampaignStageUpdate}
            />
        </>
    )

}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    productASIN: state.accountOverview.product.productOverview.asin,
    campaignData: state.accountOverview.product.campaignData,
    productCampaigns: state.accountOverview.product.productCampaigns,
})


export default connect(mapStateToProps)(ProductCampaigns);