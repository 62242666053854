export function FormatProductWidgetData(product) {
    const {
        asin,
        brand,
        itemPrice,
        itemImage,
        productTitle,
        sku,
        skus,
        link,
        itemRating,
        ratingVotes,
        isManaged,
        areKeywordsTracked,
        simpliworksManagementState,
        readyCampaignsForProduct,
        isParentProduct,
        salesRanking
    } = product;

    const details = {
        ASIN: asin,
        // SKU: sku,
        brand: brand,
        budget:'None',
        price: itemPrice ? `$${itemPrice}` : "Not Available",
        link:link,
        salesRanking:salesRanking
    };

    return {
        productTitle,
        productImage: itemImage,
        productRating: itemRating,
        ratingVotes,
        isManaged,
        skuList: skus,
        managementStatus: simpliworksManagementState,
        areKeywordsTracked,
        numProductReadyCampaigns: readyCampaignsForProduct,
        isParentProduct,
        details,
    };
}