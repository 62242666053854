import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadContentAgencies } from "../../../Redux/Actions/Actions";


function ContentAgencyListService(token) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_CONTENT_AGENCIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/account/createAndViewContentAgencies`, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadContentAgencies(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_CONTENT_AGENCIES_FAILED });
        });
    }
}

export default ContentAgencyListService;