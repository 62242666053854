import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import actionType from "../../../../Redux/Actions/ActionTypes";
import { LoadStoreAdData } from "../../../../Redux/Actions/Actions";

function StoreAdPerformanceService(token, integerID, daysBack) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_STORE_AD_DATA_BEGIN });

        axios.post(
            `${CURRENT_API_URL}/campaign/viewCachedStoreData`,
            {
                integerID: integerID,
                daysBack: daysBack,
            },
            {
                headers: {
                    Authorization: token,
                },
            }
        )
            .then((res) => {
                dispatch(LoadStoreAdData(res.data));
            })
            .catch(() => {
                dispatch({
                    type: actionType.LOAD_STORE_AD_DATA_FAILED,
                });
            });
    };
}

export default StoreAdPerformanceService;