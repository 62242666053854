import React from "react";
import { Route, Switch } from "react-router-dom";

import urls from '../../../../Utils/URLs';

import CreateProjectLayout from './Layouts/CreateProjectLayout';
import NewProjectPage from './Components/NewProjectPage';
import ViewProjectPage from './Components/ViewProjectPage';
import PageNotFound from "../../../PageNotFound";
import ViewProjects from './Screens/View/ViewProjects';
import AgencyProjectView from './AgencyProjectView/AgencyProjectView';
import CustomerProjectView from './AgencyProjectView/CustomerProjectView';
import AgencyProjectViewThankyou from './AgencyProjectView/AgencyProjectViewThankyou';


const CreateProject = () => {
    let viewURL = `${urls.BASE_PROJECTS_URL}/:id`
    let createProjectURL = `${urls.CREATE_PROJECTS_URL}/:id`
    return (
        <CreateProjectLayout>
            <Switch>

                <Route exact path={urls.BASE_PROJECTS_URL}>
                    <ViewProjectPage />
                </Route>
                <Route exact path={createProjectURL}>
                    <NewProjectPage />
                </Route>
                <Route exact path='/project/thankyou'>
                    <AgencyProjectViewThankyou />
                </Route>
                <Route exact path = '/project/:storeId/project-view/:id'>
                    <CustomerProjectView />
                </Route>
                <Route exact path={viewURL} >
                    <ViewProjects />
                </Route>
                <Route exact path={'/project/:storeId/agency-project-view/:id'} >
                    <AgencyProjectView />
                </Route>

                {/* <Route path={urls.VIEW_PROJECTS_URL}>
                    <ViewProjectPage />
                </Route> */}
                <Route component={PageNotFound} />
            </Switch>
        </CreateProjectLayout>
    );
}


export default CreateProject;
