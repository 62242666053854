import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import UserProfileLayout from "./Components/UserProfileLayout";
import urls from '../../../../Utils/URLs';
import PageNotFound from "../../../PageNotFound";
import UserStoreDirectoryPage from "./Scenes/UserStoreDirectory/UserStoreDirectoryPage";
import ProtectedRoute from "../../../Components/Nav/ProtectedRoute";
import ContentAgenciesPage from "./Scenes/ContentAgencies/ContentAgenciesPage";
import AuthorizationPage from "./Scenes/Authorization/AuthorizationPage";
import LeadGenerationPage from "./Scenes/LeadGenerationTool/LeadGenerationPage";
import LeadGenResearchProudctCategory from "./Scenes/LeadGenerationTool/LeadGenResearchProudctCategory";
import LeadGenResearchJobs from "./Scenes/LeadGenerationTool/LeadGenReasearchJobs";
import OperationsPage from "../Operations/OperationsPage";
import MarketReasearchCreateJob from './Scenes/LeadGenerationTool/MarketResearchProductCategory';
import AddKeyWordsTrackingPage from "./Scenes/AddKeywordTracking/AddKeyWordsTrackingPage";
import SubAccountsPage from "./Scenes/SubAccounts/SubAccountsPage";

function UserProfilePage(props) {
    return (
        <UserProfileLayout>
            <Switch>
                <Route exact path={urls.USER_PROFILE_AUTH_URL}>
                    <AuthorizationPage />
                </Route>
                <Route exact path={urls.USER_PROFILE_ADD_KEYWORDS_TRACKING}>
                    <AddKeyWordsTrackingPage />
                </Route>
				
                <ProtectedRoute role={props.accessLevel} perform="accounts:view" exact={true} path={urls.USER_PROFILE_DIRECTORY_URL}>
                    <UserStoreDirectoryPage />
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="contentAgencies:view" exact={true} path={urls.USER_PROFILE_CONTENT_AGENCIES_URL}>
                    <ContentAgenciesPage />
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="leadGeneration:view" exact={true} path={urls.USER_PROFILE_LEAD_GEN_URL}>
                    <LeadGenerationPage />
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="lgResearchByProductCategory:view" exact={true} path={urls.USER_PROFILE_LEAD_GEN_RESEARCH_PRODUCT_CATEGORY_URL}>
                    <LeadGenResearchProudctCategory />
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="lgResearchjobs:view" exact={true} path={urls.USER_PROFILE_LEAD_GEN_JOBS_URL}>
                    <LeadGenResearchJobs />
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="lgAmazonProductCategories:view" exact={true} path={urls.USER_PROFILE_AMA_PROD_CAT_URL}>
                    < MarketReasearchCreateJob/>
                </ProtectedRoute>
                <ProtectedRoute role={props.accessLevel} perform="subAccounts:view" exact={true} path={urls.USER_PROFILE_SUB_ACCOUNTS_URL}>
                    <SubAccountsPage />
                </ProtectedRoute>

                <Route component={PageNotFound} />
            </Switch>
        </UserProfileLayout>
    );
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
});

export default connect(mapStateToProps)(UserProfilePage);