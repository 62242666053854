import React, { useState } from 'react';
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import { connect } from 'react-redux';
import CustomTabs from "../../../../../../../Components/Nav/CustomTabs";
import BleederKeywordTable from "../Tables/BleederKeywordTable";
import CustomTabContent from "../../../../../../../Components/Nav/CustomTabContent";
import HandleBleederKeywordService
    from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/HandleBleederKeywordService";
import IdleKeywordTable from "../Tables/IdleKeywordTable";
import {IoIosExpand} from "react-icons/all";
import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import {
    ProductBleederKeywordService
} from "../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductPerformanceDataServices";


function KeywordAlertTabs(props) {

    const [activeTab, setActiveTab] = useState('1');


    const toggle = (tabId) => {
        setActiveTab(tabId);
    };

    const handleBleeder = async (keywordID, action) => {
        let response = await HandleBleederKeywordService(props.token, keywordID, action);
        if (response) {
            props.dispatch(ProductBleederKeywordService(props.token, props.productASIN));
        }
    }

    const handleIdleKW = async (keywordID, action) => {
        let response = await HandleBleederKeywordService(props.token, keywordID, action);
        if (response) {
            props.dispatch(ProductBleederKeywordService(props.token, props.productASIN));
        }
    }

    let tabList = [
        <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>Bleeder Keywords</div>,
        <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>Idle Keywords</div>,
    ];

    let tabContentList = [
        <BleederKeywordTable
            summaryView={props.summaryView}
            data={props.summaryView ?
                props.bleederKeywords.notAcknowledged :
                [...props.bleederKeywords.acknowledged, ...props.bleederKeywords.notAcknowledged]}
            handleBleeder={handleBleeder}
        />,
        <IdleKeywordTable
            summaryView={props.summaryView}
            data={props.summaryView ?
                props.idleKeywords.notAcknowledged :
                [...props.idleKeywords.acknowledged, ...props.idleKeywords.notAcknowledged]}
            handleIdleKW={handleIdleKW}
        />,
    ];

    return (
        <>
            <Card className="mb-3">
                <CardHeader className="tabs-lg-alternate">
                    <CustomTabs
                        activeTab={activeTab}
                        toggle={toggle}
                        tabList={tabList}
                    />
                </CardHeader>
                {
                    props.summaryView &&
                    <Button
                        className="btn-icon btn-icon-only ml-auto mt-2 mr-3 p-1"
                        outline
                        color="light"
                        style={{borderWidth: 0}}
                        onClick={() => props.setIsModalOpen(true)}
                    >
                        <IoIosExpand fontSize="2rem"/>
                    </Button>
                }
                <CustomTabContent
                    activeTab={activeTab}
                    tabList={tabContentList}
                    renderTabPane={(tab) => <CardBody>{tab}</CardBody>}
                />
            </Card>
        </>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    productASIN: state.accountOverview.product.productOverview.asin,
    bleederKeywords: state.accountOverview.product.bleederKeywords,
    idleKeywords: state.accountOverview.product.idleKeywords,
})

export default connect(mapStateToProps)(KeywordAlertTabs);