import React from "react";

import urls from '../../../../../Utils/URLs';
import DashboardLayout from "../../../Layout/DashboardLayout";


function LaunchpadLayout(props) {

    const menuItems = [
        {
            icon: 'pe-7s-graph',
            label: 'Ready To Launch',
            to: urls.LAUNCHPAD_READY_CAMPAIGNS_URL,
        },
        {
            icon: 'pe-7s-graph',
            label: 'Campaign Cart',
            to: urls.LAUNCHPAD_CART_OVERVIEW_URL,
        },
    ];

    return (
        <DashboardLayout
            menuItems={menuItems}
            heading="Launchpad"
            icon="lnr-map text-info"
            subheading="Build campaigns and launch them when they're ready!"
        >
            {props.children}
        </DashboardLayout>
    );
}

export default LaunchpadLayout;
