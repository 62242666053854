import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function RetryParticularAdvReportService(token, data) {
    return axios.post(`${CURRENT_API_URL}/sellerPartner/retryParticularAdvReport`, data, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            notify.NotifySuccess("Advertising report status data fetched again successfully.");
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while fetching advertising report status data, please try again later.");
            return false;
        });
}


export {
    RetryParticularAdvReportService,
}

