import React, { Fragment } from 'react';

const ImagesFromCustomer = (props) => {

    return (
        <Fragment>
            {props.images.map((img, i) => {
                return (
                    <div key={i} style={{ 'width': '45%', 'margin': '5px', 'float': 'left' }}>
                        <a href={img} target="_blank">
                            <img src={img} alt={'Project Images ' + i} className="img-thumbnail" />
                        </a>
                    </div>
                )
            })}
        </Fragment>
    )
}

export default ImagesFromCustomer;