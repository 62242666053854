import React from 'react';
import { Route } from 'react-router-dom';
import { check } from "../Can";
import PageNotFound from "../../PageNotFound";


/**
 * Wrapper for react-router-dom Route component that allows for dynamic rendering of pages based on user permissions.
 * @param role - the user's access level
 * @param perform - the permission string (defined in rbacRules.js)
 * @param props - the remaining properties which are passed onto the Route component
 */
function ProtectedRoute({ role, perform, ...props}) {
    return (
        check(role, perform) ?
            <Route exact={props.exact} path={props.path}>{props.children}</Route>
            :
            <Route component={PageNotFound} />
    );
}

export default ProtectedRoute;