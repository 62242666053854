import React, { useState } from 'react';
import { connect } from 'react-redux';
import NumericInput from 'react-numeric-input';
import { Col, Label, Row } from "reactstrap";

import CustomModal from '../../../../../../Components/Modals/CustomModal';
import LoadingButton from '../../../../../../Components/Buttons/LoadingButton';
import UpdateCampaignBudgetService from '../../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/UpdateCampaignBudgetService';
import CustomLoadingOverlay from '../../../../../../Components/Nav/CustomLoadingOverlay';


function UpdateCampaignBudgetModal(props) {

    const [newBudget, setNewBudget] = useState(0.50);
    const [loading, setLoading] = useState(false);
    console.log("first darade")
    const onSubmit = async () => {
        setLoading(true);
        let response = await UpdateCampaignBudgetService(props.token, props.campaignID, newBudget);
        setLoading(false);
        if (response) {
            props.toggle();
        }
    }

    return (
        <CustomModal
            isOpen={true}
            toggle={props.toggle}
            header={"Update Campaign Budget"}
            footer={
                <LoadingButton
                    loading={loading}
                    color="success"
                    onClick={() => onSubmit()}
                >
                    Update Budget
                </LoadingButton>
            }
        >
            <CustomLoadingOverlay
                active={props.campaignDataLoading}
                text={"loading..."}
                error={props.campaignDataStatus === "failed"}
                errorMessage={"Error loading campaign data, please try again."}
            >
                <Row>
                    <Col className="mb-2" md="12">
                        <Label>New Campaign Budget</Label>
                    </Col>
                    <Col md="12">
                        <NumericInput
                            min={0.02}
                            precision={2}
                            step={0.01}
                            value={newBudget}
                            format={num => `$${num}`}
                            parse={str => str.replace(/^\$/, "")}
                            onChange={value => setNewBudget(value)}
                        />
                    </Col>
                </Row>
            </CustomLoadingOverlay>

        </CustomModal>
    )
}


const mapStateToProps = state => ({
    token: state.loggedUser.token,
    campaignID: state.accountOverview.campaign.campaignOverview.campaignID,
    campaignDataLoading: state.accountOverview.campaign.loading,
    campaignDataStatus: state.accountOverview.campaign.status,
})

export default connect(mapStateToProps)(UpdateCampaignBudgetModal);