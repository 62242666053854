import React, {Fragment} from 'react';
import {
    Button
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import urls from '../../../../../../../../Utils/URLs';


function AddToCartStep(props) {

    let history = useHistory();

    const handleRedirectToCheckout = async () => {
        await props.submitForm();
        history.push(urls.LAUNCHPAD_CART_OVERVIEW_URL);
    }

    const success = () => {
        return (
            <div className="no-results">
                <div className="sa-icon sa-success animate">
                    <span className="sa-line sa-tip animateSuccessTip"/>
                    <span className="sa-line sa-long animateSuccessLong"/>

                    <div className="sa-placeholder"/>
                    <div className="sa-fix"/>
                </div>
                <div className="results-subtitle mt-4">All done!</div>
                <div className="results-title">Click below to prepare your campaign.</div>
                <div className="mt-3 mb-3"/>
                <div className="text-center">
                    <Button color="brand-blue-dark" size="lg" className="btn-shadow btn-wide" type="submit">
                        Add to Cart
                    </Button>
                    <Button
                        color="success"
                        size="lg"
                        className="btn-shadow btn-wide ml-2"
                        type="submit"
                        onClick={handleRedirectToCheckout}
                    >
                        Add to Cart and Build
                    </Button>
                </div>
            </div>
        );
    }

    const error = () => {
        return (
            <div className="no-results">
                <div className="sa-icon sa-error animateErrorIcon">
                    <span className="sa-x-mark animateXMark">
                        <span className="sa-line sa-left"/>
                        <span className="sa-line sa-right"/>
                    </span>
                    <div className="sa-placeholder"/>
                    <div className="sa-fix"/>
                </div>
                <div className="results-subtitle mt-4">Incomplete</div>
                <div className="results-title">Please make sure you have completed all required fields.</div>
                <div className="mt-3 mb-3"/>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="form-wizard-content">
                { props.isValid ? success() : error() }
            </div>
        </Fragment>
    );
}

export default AddToCartStep;
