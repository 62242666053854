import React from 'react';
import {PopoverBody, PopoverHeader, Progress, UncontrolledPopover} from "reactstrap";


function CampaignLaunchProgressBar(props) {

    const { stepNames, stepValues, id, header } = props;

    const getProgressValue = () => {
        let sum = stepValues.reduce((sum, step) => sum + step);
        return ((sum / stepValues.length) * 100).toFixed(0);
    }

    return (
        <>
            <Progress
                id={`Popover-${id}`}
                className="mb-3"
                value={getProgressValue()}
            />
            <UncontrolledPopover target={`Popover-${id}`}>
                <PopoverHeader>{header}</PopoverHeader>
                <PopoverBody>
                    {stepNames.map((name, idx) => (
                        <p>{name}: {`${stepValues[idx]}`}</p>
                    ))}
                </PopoverBody>
            </UncontrolledPopover>
        </>
    )
}

export default CampaignLaunchProgressBar;