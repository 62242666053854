import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from 'react-redux';

import TargetedProductTable from "../Tables/TargetedProductTable";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";


function CampaignTargetedProductModal(props) {

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} size="lg">
            <ModalHeader toggle={props.toggle}>Manage Targeted Products</ModalHeader>
            <ModalBody>
                <LoadingOverlay
                    active={props.loading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 1
                        }),
                        content: (base) => ({
                            ...base,
                            color: "#000"
                        })
                    }}
                    spinner={<Loader active type="ball-pulse"/>}
                    text='Loading targeted product details...'
                >
                    <TargetedProductTable data={props.targetedProducts} />
                </LoadingOverlay>
            </ModalBody>
            <ModalFooter>
                <Button color="link" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = state => ({
    targetedProducts: state.accountOverview.campaign.targetedProducts,
    loading: state.accountOverview.campaign.loading,
    status: state.accountOverview.campaign.status,
});

export default connect(mapStateToProps)(CampaignTargetedProductModal);