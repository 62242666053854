import React, { Fragment, useEffect } from 'react';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import Can, {check} from "../../../../../Components/Can";


const AmazonSubjectMattersEdit = ({ subjectMatters, openComments, accessLevel, openTextField, approveOrReviseProject, isFinalCompleted }) => {

    const getOrderList = (list) => {
        return list.sort((a, b) => {
            return (a.id - b.id)
        })
    }

    const openCommentsModal = (fieldData) => {
        openComments(fieldData, undefined)
    }

    const getApproveAllAccess = () => {
        let status = false;
        // if (accessLevel === 3 && subjectMatters.length > 0) {
        //     status = subjectMatters.some(s => !s.approved)
        // }
        if (check(accessLevel, "project:review") && subjectMatters.length > 0) {
            status = subjectMatters.some(s => !s.approved)
        }


        return status
    }

    const allApproved = () => {
        let status = false
        if (subjectMatters.length > 0)
            status = subjectMatters.every(s => s.approved);
        return status
    }

    const approveSection = () => {
        approveOrReviseProject(undefined, 'APPROVED', 'SUBJECT_MATTERS')
    }

    const revisionNeeded = () => {
        return subjectMatters.some(s => s.status === 'REVISED')
    }


    const getSubjectMatters = (sm, i) => {
        return (
            <li className="features p-l-10" >
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        {sm.field_value}
                        {' '}
                        {sm.approved && <i className="fas fa-check-circle f-16 ml-2 text-success" title="Approved" />}
                        {sm.status === 'REVISED' && <i className="fas fa-undo-alt ml-2 f-16 text-danger" title="Revision Needed" />}
                        {' '}
                    </div>
                    <div>
                        <span className="d-inline-block">
                            {!isFinalCompleted &&
                                <Fragment>
                                    <Can
                                        role={accessLevel}
                                        perform={"project:edit"}
                                        yes={() => (
                                            !sm.approved &&
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {
                                                        (sm.status === 'REVISED') &&
                                                        <DropdownItem onClick={() => openCommentsModal(sm)} title=" Add Comments">
                                                            <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments

                                                        </DropdownItem>
                                                    }

                                                    {(!sm.approved) &&
                                                    <Fragment>
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => {
                                                            let tempData = {
                                                                fieldData: sm,
                                                                name: 'subjectMatter' + (i + 1),
                                                                placeholder: 'Subject Matter ' + (i + 1),
                                                                maxLength: 50
                                                            }

                                                            openTextField(tempData, 'Subject Matter ' + (i + 1))
                                                        }} title={"Edit Subject Matter" + (i + 1)}>
                                                            <i className="fas fa-edit blue mr-2"></i> {' '} {"Edit Subject Matter" + (i + 1)}

                                                        </DropdownItem>
                                                    </Fragment>
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    (accessLevel === 0 && !sm.approved) &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}
                                    {/*            {*/}
                                    {/*                (sm.status === 'REVISED') &&*/}
                                    {/*                <DropdownItem onClick={() => openCommentsModal(sm)} title=" Add Comments">*/}
                                    {/*                    <i className="fas fa-comments comment-icon f-16 mr-2"></i> {' '} Add Comments*/}

                                    {/*                </DropdownItem>*/}
                                    {/*            }*/}

                                    {/*            {(!sm.approved) &&*/}
                                    {/*                <Fragment>*/}
                                    {/*                    <DropdownItem divider />*/}
                                    {/*                    <DropdownItem onClick={() => {*/}
                                    {/*                        let tempData = {*/}
                                    {/*                            fieldData: sm,*/}
                                    {/*                            name: 'subjectMatter' + (i + 1),*/}
                                    {/*                            placeholder: 'Subject Matter ' + (i + 1),*/}
                                    {/*                            maxLength: 50*/}
                                    {/*                        }*/}

                                    {/*                        openTextField(tempData, 'Subject Matter ' + (i + 1))*/}
                                    {/*                    }} title={"Edit Subject Matter" + (i + 1)}>*/}
                                    {/*                        <i className="fas fa-edit blue mr-2"></i> {' '} {"Edit Subject Matter" + (i + 1)}*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                </Fragment>*/}
                                    {/*            }*/}
                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}
                                    <Can
                                        role={accessLevel}
                                        perform={"project:review"}
                                        yes={() => (
                                            <UncontrolledDropdown>
                                                <DropdownToggle color="link">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <Fragment>
                                                        {!sm.approved
                                                        &&
                                                        <DropdownItem onClick={() => approveOrReviseProject(sm, 'APPROVED', undefined)} title="Approve">
                                                            <i className="fas fa-check green mr-2"></i> {' '} Approve

                                                        </DropdownItem>
                                                        }
                                                        {
                                                            sm.status !== 'REVISED' &&
                                                            <DropdownItem onClick={() => openComments(sm, i, true)} title="Revise">
                                                                <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise

                                                            </DropdownItem>
                                                        }
                                                    </Fragment>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    />
                                    {/*{*/}
                                    {/*    accessLevel === 3 &&*/}
                                    {/*    <UncontrolledDropdown>*/}
                                    {/*        <DropdownToggle color="link">*/}
                                    {/*            <i className="fas fa-ellipsis-v"></i>*/}
                                    {/*        </DropdownToggle>*/}
                                    {/*        <DropdownMenu right>*/}
                                    {/*            <Fragment>*/}
                                    {/*                {!sm.approved*/}
                                    {/*                    &&*/}
                                    {/*                    <DropdownItem onClick={() => approveOrReviseProject(sm, 'APPROVED', undefined)} title="Approve">*/}
                                    {/*                        <i className="fas fa-check green mr-2"></i> {' '} Approve*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*                {*/}
                                    {/*                    sm.status !== 'REVISED' &&*/}
                                    {/*                    <DropdownItem onClick={() => openComments(sm, i, true)} title="Revise">*/}
                                    {/*                        <i className="fas fa-undo-alt comment-icon mr-2"></i> {' '} Revise*/}

                                    {/*                    </DropdownItem>*/}
                                    {/*                }*/}
                                    {/*            </Fragment>*/}

                                    {/*        </DropdownMenu>*/}
                                    {/*    </UncontrolledDropdown>*/}

                                    {/*}*/}
                                </Fragment>

                            }
                        </span>
                    </div>

                </div>
                { /*}  <div className="row">
                    <div className="col-md-11">

                    </div>
                    <div className="col-md-1">

                    </div>
                </div>  */}
            </li>
        )
    }

    return (
        <Fragment>
            <h4 className="heading">Subject Matters {' '}
                {revisionNeeded() && <span className="badge badge-light-danger capitalize"><i className="fas fa-undo-alt badge-light-danger"></i>  Revision Needed</span>}
                {allApproved() && <span className="green-badge green-badge-approve"><i className="fas fa-check-circle badge-green-icon"></i>  Approved</span>}
                {
                    (getApproveAllAccess() && !isFinalCompleted) && <Button type="button" color="link" onClick={approveSection} className="badge badge-approve fs-8 fw-bolder no-text-dec pointer">Approve All</Button>
                }
            </h4>
            <ul>
                {getOrderList(subjectMatters).map((sm, i) =>

                    <Fragment key={i}>
                        {
                            sm &&
                            <Fragment>
                                {getSubjectMatters(sm, i)}
                            </Fragment>

                        }
                    </Fragment>


                )}

            </ul>
            <Can
                role={accessLevel}
                perform={"project:edit"}
                yes={() => (
                    (subjectMatters.length < 5 && subjectMatters.every(s => !s.approved) && !isFinalCompleted) &&
                    <div className="my-2">
                        <Button type="button" color="primary" className="btn-sm f07" onClick={() => {
                            let tempData = {
                                fieldData: null,
                                name: 'subjectMatter' + (subjectMatters.length + 1),
                                placeholder: 'Subject Matter ' + (subjectMatters.length + 1),
                                maxLength: 50
                            }

                            openTextField(tempData, 'Subject Matter ' + (subjectMatters.length + 1))
                        }}><i className="fas fa-plus-circle"></i> Add Subject Matter</Button>
                    </div>
                )}
            />
            {/*{*/}
            {/*    (subjectMatters.length < 5 && accessLevel === 0 && subjectMatters.every(s => !s.approved) && !isFinalCompleted) &&*/}
            {/*    <div className="my-2">*/}
            {/*        <Button type="button" color="primary" className="btn-sm f07" onClick={() => {*/}
            {/*            let tempData = {*/}
            {/*                fieldData: null,*/}
            {/*                name: 'subjectMatter' + (subjectMatters.length + 1),*/}
            {/*                placeholder: 'Subject Matter ' + (subjectMatters.length + 1),*/}
            {/*                maxLength: 50*/}
            {/*            }*/}

            {/*            openTextField(tempData, 'Subject Matter ' + (subjectMatters.length + 1))*/}
            {/*        }}><i className="fas fa-plus-circle"></i> Add Subject Matter</Button>*/}
            {/*    </div>*/}
            {/*}*/}
        </Fragment>
    )
}

export default AmazonSubjectMattersEdit;
