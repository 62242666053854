import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';

import { connect } from 'react-redux';

import LeadGenReasearchProdcutCategoryToolService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenReasearchProdcutCategoryToolService";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import ProductsTable from "./Components/ProductsTable";
import SellersTable from "./Components/SellersTable";
import LeadGenJobTables from "./Components/LeadGenJobTables"
import CustomModal from "../../../../../Components/Modals/CustomModal";
import LeadGenProductsTable from "./Components/LeadGenProductsTable";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
// import Can from "../../../../Components/Can";

import ProjectsListService from "../../../../../../Service/DashboardServices/UserProfileServices/ProjectsListService";
import ProductCategoriesTable from "./Components/ProductCategoriesTable";
import LeadGenReasearchJobsToolService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenReasearchJobsToolService";
import LeadGenProdCatJobDataToolService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatJobDataToolService";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import ViewAmazonProductCategoriesService from '../../../../../../Service/DashboardServices/UserProfileServices/ViewAmazonProductCategoriesService';
import AmazonProductCategories from './Components/AmazonProductCategories';
import AmazonCategoryService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/AmazonCategoryService";
import { DropdownList } from "react-widgets";
import FetchAmazonDomainsService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/FetchAmazonDomainsService"
import RefreshAmazonCategoriesService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/RefreshAmazonCategoriesService"


function MarketResearchProductCategory(props) {


    const [isOpen, setIsOpen] = useState(false);    
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState("profile"); 
    const [productCategory, setproductCategory] = useState('');   
    const [productASIN, setproductASIN] = useState('');
    const [amazonDomain,setamazonDomain] = useState('');
    const [searchText, setsearchText] = useState('');
    const handleAmazonCategory = () => {
        console.log("In Function handleAmazonCategory")
        props.dispatch(AmazonCategoryService(props.token, searchText,amazonDomain.Domain_name));
       
    }
    
    useEffect(() => {
        props.dispatch(FetchAmazonDomainsService(props.token));         
    }, [])
        
    const handleRefreshDomains = () => {
        if(!amazonDomain) return;        
        props.dispatch(RefreshAmazonCategoriesService(props.token, amazonDomain.Domain_name));
    } 
        
        
    return (
        <Fragment>
            <Container fluid>
                   
                <Row>                    
             
                    <Col md="9" className="mb-2" >
                        <h4>Amazon Product Categories</h4>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col md="4" className="mb-2"> 
                                    <DropdownList
                                    data={props.amazonDomains}
                                    dataKey='Domain_name'                    
                                    textField='Country'                   
                                    placeholder="Select Country"
                                    value={amazonDomain}
                                    onChange={(value) => {
                                        setamazonDomain(value);
                                    }}
                                />   
                                
                                </Col>
                                
                                      
                                    <LoadingButton
                                    className="mb-3"
                                    color="brand-blue-dark"
                                    onClick={() => handleRefreshDomains()}
                                    loading={props.status === 'loading'}

                                >
                                    Refresh Product Categories
                                </LoadingButton>

                               
                                </Row>
                                <Row>
                    <Col md="4" className="mb-2">
                       
                        <Input
                            id="inputValue"
                            placeholder="Enter Search Text"
                            value={searchText}
                            onChange={(e) =>
                                setsearchText(e.target.value)
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="9" className="mb-2">
                        <LoadingButton
                            className="mb-3"
                            color="brand-blue-dark"
                            onClick={() => handleAmazonCategory()}
                            loading={props.status === 'loading'}

                        >
                            Search
                        </LoadingButton>
                    </Col>
                </Row>

                <Row>
                        <h6 className="mt-3 text-primary" color="green">
                        {' '}
                            <b>{props.message}</b></h6>                            
                    </Row >         
                                
                                
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>
            

                
                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <AmazonProductCategories data={props.AmazonProductCategoriesList} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>  
                </Row> 
                
                
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    sellerList : state.profile.researchProductCategoryData.sellerList, 
    productList : state.profile.researchProductCategoryData.productList,
    productCategories : state.profile.productCategories,
    leadGenJobs : state.profile.leadGenJobs,  
    researchProductCategoryData : state.profile.researchProductCategoryData,
    AmazonProductCategoriesList : state.profile.AmazonProductCategories.AmazonProductCategoriesList,
    status: state.profile.status,    
    amazonDomains:state.profile.amazonDomains,
    message : state.profile.AmazonProductCategories.message,
})

export default connect(mapStateToProps)(MarketResearchProductCategory);
