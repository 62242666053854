// src/Navbar.js
import React, {Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import {useHistory} from 'react-router-dom';
import {filterByPermission} from "../../../../../Components/Can";
import StoreProductsTab from "./Nav/StoreTabs/StoreProductsTab";
// 
import ProductOverviewService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService";
import UpdateProductManagementService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/UpdateProductManagementService";
import StoreOverviewService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
import urls from "../../../../../../Utils/URLs";
import AddStoreProductService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/AddStoreProductService";
import ImportCampaignService from "../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/ImportCampaignService";
import QuickLaunchCampaignService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/QuickLaunchCampaignService";
import ProductTrackingService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductTrackingService";
import RemoveProductService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RemoveProductService";
import UpdateSKUForAsinStoreService from "../../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService";
// 
function StoreOverviewProductPage(props) {
    let history = useHistory();
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    // let { storeIntegerID } = props.match.params;

    const handleProductPageRedirect = (asin, sku) => {
        props.dispatch(
            ProductOverviewService(props.token, asin, props.storeIntegerID)
        );
        history.push(urls.ACCOUNT_OVERVIEW_PRODUCT_URL);
    };

    const updateProductManagement = async (asin, update) => {
        setLoadingUpdate(true);
        await UpdateProductManagementService(
            props.token,
            asin,
            update,
            props.storeIntegerID
        );
        props.dispatch(
            StoreOverviewService(
                props.token,
                props.storeIntegerID,
                props.storeAccountID
            )
        );
        setLoadingUpdate(false);
    };

    const handleAddProduct = async (asin, sku) => {
        // let response = await AddStoreProductService(props.token, asin, sku, props.storeIntegerID);
        let response = await AddStoreProductService(
            props.token,
            asin,
            props.storeIntegerID
        );
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
        return response;
    };


    const handleQuickLaunchCampaign = async (asin, sku, dailyBudget) => {
        let response = await QuickLaunchCampaignService(
            props.token,
            props.storeIntegerID,
            asin,
            sku,
            dailyBudget
        );
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
        return response;
    };

    const updateProductTracking = async (asin, update) => {
        let response = await ProductTrackingService(props.token, asin, update);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    const handleRemoveProduct = async (asin, integerID) => {
        let response = await RemoveProductService(props.token, asin, integerID);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    const handleUpdateSkus = async (asin, integerID) => {
        let response = await UpdateSKUForAsinStoreService(props.token, asin, integerID);
        if (response) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
            );
        }
    };

    let tabList = [
        {
            perform: 'products:view',
            yes: (
                <div className="widget-numbers-sm" style={{fontSize: '1.3rem'}}>
                    Store Products
                </div>
            ),
        },
      
    ];

    let tabContentList = [
        {
            perform: 'products:view',
            yes: (
                <StoreProductsTab
                    accessLevel={props.accessLevel}
                    products={props.storeProducts}
                    parentProducts={props.parentProducts}
                    // childProducts={props.childProducts}
                    orphanProducts={props.orphanProducts}
                    storeIntegerID={props.storeIntegerID}
                    ProductPerformanceReport={props.ProductPerformanceReport}
                    ProductTargetingReport={props.ProductTargetingReport}
                    handleRedirect={handleProductPageRedirect}
                    updateProductManagement={updateProductManagement}
                    updateProductTracking={updateProductTracking}
                    handleAddProduct={handleAddProduct}
                    removeProduct={handleRemoveProduct}
                    updateSkus={handleUpdateSkus}
                    loading={loadingUpdate}
                    handleQuickLaunchCampaign={handleQuickLaunchCampaign}
                    handleQuickActions={props.handleQuickActions}
                    toggleSelect={props.toggleSelect}
                    selectedProducts={props.selectedProducts}
                    token={props.token}
                    linkedToMRP={props.linkedToMRP}
                />
            ),
        },
       
    ];

    tabList = filterByPermission(tabList, props.accessLevel);
    tabContentList = filterByPermission(tabContentList, props.accessLevel);

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tabId) => {
        setActiveTab(tabId);
    };

    const renderTabPane = (tab) => <CardBody>{tab}</CardBody>;

    return (
        <Fragment>
            {tabList.length === 1 ? (
                <Card>
                    <CardBody>{tabContentList[0]}</CardBody>
                </Card>
            ) : (
                <Card className="mb-3">
                    <CardHeader className="tabs-lg-alternate">
                        {/* <CustomTabs
                            activeTab={activeTab}
                            toggle={toggle}
                            tabList={tabList}
                            navItemStyles={{height: 'unset'}}
                            navLinkStyles={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        /> */}
                    </CardHeader>
                    {/* <CustomTabContent
                        activeTab={activeTab}
                        tabList={tabContentList}
                        renderTabPane={renderTabPane}
                    /> */}
                </Card>
            )}
        </Fragment>
    );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    storeOverview: state.accountOverview.store.storeOverview,
    // childProducts: state.accountOverview.store.childProducts,
    adProfiles: state.accountOverview.landing.adProfiles,
    // Power BI Reports
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport:state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport:state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport:state.accountOverview.store.CampaignPerformanceReport,

    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,

    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    ///
    storeProducts: state.accountOverview.store.storeProducts,
    ProductTargetingReport: state.accountOverview.store.ProductTargetingReport,
    parentProducts: state.accountOverview.store.parentProducts,
    orphanProducts: state.accountOverview.store.orphanProducts,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
    metricData: state.accountOverview.store.metricData,
    linkedToMRP: state.accountOverview.store.storeOverview.linkedToMRP,
    ///
  };
};
export default connect(mapStateToProps)(StoreOverviewProductPage);
