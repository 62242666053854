import React, { Fragment, useState } from 'react';
import { Row, Col } from 'reactstrap';

import CampaignAnalyticsTable from "../../Tables/CampaignAnalyticsTable";
import CampaignAnalyticsChart from "../../../../../Components/Charts/CampaignAnalyticsChart";


function CampaignAnalyticsTab(props) {

    let groupedCampaigns = [];
    props.campaigns.forEach(campaign => {
        if (!groupedCampaigns.find(product => product.asin === campaign.asin)) {
            groupedCampaigns.push({
                asin: campaign.asin,
                title: campaign.title,
                campaigns: [],
            });
        }
        groupedCampaigns.find(product => product.asin === campaign.asin).campaigns.push(campaign);
    });

    const [selectedCampaigns, setSelectedCampaigns] = useState([]);

    const toggleSelect = (isSelected, campaign) => {
        if (isSelected) {
            setSelectedCampaigns(prevState => [...prevState, campaign.name]);
        } else {
            setSelectedCampaigns(selectedCampaigns.filter(name => name !== campaign.name))
        }
    }

    return (
        <Fragment>
            <Row className="mb-3">
                <Col md="6">
                    <CampaignAnalyticsChart
                        metricData={props.metricData}
                        selectedCampaigns={selectedCampaigns}
                        defaultMetric="sales"
                    />
                </Col>
                <Col md="6">
                    <CampaignAnalyticsChart
                        metricData={props.metricData}
                        selectedCampaigns={selectedCampaigns}
                        defaultMetric="conversions"
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <CampaignAnalyticsTable
                        data={groupedCampaigns}
                        toggleSelect={toggleSelect}
                        selectedCampaigns={selectedCampaigns}
                    />
                </Col>
            </Row>
        </Fragment>
    );
}

export default CampaignAnalyticsTab;