import React from 'react';
import ReactTable from "react-table";


function ProductCategoriesTable(props) {

    const renderPath = (categoryList) => {
        let pathList = categoryList.path.split(">");

        return (
            <span>
            {
                pathList.map((category, idx) => (
                    <>
                        <p
                            className="text-primary"
                            style={{display: "inline", cursor: "pointer"}}
                            onClick={() => props.handleResearchCategory(findCategoryID(category.trim()))}
                        >
                            <u>{category}</u>
                        </p>

                        <p style={{display: "inline"}}>{idx < (pathList.length - 1) ? " >" : ""}</p>
                    </>
                ))
            }
        </span>
        )
    }

    const findCategoryID = (categoryName) => {
        let category = props.data.find(item => item.name === categoryName);

        if (!category) return;

        return category.category_id;
    }

    const columns = [
        {
            Header: "Category Id",
            accessor: "category_id",
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Path",
            accessor: "path",
            //Cell: cellInfo => renderPath(cellInfo.original),
        },
    ];


    return (
        <ReactTable
            columns={columns}
            data={props.data}
            style={{
                height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            noDataText="No Data found."
        />
    )
}

export default ProductCategoriesTable;