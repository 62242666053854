import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import { LoadAppSumoDetails } from "../../../Redux/Actions/Actions";
import actionType from '../../../Redux/Actions/ActionTypes';



function AddKeywordTrackingHubSpotPaymentDetailsService(token) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_BEGIN });

        axios.get(`${CURRENT_API_URL}/account/addKeywordsTrackingFetchHubspotDetails`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            console.log(res.data)        
            dispatch(LoadAppSumoDetails(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_FAILED});

        });
    }
}
export default AddKeywordTrackingHubSpotPaymentDetailsService;
