import React, { Fragment } from 'react';
import ProductWidget from "./ProductWidget";
import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";

function ProductWidgetList(props) {

    const widgetsData = props.data.map(product => {
        const {
            asin,
            itemPrice,
            itemImage,
            productTitle,
            sku,
            itemRating,
            ratingVotes,
            isManaged,
            simpliworksManagementState,
            readyCampaignsForProduct } = product;

        const details = {
            ASIN: asin,
            SKU: sku,
            price: `$${itemPrice}`,
        };

        return {
            productTitle,
            productImage: itemImage,
            productRating: itemRating,
            ratingVotes,
            isManaged,
            managementStatus: simpliworksManagementState,
            numProductReadyCampaigns: readyCampaignsForProduct,
            details,
        }
    });

    const renderProductWidget = (productFields) => (
        <ProductWidget
            { ...productFields }
            handleRedirect={props.handleRedirect}
            updateProductManagement={props.updateProductManagement}
            quickLaunch={props.quickLaunch}
            loading={props.loading}
            handleQuickActions={props.handleQuickActions}
        />
    );

    return (
        <Fragment>
            <WidgetListCard
                widgets={widgetsData}
                renderWidget={renderProductWidget}
                card={props.card}
            />
        </Fragment>
    );
}

export default ProductWidgetList;