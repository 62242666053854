import React from 'react';
import { ButtonGroup } from 'reactstrap';

import CustomWidget from "../../../../../../../Components/Widgets/CustomWidget";
import products from "../../../../../../../../Utils/Products";
import CampaignPreviewModal from "../CampaignPreviewModal";
import LoadingButton from "../../../../../../../Components/Buttons/LoadingButton";


function CartWidget(props) {

    const { productASIN, advertisingObjective, dailyBudget } = props.campaign;

    const subheading = () => (
        <div>
            <div>
                Advertising Objective: { products[advertisingObjective] ?
                products[advertisingObjective].long
                :
                advertisingObjective}
            </div>
            <div>
                Daily Budget: ${dailyBudget}
            </div>
        </div>
    );

    const content = () => (
        <ButtonGroup className="ml-5" size="md">
            {/*<Button color="grey">Preview Campaign</Button>*/}
            <CampaignPreviewModal
                launchCampaign={props.launchCampaign}
                campaign={props.campaign}
                removeCampaign={props.removeCampaign}
                loading={props.loading}
            />
            <LoadingButton
                outline
                color="primary"
                loading={props.loading}
                onClick={() => props.launchCampaign([props.campaign])}
            >
                Build Campaign
            </LoadingButton>
        </ButtonGroup>
    );

    return (
        <CustomWidget
             heading={`Product ASIN: ${productASIN}`}
             subheading={subheading()}
             collapsible={false}
             content={content()}
        />
    )
}

export default CartWidget;