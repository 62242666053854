import React, {Fragment,useState,useEffect} from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import SelectableWidget from '../../../../Components/Widgets/SelectableWidget';
import LoadingButton from '../../../../Components/Buttons/LoadingButton';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import StarRating from '../../../Scenes/AccountOverview/Components/Rating/StarRating';
import {check} from '../../../../Components/Can';
import OneClickIcon from "../../../../../designUtils/Logo/Icon_small_centered.png";
import Play_icon from "../../../../../designUtils/img/play.svg";
import Stop_icon from "../../../../../designUtils/img/pause.svg";
import Info from "../../../../../designUtils/img/info.svg";
import { SalesDataService } from '../../../../../Service/OPSServices/SalesDataService';
import TimeFilteredDataDisplay from './TimeFilteredDataDisplay';
import CustomModal from "../../../../Components/Modals/CustomModal";
import CustomLoadingOverlay from "../../../../Components/Nav/CustomLoadingOverlay";
import { LatestCampaignService } from '../../../../../Service/OPSServices/LatestCampaignService';
import SalesDataTable from '../../../Scenes/Operations/Scenes/AdvertisingData/Components/SalesDataTable';
import UpdateSKUForAsinStoreService from '../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService';
import StoreOverviewService from '../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import GetCatalogueProductDetailsService from '../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/GetCatalogueProductDetailsService';
import CatalogueItemDetails from './CatalogueItemDetails';
import Alert from "../../../../../designUtils/img/Alert.svg";
import TicketListPage from '../../../Scenes/TroubleTickets/TicketListPage';
import ProductsAlertTicketCreate from '../../../Scenes/TroubleTickets/Components/ProductTickets/ProductsAlertTicketCreate';
const managementStates = {
    INACTIVE: 'unManaged',
    ACTIVE: 'managed',
    PAUSED: 'paused',
};

function SelectableProductWidget(props) {
    const {
        productTitle,
        productImage,
        productRating,
        numProductReadyCampaigns,
        ratingVotes,
        details,
        skuList,
        isManaged,
        managementStatus,
        areKeywordsTracked,
        quickLaunch,
        handleQuickActions,
    } = props;

    const [summary, setSummary] = useState();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [asinSalesDataList,setAsinSalesDataList] = useState();
    const [saleSummary, setSaleSummary] = useState();
    const [isOpenNew, setIsOpenNew] = useState(false);
    const [isOpenCatalogueItem, setIsOpenCatalogueItem] = useState(false);
    const [catlogProductDetails, setCatlogProductDetails] = useState();
    const [isTicketListOpen, setIsTicketListOpen] = useState(false);
    const [asinForTicketList, setAsinForTicketList] = useState(false);


    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });


    const viewLatestCampaign = () => {

        setLoading(true);        

        let data = 
                {
                    id : details.ASIN,
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate
                }
                   
                LatestCampaignService(props.token, data).then(response => {
            if (response) {
                setSummary(<SalesDataTable asin={details.ASIN} data ={response}/>);
                setIsOpen(true);
               
            }
        })

        setLoading(false);
        
    }
    //   let { storeIntegerID } = props.match.params;
    const getSalesData = () => {

        setLoading(true);        

        let data = {
                        id : details.ASIN,
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate
                    }
                    
        SalesDataService(props.token, data).then(response => {
            if (response) {

                // console.log("response.sales")
                // console.log(response.asinData.sales)
                // setAsinSalesDataList(response);
                setSummary(<TimeFilteredDataDisplay asin={details.ASIN} data ={response}/>);
                setIsOpen(true);
               
            }
        })

        setLoading(false);
        
    }
    const handleGetSalesData = (key) =>
        (<Button
            color={'brand-blue-dark'}
            className={'mr-2'}
            onClick={() =>
                // setIsSalesRefreshOpen(!isSalesRefreshOpen)
                getSalesData()
                                                }
        >
            Sales Data									
            </Button>);
    
    const handleCampaignData = (key) =>
       
                    <Button
                    color={'brand-blue-dark'}
                    className={'mr-2'}
                    onClick={() => {
                        
                        viewLatestCampaign()
                                                 
                    }}
                    >
                    Campaigns
                    </Button>
        

    const launchCampaignButton = (key) =>
        (managementStatus === managementStates.INACTIVE ||
            check(props.accessLevel, 'campaigns:relaunch')) && (
            <LoadingButton
                className="mr-2 ml-2 btn-icon btn-icon-left"
                loading={props.loading}
                key={key}
                color="brand-blue-dark"
                onClick={(e) => {
                    e.stopPropagation();
                    quickLaunch(details.ASIN, skuList);
                }}
            >
                <img className="mr-1" src={OneClickIcon} width={25} height={25}/>
                1-Click Campaign
            </LoadingButton>
        );
        const renderTicketList = (asin,sku) => {
            setAsinForTicketList(asin)
            setIsTicketListOpen(true)
            // return ()
        }
        const handleCreateAndViewTicketClose = (response) => {
            setIsTicketListOpen(false)
            // return ()
        }
        const alertBtn = (key) =>
        <>
          <Button
            loading={props.loading}
            key={key}
            outline={managementStatus === managementStates.ACTIVE}
            color={
                managementStatus === managementStates.ACTIVE ? '' : ''
            }
            onClick={(e) => {
                e.stopPropagation();
                // handleQuickActions.find(qa => qa.id === "updateManagement").action({
                //     productASIN: details.ASIN,
                //     productSKU: details.SKU,
                //     managementStatus: managementStatus,
                // });
                renderTicketList(details.ASIN,details.SKU);
            }}
        >
        <img  className=""  src={Alert} width={25} height={25}/>
        </Button>
        </>
    const updateProductManagementButton = (key) =>
    <>
            <Button
            loading={props.loading}
            key={key}
            // outline={managementStatus === managementStates.ACTIVE}
            color={
                managementStatus === managementStates.ACTIVE ? '' : ''
            }
            onClick={(e) => {
                e.stopPropagation();
                handleQuickActions.find(qa => qa.id === "updateManagement").action({
                    productASIN: details.ASIN,
                    productSKU: details.SKU,
                    managementStatus: managementStatus,
                });
            }}
        >
          
            {managementStatus === managementStates.ACTIVE 
                ? <img  className="" title='Pause Management' src={Stop_icon} width={25} height={25}/>
                : <img className="" title='Resume Management' src={Play_icon} width={25} height={25}/>}
        </Button>
        
        </>
        // );
        const handleProductDetails = async (asin, integerID) => {
            let response = await GetCatalogueProductDetailsService(props.token, asin, integerID);
            if (response) {
                setCatlogProductDetails(<CatalogueItemDetails data={response} />)
                setIsOpenCatalogueItem(true) 
            }
        }
    
        const managementStatusMessage = (key) =>
        (managementStatus === managementStates.ACTIVE ? (
            <span className="mt-2 text-brand-blue-dark font-weight-bold">
                <i
                    className="pe-7s-check"
                    style={{fontSize: '1rem', color: 'green', fontWeight: 'bold'}}
                >
                    {' '}
                </i>{' '}
                Managed by Simpliworks
            </span>
        ) : managementStatus === managementStates.PAUSED ? (
            <span className="mt-2 text-warning font-weight-bold">
                <i
                    className="pe-7s-attention text-warning"
                    style={{fontSize: '1rem', fontWeight: 'bold'}}
                >
                    {' '}
                </i>{' '}
                Management Paused
            </span>
        ) : (
            <></>
        ));
         const handleProductDetailsIcon = (key) =>
         (<Button
             color={''}
             className={'ml-0 mr-0'}
             onClick={() =>
                handleProductDetails(details.ASIN,props.storeIntegerID)
                                                 }
         >
           <img className="" title='Product Details' src={Info} width={25} height={25}/>						
            </Button>);
    const updateProductTrackingButton = (key) =>
        managementStatus === managementStates.ACTIVE && (
            <LoadingButton
                loading={props.loading}
                key={key}
                outline={areKeywordsTracked}
                color={areKeywordsTracked ? 'secondary' : 'primary'}
                onClick={(e) => {
                    e.stopPropagation();
                    handleQuickActions[2].action({
                        productASIN: details.ASIN,
                        productSKU: details.SKU,
                        ...props,
                    });
                }}
            >
                {areKeywordsTracked ? 'Pause Tracking' : 'Product Tracking'}
            </LoadingButton>
        );
       function handleUpdateOptimizeListingMsg(){
        alert('Development for this functionality is in progress..');
       }
        
    const quickActionsDropdown = (key) => {
        if (handleQuickActions) {
            return (
                <UncontrolledButtonDropdown key={key}>
                    <DropdownToggle
                        caret
                        color="link"
                        onClick={(e) => e.stopPropagation()}
                    >
                        More Actions
                    </DropdownToggle>
                    <DropdownMenu>
                        {handleQuickActions.map((qa) => {
                            if (qa.show) return (
                                <DropdownItem
                                    key={key + qa.name}
                                    onClick={() =>
                                        qa.action({
                                            productASIN: details.ASIN,
                                            productSKU: details.SKU,
                                            ...props,
                                        })
                                    }
                                >
                                    {qa.name(props)}
                                </DropdownItem>
                            )
                            else return <></>
                        })}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            );
        }
    };
    
    const handleUpdateSkus = async (asin, integerID) => {
        let response = await UpdateSKUForAsinStoreService(props.token, asin, integerID);
        if (response) {
                StoreOverviewService(
                    props.token,
                    props.storeIntegerID,
                    props.storeAccountID
                )
                window.location.reload();
        }
    };
    const handleUpdateSkusButton = (key) =>
    (<Button
        color={'brand-blue-dark'}
        style={{height:"38px"}}
        className={'mr-2'}
        onClick={() =>
            // setIsSalesRefreshOpen(!isSalesRefreshOpen)
            handleUpdateSkus(details.ASIN,props.storeIntegerID)
                                            }
    >
        Update SKUs								
        </Button>);
            const handleUpdateViewAnalytics = (key) =>
            (<Button
                color={'brand-blue-dark'}
                style={{height:"38px"}}
                className={'mr-2'}
                onClick={handleUpdateOptimizeListingMsg}
               
            >
               	View Analytics						
                </Button>);
                 const handleUpdateOptimizeListing = (key) =>
                 (<Button
                     color={'brand-blue-dark'}
                     style={{height:"38px"}}
                     className={'mr-2'}
                    onClick={handleUpdateOptimizeListingMsg}
                 >
                        Optimize Listing						
                     </Button>);
    const subheading = () => (
        <Fragment>
            {ratingVotes !== 0 ? (
                <div>
                    Rating: <StarRating initialRating={productRating} readonly stop={5}/>{' '}
                    ({productRating}) ({ratingVotes} reviews)
                </div>
            ) : (
                <div>No ratings available</div>
            )}
            {numProductReadyCampaigns > 0 && (
                <div>
                    {numProductReadyCampaigns} campaigns for this product are ready to
                    launch
                </div>
            )}
            {/* {managementStatus === managementStates.ACTIVE ? (
                <div className="mt-2 text-brand-blue-dark font-weight-bold">
                    <i
                        className="pe-7s-check"
                        style={{fontSize: '1rem', color: 'green', fontWeight: 'bold'}}
                    >
                        {' '}
                    </i>{' '}
                    Managed by Simpliworks
                </div>
            ) : managementStatus === managementStates.PAUSED ? (
                <div className="mt-2 text-warning font-weight-bold">
                    <i
                        className="pe-7s-attention text-warning"
                        style={{fontSize: '1rem', fontWeight: 'bold'}}
                    >
                        {' '}
                    </i>{' '}
                    Management Paused
                </div>
            ) : (
                <></>
            )} */}
        </Fragment>
    );

    return (
        <>
        <SelectableWidget
            collapsible={true}
            collapseOnUnselect={true}
            heading={productTitle}
            image={productImage}
            // image={productImage ? productImage : }
            subheading={subheading()}
            details={details}
            data={details}
            autoFormat={true}
            newActions={[
                managementStatusMessage,
                updateProductManagementButton,
                alertBtn,
                handleProductDetailsIcon,
            ]}
            actions={[
                // handleGetSalesData,
                // handleCampaignData,
                // handleUpdateSkusButton,
                handleUpdateViewAnalytics,
                handleUpdateOptimizeListing,
                launchCampaignButton,
                // quickActionsDropdown
            ]}
            toggleSelect={(isSelected) => props.toggleSelect(isSelected, details.ASIN, false)}
            isSelected={props.isSelected}
            listItemKey={props.listItemKey}
        />
           <CustomModal
        header={"Create Ticket"}
        isOpen={isTicketListOpen}
        toggle={() => setIsTicketListOpen(!isTicketListOpen)}
        size={"lg"}
        className="bgColor"
        // footer={
        //   <>
        //     <Button
        //       color={"link"}
        //       onClick={() => setIsTicketListOpen(!isTicketListOpen)}
        //     >
        //       Cancel
        //     </Button>
        //   </>
        // }
      >
        {/* <Label> */}
         <ProductsAlertTicketCreate  asinForTicketList = {asinForTicketList} handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}/>
         {/* <TicketListPage asinForTicketList = {asinForTicketList} requestTypeID={2} problemAreaId={1} requestSubTypeID={1} problemAreaPathwayId={1}/> */}
        {/* </Label> */}
      </CustomModal>
        <CustomModal
                header={props.profileName }
                isOpen={isOpenNew}
                className="salesPopup"
                toggle={() => setIsOpenNew(!isOpenNew)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpenNew(!isOpenNew)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{saleSummary}</pre> </p></Label>                
            </CustomModal>

        <CustomModal
                header={props.profileName}
                isOpen={isOpen}
                className="salesPopup"
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
            <CustomModal
                header={'Product Details'}
                isOpen={isOpenCatalogueItem}
                className=""
                toggle={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
                size={"lg"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> {catlogProductDetails}</Label>                
            </CustomModal>
            </>
        
    );
}

export default SelectableProductWidget;
