import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import actionType from '../../../../Redux/Actions/ActionTypes';
import {LoadAmaProdCategories} from "../../../../Redux/Actions/Actions";

function RefreshAmazonCategoriesService(token, amazonDomain) {
    
    return function(dispatch) {

        dispatch({ type: actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/FetchAndSaveAllProductCategoriesForDomain/${amazonDomain}`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            dispatch(LoadAmaProdCategories(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_FAILED });

        });
    }
}

export default  RefreshAmazonCategoriesService;