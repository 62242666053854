import React, { Fragment, useEffect, useState } from "react";
import { Container, Card, CardBody, CardHeader } from "reactstrap";
import "./ProgressBar";
import Rectangle from "./img/Rectangle.png";
import Group from "./img/Group.png";
import "./ProgressBar.css";
import { Link } from "react-router-dom";
import StarRating from "../../AccountOverview/Components/Rating/StarRating";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { Col, Input, Row, Button, Label } from "reactstrap";
import LoadingButton from "../../../../Components/Buttons/LoadingButton";
import { DropdownList } from "react-widgets";
import OneClickIcon from "./img/Icon_small_centered.png";
import UpdateAsinManagementStatusService from "../../../../../Service/DashboardServices/RegistrationJourneyServices/UpdateAsinManagementStatusService";
import regions from "../../../../../Utils/RegionAuthorization";
import AuthButtonSPAPI from "../../UserProfile/Scenes/Authorization/Components/AuthButtonSPAPI";
import { useParams } from 'react-router-dom';
function StoreSetup(props) {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState("");
  const [ProductList, setProductList] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [asinArray, setAsinArray] = useState([]);
  const { profileId } = useParams();

  function handleChange(e) {
    setChecked(e.target.checked);
    addValueToArray(e.target.value);
    console.log("e.target.checked");
    console.log(e.target.checked);

    var simpliworksManagementStateOnScreen = "";
    if (e.target.checked === false) {
      simpliworksManagementStateOnScreen = "unManaged";
    } else if (e.target.checked === true) {
      simpliworksManagementStateOnScreen = "managed";
    }

    const updatedArray = store.productList.map((item) =>
      item.asin === e.target.value
        ? {
            ...item,
            simpliworksManagementState: simpliworksManagementStateOnScreen,
          }
        : item
    );

    console.log("updatedArray");
    console.log(updatedArray);
    store.productList = updatedArray;
    setStore(store);
    console.log(store);
  }

  const addValueToArray = (value) => {
    const updatedArray = [...asinArray];
    updatedArray.push(value);
    setAsinArray(updatedArray);
  };

  const {
    productRating,
    numProductReadyCampaigns,
    ratingVotes,
    managementStatus,
  } = props;
  const storeData = {
    id: '',
    name:'',
    location: '',
    logo: '',
    storeRatings: '',
    productList: '',
    storeSPAPIAuthorized: '',
    storeSetUpComplete:'',
  }
  if(props.storeCount > 0)
  {
   if(profileId){
      props.storeList.map((selectedStore)=>(
        selectedStore.integerID == profileId ? 
        window.storeData = {
          id: selectedStore.integerID,
          name: selectedStore.name,
          location: selectedStore.location,
          logo: selectedStore.logo,
          storeRatings: selectedStore.rating,
          productList: selectedStore.productList,
          storeSPAPIAuthorized: selectedStore.storeSPAPIAuthorized,
          storeSetUpComplete:selectedStore.storeSetUpComplete,
        }
        :
        ''
      ))
     }
     else{
      window.storeData = {
        id: props.storeList[0].integerID,
        name: props.storeList[0].name,
        location: props.storeList[0].location,
        logo: props.storeList[0].logo,
        storeRatings: props.storeList[0].rating,
        productList: props.storeList[0].productList,
        storeSPAPIAuthorized: props.storeList[0].storeSPAPIAuthorized,
        storeSetUpComplete:props.storeList[0].storeSetUpComplete,
      }
     }
  }
  //console.log("storeList & productlist");
  //console.log(props.storeList);
  useEffect(() => {
    if(props.storeCount > 0)
  {
    setStore(window.storeData);
  }
  else 
  {
    setStore(storeData);
  }
    // setStore(window.storeData);
    console.log("useEffect");
    console.log(window.storeData);
  }, []);
  const setSelectedStore = (value) => {
    storeData.id = value.integerID;
    storeData.name = value.name;
    storeData.location = value.location;
    storeData.logo = value.logo;
    storeData.storeRatings = value.rating;
    storeData.productList = value.productList;
    storeData.storeSPAPIAuthorized = value.storeSPAPIAuthorized;
    storeData.storeSetUpComplete = value.storeSetUpComplete;
    setStore(storeData);
    console.log("setSelectedStore");
    console.log(storeData);
  };
  const handleOnClick = () => {
    setIsOpen(true);
  };
  const handleOnSubmit = async () => {
    const asinList = store.productList
      .filter((product) => product.simpliworksManagementState == "managed")
      .map((item) => ({ asin: item.asin }));

    let data = {
      integerID: store.id,
      asinList: asinList,
    };
    UpdateAsinManagementStatusService(props.token, data).then((response) => {
      if (response) {
        setIsOpen(false);
      }
    });
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <>
      <Container fluid>
        <Row className="justify-content-around ">
          <Col md="5" className=" mt-2">
            <h2 className="conaitent-heading">
              <b>SPAPI Authorization Steps:</b>
            </h2>
            
            
            {props.storeCount > 1 ? (
                  <>
                  <ol type="1" className="mb-3">
                    <li className="text-font">
                      Log in to your seller central account in another tab.
                    </li>
                    <li className="text-font">
                      Select required store in seller central.
                    </li>
                    <li className="text-font">
                      Come back to Simpliworks window and click 'Authorize'
                     <br></br> button below.
                    </li>
                    <li className="text-font">
                      Consent page will open in the new tab.
                    </li>
                    <li className="text-font">Please confirm authorization.</li>
                    <li className="text-font">
                      On successful authorization, a message will get displayed.
                    </li>
                    <li className="text-font">
                      Your store setup process will be started in the
                      background.
                    </li>
                    <li className="text-font">
                     Please press the 'Refresh' button after 10 to 15 minutes.
                    </li>
                  </ol>
                  <p className="text-end"> <Button
                              color={"brand-blue-dark"}
                              className="ml-auto mb-3"
                              onClick={handleRefresh}
                            >
                              Refresh
                            </Button></p> 
                            {/* style={{textAlign: 'right'}} */}
                 </>
                ) : (
                  <ol type="1" className="mb-5">
              {/* <li className="text-font">Log in to your seller central account in another tab.</li>
              <li className="text-font">Come back to Simpliworks window and click authorize.</li> */}
              <li className="text-font">Consent page will open in the new tab.</li>
              <li className="text-font">Please confirm authorization.</li>
              <li className="text-font">On successful authorization, a message will get displayed.</li>
              <li className="text-font">Your store setup process will be started in the background.</li>
            </ol>
                )}
            
            
          </Col>

          <Col md="6" className=" mt-2">
          <Card>
              <Row className="justify-content-around pl-5 pr-5 pt-5">
                <Col md="4">
                <div className={store.logo ? 'mt-5' : ''}>
								<p className='text-center'><img src={store.logo ? store.logo : Group}  width={store.logo ? '' : 80}/></p>
								
							</div>
                  <DropdownList
                    className="mt-3 mb-3 dropdown-style "
                    data={props.storeList}
                    dataKey="integerID"
                    textField="name"
                    placeholder="Select"
                    defaultValue={"placeholder"}
                    value={store}
                    disabled={profileId ? true : false}
                    onChange={(value) => {
                      setSelectedStore(value);
                    }}
                  />
                  {((store.storeSPAPIAuthorized == false) && (props.storeCount > 0)) ? (
                  <Col md="5">
                    <AuthButtonSPAPI
                      pathname={regions[0].pathname}
                      region={regions[0].name}
                    />
                  </Col>
                ) : (
                  ""
                )}
                </Col>


                <Col md="8" className="">
                 
                  <p>
                    <b>Store name: </b>
                    {store.name}
                  </p>
                  <p>
                    <b>Account ID: </b>
                    {store.id}
                  </p>
                  <p>
                    <b>Location : </b>
                    {store.location}
                  </p>
                  <p>
                    <b>Seller central: </b>
                    {store.storeSPAPIAuthorized == false ? (
                  "Authorization pending"
                ) : (
                  "Authorized"
                )}
                  </p>
                  <p>
                    <b>Store rating: </b>&nbsp;
                    <StarRating
                      initialRating={store.storeRatings}
                      readonly
                      stop={5}
                    />
                  </p>
                </Col>
                </Row>
                <Row className=" pl-5 pr-5 pb-3">
                
                
              </Row>

            </Card>
          </Col>
        </Row>
      </Container>
      {/* Add custom model here only */}

      <CustomModal
        header={"Store name: " + store.name}
        isOpen={isOpen}
        className="h-50"
        toggle={() => setIsOpen(!isOpen)}
        size={"lg"}
        footer={
          <>
            <Button color={"link"} onClick={() => setIsOpen(!isOpen)}>
              Cancel
            </Button>
          </>
        }
      >
        <Button
          className="mt-2 ml-2 pl-2 pr-2 float-right"
          color={"brand-blue-dark"}
          onClick={() => {
            {
              handleOnSubmit();
            } //props.requestStores(selectedUser.email);
          }}
        >
          Submit
        </Button>
        <Label className="color-changing">
          <Card>
            <div>{ props.storeCount > 0 ?<>
              {props.storeList.map((oneStore) => (
                <>
                  {oneStore.integerID == store.id ? (
                    <>
                      {store.productList.map((product) => (
                        <>
                          <Row
                            className={
                              product.simpliworksManagementState == "managed"
                                ? "row-background-color-blue"
                                : ""
                            }
                          >
                            <Col md="1" className="mt-5">
                              <div className="">
                                {product.simpliworksManagementState ==
                                "managed" ? (
                                  <img
                                    className="float-right"
                                    src={OneClickIcon}
                                    width={40}
                                    height={40}
                                  />
                                ) : (
                                  ""
                                )}
                                <input
                                  className="mt-5 float-right"
                                  value={product.asin}
                                  type="checkbox"
                                  name={product.asin}
                                  // checked={false}
                                  checked={
                                    product.simpliworksManagementState ==
                                    "managed"
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col md="11">
                              <Row className="justify-content-center border m-2 row-background-color">
                                <Col md="3" className="p-2">
                                  <div>
                                    <img
                                      className="productlistitem"
                                      src={product.itemImage}
                                    />
                                  </div>
                                </Col>
                                <Col md="8" className="p-2">
                                  <div>
                                    <h6>
                                      <b>{product.productTitle}</b>
                                    </h6>

                                    <p>
                                      {ratingVotes !== 0 ? (
                                        <div>
                                          Rating:{" "}
                                          <StarRating
                                            initialRating={product.itemRating}
                                            readonly
                                            stop={5}
                                          />{" "}
                                          ({product.itemRating}) ({ratingVotes}{" "}
                                          reviews)
                                        </div>
                                      ) : (
                                        <div>No ratings available</div>
                                      )}
                                    </p>
                                    <p>
                                      {product.simpliworksManagementState ==
                                      "unManaged" ? (
                                        <div className="mt-2 text-warning font-weight-bold">
                                          <i
                                            className="pe-7s-attention text-warning"
                                            style={{
                                              fontSize: "1rem",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {" "}
                                          </i>{" "}
                                          Management Paused
                                        </div>
                                      ) : (
                                        <div className="mt-2 text-brand-blue-dark font-weight-bold">
                                          <i
                                            className="pe-7s-check"
                                            style={{
                                              fontSize: "1rem",
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {" "}
                                          </i>{" "}
                                          Managed by Simpliworks
                                        </div>
                                      )}{" "}
                                    </p>
                                    <div className="">
                                      <p>
                                        <span>
                                          <b>ASIN:</b> {product.asin}
                                        </span>
                                        <span className="float-right ml-auto">
                                          <b>PRICE:</b> ${product.itemPrice}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </> :""}
            
              
            </div>
          </Card>
        </Label>
      </CustomModal>
    </>
  );
}

export default StoreSetup;
