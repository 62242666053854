import { combineReducers } from "redux";

import actionType from '../Actions/ActionTypes';
import LWAReducer from "./LWAReducer";
import CartReducer from "./CartReducer";
import EntryReducer from "./EntryReducer";
import CampaignBuilderReducer from "./CampaignBuilderReducer";
import AccountOverviewReducer from "./AccountOverview";
import ProjectReducer from './ProjectReducer';
import ThemeReducer from "./Theme/ThemeReducer";
import ProfileReducer from "./ProfileReducer";


const appReducer = combineReducers({
    theme: ThemeReducer,
    LWA: LWAReducer,
    loggedUser: EntryReducer,
    profile: ProfileReducer,
    cart: CartReducer,
    accountOverview: AccountOverviewReducer,
    campaignBuilder: CampaignBuilderReducer,
    project: ProjectReducer
});

const rootReducer = (state, action) => {
    if (action.type === actionType.LOGOUT_SUCCESS) {
        state = undefined;
    }

    return appReducer(state, action);
}

export default rootReducer;
