import React, {Fragment, useCallback, useState} from 'react';
import { Row, Col } from 'reactstrap';


import FileDropzone from "../../../../../../../../Components/FileDropzone";
import SearchTermReportUploadService
    from "../../../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/SearchTermReportUploadService";


function SearchTermReportTab(props) {

    const [loading, setLoading] = useState(false);

    const handleFileUpload = async files => {
        setLoading(true);
        await SearchTermReportUploadService(props.token, props.integerID, files[0]);
        setLoading(false);
    }

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <h5 className="text-brand-green-dark">Search Term Report Upload</h5>
                </Col>
                <Col md="11" className="ml-2 mb-3">
                    <h6>Search term report files can be uploaded below in (.csv) format. Only one file can be uploaded at a time.</h6>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FileDropzone
                        options={{accept: '.csv', multiple: false}}
                        singleFile={true}
                        loading={loading}
                        onSubmit={handleFileUpload}
                    />
                </Col>
            </Row>
        </Fragment>

    )
}

export default SearchTermReportTab;