import React from "react";
import { connect } from 'react-redux';

import urls from '../../../../../Utils/URLs';
import DashboardLayout from "../../../Layout/DashboardLayout";
import {filterByPermission} from "../../../../Components/Can";


function UserProfileLayout(props) {

    let menuItems = [
        {
            perform: "profile:authorization",
            yes: {
                icon: 'pe-7s-safe',
                label: 'Authorization',
                to: urls.USER_PROFILE_AUTH_URL,
            },
        },
        {
            perform: "accounts:view",
            yes: {
                icon: 'pe-7s-users',
                label: 'User Directory',
                to: urls.USER_PROFILE_DIRECTORY_URL,
            },
        },
        {
            perform: "subAccounts:view",
            yes: {
                icon: 'pe-7s-users',
                label: 'Sub-Accounts',
                to: urls.USER_PROFILE_SUB_ACCOUNTS_URL,
            },
        },
        {
            perform: "contentAgencies:view",
            yes: {
                icon: 'pe-7s-network',
                label: 'Content Agencies',
                to: urls.USER_PROFILE_CONTENT_AGENCIES_URL,
            },
        },
        {
            perform: "leadGeneration:view",
            yes: {
                icon: 'pe-7s-display2',
                label: 'Lead Gen : Quick Search',
                to: urls.USER_PROFILE_LEAD_GEN_URL,
            },
        },
        {
            perform: "lgAmazonProductCategories:view",
            yes: {
                icon: 'pe-7s-graph',
                label: 'Market Research : Categories',
                to: urls.USER_PROFILE_AMA_PROD_CAT_URL,
            },
	    },
        {
            perform: "lgResearchByProductCategory:view",
            yes: {
                icon: 'pe-7s-graph2',
                label: 'Market Research : Create Job',
                to: urls.USER_PROFILE_LEAD_GEN_RESEARCH_PRODUCT_CATEGORY_URL,
            },
        },
        {
            perform: "lgResearchjobs:view",
            yes: {
                icon: 'pe-7s-bookmarks',
                label: 'Operations : Research Jobs List',
                to: urls.USER_PROFILE_LEAD_GEN_JOBS_URL,
            },
        },
        

    ];

    menuItems = filterByPermission(menuItems, props.accessLevel);

    return (
        <DashboardLayout
            menuItems={menuItems}
            heading="My Profile"
            icon="lnr-map text-info"
            subheading=""
        >
            {props.children}
        </DashboardLayout>
    );
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(UserProfileLayout);
