import React  from 'react';
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
function FetchProductManagementHistory(token,data) {
    return axios.post(`${CURRENT_API_URL}/campaign/fetchProductManagementHistory`,data, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        // notify.NotifySuccess("Store products history are getting fetched.");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem while getting products history.");
        return false;
    });
}

export default FetchProductManagementHistory;