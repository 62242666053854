import {CURRENT_ENV } from "./Constants";

const testPriceIDs = {
    growth: {
        monthly: "price_1KHHjCGIqnst6pth1CXu3b2m",
        annual: "price_1KHHjCGIqnst6pthH5c8uKix",
    },
    elite: {
        monthly: "price_1KHHjxGIqnst6pthiOHIUf8l",
        annual: "price_1KHHjxGIqnst6pthoSsu0LzD",
    },
    basic: {
        monthly: "price_1LB0FbGIqnst6pthaRY7CQTy",
    },
    pro: {
        monthly: "price_1LB0H0GIqnst6pthfrlMrYnD",
        singular: "price_1LB0H0GIqnst6pthKrFugtIR",
    },
};

const livePriceIDs = {
    growth: {
        monthly: "price_1KHH43GIqnst6pthGM7dQqbz",
        annual: "price_1KHH43GIqnst6pth2NF9qn2Z",
    },
    elite: {
        monthly: "price_1KHH5FGIqnst6pthDfzdHJ74",
        annual: "price_1KHH5FGIqnst6pthw95OvlTG",
    },
    basic: {
        monthly: "price_1LB08PGIqnst6pthBiCqdxNP",
    },
    pro: {
        monthly: "price_1LB0ANGIqnst6pthAk02D00H",
        singular: "price_1LB0ANGIqnst6pthZUL9PlzE",
    },
};

const priceIDs = CURRENT_ENV === 'PRODUCTION' ? livePriceIDs : testPriceIDs;

const pricingOptions = [
    {
        title: "Basic",
        subtitle: "Best for entrepreneurs looking to scale individual products.",
        monthlyPrice: 197,
        annualPrice: 0,
        numManagedProducts: 1,
        numManagedProductFamilies: 0,
        level: 0,
        managedProductsDescription: 'up to 1 managed product',
        features: [
            '1 Managed Product SKU',
            '1-Click Campaign Launcher',
            'End-to-End Campaign Management',
            'Standard Reporting Dashboard',
            'Limited Support',
        ],
        monthlyPriceID: priceIDs.basic.monthly,
    },
    {
        title: "Pro",
        subtitle: "Best for brands looking to scale portfolios of products",
        monthlyPrice: 150,
        annualPrice: 405,
        numManagedProducts: -1,
        numManagedProductFamilies: 5,
        managedProductsDescription: 'up to 5 managed product families',
        level: 1,
        features: [
            'Up to 5 Product Families',
            '1-Click Campaign Launcher',
            'End-to-End Campaign Management',
            'Unlimited Variations',
            'Single-Day Keyword Tracking',
            'Weekly Performance Report',
            'Priority Support',
        ],
        monthlyPriceID: priceIDs.pro.monthly,
    },
    {
        title: "Enterprise",
        subtitle: "Best for enterprises looking to scale catalogs of products.",
        monthlyPrice: 0,
        annualPrice: 756,
        numManagedProducts: -1,
        numManagedProductFamilies: 10,
        managedProductsDescription: 'up to 10 managed product families',
        level: 2,
        features: [
            'Up to 10 Product Families',
            '1-Click Campaign Launcher',
            'End-to-End Campaign Management',
            'Unlimited Variations',
            'Multi-Day Keyword Tracking',
            'Weekly Keyword Trends Report',
            'Weekly Performance Report',
            'Priority Support',
            'White Glove Onboarding Call',
            'Monthly Insight Report',
            'Monthly 30 Minute Strategy Call',
            'Quarterly Business Review',
        ],
    },
];


export default pricingOptions;