import React from 'react';
import { Button } from 'reactstrap';

import CustomWidget from "../../../../Components/Widgets/CustomWidget";


function NewKeywordWidget(props) {
    const { keywordText, matchType } = props.keyword;

    const renderRemoveButton = () => (
        <Button
            outline
            color="danger"
            onClick={() => props.handleRemove(props.keyword)}
        >
            Remove
        </Button>
    )

    return (
        <CustomWidget
            heading={keywordText}
            subheading={`Match Type: ${matchType === 'Theme' ? 'phrase-match' : 'exact-match'}`}
            collapsible={false}
            content={renderRemoveButton()}
        />
    )
}

export default NewKeywordWidget;