import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import urls from '../../Utils/URLs';
import LoginPage from "./Scenes/Login/LoginPage";
import AccountRecoveryPage from "./Scenes/AccountRecovery/AccountRecoveryPage";
import SPAPIRedirectPage from "../Dashboard/Scenes/UserProfile/Scenes/Authorization/Components/SPAPIRedirectPage";
import RegistrationPage from "./Scenes/Registration/RegistrationPage";


function Entry() {

    return (
        <Switch>
            <Route exact path={urls.LOGIN_URL}>
                <LoginPage />
            </Route>
            <Route path={urls.BASE_REGISTER_URL}>
                <RegistrationPage />
            </Route>
            <Route exact path={urls.STORE_AUTH_REDIRECT_URL}>
                <SPAPIRedirectPage />
            </Route>
            <Route path={urls.BASE_ACCOUNT_RECOVERY_URL}>
                <AccountRecoveryPage />
            </Route>
            <Route render={() => <Redirect to={{pathname: urls.LOGIN_URL}} />} />
        </Switch>
    );
}

export default Entry;
