import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';

import { connect } from 'react-redux';

import LeadGenReasearchProdcutCategoryToolService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenReasearchProdcutCategoryToolService";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import ProductsTable from "./Components/ProductsTable";
import SellersTable from "./Components/SellersTable";
import LeadGenJobTables from "./Components/LeadGenJobTables"
import CustomModal from "../../../../../Components/Modals/CustomModal";
import LeadGenProductsTable from "./Components/LeadGenProductsTable";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import Can from "../../../../../Components/Can";

import ProjectsListService from "../../../../../../Service/DashboardServices/UserProfileServices/ProjectsListService";
import ProductCategoriesTable from "./Components/ProductCategoriesTable";
import LeadGenReasearchJobsToolService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenReasearchJobsToolService";
import LeadGenProdCatJobDataToolService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatJobDataToolService";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function LeadGenResearchProudctCategory(props) {


    
    const [view, setView] = useState("profile"); 
    const [productCategory, setproductCategory] = useState('');   
    const [productASIN, setproductASIN] = useState('');   
    const [message, setmessage] = useState('');
    
    useEffect(() => {
        props.dispatch(LeadGenReasearchJobsToolService(props.token)); 
    }, [])

    const [isOpen, setIsOpen] = useState(false);    
    const [loading, setLoading] = useState(false);
        
        const handleSubmit = (id) => {
            if (id){
                props.dispatch(LeadGenProdCatJobDataToolService(props.token, id));}}
        
        const ExportCSV = (csvData, fileName) => {

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            console.log('Download Called')
            const exportToCSV = (csvData, fileName) => {
                console.log(csvData)
                console.log(fileName)
                const ws = XLSX.utils.json_to_sheet(csvData.productList);
                const ws1 = XLSX.utils.json_to_sheet(csvData.sellerList);
                const ws2 = XLSX.utils.json_to_sheet(csvData.productSalesEstimatesList);
                const ws3 = XLSX.utils.json_to_sheet(csvData.top100productAnalysisList);
                const ws4 = XLSX.utils.json_to_sheet(csvData.jobSummaryData);
                const ws5 = XLSX.utils.json_to_sheet(csvData.jobHistoryData); 
                //const wb = { Sheets: { 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2,'top100productAnalysisList': ws3 }, SheetNames: ['productList','sellerList','productSalesEstimatesList','top100productAnalysisList'] };                
                const wb = { Sheets: {'jobSummaryData':ws4, 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2,'top100productAnalysisList': ws3,'jobHistoryData':ws5 }, SheetNames: ['jobSummaryData','productList','sellerList','productSalesEstimatesList','top100productAnalysisList','jobHistoryData'] };                
                
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                console.log('before save')
                FileSaver.saveAs(data, 'MarketResearchData' + fileExtension);   
                               
                };

            exportToCSV(csvData, fileName);
                
            }        
    return (
        <Fragment>
            <Container fluid>
                   

                    
                
                <Row>
                    <Col md="9" className="mb-2" >
                        <h4>All Lead Generation Jobs</h4>
                    </Col>
                </Row>
                <Row>
                        <h5 className="mt-3 text-primary" color="green">
                        {' '}
                            <b>{props.researchProductCategoryData.message}</b></h5>                            
                    </Row > 
                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <LeadGenJobTables data={props.leadGenJobs} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row> 
                
                <Row>
                    <Col md="9" className="mb-2">
                        <h1>Research Data </h1>
                    </Col>
                    
                  
                </Row>
                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Products List</h4>
                    </Col>
                    <Button
                                        className="mb-3"
                                        color="brand-blue-dark"
                                        onClick={() => ExportCSV(props.researchProductCategoryData,'test')}  
                                    >
                                        Download
                                    </Button>
                </Row>
                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <LeadGenProductsTable data={props.productList} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>       

                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Sellers List</h4>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <SellersTable data={props.sellerList} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>       
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    sellerList : state.profile.researchProductCategoryData.sellerList, 
    productList : state.profile.researchProductCategoryData.productList,
    productCategories : state.profile.productCategories,
    leadGenJobs : state.profile.leadGenJobs,  
    researchProductCategoryData : state.profile.researchProductCategoryData,    
    status: state.profile.status,
})

export default connect(mapStateToProps)(LeadGenResearchProudctCategory);
