import React, { useState, useEffect } from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import { connect } from 'react-redux';
import SubAccountsTable from "./Components/SubAccountsTable";
import CreateSubAccountModal from "./Components/CreateSubAccountModal";
import ManageSubAccountStoresModal from "./Components/ManageSubAccountStoresModal";
import {
    AssignStoresToSubAccount,
    CreateSubAccount,
    DeleteSubAccount, GetSubAccounts, GetSubAccountStores, RemoveStoresFromSubAccount
} from "../../../../../../Service/DashboardServices/UserProfileServices/SubAccountServices/SubAccountServices";
import DeleteSubAccountModal from "./Components/DeleteSubAccountModal";


function SubAccountsPage({ token, stores, ...props }) {

    const getStoreOptionsList = (stores) => {
        return stores.map(store => ({
            value: store.integerID,
            label: store.name,
        }))
    }

    const [isCreateAccountOpen, setCreateAccountOpen] = useState(false);
    const [isManageAccountOpen, setManageAccountOpen] = useState(false);
    const [isDeleteAccountOpen, setDeleteAccountOpen] = useState(false);
    const [subAccounts, setSubAccounts] = useState([]);
    const [accountLimit, setAccountLimit] = useState(0);
    const [selectStoresOptions, setSelectStoresOptions] = useState(getStoreOptionsList(stores));
    const [selectedSubAccount, setSelectedSubAccount] = useState({});
    const [assignedStores, setAssignedStores] = useState([]);
    const [storeUpdateLoading, setStoreUpdateLoading] = useState(false);
    const [accountToDelete, setAccountToDelete] = useState({});


    useEffect(() => {
        loadSubAccounts();
    }, [])

    useEffect(() => {
        setSelectStoresOptions(getStoreOptionsList(stores));
    }, [stores]);


    const toggleCreateAccountModal = () => setCreateAccountOpen(!isCreateAccountOpen);
    const toggleManageAccountModal = () => setManageAccountOpen(!isManageAccountOpen);
    const toggleDeleteAccountModal = () => setDeleteAccountOpen(!isDeleteAccountOpen);

    const subAccountActions = [
        {
            name: "Manage Stores",
            action: (subAccount) => {
                toggleManageAccountModal();
                if (subAccount.subAccountName !== selectedSubAccount.subAccountName) {
                    setSelectedSubAccount(subAccount);
                }
                loadSubAccountStores(subAccount);
            },
        },
        {
            name: "Delete Account",
            action: (subAccount) => {
                setAccountToDelete(subAccount);
                toggleDeleteAccountModal();
            },
        },
    ];

    const createAccount = (account) => {
        CreateSubAccount(token, account).then(response => {
            if (response) setCreateAccountOpen(!isCreateAccountOpen);
        })
    }

    const deleteAccount = (subAccount) => {
        DeleteSubAccount(token, subAccount.subAccountName).then(response => {
            if (response) toggleDeleteAccountModal();
        });
    }

    const loadSubAccounts = () => {
        GetSubAccounts(token).then(response => {
            setAccountLimit(response.subAccountLimit)
            setSubAccounts(response.subAccounts)
        });
    }

    const loadSubAccountStores = (subAccount) => {
        GetSubAccountStores(token, subAccount.subAccountName).then(response => {
            setAssignedStores(response.map(store => store.integerID));
        })
    }

    const handleSubAccountStoreChange = async (storesToAssign, storesToRemove) => {
        let addStores = storesToAssign.map(store => store.value);
        let removeStores = storesToRemove.map(store => store.value);

        let isAddSuccess = false;
        let isRemoveSuccess = false;

        setStoreUpdateLoading(true);

        if (storesToAssign.length === 0) {
            isAddSuccess = true;
        } else {
            let response = await AssignStoresToSubAccount(token, addStores, selectedSubAccount.subAccountName);
            if (response) {
                isAddSuccess = true;
            }
        }

        if (storesToRemove.length === 0) {
            isRemoveSuccess = true;
        } else {
            let response = await RemoveStoresFromSubAccount(token, removeStores, selectedSubAccount.subAccountName);
            if (response) {
                isRemoveSuccess = true;
            }
        }

        if (isAddSuccess && isRemoveSuccess) {
            toggleManageAccountModal();
            setSelectedSubAccount({});
        }

        setStoreUpdateLoading(false);
    }

    return (
        <Container fluid>
            <Row className="mbg-3">
                <Col className="mbg-3" md="12">
                    <h4>Sub-Account Management</h4>
                </Col>
            </Row>
            <Row>
                <Col xl={"6"} lg={"8"} md={"12"}>
                    <Card>
                        <CardHeader>
                            Sub-Accounts
                            <Button
                                className={"ml-auto btn-icon btn-icon-right"}
                                size={"sm"}
                                color={"primary"}
                                onClick={toggleCreateAccountModal}
                            >
                                Create
                                <i
                                    className="lnr-plus-circle btn-icon-wrapper"
                                    style={{paddingBottom: '1px'}}
                                >
                                    {' '}
                                </i>
                            </Button>
                        </CardHeader>
                        <CardBody>
                            <p>(<b>{subAccounts.length}</b>{" "}out of{" "}<b>{accountLimit}</b>{" "}accounts activated)</p>
                            <SubAccountsTable data={subAccounts} quickActions={subAccountActions} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <CreateSubAccountModal
                isOpen={isCreateAccountOpen}
                toggle={toggleCreateAccountModal}
                onSubmit={createAccount}

            />
            <ManageSubAccountStoresModal
                isOpen={isManageAccountOpen}
                toggle={toggleManageAccountModal}
                allStores={selectStoresOptions}
                assignedStores={selectStoresOptions.filter(store => assignedStores.includes(store.value))}
                unAssignedStores={selectStoresOptions.filter(store => !assignedStores.includes(store.value))}
                selectedSubAccount={selectedSubAccount}
                onSubmit={handleSubAccountStoreChange}
                loading={storeUpdateLoading}
            />
            <DeleteSubAccountModal
                isOpen={isDeleteAccountOpen}
                toggle={toggleDeleteAccountModal}
                account={accountToDelete}
                onSubmit={deleteAccount}
            />
        </Container>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    stores: state.accountOverview.landing.adProfiles,
})

export default connect(mapStateToProps)(SubAccountsPage);
