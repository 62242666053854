import React, {Fragment, useEffect, useState} from "react";
import { connect } from 'react-redux';
import ProgressBar from './Components/ProgressBar';
import AdvertisingAuthorization from "./Components/AdvertisingAuthorization";
import SPAPIAuthorization from "./Components/SPAPIAuthorization";
import StoreSetup from "./Components/StoreSetup";
import GetUserRegistrationJourneyDataService from "../../../../Service/DashboardServices/RegistrationJourneyServices/GetUserRegistrationJourneyDataService"
import { ContactsOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';

function RegistrationJourneyPage(props) {
    const [progress, setProgress] = useState(0);
    const [currentPage, setCurrentPage] = useState('');
    const [storeList, setStoreList] = useState();
    const [storeCount, setStoreCount] = useState();
    const [endJourney, setEndJourney] = useState(false);
    const { profileId } = useParams();
    if(profileId)
    {
      localStorage.setItem('profileId', profileId);
    }
    else{
      localStorage.setItem('profileId', '');
    }

    useEffect(() => {
      console.log('In RegistrationJourneyPage')
      const pID = localStorage.getItem('profileId')
      GetUserRegistrationJourneyDataService(props.token,pID).then(response => {
        if (response) {
            console.log("RegistrationJourneyPage")
            console.log(response.storesList)
            if(response.storesList)
            {
              setStoreCount(response.storesList.length)
            }
            else{
              setStoreCount(0)
            }
            // setStoreCount(response.storesList.length)
            // setStoreCount(1)
            console.log(response)
            setStoreList(response.storesList)
            if(!response.AmazonAdvAuthorized)
            {
              setCurrentPage('AmazonAdvAuthorized')
              setProgress(25)              
            }else if(!response.SPAPIAuthorized)
            {
              setCurrentPage('SPAPIAuthorized')
              setProgress(50)              
            }
            // else if(response.RegJournneySelectedManagedProducts)
            // {
            //   setEndJourney(true)
            //   setCurrentPage('StoreSetup')
            //   setProgress(100)
            // }
            else if(response.RegJournneyStoreSetUpComplete)
            {
              setEndJourney(true)
              setCurrentPage('StoreSetup')
              setProgress(100)
            }else if(response.SPAPIAuthorized)
            {
              setCurrentPage('StoreSetup')
              setProgress(75)
              
            }else if(response.AmazonAdvAuthorized)
            {
              setCurrentPage('SPAPIAuthorized')
              setProgress(50)
            }
            else
            {
              setCurrentPage('AmazonAdvAuthorized')
              setProgress(25)
            }            

            
            
            


        }
    })
  }, [])


    switch (currentPage) {

      case 'AmazonAdvAuthorized':
        return (
          <div className='mt-5'>
            <ProgressBar progress={progress} endJourney={endJourney}/>
            <AdvertisingAuthorization/>

          </div>
        );
        case 'SPAPIAuthorized':
        return (
          <div className='mt-5'>
            <ProgressBar progress={progress} endJourney={endJourney}/>
            <SPAPIAuthorization storeList={storeList}  storeCount={storeCount} token={props.token}/>

          </div>
        );
        case 'StoreSetup':
        return (
          <div className='mt-5'>
            <ProgressBar progress={progress} endJourney={endJourney}/>
            <StoreSetup storeList={storeList}  storeCount={storeCount} token={props.token} endJourney={endJourney}/>

          </div>
        );
      default:
        return <div>Page Not Found</div>;
    }

}
const mapStateToProps = state => {
  return {
      token: state.loggedUser.token,
      accessLevel: state.loggedUser.accessLevel,
      status: state.loggedUser.status,
      error: state.loggedUser.error,
      newUser: state.loggedUser.newUser,
      needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
      user: state.LWA.user,
      isAuth: state.LWA.isAuth,
      enableMobileMenu: state.theme.enableMobileMenu,
      enableClosedSidebar: state.theme.enableClosedSidebar,
  }
}

export default connect(mapStateToProps)(RegistrationJourneyPage);