import React, { Fragment, useEffect } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { connect } from 'react-redux';

import notify from '../../../../../../../Notifications/ToastifyActions';
import { CURRENT_STRIPE_PK } from "../../../../../../../Utils/Constants";
import CreditPurchaseService from "../../../../../../../Service/PaymentServices/Credits/CreditPurchaseService";
import CreditPackageCards from "./CreditPackageCards";


const stripePromise = loadStripe(CURRENT_STRIPE_PK);


function CreditsPurchaseForm(props) {

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
            notify.NotifySuccess("Order placed! Your credit balance will update soon.");
        }
        if (query.get("canceled")) {
            notify.NotifyError("Your order has been canceled.");
        }
    }, []);

    const handlePurchase = async (creditPackage) => {
        const stripe = await stripePromise;
        const session = await CreditPurchaseService(props.token, creditPackage, props.userName, props.userEmail);
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            notify.NotifyError(result.error.message);
        }
    };


    return (
        <Fragment>
            <CreditPackageCards handlePurchase={handlePurchase} accessLevel={props.accessLevel} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    userName: `${state.loggedUser.user.first_name} ${state.loggedUser.user.last_name}`,
    userEmail: state.loggedUser.user.email,
})

export default connect(mapStateToProps)(CreditsPurchaseForm);