import React, { Fragment, useState } from 'react';
import {Container, Row, Col, Card, CardBody, Button} from "reactstrap";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from 'react-router-dom';

import ProductSummaryCard from "./Components/ProductSummaryCard";
import urls from '../../../../../../Utils/URLs';
import CustomBreadcrumb from "../../../../../Components/Nav/CustomBreadcrumb";
import CampaignList from "../../Components/CampaignList";
import { SetAutofillFields } from "../../../../../../Redux/Actions/Actions";
import Can from "../../../../../Components/Can";
import UpdateProductManagementService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/UpdateProductManagementService";
import ProductOverviewService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService";
import ProductAnalyticsChart from "../../Components/Charts/ProductAnalyticsChart";
import CustomWidget from "../../../../../Components/Widgets/CustomWidget";
import KeywordTable from "../../../../Components/Tables/KeywordTable";
import actionType from "../../../../../../Redux/Actions/ActionTypes";
import EditKeywordModal from "../../../../Components/Modals/EditKeywordModal";


const breadcrumbs = [
    {
        label: 'Stores',
        to: urls.ACCOUNT_OVERVIEW_MY_STORES_URL,
    },
];

function ProductOverviewPage(props) {

    let history = useHistory();
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [isKeywordOpen, setIsKeywordOpen] = useState(false);

    const updateProductManagement = async (asin, sku, update) => {
        setLoadingUpdate(true);
        const store = props.stores.find(store => store.integerID === props.selectedStore.integerID);
        await UpdateProductManagementService(props.token, asin, update, store.integerID);
        // if (response) {
        //     props.dispatch(ProductOverviewService(props.token, asin, store.integerID));
        // }
        props.dispatch(ProductOverviewService(props.token, asin, store.integerID));
        setLoadingUpdate(false);
    }

    const handleBuildCampaignRedirect = (asin, sku) => {
        const store = props.stores.find(store => store.integerID === props.selectedStore.integerID);
        props.dispatch(SetAutofillFields({
            productASIN: asin,
            productSKU: sku,
            sellerProfileId: store.integerID,
            country: store.location,
        }));
        history.push(urls.CREATE_CAMPAIGN_INFO_URL);
    }

    const handleUpgradeProductListing = () => {
        props.dispatch({
            type: actionType.SET_CURRENT_STORE,
            payload: {
                id: props.selectedStore.integerID,
            }
        });
        history.push(`${urls.CREATE_PROJECTS_URL}/${props.selectedStore.integerID}`);
    };

    const renderProductDetails = () => (
        <Fragment>
            <Row className="mb-5">
                <Col md="12" lg="10" xl="8">
                    <ProductSummaryCard
                        accessLevel={props.accessLevel}
                        productOverview={props.productOverview}
                        handleRedirect={handleBuildCampaignRedirect}
                        updateProductManagement={updateProductManagement}
                        upgradeListing={handleUpgradeProductListing}
                        loading={loadingUpdate}
                    />
                </Col>
            </Row>
            <Can
                role={props.accessLevel}
                perform={"products:view"}
                yes={() => (
                    <Fragment>
                        <h4 className="mbg-3">Simpliworks Campaign Performance</h4>
                        <Row className="mb-4">
                            <Col className="mb-3" md="12" lg="6" xl="3">
                                <CustomWidget
                                    className="card widget-content bg-happy-green text-white"
                                    heading="Sales"
                                    content={<div className="widget-numbers">${props.productOverview.productSales}</div>}
                                />
                            </Col>
                            <Col className="mb-3" md="12" lg="6" xl="3">
                                <CustomWidget
                                    className="card widget-content bg-premium-dark text-white"
                                    heading="Conversions"
                                    content={<div className="widget-numbers">{props.productOverview.productConversions}</div>}
                                />
                            </Col>
                            <Col className="mb-3" md="12" lg="6" xl="3">
                                <CustomWidget
                                    className="card widget-content bg-night-fade text-white"
                                    heading="Advertising Spend"
                                    content={<div className="widget-numbers">${props.productOverview.productCost}</div>}
                                />
                            </Col>
                            <Col className="mb-3" md="12" lg="6" xl="3">
                                <CustomWidget
                                    className="card widget-content bg-arielle-smile "
                                    heading="ROAS"
                                    content={<div className="widget-numbers">{+((props.productOverview.productSales / props.productOverview.productCost).toFixed(3)) || 0}</div>}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )}
            />
            <Can
                role={props.accessLevel}
                perform={"products:view"}
                yes={() => (
                    <Fragment>
                        <Row className="mb-5">
                            <Col className="mb-3" md="12">
                                <span>
                                    <h4 style={{display: "inline"}}>Keyword Performance</h4>
                                    <Can
                                        role={props.accessLevel}
                                        perform={"campaigns:edit"}
                                        yes={() => <Button className="mb-1 ml-2" color="link" onClick={() => setIsKeywordOpen(!isKeywordOpen)}>Manage Keywords</Button>}
                                    />
                                </span>

                            </Col>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <KeywordTable data={props.keywordTableData} productKeywords={true}/>
                                    </CardBody>
                                </Card>
                                <EditKeywordModal
                                    header={"Manage All Product Keywords"}
                                    isOpen={isKeywordOpen}
                                    toggle={() => setIsKeywordOpen(!isKeywordOpen)}
                                    productKeywords={true}
                                    keywords={props.keywordTableData}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )}
            />
            <Can
                role={props.accessLevel}
                perform={"campaigns:view"}
                yes={() => (
                    <Fragment>
                        <h4 className="mbg-3">Campaign Performance</h4>
                        <Row className="mb-5">
                            <Col md="6">
                                <ProductAnalyticsChart
                                    metricData={props.metricData}
                                    defaultMetric="sales"
                                />
                            </Col>
                            <Col md="6">
                                <ProductAnalyticsChart
                                    metricData={props.metricData}
                                    defaultMetric="conversions"
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )}
            />
            <Can
                role={props.accessLevel}
                perform={"campaigns:view"}
                yes={() => (
                    <Fragment>
                        <h4 className="mbg-3">Product Campaigns</h4>
                        <Row>
                            <Col md="12">
                                <CampaignList
                                    campaigns={props.productCampaigns}
                                    onCampaignStageUpdate={() => props.dispatch(ProductOverviewService(props.token, props.productOverview.asin, props.selectedStore.integerID))}
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )}
            />
        </Fragment>
    );

    const renderFailure = () => (
        <h5>An error occurred while retrieving your product details.</h5>
    );


    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <CustomBreadcrumb history={breadcrumbs} activeLabel="Product Overview" />
                        </div>
                        <h4 className="mbg-3">Product Overview</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <LoadingOverlay
                            active={props.loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#f1f4f6',
                                    opacity: 1
                                }),
                                content: (base) => ({
                                    ...base,
                                    color: "#000"
                                })
                            }}
                            spinner={<Loader active type="ball-pulse"/>}
                            text='Loading...'
                        >
                            { props.status === 'success' ?
                                renderProductDetails()
                                :
                                renderFailure()
                            }
                        </LoadingOverlay>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    stores: state.accountOverview.landing.adProfiles,
    productOverview: state.accountOverview.product.productOverview,
    productCampaigns: state.accountOverview.product.productCampaigns,
    loading: state.accountOverview.product.loading,
    status: state.accountOverview.product.status,
    selectedStore: state.accountOverview.store,
    metricData: state.accountOverview.product.metricData,
    keywordTableData: state.accountOverview.product.keywordTableData,
})

export default connect(mapStateToProps)(ProductOverviewPage);
