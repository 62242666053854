import React from 'react';
import SelectableWidget from "../../../../Components/Widgets/SelectableWidget";
import CustomWidget from "../../../../Components/Widgets/CustomWidget";


function SelectableParentProductWidget(props) {
    const {
        productName,
        productImage,
        numManagedChildren,
        details,
        nestedList,
    } = props;

    const subheading = () => (
        <div>
            {`${numManagedChildren} Variation${numManagedChildren === 1 ? '' : 's'} under Management`}
        </div>
    )

    return (

        <SelectableWidget
            collapsible={true}
            collapseOnUnselect={true}
            heading={productName}
            image={productImage}
            subheading={subheading()}
            details={details}
            nestedList={nestedList}
            autoFormat={true}
            toggleSelect={(isSelected) => props.toggleSelect(isSelected, details.productVariation, true)}
            // toggleSelect={() => {}}
            isSelected={props.isSelected}
            listItemKey={props.listItemKey}
        />
    )

}

export default SelectableParentProductWidget;
