import React, {Fragment, useState} from 'react';
import {Button, ButtonGroup, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import { connect } from 'react-redux';

import CartWidgetList from "./Components/Widgets/CartWidgetList";
import { RemoveFromCart } from "../../../../../../Redux/Actions/Actions";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import { useHistory } from "react-router-dom";
import BuildCampaignService from "../../../../../../Service/DashboardServices/CreateCampaignServices/BuildCampaignService";
import notify from "../../../../../../Notifications/ToastifyActions";
import urls from "../../../../../../Utils/URLs";
import {ShoppingCartOutlined} from "@ant-design/icons";


function CartOverviewPage(props) {

    let createCampaignResponse = {};
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [modalState, setModalState] = useState({
        visible: false,
        content: '',
    });

    const removeCampaign = itemId => {
        props.dispatch(RemoveFromCart([itemId]));
    }

    const handleLaunchCampaigns = async (campaigns) => {
        setLoading(true);
        createCampaignResponse = await BuildCampaignService(props.token, campaigns);

        if (!createCampaignResponse.error) {
            setModalState({
                visible: true,
                content: createCampaignResponse.message,
            });
            props.dispatch(RemoveFromCart(campaigns.map(campaign => campaign.itemId)));
        } else {
            notify.NotifyError(createCampaignResponse.message);
        }

        setLoading(false);
    }

    const returnHome = () => {
        setModalState({
            visible: false,
            content: '',
        });
        history.push(urls.ACCOUNT_OVERVIEW_MY_STORES_URL);
    }

    const renderEmptyCart = () => (
        <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50}}>
            <ShoppingCartOutlined style={{ fontSize: 35, color: 'lightgray' }} />
            <h3 style={{color: 'lightgray'}}>Empty Cart</h3>
            <Button
                color="link"
                size="lg"
                onClick={() => history.push(urls.CREATE_CAMPAIGN_INFO_URL)}
            >
                Create a Campaign {'>'}
            </Button>
        </span>
    );

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <h4 className="mbg-3">Campaign Cart</h4>
                        { props.cart.length === 0 ?
                            renderEmptyCart()
                            :
                            <CartWidgetList
                                data={props.cart}
                                launchCampaign={handleLaunchCampaigns}
                                removeCampaign={removeCampaign}
                                loading={loading}
                                card={true}
                            />
                        }
                    </Col>
                </Row>
                { props.cart.length > 1 &&
                    <Row>
                        <Col className="mt-3" md="12">
                            <div className="float-right font-weight-bold" style={{display: 'flex', alignItems: 'baseline'}}>
                                <ButtonGroup className="ml-5" size="lg">
                                    <LoadingButton
                                        className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
                                        color="success"
                                        size="lg"
                                        disabled={loading}
                                        loading={loading}
                                        onClick={() => handleLaunchCampaigns(props.cart)}
                                    >
                                        Build All Campaigns
                                    </LoadingButton>
                                </ButtonGroup>
                            </div>
                        </Col>
                    </Row>
                }
                <Modal isOpen={modalState.visible}>
                    <ModalHeader closeButton={false}>{`Thank you, ${props.user.first_name}.`}</ModalHeader>
                    <ModalBody>
                        {modalState.content}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={returnHome}>View My Campaigns</Button>{' '}
                    </ModalFooter>
                </Modal>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    cart: state.cart,
    token: state.loggedUser.token,
    user: state.loggedUser.user,
})

export default connect(mapStateToProps)(CartOverviewPage);