
import { Container, Row, Col, Card, CardBody, Button, Label, Input, CardTitle } from 'reactstrap';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import { models } from "powerbi-client";
import TimeFilteredDataDisplay from './TimeFilteredDataDisplay';
import SalesDataTable from './SalesDataTable';
import React, {Fragment,useState,useEffect} from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'; 
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { SalesDataService } from '../../../../../../../Service/OPSServices/SalesDataService';
import { LatestCampaignService } from '../../../../../../../Service/OPSServices/LatestCampaignService';
import { GetAdvertisingReportService } from '../../../../../../../Service/OPSServices/GetAdvertisingReportService';


function StoreProductDataTable(props) {
    const [loading, setLoading] = useState(false);
    const [isJsonOpen, setIsJsonOpen] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenNew, setIsOpenNew] = useState(false);
    const [summary, setSummary] = useState();

    const [saleSummary, setSaleSummary] = useState();
    const [asinSalesDataList,setAsinSalesDataList] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const viewLatestCampaign = (asin) => {
        setLoading(true);        

        let data = {
            id : asin,
            startDate:props.startDate,
            endDate:props.endDate
        }
        
        LatestCampaignService(props.token, data).then(response => {
            if (response) {
                //console.log(response['asinSalesDataList']);
                setAsinSalesDataList(response);
                setSaleSummary(<SalesDataTable asin={asin} data ={response}/>);
                setIsOpenNew(true);
               
            }
        })

        setLoading(false);
    }
    const viewSalesData = (asin) => {
        setLoading(true);        

        let data = {
                        id : asin,
                        startDate:props.startDate,
                        endDate:props.endDate
                    }
    
        SalesDataService(props.token, data).then(response => {
            if (response) {

                // console.log("response.sales")
                // console.log(response.asinData.sales)
                // setAsinSalesDataList(response);
                setSummary(<TimeFilteredDataDisplay asin={asin} data ={response}/>);
                setIsOpen(true);
               
            }
        })

        setLoading(false);
    }

      const columns1 = [

        {
            Header: "Image",
            accessor: 'itemImage',
            Cell: (row) => {
                return  <img src={row.value} className="img-fluid" />
              }
        },        
        {
            Header: 'Asin',
            accessor: 'asin',
        },
        {
            Header: 'Title',
            accessor: 'title',
        },       
        {
            Header: "Managed",
            accessor: 'isManaged',
            Cell: (row) => {
                return <p style={{ paddingLeft: '50%'}}>{row.value}</p>;
              }
           
        },
        {
            Header: "Latest Campaigns",
            accessor: 'asin',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    className="m-auto"
                    onClick={() => {
                        // printTheJSONInPrettyFormat(cellInfo.value) 
                        viewLatestCampaign(cellInfo.value)
                        // salesData(cellInfo.value)  
                        setIsJsonOpen(!isJsonOpen)                         
                    }}
                >
                    View 
                </Button>
            )
           
        },
        {
            Header: "Sales Data",
            accessor: 'asin',
            Cell: cellInfo => (               
                <Button
                    color={"link"}
                    className="m-auto"
                    onClick={() => {
                        
                        viewSalesData(cellInfo.value)                      
                        setIsJsonOpen(!isJsonOpen)
                       
                    }}
                >
                    View 
                </Button>
            )
           
        },
        {
            Header: "Amazon Link",
            accessor: 'amazonLink',       
            Cell: cellInfo => <a
            className="m-auto"
            href={cellInfo.value}
            target="_blank"
            rel="noopener noreferrer"
        >
            View
        </a>
            

           
           
        },
             
    ];
  

    return (
        <Fragment>
            <Row>
                    <Col>
                        <CardTitle className="pt-3 pl-3">Store Products</CardTitle>
                        <Card>
                            <h4 className="text-center">{props.profileName }</h4>
                        </Card>
                    </Col>
            </Row>
        <ReactTable
            data={props.productList}
            columns={columns1}
            defaultPageSize={5}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped" 
              
         
            />
        
            &nbsp;                               
            <CustomModal
                header={props.profileName }
                isOpen={isOpenNew}
                className="salesPopup"
                toggle={() => setIsOpenNew(!isOpenNew)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpenNew(!isOpenNew)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{saleSummary}</pre> </p></Label>                
            </CustomModal>
            <CustomModal
                header={props.profileName}
                isOpen={isOpen}
                className="salesPopup"
                toggle={() => setIsOpen(!isOpen)}
                size={"md"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
            
            </Fragment>
    );
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps) (StoreProductDataTable);