
import { Container, Row, Col, Card, CardBody, Button, Label, Input, CardTitle } from 'reactstrap';
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import LoadingButton from "../../../../../../Components/Buttons/LoadingButton";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import PowerbiEmbed from './PowerbiEmbed';
import SalesDataTable from './SalesDataTable';
import React, {Fragment,useState,useEffect} from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx'; 
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { SalesDataService } from '../../../../../../../Service/OPSServices/SalesDataService';
import { GetAdvertisingReportService } from '../../../../../../../Service/OPSServices/GetAdvertisingReportService';


function AllReportsListDataTable(props) {
    const [loading, setLoading] = useState(false);
    const [jsonData, setJsonData] = useState();
    const [isJsonOpen, setIsJsonOpen] = useState();
    const [parsedJsonData, setParsedJsonData] = useState();
    const [Th_Data, setThData] = useState();
    const [td_Data, settdData] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenNew, setIsOpenNew] = useState(false);
    const [summary, setSummary] = useState();
    const [Id, setId] = useState();
    const [Name, setName] = useState();
    const [saleData, setSaleData] = useState();
    const [saleSummary, setSaleSummary] = useState();
    const [asinSalesDataList,setAsinSalesDataList] = useState();

  
    
    const getAdvertisingData = (pbiurl,profileId) => {
        
        console.log('In Grid Component Button Click')
        console.log(props.pbiurl)
        // alert(props.pbiurl)
        // alert(props.profileId)
        // alert(props.profileName)
       
        setId(props.profileId)
        setName(props.profileName)
        console.log('Before Pop up call.')
        console.log(profileId)

        // let profileId  = props.profileId['id']
        // let profileName = props.profileId['name']
        // alert(pbiurl)
        setSummary(<PowerbiEmbed responseURL = {pbiurl} profileId={profileId}/>);
        setIsOpen(true);
    }
    const getWeeklyStoreReport = () => {
        
        setId(props.profileId)
        setName(props.profileName)
    
        setSummary(<PowerbiEmbed responseURL = {props.weeklyStoreReport} profileId={props.profileId}/>);
        setIsOpen(true);
    }

    // const viewSalesData = (asin) => {
    //     setLoading(true);        

    //     let data = 
    //     {id : asin}
        
    //     SalesDataService(props.token, data).then(response => {
    //         if (response) {

    //             // console.log("surya");
    //             //console.log(response['asinSalesDataList']);
    //             setAsinSalesDataList(response);
    //             setSaleSummary(<SalesDataTable asin={asin} data ={response}/>);
    //             setIsOpenNew(true);
               
    //         }
    //     })

    //     setLoading(false);
    // }


    const printTheJSONInPrettyFormat = (ReportName,ProfileId) => {
    
        let data = 
        {ReportName : ReportName,
         ProfileId : ProfileId,
         startDate:props.startDate,
         endDate:props.endDate}
        GetAdvertisingReportService(props.token, data).then(response => {
            if (response) {
      var parseJSON = JSON.parse(response);

      var JSONInPrettyFormat = JSON.stringify(parseJSON, undefined, 4);
      setParsedJsonData(parseJSON);
      setJsonData(JSONInPrettyFormat);

       // get table column
            const column = Object.keys(parseJSON[0]);
            // get table heading data
            const ThData =()=>{
                
                return column.map((data)=>{
                    // alert(<th key={data}>{data}</th>)
                    return <th key={data}>{data}</th>
                })
            }
            // get table row data
            const tdData =() =>{
            
                return parseJSON.map((data)=>{
                return(
                    <tr>
                            {
                            column.map((v)=>{
                                return <td>{data[v]}</td>
                            })
                            }
                    </tr>
                )
                })
            }

            setThData(ThData())
            settdData(tdData())
        }
    })
                // end table
                };

    const downloadReport = (ReportName,ProfileId, fileName) => {
     
        let data = 
        {ReportName : ReportName,
         ProfileId : ProfileId,
         startDate:props.startDate,
         endDate:props.endDate
        }
        GetAdvertisingReportService(props.token, data).then(response => {
            if (response) {
  
                    var parseJSON = JSON.parse(response);
                
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    console.log('Download Called')
                    const exportToCSV = (csvData, fileName) => {
                        console.log(csvData)
                        console.log(fileName)
                        const ws = XLSX.utils.json_to_sheet(csvData);              
                        
                        const wb = { Sheets: {'Report':ws }, SheetNames: ['Report'] };            
                            
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const data = new Blob([excelBuffer], {type: fileType});
                        console.log('before save')
                        console.log(data)
                        FileSaver.saveAs(data, fileName + fileExtension);   
                         
          };
  
      exportToCSV(parseJSON, fileName);
    }
})

}


    //   const columns1 = [
                

    //     {
    //         Header: 'Asin',
    //         accessor: 'asin',
    //     },
    //     {
    //         Header: 'Title',
    //         accessor: 'title',
    //     },       
    //     {
    //         Header: "Managed",
    //         accessor: 'isManaged',
           
    //     },
    //     {
    //         Header: "Sales Data",
    //         accessor: 'asin',
    //         Cell: cellInfo => (               
    //             <Button
    //                 color={"link"}
    //                 onClick={() => {
    //                     // printTheJSONInPrettyFormat(cellInfo.value) 
    //                     viewSalesData(cellInfo.value)
    //                     // salesData(cellInfo.value)  
    //                     setIsJsonOpen(!isJsonOpen)
                         
    //                     // viewSalesData()
                                              
    //                 }}
    //             >
    //                 View 
    //             </Button>
    //         )
           
    //     },
    //     {
    //         Header: "Amazon Link",
    //         accessor: 'amazonLink',       
    //         Cell: cellInfo => <a
    //         className="mt-1"
    //         href={cellInfo.value}
    //         target="_blank"
    //         rel="noopener noreferrer"
    //     >
    //         View
    //     </a>
            

           
           
    //     },
             
    // ];
  




    

    const columns = [
                

        {
            Header: 'Report Name',
            accessor: 'ReportName',
        },        
        {
            Header: 'Data',
            accessor: 'ReportName',
            Cell: props => (               
                <Button
                    color={"link"}
                    className="m-auto"
                    onClick={() => {
                        printTheJSONInPrettyFormat(props.value,props.original.ProfileId)   
                        setIsJsonOpen(!isJsonOpen)                       
                    }}
                >
                    View 
                </Button>
            )
        },
        {
            Header: 'Report ',
            accessor: 'pbiurl',
            Cell: (props) => (
                <Button
                  color={"link"}
                  className="m-auto"
                  onClick={() => {
                    console.log(props.original.ProfileId)
                    getAdvertisingData(props.original.pbiurl,props.original.ProfileId)
                  }}
                >
                  View 
                </Button>
              ),
        },
        {
            Header: "Report Data",
            accessor: "ReportName",
            Cell: (props) => (
              <Button
                color={"link"}
                className="m-auto"
                onClick={() => {
                  downloadReport(props.value,props.original.ProfileId,props.value)
                }}
              >
                Download 
              </Button>
            ),
          },

             
    ];

    return (
        <Fragment>
           
        <Row>
                    <Col>
                        <CardTitle className="pt-3 pl-3">Advertising Reports
                        <LoadingButton
                                    className="mt-3 ml-2"
                                    color="brand-blue-dark"
                                    onClick={getWeeklyStoreReport}
                                >
                                    Weekly Store Report
                                </LoadingButton>
                        </CardTitle>
                        <Card>
                            <h4 className="text-center">{props.profileName }</h4>
                        </Card>
                    </Col>
        </Row>

        <ReactTable
            data={props.data}
            columns={columns}
            defaultPageSize={10}
            noDataText={"No Data Found."}
            style={{height: "500px"}}
            className="-fixed -highlight -striped" 
              
         
            />


             <CustomModal
                header={props.profileName }
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
            &nbsp;                               
            {/* <CustomModal
                header={props.profileName }
                isOpen={isOpenNew}
                className="salesPopup"
                toggle={() => setIsOpenNew(!isOpenNew)}
                size={"xl"}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpenNew(!isOpenNew)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{saleSummary}</pre> </p></Label>                
            </CustomModal> */}
            {/* <Row>
                    <Col>                       
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    </Col>
            </Row> */}
            &nbsp;
            <Row className="">
                    <Col>                       
                        <Card>
                <div className="container-fluid mt-3  ">
                    <div className="row">
                        <div className="col-12">
                            <pre style={{ whiteSpace: "pre-wrap", height: "500px", overflowY:"auto"}}>
                                <h4 className="p-3">All Reports</h4>
                                <table id="table_id" className="table table-striped  table-bordered" >
                                    <thead>
                                        <tr>{Th_Data}</tr>
                                    </thead>
                                    <tbody>
                                        {td_Data}
                                    </tbody>
                                </table>
                            </pre>
                        </div>
                    </div>
                </div>
        
                        </Card>
                </Col>
            </Row>
            {/* <Row >
                    <Col >
                        <Card className="main-card mb-3">
                            <CardBody>
                            <CardTitle>Report Section</CardTitle>
                                        
                        <Card>
                        <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                       
                        </Card>
                    
                    </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </Fragment>
    );
}
const mapStateToProps = state => ({
    token: state.loggedUser.token,
})

export default connect(mapStateToProps) (AllReportsListDataTable);