import React, { Fragment } from 'react';
import CampaignWidget from "./CampaignWidget";
import WidgetListCard from "../../../../Components/Widgets/WidgetListCard";


function CampaignWidgetList(props) {
    const widgetsData = props.data.map(campaign => {
        const { asin, productSKU, dailyBudget, collectionID, name, country, state, type, campaignStage, campaignID } = campaign;

        const details = {
            productASIN: asin,
            dailyBudget: `$${dailyBudget}`,
            marketplace: country,
            campaignStage: campaignStage === 'swarm' ? 'Swarm'
                : campaignStage === 'explore' ? 'Explore'
                : campaignStage === 'establish' ? 'Establish'
                : campaignStage === 'winning' ? 'Winning'
                : 'Unavailable',
            // productTargeting: type === 'both' ? 'Enabled' : 'Disabled',
            status: state === 'ready' ? 'Ready to Launch' : state === 'enabled' ? 'Active' : state,
        };

        return { name, asin, campaignID, collectionID, state, details };
    });

    const renderCampaignWidget = (campaign) => (
        <CampaignWidget
            {...campaign}
            handleRedirect={props.handleRedirect}
            handleLaunch={props.handleLaunch}
            handleQuickActions={props.handleQuickActions}
        />
    )

    return (
        <Fragment>
            <WidgetListCard
                widgets={widgetsData}
                renderWidget={renderCampaignWidget}
                listHeading={props.listHeading}
                card={props.card}
            />
            {widgetsData.length === 0 &&
                <h5 className="m-3">No campaigns found.</h5>
            }
        </Fragment>
    )
}

export default CampaignWidgetList;
