import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Slider } from 'react-burgers';
import { setEnableClosedSidebar } from "../../../../Redux/Actions/Actions";
// import New_LOGO from '../../../../../src/assets/utils/images/New_LOGO.png'
import New_LOGO from '../../../../../src/assets/utils/images/New_LOGO.svg'
import { Link } from 'react-router-dom';
import urls from '../../../../Utils/URLs';

function HeaderLogo(props) {

    const [active, setActive] = useState(false);

    const toggleEnableClosedSidebar = () => {
        props.dispatch(setEnableClosedSidebar(!props.enableClosedSidebar));
    }
    const backToHome = () =>{
        window.location.reload()
    }
    return (
        <Fragment>
            <div className="app-header__logo text-center" style={{background: 'none'}}>
                {/* <span style={{backgroundColor: "#dcebf7", borderRadius: "0.5rem", marginTop: "0.5rem", marginBottom: "0.5rem"}}> */}
                <span style={{color: '#000', fontWeight:'900', marginTop: "0.5rem", marginBottom: "0.5rem"}} onClick={()=>backToHome()}>
                    {/* <div className="logo-src"/> */}
                    {/* <h1>Simpliworks</h1> */}
                    <Link to={urls.STORE_OVERVIEW_MY_STORES_URL}>
                        <img src={New_LOGO} width={200} height={50} alt="Logo" />
                    </Link>
                    {/* <img src={New_LOGO} width={200} height={50}/> */}
                </span>
                {/* <div className="header__pane ml-auto">
                    <div onClick={toggleEnableClosedSidebar}>
                        <Slider width={26} lineHeight={1} lineSpacing={5} color="#dcebf7"
                                active={active} onClick={() => setActive(!active)}/>
                    </div>
                </div> */}
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
});

export default connect(mapStateToProps)(HeaderLogo);
