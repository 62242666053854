import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import {LoadRegistrastionJournyData} from "../../../Redux/Actions/Actions";
import notify from '../../../Notifications/ToastifyActions';

function CalculateExistingTicketTimeService(token) {
    return axios.post(`${CURRENT_API_URL}/swhelpdesk/calculateExistingTicketTime`,'',{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        notify.NotifySuccess("Calculating existing ticket time successfully.");
        return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while calculating existing ticket time. please try again later.");
        return false;
    });
}

export default CalculateExistingTicketTimeService
;