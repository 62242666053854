import React from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';


/**
 * Wrapper for the Reactstrap Modal component. Children components of CustomModal are displayed within
 * the ModalBody component.
 * @param isOpen - whether the modal is open/visible or closed/hidden
 * @param toggle - function that closes the modal if it is open, or opens the modal if it is closed
 * @param className - className to pass along to the Reactstrap Modal component
 * @param header - the contents of the ModalHeader component
 * @param footer - optional. The contents of the ModalFooter component
 * @param props - the props to pass down to the Modal component
 */
function CustomModal({ isOpen, toggle, className, header, footer, ...props }) {

    return (
        <Modal {...props} isOpen={isOpen} toggle={toggle} className={className}>
            <ModalHeader toggle={toggle}>{header}</ModalHeader>
            <ModalBody>{props.children}</ModalBody>
            { footer &&
                <ModalFooter>{footer}</ModalFooter>
            }
        </Modal>
    );
}

export default CustomModal;
