import React, { useState } from 'react';
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import {Button, Input, InputGroup, Label} from "reactstrap";


function CreateSubAccountModal({ toggle, isOpen, onSubmit }) {

    const [newAccount, setNewAccount] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        subAccountName: "",
    });

    const handleChange = event => {
        const { id, value } = event.target;
        setNewAccount(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const isInvalid = () => {
        for (let key in newAccount) {
            if (newAccount[key] === "")
                return true;
        }

        return (newAccount.password !== newAccount.confirmPassword);
    }

    return (
        <CustomModal
            header="Create New Sub-Account"
            toggle={toggle}
            isOpen={isOpen}
            size={"md"}
            footer={
                <Button color={"success"} onClick={() => onSubmit(newAccount)} disabled={isInvalid()}>Create</Button>
            }
        >
            <Label for="firstName">First Name</Label>
            <Input id="firstName" type="text" onChange={handleChange} />

            <Label className="mt-3" for="lastName">Last Name</Label>
            <Input id="lastName" type="text" onChange={handleChange} />

            <Label className="mt-3" for="email">Email</Label>
            <Input id="email" type="email" onChange={handleChange} />

            <Label className="mt-3" for="password">Password</Label>
            <Input id="password" type="password" onChange={handleChange} />

            <Label className="mt-3" for="confirmPassword">Confirm Password</Label>
            <Input id="confirmPassword" type="password" onChange={handleChange} />

            <Label className="mt-3" for="subAccountName">Sub-Account Name (Unique to each sub-account)</Label>
            <Input id="subAccountName" type="text" onChange={handleChange} />
        </CustomModal>
    )
}

export default CreateSubAccountModal;
