import React, { Fragment, useEffect } from 'react';
import { useLocation, useHistory, Link } from "react-router-dom";
import URLs from '../../../../../Utils/URLs';
import { Button } from 'reactstrap'

const AgencyProjectViewThankyou = () => {

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        console.log(location.state);
    }, [location])
    return (
        <Fragment>
            <div className="jumbotron text-center">
                <h4 className="display-5 green">Project Details Submitted Successfully!</h4>
                <p className="lead">{location.state && location.state.userType ? location.state.userType : '' } will review the details and get back to you</p>
                <hr />
                <p>
                    Having trouble? <a href="">Contact us</a>
                </p>
                <p className="lead" >
                    {location.state && location.state.backurl ?
                        <span>
                            Go to: <Button tag={Link} type="button" color="primary" sm to={location.state.backurl}>View Projects</Button>
                        </span>
                        :
                        <span>
                            Go to: <Button tag={Link} type="button" color="primary" sm to={URLs.BASE_PROJECTS_URL}>View Projects</Button>
                        </span>
                    }

                </p>
            </div>
        </Fragment>
    )
}

export default AgencyProjectViewThankyou;
