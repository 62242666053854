import React, { Fragment } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';


function Timeline(props) {

    return (
        <div className={"scroll-area"} style={props.style ? props.style : {}}>
            <PerfectScrollbar>
                {props.data.length === 0 ?
                    <h5>No data found.</h5>
                    :
                    <VerticalTimeline layout="1-column">
                        {
                            props.data.map(group => (
                                <Fragment>
                                    <h5 style={{marginLeft: "80px", marginBottom: "2rem", fontSize: "2rem"}}>{new Date(group.date).toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'})}</h5>
                                    {
                                        group.items.map(item => (
                                            <VerticalTimelineElement
                                                className="vertical-timeline-item"
                                                icon={
                                                    <i className={`badge badge-dot badge-dot-xl badge-${item.color ? item.color : "success"}`}>  {" "} </i>
                                                }
                                                date={item.time}
                                            >
                                                {item.header && <h4 className="timeline-title">{item.header}</h4>}
                                                {item.body}
                                            </VerticalTimelineElement>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                    </VerticalTimeline>
                }
            </PerfectScrollbar>
        </div>
    )
}

export default Timeline;