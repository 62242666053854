import React, { Fragment, useEffect, useState } from 'react';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { toast } from 'react-toastify';
import URLs from '../../../../../../Utils/URLs';
import {
    Card, CardBody, Row, Col, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { Link, useHistory } from "react-router-dom";

import AmazonProjectViewComments from '../AmazonProjectViewComments/AmazonProjectViewComments';
import CommentsOnPRoject from '../AmazonProjectViewComments/CommentsOnProject';
import AmazonGalleryEdit from './AmazonGalleryEdit';
import AmazonTitleAndFeaturesEdit from './AmazonTitleAndFeaturesEdit';
import AmazonProductDescriptionEdit from './AmazonProductDescriptionEdit';
import AmazonBackendKeywordsEdit from './AmazonBackendKeywordsEdit';
import AmazonSubjectMattersEdit from './AmazonSubjectMattersEdit';
import AmazonAPlusContentEdit from './AmazonAPlusContentEdit';
// import AmazonPDFFileEdit from './AmazonPDFFileEdit';
import { getComments, postComments, projectFieldsUpdateOrCreate, projectFilesUpdateOrCreate, deleteProjectField, changeProjectStatus } from '../../../../../../Service/DashboardServices/Projects/ProjectsService';
import AmazonTextField from '../AmazonProjectEditComponents/AmazonTextField';
import AmazonQuillField from '../AmazonProjectEditComponents/AmazonQuillField';
import AmazonUploadField from '../AmazonProjectEditComponents/AmazonUploadField';
import AiFilesEdit from '../AmazonProjectPreview/AiFilesEdit';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Can, {check} from "../../../../../Components/Can";


const AmazonProjectPreview = ({ token, accessLevel, id, storeId, data, refreshData }) => {

    const history = useHistory();
    const [projectData, setProjectData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submittedData, setSubmittedData] = useState({
        galleryPhotos: [],
        title: null,
        features: [],
        productDescription: null,
        backendKeywords: null,
        subjectMatters: [],
        linkToAsset: null,
        aiFiles: [],
        pdfFile: null,
        isFinalCompleted: false
    });
    const [modal, setModal] = useState(false);

    const [fieldData, setFieldData] = useState(null);
    const [modalTitle, setModalTitle] = useState('');

    const toggle = () => setModal(!modal);
    const [fieldLevelComments, setFieldLevelComments] = useState([]);
    const [projectComments, setProjectComments] = useState([])

    const [textModal, setTextModal] = useState(false);
    const [fieldProps, setFieldProps] = useState(null);
    const toggleTextModal = () => setTextModal(!textModal);

    const [quillModal, setQuillModal] = useState(false);

    const toggleQuillModal = () => setQuillModal(!quillModal);

    const [modalUpload, setModalUpload] = useState(false);
    const toggleUpload = () => setModalUpload(!modalUpload);
    const [uploadFieldProps, setUploadFieldProps] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false)
    const toggleConfirmModal = () => setConfirmModal(!confirmModal);
    const [isRevisedStatus, setIsRevisedStatus] = useState(false);
    const [disableApproveBtn, setDisableApproveBtn] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);


    const showAlert = (msg, type) => {
        if (type === 'error') {
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        else if (type === 'success') {
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const fieldNamesEnum = {
        'galleryPhotos': 'Gallery Photo',
        'title': 'Title',
        'feature1': 'Feature 1',
        'feature2': 'Feature 2',
        'feature3': 'Feature 3',
        'feature4': 'Feature 4',
        'feature5': 'Feature 5',
        'productDescription': 'Product Description',
        'backendKeywords': 'Backend Keywords',
        'subjectMatter1': 'Subject Matter 1',
        'subjectMatter2': 'Subject Matter 2',
        'subjectMatter3': 'Subject Matter 3',
        'subjectMatter4': 'Subject Matter 4',
        'subjectMatter5': 'Subject Matter 5',
        'linkToAsset': 'Google Drive or Dropbox links',
        'aiFiles': 'AI File',
        'pdfFile': 'PDF File'
    }

    const openComments = async (fieldData, imageIndex, isRevised = false) => {
        setFieldData(fieldData)
        setIsRevisedStatus(isRevised)
        if (imageIndex >= 0) {
            setModalTitle(`${fieldNamesEnum[fieldData.field_name]} - ${imageIndex + 1}`)
        }
        else {
            setModalTitle(fieldNamesEnum[fieldData.field_name])
        }

        try {
            await getFieldLevelComments(fieldData);
            setModal(true);
        } catch (e) {
            console.log(e.message);
        }
    }

    const openTextField = async (fieldProps, modalTitle) => {
        setFieldProps(fieldProps)
        if (fieldProps.fieldData) {
            try {
                await getFieldLevelComments(fieldData);

            } catch (e) {
                console.log(e.message);
            }
        }
        setTextModal(true);
        setModalTitle(modalTitle)

    }

    const openQuillField = async (fieldData) => {
        setFieldData(fieldData)
        setQuillModal(true);
        if (fieldData) {
            try {
                await getFieldLevelComments(fieldData);
            } catch (e) {
                console.log(e.message);
            }
        }

    }

    const openFileUpload = async (uploadField, title) => {
        setUploadFieldProps(uploadField)
        setModalTitle(title);
        toggleUpload();
        if (uploadField.fieldData) {
            try {
                await getFieldLevelComments(uploadField.fieldData);
            } catch (e) {
                console.log(e.message);
            }
        }
    }

    const onSubmit = async (payload) => {
        payload = { ...payload, project_id: id }
        try {
            setLoading(true);
            let result = await projectFieldsUpdateOrCreate(payload, token)
            showAlert(result.msg, 'success')
            setLoading(false);
            refreshData();
        } catch (e) {
            setLoading(false);
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }
        console.log(payload);
    }

    const onUploadFile = async (payload) => {
        payload = { ...payload, project_id: id }
        try {
            setLoading(true);
            let result = await projectFilesUpdateOrCreate(payload, token)
            showAlert(result.msg, 'success')
            setLoading(false);
            refreshData();
        } catch (e) {
            setLoading(false);
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }
    }

    const onFileError = (msg) => {
        showAlert(msg, 'error');
    }

    const getFieldLevelComments = async (fieldData) => {

        try {
            let payload = {
                projectId: 0,
                projectDetailsId: fieldData.id
            }
            setLoading(true);
            let comments = await getComments(payload, token);
            setFieldLevelComments([...comments]);
            setLoading(false);

        } catch (e) {
            setLoading(false);
            console.log(e.message);
        }
    }



    useEffect(() => {
        //setLoading(true)
        convertObj(data)
        setLoading(false)

    }, [data])


    const convertObj = (data) => {
        let data_ = {};
        let submittedData__ = submittedData;
        setFilterOptions([]);
        let tempFilterOptions = [];
        if (data && data.amazonproject && data.amazonproject.length > 0) {

            data.amazonproject.forEach(d => {
                let tempFilter = {

                }
                let aiFileIndex = 1
                if (d.section === 'GALLERY_PHOTOS') {
                    if (data_.galleryPhotos) data_.galleryPhotos.push(d.field_value)
                    else data_.galleryPhotos = [d.field_value]

                    submittedData__.galleryPhotos.push(d)
                    tempFilter = {
                        ...tempFilter,
                        id: d.id,
                        fieldName: `Gallery Photos ${d.field_sequence}`,
                        section: 'GALLERY_PHOTOS'
                    }
                }
                else if (d.field_name === 'aiFiles') {
                    if (data_.aiFiles) data_.aiFiles.push(d.field_value)
                    else data_.aiFiles = [d.field_value]
                    submittedData__.aiFiles.push(d)
                    tempFilter = {
                        ...tempFilter,
                        id: d.id,
                        fieldName: `AI File ${aiFileIndex}`,
                        section: 'A_PLUS_CONTENT'
                    }
                    aiFileIndex++;
                }
                else {
                    data_[d.field_name] = d.field_value

                    //submittedData__[d.field_name] = d
                    switch (d.section) {
                        case 'TITLE_AND_FEATURES':
                            if (d.field_name === 'title') {
                                submittedData__.title = d
                            }
                            else {
                                submittedData__.features.push(d)
                            }

                            break;
                        case 'BACKEND_KEYWORDS':
                            submittedData__.backendKeywords = d;
                            break;
                        case 'PRODUCT_DESCRIPTION':
                            submittedData__.productDescription = d;
                            break;
                        case 'SUBJECT_MATTERS':
                            submittedData__.subjectMatters.push(d);
                            break;
                        case 'A_PLUS_CONTENT':
                            if (d.field_name === 'linkToAsset') {
                                submittedData__.linkToAsset = d
                            }
                            else if (d.field_value)
                            submittedData__.pdfFile = d
                            break;
                        case 'PDF_FILE':
                            if (d.field_value)
                                submittedData__.pdfFile = d
                    }

                    tempFilter = {
                        ...tempFilter,
                        id: d.id,
                        fieldName: fieldNamesEnum[d.field_name],
                        section: d.section
                    }
                }

                tempFilterOptions.push(tempFilter)


            })

            let tempStatus = data.amazonproject.some(p => p.status === 'REVISED');
            setDisableApproveBtn(tempStatus);
        }

        // if (data && (data.projectStatus === 'completed' || (accessLevel === 0 && data.projectStatus === 'ready') || (accessLevel === 3 && data.projectStatus === 'inProgress'))) {
        //     submittedData__.isFinalCompleted = true;
        // }
        if (data && (data.projectStatus === 'completed' || (check(accessLevel, "project:edit") && data.projectStatus === 'ready') || (check(accessLevel, "project:review") && data.projectStatus === 'inProgress'))) {
            submittedData__.isFinalCompleted = true;
        }

        if (data) {
            getProjectComments(data.id)
        }

        setProjectData(data)
        setFilterOptions(tempFilterOptions.sort((a, b) => (a.id - b.id)));
        setSubmittedData({ ...submittedData__ })
    }

    const getProjectComments = async (id) => {

        try {
            let payload = {
                projectId: id,
                projectDetailsId: 0
            }

            const result = await getComments(payload, token)
            setProjectComments(result);
        } catch (e) {
            console.log(e.message)
        }



    }

    const deleteField = async (fieldId) => {
        try {
            setLoading(true);
            const result = await deleteProjectField(fieldId, token)
            showAlert(result.msg, 'success')
            setLoading(false);
            refreshData();
        } catch (e) {
            setLoading(false);
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }
    }

    const submitComments = async (comments, isRevised) => {
        try {
            if (!isRevised) {
                let payload = {
                    projectId: id,
                    projectDetailsId: fieldData.id,
                    comments: comments,
                    fieldName: modalTitle
                }
                setLoading(true);
                let result = await postComments(payload, token);
                setLoading(false);
                showAlert(result.message, 'success');
                await getFieldLevelComments(fieldData);
                await getProjectComments(id)

                setModal(false);
            }

            else {
                let payload = {
                    project_id: id,
                    id: fieldData.id,
                    isApprovedOrRevise: 'REVISED',
                    comments: comments,
                    fieldName: modalTitle
                }
                let url = 'onboarding/projectFieldApproveOrRevise';
                statusChangAPI(payload, url);
            }


        } catch (e) {
            setLoading(false);
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }

    }

    const submitStatusChange = async (status) => {
        try {
            let payload = {
                project_id: id,
                isApprovedOrRevise: status
            }
            submitStatusWithConfirmBox({ title: 'Confirm to Proceed', message: 'Are you sure that you want to proceed?' }, payload, 'onboarding/projectStatusChange', true)

        } catch (e) {

        }
    }

    const statusChangAPI = async (payload, url, isStatusChange = false) => {
        try {
            setLoading(true);
            const result = await changeProjectStatus(payload, url, token);
            setLoading(false);
            let userType = payload.isApprovedOrRevise && payload.isApprovedOrRevise === 'inProgress' ? 'Simpliworks' :
                payload.isApprovedOrRevise && payload.isApprovedOrRevise === 'ready' ? 'Customer' : '';

            showAlert(result.message, 'success');
            if (isStatusChange)
                history.push({
                    pathname: '/project/thankyou',
                    state: { projectId: id, backurl: `/project/${storeId}`, userType: userType }
                });
            refreshData()
            await getProjectComments(id)
        } catch (e) {
            console.log(e)
            setLoading(false);
            if (e.response) {
                if (e.response.status === 400) {
                    let errMsg = [];
                    Object.keys(e.response.data).forEach(key => {
                        let str = `${key} is required or invalid`;
                        errMsg.push(str)
                    })

                    showAlert(errMsg.join(','), 'error');
                }
                else {
                    showAlert(e.response.data.message, 'error')
                }
            }
        }
    }

    const approveOrReviseProject = async (fieldData, status, section) => {

        let payload = null
        let url = ''
        if (!fieldData && section) {
            payload = {
                project_id: id,
                approved: status,
                section: section
            }
            url = 'onboarding/projectFieldsSectionApprove';
            submitStatusWithConfirmBox({ title: 'Confirm to Proceed', message: 'Are you sure that you want to proceed?' }, payload, url);
        }
        else if (fieldData && !section) {
            payload = {
                id: fieldData.id,
                project_id: id,
                isApprovedOrRevise: status
            }
            url = 'onboarding/projectFieldApproveOrRevise';
            submitStatusWithConfirmBox({ title: 'Confirm to Proceed', message: 'Are you sure that you want to proceed?' }, payload, url);
        }
    }



    const submitStatusWithConfirmBox = ({ title, message }, payload, apiUrl, isStatusChange = false) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => statusChangAPI(payload, apiUrl, isStatusChange)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const finalApproval = () => {
        try {
            let payload = {
                project_id: id
            }
            let url = 'onboarding/projectFinalApprove'
            submitStatusWithConfirmBox({ title: 'Confirm to Proceed', message: 'Are you sure that you want to proceed?' }, payload, url)
        } catch (e) {

        }
    }

    const sequenceOrderChange = async (ordering) => {
        let payload = {
            project_id: id,
            sequencing: ordering
        }
        statusChangAPI(payload, 'onboarding/sequenceOrderChange');
    }

    const getClassNameForStatus = (status) => {
        if (status === 'inProgress')
            return 'badge-light-orange';
        else if (status === 'ready')
            return 'badge-light-info'
        else if (status === 'completed')
            return 'badge-light-success'
        else
            return ''
    }

    if ((!projectData || projectData.amazonproject.length === 0) && !loading) {
        return (
            <div className="jumbotron text-center">
                <h4 className="display-4">Invalid Access</h4>
                <p className="lead">You don't have permissions to access this page right now. Please contact your administrator</p>
                <hr />
                <p className="lead" >
                    Go to: <Button tag={Link} type="button" color="primary" size="sm" to={URLs.BASE_PROJECTS_URL}>View Projects</Button>
                </p>
            </div>
        )
    }

    return (
        <Fragment>
            <Container fluid>
                <LoadingOverlay
                    active={loading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(0, 0, 0, 0.5)',
                            opacity: 1,
                            zIndex: 10000
                        }),
                        content: (base) => ({
                            ...base,
                            color: "#000"
                        })
                    }}
                    spinner={<Loader active type="ball-pulse" />}
                    text='Loading...'
                >
                    <Row>
                        <Col md="12">
                            <div className="d-flex justify-content-between">
                                <h4 className="mb-3">Project: <strong>{projectData && projectData.productName ? projectData.productName : ''}</strong></h4>
                                <div>
                                    {
                                        (projectData && projectData.projectStatus) &&
                                        <Fragment>
                                            Project Status: <span className={"badge fw-bolder badge-border px-4 py-3 " + getClassNameForStatus(projectData.projectStatus)}>{projectData.projectStatus === 'ready' ? 'Ready for Review' : (projectData.projectStatus === 'inProgress' ? 'In Progress' : projectData.projectStatus)}</span>
                                        </Fragment>
                                    }

                                </div>
                            </div>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="forms-wizard-horizontal p-15">
                                        {
                                            submittedData &&

                                            (
                                                <Fragment>
                                                    <Row>
                                                        <Col md="6" className="mb2rem b-d-r">
                                                            {submittedData.galleryPhotos.length > 0 ?
                                                                <AmazonGalleryEdit
                                                                    galleryPhotos={submittedData.galleryPhotos}
                                                                    accessLevel={accessLevel}
                                                                    isFinalCompleted={submittedData.isFinalCompleted}
                                                                    openComments={openComments}
                                                                    openFileUpload={openFileUpload}
                                                                    deleteField={deleteField}
                                                                    approveOrReviseProject={approveOrReviseProject}
                                                                    sequenceOrderChange={sequenceOrderChange}
                                                                    imageUrls={projectData ? projectData.image_urls : []}
                                                                />
                                                                :
                                                                <Fragment>
                                                                    <Can
                                                                        role={accessLevel}
                                                                        perform={"project:edit"}
                                                                        yes={() => (
                                                                            (projectData && projectData.projectStatus === 'inProgress') &&
                                                                            <Button type="button" color="primary" className="btn-sm f07 mx-2" size="sm" onClick={() => {
                                                                                let tempData = {
                                                                                    fieldData: null,
                                                                                    accept: ".jpg,.png,.jpeg",
                                                                                    label: "",
                                                                                    multiple: false,
                                                                                    maxFiles: 10,
                                                                                    name: 'galleryPhotos',
                                                                                    maxFileSizeInBytes: 20 * 1024 * 1024,
                                                                                }

                                                                                openFileUpload(tempData, 'Gallery Photo');
                                                                            }}><i className="fas fa-file-upload" /> Upload Gallery Photo</Button>
                                                                        )}
                                                                    />
                                                                    {/*{(accessLevel === 0 && projectData && projectData.projectStatus === 'inProgress') &&*/}
                                                                    {/*    <Button type="button" color="primary" className="btn-sm f07 mx-2" size="sm" onClick={() => {*/}
                                                                    {/*        let tempData = {*/}
                                                                    {/*            fieldData: null,*/}
                                                                    {/*            accept: ".jpg,.png,.jpeg",*/}
                                                                    {/*            label: "",*/}
                                                                    {/*            multiple: false,*/}
                                                                    {/*            maxFiles: 10,*/}
                                                                    {/*            name: 'galleryPhotos',*/}
                                                                    {/*            maxFileSizeInBytes: 20 * 1024 * 1024,*/}
                                                                    {/*        }*/}

                                                                    {/*        openFileUpload(tempData, 'Gallery Photo');*/}
                                                                    {/*    }}><i className="fas fa-file-upload" /> Upload Gallery Photo</Button>*/}
                                                                    {/*}*/}
                                                                </Fragment>
                                                            }


                                                        </Col>
                                                        <Col md="6">
                                                            <AmazonTitleAndFeaturesEdit
                                                                accessLevel={accessLevel}
                                                                title={submittedData.title}
                                                                isFinalCompleted={submittedData.isFinalCompleted}
                                                                features={submittedData.features}
                                                                openComments={openComments}
                                                                openTextField={openTextField}
                                                                approveOrReviseProject={approveOrReviseProject}
                                                            />
                                                        </Col>

                                                        <Col md="12" className="mt-3">
                                                            <br />
                                                            <AmazonProductDescriptionEdit
                                                                accessLevel={accessLevel}
                                                                productDescription={submittedData.productDescription}
                                                                isFinalCompleted={submittedData.isFinalCompleted}
                                                                openComments={openComments}
                                                                openQuillField={openQuillField}
                                                                approveOrReviseProject={approveOrReviseProject} />
                                                        </Col>



                                                        <Col md="12" className="mt-3">
                                                            <AmazonBackendKeywordsEdit
                                                                accessLevel={accessLevel}
                                                                backendKeywords={submittedData.backendKeywords}
                                                                isFinalCompleted={submittedData.isFinalCompleted}
                                                                openComments={openComments}
                                                                openTextField={openTextField}
                                                                approveOrReviseProject={approveOrReviseProject}
                                                            />
                                                        </Col>

                                                        <Fragment>
                                                            <Col md="5" className="mt-3">
                                                                <AmazonSubjectMattersEdit
                                                                    accessLevel={accessLevel}
                                                                    subjectMatters={submittedData.subjectMatters}
                                                                    isFinalCompleted={submittedData.isFinalCompleted}
                                                                    openComments={openComments}
                                                                    openTextField={openTextField}
                                                                    approveOrReviseProject={approveOrReviseProject}
                                                                />
                                                            </Col>
                                                            <Col md="7"></Col>
                                                        </Fragment>

                                                        <Fragment>

                                                            <Col md="12">
                                                                <AmazonAPlusContentEdit
                                                                    accessLevel={accessLevel}
                                                                    linkToAsset={submittedData.linkToAsset}
                                                                    isFinalCompleted={submittedData.isFinalCompleted}
                                                                    pdfFile={submittedData.pdfFile}
                                                                    openComments={openComments}
                                                                    openTextField={openTextField}
                                                                    openFileUpload={openFileUpload}
                                                                    deleteField={deleteField}
                                                                    approveOrReviseProject={approveOrReviseProject}
                                                                />
                                                            </Col>

                                                        </Fragment>


                                                        {/* <Col md="12" className="mt-3">
                                                            <AmazonPDFFileEdit accessLevel={accessLevel} pdfFile={submittedData.pdfFile} isFinalCompleted={submittedData.isFinalCompleted} openComments={openComments} openFileUpload={openFileUpload} deleteField={deleteField} approveOrReviseProject={approveOrReviseProject} />
                                                        </Col> */}

                                                        <Col md="12" className="mt-3">
                                                            <AiFilesEdit
                                                                accessLevel={accessLevel}
                                                                isFinalCompleted={submittedData.isFinalCompleted}
                                                                aiFiles={submittedData.aiFiles}
                                                                openComments={openComments}
                                                                openTextField={openTextField}
                                                                openFileUpload={openFileUpload}
                                                                deleteField={deleteField}
                                                                approveOrReviseProject={approveOrReviseProject}
                                                            />
                                                        </Col>


                                                        <Col md="12" className="mt-3 text-right">
                                                            {projectData && projectData.projectStatus !== 'completed' &&
                                                                <Fragment>
                                                                    <Can
                                                                        role={accessLevel}
                                                                        perform={"project:edit"}
                                                                        yes={() => (
                                                                            (projectData.projectStatus === 'inProgress') &&
                                                                            <Button type="button" color="success" onClick={(e) => {submitStatusChange('ready')}}>
                                                                                <i className="fab fa-telegram-plane"></i> &nbsp;Submit For Review
                                                                            </Button>
                                                                        )}
                                                                    />
                                                                    {/*{*/}
                                                                    {/*    (accessLevel === 0 && projectData.projectStatus === 'inProgress') && */}
                                                                    {/*    <Button type="button" color="success" onClick={(e) => {submitStatusChange('ready')}}>*/}
                                                                    {/*        <i className="fab fa-telegram-plane"></i> &nbsp;Submit For Review*/}
                                                                    {/*    </Button>*/}
                                                                    {/*}*/}

                                                                    <Can
                                                                        role={accessLevel}
                                                                        perform={"project:review"}
                                                                        yes={() => (
                                                                            (projectData.projectStatus === 'ready') &&
                                                                            <div>
                                                                                <Button type="button" color="danger" className="mr-2" onClick={(e) => {

                                                                                    submitStatusChange('inProgress')
                                                                                }}><i className="fas fa-undo-alt"></i> &nbsp;Send For Revision</Button>
                                                                                {
                                                                                    disableApproveBtn ?
                                                                                        <Button type="button" color="secondary" className="not-allowed" disabled><i className="fas fa-check-circle"></i> &nbsp;Approve All</Button>
                                                                                        :
                                                                                        <Button type="button" color="success" onClick={finalApproval}><i className="fas fa-check-circle"></i> &nbsp;Approve All</Button>
                                                                                }

                                                                            </div>
                                                                        )}
                                                                    />
                                                                    {/*{(accessLevel === 3 && projectData.projectStatus === 'ready') && <div>*/}
                                                                    {/*    <Button type="button" color="danger" className="mr-2" onClick={(e) => {*/}

                                                                    {/*        submitStatusChange('inProgress')*/}
                                                                    {/*    }}><i className="fas fa-undo-alt"></i> &nbsp;Send For Revision</Button>*/}
                                                                    {/*    {*/}
                                                                    {/*        disableApproveBtn ?*/}
                                                                    {/*            <Button type="button" color="secondary" className="not-allowed" disabled><i className="fas fa-check-circle"></i> &nbsp;Approve All</Button>*/}
                                                                    {/*            :*/}
                                                                    {/*            <Button type="button" color="success" onClick={finalApproval}><i className="fas fa-check-circle"></i> &nbsp;Approve All</Button>*/}
                                                                    {/*    }*/}

                                                                    {/*</div>*/}
                                                                    {/*}*/}
                                                                </Fragment>
                                                            }

                                                        </Col>

                                                    </Row>

                                                </Fragment>
                                            )
                                        }

                                    </div>

                                    <div>
                                        <Modal isOpen={modal} toggle={toggle} className="modal-lg" keyboard={false} backdrop="static">
                                            <ModalHeader toggle={toggle}>Comments on {modalTitle ? modalTitle : ''}</ModalHeader>
                                            <ModalBody>
                                                <AmazonProjectViewComments postComments={submitComments} fieldLevelComments={fieldLevelComments} isRevised={isRevisedStatus} loading={loading} />
                                            </ModalBody>
                                            <ModalFooter>

                                                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
                                            </ModalFooter>
                                        </Modal>
                                    </div>

                                    <div>
                                        <Modal isOpen={textModal} toggle={toggleTextModal} className="modal-lg" keyboard={false} backdrop="static">
                                            <ModalHeader toggle={toggleTextModal}>Edit {modalTitle}</ModalHeader>
                                            <ModalBody>
                                                <AmazonTextField onSubmit={onSubmit} loading={loading} {...fieldProps} />
                                            </ModalBody>
                                            <ModalFooter>

                                                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
                                            </ModalFooter>
                                        </Modal>
                                    </div>

                                    <div>
                                        <Modal isOpen={quillModal} toggle={toggleQuillModal} className="modal-lg" keyboard={false} backdrop="static">
                                            <ModalHeader toggle={toggleQuillModal}>Edit Product Description</ModalHeader>
                                            <ModalBody>
                                                <AmazonQuillField fieldData={fieldData} loading={loading} onSubmit={onSubmit} name="productDescription" placeholder="Enter your Text" />
                                            </ModalBody>
                                            <ModalFooter>

                                                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
                                            </ModalFooter>
                                        </Modal>
                                    </div>

                                    <div>
                                        <Modal isOpen={modalUpload} toggle={toggleUpload} className="modal-lg" keyboard={false} backdrop="static">
                                            <ModalHeader toggle={toggleUpload}>Upload {modalTitle}</ModalHeader>
                                            <ModalBody>
                                                <AmazonUploadField
                                                    loading={loading}
                                                    onUploadFile={onUploadFile}
                                                    onFileError={onFileError}
                                                    {...uploadFieldProps} />
                                            </ModalBody>
                                            <ModalFooter>

                                                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
                                            </ModalFooter>
                                        </Modal>

                                    </div>
                                    <div>
                                        <Modal isOpen={confirmModal} toggle={toggleConfirmModal} size={'sm'} className="modal-sm shadow-none" centered={true} keyboard={false} backdrop="static">
                                            <ModalHeader toggle={toggleConfirmModal}>Confirm</ModalHeader>
                                            <ModalBody>
                                                Are you sure do you want to continue?
                                                <div className="text-center mt-3">
                                                    <Button type="button" color="primary" className="mr-2" size="sm" onClick={submitStatusChange}>Yes</Button>
                                                    <Button type="button" color="danger" size="sm" onClick={() => setConfirmModal(false)} >No</Button>
                                                </div>

                                            </ModalBody>
                                        </Modal>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <CommentsOnPRoject comments={projectComments} isProjectLevel={true} filterOptions={filterOptions} />
                </LoadingOverlay>
            </Container>
        </Fragment>
    )
}



export default AmazonProjectPreview;
