import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function ExportProductKeywordService(token, asin) {
    return axios.post(`${CURRENT_API_URL}/campaign/exportAsin`, {
        asin: asin,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while exporting product keywords. Please try again later.");
            return [];
        })
}

export default ExportProductKeywordService;