import React, { Fragment, useState, useEffect } from 'react';
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    FormGroup,
    Label,
    Input,
    FormFeedback
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import KeywordTable from "../Tables/KeywordTable";
import ReadyCampaignKeywordTable from "../Tables/ReadyCampaignKeywordTable";
import CustomSelectInput from "../../../Components/Input/CustomSelectInput";
import ReadyCampaignKeywordService
    from "../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/ReadyCampaignKeywordService";
import NewKeywordWidgetList from "../Widgets/Keywords/NewKeywordWidgetList";
import EditKeywordService
    from "../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/EditKeywordService";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import Can from "../../../Components/Can";
import LoadingButton from "../../../Components/Buttons/LoadingButton";
import RefreshKeywordService
    from "../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RefreshKeywordService";
import ProductOverviewService
    from "../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService";
import CustomWidget from "../../../Components/Widgets/CustomWidget";
import RefreshSuggestedBidsService
    from "../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RefreshSuggestedBidsService";


function EditKeywordModal(props) {

    // defines the different keyword match types
    const matchTypes = [
        {
            id: 'Theme',
            name: 'phrase-match',
        },
        {
            id: 'Keyword',
            name: 'exact-match',
        }
    ];

    // validation schema for a new keyword
    const keywordSchema = Yup.object().shape({
        keywordText: Yup.string()
            .min(1, 'Required')
            .required('Required'),
        matchType: Yup.string()
            .oneOf(['Theme', 'Keyword'], 'One of the available match types must be selected')
            .required('Required'),
    });

    // defines state for whether editing mode is active or inactive
    const [isEditActive, setIsEditActive] = useState(false);

    const [loading, setLoading] = useState(false);

    // copy of list of keywords that contains the user's edits
    const [updatedKeywords, setUpdatedKeywords] = useState([]);

    // list of new keywords the user added
    const [newKeywords, setNewKeywords] = useState([]);

    // ASIN and SKU of the selected product or selected campaign's product
    const [product, setProduct] = useState({ asin: '', sku: ''});

    useEffect(() => {
        if (props.productKeywords) {
            setProduct({
                asin: props.productOverview.asin,
                sku: props.productOverview.sku,
            });
        } else {
            setProduct({
                asin: props.campaignOverview.asin,
                sku: props.campaignOverview.sku,
            });
        }
    }, [props.productOverview.asin, props.productOverview.sku, props.campaignOverview.asin, props.campaignOverview.sku, props.productKeywords]);

    useEffect(() => {
        if (props.keywords && props.keywords.length > 0) {
            setUpdatedKeywords(props.keywords.map(keyword => Object.assign({}, keyword)))
        }
    }, [props.keywords]);

    const handleKeywordRefresh = async () => {
        setLoading(true);
        let response = await RefreshKeywordService(props.token, product.asin);
        setLoading(false);
        if (response) {
            props.dispatch(ProductOverviewService(props.token, product.asin, props.integerID));
        }
    }

    const handleSuggestedBidsRefresh = async () => {
        setLoading(true);
        let response = await RefreshSuggestedBidsService(props.token, product.asin);
        setLoading(false);
        if (response) {
            props.dispatch(ProductOverviewService(props.token, product.asin, props.integerID));
        }
    }

    const handleAddKeyword = keyword => {
        const newKeyword = { keywordText: keyword.keywordText, matchType: keyword.matchType, campaignId: props.campaignID };
        let newArr = Array.from(newKeywords);
        newArr.push(newKeyword);
        setNewKeywords(newArr);
    };

    const handleSaveChanges = () => {
        setIsEditActive(false);
        const updateSelected = updatedKeywords.filter((kw, i) => props.keywords[i].selected !== kw.selected);
        const activateWords = updateSelected.map(kw => ({
            keywordText: kw.keywordText,
            keywordId: kw.keywordID,
            campaignId: kw.campaignID,
            actionToBeTaken: kw.selected ? 'activateKeyword' : 'deactivateKeyword',
        }));

        if (props.status === 'ready') {
            props.dispatch(ReadyCampaignKeywordService(props.token, props.name, props.collectionID, newKeywords, updateSelected));
        } else {
            const updateBid = updatedKeywords.filter((kw, i) => props.keywords[i].bidPrice !== kw.bidPrice);
            const editWords = updateBid.map(kw => ({
                keywordText: kw.keywordText,
                keywordId: kw.keywordID,
                campaignId: kw.campaignID,
                newBid: kw.bidPrice,
            }));

            const suggestedBidWords = updatedKeywords.filter(kw => kw.whichBid !== undefined).map(kw => ({
                keywordText: kw.keywordText,
                keywordId: kw.keywordID,
                campaignId: kw.campaignID,
                whichBid: kw.whichBid,
            }));

            // console.log(newKeywords, activateWords, editWords, suggestedBidWords);

            // props.dispatch(EditKeywordService(props.token, props.name, props.collectionID, newKeywords, activateWords, editWords));
            props.dispatch(EditKeywordService(props.token, newKeywords, activateWords, editWords, suggestedBidWords));
        }
    };

    const handleDiscardChanges = () => {
        setIsEditActive(false);
        setNewKeywords([]);
        setUpdatedKeywords(props.keywords.map(keyword => Object.assign({}, keyword)));
    };

    const handleRemoveKeyword = (keywordToRemove) => {
        setNewKeywords(newKeywords.filter(keyword => keyword !== keywordToRemove));
    };

    const isDuplicateKeyword = (keywordText, matchType, formikBag) => {
        if (updatedKeywords.find(kw => kw.keyword === keywordText && kw.status === matchType) ||
            newKeywords.find(kw => kw.keywordText === keywordText && kw.matchType === matchType)) {
            formikBag.setFieldError('keywordText', "This keyword already exists with this match type.");
            return true;
        }
    };

    const renderEditButtons = () => (
        <div style={{display: "flex"}}>
            { isEditActive ?
                <>
                    <Button
                        className="btn-icon btn-icon-right"
                        outline
                        color="secondary"
                        size="md"
                        onClick={() => handleDiscardChanges()}
                    >
                        Discard Changes
                        <i className="lnr-cross btn-icon-wrapper" style={{paddingBottom: '1px'}}> </i>
                    </Button>
                    <Button
                        className="ml-3 btn-icon btn-icon-right"
                        outline
                        color="success"
                        size="md"
                        onClick={() => handleSaveChanges()}
                    >
                        Save Changes
                        <i className="pe-7s-check btn-icon-wrapper" style={{paddingBottom: '1px', fontSize: '18px'}}> </i>
                    </Button>
                </>
                :
                <Button
                    className="btn-icon btn-icon-right"
                    color="brand-blue-dark"
                    size="md"
                    onClick={() => setIsEditActive(true)}
                >
                    Edit
                    <i className="lnr-pencil btn-icon-wrapper" style={{paddingBottom: '3px', fontSize: '12px'}}> </i>
                </Button>
            }
        </div>
    );

    const renderAddKeywordForm = () => (
        <Row className="mt-2 mb-3">
            <Col md="12">
                <h5 className="mb-3">Add Keywords</h5>
                <Formik
                    validationSchema={keywordSchema}
                    initialValues={{
                        keywordText: '',
                        matchType: '',
                    }}
                    onSubmit={(keyword, formikBag) => {
                        if (!isDuplicateKeyword(keyword.keywordText, keyword.matchType, formikBag)) {
                            handleAddKeyword(keyword);
                            formikBag.resetForm();
                        }
                    }}
                >
                    {formik =>
                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col md="4">
                                        <Label for="keywordText">Keyword</Label>
                                        <Input
                                            type="text"
                                            name="keywordText"
                                            tag={Field}
                                            invalid={formik.errors.keywordText && formik.touched.keywordText}
                                        />
                                        <FormFeedback>{formik.errors.keywordText}</FormFeedback>
                                    </Col>
                                    <Col md="4">
                                        <Label for="matchType">Keyword Match Type</Label>
                                        <CustomSelectInput
                                            name="matchType"
                                            tag={Field}
                                            data={matchTypes}
                                            valueField='id'
                                            textField='name'
                                        />
                                    </Col>
                                    <Col md="4">
                                        <Button className="mt-4" type="submit" color="primary" size="lg">
                                            Add Keyword
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    }
                </Formik>
            </Col>
        </Row>
    );

    const renderPerformanceWidgets = () => (
        <Fragment>
            <h4 className="mbg-3">Campaign Performance</h4>
            <Row className="mb-4">
                <Col className="mb-3" lg="6" xl="3">
                    <CustomWidget
                        className="card widget-content bg-happy-green text-white"
                        heading="Sales"
                        content={<div className="widget-numbers">${props.campaignOverview.totalSales}</div>}
                    />
                </Col>
                <Col className="mb-3" lg="6" xl="3">
                    <CustomWidget
                        className="card widget-content bg-premium-dark text-white"
                        heading="Conversions"
                        content={<div className="widget-numbers">{props.campaignOverview.totalConversions}</div>}
                    />
                </Col>
                <Col className="mb-3" lg="6" xl="3">
                    <CustomWidget
                        className="card widget-content bg-night-fade text-white"
                        heading="Advertising Spend"
                        content={<div className="widget-numbers">${props.campaignOverview.totalSpend}</div>}
                    />
                </Col>
                <Col className="mb-3" lg="6" xl="3">
                    <CustomWidget
                        className="card widget-content bg-arielle-smile "
                        heading="ROAS"
                        content={<div className="widget-numbers">{+((props.campaignOverview.totalSales / props.campaignOverview.totalSpend).toFixed(3)) || 0}</div>}
                    />
                </Col>
            </Row>
            <div className="divider mb-4"></div>
        </Fragment>
    )


    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} size="xl">
            <ModalHeader toggle={props.toggle}>{props.header ? props.header : "Manage Keywords"}</ModalHeader>
            <ModalBody>
                <LoadingOverlay
                    active={props.productLoading || props.campaignLoading}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 1
                        }),
                        content: (base) => ({
                            ...base,
                            color: "#000"
                        })
                    }}
                    spinner={<Loader active type="ball-pulse"/>}
                    text='Loading keyword details...'
                >
                    {!props.productKeywords &&
                        renderPerformanceWidgets()
                    }
                    <Row className="mb-3">
                        <Col md="12" style={{display: "flex", justifyContent: "space-between"}}>
                            <Can
                                role={props.accessLevel}
                                perform={"campaigns:edit"}
                                yes={() => renderEditButtons()}
                            />
                            <Can
                                role={props.accessLevel}
                                perform={"keywords:refreshData"}
                                yes={() => (
                                    <div>
                                        <LoadingButton
                                            className={"ml-2"}
                                            size={"md"}
                                            outline
                                            loading={loading}
                                            color="brand-blue-dark"
                                            onClick={() => handleKeywordRefresh()}
                                        >
                                            Refresh Keyword Data
                                        </LoadingButton>
                                        <LoadingButton
                                            className={"ml-2"}
                                            size={"md"}
                                            outline
                                            loading={loading}
                                            color="brand-blue-dark"
                                            onClick={() => handleSuggestedBidsRefresh()}
                                        >
                                            Refresh Suggested Bids
                                        </LoadingButton>
                                    </div>


                                )}
                            />
                        </Col>
                    </Row>


                    { (isEditActive && !props.productKeywords) &&
                    <Fragment>
                        { renderAddKeywordForm() }
                        <Row>
                            <Col>
                                <NewKeywordWidgetList data={newKeywords} handleRemove={handleRemoveKeyword} card={true}/>
                            </Col>
                        </Row>
                    </Fragment>
                    }
                    <Row>
                        <Col md="12">
                            { props.status === 'ready' ?
                                <ReadyCampaignKeywordTable
                                    data={props.keywords}
                                    updatedKeywords={updatedKeywords}
                                    setUpdatedKeywords={setUpdatedKeywords}
                                    isEditActive={isEditActive}
                                />
                                :
                                <KeywordTable
                                    data={props.keywords}
                                    updatedKeywords={updatedKeywords}
                                    setUpdatedKeywords={setUpdatedKeywords}
                                    isEditActive={isEditActive}
                                    productKeywords={props.productKeywords}
                                />
                            }
                        </Col>
                    </Row>

                </LoadingOverlay>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    productOverview: state.accountOverview.product.productOverview,
    integerID: state.accountOverview.store.integerID,
    campaignOverview: state.accountOverview.campaign.campaignOverview,
    campaignID: state.accountOverview.campaign.campaignOverview.campaignID,
    name: state.accountOverview.campaign.campaignOverview.name,
    campaignLoading: state.accountOverview.campaign.loading,
    productLoading: state.accountOverview.product.loading,
    status: state.accountOverview.campaign.campaignOverview.status,
})

export default connect(mapStateToProps)(EditKeywordModal);