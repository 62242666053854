import axios from 'axios'
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function ProductTrackingService(token, asin, update) {
    return axios.post(`${CURRENT_API_URL}/campaign/manageKeywordTracking`, {
        asin: asin,
        update: update,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess(`Product Tracking ${update}d successfully!`);
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while updating your product tracking, please try again.");
            return false;
        })
}

export default ProductTrackingService