import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function AssignContentAgencyService(token, projectName, contentAgencyAssignedMasterId, contentAgencyAssignedName) {
    return axios.post(`${CURRENT_API_URL}/onboarding/assignProject`, {
        projectName: projectName,
        contentAgencyAssignedMasterId: contentAgencyAssignedMasterId,
        contentAgencyName: contentAgencyAssignedName,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(() => {
        notify.NotifySuccess("Agency was successfully assigned to the project!");
        return true;
    }).catch(() => {
        notify.NotifyError("There was an error while assigning the agency, please try again.");
        return false;
    })
}

export default AssignContentAgencyService;
