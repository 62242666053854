import React from 'react';
import { Collapse, ListGroup, ListGroupItem } from "reactstrap";
import _ from 'lodash';

import WidgetLayout from "./WidgetLayout";


/**
 * Component that adds a collapsible list of details/info to a widget.
 * Props:
 * isOpen - true if the collapsible view should be open, false if closed.
 * details - dictionary of "details" where the key of each property is used as the heading. Every property will be displayed
 * as a separate list item in the collapsible view with its "heading" on the left and its value on the right.
 * actions - array of callback functions that each render a component. All components will be rendered inline below the details list.
 * Each action callback should take in a key prop that is assigned to the rendered component to ensure all components are unique.
 * autoFormat (optional) - boolean that indicates whether this component should automatically format detail list headings in the collapsible view
 * nestedList (optional) - widget list to be displayed in the collapsible section of the widget
 */
function WidgetDetailsCollapsible(props) {
  const renderDetailItem = (key, i) => {
    return (
      <ListGroupItem key={i}>
        <WidgetLayout>
          <div className="widget-content-left">
            <div className="widget-heading">
              {props.autoFormat ? _.startCase(key) : key}
            </div>
          </div>
          <div className="widget-content-right">
            <div className="widget-numbers-sm" style={{ fontSize: "1.2rem" }}>
              {props.details[key] ? props.details[key] : "Unavailable"}
            </div>
          </div>
        </WidgetLayout>
      </ListGroupItem>
    );
  };

  const renderNestedList = (list, i) => (
    <ListGroupItem key={i}>{list}</ListGroupItem>
  );

  const renderActionItems = (actions) => {
    return (
      <WidgetLayout>
        <div className="widget-content-right">
          {actions.map((action, i) => action(i))}
        </div>
      </WidgetLayout>
    );
  };

  return (
    <Collapse isOpen={props.isOpen}>
      <ListGroup className="ml-3 mr-3 mb-2">
        <div className="container ml-3">
          <div className="row">
            {// Object.keys(props.details).map((key, i) => renderDetailItem(key, i))
            Object.keys(props.details).map((key, i) => (
              <>
                {key != "link" ? (
                  <div className="col-3 ">
                    <div className="widget-content-left">
                      <div className="widget-heading text-center">
                        <b>{props.autoFormat ? _.startCase(key) : key}</b>: {props.details[key] ? props.details[key]: "Unavailable"}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
        </div>
        {props.nestedList && renderNestedList(props.nestedList, -1)}
      </ListGroup>
      {props.actions && renderActionItems(props.actions)}
    </Collapse>
  );
}

export default WidgetDetailsCollapsible;
