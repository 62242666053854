import React, { Fragment, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink, FormGroup, Input } from 'reactstrap';


const CustomPagination = ({ pageSize, totalRecords, onPageChange, pageNums }) => {

    const [currentPage, setCurrentPage] = useState(0)

    const handleClick = (e, index) => {
        e.preventDefault();

        setCurrentPage(index);

        onPageChange({
            currentPage: index,
            pageCount: getPageCount(),
            pageSize
        })
    }

    const getPageCount = () => {
        return Math.ceil(totalRecords / pageSize);
    }

    const onPageNumChange = (e) => {
        console.log(e.target.value)
        onPageChange({
            currentPage,
            pageCount: getPageCount(),
            pageSize: e.target.value
        })
    }

    return (
        <Fragment>
            <div className="pagination-wrapper container-fluid">
            <div className="row">
              <div className="col-md-2">
                {
                    (pageNums && pageNums.length > 0) &&
                    <span>
                        <FormGroup>
                        <br/>
                            <Input type="select" name="select" id="exampleSelect" onChange={onPageNumChange}>
                                {
                                    pageNums.map((count, i) => {
                                       return <option key={i}>{count}</option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </span>
                }
              </div>
              <div className="col-md-10 text-center">
              <Pagination size="sm" aria-label="Page navigation example" listClassName="float-right">

                  <PaginationItem disabled={currentPage <= 0}>
                      <PaginationLink
                          onClick={e => handleClick(e, 0)}
                          first
                          href="#" />
                  </PaginationItem>

                  <PaginationItem disabled={currentPage <= 0}>

                      <PaginationLink
                          onClick={e => handleClick(e, currentPage - 1)}
                          previous
                          href="#"
                      />

                  </PaginationItem>

                  {[...Array(getPageCount())].map((page, i) =>
                      <PaginationItem active={i === currentPage} key={i}>
                          <PaginationLink onClick={e => handleClick(e, i)} href="#">
                              {i + 1}
                          </PaginationLink>
                      </PaginationItem>
                  )}

                  <PaginationItem disabled={currentPage >= getPageCount() - 1}>

                      <PaginationLink
                          onClick={e => handleClick(e, currentPage + 1)}
                          next
                          href="#"
                      />

                  </PaginationItem>
                  <PaginationItem disabled={currentPage >= getPageCount() - 1}>
                      <PaginationLink
                          onClick={e => handleClick(e, getPageCount() - 1)}
                          last
                          href="#" />
                  </PaginationItem>

              </Pagination>
              </div>
            </div>





            </div>

        </Fragment>
    )
}

export default CustomPagination;