import React, {Component} from 'react';


class PageTitle extends Component {

    render() {
        let {
            heading,
            icon,
            subheading
        } = this.props;


        return (
            <div className="app-page-title">
                <div className="page-title-wrapper">
                    <div className="page-title-heading">
                        <div
                            className="page-title-icon">
                            <i className={icon}/>
                        </div>
                        <div>
                            {heading}
                            <div
                                className="page-title-subheading">
                                {subheading}
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default PageTitle;
