import React, { Fragment, useRef, useState } from 'react';
import {
    Button
} from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

// const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5 * 1024 * 1024;


const AmazonPDFView = (props) => {

    const fileInputField = useRef(null);
    const [pdfFile, setPdfFile] = useState(null);

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    }

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let regex = new RegExp('[^.]+$');
            let extension = ""
            extension = newFiles[0].name.match(regex)
            if (extension[0].toLowerCase() !== 'pdf') {
                props.fileErrorMsg('Only PDF file is allowed.')
                return;
            }

            if(newFiles[0].size > DEFAULT_MAX_FILE_SIZE_IN_BYTES){
                props.fileErrorMsg('Maximum file size allowed is 5 MB')
                return;
            }
            props.formik.setFieldValue('pdfFile', newFiles[0])
            setPdfFile(newFiles[0])
        }

    }

    const deletePDFFile = () => {
        props.formik.setFieldValue('pdfFile', undefined)
        setPdfFile(null);
    }
    return (
        <Fragment>
            {!pdfFile && <section className="upload-button">
            <div className="symbol symbol-60px mb-6 align-left">
            <img src="/svg/pdf.svg" alt="" /> <span className="heading t-5 grey">PDF file upload <sup className="red">*</sup> - <span className="note">Maximum file size allowed is 5 MB</span></span>
            </div>
                <section className="pdfUploadSection">
                    <p className="sc-bxivhb bSJghW">Upload PDF File</p>
                    {/* <Button type="button" color="primary" size="lg" onClick={() => fileInputField.current.click()}>

                        Upload
                    </Button> */}
                    <button type="button" className="sc-ifAKCX weRJm" onClick={() => fileInputField.current.click()}><i className="fas fa-file-upload"></i><span> Upload</span></button>
                    <input
                        type="file"
                        accept=".pdf"
                        ref={fileInputField}
                        onChange={(e) => handleNewFileUpload(e)}
                        title=""
                        value=""
                    />
                </section>
            </section>
            }

            {pdfFile && (
                <div className="pdfViwer">
                    <div className="pdfViwer__container">

                        <div className="pdfViwer__container__document">

                            <Fragment>
                                <div className="deleteImage">
                                    <Button color="link" onClick={deletePDFFile}>
                                        <i className="fas fa-trash-alt r-5 pos-ab red t-0"></i>
                                    </Button>
                                </div>
                              { /* <div className="deleteImage comment pdf-comments blink">
                                    <Button color="link" type="button">
                                        <i className="fas fa-comments r-4 pos-ab t-0 comment-icon"></i>
                                    </Button>
                                </div> */ }
                                <Document
                                    file={pdfFile}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    {
                                        Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    scale={1.5}
                                                />
                                            ),
                                        )
                                    }
                                </Document>
                            </Fragment>


                        </div>
                    </div>
                </div>
            )
            }
        </Fragment>
    )
}


export default AmazonPDFView;
