import React from 'react'
import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';

function getAllOriginalFilesByAssetIdentifierService(token,data) {
    return axios.post(`${CURRENT_API_URL}/assetgallery/getAllOriginalFilesByAssetIdentifier`,data,{
        headers: {
            'Authorization': token,
        }
    }).then(res => {
            return res.data;        
    }).catch(error => {
        console.log(error);
        notify.NotifyError("An error occurred while fetching all file list. please try again later.");
        return false;
    });
}

export default getAllOriginalFilesByAssetIdentifierService;