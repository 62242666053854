import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";


function RefreshSuggestedBidsService(token, asin) {
    return axios.post(`${CURRENT_API_URL}/campaign/getSuggestedBidsForProduct`, {
        asin: asin,
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Suggested bids refreshed successfully!");
        return true;
    }).catch(() => {
        notify.NotifyError("An error occurred while suggested bids, please try again.");
        return false;
    })
}

export default RefreshSuggestedBidsService;