import React from "react";
import { connect } from 'react-redux';

import urls from '../../../../../Utils/URLs';
import DashboardLayout from "../../../Layout/DashboardLayout";
import {filterByPermission} from "../../../../Components/Can";


function CreditsLayout(props) {

    let menuItems = [
        {
            perform: "pricingOptions:view",
            yes: {
                icon: 'pe-7s-refresh-2',
                label: 'Pricing Options',
                to: urls.PRICING_OPTIONS_URL,
            },
        },
        {
            perform: "addOns:view",
            yes: {
                icon: 'pe-7s-refresh-2',
                label: 'Add Ons',
                to: urls.PRICING_ADDONS_URL,
            },
        }
        // {
        //     icon: 'pe-7s-refresh-2',
        //     label: 'Purchase Credits',
        //     to: urls.CREDITS_PURCHASE_PAGE_URL,
        // },
    ];

    menuItems = filterByPermission(menuItems, props.accessLevel)


    return (
        <DashboardLayout
            menuItems={menuItems}
            heading="Simple Pricing"
            icon="lnr-map text-info"
            subheading="Flexible options. No percentage deals. Everything you need."
        >
            {props.children}
        </DashboardLayout>
    );
}

const mapStateToProps = state => ({
    accessLevel: state.loggedUser.accessLevel,
})

export default connect(mapStateToProps)(CreditsLayout);
