import React, { Fragment, useEffect, useState } from "react";
import { Container, Card, CardBody, CardHeader } from "reactstrap";
import "./ProgressBar";
import { connect } from "react-redux";
import Rectangle from "./img/Rectangle.png";
import Group from "./img/Group.png";
import "./ProgressBar.css";
import { Link } from "react-router-dom";
import StarRating from "../../AccountOverview/Components/Rating/StarRating";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { Col, Input, Row, Button, Label } from "reactstrap";
import LoadingButton from "../../../../Components/Buttons/LoadingButton";
import { DropdownList } from "react-widgets";
import OneClickIcon from "./img/Icon_small_centered.png";
import UpdateAsinManagementStatusService from "../../../../../Service/DashboardServices/RegistrationJourneyServices/UpdateAsinManagementStatusService";
import regions from "../../../../../Utils/RegionAuthorization";
import AuthButtonSPAPI from "../../UserProfile/Scenes/Authorization/Components/AuthButtonSPAPI";
import LogoutService from "../../../../../Service/EntryServices/LogoutService";
import Can , {filterByPermission} from "../../../../Components/Can";
import AddStoreProductService from "../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/AddStoreProductService";
import StoreOverviewService from "../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
function StoreSetup(props) {
  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState("");
  const [ProductCount, setProductCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [asinArray, setAsinArray] = useState([]);
  const { ratingVotes } = props;

  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [newProductASIN, setNewProductASIN] = useState('');
  // B07P6GDRV3
  const logout = () => {
    localStorage.setItem('profileId', '');
    props.dispatch(LogoutService(props.token));
  };
  function handleChange(e) {
    setChecked(e.target.checked);
    addValueToArray(e.target.value);
    console.log("e.target.checked");
    console.log(e.target.checked);

    var simpliworksManagementStateOnScreen = "";
    if (e.target.checked === false) {
      simpliworksManagementStateOnScreen = "unManaged";
    } else if (e.target.checked === true) {
      simpliworksManagementStateOnScreen = "managed";
    }

    const updatedArray = store.productList.map((item) =>
      item.asin === e.target.value
        ? {
          ...item,
          simpliworksManagementState: simpliworksManagementStateOnScreen,
        }
        : item
    );

    console.log("updatedArray");
    console.log(updatedArray);
    store.productList = updatedArray;
    setStore(store);
    console.log(store);
  }

  const addValueToArray = (value) => {
    const updatedArray = [...asinArray];
    updatedArray.push(value);
    setAsinArray(updatedArray);
  };

  const storeData = {
    id: props.storeList[0].integerID,
    name: props.storeList[0].name,
    stringID: props.storeList[0].stringID,
    location: props.storeList[0].location,
    logo: props.storeList[0].logo,
    storelink: props.storeList[0].link,
    storeRatings: props.storeList[0].rating,
    productList: props.storeList[0].productList,
    managedProducts: props.storeList[0].managedProducts,
    storeSelectedManagedProducts:
      props.storeList[0].storeSelectedManagedProducts,
    storeSPAPIAuthorized: props.storeList[0].storeSPAPIAuthorized,
    storeSetUpComplete: props.storeList[0].storeSetUpComplete,
  };
  //console.log("storeList & productlist");
  //console.log(props.storeList);
  useEffect(() => {
    setStore(storeData);
    console.log("useEffect");
    console.log(storeData);
  }, []);
  const setSelectedStore = (value) => {
    storeData.id = value.integerID;
    storeData.stringID = value.stringID;
    storeData.name = value.name;
    storeData.location = value.location;
    storeData.logo = value.logo;
    storeData.storelink = value.link;
    storeData.storeRatings = value.rating;
    storeData.productList = value.productList;
    storeData.managedProducts = value.managedProducts;
    storeData.storeSelectedManagedProducts = value.storeSelectedManagedProducts;
    storeData.storeSPAPIAuthorized = value.storeSPAPIAuthorized;
    storeData.storeSetUpComplete = value.storeSetUpComplete;
    setStore(storeData);
    console.log("setSelectedStore");
    console.log(storeData);
  };
  const handleOnClick = () => {
    setIsOpen(true);
  };
  const handleOnSubmit = async () => {
    const asinList = store.productList
      .filter((product) => product.simpliworksManagementState == "managed")
      .map((item) => ({ asin: item.asin }));

    let data = {
      integerID: store.id,
      asinList: asinList,
    };
    UpdateAsinManagementStatusService(props.token, data).then((response) => {
      // setProductCount(response)

      setIsOpen(false);
      window.location.reload(false);
    });
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  const handleAddProduct = async (asin, sku) => {
    // let response = await AddStoreProductService(props.token, asin, sku, props.storeIntegerID);
    let response = await AddStoreProductService(
        props.token,
        asin,
        store.id,
        store.stringID
    );
    if (response) {
        props.dispatch(
            StoreOverviewService(
                props.token,
                store.id,
                store.stringID
            )
        );
        if(!loading)
        {
          window.location.reload(false);
        }
    }
    return response;
};
//   const renderAddProductModal = () => (
//     <Can
//         role={props.accessLevel}
//         perform="products:add"
//         yes={() => (
//             <Col xl="12" md="12">
//                 <Button
//                     className="btn-icon btn-icon-left float-right"
//                     // className="mt-2 ml-2 pl-2 pr-2 float-right"
//                     color="brand-blue-dark"
//                     style={{fontWeight: 400}}
//                     onClick={() => setIsAddProductOpen(!isAddProductOpen)}
//                 >
//                     <i
//                         className="lnr-plus-circle btn-icon-wrapper"
//                         style={{paddingBottom: '1px'}}
//                     >
//                         {' '}
//                     </i>
//                     Add Product
//                 </Button>
//                 <CustomModal
//                     header="Add a product to this store"
//                     isOpen={isAddProductOpen}
//                     toggle={() => setIsAddProductOpen(!isAddProductOpen)}
//                 >
//                     <Row>
//                         <Col className="mb-3" md="12">
//                             <Label for="newProductASIN">Product ASIN</Label>
//                             <Input
//                                 id="newProductASIN"
//                                 placeholder="Enter product ASIN"
//                                 value={newProductASIN}
//                                 onChange={(e) => setNewProductASIN(e.target.value)}
//                             />
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col md={{offset: 4, size: 4}}>
//                             <LoadingButton
//                                 color="primary"
//                                 onClick={async () => {
//                                     setLoading(true);
//                                     await handleAddProduct(newProductASIN);
//                                     setLoading(false);
//                                 }}
//                                 disabled={newProductASIN === ''}
//                                 loading={loading}
//                             >
//                                 Add Product
//                             </LoadingButton>
//                         </Col>
//                     </Row>
//                 </CustomModal>
//             </Col>
//         )}
//     />
// );

  return (
    <>
      <Container fluid>
        <Row className="justify-content-around ">
          {props.endJourney ? (
            <>
              <Col md="6" className="mt-5 ">
                <h1 className="text-center dropdown-style">
                  <b>Congratulations!!!</b>
                </h1>
                <h2 className="text-font mb-5 text-center">
                  You have successfully completed registration journey.
                </h2>
                <p className="text-center">
                  <Button
                    color={"brand-blue-dark"}
                    className="mb-3"
                    onClick={logout}
                  >
                    Log Out
                  </Button>
                </p>
              </Col>
            </>
          ) : (
            <Col md="5" className=" mt-2">
              {props.storeCount > 1 &&
                store.storeSPAPIAuthorized &&
                store.storeSetUpComplete == false ? (
                <>
                  <h1 className="conaitent-heading">
                    <b>Store Setup:</b>
                  </h1>
                  <p className="text-font mb-5">
                    Selected store has been already authorized for SPAPI. If you
                    do not see Product List button below then background process
                    for store set up is still in progress. Please wait for few
                    more minutes and refresh.
                    <br></br>
                    If you wish to authorize another store, kindly select store
                    from the dropdown and follow mentioned steps.
                  </p>
                </>
              ) : (
                ""
              )}

              {props.storeCount > 1 && store.storeSPAPIAuthorized == false ? (
                <>
                  <h2 className="conaitent-heading">
                    <b>SPAPI Authorization Steps:</b>
                  </h2>
                  <ol type="1" className="mb-3">
                    {/* <li className="text-font">Select store from dropdown below.</li> */}
                    {/* <li className="text-font">Click on Authorize button.</li> */}
                    <li className="text-font">
                      Log in to your seller central account in another tab.
                    </li>
                    <li className="text-font">
                      Select required store in seller central.
                    </li>
                    <li className="text-font">
                      Come back to Simpliworks window and click 'Authorize'
                      button below.
                    </li>
                    <li className="text-font">
                      Consent page will open in the new tab.
                    </li>
                    <li className="text-font">Please confirm authorization.</li>
                    <li className="text-font">
                      On successful authorization, a message will get displayed.
                    </li>
                    <li className="text-font">
                      Your store setup process will be started in the
                      background.
                    </li>
                    <li className="text-font">
                      Please press the 'Refresh' button after 10 to 15 minutes.
                    </li>
                  </ol>
                  <p className="text-end" > <Button
                    color={"brand-blue-dark"}
                    className="ml-auto mb-3"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button></p>
                </>
              ) : (
                ""
              )}
              {props.storeCount == 1 && store.storeSPAPIAuthorized && store.productList.length ==0 ? (
                <>
                  <h1 className="conaitent-heading">
                    <b>Store Setup:</b>
                  </h1>
                  <p className="text-font mb-5">
                    Your SPAPI Authorization is complete. Your store setup
                    process will be started in the background. Please wait.{" "}
                    <br></br>
                    Your product list will populate below.<br></br>
                    Please press the 'Refresh' button after 10 to 15 minutes.
                  </p>
                  <p className="text-end" > <Button
                    color={"brand-blue-dark"}
                    className="ml-auto mb-3"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button></p>
                </>
              ) : (
                ""
              )}
              {/* {store.storeSetUpComplete &&
                store.storeSPAPIAuthorized &&
                props.endJourney != true ? (
                <>
                  <h2 className="conaitent-heading">
                    <b>Products Selection:</b>
                  </h2>
                  <p className="text-font mb-1">
                    Your store set up proccess is completed.<br></br> Please
                    click on product list button and select products. <br></br>Your
                    product list will populate below.
                  </p>
                </>
              ) : (
                ""
              )} */}

            </Col>
          )}

          {props.endJourney ? '' : <> <Col md="6" className="">

            <Card>
              <Row className="justify-content-around pl-5  pt-5">
                <Col md="4">
                  <div className={store.logo ? 'mt-5' : ''}>
                    <p className='text-center'><img src={store.logo ? store.logo : Group} width={store.logo ? '' : 80} /></p>

                  </div>

                  <DropdownList
                    className="mt-3 mb-3 dropdown-style "
                    data={props.storeList}
                    dataKey="integerID"
                    textField="name"
                    placeholder="Select"
                    defaultValue={"placeholder"}
                    value={store}
                    onChange={(value) => {
                      setSelectedStore(value);
                    }}
                  />
                  <Row className="">
                    {store.storeSPAPIAuthorized == false ? (
                      <Col md="12">
                        <AuthButtonSPAPI
                          pathname={regions[0].pathname}
                          region={regions[0].name}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {store.storeSPAPIAuthorized &&
                      store.storeSetUpComplete ? (
                      <>
                        <Col md="4">

                        </Col>
                        <Col md="8">
                          <Button
                            color={"brand-blue-dark"}
                            className=""
                            onClick={handleOnClick}
                          >
                            Product List
                          </Button>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>

                <Col md="8" className="">

                  <p>
                    <b>Rating: </b>&nbsp;
                    <StarRating
                      initialRating={store.storeRatings}
                      readonly
                      stop={5}
                    />
                  </p>
                  <p>
                    <b>Store name: </b>
                    {store.name}
                  </p>
                  <p>
                    <b>Account ID: </b>
                    {store.id}
                  </p>
                  <p>
                    <b>Location : </b>
                    {store.location}
                  </p>
                  <p>
                    <b>SPAPI: </b>
                    {store.storeSPAPIAuthorized == false
                      ? "Authorization pending"
                      : "Authorized"}
                  </p>
                  <p>
                    <b>Managed products: </b>
                    {store.managedProducts} {/* {ProductCount} */}
                  </p>
                  <p>
                    <a href={store.storelink} target="_blank">
                      Visit store on Amazon
                    </a>
                  </p>
                </Col>
              </Row>
             
            </Card>
            {/* <img src={Rectangle}></img> */}
          </Col></>}

        </Row>
      </Container>
      {/* Add custom model here only */}

      <CustomModal
        header={"Store name: " + store.name}
        isOpen={isOpen}
        className="h-50"
        toggle={() => setIsOpen(!isOpen)}
        size={"lg"}
        footer={
          <>
            <Button color={"link"} onClick={() => setIsOpen(!isOpen)}>
              Cancel
            </Button>
          </>
        }
      >
         
        {store.storeSelectedManagedProducts == false ? (
          <Button
            className="ml-2 pl-2 pr-2 float-right"
            color={"brand-blue-dark"}
            onClick={() => {
              {
                handleOnSubmit();
                // disabled={storeSelectedManagedProducts}
              } //props.requestStores(selectedUser.email);
            }}
          >
            Submit
          </Button>
        ) : (
          ""
        )}
        {/* {renderAddProductModal()} */}
        <Label className="color-changing">
          <Card>
            <div>
              {props.storeList.map((oneStore) => (
                <>
                  {oneStore.integerID == store.id ? (
                    <>
                      {store.productList.map((product) => (
                        <>
                          <Row
                            className={
                              product.simpliworksManagementState == "managed"
                                ? "row-background-color-blue"
                                : ""
                            }
                          >
                            <Col md="1" className="mt-5">
                              <div className="">
                                {product.simpliworksManagementState ==
                                "managed" ? (
                                  <img
                                    className="float-right"
                                    src={OneClickIcon}
                                    width={40}
                                    height={40}
                                  />
                                ) : (
                                  ""
                                )}
                                <input
                                  className="mt-5 float-right"
                                  value={product.asin}
                                  type="checkbox"
                                  // name={'checkbox'}
                                  name={product.asin}
                                  // checked={false}
                                  checked={
                                    product.simpliworksManagementState ==
                                    "managed"
                                      ? true
                                      : false
                                  }
                                  disabled={store.storeSelectedManagedProducts}
                                  onChange={handleChange}
                                />
                              </div>
                            </Col>
                            <Col md="11">
                              <Row className="justify-content-center border m-2 row-background-color">
                                <Col md="3" className="p-2">
                                  <div>
                                    <img
                                      className="productlistitem"
                                      src={product.itemImage}
                                    />
                                  </div>
                                </Col>
                                <Col md="8" className="p-2">
                                  <div>
                                    <h6>
                                      <b>{product.productTitle}</b>
                                    </h6>

                                    <p>
                                      {ratingVotes !== 0 ? (
                                        <div>
                                          Rating:{" "}
                                          <StarRating
                                            initialRating={product.itemRating}
                                            readonly
                                            stop={5}
                                          />{" "}
                                          ({product.itemRating}) ({ratingVotes}{" "}
                                          reviews)
                                        </div>
                                      ) : (
                                        <div>No ratings available</div>
                                      )}
                                    </p>
                                    <p>
                                      {product.simpliworksManagementState ==
                                        "unManaged" ? (
                                        <div className="mt-2 text-warning font-weight-bold">
                                          <i
                                            className="pe-7s-attention text-warning"
                                            style={{
                                              fontSize: "1rem",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {" "}
                                          </i>{" "}
                                          Management Paused
                                        </div>
                                      ) : (
                                        <div className="mt-2 text-brand-blue-dark font-weight-bold">
                                          <i
                                            className="pe-7s-check"
                                            style={{
                                              fontSize: "1rem",
                                              color: "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {" "}
                                          </i>{" "}
                                          Managed by Simpliworks
                                        </div>
                                      )}{" "}
                                    </p>
                                    <div className="">
                                      <p>
                                        <span>
                                          <b>ASIN:</b> {product.asin}
                                        </span>
                                        <span className="float-right ml-auto">
                                          <b>PRICE:</b> ${product.itemPrice}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </div>
          </Card>
        </Label>
      </CustomModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(StoreSetup);