import React, { Fragment, useState } from 'react';
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { connect } from 'react-redux';

import CampaignKeywordModal from "../../../../Components/Modals/EditKeywordModal";


function CampaignKeywordCard(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Fragment>
            <Card>
                <CardHeader>Keywords</CardHeader>
                <CardBody>
                    <ListGroup>
                        {props.keywords.slice(0, 3).map((item, i) => (
                            <ListGroupItem key={i}>{item.keyword}</ListGroupItem>
                        ))}
                        <ListGroupItem>...</ListGroupItem>
                    </ListGroup>
                    <Button
                        color="link"
                        onClick={() => toggle()}
                    >
                        Manage Keywords {'>'}
                    </Button>
                </CardBody>
            </Card>
            <CampaignKeywordModal
                isOpen={isOpen}
                toggle={toggle}
                keywords={props.keywords}
                // status={props.status}
            />
        </Fragment>
    );
}

const mapStateToProps = state => ({
    keywords: state.accountOverview.campaign.keywords,
})

export default connect(mapStateToProps)(CampaignKeywordCard);