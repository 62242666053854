import axios from 'axios';
import notify from '../../../../Notifications/ToastifyActions';
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadProductData } from "../../../../Redux/Actions/Actions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function ProductOverviewService(token, asin, integerID) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_PRODUCT_DATA_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/product`, {
            asin: asin,
            integerID: integerID,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadProductData(res.data.productOverview,
                    res.data.campaignsForProduct,
                    {
                        clicks: res.data.clickData,
                        conversions: res.data.conversionData,
                        impressions: res.data.impressionData,
                        sales: res.data.salesData,
                        spend: res.data.spendData,
                        unitsSold: res.data.unitsSoldData,
                    },
                    res.data.keywordTableData,
                    res.data.granularKeywordData,
                    res.data.topKeywords,
                    res.data.impressionGeneratingKeywords,
                    // [],
                    // [],
                    res.data.campaignUpdates,
                    res.data.creditPurchases,
                ));
        })
            .catch(() => {
                dispatch({ type: actionType.LOAD_PRODUCT_DATA_FAILED });
                notify.NotifyError('We\'ve encountered an error while retrieving your product details, please try again later.');
        })
    }
}

export default ProductOverviewService;
