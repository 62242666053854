import React, { Fragment } from 'react';
import {
    FormGroup, Label, Input
} from 'reactstrap';
import { Field, ErrorMessage } from 'formik';

const AmazonProductTitleFeatures = (props) => {

    return (
        <Fragment>
            <FormGroup>
                <Label for="titleId" className="bold">Title <sup className="red">*</sup></Label>
                <Field as="textarea" className="form-control" rows="2" name="title" id="titleId" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="title" />
                </span>
            </FormGroup>
            <FormGroup>
                <Label for="bullet1" className="bold">Feature 1 <sup className="red">*</sup></Label>
                <Input type="text" name="feature1" id="bullet1" tag={Field} placeholder="Feature 1" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="feature1" />
                </span>
            </FormGroup>
            <FormGroup>
                <Label for="bullet2" className="bold">Feature 2 <sup className="red">*</sup></Label>
                <Input type="text" name="feature2" id="bullet2" tag={Field} placeholder="Feature 2" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="feature2" />
                </span>
            </FormGroup>
            <FormGroup>
                <Label for="bullet3" className="bold">Feature 3 <sup className="red">*</sup></Label>
                <Input type="text" name="feature3" id="bullet3" tag={Field} placeholder="Feature 3" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="feature3" />
                </span>
            </FormGroup>
            <FormGroup>
                <Label for="bullet4" className="bold">Feature 4 <sup className="red">*</sup></Label>
                <Input type="text" name="feature4" id="bullet4" tag={Field} placeholder="Feature 4" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="feature4" />
                </span>
            </FormGroup>
            <FormGroup>
                <Label for="bullet5" className="bold">Feature 5 <sup className="red">*</sup></Label>
                <Input type="text" name="feature5" id="bullet5" tag={Field} placeholder="Feature 5" maxLength="200" placeholder="Maximum 200 characters..." />
                <span className="text-danger">
                    <ErrorMessage name="feature5" />
                </span>
            </FormGroup>
        </Fragment>
    )
}

export default AmazonProductTitleFeatures;
