import axios from 'axios';

import { CURRENT_API_URL } from '../../../../Utils/Constants';
import notify from '../../../../Notifications/ToastifyActions';

function EditStoreNameService(token, integerID, oldName, newName) {
  return axios
    .post(
      `${CURRENT_API_URL}/account/amazonAdvertiserProfiles`,
      {
        integerID: integerID,
        oldName: oldName,
        newName: newName,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => {
      notify.NotifySuccess('Your request was successfully processed.');
      return true;
    })
    .catch(() => {
      notify.NotifyError(
        "We've encountered an error trying to edit your store name. Please try again later."
      );
      return false;
    });
}

export default EditStoreNameService;
