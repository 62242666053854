import React, {useState} from 'react';
import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import {Button, Col, Form, Input, Label, Row} from "reactstrap";


const phases = [
    {
        number: 1,
        name: "Swarm Campaigns",
        description: "Focused on analyzing a large range of keywords to learn which opportunities are most successful for your product",
    },
    {
        number: 2,
        name: "Exploration",
        description: "Narrowed targeting to uncover high opportunity keywords that are most likely to result in organic ranking",
    },
    {
        number: 3,
        name: "Ongoing Campaigns and Defense",
        description: "Maximize winning of core keywords and defend organic rankings to box competitors out. Continually narrowing our targets and expanding the opportunities that we assess.",
    },
]


function CampaignLaunchTutorialModal(props) {
    const {
        isOpen,
        setIsOpen,
        hideTutorial,
        setHideTutorial,
    } = props;


    const renderPhase = (phase) => (
        <>
            <Row>
                <Col>
                    <h5 className="text-brand-green" style={{display: "inline-block"}}>Phase {phase.number}</h5>
                    <div className={"text-center"} style={{display: "inline-block", width: "80%"}}>
                        <h4 className="text-primary ml-4" style={{display: "inline-block"}}>{phase.name}</h4>
                    </div>
                    <div className="text-center">
                        <p className="mt-3" style={{fontSize: "large"}}>
                            {phase.description}
                        </p>
                    </div>

                </Col>
            </Row>
            <div className="divider" style={{marginTop: "2.5rem", marginBottom: "2.5rem"}} />
        </>
    );

    const renderFooter = () => (
        <>
            <Form check className="mr-auto ml-3">
                <Label check>
                    <Input
                        type={"checkbox"}
                        checked={hideTutorial}
                        onChange={(e) => setHideTutorial(e.target.checked)}
                    />
                    Don't display this tutorial again
                </Label>
            </Form>
            <Button
                color={"success"}
                onClick={() => setIsOpen(!isOpen)}
            >
                I'm ready to launch my campaign!
            </Button>
        </>
    )


    return (
        <CustomModal
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            size={"lg"}
            header={"1-Click Campaign Overview"}
            footer={renderFooter()}
        >
            {
                phases.map(phase => renderPhase(phase))
            }
            <Row>
                <Col className="text-center">
                    <h5 className="text-dark">Campaigns and keywords are not evergreen. We regularly consider new keyword opportunities to stay ahead of the market.</h5>
                </Col>
            </Row>
        </CustomModal>
    )
}


export default CampaignLaunchTutorialModal;