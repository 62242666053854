import React from 'react';
import ReactTable from "react-table";


function ConvertingKeywordSummaryTable(props) {

    const topConvertingColumns = [
        {
            Header: "Keyword",
            accessor: "keywordText",
        },
        {
            Header: "Initial Rank",
            accessor: "initialRank",
        },
        {
            Header: "Current Rank",
            accessor: "currentRank",
            Cell: cellInfo => (
                <>
                    {cellInfo.value}
                    <div
                        className={cellInfo.original.changeInRank > 0 ? "ml-3 text-success" : cellInfo.original.changeInRank < 0 ? "ml-3 text-danger" : "ml-3 text-warning"}
                    >
                        <i className={cellInfo.original.changeInRank > 0 ? "lnr-chevron-up-circle" : cellInfo.original.changeInRank < 0 ? "lnr-chevron-down-circle" : "lnr-circle-minus"}> </i>
                        {cellInfo.original.changeInRank}
                    </div>

                </>
            )
        },
        {
            Header: "Average Rank",
            accessor: "averageRank",
        },
        {
            Header: "Conversions",
            accessor: "numConversions",
        },
    ];

    const impressionGeneratingColumns = [
        {
            Header: "Keyword",
            accessor: "keywordText",
        },
        {
            Header: "Initial Rank",
            accessor: "initialRank",
        },
        {
            Header: "Current Rank",
            accessor: "currentRank",
            Cell: cellInfo => (
                <>
                    {cellInfo.value}
                    <div
                        className={cellInfo.original.changeInRank > 0 ? "ml-3 text-success" : cellInfo.original.changeInRank < 0 ? "ml-3 text-danger" : "ml-3 text-warning"}
                    >
                        <i className={cellInfo.original.changeInRank > 0 ? "lnr-chevron-up-circle" : cellInfo.original.changeInRank < 0 ? "lnr-chevron-down-circle" : "lnr-circle-minus"}> </i>
                        {cellInfo.original.changeInRank}
                    </div>

                </>
            )
        },
        {
            Header: "Average Rank",
            accessor: "averageRank",
        },
        {
            Header: "Impressions",
            accessor: "numImpressions",
        },
    ];

    const allKeywordsColumns = [
        {
            Header: "Keyword",
            accessor: "keywordText",
        },
        {
            Header: "Initial Rank",
            accessor: "initialRank",
        },
        {
            Header: "Current Rank",
            accessor: "currentRank",
            Cell: cellInfo => (
                <>
                    {cellInfo.value}
                    <div
                        className={cellInfo.original.changeInRank > 0 ? "ml-3 text-success" : cellInfo.original.changeInRank < 0 ? "ml-3 text-danger" : "ml-3 text-warning"}
                    >
                        <i className={cellInfo.original.changeInRank > 0 ? "lnr-chevron-up-circle" : cellInfo.original.changeInRank < 0 ? "lnr-chevron-down-circle" : "lnr-circle-minus"}> </i>
                        {cellInfo.original.changeInRank}
                    </div>

                </>
            )
        },
        {
            Header: "Average Rank",
            accessor: "averageRank",
        },
        {
            Header: "Impressions",
            accessor: "numImpressions",
        },
        {
            Header: "Conversions",
            accessor: "numConversions",
        },
    ];


    return (
        <ReactTable
            className={"-fixed -highlight -striped"}
            columns={props.isTopKeywords ? topConvertingColumns : props.isAllKeywords ? allKeywordsColumns : impressionGeneratingColumns}
            style={{
                height: "500px",
            }}
            data={props.data}
            noDataText={"No keywords found."}
        />

    );
}

export default ConvertingKeywordSummaryTable;