import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import { FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import LeadGenCreateProdCatToolService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenCreateProdCatToolService";
import LeadGenReasearchProdcutCategoryToolService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenReasearchProdcutCategoryToolService";
import CustomLoadingOverlay from "../../../../../Components/Nav/CustomLoadingOverlay";
import ProductsTable from "./Components/ProductsTable";
import SellersTable from "./Components/SellersTable";
import CustomModal from "../../../../../Components/Modals/CustomModal";

import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import Can from "../../../../../Components/Can";

import ProjectsListService from "../../../../../../Service/DashboardServices/UserProfileServices/ProjectsListService";
import ProductCategoriesTable from "./Components/ProductCategoriesTable";
import LeadGenerationToolService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationToolService";
import LeadGenProdCatJobSampleDataService
    from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatJobSampleDataService"; 
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import FetchAmazonDomainsService from "../../../../../../Service/DashboardServices/UserProfileServices/LeadGenerationServices/FetchAmazonDomainsService"
import { DropdownList } from "react-widgets"; 





function LeadGenResearchProudctCategory(props) {


    const [isOpen, setIsOpen] = useState(false);    
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState("profile"); 
    const [productCategory, setproductCategory] = useState('');   
    const [writeHistory,setwriteHistory] = useState('');   
    const [productASIN, setproductASIN] = useState('');
    const [amazonDomain,setamazonDomain] = useState('');
   
    useEffect(() => {
        props.dispatch(FetchAmazonDomainsService(props.token)); 
    }, [])
    

    const handleCreateBackgroundJob = () => {
        if(productCategory){
            if(writeHistory == true){
            props.dispatch(LeadGenCreateProdCatToolService(props.token,productCategory, writeHistory,amazonDomain.Domain_name));}
            else
            {
                props.dispatch(LeadGenCreateProdCatToolService(props.token,productCategory, false,amazonDomain.Domain_name));
            }
        }      
    }
    
    const handleViewSampleData = () => {
        props.dispatch(LeadGenProdCatJobSampleDataService(props.token,productCategory,writeHistory,amazonDomain.Domain_name));
            
        if((productCategory) & (amazonDomain)){
            if(writeHistory == true){
            props.dispatch(LeadGenProdCatJobSampleDataService(props.token,productCategory,writeHistory,amazonDomain.Domain_name));
            }
            else
            {
                props.dispatch(LeadGenProdCatJobSampleDataService(props.token,productCategory,false,amazonDomain.Domain_name));
            }
        }      
    }
    
    const ExportCSV = (csvData, fileName) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        console.log('Download Called')
        const exportToCSV = (csvData, fileName) => {
            console.log(csvData)
            console.log(fileName)
            const ws = XLSX.utils.json_to_sheet(csvData.productList);
            const ws1 = XLSX.utils.json_to_sheet(csvData.sellerList);
            const ws2 = XLSX.utils.json_to_sheet(csvData.productSalesEstimatesList);
            const ws3 = XLSX.utils.json_to_sheet(csvData.top100productAnalysisList);
            const ws4 = XLSX.utils.json_to_sheet(csvData.jobSummaryData); 
            const ws5 = XLSX.utils.json_to_sheet(csvData.jobHistoryData); 
                
            //const wb = { Sheets: { 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2 }, SheetNames: ['productList','sellerList','productSalesEstimatesList'] };                
            const wb = { Sheets: {'jobSummaryData':ws4, 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2,'top100productAnalysisList': ws3,'jobHistoryData':ws5 }, SheetNames: ['jobSummaryData','productList','sellerList','productSalesEstimatesList','top100productAnalysisList','jobHistoryData'] };                
                 
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            console.log('before save')
            FileSaver.saveAs(data, 'SampleResearchData' + fileExtension);   
                           
            };

        exportToCSV(csvData, fileName);
            
        }    
    
    const handleChange=(e)=> {       
        // do whatever you want with isChecked value
        let isChecked = e.target.checked;
        console.log('Checkbox checked:')
        console.log(isChecked)
        setwriteHistory(isChecked)
        console.log('After Set')
        console.log(writeHistory)
      }

    
    
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Research For Product Category</h4>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>
                                    <Row>
                                    <Col md="4" className="mb-2"> 
                                    <DropdownList
                                    data={props.amazonDomains}
                                    dataKey='Domain_name'                    
                                    textField='Country'                   
                                    placeholder="Select Country"
                                    value={amazonDomain}
                                    onChange={(value) => {
                                        setamazonDomain(value);
                                    }}
                                />   
                                
                                    </Col>        
                                    </Row>


                <Row>                    
                <Col md="4" className="mb-2">
                            <Input
                                id="inputProductCategory"
                                placeholder="Enter Product Category ID"
                                value={productCategory}       
                                onChange={(e) =>
                                    setproductCategory(e.target.value)
                                }                   
                            
                            />    
                         
                         </Col>
                </Row>      
                <Row>
                <Col md="9" className="mb-2">
                       
                    <Button
                                    className="mb-3"
                                    color="brand-blue-dark"
                                    onClick={() => handleViewSampleData()}  

                                >
                                    View Sample Data
                                </Button>
                </Col>
                <Col md="9" className="mb-2">      
                     <Button
                                        className="mb-3"
                                        color="brand-blue-dark"
                                        onClick={() => handleCreateBackgroundJob()}  

                                    >
                                        Create Background Job
                                    </Button>                               
                    </Col>
                    </Row>

                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>






                    <Row>
                    <FormGroup check>
                                <Input type="checkbox" name="writeHistory" id="inputwriteHistory"  value={writeHistory} 
                                   onChange={(e) =>
                                    setwriteHistory(e.target.checked)
                                } 
                                                               />
                                <Label for="exampleCheck" check>Write Job History</Label>
                                </FormGroup>

                    </Row>
                    <Row >
                        <h6 className="mt-3 text-primary" color="green">
                        {' '}
                            <b>{props.researchProductCategoryData.message}</b></h6>    
                            </Row>                        
                    <Row >                   
                </Row>      


                    <Row>
                    <Col md="9" className="mb-2">
                        <h4>Sample Market Research Data</h4>
                    </Col>
                </Row>  
                    <Button
                                        className="mb-9"
                                        color="brand-blue-dark"
                                        onClick={() => ExportCSV(props.researchProductCategoryData,'test')}  
                                        
                                    >
                                        Download
                                    </Button>
                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Products List</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <ProductsTable data={props.productList} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>       

                <Row>
                    <Col md="9" className="mb-2">
                        <h4>Sellers List</h4>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <CustomLoadingOverlay active={props.status === 'loading'}>
                            <Card>
                                <CardBody>    
                                    <SellersTable data={props.sellerList} />                                                             
                                </CardBody>
                            </Card>
                        </CustomLoadingOverlay>
                    </Col>
                </Row>       
            </Container>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    sellerList : state.profile.researchProductCategoryData.sellerList,
    amazonDomains:state.profile.amazonDomains,
    productList : state.profile.researchProductCategoryData.productList,
    productCategories : state.profile.productCategories,
    researchProductCategoryData : state.profile.researchProductCategoryData,
    status: state.profile.status,
})

export default connect(mapStateToProps)(LeadGenResearchProudctCategory);
