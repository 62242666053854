import React from 'react';

import WidgetListCard from '../../../../Components/Widgets/WidgetListCard';
import SelectableProductWidget from './SelectableProductWidget';
import SelectableWidget from '../../../../Components/Widgets/SelectableWidget';
import ProductWidget from './ProductWidget';
import {FormatProductWidgetData} from "./WidgetDataFunctions/FormatProductWidgetData";

function SelectableProductWidgetList(props) {
    const widgetsData = props.data.map((product) => {
        return FormatProductWidgetData(product);
        // const {
        //     asin,
        //     itemPrice,
        //     itemImage,
        //     productTitle,
        //     sku,
        //     skus,
        //     itemRating,
        //     ratingVotes,
        //     isManaged,
        //     areKeywordsTracked,
        //     simpliworksManagementState,
        //     readyCampaignsForProduct,
        // } = product;
        //
        // const details = {
        //     ASIN: asin,
        //     // SKU: sku,
        //     price: itemPrice ? `$${itemPrice}` : "Not Available",
        // };
        //
        // return {
        //     productTitle,
        //     productImage: itemImage,
        //     productRating: itemRating,
        //     ratingVotes,
        //     isManaged,
        //     skuList: skus,
        //     managementStatus: simpliworksManagementState,
        //     areKeywordsTracked,
        //     numProductReadyCampaigns: readyCampaignsForProduct,
        //     details,
        // };
    });

    const renderSelectableProductWidget = (product, key) => (
        <SelectableProductWidget
            {...product}
            updateProductManagement={props.updateProductManagement}
            quickLaunch={props.quickLaunch}
            handleQuickActions={props.handleQuickActions}
            toggleSelect={props.toggleSelect}
            listItemKey={key}
            // isSelected={props.selectedProducts.find(p => p.ASIN === product.details.ASIN && p.SKU === product.details.SKU)}
            isSelected={props.selectedProducts.find(
                (p) => p.ASIN === product.details.ASIN
            )}
            accessLevel={props.accessLevel}
            storeIntegerID={props.storeIntegerID}
            token={props.token}
        />
    );

    // const renderSelectableProductWidget = (product, key) => (
    //     <SelectableWidget
    //         toggleSelect={(isSelected) => props.toggleSelect(isSelected, product)}
    //         key={key}
    //         isSelected={props.selectedProducts.includes(product.details.ASIN)}
    //     >
    //         <ProductWidget
    //             { ...product }
    //             updateProductManagement={props.updateProductManagement}
    //             quickLaunch={props.quickLaunch}
    //             handleQuickActions={props.handleQuickActions}
    //         />
    //     </SelectableWidget>
    // );

    return (
        <WidgetListCard
            className=""
            listHeading={props.listHeading}
            widgets={widgetsData}
            renderWidgetListItem={renderSelectableProductWidget}
            card={props.card}
        />
    );
}

export default SelectableProductWidgetList;
