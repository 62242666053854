import React, {useState} from 'react';
import {Row, Col, Input} from 'reactstrap';
import SearchProductWidgetList from "./Widgets/Products/SearchProductWidgetList";


function ProductSelector(props) {

    const [searchProduct, setSearchProduct] = useState('');

    const compareProducts = (product1, product2) => {
        if (product1.productTitle < product2.productTitle) return -1;
        else if (product1.productTitle > product2.productTitle) return 1;
        else return 0;
    }

    let sortedProducts = props.data.sort(compareProducts);

    const filterProducts = () => {
        if (searchProduct) {
            return sortedProducts.filter(product => (
                    product.title.toLowerCase().includes(searchProduct.toLowerCase()) ||
                    product.asin.toLowerCase().includes(searchProduct.toLowerCase())
                )
            );
        } else {
            return sortedProducts;
        }
    }

    return (
        <Row>
            <Col className="mb-3" md="6">
                <Input
                    value={searchProduct}
                    onChange={(e) => setSearchProduct(e.target.value)}
                    placeholder="Search for a product by its title or ASIN"
                />
            </Col>
            <Col md="12">
                <SearchProductWidgetList
                    // data={props.data}
                    data={filterProducts()}
                    handleSelectProduct={props.handleSelectProduct}
                    card={true}
                />
            </Col>
        </Row>
    );
}

export default ProductSelector;