export const appSumoPlans = [
    {
        tierName: "AppSumo Tier 1",
        price: 79,
        numStores: 1,
        numProductFamilies: 5,
        numUsers: 1,
        numSubAccounts: 0,
        hasWhiteLabelReports: false,
    },
    {
        tierName: "AppSumo Tier 2",
        price: 179,
        numStores: 3,
        numProductFamilies: 12,
        numUsers: 3,
        numSubAccounts: 0,
        hasWhiteLabelReports: false,
    },
    {
        tierName: "AppSumo Tier 3",
        price: 299,
        numStores: -1,
        numProductFamilies: 20,
        numUsers: 5,
        numSubAccounts: 0,
        hasWhiteLabelReports: false,
    },
    {
        tierName: "AppSumo Tier 4",
        price: 499,
        numStores: -1,
        numProductFamilies: 40,
        numUsers: -1,
        numSubAccounts: 10,
        hasWhiteLabelReports: false,
    },
    {
        tierName: "AppSumo Tier 5",
        price: 699,
        numStores: -1,
        numProductFamilies: 60,
        numUsers: -1,
        numSubAccounts: 20,
        hasWhiteLabelReports: true,
    },
];