import React, { Fragment, useState, useMemo } from 'react';
import {Container, Row, Col, Button, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';

import CustomModal from "../../../../../../../../Components/Modals/CustomModal";
import Timeline from "../../../../../../../../Components/Timeline";
import CustomTabs from "../../../../../../../../Components/Nav/CustomTabs";
import CustomTabContent from "../../../../../../../../Components/Nav/CustomTabContent";


function ChangelogModal(props) {

    const { changelogs, isOpen, toggle } = props;


    const [activeTab, setActiveTab] = useState("1");

    return (
        <Fragment>
            <CustomModal isOpen={isOpen} toggle={toggle} header={`${props.modalHeader} Changelog`} size="xl">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card tabs={true}>
                                <CardHeader className="tabs-lg-alternate">
                                    <CustomTabs
                                        activeTab={activeTab}
                                        toggle={(tabId) => setActiveTab(tabId)}
                                        // tabList={[
                                        //     <div className="widget-numbers-sm" style={{fontSize: "1.2rem"}}>Campaign Updates</div>,
                                        //     <div className="widget-numbers-sm" style={{fontSize: "1.2rem"}}>Credit Purchases</div>,
                                        // ]}
                                        tabList={changelogs.map(changelog => (
                                            <div className="widget-numbers-sm" style={{fontSize: "1.2rem"}}>{changelog.header}</div>
                                        ))}
                                    />
                                </CardHeader>
                                <CustomTabContent
                                    activeTab={activeTab}
                                    // tabList={[
                                    //     <Row>
                                    //         <Col md="6">
                                    //             <Card className="main-card mt-3 ml-3 mb-3 mr-0">
                                    //                 <CardBody>
                                    //                     <CardTitle>Campaign Creation</CardTitle>
                                    //                     <Timeline style={{height: "60vh"}} data={memoizedCampaignTimelines.creation}/>
                                    //                 </CardBody>
                                    //             </Card>
                                    //         </Col>
                                    //         <Col md="6">
                                    //             <Card className="main-card mt-3 mr-3 mb-3 ml-0">
                                    //                 <CardBody>
                                    //                     <CardTitle>Campaign Optimization</CardTitle>
                                    //                     <Timeline style={{height: "60vh"}} data={memoizedCampaignTimelines.optimization}/>
                                    //                 </CardBody>
                                    //             </Card>
                                    //         </Col>
                                    //     </Row>,
                                    //     <Card className="main-card m-3">
                                    //         <CardBody>
                                    //             <CardTitle>Credit Purchases</CardTitle>
                                    //             <Timeline style={{height: "60vh"}} data={memoizedPurchaseTimeline}/>
                                    //         </CardBody>
                                    //     </Card>,
                                    // ]}
                                    tabList={changelogs.map(changelog => (changelog.body))}
                                    renderTabPane={tab => <Fragment>{tab}</Fragment>}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </CustomModal>
        </Fragment>
    )
}

export default ChangelogModal;