import React, {Fragment, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader, CardTitle,
    Col,
    Container,
    Input,
    Label,
    Row,
} from 'reactstrap';
import {useHistory} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import {connect} from 'react-redux';

import StoreOverviewService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService';
import ProductOverviewService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductOverviewService';
import urls from '../../../../../../Utils/URLs';
import StoreOverviewCard from './Components/Cards/StoreOverviewCard';
import Can from '../../../../../Components/Can';
import StoreTabs from './Components/Nav/StoreTabs';
import LoadingButton from '../../../../../Components/Buttons/LoadingButton';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import EditStoreNameService
    from '../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/EditStoreNameService';
import ProductPerformancePane from "./Components/ProductPerformancePane";
import StorePerformance from "./Components/StorePerformance";
import ProductCampaigns from "./Components/ProductCampaigns";


function MyStoresPageLoki(props) {
    let {storeIntegerID} = props.match.params;

    const [store, setStore] = useState(props.adProfiles[0]);
    const history = useHistory();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [storeName, setStoreName] = useState('');
    const [newStoreName, setNewStoreName] = useState('');


    // toggles product selection for later fetching of performance details
    const toggleSelect = (isSelected, asin, isParentProduct) => {
        console.log('In toggleSelect');
        console.log('isParentProduct');
        console.log(isParentProduct);
        console.log('isSelected');
        console.log(isSelected);
        console.log('asin');
        console.log(asin);
        console.log('selectedProducts')
        console.log(selectedProducts)
        if (isSelected) {
            if (isParentProduct) {
                setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}]); // if parent product selected, clear selectedProducts array and add parent product and dummy child product
            } else {
                if (selectedProducts.length > 0 && selectedProducts[0].isParentProduct) {   // if parent product is the only selection
                    setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, ...prevState])
                } else if (selectedProducts.length > 1 && selectedProducts[1].isParentProduct) {    // if a child product was already selected
                    setSelectedProducts(prevState => [{ASIN: asin, isParentProduct: isParentProduct}, prevState[1]])
                } else {
                    setSelectedProducts([{ASIN: asin, isParentProduct: isParentProduct}])   // no previous selection
                }
            }
        } else {
            if (isParentProduct) {
                setSelectedProducts([]); // clears array if parent product is unselected
            } else {
                setSelectedProducts(prevState => prevState.filter(product => product.ASIN !== asin));   // removes product with given ASIN from selectedProducts array
            }
        }
        console.log('selectedProducts')
        console.log(selectedProducts)
    };

    // handles waiting for store list data and properly rendering page upon visiting
    useEffect(() => {
        // redirect to first store in submenu if no store was selected
        if (!storeIntegerID && store) {
            history.push(
                `${urls.ACCOUNT_OVERVIEW_MY_STORES_URL}/${store.integerID}`
            );
        } else if (props.adProfiles && storeIntegerID) {
            setStore(
                props.adProfiles.find(
                    (profile) => profile.integerID == storeIntegerID
                )
            );
        } else if (props.adProfiles) {
            setStore(props.adProfiles[0]);
        }
    }, [history, store, props.adProfiles, storeIntegerID]);

    useEffect(() => {
        if (store) {
            props.dispatch(
                StoreOverviewService(
                    props.token,
                    store.integerID,
                    store.details.accountID
                )
            );
            setStoreName(store.name);
        }
    }, [props.token, store]);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            // if selected product is not a parent product get rank data from product overview service
            if (!selectedProducts[0].isParentProduct) {
                props.dispatch(
                    ProductOverviewService(
                        props.token,
                        selectedProducts[0].ASIN,
                        store.integerID
                    )
                );
            }

            // if store is linked to MRP get MRP data for selected product
            // if (props.storeOverview.linkedToMRP) {
            //     if (selectedProducts[0].isParentProduct) {
            //         props.dispatch(
            //             ParentProductMRPDataService(
            //                 props.token,
            //                 selectedProducts[0].ASIN,
            //                 7
            //             )
            //         );
            //     } else {
            //         props.dispatch(
            //             ChildProductMRPDataService(
            //                 props.token,
            //                 selectedProducts[0].ASIN,
            //                 7
            //             )
            //         );
            //     }
            // }
        }
    }, [selectedProducts]);

    useEffect(() => {
        setSelectedProducts([]);
    }, [store]);


    const renderStoreDetails = () => (
        <Fragment>
            <Col md="6">
                <Col className="mb-3" md="12">
                    <Card>
                        <CardBody>
                            <StoreOverviewCard
                                profile={store}
                                weeklyReport={props.weeklyReport}
                                ProductPerformanceReport={props.ProductPerformanceReport}
                                WeeklySalesReport ={props.WeeklySalesReport}
                                TargetPerformanceReport={props.TargetPerformanceReport}
                                CampaignPerformanceReport={props.CampaignPerformanceReport}
                                WeeklySearchTermReport={props.WeeklySearchTermReport}
                                overviewData={props.storeOverview}
                                token={props.token}
                                accessLevel={props.accessLevel}
                            />
                        </CardBody>
                    </Card>
                </Col>
                {/*<Col className="mb-3" md="12">*/}
                {/*    <Card>*/}
                {/*        <CardBody>*/}
                {/*            <StorePerformance*/}
                {/*                integerID={store.integerID}*/}
                {/*            />*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*{*/}
                {/*    props.storeOverview.linkedToMRP &&*/}
                {/*    <Col className="mb-3" md="12">*/}
                {/*        <Card>*/}
                {/*            <CardBody>*/}
                {/*                <StorePerformance*/}
                {/*                    integerID={store.integerID}*/}
                {/*                />*/}
                {/*            </CardBody>*/}
                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*}*/}
                {/*{*/}
                {/*    !props.storeOverview.linkedToMRP &&*/}
                {/*    <Col className="mb-3" md="12">*/}
                {/*        <Card>*/}

                {/*            <CardBody>*/}
                {/*                <h5 className="text-primary">Performance Reporting</h5>*/}
                {/*                Authorize MyRealProfit for simplified store and product performance reporting, giving you the insights you need for what you do best.*/}
                {/*            </CardBody>*/}
                {/*        </Card>*/}
                {/*    </Col>*/}
                {/*}*/}
                <Col md="12">
                    <StoreTabs
                        accessLevel={props.accessLevel}
                        storeIntegerID={store.integerID}
                        storeAccountID={store.details.accountID}
                        toggleSelect={toggleSelect}
                        selectedProducts={selectedProducts}
                    />
                </Col>
            </Col>
            <Col md="6" style={{paddingLeft: 0, paddingRight: 0}}>
                {selectedProducts.length > 0 ? (
                    renderProductPerformance()
                ) : (
                    <Card style={{position: 'sticky', top: '100px'}}>
                        <CardBody>
                            <h5>
                                Select a store product to view its
                                performance.
                            </h5>
                        </CardBody>
                    </Card>
                )}
            </Col>
        </Fragment>
    );

    const renderProductPerformance = () => (
        <div
            className="productPerformance"
            style={{
                position: 'sticky',
                top: '100px',
                height: '80vh',
                overflowY: 'auto',
                marginBottom: '15px',
                paddingLeft: '15px',
                paddingRight: '15px',
            }}
        >
            <ProductPerformancePane
                accessLevel={props.accessLevel}
                linkedToMRP={props.storeOverview.linkedToMRP}
                // mrpData={props.mrpData}
                productOverview={props.productOverview}
                selectedProducts={selectedProducts}
            />
            {(!props.productLoading && !selectedProducts[0].isParentProduct) && (
                <Can
                    role={props.accessLevel}
                    perform={'campaigns:view'}
                    yes={() => (
                        <Fragment>
                            <h4 className="mbg-3">Product Campaigns</h4>
                            <Row className="mb-5">
                                <Col md="12">
                                    <ProductCampaigns
                                        productASIN={props.productOverview.asin}
                                        campaigns={props.productCampaigns}
                                        onCampaignStageUpdate={() =>
                                            props.dispatch(
                                                ProductOverviewService(
                                                    props.token,
                                                    props.productOverview.asin,
                                                    store.integerID
                                                )
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                />
            )}
        </div>
    );

    const renderFailureMessage = () => (
        <Col md="12">
            <h5>
                We've encountered a problem while loading your store
                information. Please try again later.
            </h5>
        </Col>
    );

    const renderNoStoresMessage = () => (
        <Col md="12">
            <h5>
                No stores found. Newly registered Simpliworks accounts can take
                up to 24 hours to load store information.
            </h5>
        </Col>
    );

    if (props.adProfiles.length === 0) {
        return renderNoStoresMessage();
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const showModal = (storeName) => {
        setNewStoreName(storeName);
        toggleModal();
    };

    const handleEditStoreName = async (
        integerID,
        oldName,
        newName,
        storeAccountID
    ) => {
        let response = await EditStoreNameService(
            props.token,
            integerID,
            oldName,
            newName
        );
        if (response) {
            setStoreName(newName);
            props.dispatch(
                StoreOverviewService(props.token, integerID, storeAccountID)
            );
            toggleModal();
        }
        return response;
    };

    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row>
                        <Col md="12" className="mbg-3 font-size-xlg">
                            {store && store.logo ? (
                                <img
                                    className="mr-2"
                                    width={100}
                                    src={store.logo}
                                    alt=""
                                />
                            ) : (
                                <i
                                    className="lnr-store icon-gradient bg-simpli-gradient mr-2"
                                    style={{fontSize: '40px'}}
                                ></i>
                            )}
                            <h5
                                className="text-brand-blue-dark"
                                style={{
                                    display: 'inline',
                                    fontSize: '2.3rem',
                                }}
                            >
                                {store ? storeName : ''}
                            </h5>
                            {/* Edit Store Name */}
                            <Button
                                className="mb-1 ml-2"
                                color="link"
                                onClick={() => showModal(store.name)}
                            >
                                Edit
                            </Button>

                            <CustomModal
                                header="Edit Store Name"
                                isOpen={isModalOpen}
                                toggle={toggleModal}
                            >
                                <Row>
                                    <Col className="mb-3" md="12">
                                        <Label for="newStoreName">
                                            Store Name
                                        </Label>
                                        <Input
                                            id="newStoreName"
                                            placeholder="Enter new store name"
                                            value={newStoreName}
                                            onChange={(e) =>
                                                setNewStoreName(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{offset: 4, size: 4}}>
                                        <LoadingButton
                                            color="primary"
                                            onClick={async () => {
                                                setLoading(true);
                                                await handleEditStoreName(
                                                    storeIntegerID,
                                                    store.name,
                                                    newStoreName,
                                                    store.details.accountID
                                                );
                                                setLoading(false);
                                            }}
                                            loading={loading}
                                        >
                                            Edit Store name
                                        </LoadingButton>
                                    </Col>
                                </Row>
                            </CustomModal>

                            {/*<Can*/}
                            {/*    role={props.accessLevel}*/}
                            {/*    perform={"changelog:view"}*/}
                            {/*    yes={() => <ChangelogModal*/}
                            {/*        campaignUpdates={props.storeCampaignUpdates}*/}
                            {/*        creditPurchases={props.storeCreditPurchases}*/}
                            {/*        modalHeader={props.storeOverview.name}*/}
                            {/*        buttonLabel={"View Store Changelog"}*/}
                            {/*    />}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                    <LoadingOverlay
                        active={props.storeLoading}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#f1f4f6',
                                opacity: 1,
                            }),
                            content: (base) => ({
                                ...base,
                                color: '#000',
                            }),
                        }}
                        spinner={<Loader active type="ball-pulse"/>}
                        text="Loading store details..."
                    >
                        <Row>
                            {props.status === 'success'
                                ? renderStoreDetails()
                                : renderFailureMessage()}
                        </Row>
                    </LoadingOverlay>
                    {/*{ renderAddCampaignModal() }*/}
                    {/*{ renderProductManagementModal() }*/}
                </Container>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    token: state.loggedUser.token,
    adProfiles: state.accountOverview.landing.adProfiles,
    accessLevel: state.loggedUser.accessLevel,
    storeOverview: state.accountOverview.store.storeOverview,
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    WeeklySearchTermReport:state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport:state.accountOverview.store.CampaignPerformanceReport,
    sellerFeedback: state.accountOverview.store.sellerFeedback,
    storeProducts: state.accountOverview.store.storeProducts,
    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
    metricData: state.accountOverview.store.metricData,
    storeCampaignUpdates: state.accountOverview.store.campaignUpdates,
    storeCreditPurchases: state.accountOverview.store.creditPurchases,

    productOverview: state.accountOverview.product.productOverview,
    parentProductKeywordData: state.accountOverview.product.parentProductKeywordData,
    keywordTableData: state.accountOverview.product.keywordTableData,
    topKeywords: state.accountOverview.product.topKeywords,
    impressionGeneratingKeywords: state.accountOverview.product.impressionGeneratingKeywords,
    mrpData: state.accountOverview.product.mrpData,
    isProductManaged: state.accountOverview.product.productOverview.isManaged,
    productLoading: state.accountOverview.product.loading,
    productCampaigns: state.accountOverview.product.productCampaigns,
    productCampaignUpdates: state.accountOverview.product.campaignUpdates,
    productCreditPurchases: state.accountOverview.product.creditPurchases,
});

export default connect(mapStateToProps)(MyStoresPageLoki);
