import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import './agencyView.css';
import AmazonProjectPreview from './AmazonProjectPreview/AmazonProjectPreview';
import { Button } from 'reactstrap'
import { getProjectDetailsForPreview } from '../../../../../Service/DashboardServices/Projects/ProjectsService';
import actionTypes from '../../../../../Redux/Actions/ActionTypes';
import Can from "../../../../Components/Can";


const CustomerProjectView = ({ token, accessLevel, storeDetails, dispatch }) => {

    const { id, storeId } = useParams();
    const [loading, setLoading] = useState(true)
    const [projectData, setProjectData] = useState(null)

    useEffect(() => {
      
        if (id > 0) {

        }
        if (storeDetails.length > 0 && storeId > 0) {
            getProjectDetailsData()
            dispatch({
                type: actionTypes.SET_CURRENT_STORE_ONLY,
                payload: {
                    id: storeId
                }
            })
        }
    }, [id, storeDetails])

    const getProjectDetailsData = () => {
        let payload = {
            project_id: id,
            store_id: storeId
        }
        getProjectDetailsForPreview(token, payload)
            .then(data => {
                setLoading(false)
                setProjectData(data)
            })
            .catch(err => {
                setLoading(false)
            })
    }


    const refreshData = () => {
        setLoading(true);
        if (id > 0) {
            setProjectData(null)
            getProjectDetailsData()
        }
        else {
            setLoading(false);
        }
    }

    const getErrorPage = () => {
        return (
            <div className="jumbotron text-center">
                <h4 className="display-4">Invalid Access</h4>
                <p className="lead">You don't have permissions to access this page right now. Please contact your administrator</p>
                <hr />
                <p>
                    Having trouble? <a href="">Contact us</a>
                </p>
                <p className="lead" >
                    Go to: <Button tag={Link} type="button" color="primary" size="sm" to={`/project/${storeId}`}>View Projects</Button>
                </p>
            </div>
        )
    }

    return (
        <div>
            <Can
                role={accessLevel}
                perform={"project:review"}
                yes={() => (
                    (!loading && projectData) &&
                    <AmazonProjectPreview token={token} id={id} accessLevel={accessLevel} storeId={storeId} data={projectData} refreshData={refreshData} />
                )}
                no={() => (
                    <Fragment>
                        {!loading && getErrorPage()}
                    </Fragment>
                )}
            />
            {/*{*/}
            {/*    (!loading && projectData && accessLevel === 3) ?*/}
            {/*        <AmazonProjectPreview token={token} id={id} accessLevel={accessLevel} storeId={storeId} data={projectData} refreshData={refreshData} />*/}

            {/*        :*/}
            {/*        <Fragment>*/}
            {/*            {!loading && getErrorPage()}*/}
            {/*        </Fragment>*/}

            {/*}*/}
        </div>
    )
}


const mapToProps = (state) => {
    return {
        currentStore: state.project.currentStore,
        token: state.loggedUser.token,
        storeDetails: state.project.storeDetails,
        accessLevel: state.loggedUser.accessLevel
    }
}



export default connect(mapToProps)(CustomerProjectView);
