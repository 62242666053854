import React, {Fragment, useEffect, useState} from "react";
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders';
import StoreListService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
import StoreWidgetList from "./Components/Widgets/StoreWidgetList";
import StoreModal from "./Components/Modals/StoreModal";
import StoreTable from "./Components/Tables/StoreTable";


function MyStoresPage(props) {
    
    const [view, setView] = useState('table');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState('');

    // useEffect(() => {
    //     props.dispatch(StoreListService(props.token))
    // }, []);

    const widgetView = () => {
        setView('widget');
    }

    const tableView = () => {
        setView('table');
    }

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const showModal = (profile) => {
        setSelectedProfile(profile);
        toggleModal();
    }

    return (
        <Fragment>
            <div>
                <Container fluid>
                    <Row>
                        <Col md="11">
                            <h4 className="mbg-3">My Stores</h4>
                        </Col>
                        <Col md="1">
                            <ButtonGroup size="sm">
                                <Button
                                    className="btn-icon btn-icon-only"
                                    color="secondary"
                                    size="sm"
                                    onClick={widgetView}
                                >
                                    <i
                                        className="lnr-layers btn-icon-wrapper"
                                        style={{fontSize: '20px'}}
                                    >
                                    </i>
                                </Button>
                                <Button
                                    className="btn-icon btn-icon-only"
                                    color="secondary"
                                    size="sm"
                                    onClick={tableView}
                                >
                                    <i
                                        className="lnr-list btn-icon-wrapper"
                                        style={{fontSize: '20px'}}
                                    >
                                    </i>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {!props.loading &&
                                <p className="text-primary">You are currently using <b>{props.totalSpend}</b> credits per day across all stores.</p>
                            }
                            { view === 'table' ?
                                <Card className="mb-4">
                                    <CardBody>
                                        <LoadingOverlay
                                            active={props.loading}
                                            styles={{
                                                overlay: (base) => ({
                                                    ...base,
                                                    background: '#fff',
                                                    opacity: 0.5
                                                }),
                                                content: (base) => ({
                                                    ...base,
                                                    color: "#000"
                                                })
                                            }}
                                            spinner={<Loader active type="ball-pulse"/>}
                                            text='Loading your profiles...'
                                        >
                                            <StoreTable data={props.adProfiles} showModal={showModal} />
                                        </LoadingOverlay>
                                    </CardBody>
                                </Card>
                                :
                                <LoadingOverlay
                                    active={props.loading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: '#f1f4f6',
                                            opacity: 1
                                        }),
                                        content: (base) => ({
                                            ...base,
                                            color: "gray"
                                        })
                                    }}
                                    spinner={<Loader active type="ball-pulse"/>}
                                    text='Loading your Seller Profiles...'
                                >
                                    <StoreWidgetList data={props.adProfiles} showModal={showModal} card={true}/>
                                </LoadingOverlay>

                            }
                            <StoreModal
                                isOpen={isModalOpen}
                                toggle={toggleModal}
                                profile={selectedProfile}
                                token={props.token}
                            />
                        </Col>
                    </Row>                    
                </Container>
            </div>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        token: state.loggedUser.token,
        status: state.accountOverview.landing.status,
        loading: state.accountOverview.landing.loading,
        readyCampaigns: state.accountOverview.landing.readyCampaigns,
        adProfiles: state.accountOverview.landing.adProfiles,
        totalSpend: state.accountOverview.landing.totalSpend,
    }
}

export default connect(mapStateToProps)(MyStoresPage);
