import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Input } from 'reactstrap';

import AreaChart from "./AreaChart";


function ProductAnalyticsChart(props) {

    const noData = {
        text: "No data available.",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
        }
    };

    const [selectedMetric, setSelectedMetric] = useState(props.defaultMetric);

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <Input
                        type="select"
                        name="selectMetric"
                        value={selectedMetric}
                        onChange={(e) => setSelectedMetric(e.target.value)}
                        style={{maxWidth: "fit-content", border: '0px'}}
                    >
                        <option value="sales">Sales</option>
                        <option value="spend">Spend</option>
                        <option value="unitsSold">Units Sold</option>
                        <option value="conversions">Conversions</option>
                        <option value="impressions">Impressions</option>
                        <option value="clicks">Clicks</option>
                    </Input>
                </CardHeader>
                <CardBody className="p-0">
                    <AreaChart
                        series={props.metricData[selectedMetric]}
                        noData={noData}
                    />
                </CardBody>
            </Card>
        </Fragment>
    );
}

export default ProductAnalyticsChart;