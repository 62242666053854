import React from 'react';
import {Button, Card, CardBody, CardTitle} from "reactstrap";


function ProductBudgetAllocationCard(props) {

    const {
        unallocatedBudget,
    } = props;

    const renderRemainingBudget = () => {
        if (unallocatedBudget !== 0) {
            return (
                <>
                    <p
                        className={`mr-2 widget-numbers-sm text-${unallocatedBudget < 0 ? "danger" : "brand-green"}`}
                        style={{display: "inline-block"}}
                    >
                        ${unallocatedBudget.toFixed(2)}
                    </p>
                    <h5 style={{display: "inline-block"}}>Remaining</h5>
                </>
            );
        } else {
            return (
                <h5
                    style={{display: "inline-block"}}
                >
                    Budget is fully allocated
                </h5>
            )
        }
    }

    return (
        <Card>
            <CardBody>
                <CardTitle>Allocate Budget</CardTitle>
                {
                    (unallocatedBudget === undefined) ?
                        <h5>
                            Not Available
                        </h5>
                        :
                        renderRemainingBudget()
                }
                <Button
                    className="ml-4"
                    style={{display: "inline-block"}}
                    color={"brand-blue-dark"}
                    disabled={unallocatedBudget === undefined}
                >
                    {unallocatedBudget > 0 ? "Allocate" : "Reallocate"}
                </Button>
            </CardBody>
        </Card>
    )
}


export default ProductBudgetAllocationCard;