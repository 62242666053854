import axios from 'axios';
import actionType from '../../../../Redux/Actions/ActionTypes';
import {LoadStoreData, LoadStoreSalesData} from '../../../../Redux/Actions/Actions';
import { CURRENT_API_URL } from '../../../../Utils/Constants';

function StoreSalesService(token, integerID, daysBack) {
	return function(dispatch) {
		dispatch({ type: actionType.LOAD_STORE_SALES_DATA_BEGIN });

		axios.post(
				`${CURRENT_API_URL}/myRealProfit/getSalesData`,
				{
					integerID: integerID,
					daysBack: daysBack,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((res) => {
				dispatch(LoadStoreSalesData(res.data.salesData))
			})
			.catch(() => {
				dispatch({ type: actionType.LOAD_STORE_SALES_DATA_FAILED });
			});
	};
}

export default StoreSalesService;
