import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionType from '../../../Redux/Actions/ActionTypes';
import { LoadProductCategories } from "../../../Redux/Actions/Actions";
import { LoadResearchProductCategoryData } from "../../../Redux/Actions/Actions";


function LeadGenCreateProdCatToolService(token, productCategory,writeHistory,amazonDomain) {

    return function(dispatch) {

        dispatch({ type: actionType.LOAD_PRODUCT_CATEGORIES_BEGIN });

        axios.get(`${CURRENT_API_URL}/leadGeneration/FetchAndSaveAllProductsSellersForCategoryTask/${productCategory}/${writeHistory}/${amazonDomain}`,
            {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            console.log(res.data)        
            dispatch(LoadResearchProductCategoryData(res.data))
        }).catch(() => {
            dispatch({ type: actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED });

        });
    }
}

export default LeadGenCreateProdCatToolService;