import React, { Fragment, useState } from 'react';

import WidgetLayout from "./WidgetLayout";
import WidgetDetailsCollapsible from "./WidgetDetailsCollapsible";


/**
 * Props:
 * image (optional) - src link for an image. Will be displayed on the left side of the widget.
 * defaultIcon (optional) - the default icon to display in place of the image if it is not provided.
 * heading - the title text displayed in the widget.
 * subheading - supporting text displayed beneath the heading.
 * content (optional) - component or text displayed on the right side of the widget.
 * collapsible - set true if widget should be clickable to expand a view containing more details.
 * details (required if collapsible is true) - array of dictionaries each containing "heading" and "value" properties. Every dictionary will be displayed
 * as a separate list item in the collapsible view.
 * actions - array of callback functions that each render a component. All components will be displayed below the details list in the collapsible view
 * autoFormat (optional) - boolean that indicates whether this component should automatically format detail list headings in the collapsible view
 * nestedList (optional) - widget list to display in the collapsible section of the widget
 * controlledCollapse (optional) - true if widget details expansion/collapse is externally controlled
 * isOpen - true if the widget details should be open, false if closed. Required prop if controlledCollapse is true.
 */
function CustomWidget(props) {

    // controls open/close state of collapsible detail section of widget
    const [isOpen, setIsOpen] = useState(false);
   
const renderActionItemsIcons = (actions) => {
    return (
        <WidgetLayout>
            <div className="widget-content-right">
                {actions.map( (action, i) => action(i) )}
            </div>
        </WidgetLayout>
    );
}
  

    const toggle = () => props.collapsible && setIsOpen(!isOpen);

    return (
        <Fragment>
               
            { props.newActions && renderActionItemsIcons(props.newActions) }
            <WidgetLayout className={props.className || "mb-2 widget-content p-0"} onClick={props.controlledCollapse ? () => {} : toggle}>
             
                            
                { props.image ?
                    <div className="widget-content-left mr-3">
                        <img width={100} src={props.image}
                             alt=""/>
                    </div>
                :
                    props.defaultIcon &&
                    <div className="widget-content-left mr-3">
                        {props.defaultIcon}
                    </div>
                }

                <div className="widget-content-left">
                    <div className="widget-heading">
                        <a>{props.heading}</a>
                        
                    </div>
                    <div className="widget-subheading">
                        {props.subheading}
                        {(props.collapsible && props.details.link) ? <a href={(props.collapsible && props.details.link) ? props.details.link :"" } target="_blank">Visit on Amazon</a> : ''}
                    </div>
                </div>
                <div className="widget-content-right mr-2">
                    {props.content}
                </div>
                { props.collapsible &&
                    <div className="widget-content-right">
                        <div style={{fontSize: "2rem", marginBottom: "-7px"}}>
                            { (props.isOpen || isOpen) ?
                                <i className="pe-7s-angle-down font-weight-bold"> </i>
                                :
                                <i className="pe-7s-angle-right font-weight-bold"> </i>
                            }
                        </div>
                    </div>
                }
            </WidgetLayout>
            { props.collapsible &&
                <WidgetDetailsCollapsible
                    isOpen={props.controlledCollapse ? props.isOpen : isOpen}
                    details={props.details}
                    nestedList={props.nestedList}
                    actions={props.actions}
                    autoFormat={props.autoFormat}
                />
            }
        </Fragment>
    );
}

export default CustomWidget;
