import React, { Fragment, useEffect, useState } from 'react'
import { connect } from "react-redux";
// import  StoreHealtService  from '../../../../../../Service/OPSServices/StoreHealtServices/StoreHealtService';
import { propTypes } from 'react-widgets/lib/Calendar';
import StoreHealthService from '../../../../../../Service/OPSServices/StoreHealthServices/StoreHealthService';
import { Container, Col, Row, Card, Input, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ReactTable from 'react-table';
import { values } from 'lodash';
import notify from '../../../../../../../src/Notifications/ToastifyActions';
import StoreHealthUpdateActiveFlagService from '../../../../../../Service/OPSServices/StoreHealthServices/StoreHealthUpdateActiveFlagService';
import CustomModal from '../../../../../Components/Modals/CustomModal';
import AddRecipientService from '../../../../../../Service/OPSServices/StoreHealthServices/AddRecipientService';
import EditRecipientDataService from '../../../../../../Service/OPSServices/StoreHealthServices/EditRecipientDataService';
import GetManagedStoreDataService from '../../../../../../Service/OPSServices/StoreHealthServices/GetManagedStoreDataService';
import CampaignLaunchStatusTable from './Components/CampaignLaunchStatusTable';
import { CampaignLaunchStatus } from '../../../../../../Service/OPSServices/CampaignLaunchServices';
import FetchProductsForStores from '../../../../../../Service/DashboardServices/UserProfileServices/FetchProductsForStores';
import RemoveProductService from '../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/RemoveProductService';
import UpdateSKUForAsinStoreService from '../../../../../../Service/DashboardServices/UserProfileServices/UpdateSKUForAsinStoreService';
function StoreHealthMainPage(props) {
  const [report, setReport] = useState('1');
  const [storeHealthData, setStoreHealthdata] = useState([])
  const [managedStoreData, setManagedStoreData] = useState([])
  const [activeValue, setActiveValue] = useState([]);
  const [serachTeaxt, setSerachTeaxt] = useState('')
  const [addRecipientPopup, setAddRecipientPopup] = useState(false)
  const [emailRecipient, setEmailRecipient] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isStoresAsinOpen, setIsStoresAsinOpen] = useState(false);
  const [managedProducts, setManagedProducts] = useState();

  const [sellerCentralAccessIds, setSellerCentralAccessIds] = useState()
  const [selectedSCAID, setSelectedSCAID] = useState()
  const [editRecipientPopup, setEditRecipientPopup] = useState(false)
  const [text, setText] = useState()
  const [isOpenCampaignDetails, setIsOpenCampaignDetails] = useState()
  const [campaignLaunchStatus, setCampaignLaunchStatus] = useState([]);
  const [editEmailRecipientData, setEditEmailRecipientData] = useState({
    id: '',
    sellerCentralAccessId: '',
    recipientEmail: '',
    isActive: ''
  })
  const [selectedStoreData, setSelectedStoreData] = useState({
    integerId: "",
    storeName: "",
  });
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  useEffect(() => {
    StoreHealthService(props.token).then((res) => {
      if (res) {
        console.log(res)
        const activeIds = res.storeHeath
          .filter(item => item.isActive)
          .map(item => item.id);
        setActiveValue(activeIds);
        setSellerCentralAccessIds(res.sellerCentralAccessIds)
        setStoreHealthdata(res.storeHeath);
      }
    })

  }, [])
  const getManagedStoreData = () =>{

    GetManagedStoreDataService(props.token).then((res)=>{
      if(res)
      {
        console.log(res)
        setManagedStoreData(res.storeData)
      }
    })
  }
  const fetchCampaignLaunchStatus = (row) => {
    setIsOpenCampaignDetails(true)
    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 30);

    setDateRange({
      startDate: pastDate.toISOString().split('T')[0],
      endDate: currentDate.toISOString().split('T')[0]
    });

    let data = {
        startDate: '', // dateRange.startDate,
        endDate: '',//dateRange.endDate,
        integerId:row.integerId
    };

    // if (isProduct) {
    //     data["asin"] = asin;
    // } else {
    //     data["integerId"] = integerId;
    // }

    CampaignLaunchStatus(props.token, data).then(launchStatus => {
        if (launchStatus) {
            setCampaignLaunchStatus(launchStatus);
        }
    })

    // setLoading(false);
}
  const handleCheckboxChange = (id) => {
    let data;
    if (activeValue.includes(parseInt(id))) {
      data = {
        id: id,
        flag: 0
      }
      setActiveValue(activeValue.filter((item) => item !== id));
      StoreHealthUpdateActiveFlagService(props.token, data)
    } else {
      data = {
        id: id,
        flag: 1
      }
      setActiveValue([...activeValue, id]);
      StoreHealthUpdateActiveFlagService(props.token, data)
    }
  };
  const handleEditEmailRecipient = (intergerId, sellerCentralAccessId, id, recipientEmail, isActive) => {
    setEditEmailRecipientData(
      {
        id: id,
        sellerCentralAccessId: sellerCentralAccessId,
        recipientEmail: recipientEmail,
        isActive: isActive
      }
    )
    setEditRecipientPopup(true)
  }

  const recipientsReportData1 = [
    // {
    //   Header: 'Integer ID',
    //   accessor: 'integerID',
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       <p onClick={()=>handleEditEmailRecipient(cellInfo.value
    //         ,cellInfo.original.sellerCentralAccessId
    //         ,cellInfo.original.id
    //         ,cellInfo.original.recipientEmail
    //         ,cellInfo.original.isActive)} className="m-0 cursor-pointer">{cellInfo.value}</p>
    //     </div>
    //   ),
    // },
    {
      Header: 'Store Name',
      accessor: 'storeName',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p onClick={() => handleEditEmailRecipient(cellInfo.value
            , cellInfo.original.sellerCentralAccessId
            , cellInfo.original.id
            , cellInfo.original.recipientEmail
            , cellInfo.original.isActive)} className="m-0 cursor-pointer">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Recipient Email',
      accessor: 'recipientEmail',
    },
    {
      Header: 'Seller Central Access Id',
      accessor: 'sellerCentralAccessEmailId',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Is Active',
      accessor: 'isActive',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <input
            type="checkbox"
            value={cellInfo.original.id}
            name={cellInfo.original.id}
            checked={activeValue.includes(cellInfo.original.id) ? true : false}
            onChange={() => handleCheckboxChange(cellInfo.original.id)}
          />
        </div>
      ),
    },
  ]
  const managedStoreColumn = [

    {
      Header: 'Integer ID',
      accessor: 'integerId',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Store Name',
      accessor: 'storeName',
    },
    // {
    //   Header: 'Products',
    //   Cell: props => productQuickActionsDropdown(props.original),
    //   filterable: false,
    // },
    // {
    //   Header: 'Reports',
    //   Cell: (props) => quickActionsDropdown(props.original),
    //         filterable: false,
    // },
    {
      Header: 'Total Asins',
      accessor: 'totalAsins',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    
    },
    {
      Header: 'Managed Asins',
      accessor: 'totalManagedAsins',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Campaigns',
      accessor: 'totalCampaigns',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100" onClick={()=>fetchCampaignLaunchStatus(cellInfo.original)}>
          <p className="m-0 cursor-pointer">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'SC Access Id',
      accessor: 'scAccessId',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
  ]
  const recipientsReportData3 = [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: 'Integer ID',
      accessor: 'integerID',
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },

    {
      Header: 'Recipient Email',
      accessor: 'recipientEmail',
    },
    {
      Header: 'Store Name',
      accessor: 'storeName',
    },
  ]
  const subInnerColumns = [
    {
        Header: 'Asin',
        accessor: 'asin',
    },
    {
        Header: 'Name',
        accessor: 'productTitle',
    },      
    {
        Header: 'Marketplace',
        accessor: 'location',
    },       
    {
        Header: 'Price',
        accessor: 'itemPrice',
    },
    {
        Header: 'Rating',
        accessor: 'itemRating',
    },
    {
        Header: 'Is Managed',
        accessor: 'simpliworksManagementState',
    }, 
    {
        Header: 'Actions',
        accessor: 'asin',
        Cell: props => (
            <Fragment>                  
                <Button
                    className=""  
                    color="link"
                    onClick={() => {
                        handleRemoveProduct(props.value,props.original.integerID);
                    }}
                >
                    Delete
                </Button>
            </Fragment>
        )
    },
    {
        Header: 'Skus',
        accessor: 'skus',        
    },
    {
        Header: 'Skus',
        accessor: 'skus',
        Cell: props => (           
            <Fragment>                    
                <Button
                    className="m-auto"
                    color="link"
                    onClick={() => {                                                       
                        handleUpdateSkus(props.original.asin,props.original.IntegerId);                                                      
                    }}
                >
                    Update 
                </Button>                 
            </Fragment>
        )
    }, 
    {
        Header: 'Link',
        accessor: 'link',
        Cell: props =><a          
                        href={props.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    > 
                        Click here 
                    </a>
        
    }, 
];
  const handleSearchGrid = (e) => {
    setSerachTeaxt(e.target.value)
  }
  const handaleFilterStoreHealth = storeHealthData.filter((row) => {
    const storeName = (row.storeName || '').toLowerCase();
    const recipient = (row.recipientEmail || '').toLowerCase();
    const sellerCentralAccessEmailId = (row.sellerCentralAccessEmailId || '').toLowerCase();
    const integerId = (row.integerID || '').toString().toLowerCase();
    return integerId.includes(serachTeaxt.toString().toLowerCase()) || storeName.includes(serachTeaxt.toLowerCase()) || recipient.includes(serachTeaxt.toLowerCase()) || sellerCentralAccessEmailId.includes(serachTeaxt.toLowerCase())
  })
  const filterManagedStoreData = managedStoreData.filter((row) => {
    const storeName = (row.storeName || '').toLowerCase();
    const integerId = (row.integerId || '').toString().toLowerCase();
    return integerId.includes(serachTeaxt.toString().toLowerCase()) || storeName.includes(serachTeaxt.toLowerCase())
  })
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(email));
  };
  const handleAddEmailRecipient = (e) => {
    const email = e.target.value;
    setEmailRecipient(email);
    validateEmail(email);
  };
  const handleSubmit = () => {
    let data = {
      recipientEmail: emailRecipient,
      integerId: selectedStoreData.integerId,
      storeName: selectedStoreData.storeName,
      sellerCentralAccessId: selectedSCAID ? selectedSCAID : ''
    }
    if (isValidEmail) {
      AddRecipientService(props.token, data).then((res) => {
        if (res.status) {
          const activeIds = res.storeHeath.storeHeath
            .filter(item => item.isActive)
            .map(item => item.id);
          setActiveValue(activeIds);
          setStoreHealthdata(res.storeHeath.storeHeath);
          setSelectedStoreData({
            integerId: "",
            storeName: "",
          })
          setEmailRecipient('')
          setAddRecipientPopup(false)
        }
      })

    }
    else {
      notify.NotifySuccess('Invalid e-mail address')
    }
  }
  const handleInputChange = (e) => {
    setSelectedSCAID(e.target.value)
  }
  const handleStoreChange = (e) => {
    const selectedStoreId = e.target.value;
    const store = props.adProfiles.find((store) => store.integerID == selectedStoreId);

    if (store) {
      // setSelectedStore({ integerId: store.integerID });
      setSelectedStoreData({
        integerId: store.integerID,
        storeName: store.name,
      });
    }
  };
  const handleEditDataChange = (e) => {
    const { name, value } = e.target;
    if (name == 'isActive') {
      setEditEmailRecipientData(prevState => ({
        ...prevState,
        ['isActive']: e.target.checked
      }));
    }
    else {
      setEditEmailRecipientData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    if (name === 'recipientEmail') {
      validateEmail(value);
    }

  }
  const handleSubmitEditInfo = () => {
    if (isValidEmail) {
      let data = {
        id: editEmailRecipientData ? editEmailRecipientData.id : '',
        sellerCentralAccessId: editEmailRecipientData ? editEmailRecipientData.sellerCentralAccessId : '',
        recipientEmail: editEmailRecipientData ? editEmailRecipientData.recipientEmail : '',
        isActive: editEmailRecipientData ? editEmailRecipientData.isActive : ''
      }

      EditRecipientDataService(props.token, data).then((res) => {
        if (res.status) {
          console.log(res)
          const activeIds = res.storeHeath.storeHeath
            .filter(item => item.isActive)
            .map(item => item.id);
          setActiveValue(activeIds);
          setStoreHealthdata(res.storeHeath.storeHeath);
          setSelectedStoreData({
            integerId: "",
            storeName: "",
          })
          setEmailRecipient('')
          setEditEmailRecipientData({
            id: '',
            sellerCentralAccessId: '',
            recipientEmail: '',
            isActive: ''
          })
          setEditRecipientPopup(false)
        }
      })
    }
    else {
      notify.NotifySuccess('Invalid e-mail address')
    }
  }
  const productQuickActionsDropdown = actions => (
    <UncontrolledButtonDropdown className='d-flex justify-content-center w-100'>
        <DropdownToggle caret color="link" className='ml-auto'>
            Actions
        </DropdownToggle>
        <DropdownMenu>
            {
                productQuickActions.map(qa => (
                    <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>{qa.name(actions)}</DropdownItem>
                ))
            }
        </DropdownMenu>
    </UncontrolledButtonDropdown>
);
const quickActionsDropdown = actions => (
  <UncontrolledButtonDropdown className='d-flex justify-content-center w-100'>
      <DropdownToggle caret color="link" className='ml-auto'>
          View
      </DropdownToggle>
      <DropdownMenu>
          {
              quickActions.map(qa => (
                  <DropdownItem key={qa.name} onClick={() => qa.action(actions)}>{qa.name(actions)}</DropdownItem>
              ))
          }
      </DropdownMenu>
  </UncontrolledButtonDropdown>
);
  const productQuickActions = [
    {
        name: () =>'View',
        action: ({integerId,storeName}) => {
            setIsStoresAsinOpen(!isStoresAsinOpen)
            handleFetchProductsForStores(integerId,storeName);                        
        }
    },
    {
        name: () => 'Update',
        action: ({integerID}) => {
                // handleUpdateProducts(integerID) 
        }
    },
    {
        name: () =>'Update Skus',
        action: ({integerID}) => {
            // updateSKUs(integerID); 
        }
    },
    {
        name: () =>'Get Catalogue Item Details',
        action: ({stringID}) => {
            // getCatalogueItemDetails(stringID); 
        }
    },
    {
        name: () => 'Management History',
        action: ({integerID}) => {
            // setIntegerID(integerID)
            // fetchProductManagementHistory(integerID) 
        }
    },
    {
        name: () => 'Download Managed Asins',
        action: ({integerID,name}) => {
            // setIntegerID(integerID)
            // downloadManagedAsins(integerID,name) 
        }
    }
]
const quickActions = [
  {
      name: () =>'Request Adv Report',
      action: ({integerID}) => {
          // setIntegerID(integerID)
          // setRequestDaysPopup(true)
      }
  },
  {
      name: () => 'Save Adv Reports',
      action: ({integerID}) => {
          // handleSaveReports(integerID);   
      }
  },
  {
      name: () =>'Retry Adv Reports',
      action: ({integerID}) => {
          // RetryErrorReportV3(integerID); 
      }
  },
  {
      name: () =>'Fetch SPAPI Reports',
      action: ({stringID}) => {
          // setStringID(stringID); 
          // setSPAPIReportsPopup(true)
      }
  },
  {
      name: () =>'Get Merchant All Listing',
      action: ({stringID}) => {
          // getMerchantList(stringID); 
      }
  },
  
  {
      name: () =>'Display Errors Reports',
      action: ({integerID}) => {
          // fetchErrorReports(integerID); 
      }
  },
  {
      name: () =>'Display Pending Reports',
      action: ({integerID}) => {
          // displayPendingReports(integerID)
      }
  },
]
const  handleFetchProductsForStores = async (profileId,storeName) => {
  console.log('in handleFetchProductsForStores') 
  console.log(storeName) 
  setSelectedStoreData({ integerId:profileId, storeName: storeName });
  let response = await FetchProductsForStores(props.token, profileId);  
  console.log (response)  
  if (response) 
  {
    setManagedProducts(response['managedProducts'])  
  } 
}
const handleRemoveProduct = async (asin,profileId) => {
  let response = await RemoveProductService(props.token, asin, profileId);   
  if(response)
  {
      handleFetchProductsForStores(selectedStoreData.integerId,selectedStoreData.storeName)
  }   
};
const  handleUpdateSkus = async (asin,profileId) => {
  let response = await UpdateSKUForAsinStoreService(props.token, asin,profileId);  
  if(response)
  {
      handleFetchProductsForStores(selectedStoreData.integerId,selectedStoreData.storeName)
  }   
};
  return (
    <Container>
      <Card className='card-of-problemarea p-3'>
        <Row>
          <Col md={12}>
            <div className='d-flex'>
              <h5 className='storeHealth-report-fetch' onClick={() => {setReport('1');setSerachTeaxt('')}}>Weekly Report Recipients</h5>
              <h5 className='storeHealth-report-fetch' onClick={()=>{getManagedStoreData();setReport('2');setSerachTeaxt('')}}>Managed Stores</h5>
              {/* <h5 className='storeHealth-report-fetch' onClick={()=>setReport('3')}>Recipients3 Report</h5> */}
            </div>
          </Col>
        </Row>
      </Card>
      <Card className='card-of-problemarea mt-3 p-4'>
        <Row>
          <Col md={6}>
            <Input
              type='text'
              placeholder='Search Text'
              className='w-50 mt-2 mb-3'
              value={serachTeaxt}
              onChange={(e) => handleSearchGrid(e)}
            />
          </Col>
          <Col md={6} className="text-md-right">
            {
              report == '1' &&
            <Button
              className="btn-background-color float-md-right"
              onClick={() => { setAddRecipientPopup(true); setSelectedStoreData({ integerId: "", storeName: "", }) }}
            >
              Add Recipient
            </Button> 
            }
          </Col>
          <Col md={12}>
            {
              report == '1' &&
              <ReactTable
                data={handaleFilterStoreHealth}
                columns={recipientsReportData1}
                // noDataText={"No Data Found."}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      overflowY: "hidden",
                      background: "#B1FA63",
                      // color: "#243837",
                    },
                  };
                }}
                defaultPageSize={50}
                noDataText={"No Data Found."}
              // defaultPageSize={5}
              />
            }
          </Col>
        </Row>

        {
          report == '2' &&
          <ReactTable
            data={filterManagedStoreData}
            columns={managedStoreColumn}
            noDataText={"No Data Found."}
            className="-fixed -highlight -striped"
            defaultPageSize={50}
          />
        }
        {
          report == '3' &&
          <ReactTable
            data={storeHealthData}
            columns={recipientsReportData3}
            noDataText={"No Data Found."}
            className="-fixed -highlight -striped"
            defaultPageSize={50}
          />
        }
      </Card>
      <CustomModal
        header={"Add Recipient"}
        isOpen={addRecipientPopup}
        toggle={() => {
          setAddRecipientPopup(!addRecipientPopup);
          setEmailRecipient('')
          // setSelectedStore({ integerId: '' });
          setSelectedStoreData({ integerId: '', storeName: '' });
        }}
        size={"md"}
        className='curve-popup'
      >
        <Card className='curve-popup p-3'>
          <Row>
            <Col md={12}>
              <select
                id='sellerCentralAccessId'
                name='sellerCentralAccessId'
                className='form-control mt-2'
                value={selectedSCAID}
                onChange={(e) => handleInputChange(e)}>
                <option value=''> -- Seller Central Access Id --</option>
                {sellerCentralAccessIds && sellerCentralAccessIds.map((accessId) => (
                  <option value={accessId.id}>{accessId.email}</option>
                ))}
              </select>
            </Col>
            <Col md={12}>
              <select
                id="StoreList"
                name="StoreList"
                onChange={handleStoreChange}
                value={selectedStoreData.integerId}
                className="form-control mt-2"
              >
                <option value="">--Select Store--</option>
                {props.adProfiles.map((store) => (
                  <option key={store.integerID} value={store.integerID}>
                    {store.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={12} className='mt-2'>
              <Input
                value={emailRecipient}
                type='text'
                placeholder='E-mail'
                onChange={(e) => handleAddEmailRecipient(e)} />
            </Col>
            <Col className="d-flex justify-content-center mt-2">
              <Button
                className="btn-background-color"
                disabled={selectedStoreData.integerId && emailRecipient ? false : true}
                onClick={() => handleSubmit()}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        header={"Edit Recipient"}
        isOpen={editRecipientPopup}
        toggle={() => { setEditRecipientPopup(!editRecipientPopup); }}
        size={"md"}
        className='curve-popup'
      >
        <Card className='curve-popup p-3'>
          <Row className=''>
            <Col md={4} className='mt-2'>
              <h6><b>Seller Central Access Id :</b></h6>
            </Col>
            <Col md={8}>
              <select
                id='sellerCentralAccessId'
                name='sellerCentralAccessId'
                className='form-control mt-2 w-100'
                value={parseInt(editEmailRecipientData.sellerCentralAccessId)}
                onChange={(e) => handleEditDataChange(e)}>
                <option value=''> -- Seller Central Access Id --</option>
                {sellerCentralAccessIds && sellerCentralAccessIds.map((accessId) => (
                  <option value={accessId.id}>{accessId.email}</option>
                ))}
              </select>
            </Col>
            <Col md={4} className='mt-2'>
              <h6><b>Recipient Email :</b></h6>
            </Col>
            <Col md={8} className='mt-2'>
              <Input
                type='text'
                className='w-100'
                name='recipientEmail'
                value={editEmailRecipientData.recipientEmail}
                onChange={(e) => handleEditDataChange(e)} />
            </Col>
            <Col md={4} className='mt-2'>
              <h6><b>Is Active :</b></h6>
            </Col>
            <Col md={8} className='mt-2 d-flex'>
              <input
                type='checkbox'
                className='custom-checkbox '
                name='isActive'
                checked={editEmailRecipientData.isActive ? true : false}
                onChange={(e) => handleEditDataChange(e)} />
              {/* <h6 className='ml-2'>{editEmailRecipientData.isActive ? <b>Active</b> : <b>InActive</b>}</h6> */}
            </Col>
            <Col className="d-flex justify-content-center mt-2" >
              <Button

                onClick={() => handleSubmitEditInfo()}
                className='btn-background-color'>
                Submit
              </Button>

            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        header={"CAMPAIGN LAUNCH STATUS"}
        isOpen={isOpenCampaignDetails}
        toggle={() => { setIsOpenCampaignDetails(!isOpenCampaignDetails); }}
        size={"xl"}
        className='curve-popup'
      >
        <Card className='curve-popup p-3'>
          <Row className=''>
            <Col md={12} className='mt-2'>
              <CampaignLaunchStatusTable data={campaignLaunchStatus} />
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
                isOpen={isStoresAsinOpen}
                toggle={() => setIsStoresAsinOpen(!isStoresAsinOpen)}
                header={`Amazon Stores/Asin for : ${selectedStoreData.storeName} `}
                size={"xl"}
                footer={
                    <Fragment>
                        <Button color="link" onClick={() => setIsStoresAsinOpen(!isStoresAsinOpen)}>Cancel</Button>
                    </Fragment>
                }        
                    
            >
                <Card>
                <div className="p-3">

            <div>
                <h3>Products</h3>
            </div>
            <ReactTable
                data={managedProducts}
                columns={subInnerColumns}
                defaultPageSize={5}
                getTheadProps={() => {
                    return {
                        style: {
                            overflowY: 'hidden'
                        }
                    }
                }}
                className="-striped -highlight -fixed"
                noDataText={"No stores found."}
            />


        </div>
    
                </Card>

                
            </CustomModal>
    </Container>
  )
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(StoreHealthMainPage);
