import React, {useState} from 'react';
import {Card, CardBody, CardTitle, Col, Container, Input, Label, Row} from "reactstrap";
import { connect } from 'react-redux';

import DateRangePicker from "../../../../../Components/Input/DateRangePicker";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import {ProductBudgetUpdateStatus} from "../../../../../../Service/OPSServices/ProductBudgetUpdateServices";
import ProductBudgetUpdateTable from "./Components/ProductBudgetUpdateTable";

function ProductBudgetUpdatePage(props) {

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const [integerId, setIntegerId] = useState("");
    const [asin, setAsin] = useState("");

    const [loading, setLoading] = useState(false);

    const [productStatus, setProductStatus] = useState([]);

    console.log(productStatus);

    const fetchProductStatus = () => {
        setLoading(true);

        let isProduct = asin !== "";

        let data = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
        };

        if (isProduct) {
            data["asin"] = asin;
        } else {
            data["integerId"] = integerId;
        }

        ProductBudgetUpdateStatus(props.token, data).then(budgetUpdateStatus => {
            if (budgetUpdateStatus) {
                setProductStatus(budgetUpdateStatus);
            }
        })

        setLoading(false);
    }

    const isInputValid = () => {
        if (dateRange.startDate === null) return false;
        //if (integerId === "" && asin === "") return false;
        //if (integerId !== "" && asin !== "") return false;

        return true;
    }

    return (
        <>
            <Container fluid>
                <Row className="mb-3">
                    <Col md="12" lg="6">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Date Range</CardTitle>
                                <DateRangePicker
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                                <div className="divider"/>
                                <Label for={"storeID"}>Store Integer ID</Label>
                                <Input
                                    className="mb-3"
                                    id={"storeID"}
                                    value={integerId}
                                    onChange={(e) => setIntegerId(e.target.value)}
                                    placeholder="Enter Store ID"
                                />
                                <p>OR</p>
                                <Label for={"productASIN"}>Product ASIN</Label>
                                <Input
                                    id={"productASIN"}
                                    value={asin}
                                    onChange={(e) => setAsin(e.target.value)}
                                    placeholder="Enter Product ASIN"
                                />
                                {
                                    (asin !== "" && integerId !== "") &&
                                    <p className="text-danger mt-1">Please enter only one of the following: Product ASIN or Store ID.</p>
                                }
                                <LoadingButton
                                    className="mt-5"
                                    loading={loading}
                                    color={"primary"}
                                    onClick={fetchProductStatus}
                                    disabled={!isInputValid()}
                                >
                                    Submit
                                </LoadingButton>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardTitle>Product Budget Update Status</CardTitle>
                        <Card>
                            <ProductBudgetUpdateTable data={productStatus} />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
})


export default connect(mapStateToProps)(ProductBudgetUpdatePage);