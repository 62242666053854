import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import actionTypes from '../../../Redux/Actions/ActionTypes';
import { getFilenameWithTimestamp } from '../../../Utils/Utilities';

const formRest = (status) => {
    return new Promise()
}


const getAmazonOnBoardLanding = async (token, dispatch) => {
    try {
        dispatch({
            type: actionTypes.SET_PROJECT_LOADING,
            payload: {
                loading: true
            }
        })
        const result = await axios.get(`${CURRENT_API_URL}/onboarding/getstores`, {
            headers: {
                'Authorization': token,
            }
        })

        if (result.data) {
            dispatch({ type: actionTypes.GET_STORE_DETAILS, payload: result.data });
        }
    } catch (e) {
        if (e.response) {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: e.response.data ? e.response.data.message : "Something went wrong"
                }
            })
        }
        else {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: "Something went wrong"
                }
            })
        }

    }
}

// const getAmzazonOnBoardProjects = async (token, storeId, dispatch) => {
//     try {

//         const result = await axios.get(`${CURRENT_API_URL}/onboarding/viewAllProjects`, {
//             headers: {
//                 'Authorization': token,
//             }
//         })

//         dispatch({ type: actionTypes.SET_CURRENT_STORE, payload: { id: storeId, projects: result.data } })

//     } catch (e) {
//         console.log(e.message)
//     }
// }

//'X-CSRFTOKEN': "QzwKX7xE8gLxgAyIHH29zTSL6KdZymeKmwmhQuhyM4Yn62BI59cLaRGo4SElTq92",
const submitAmazonOnBoardProjects = async (token, data, dispatch, initialStoreId) => {
    try {
        dispatch({
            type: actionTypes.SET_SUBMIT_PROJECT_LOADING,
            payload: {
                loading: true
            }
        })
        let formData = new FormData();

        data.files.forEach(f => {
            formData.append('projectFiles', f, getFilenameWithTimestamp(f.name))
        })
        delete data.files;
        if (data.linkToAssets.length > 0) {
            let len = data.linkToAssets.length - 1
            if (!data.linkToAssets[len].comparabaleProducts) {
                data.linkToAssets.splice(len, 1);
            }
            if (data.linkToAssets.length > 0) data.linkToAssets = data.linkToAssets.map(l => l.comparabaleProducts)
        }
        formData.append('data', JSON.stringify(data));
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/projectsubmit`, formData, {
            headers: {
                'Authorization': token
            }
        })

        // await afterSubmitProjectsData(token, dispatch, data.storeId, initialStoreId, result.data.message);
        await getAmazonOnBoardProjects(token, data.storeId, dispatch)
        return true;
    } catch (e) {
        if (e.response) {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: e.response.data ? e.response.data.message : "Something went wrong"
                }
            })
        }
        else {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: "Something went wrong"
                }
            })
        }

        return false
    }
}

const afterSubmitProjectsData = async (token, dispatch, storeId, initialStoreId, msg) => {
    try {
        const result = await axios.get(`${CURRENT_API_URL}/onboarding/getonboardamazonprojects/${storeId}`, {
            headers: {
                'Authorization': token,
            }
        })
        dispatch({
            type: actionTypes.ADD_PROJECT,
            payload: {
                storeData: result.data,
                initialStoreId: initialStoreId,
                storeId: storeId,
                msg: msg
            }
        })
    } catch (e) {
        console.log(e)
        if (e.response) {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: e.response.data ? e.response.data.message : "Something went wrong"
                }
            })
        }
        else {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: "Something went wrong"
                }
            })
        }

    }
}

const getPorducts = async (token, storeId) => {
    try {

        const result = await axios.post(`${CURRENT_API_URL}/onboarding/getProducts`, {
            IntegerID: storeId
        }, {
            headers: {
                'Authorization': token,
            }
        })
        return result.data;
    } catch (e) {
        return [];
    }
}

const getAmazonOnBoardProjects = async (token, storeId, dispatch) => {
    try {
        dispatch({
            type: actionTypes.SET_SUBMIT_PROJECT_LOADING,
            payload: {
                loading: true
            }
        })
        const result = await axios.get(`${CURRENT_API_URL}/onboarding/getonboardamazonprojects/${storeId}`, {
            headers: {
                'Authorization': token,
            }
        })

        dispatch({
            type: actionTypes.SET_STORE_PROJECTS,
            payload: {
                id: storeId,
                projects: result.data
            }
        })

    } catch (e) {
        if (e.response) {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: e.response.data ? e.response.data.message : "Something went wrong"
                }
            })
        }
        else {
            dispatch({
                type: actionTypes.SET_PROJECT_API_STATUS,
                payload: {
                    status: "Something went wrong"
                }
            })
        }
    }
}


const submitAmazonOnBoardingProjectDetails = async (token, data, projectId) => {
    try {
        let formData = new FormData();

        data.galleryImages.forEach(f => {
            formData.append('galleryPhotos', f, getFilenameWithTimestamp(f.name))
        })

        if (data.aiFiles && data.aiFiles.length > 0) {
            data.aiFiles.forEach(f => {
                formData.append('aiFiles', f, getFilenameWithTimestamp(f.name))
            })

        }
        Object.keys(data).forEach(key => {
            if (key !== 'galleryImages' && key !== 'aiFiles' && key !== 'pdfFile') {
                formData.append(key, data[key])
            }
        })
        if (data.pdfFile) {
            formData.append('pdfFile', data.pdfFile, getFilenameWithTimestamp(data.pdfFile.name))
        }
        formData.append('projectId', projectId);
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/submitprojectdetails`, formData, {
            headers: {
                'Authorization': token
            }
        })

        return true
    } catch (e) {
        console.log(e.response)
        throw e;
    }
}

const getProjectById = async (token, id) => {
    try {
        let payload = {
            project_id: id
        }
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/getprojectbyid`, payload, {
            headers: {
                'Authorization': token
            }
        })

        return result.data;
    } catch (e) {
        throw e;
    }
}

const getProjectDetailsForPreview = async (token, payload) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/getprojectbyid`, payload, {
            headers: {
                'Authorization': token
            }
        })

        return result.data;
    } catch (e) {
        throw e;
    }
}

const postComments = async (payload, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/submitComments`, payload, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}

const getComments = async (payload, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/getComments`, payload, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}

const projectFieldsUpdateOrCreate = async (payload, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/updateOrCreateProjectField`, payload, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}

const projectFilesUpdateOrCreate = async (payload, token) => {

    try {
        let formData = new FormData();
        formData.append('id', payload.id);
        formData.append('project_id', payload.project_id);
        formData.append('field_name', payload.field_name);
        payload.files.forEach(file => {
            formData.append('files', file, getFilenameWithTimestamp(file.name))
        })
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/updateOrCreateProjectFiles`, formData, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;

    } catch (e) {
        throw e;
    }
}

const deleteProjectField = async (id, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/onboarding/deleteProjectField`, { id: id }, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}

const changeProjectStatus = async (payload, url, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/${url}`, payload, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}


const customerDetailsSumbmitSection = async (payload, url, token) => {
    try {
        const result = await axios.post(`${CURRENT_API_URL}/${url}`, payload, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}

const getCustomerDetails = async (token, storeId) => {
    try {
        const result = await axios.get(`${CURRENT_API_URL}/onboarding/getCustomerDetails/${storeId}`, {
            headers: {
                'Authorization': token
            }
        })
        return result.data;
    } catch (e) {
        throw e;
    }
}


export {
    //getAmzazonOnBoardProjects,
    getAmazonOnBoardLanding,
    submitAmazonOnBoardProjects,
    getPorducts,
    getAmazonOnBoardProjects,
    submitAmazonOnBoardingProjectDetails,
    getProjectById,
    getProjectDetailsForPreview,
    postComments,
    getComments,
    projectFieldsUpdateOrCreate,
    projectFilesUpdateOrCreate,
    deleteProjectField,
    changeProjectStatus,
    customerDetailsSumbmitSection,
    getCustomerDetails
}
