import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function UpdateProductBudgetService(token, storeId, asin, newBudget) {
    return axios.post(`${CURRENT_API_URL}/campaign/lokiUpdateBudget`, {
        integerId: storeId,
        asin: asin,
        newBudget: newBudget,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            notify.NotifySuccess("Your product's budget was updated successfully!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("An error occurred while updating your product's budget. Please try again later.");
            return false;
        })
}


function UnallocatedProductBudgetService(token, asin) {
    return axios.post(`${CURRENT_API_URL}/campaign/budgetToAllocate`, {
        asin: asin,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        })
        .catch(() => {
            return undefined;
        })
}


export {
    UpdateProductBudgetService,
    UnallocatedProductBudgetService,
};