import React, { Fragment, useState, useEffect } from 'react';
import {
    Card, CardBody, Row, Col, Container, Button, Popover, PopoverHeader, PopoverBody,
} from 'reactstrap';
import { connect } from 'react-redux';
import Fileupload from '../../../../Components/Fileupload/Fileupload';
import './agencyView.css';
import AmazonFileUpload from './AmazonProductView/AmazonFileUpload';
import AmazonProductTitleFeatures from './AmazonProductView/AmazonProductTitleFeatures';
import AmazonProductDetails from './AmazonProductView/AmazonProductDetails';
import AmazonPDFView from './AmazonProductView/AmazonPDFView';
import { toast } from 'react-toastify';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams, useHistory, Link } from "react-router-dom";
import { submitAmazonOnBoardingProjectDetails, getProjectDetailsForPreview } from '../../../../../Service/DashboardServices/Projects/ProjectsService';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import URLs from '../../../../../Utils/URLs';
import ImagesFromCustomer from './AmazonProductView/ImagesFromCustomer';
import AmazonProjectPreview from './AmazonProjectPreview/AmazonProjectPreview';
import actionTypes from '../../../../../Redux/Actions/ActionTypes';
import Can from "../../../../Components/Can";


const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5 * 1024 * 1024;


const AgencyProjectView = ({ token, accessLevel, storeDetails, dispatch }) => {

    // if(!currentStore){

    // }
    const { id, storeId } = useParams();
    const history = useHistory();

    const [preAIFiles, setPreAIFiles] = useState([])
    const [loading, setLoading] = useState(true)
    const [projectData, setProjectData] = useState(null)
    const [imgPopover, setImgPopover] = useState(false)
    // const [selectedStore, setSelectedStore] = useState(null);

    const initialValues = {
        galleryImages: [],
        title: '',
        feature1: '',
        feature2: '',
        feature3: '',
        feature4: '',
        feature5: '',
        productDescription: '',
        backendKeywords: '',
        subjectMatter1: '',
        subjectMatter2: '',
        subjectMatter3: '',
        subjectMatter4: '',
        subjectMatter5: '',
        linkToAsset: '',
        aiFiles: [],
        pdfFile: undefined
    }

    const validationSchema = Yup.object({
        galleryImages: Yup.array(),
        title: Yup.string(),  // Yup.string().max(200, 'Must be 200 Characters or less').required('Title is required'),
        feature1: Yup.string(), // Yup.string().max(200, 'Must be 200 Characters or less').required('Feature 1 is required'),
        feature2: Yup.string(), //  Yup.string().max(200, 'Must be 200 Characters or less').required('Feature 2 is required'),
        feature3: Yup.string(), //Yup.string().max(200, 'Must be 200 Characters or less').required('Feature 3 is required'),
        feature4: Yup.string(), //Yup.string().max(200, 'Must be 200 Characters or less').required('Feature 4 is required'),
        feature5: Yup.string(), //Yup.string().max(200, 'Must be 200 Characters or less'.required('Feature 5 is required')),
        productDescription: Yup.string(), //Yup.string().max(2000, 'Must be 2000 Characters or less'),
        backendKeywords: Yup.string(), //Yup.string().max(250, 'Must be 250 Characters or less'),
        subjectMatter1: Yup.string(), //Yup.string().max(50, 'Must be 50 Characters or less'),
        subjectMatter2: Yup.string(), //Yup.string().max(50, 'Must be 50 Characters or less'),
        subjectMatter3: Yup.string(), //Yup.string().max(50, 'Must be 50 Characters or less'),
        subjectMatter4: Yup.string(), //Yup.string().max(50, 'Must be 50 Characters or less'),
        subjectMatter5: Yup.string(), //Yup.string().max(50, 'Must be 50 Characters or less'),
        linkToAsset: Yup.string().url().max(5000, 'Must be 1000 Characters or less'),
        aiFiles: Yup.array(),
        pdfFile: Yup.mixed(), //Yup.mixed().required('PDF File is required')
    })

    useEffect(() => {
        setLoading(true);
        if (id > 0 && !projectData) {

        }
        if (storeDetails.length > 0 && storeId > 0) {
            getProjectDetailsData()
            dispatch({
                type: actionTypes.SET_CURRENT_STORE_ONLY,
                payload: {
                    id: storeId
                }
            })
        }
    }, [id, storeDetails])

    const getProjectDetailsData = () => {
        let payload = {
            project_id: id,
            store_id: storeId
        }
        getProjectDetailsForPreview(token, payload)
            .then((data) => {
                setLoading(false);
                setProjectData(data);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    const fileErrorMsg = (msg) => {
        showAlert(msg, 'error');
    }

    const fileErrorMsgPDF = (msg) => {
        showAlert(msg, 'error');
    }

    const refreshData = () => {
        setLoading(true);
        if (id > 0) {
            setProjectData(null)
            getProjectDetailsData()
        }
        else {
            setLoading(false);
        }
    }

    const showAlert = (msg, type) => {
        if (type === 'error') {
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        else if (type === 'success') {
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const toggleImgPopover = () => setImgPopover(!imgPopover)

    const updateFilesCb = (files, formik) => {
        let totalSize = 0;
        files.forEach(file => {
            totalSize = totalSize + file.size;
        })

        if (totalSize > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
            showAlert('The Total size of all AI files should not exceed 5MB', 'error')
            setPreAIFiles([]);
            return;
        }

        formik.setFieldValue('aiFiles', files)
    }

    const getErrorPage = () => {
        return (
            <div className="jumbotron text-center">
                <h4 className="display-4">Invalid Access</h4>
                <p className="lead">You don't have permissions to access this page right now. Please contact your administrator</p>
                <hr />
                <p>
                    Having trouble? <a href="">Contact us</a>
                </p>
                <p className="lead" >
                    Go to: <Button tag={Link} type="button" color="primary" size="sm" to={URLs.BASE_PROJECTS_URL}>View Projects</Button>
                </p>
            </div>
        )
    }

    if (projectData && projectData.projectStatus === 'drafted') {
        return (
            <Fragment>
                {getErrorPage()}
            </Fragment>
        )
    }

    if ((!projectData && !loading)) {
        return (
            <Fragment>
                {getErrorPage()}
            </Fragment>
        )
    }

    if (!loading && projectData.amazonproject.length > 0) {
        return (
            <div>
                <AmazonProjectPreview token={token} accessLevel={accessLevel} id={id} storeId={storeId} data={projectData} refreshData={refreshData} />
            </div>
        )
    }

    return (
        <Fragment>
            <Can
                role={accessLevel}
                perform={"project:edit"}
                yes={() => (
                    <Container fluid>
                        {/* <ToastContainer /> */}
                        <LoadingOverlay
                            active={loading}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: 'rgba(0, 0, 0, 0.5)',
                                    opacity: 1,
                                    zIndex: 10000
                                }),
                                content: (base) => ({
                                    ...base,
                                    color: "#000"
                                })
                            }}
                            spinner={<Loader active type="ball-pulse" />}
                            text='Loading...'
                        >
                            {!loading &&
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        submitAmazonOnBoardingProjectDetails(token, values, id)
                                            .then(() => {
                                                setLoading(false);
                                                showAlert('Details submitted Successfully', 'success');
                                                history.push({
                                                    pathname: '/project/thankyou',
                                                    state: { projectId: id, backurl: `/project/${storeId}` }
                                                });
                                            })
                                            .catch((e) => {
                                                setLoading(false);
                                                if (e.response) {
                                                    if (e.response.status === 400) {
                                                        let errMsg = [];
                                                        Object.keys(e.response.data).forEach(key => {
                                                            let str = `${key} is required or invalid`;
                                                            errMsg.push(str)
                                                        })

                                                        showAlert(errMsg.join(','), 'error');
                                                    }
                                                    else {
                                                        showAlert(e.response.data.message, 'error')
                                                    }
                                                }

                                            })
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {formik => (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Row>
                                            <Col md="12">
                                                <Card className="main-card mb-3">
                                                    <CardBody>
                                                        <div className="forms-wizard-horizontal p-15">
                                                            <Row>
                                                                <Col md="7">
                                                                    <h4 className="heading">Gallery Photos <sup className="red">*</sup> {(projectData && projectData.image_urls && projectData.image_urls.length > 0) &&
                                                                    (
                                                                        <span>
                                                                            (<Button color="link" id="customerImages" type="button" className="p-0" title="Customer Images">
                                                                                <i className="fas fa-user"></i>
                                                                            </Button>)
                                                                            <Popover placement="bottom" trigger="legacy" isOpen={imgPopover} target="customerImages" toggle={toggleImgPopover}>
                                                                                <PopoverHeader>Customer Images</PopoverHeader>
                                                                                <PopoverBody>
                                                                                    <ImagesFromCustomer images={projectData.image_urls} />
                                                                                </PopoverBody>
                                                                            </Popover>
                                                                        </span>
                                                                    )

                                                                    } - <span className="note">Minimum dimensions are 1500 x 1500</span></h4>

                                                                    <AmazonFileUpload formik={formik} fileErrorMsg={fileErrorMsg} />
                                                                    <span className="text-danger">
                                                                    <ErrorMessage name="galleryImages" />
                                                                </span>

                                                                </Col>
                                                                <Col md="5">
                                                                    <h4 className="heading">Title and Features - <span className="note">Title and Features are mandatory</span></h4>
                                                                    <br />
                                                                    <AmazonProductTitleFeatures formik={formik} />
                                                                </Col>
                                                                <Col md="12" className="mt-3">
                                                                    <AmazonProductDetails formik={formik} />
                                                                </Col>

                                                                <Col md="12 text-center">
                                                                    <AmazonPDFView formik={formik} fileErrorMsg={fileErrorMsgPDF} />
                                                                    <span className="text-danger">
                                                                    <ErrorMessage name="pdfFile" />
                                                                </span>
                                                                </Col>
                                                                <Col md="12">
                                                                    <div className="symbol symbol-60px mb-6">
                                                                        <img src="/svg/ai.svg" alt="" /> <span className="heading t-5 grey">.AI files upload - <span className="note">Maximum files size allowed is 5 MB</span></span>
                                                                    </div>
                                                                    <Fileupload
                                                                        accept=".ai"
                                                                        label=""
                                                                        multiple
                                                                        updateFilesCb={(files) => updateFilesCb(files, formik)}
                                                                        previousFiles={preAIFiles}
                                                                        maxFiles="10"
                                                                        onFileLengthError={(msg) => { showAlert(msg, 'error') }} />
                                                                </Col>
                                                                <Col md="12 text-center">
                                                                    <div className="text-right">
                                                                        <Button type="submit" color="success" disabled={!formik.isValid} ><i className="fab fa-telegram-plane"></i> &nbsp;Submit For Review</Button>
                                                                        {!formik.isValid && <p className="text-danger bold mt-2 mb-0"><sup className="red">*</sup> Please fill all the required fields to submit</p>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                    </form>
                                )}
                            </Formik>

                            }

                        </LoadingOverlay>

                    </Container>
                )}
                no={() => (
                    getErrorPage()
                )}
            />


        </Fragment>
    )
}

const mapToProps = (state) => {
    return {
        currentStore: state.project.currentStore,
        token: state.loggedUser.token,
        loading: state.project.loading,
        storeDetails: state.project.storeDetails,
        status: state.project.apiStatus,
        accessLevel: state.loggedUser.accessLevel
    }
}

export default connect(mapToProps)(AgencyProjectView);
