import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import notify from '../../Notifications/ToastifyActions';


function GetAdvReportsStatusService(token, data) {
    notify.NotifySuccess("We are analysing reports statistics. It may take a few minutes depending on size of the data. Please do not refreseh screen.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/getAdvReportsStatusV3`, data, {
        headers: {
            'Authorization': token,
        }
       
    }).then(res => {
            console.log(res.data)
            notify.NotifySuccess("Requested reports data fetched successfully.");
            notify.NotifySuccess("Background job for missing reports has been fired. Please check after 15-20 minutes.");
            data = res.data
            if ("store_summary" in data.allReports)
            {
                notify.NotifySuccess("Please check 'Downloads' folder for reports data &  analysis.");
            }
            
            return res.data;
        }).catch(() => {
            notify.NotifyError("An error occurred while fetching  report status data, please try again later.");
            return false;
        });
}


export {
    GetAdvReportsStatusService,
}

