import React from 'react';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";


/**
 * LoadingOverlay component that displays a loading animation on top of children components while active
 * @param active true if the loading animation should be visible, false otherwise
 * @param styles (optional) custom styling for the overlay
 * @param text text to display while loading
 * @param error (optional) true if a separate error message should be displayed rather than the children components, false otherwise
 * @param errorMessage (optional) the error message to display when error is true
 */
function CustomLoadingOverlay({ active, styles, text, error, errorMessage, ...props }) {
    return (
        <LoadingOverlay
            active={active}
            styles={styles ?
                styles :
                {
                    overlay: (base) => ({
                        ...base,
                        background: '#fff',
                        opacity: 1
                    }),
                    content: (base) => ({
                        ...base,
                        color: "#000"
                    })
                }}
            spinner={<Loader active type="ball-pulse"/>}
            text={text}
        >
            {error ?
                errorMessage
                :
                props.children
            }
        </LoadingOverlay>
    )
}

export default CustomLoadingOverlay;