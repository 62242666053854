import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStore,
  faCaretDown,
  faCaretRight,
  faBox,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "reactstrap";
import No_ratings from "./../../../../../../assets/img/StoreOverview_Img/No_rating.svg"
import Group from "./../../../../../../assets/img/StoreOverview_Img/Group.png"
import Arrow from "./../../../../../../assets/img/StoreOverview_Img/arrow.svg"
import StarRating from "../../../AccountOverviewRelaunch/Components/Rating/StarRating";
import MetisMenu from "react-metismenu/lib/components/MetisMenu";
import RouterLink from 'react-metismenu-router-link';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
function StoreInformationCard(props) {
  const [areStoresOpen, setStoresOpen] = useState(false);
  const toggleStores = () => {
    setStoresOpen(!areStoresOpen);
  };
  const [selectedStore, setSelectedStore] = useState(props.menuItems.length !== 0 ?props.menuItems[0].content[0].to:'');
  const history = useHistory();

  const handleStoreChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedStore(selectedValue);
    if (selectedValue) {
      history.push(`${selectedValue}`);
    }
  };
  return <>
    <Container fluid className=''>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
            <p className="text-center"><img
                            src={props.store.logo ? props.store.logo : Group}
                            width={props.store.logo ? "" : 80}
                          /></p>
            </Col>
            <Col md={12}>
              <select
                    id="StoreList"
                    name="StoreList"
                    onChange={handleStoreChange}
                    value={selectedStore}
                    className="form-control"
                  >
                    <option value="">--Select Store--</option>
                    {props.menuItems.length !== 0
                     ? props.menuItems[0].content.map((store) => (
                         <option value={store.to} >
                          {store.label}
                         </option>
                       ))
                     : ""}
                  </select>
                 
            </Col>
          </Row>
        </Col>
        <Col md={12} className="mt-2">
          <div style={{fontSize:'12px'}}>
            <p className="text-center"><b>Seller Partner Id: </b> {props.store.details.accountID}
            <br/><b>Profile Id: </b> {props.store.integerID}
            <br/><b>Location: </b> {props.store.location}</p>
            {props.store.ratingTotal < 1 ? (
                      <>
                        <p className="text-center">
                          <img src={No_ratings} width={150} />
                        </p>
                      </>
                    ) : (
                      <>
                        <h3 className="text-center">
                          <StarRating
                            initialRating={props.store.rating}
                            readonly
                            stop={5}
                          />
                        </h3>
                        <p className="text-center"><b> {props.store.rating}</b>
                        <br/><b> {props.store.ratingTotal} </b> Reviews
                        <br/><span className="positive-reviews" style={{fontSize:'14px'}}><b> {props.store.ratingTotalPercentage}% positive reviews</b></span></p>
                      </>
                    )}
          </div>
        </Col>
      </Row>
    </Container>
  </>;
}

const mapStateToProps = (state) => ({
  stores: state.accountOverview.landing.adProfiles,
  accessLevel: state.loggedUser.accessLevel,
});

export default connect(mapStateToProps)(StoreInformationCard);
