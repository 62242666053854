import axios from 'axios';
import { LoadReadyCampaigns } from "../../../../Redux/Actions/Actions";
import actionType from '../../../../Redux/Actions/ActionTypes';
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function ReadyCampaignListService(token) {
    return function(dispatch) {

        dispatch({
            type: actionType.LOAD_READY_CAMPAIGNS_BEGIN,
        });

        axios.get(`${CURRENT_API_URL}/campaign/launchpad`, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(LoadReadyCampaigns(res.data.readyCampaigns));
            })
            .catch(() => {
                dispatch({
                    type: actionType.LOAD_READY_CAMPAIGNS_FAILED,
                });
            })
    }
}

export default ReadyCampaignListService;