import React, { useEffect } from "react";
import DashboardLayout from "../../../Layout/DashboardLayout";
import { connect } from 'react-redux';
import { getAmazonOnBoardLanding } from '../../../../../Service/DashboardServices/Projects/ProjectsService';




function CreateProjectLayout(props) {

    useEffect(() => {
        if (props.token) {
            getAmazonOnBoardLanding(props.token, props.dispatch);
        }
    }, [])


    return (
        <DashboardLayout
            menuItems={props.menuItems}
            heading="Projects Overview"
            icon="lnr-map text-info"
            subheading="Easily create automated Amazon Projects!"
        >
            {props.children}
        </DashboardLayout>
    );
}

const mapToProps = (state) => {
    return {
        products: state.project.projects,
        storeDetails: state.project.storeDetails,
        menuItems: state.project.menuItems,
        token: state.loggedUser.token,
        user: state.loggedUser.user, 
    }
}

export default connect(mapToProps)(CreateProjectLayout);