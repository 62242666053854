import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';
import React  from 'react';
import  useState  from 'react';



function FetchStoresForAsinService(token, asin) {

    
    return axios.post(`${CURRENT_API_URL}/sellerPartner/FetchStoresForAsin`, {
        asin: asin,       
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(res => {
        notify.NotifySuccess("Stores are getting fetched.");        
        return (res.data);
    }).catch(() => {
        notify.NotifyError("There was a problem getting store.");
        return false;
    });
}

export default FetchStoresForAsinService;