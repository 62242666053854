import axios from 'axios';
import notify from "../../../../Notifications/ToastifyActions";
import { CURRENT_API_URL } from "../../../../Utils/Constants";


function QuickLaunchCampaignService(token, integerID, asin, sku, dailyBudget) {

    return axios.post(`${CURRENT_API_URL}/campaign/oneClickLaunch`, {
        integerID: integerID,
        productASIN: asin,
        productSKU: sku,
        dailyBudget: dailyBudget,
    }, {
        headers: {
            'Authorization': token,
        }
    }).then(res => {
        notify.NotifySuccess("Your product's campaign was launched successfully! It'll take 5-7 minutes for your campaign to appear in a new portfolio in Seller Central.");

        if (res.status === 200) {   // first campaign launch
            return {
                isFirstCampaign: true,
            }
        } else return true;
    }).catch(error => {
        if (error.response.status === 402) { // need to upgrade subscription
            notify.NotifyError("You've reached the maximum number of campaigns for your subscription. Please upgrade your subscription to launch more campaigns.");
        } else {
            notify.NotifyError('We\'ve encountered an error while launching your campaign, please try again later.');
        }
        return false;
    })
}

export default QuickLaunchCampaignService;