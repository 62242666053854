import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session'
import { PersistGate } from 'redux-persist/integration/react';
import Loader from 'react-loaders';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from "./Redux/Reducers";
import { CURRENT_ENV } from "./Utils/Constants";


//REDUX DEV TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'root',
    storage: storageSession,
}

//REDUX PERSIST STORE
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = (CURRENT_ENV === 'DEVELOPMENT' || CURRENT_ENV === 'LOCAL' || CURRENT_ENV === 'TEST') ?
    createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk))) :
    createStore(persistedReducer, applyMiddleware(thunk));

const persistor = persistStore(store);


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store} >
          <PersistGate loading={<Loader active type="ball-pulse"/>} persistor={persistor}>
              <App />
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
