import React from 'react';
import ReactTable from "react-table";


function SellersTable(props) {
    const columns = [
        {
            Header: "ID",
            accessor: "seller_id",
        },
        {
            Header: "Name",
            accessor: 'seller_name',
        },
        {
            Header: "Phone",
            accessor: "phone",
        }, 
        {
            Header: "Business name",
            accessor: "business_name",
        },  
        {
            Header: "About Info",
            accessor: "about_this_seller",
        }, 
        {
            Header: "Link",
            accessor: "link",
            Cell: cellInfo => <a
                className="mt-1"
                href={cellInfo.value}
                target="_blank"
                rel="noopener noreferrer"
            >
                View on Amazon
            </a>
        },       
        {
            Header: "Rating",
            accessor: "rating",
        },
        {
            Header: "Ratings Total",
            accessor: "ratings_total",
        },
        {
            Header: "Ratings % Positive",
            accessor: "ratings_percentage_positive",
        },              
          
    ];


    return (
        <ReactTable
            columns={columns}
            data={props.data}
            style={{
                height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            noDataText="No Sellers found."
        />
    )
}

export default SellersTable;