import React, { useEffect, useState } from 'react';
import { ListGroupItem } from "reactstrap";

import CustomWidget from "./CustomWidget";


/**
 * Wrapper for CustomWidget component that allows for a "selecting" a widget. When a widget is selected, it is
 * highlighted and its selection state is updated accordingly for other actions to be performed upon widget selection.
 * Renders the widget inside a ListGroupItem component which allows for rendering the widget as part of a widget list.
 * If the SelectableWidget has children components, then the children are rendered in place of the CustomWidget component.
 * @param toggleSelect - callback function that is triggered when the SelectableWidget component is clicked. Takes as
 * a parameter the new value of isSelected and updates it to that new value.
 * @param isSelected - state of selection; true if the widget is selected, false otherwise.
 * @param listItemKey - unique key for this widget's ListGroupItem wrapping component.
 * @param collapseOnUnselect - allows for closing the widget detail section of this widget if it is externally
 * unselected, i.e. if it is unselected through other means than clicking on this widget.
 * True if the detail section should collapse when widget is unselected, false otherwise. If false, this does not
 * prevent the detail section from collapsing if the widget is unselected by clicking on it.
 * @param selectedColor - the background color of a selected widget
 * @param props - remaining props to be passed to the CustomWidget component.
 */
function SelectableWidget({ toggleSelect, isSelected, listItemKey, collapseOnUnselect, selectedColor, ...props }) {

    const [isOpen, setIsOpen] = useState(false);

    let bgColor = selectedColor ? selectedColor: "#F0F9FF";
    // "#e0f3ff"
    // "#f0f9ff"

    useEffect(() => {
        if (collapseOnUnselect) {
            if (isSelected) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }
    }, [isSelected, collapseOnUnselect])

    return (
        <ListGroupItem
            onClick={(e) => {
                e.stopPropagation();
                toggleSelect(!isSelected);
            }}
            key={listItemKey}
            style={isSelected ? {backgroundColor: bgColor, cursor: "pointer"} : {cursor: "pointer"}}
        >
            { props.children ? props.children :
                <CustomWidget
                    { ...props }
                    controlledCollapse={collapseOnUnselect}
                    isOpen={isOpen}
                />
            }
        </ListGroupItem>
    )
}

export default SelectableWidget;