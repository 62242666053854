import React from 'react';
import ReactTable from "react-table";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";


function IdleKeywordTable(props) {

    const actionDropdown = (keyword) => (
        <UncontrolledButtonDropdown>
            <DropdownToggle caret color={"link"}>
                View
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    onClick={() => props.handleIdleKW(keyword.keywordID, 'force')}
                >
                    Force Spend
                </DropdownItem>
                <DropdownItem
                    onClick={() => props.handleIdleKW(keyword.keywordID, 'ignore')}
                >
                    Ignore
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const columns = [
        {
            Header: "Keyword",
            accessor: "keywordText",
        },
        {
            Header: "Match Type",
            accessor: "matchType",
        },
        {
            Header: "Organic Rank",
            accessor: "organicRank",
        },
        {
            Header: "Sales",
            accessor: "keywordSales",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Spend",
            accessor: "keywordSpend",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Campaign Spend",
            accessor: "totalCampaignSpend",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Campaign",
            accessor: "campaignName",
            width: 250,
        },
        {
            Header: "Ignored",
            accessor: "acknowledged",
            Cell: cellInfo => cellInfo.value ? "Yes" : "No",
            width: 80,
        },
        {
            Header: "Actions",
            Cell: cellInfo => actionDropdown(cellInfo.original),
            width: 80,
        },
    ];

    const summaryColumns = [
        {
            Header: "Keyword",
            accessor: "keywordText",
        },
        {
            Header: "Sales",
            accessor: "keywordSales",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Spend",
            accessor: "keywordSpend",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Campaign Spend",
            accessor: "totalCampaignSpend",
            Cell: props => !isNaN(props.value) ? `$${parseInt(props.value).toFixed(2)}` : '$0',
        },
        {
            Header: "Campaign",
            accessor: "campaignName",
        },
        {
            Header: "Actions",
            width: 80,
            Cell: cellInfo => actionDropdown(cellInfo.original),
        },
    ];


    return (
        <ReactTable
            data={props.data}
            columns={props.summaryView ? summaryColumns : columns}
            style={{
                height: "500px",
            }}
            className="-striped -highlight -fixed"
            noDataText="No keywords found."
        />
    )
}

export default IdleKeywordTable;