import ReactTable from "react-table";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormFeedback,
  FormGroup,
  Input,
  CardTitle,
  Alert,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { DropdownList } from "react-widgets";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import Open from "../../../../../designUtils/Storeoverview/status/Open.svg"
import Assigned from "../../../../../designUtils/Storeoverview/status/Assigned.svg"
import Closed from "../../../../../designUtils/Storeoverview/status/Closed.svg"
import NeedInfo from "../../../../../designUtils/Storeoverview/status/NeedInfo.svg"
import Resolved from "../../../../../designUtils/Storeoverview/status/Resolved.svg"
import Updated from "../../../../../designUtils/Storeoverview/status/Updated.svg"
import InProgress from "../../../../../designUtils/Storeoverview/status/InProgress.svg"
import Pending from "../../../../../designUtils/Storeoverview/status/Pending.svg"
import AmazonTicketRaised from "../../../../../designUtils/Storeoverview/status/AmazonTicketRaised.svg"
import AmazonResponseReceived from "../../../../../designUtils/Storeoverview/status/AmazonResponseReceived.svg"
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor, SetInnerHtmlWithClassReplacement } from "../../../../../Utils/Constants";
import DashbordViewTicketService from "../../../../../Service/DashboardServices/DashboardTicketServices/DashbordViewTicketService";
function DashbordViewTicketPage(props) {
  const [ticketHistory, setTicketHistory] = useState([]);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [text, setText] = useState("");
  const [issueDescription, setIssueDescription] = useState(false);
  const [ticketHistoryText, setTicketHistoryText] = useState("");
  const [ticketHistoryUpdatedBy, setTicketHistoryUpdatedBy] = useState("");
  const [isTicketHistoryTextOpen, setIsTicketHistoryTextOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [productList,setProductList] = useState([])
  const [status, setStatus] = useState({
    ticketStatus: "",
  });
  const [searchText, setSearchText] = useState("");

  const [state, setState] = useState({
    ticketTitle: "",
    ticketRequestType: "",
    ticketDate: "",
    ticketStatus: "",
    ticketSubRequestType: "",
    Asin: "",
    issueDescription: "",
    Sku: "",
    problemAreaId: "",
    problemAreaTitle: '',
    problemAreaPathwayId: '',
    problemAreaPathwayTitle: '',
    priority: '',
    amazonCaseId: '',
    amazonCaseUrl: ''
  });
  const [loading, setLoading] = useState(false);
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };

  const setTicketInfo = () => {
    if (props.ticketList && props.ticketId) {
      var requestMainType = "";
      props.ticketList.map((ticket) => {
        {
          if (ticket.ticketId === props.ticketId) {
            props.mainRequestTypes.map((Type) => {
              {
                if (Type.id === ticket.mainRequestTypeId) {

                  let asinArray = []
                  let problemAreaPathwayTitle = ''
                  if (props.problemAreaPathwayList) {
                    props.problemAreaPathwayList.map((pap) => {
                      if (props.problemAreaPathwayId == pap.id) {
                        problemAreaPathwayTitle = pap.problemAreaPathway
                      }
                    })
                  }
                  if (ticket.productList.length > 0) {
                    setProductList(ticket.productList)
                    // asinArray = ticket.Asin[0][0].split(",");
                    console.log(ticket.productList)
                    // console.log(ticket.Asin[0])
                    // console.log(ticket.Asin[0][0])
                  }

                  setState({
                    ticketTitle: ticket.ticketTitle,
                    ticketDate: ticket.ticketCreatedAt,
                    ticketStatus: ticket.ticketStatus,
                    ticketRequestType: Type.requestType,
                    ticketSubRequestType: ticket.requestSubTypes,
                    issueDescription: ticket.issueDescription,
                    Asin: asinArray,//ticket.Asin,
                    Sku: ticket.Sku,
                    problemAreaPathwayTitle: ticket.problemAreaPathway,
                    priority: ticket.priority,
                    amazonCaseId: ticket.amazonCaseId,
                    amazonCaseUrl: ticket.amazonCaseUrl
                  });
                }
              }
            });
          }
          if (ticket.ticketId === props.ticketId) {
            props.ticketViewData.map((history) => {
              {
                if (history.ticketId === props.ticketId) {
                  setTicketHistory((prevTicketHistory) => [
                    ...prevTicketHistory,
                    history,
                  ]);
                }
              }
            });
          }
        }
      });
    }
  };
  const handleFileChange = (e) => {
    const newFiles = [...selectedFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setSelectedFiles(newFiles);
  };
  const handleQuillChange = (value) => {
    setText(value);
  };

  const handleStatusChange = (e) => {
    const { name, value } = e.target;
    setStatus({ ...status, [name]: value });
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    setTicketInfo();
  }, []);

  const filteredTicketHistory = ticketHistory.filter((row) => {
    const historyComment = row.ticketHistoryComment.toLowerCase();
    const ticketHistoryCreateBy = row.ticketHistoryCreateBy.toLowerCase();
    const ticketHistoryStatus = row.ticketHistoryStatus.toLowerCase();
    return historyComment.includes(searchText.toLowerCase()) || ticketHistoryCreateBy.includes(searchText.toLowerCase()) || ticketHistoryStatus.includes(searchText.toLowerCase());
  });

  const handleUpload = async (flag) => {
    if (state.ticketStatus === 'Closed') {
      return true
    }
    setLoading(true)
    const formData = new FormData();
    if (props.accessLevel == 0) {
      formData.append("ticketStatus", status.ticketStatus);
    } else if (props.accessLevel != 0 && flag == 1) {
      formData.append("ticketStatus", 1);
    } else {
      formData.append("ticketStatus", 0);
    }
    formData.append("ticketHistoryComment", text);
    formData.append("ticketId", props.ticketId);
    formData.append("storeName", props.storeOverview.name);
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("ticketAttachmentFile", selectedFiles[i]);
    }
    DashbordViewTicketService(props.token, formData).then((response) => {
      if (response) {
        console.log(response);
        setText("");
        setSelectedFiles(null);
        props.handleCreateAndViewTicketClose(response);
        setLoading(false);
      }
    });
  };
  const show = (path) => {
    const separatedPath = path.split("/");
    const ext = separatedPath[1].split(".")[1];
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
  const downloadFile = async (file) => {
    if (true) {
      try {
        let fileName = file.ticketAttachment.split('/')[1]
        fileName = fileName.split('.')[0]
        const response = await fetch("https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" + file.ticketAttachment);
        if (!response.ok) throw new Error(`Network response was not ok for ${file.ticketAttachmentId}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'downloaded_file';
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Failed to download files:', error);
      }
    }
  }
  const viewSingleHistory = (text, updatedBy) => {
    setTicketHistoryText(text)
    setTicketHistoryUpdatedBy(updatedBy)
    setIsTicketHistoryTextOpen(true)
  }
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const replaceIssueDescriptionText = SetInnerHtmlWithClassReplacement(state.issueDescription);
  const replaceticketHistoryText = SetInnerHtmlWithClassReplacement(ticketHistoryText);


  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls'];
  const columns = [
    {
      Header: "Date",
      accessor: "ticketHistoryCreatedAt",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Comments",
      accessor: "ticketHistoryComment",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0 comment-paragraph" style={{ color: '#3D85C6', cursor: 'pointer' }} onClick={() => viewSingleHistory(cellInfo.value, cellInfo.original.ticketHistoryCreateBy)}>{stripHtmlTags(cellInfo.value)}</p>
        </div>
      ),
    },
    {
      Header: "Updated by",
      accessor: "ticketHistoryCreateBy",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "ticketHistoryStatus",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <span
            className={
              cellInfo.value === "Closed"
                ? "btn btn-sm text-primary "
                : cellInfo.value === 'New'
                  ? "btn btn-sm text-danger"
                  : cellInfo.value === "Resolved"
                    ? "btn btn-sm text-success"
                    : cellInfo.value === "Need info"
                      ? "btn btn-sm text-info "
                      : cellInfo.value === "Informed"
                        ? "btn btn-sm text-secondary "
                        : cellInfo.value === "Ticket updated"
                          ? "btn btn-sm text-warning"
                          : cellInfo.value === "Assigned"
                            ? "btn btn-sm text-warning"
                            : "btn btn-sm"
            }
          >
            {cellInfo.value}
          </span>
        </div>
      ),
    },
    // {
    //   Header: "Attachment",
    //   accessor: "ticketAttachment",
    //   Cell: (cellInfo) => (
    //     <div className="d-flex justify-content-center w-100">
    //       {/* <a className="m-0" href={cellInfo.value} target="_blank">
    //       {cellInfo.value}
    //       </a> */}
    //       <Button  className='btn_class' onClick={()=>show(cellInfo.value)}> view</Button>
    //     </div>
    //   ),
    // },
  ];
  const ReadMore = ({ htmlContent, maxLength }) => {

    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    const truncatedContent = htmlContent.length > maxLength ? htmlContent.substring(0, maxLength) + '...' : htmlContent;
    return (
      <div className="">
        <span className="issue-description-set d-flex flex-wrap " dangerouslySetInnerHTML={{ __html: truncatedContent }} />
        {htmlContent.length > maxLength && (
          <span  style={{fontSize:'13px'}} onClick={toggleReadMore} className="cursor-pointer d-flex justify-content-end">
            {isExpanded ? ' Read Less' : '....Read More'}
          </span>
        )}
      </div>
    )
  };
  return (
    <Container fluid className="">
      <Card className='p-2 card-of-problemarea'>
        <Row className="m-0 justify-content-center" style={{ fontSize: "19px" }}>
          <Col md={12}>
            <Row>
              <Col md={6}>
                {state.amazonCaseId && (state.amazonCaseId > 0) ?
                  <Label for="Request Sub Type">
                    <b>Amazon Case Id : </b><a href={state.amazonCaseUrl} target='_blank'>{state.amazonCaseId}</a>
                  </Label>
                  : ''}
              </Col>
              <Col md={6}>
                <div className='img-class-button pb-0 pt-0 d-flex justify-content-end'>
                  <p className={state.ticketStatus === 'New' ? 'Open-button mb-0 pt-0' :
                    state.ticketStatus === "Closed" ? 'closed-button  pb-0 pt-0' :
                      state.ticketStatus === "Resolved" ? 'Resolved-button pb-0 pt-0' :
                        state.ticketStatus === "Need info" ? 'Needinfo-button pb-0 pt-0' :
                          state.ticketStatus === "Informed" ? 'Inprogress-button pb-0 pt-0' :
                          state.ticketStatus === "Need info" ? 'Needinfo-button pb-0 pt-0' :
                          state.ticketStatus === "Amazon Ticket Raised" ? 'amazon-ticket-raised  pb-0 pt-0' :
                            state.ticketStatus === "Ticket Updated" ? 'Ticketupdat-button pb-0 pt-0' :
                            state.ticketStatus === "Amazon Response Received" ? 'amazon-response-received  pb-0 pt-0' :
                              state.ticketStatus === "Assigned" ? 'Assigned-button pb-0 pt-0' :
                                state.ticketStatus === "Pending" ? 'Pending-button pb-0 pt-0' : ''}>
                    {state.ticketStatus === "Closed" ? <img className="img-class" src={Closed} /> :
                      state.ticketStatus === 'New' ? <img className="img-class" src={Open} /> :
                        state.ticketStatus === "Resolved" ? <img className="img-class" src={Resolved} /> :
                        state.ticketStatus === 'Amazon Ticket Raised' ? <img className="img-class" src={AmazonTicketRaised} /> :
                        state.ticketStatus === 'Amazon Response Received' ? <img className="img-class" src={AmazonResponseReceived} /> :
                          state.ticketStatus === "Informed" ? <img className="img-class" src={InProgress} /> :
                            state.ticketStatus === "Ticket Updated" ? <img className="img-class" src={Updated} /> :
                              state.ticketStatus === "Assigned" ? <img className="img-class" src={Assigned} /> :
                                state.ticketStatus === "Pending" ? <img className='img-class' src={Pending} /> :
                                  state.ticketStatus === "Need info" ? <img className="img-class" src={NeedInfo} /> :
                                    ''}
                    <span style={{ color: 'white' }} className="status-text">{state.ticketStatus}</span>
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <Label htmlFor="subRequestType">
                  <b>Products{productList ? `(${productList.length})` : ''}</b>
                </Label>
                <div
                  className={
                    productList
                      ? productList.length > 1
                        ? "scrollbar "
                        : " "
                      : ""
                  }
                >
                  {productList ? (
                    productList.length > 0 ? (
                      productList.map((product) => (
                        <>
                          <Row className="border row-background-color justify-content-center p-0 m-0">
                            {/* <Col md="2" className="p-2">
                              <div>
                                <img
                                  className="ml-0"
                                  width={"100%"}
                                  src={product.itemImage}
                                />
                              </div>
                            </Col> */}
                            <Col md={12} className="p-2">
                              <div>
                                <h6>
                                  <b>
                                    {product.asin} :
                                  </b> &nbsp;
                                  <a
                                    href={product.link}
                                    className="text-decoration-none "
                                    target="blank"
                                  >
                                    {product.productTitle}
                                  </a>
                                </h6>
                                <div className="">
                                  {/* <Row>
                                    <Col md={5}>
                                      <span>
                                        <b>ASIN : </b> 
                                      </span>
                                    </Col>
                                    <Col md={7}>
                                      {/* <div>
                                                      <DropdownWithCheckbox options={product.skus} onSelect={(values) => handleSelect(values, product.asin)} />
                                                    </div> 
                                    </Col>
                                  </Row> */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ))
                    ) : (
                      <p className="text-center"> No product selected .</p>
                    )
                  ) : (
                    <p className="text-center"> No product selected .</p>
                  )}
                </div>
              </Col>

              <Col md={12} className='mt-2 mb-2'>
              <Label for="Created Date">
                    <b>Issue Description : </b>
                  </Label>
                <div className='custom-issue-dis-box'>
                  <ReadMore htmlContent={replaceIssueDescriptionText} maxLength={200} />
                </div>
                {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} /> */}
              </Col>

              {/* <Col md={5}>
                <Label for="Created Date">
                  <b>Priority : </b>
                </Label>
                <p>{state.priority}</p>
              </Col> */}

              <Col md={12} className='mt-2 mb-2'>
                {state.ticketStatus !== "Closed" && props.accessLevel !== 0 && (
                  <>

                    {/* <Input
                    id="ticketHistoryComment"
                    placeholder="Add Your Comments Here."
                    value={text}
                    rows={5}
                    type="textarea"
                    name="ticketHistoryComment"
                    onChange={handleTextChange}
                  /> */}
                    <ReactQuill
                      theme={'snow'}
                      modules={Editor.modules}
                      formats={Editor.formats}
                      value={text}
                      onChange={handleQuillChange}
                      bounds={'.quill-prod'}
                      placeholder='Add Your Comments Here' />
                  </>
                )}

              </Col>

              {state.ticketStatus !== "Closed" && props.accessLevel !== 0 && (
                <>
                  <Col md={6}>
                    {/* <Input
                      id="ticketAttachment"
                      name="ticketAttachment"
                      className="mt-3"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    /> */}
                    <label htmlFor="fileInput" className="custom-file-input-label text-center">
                      <i className="fas fa-upload"></i><br /> Choose File
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      name="ticketAttachment"
                      className="custom-file-input"
                      onChange={handleFileChange}
                    />
                  </Col>
                  <Col md={6}>
                    <span style={{ fontSize: "13px" }}>Attached File List</span>
                    <ul style={{ color: "green", fontSize: "13px" }}>
                      {selectedFiles
                        ? selectedFiles.map((file, index) => (
                          <li key={index}>
                            {file.name}
                            <button
                              className="btn"
                              onClick={() => handleRemoveFile(file)}
                            >
                              <FontAwesomeIcon
                                color="red"
                                size="15px"
                                icon={faWindowClose}
                              />
                            </button>
                          </li>
                        ))
                        : ""}
                    </ul>
                  </Col>
                </>
              )}




            </Row>
            <Row>
              <Col md={12} className='mb-2 mt-2'>
                <span>
                  {state.ticketStatus !== "Closed" && props.accessLevel !== 0 && (
                    <Button
                      className="btn_class float-right"
                      size="lg"
                      onClick={() => handleUpload(2)}
                      disabled={!text ? true : (loading ? true : false)}
                    >
                      {loading ? 'Saving...' : 'Save'}
                    </Button>
                  )}
                  {state.ticketStatus === "Resolved" &&
                    props.accessLevel !== 0 ? (
                    <Button
                      className="btn_class float-right mr-2"
                      size="lg"
                      onClick={() => handleUpload(1)}
                      disabled={!text ? true : (loading ? true : false)}
                    >
                      {loading ? 'Closing...' : 'Close'}
                    </Button>
                  ) :
                    ""
                  }
                </span>
                <p className="text-center">
                  {state.ticketStatus === "Closed" && (
                    <Alert>Note: This ticket is closed.</Alert>
                  )}
                  {/* {state.ticketStatus !== 'Closed' && (
                <Button
                  className="btn_class"
                  size="lg"
                  onClick={handleUpload}
                >
                  Save
                </Button>
              )} */}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row className="mt-3 ml-0 mr-0">
        <Col md="12" className="mbg-3 font-size-lg p-0">
          <Card className="main-card mb-3 mt-0 card-of-problemarea">
            <CardBody className="pt-0">
              <CardTitle
                className="pt-2"
                style={{ fontSize: "20px", color: "#6c757d" }}
              >
                Ticket History
              </CardTitle>
              <div>
                <input
                  type="text"
                  className="form-control w-25 mb-3"
                  placeholder="Search Ticket History"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>
              <ReactTable
                data={filteredTicketHistory}
                columns={columns}
                className="-fixed -highlight -striped"
                getTheadProps={() => {
                  return {
                    style: {
                      background: "#B1FA63",
                      overflowY: "hidden",
                      color: "#243837",
                    },
                  };
                }}
                defaultPageSize={5}
                noDataText={"No Data Found."}
                SubComponent={(row) => (
                  <div className="p-1">
                    {row.original.ticketAttachment.length > 0 ? (
                      <ReactTable
                        data={row.original.ticketAttachment}
                        getTheadProps={() => {
                          return {
                            style: {
                              background: "#B1FA63",
                              overflowY: "hidden",
                              color: "#243837",
                            },
                          };
                        }}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "ticketAttachment",
                            Cell: (cellInfo) => (
                              <div className="d-flex justify-content-center w-100">
                                {cellInfo.value.split("/")[1]}
                              </div>
                            ),
                          },
                          {
                            Header: "Action",
                            accessor: "ticketAttachment",
                            Cell: (cellInfo) => (
                              <div className="d-flex justify-content-center w-100">
                                {
                                  fileExtensions.includes(cellInfo.value.split('.')[1]) ? (
                                    <FontAwesomeIcon
                                      className="ml-3"
                                      style={{ cursor: 'pointer' }}
                                      icon={faDownload}
                                      onClick={() => downloadFile(cellInfo.original)}
                                    />
                                  ) : (
                                    <>
                                      <FontAwesomeIcon
                                        className="ml-3"
                                        style={{ cursor: 'pointer' }}
                                        icon={faEye}
                                        onClick={() => show(cellInfo.value)}
                                      />
                                      <FontAwesomeIcon
                                        className="ml-3"
                                        style={{ cursor: 'pointer' }}
                                        icon={faDownload}
                                        onClick={() => downloadFile(cellInfo.original)}
                                      />
                                    </>
                                  )
                                }

                              </div>
                            ),
                          },
                        ]}
                        className="-striped -highlight"
                        defaultPageSize={row.original.ticketAttachment.length}
                        noDataText="No Attachments available"
                      />
                    ) : (
                      <p className="text center">No Attachments.</p>
                    )}
                  </div>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CustomModal
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'ticketAttachment': filePath, 'ticketAttachmentId': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsAttachmentOpen(!isAttachmentOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <div>
            <iframe
              src={
                "https://simpliworks-project-onboarding-dev.s3.amazonaws.com/images/" +
                filePath
              }
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>
      <CustomModal
        header={"Comment " + "By - " + ticketHistoryUpdatedBy}
        isOpen={isTicketHistoryTextOpen}
        toggle={() => setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)}
        size={"lg"}
        className='curve-popup'
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsTicketHistoryTextOpen(!isTicketHistoryTextOpen)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label>
          <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceticketHistoryText }} />
        </Label>
      </CustomModal>
      <CustomModal
        header={"Issue Description"}
        isOpen={isExpanded}
        toggle={() => setIsExpanded(!isExpanded)}
        size={"lg"}
        className="curve-popup"
        footer={
          <>
            <Button
              color={"link"}
              onClick={() =>
                setIsExpanded(!isExpanded)
              }
            >
              Cancel
            </Button>
          </>
        }
      >
        <p style={{ fontSize: "19px" }} className="issue-discription-set" dangerouslySetInnerHTML={{ __html: replaceIssueDescriptionText }} />
        {/* <Label>{ticketHistoryText}</Label> */}
      </CustomModal>
    </Container>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
  };
};

export default connect(mapStateToProps)(DashbordViewTicketPage);
