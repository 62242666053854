import axios from 'axios';
import { CURRENT_API_URL } from "../../Utils/Constants";
import {UpdateSubscriptionLevel} from "../../Redux/Actions/Actions";
import notify from '../../Notifications/ToastifyActions';


function SubscriptionEmailService(token, email) {
    return function(dispatch) {

        axios.post(`${CURRENT_API_URL}/payments/retrieve-customer`, {
            email: email,
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                dispatch(UpdateSubscriptionLevel(res.data.subscriptionLevel));
                notify.NotifySuccess("Your subscription was linked to your account successfully!");
                return true;
            })
            .catch(() => {
                notify.NotifyError("An error occurred connecting your subscription. Please ensure you are using the correct email address and try again.");
                return false;
            })
    }
}


export default SubscriptionEmailService;