import actionType from '../Actions/ActionTypes';


const initialState = {
    usersDirectory: [],
    storesDirectory: [],
    managedStoreFirstDirectory: [],
    unmanagedStoreFirstDirectory: [],
    allProductsDirectory : [],
    contentAgencies: [],
    projects: [],
    productCategories: [],
    researchProductCategoryData:[],
    leadGenJobs:[],
    AmazonProductCategories: [],
    amazonDomains:[],
    sellerPartnerList:[],
    addKeywordTrackingHubSpotPaymentDetails:[],
    status: '',    
};


function ProfileReducer(state = initialState, action) {
    switch(action.type) {
        case actionType.LOAD_DIRECTORIES_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_DIRECTORIES_SUCCESS:
            return {
                ...state,
                status: 'success',
                usersDirectory: action.usersDirectory,
                storesDirectory: action.storesDirectory,
                managedStoreFirstDirectory: action.managedStoreFirstDirectory,
                unmanagedStoreFirstDirectory: action.unmanagedStoreFirstDirectory,
                allProductsDirectory : action.allProductsDirectory,
            }
        case actionType.LOAD_DIRECTORIES_FAILED:
            return {
                ...state,
                status: 'failed',
            }
        case actionType.LOAD_STORES_DIRECTORIES_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_STORES_DIRECTORIES_SUCCESS:
            return {
                ...state,
                status: 'success',               
                storesDirectory: action.storesDirectory,
                managedStoreFirstDirectory: action.managedStoreFirstDirectory,
                unmanagedStoreFirstDirectory: action.unmanagedStoreFirstDirectory,
            }
        case actionType.LOAD_STORES_DIRECTORIES_FAILED:
            return {
                ...state,
                status: 'failed',
            }
        case actionType.LOAD_CONTENT_AGENCIES_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_CONTENT_AGENCIES_SUCCESS:
            return {
                ...state,
                status: 'success',
                contentAgencies: action.contentAgencies,
            }
        case actionType.LOAD_CONTENT_AGENCIES_FAILED:
            return {
                ...state,
                status: 'failed',
            }
        case actionType.LOAD_PROJECTS_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                status: 'success',
                projects: action.projects,
            }
        case actionType.LOAD_PROJECTS_FAILED:
            return {
                ...state,
                status: 'failed',
            }
        
        case actionType.LOAD_PRODUCT_CATEGORIES_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                status: 'success',
                productCategories: action.productCategories,
            }
        case actionType.LOAD_PRODUCT_CATEGORIES_FAILED:
            return {
                ...state,
                status: 'failed',
                }
        case actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_SUCCESS:
            return {
                ...state,
                status: 'success',
                researchProductCategoryData: action.researchProductCategoryData,
            }
        case actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_FAILED:
            return {
                ...state,
                status: 'failed',
                }
        case actionType.LOAD_LEAD_GEN_JOBS_BEGIN:
            return {
                ...state,
                status: 'loading',
            }
        case actionType.LOAD_LEAD_GEN_JOBS_SUCCESS:
            return {
                ...state,
                status: 'success',
                leadGenJobs: action.leadGenJobs,
            }
        case actionType.LOAD_LEAD_GEN_JOBS_FAILED:
            return {
                ...state,
                status: 'failed',
                }

        case actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_BEGIN:
            return {
                ...state,
                status: 'loading',
                }
        case actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_SUCCESS:
            return {
                ...state,
                status: 'success',
                AmazonProductCategories: action.AmazonProductCategories,
                }
        case actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_FAILED:
            return {
                ...state,
                status: 'failed',
                }
        case actionType.LOAD_AMAZON_DOMAINS_BEGIN:
            return {
                ...state,
                status: 'loading',
                }
        case actionType.LOAD_AMAZON_DOMAINS_SUCCESS:
            return {
                ...state,
                status: 'success',
                amazonDomains: action.amazonDomains,
                }
        case actionType.LOAD_AMAZON_DOMAINS_FAILED:
            return {
                ...state,
                status: 'failed',
                }
    
        case actionType.LOAD_SELLER_PARTNER_LIST_BEGIN:
            return {
                ...state,
                status: 'loading',
                }
        case actionType.LOAD_SELLER_PARTNER_LIST_SUCCESS:
            return {
                ...state,
                status: 'success',
                sellerPartnerList: action.sellerPartnerList,
                }
        case actionType.LOAD_SELLER_PARTNER_LIST_FAILED:
            return {
                ...state,
                status: 'failed',
                }
        case actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_BEGIN:
            return {
                ...state,
                status: 'loading',
                }
        case actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_SUCCESS:
            return {
                ...state,
                status: 'success',
                addKeywordTrackingHubSpotPaymentDetails: action.addKeywordTrackingHubSpotPaymentDetails,
                }
        case actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_FAILED:
            return {
                ...state,
                status: 'failed',
                }
        default:
            break;
    }
    return state;
}

export default ProfileReducer;
