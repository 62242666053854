import React from 'react'
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Form, Formik } from "formik";
import * as Yup from 'yup';

import {
    AddToCart,
    ResetAutofillFields,
    SetAutofillASIN,
    SetDefaultSellerId
} from "../../../../../../../Redux/Actions/Actions";
import products from "../../../../../../../Utils/Products";
import { COUNTRIES } from '../../../../../../../Utils/Constants';


// determines whether the next and previous buttons should appear for each step's card container
const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            showPreviousBtn: true,
            showNextBtn: true
        }
    } else if (currentStep === 0) {
        return {
            showPreviousBtn: false,
            showNextBtn: true
        }
    } else {
        return {
            showPreviousBtn: true,
            showNextBtn: false
        }
    }
}


class CreateCampaignForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPreviousBtn: false,
            showNextBtn: true,
            compState: 0,   // index of current step object in steps array
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleAutofillASIN = this.handleAutofillASIN.bind(this);
    }

    campaignSchema = Yup.object().shape({
        sellerProfileId: Yup.number()
            .oneOf(this.props.adProfiles.map(profile => profile.integerID), 'You must select a valid seller profile.')
            .required('Required'),
        productASIN: Yup.string()
            .length(10, 'Product ASIN must be 10 characters long.')
            .required('Required'),
        advertisingObjective: Yup.string()
            .oneOf(products.keys, 'You must select a valid advertising style.')
            .required('You must select an advertising style.'),
        dailyBudget: Yup.number()
            .transform(value => (isNaN(value) ? undefined : value))
            .min(1.00, 'Daily budget must be at least $1.00')
            .required('Required'),
        bidPriceExact: Yup.number()
            .transform(value => (isNaN(value) ? undefined : value))
            .min(0.02, 'Starting bid price must be at least $0.02')
            .required('Required'),
        bidPricePhrase: Yup.number()
            .transform(value => (isNaN(value) ? undefined : value))
            .min(0.02, 'Starting bid price must be at least $0.02')
            .required('Required'),
        country: Yup.string()
            .oneOf(COUNTRIES, 'You must select a marketplace.')
            .required('Required'),
        productTargetingEnabled: Yup.boolean(),
        bidPriceTargeting: Yup.number()
            .when("productTargetingEnabled", {
                is: true,
                then: Yup.number()
                    .transform(value => (isNaN(value) ? undefined : value))
                    .min(0.02, 'Starting bid price must be at least $0.02')
            })
    });

    // determines the style of each step based on whether its done, invalid inputs result in 'error' styling
    getNavStyles = (indx, length, stepIsValid) => {
        let styles = [];
        for (let i = 0; i < length; i++) {
            if (i < indx) {
                (stepIsValid && !stepIsValid[i]) ? styles.push('error') : styles.push('done');
            } else if (i === indx) {
                styles.push('doing');
            } else {
                styles.push('todo');
            }
        }

        return styles;
    }

    // sets all state fields based on the provided step index
    setNavState = next => {
        if (next < this.props.steps.length) {
            this.setState({compState: next})    // sets compState (idx of current step component)
        }
        this.setState(checkNavState(next, this.props.steps.length))     // sets prev. and next button states
    }

    // allows user to press "enter" key to progress through form steps
    handleKeyDown = evt => {
        if (evt.which === 13) {
            this.next()
        }
    }

    // allows user to click step component to progress through form
    handleOnClick = evt => {
        if (
            evt.currentTarget.value === this.props.steps.length - 1 &&
            this.state.compState === this.props.steps.length - 1
        ) {
            this.setNavState(this.props.steps.length)
        } else {
            this.setNavState(evt.currentTarget.value)
        }
    }

    next = () => {
        this.setNavState(this.state.compState + 1)
    }

    previous = () => {
        if (this.state.compState > 0) {
            this.setNavState(this.state.compState - 1)
        }
    }

    getStepStyles = (formik) => {
        const { errors } = formik;
        // holds whether a step's fields are valid (i.e. if stepIsValid = [false, true, true] then steps 2, 3 are valid and step 1 is invalid.)
        let stepIsValid = this.props.steps.map(step => {
            // return step.fields.every(stepField => !errors[stepField] && touched[stepField]);
            return step.fields.every(stepField => !errors[stepField]);
        });
        return this.getNavStyles(this.state.compState, this.props.steps.length, stepIsValid);
    }

    // changes class name of steps to adjust their styling based on what step of the form the user is on
    getClassName = (className, i, styles) => {
        return className + '-' + styles[i];
    }

    // renders form steps with icon and name
    renderSteps = (formik) => {
        let styles = this.getStepStyles(formik);
        return this.props.steps.map((s, i) => (
            <li
                className={this.getClassName('form-wizard-step', i, styles)}
                onClick={this.handleOnClick}
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span>{this.props.steps[i].name}</span>
            </li>
        ))
    }

    renderStepComponent = (formik) => {
        const { compState } = this.state;
        let currentStep = this.props.steps[compState];

        if (!currentStep) return;

        return React.cloneElement(currentStep.component, formik);
    }

    handleSubmit = (campaign) => {
        this.props.dispatch(AddToCart(campaign));
        // this.props.dispatch(SetDefaultSellerId(campaign.sellerProfileId));
        this.resetFormWizard();
    };

    resetFormWizard = () => {
        this.setState({
            showPreviousBtn: false,
            showNextBtn: true,
            compState: 0,   // index of current step object in steps array
        });
    }
    // handleAutofillASIN = () => {
    //     const asin = this.props.autofillASIN;
    //     if (asin !== '') {
    //         this.props.dispatch(SetAutofillASIN(''));
    //     }
    //
    //     return asin;
    // }

    handleAutofillField = () => {
        const fieldValues = Object.assign({},this.props.initialFields);
        this.props.dispatch(ResetAutofillFields());

        try {
            if (fieldValues.sellerProfileId === '') fieldValues.sellerProfileId = this.props.adProfiles[0].integerID;
            if (fieldValues.country === '') fieldValues.country = COUNTRIES[0];
        } catch (error) {
            console.log(error.message)
        }


        return fieldValues;
    }

    fieldsTouched = (formik) => {
        Object.keys(formik.values).forEach(key => {
            formik.setFieldTouched(key, true)
        })
    }

    render() {
        return (
            <div onKeyDown={this.handleKeyDown}>
                <Formik
                    initialValues={{
                        ...this.handleAutofillField(),
                    }}
                    initialTouched={{
                        sellerProfileId: true,
                        country: true,
                    }}
                    validateOnMount={true}
                    validationSchema={this.campaignSchema}
                    onSubmit={(campaign, formikBag) => {
                        this.handleSubmit(campaign);
                        formikBag.resetForm();
                        formikBag.validateForm();
                    }}
                >
                    {formik => (
                        <Form>
                            <ol className='forms-wizard'>
                                {this.renderSteps(formik)}
                            </ol>

                            { this.renderStepComponent(formik) }

                            <div className="divider"/>
                            <div className="clearfix">
                                <div style={this.props.showNavigation ? {} : {display: 'none'}}>
                                    <Button color="secondary" className="btn-shadow float-left btn-wide btn-pill" outline
                                            style={this.state.showPreviousBtn ? {} : {display: 'none'}}
                                            onClick={() => {
                                                this.fieldsTouched(formik);
                                                this.previous();
                                            }}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        color="primary"
                                        className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
                                        style={this.state.showNextBtn ? {} : {display: 'none'}}
                                        onClick={() => {
                                            this.fieldsTouched(formik);
                                            this.next();
                                        }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

CreateCampaignForm.defaultProps = {
    showNavigation: true
}

const mapStateToProps = state => {
    return {
        // autofillASIN: state.marketAnalysis.autofillASIN,
        sellerProfileIdList: state.loggedUser.sellerProfileIdList,
        defaultSellerId: state.loggedUser.defaultSellerId,
        initialFields: state.campaignBuilder.autofillFields,
        adProfiles: state.accountOverview.landing.adProfiles,
    }
}

export default connect(mapStateToProps)(CreateCampaignForm);
