import React, { Fragment, useEffect, useState } from "react";
import {
    Card, CardBody, Row, Col, Container, Alert,
} from 'reactstrap';
import CreateProjectForm from '../Screens/Create/CreateProjectForm';
import ProjectDetails from '../Screens/Create/ProjectDetails';
import UploadProjectFiles from '../Screens/Create/UploadProjectFiles';
import SubmitProject from '../Screens/Create/SubmitProject';
import { connect } from 'react-redux';
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from "react-router-dom";
import actionType from '../../../../../Redux/Actions/ActionTypes';
import { ToastContainer, toast } from 'react-toastify';

const NewProjectPage = ({ currentStore, loading, dispatch, storeDetails, status }) => {

    const { id } = useParams();
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        if (id && !loading && storeDetails.length > 0 && !isLoaded) {
            setIsLoaded(true)
            dispatch({
                type: actionType.SET_CURRENT_STORE,
                payload: {
                    id: id
                }
            })
        }
    }, [currentStore])

    const steps = [
        {
            name: 'Project Details',
            id: 'projectDetails',
            fields: ['productName',
                'productsku',
                'productId',
                'onAmazon',
                'idType',
                'variation',
                'serviceSEOCopy',
                'serviceGalleryPhotos',
                'serviceAPlusContent'],
            component: <ProjectDetails />
        },
        {
            name: 'Upload Files',
            id: 'uploadFiles',
            fields: ['sellerProfileId', 'dailyBudget', 'bidPriceExact', 'bidPricePhrase', 'productSKU', 'marketplace', 'productTargeting'],
            component: <UploadProjectFiles />
        },
        {
            name: 'Submit Project',
            id: 'submitProject',
            fields: [],
            component: <SubmitProject />
        }
    ]


    const onAlert = (type) => {
        console.log(status)
        if (type === 'success') {
            toast.success("Successfully created new Project", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            })
            // setIsSuccess(true);
            // setTimeout(() => {
            //     setIsSuccess(false)
            // }, 8000)
        }
        else if (type === 'failure') {
            toast.error("Project can't be created right now. Please try again later.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            })
            // setIsFailure(true);
            // setTimeout(() => {
            //     setIsFailure(false)
            // }, 8000)
        }
    }

    const onFileLimitError = (msg) => {
        toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }

    const renderProductForm = () => {
        return (
            <Fragment>
                <Row>
                    <Col md="12" className="mbg-3">
                        <h4 className="mb-2">New Project for {currentStore.name} store</h4>
                        {/* { props.adProfiles.length === 0 &&
                                    <p className="invalid-feedback" style={{display: 'block', fontSize: '100%'}}>
                                        We could not find any profile IDs associated with your Amazon Seller account,
                                        please create a profile in Amazon Seller Central.
                                    </p>
                                } */}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <div className="forms-wizard-horizontal">
                                    <CreateProjectForm showNavigation={true} steps={steps} onAlert={onAlert} onFileLimitError={onFileLimitError} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }


    return (
        <Fragment>
            <div>
                <Container fluid>
                    <ToastContainer />
                    {/* <div className="my-2">
                        <Alert color="success" isOpen={isSuccess} toggle={() => onDismiss('success')}>
                            {status}
                        </Alert>
                        <Alert color="danger" isOpen={isFailure} toggle={() => onDismiss('failure')}>
                            {status}
                        </Alert>
                    </div> */}
                    <Row>
                        <Col md="12">
                            <LoadingOverlay
                                active={loading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        opacity: 1,
                                        zIndex: 10000
                                    }),
                                    content: (base) => ({
                                        ...base,
                                        color: "#000"
                                    })
                                }}
                                spinner={<Loader active type="ball-pulse" />}
                                text='Loading...'
                            >
                                {currentStore ? renderProductForm() : null}
                            </LoadingOverlay>

                        </Col>
                    </Row>

                </Container>
            </div>
        </Fragment>
    )
}

const mapToProps = (state) => {
    return {
        currentStore: state.project.currentStore,
        token: state.loggedUser.token,
        loading: state.project.loading,
        storeDetails: state.project.storeDetails,
        status: state.project.apiStatus
    }
}

export default connect(mapToProps)(NewProjectPage);