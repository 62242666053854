import React, { useState } from "react";
import {Col, Row, Form, FormGroup, Label, Input} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoginService from "../../../../../Service/EntryServices/LoginService";
import urls from "../../../../../Utils/URLs";
import LoadingButton from "../../../../Components/Buttons/LoadingButton";
import New_LOGO from '../../../../../assets/utils/images/New_LOGO.svg'
// import New_LOGO from '../../../../../assets/utils/images/New_LOGO.png'

function LoginForm(props) {

    const [state, setState] = useState({
        email: '',
        password: '',
    });

    const handleChange = e => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value,
        }));
    }

    const handleSubmit = () => {
        props.dispatch(LoginService({
            email: state.email,
            password: state.password,
        }));
    }

    const handleKeyDown = e => {
        if (state.email.length && state.password.length && e.which === 13) {
            handleSubmit();
        }
    }


    return (
        <div className="modal-content card-of-problemarea p-0">
            <div className="modal-body" onKeyDown={handleKeyDown}>
                <div className="text-center mx-auto mb-3">
                <img src={New_LOGO} width={230} height={60} className=''/>
                </div>
                <div className="h5 modal-title text-center">
                    <p className="mt-2">
                        <span>Please Sign In </span>
                    </p>
                </div>
                <Form>
                    <Row form className='pt-1 pl-2 pr-2 pb-3'>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="email" style={{color:'black'}}>E-mail</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={state.email}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="password" style={{color:'black'}}>Password</Label>
                                <Input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={state.password}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                        <LoadingButton
                            size="lg"
                            className="w-100 login-btn"
                            onClick={() => handleSubmit()}
                            disabled={(!(state.email.length && state.password.length))}
                            loading={props.status === 'loading'}
                        >
                           <b>Login</b>
                        </LoadingButton>
                        </Col>
                        <Col md={12}>
                        <div className="float-right">
                            <Link className="btn-lg btn btn-link text-color" to={urls.ACCOUNT_RECOVERY_PASSWORD_RESET}>Forgot Password?</Link>
                        </div>
                        </Col>
                        <Col md={12}>
                        <h6 className="mb-0 text-center">
                            Don't have an account? 
                            <Link className="text-color" to={urls.REGISTER_SELLER_URL}> Sign up</Link>
                        </h6>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        logged: state.loggedUser.logged,
        status: state.loggedUser.status,
    }
}

export default connect(mapStateToProps)(LoginForm);
