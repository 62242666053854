import React, { Fragment } from 'react';
import {
    Row, Col, Card, CardBody, Button
} from 'reactstrap';

const ProductItem = ({ product, onSelectProduct }) => {


    return (
        <Fragment>
            <Card className="main-card mb-1">
                <CardBody>
                    <Row>
                        <Col md="2">
                            <img src={product.itemImage} className="img-fluid" />
                        </Col>
                        <Col md="8">
                            <p>{product.productTitle}</p>
                        </Col>
                        <Col md="2">
                            <Button type="button" className="btn-sm ml-2 float-right" onClick={() => onSelectProduct(product)}>Select</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default ProductItem;