import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function UpdateStoreProducts(token,profileId) {
    notify.NotifySuccess("User store products are being updated.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/UpdateStoreProductsDetails`, {
        profileId: profileId,      
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("All user store products have been updated successfully.");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while updating store products, please try again.");
        return false;
    });
}

export default UpdateStoreProducts;