import actionType from '../../Actions/ActionTypes';

const initialState = {
    enableMobileMenu: false,
    enableClosedSidebar: false,
};

function ThemeReducer(state = initialState, action) {
    switch(action.type) {
        case actionType.SET_ENABLE_MOBILE_MENU:
            return {
                ...state,
                enableMobileMenu: action.enableMobileMenu,
            }
        case actionType.SET_ENABLE_CLOSED_SIDEBAR:
            return {
                ...state,
                enableClosedSidebar: action.enableClosedSidebar,
            }
        default:
            break;
    }

    return state;
}

export default ThemeReducer;