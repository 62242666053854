import React, { Fragment, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import CampaignOverviewService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/CampaignServices/CampaignOverviewService";
import LaunchCampaignService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/LaunchpadServices/LaunchCampaignService";
import StoreListService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreListService";
import ReadyCampaignDetailsModal from "./Components/ReadyCampaignDetailsModal";
import CampaignWidgetList from "../../../../Components/Widgets/Campaigns/CampaignWidgetList";
import CampaignKeywordModal from "../../../../Components/Modals/EditKeywordModal";
import CampaignTargetedProductModal from "../../../../Components/Modals/CampaignTargetedProductModal";
import DeleteReadyCampaignService
    from "../../../../../../Service/DashboardServices/AccountOverviewServices/LaunchpadServices/DeleteReadyCampaignService";


function ReadyToLaunchPage(props) {

    const [isOpen, setIsOpen] = useState(false);
    const [keywordIsOpen, setKeywordIsOpen] = useState(false);
    const [productIsOpen, setProductIsOpen] = useState(false);

    const loadCampaignInfo = (name, campaignID) => {
        props.dispatch(CampaignOverviewService(props.token, name, campaignID));
    }

    const deleteCampaign = async (name, collectionId) => {
        await DeleteReadyCampaignService(props.token, name, collectionId);
        props.dispatch(StoreListService(props.token));
    }

    const quickActions = [
        {
            name: 'View Keywords',
            action: (name, campaignID) => {
                loadCampaignInfo(name, campaignID);
                setKeywordIsOpen(!keywordIsOpen)
            },
        },
        // {
        //     name: 'Manage Targeted Products',
        //     action: (name, collectionId) => {
        //         loadCampaignInfo(name, collectionId);
        //         setProductIsOpen(!productIsOpen);
        //     },
        // },
        {
            name: 'Delete Campaign',
            action: (name, collectionId) => {
                deleteCampaign(name, collectionId);
            },
        },
    ];

    const handleCampaignDetailsRedirect = (name, collectionId) => {
        props.dispatch(CampaignOverviewService(props.token, name, collectionId));
        setIsOpen(true);
    }

    const handleLaunchCampaign = async (name, collectionID, selectedKeywords) => {
        let response = await LaunchCampaignService(props.token, name, collectionID, selectedKeywords);
        if (response) {
            props.dispatch(StoreListService(props.token));
        }
    }

    const listHeading = (image, title) => (
        <Fragment>
            <img style={{height: "3rem", marginRight: "1rem"}} src={image} alt=""/>
            {title}
        </Fragment>
    );

    const renderReadyCampaigns = () => (
        <Fragment>
            { props.readyCampaigns.map(product => (
                <CampaignWidgetList
                    data={product.Campaigns}
                    handleRedirect={handleCampaignDetailsRedirect}
                    handleLaunch={handleLaunchCampaign}
                    listHeading={listHeading(product.image, product.title)}
                    handleQuickActions={quickActions}
                    card={true}
                />
            ))}
        </Fragment>
    )

    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <h4 className="mbg-3">Ready to Launch Campaigns</h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="11">
                        {renderReadyCampaigns()}
                        {props.readyCampaigns.length === 0 &&
                            <h5 className="text-center">Campaigns that have been built and are ready to be launched will appear here.</h5>
                        }
                    </Col>
                </Row>
            </Container>
            <ReadyCampaignDetailsModal isOpen={isOpen} setIsOpen={setIsOpen} handleLaunch={handleLaunchCampaign} />
            <CampaignKeywordModal
                isOpen={keywordIsOpen}
                toggle={() => setKeywordIsOpen(!keywordIsOpen)}
                keywords={props.keywords}
            />
            <CampaignTargetedProductModal
                isOpen={productIsOpen}
                toggle={() => setProductIsOpen(!productIsOpen)}
            />
        </Fragment>
    );
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    readyCampaigns: state.accountOverview.landing.readyCampaigns,
    keywords: state.accountOverview.campaign.keywords,
})

export default connect(mapStateToProps)(ReadyToLaunchPage);
