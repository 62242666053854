const creditPackages = {
    // demo: {
    //     bundleType: 'demo',
    //     numCredits: 1000,
    //     subtitle: 'Buy enough credits to demo.',
    //     description: 'This package will get you started with a weeks worth of credits to start up your very first advertising campaign!',
    //     price: 1,
    // },
    starter: {
        bundleType: 'Starter',
        numCredits: 30,
        subtitle: 'Perfect for managing your first product',
        description: 'This package will get you started with a months worth of credits for a single product.',
        price: 180,
    },   
    boost: {
        bundleType: 'Boost',
        numCredits: 150,
        subtitle: 'Great value for managing a small store',
        description: 'This package provides enough credits to boost sales for 5 products for a month.',
        price: 800,
    },
    pro: {
        bundleType: 'Pro',
        numCredits: 300,
        subtitle: 'Our most popular package',
        description: 'Enough credits to promote sales for 10 products for a month.',
        price: 1500,
    },
    premier: {
        bundleType: 'Premier',
        numCredits: 750,
        description: 'Enough credits to promote sales for 25 products for a month.',
        price: 3600,
    },
    elite: {
        bundleType: 'Elite',
        numCredits: 1500,
        description: 'Enough credits to promote sales for 50 products for a month.',
        price: 6900,
    },
};

export const odinPackage = {
    bundleType: 'Odin',
    numCredits: 1000,
    subtitle: 'Odin Package for Test Purposes',
    description: 'A one of a kind credit package offered exclusively to our elite Odin users!',
    price: 1,
}

export default creditPackages;