import axios from 'axios';
import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";

function RefreshSalesDataService(token, storeIntegerID, daysBack) {
    return axios.post(`${CURRENT_API_URL}/campaign/manualGetQueryReports`, {
        integerID: storeIntegerID,
        daysBack: daysBack,
    }, {
        headers: {
            'Authorization': token,
        }
    })
        .then(() => {
            notify.NotifySuccess("Sales data refreshed successfully!");
            return true;
        })
        .catch(() => {
            notify.NotifyError("There was a problem refreshing sales data, please try again later.")
            return false;
        })
}

export default RefreshSalesDataService;