
//import React from 'react';
import React, { Fragment, useState } from 'react';
import ReactTable from "react-table";
import { Container, Row, Col, Card, CardBody, Button, Label, Input } from 'reactstrap';
import LeadGenProdCatJobDataToolService from "../../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatJobDataToolService";
import LeadGenProdCatRerunJobService from "../../../../../../../Service/DashboardServices/UserProfileServices/LeadGenProdCatRerunJobService";
import CustomModal from '../../../../../../Components/Modals/CustomModal';
import { connect } from 'react-redux';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import { useState } from 'react';


function LeadGenJobTables(props) {

        const [loading, setLoading] = useState(false);
        const [isOpen, setIsOpen] = useState(false);
        const [summary, setSummary] = useState();
        const [researchProductCategoryData, setresearchProductCategoryData] = useState(''); 
        const [isSummaryOpen, setisSummaryOpen] = useState(false);
        const handleSubmit = (id) => {
            props.dispatch(LeadGenProdCatJobDataToolService(props.token, id));            
        
        }
        
        const handleRerunJob = (id) => {
            props.dispatch(LeadGenProdCatRerunJobService(props.token, id));            
        
        }

        const getTrProps = (state, rowInfo, instance) => {
            if (rowInfo) {
              return {
                style: {
                  color: 
                  rowInfo.row.status == "Complete"? ' green' :  
                  rowInfo.row.status == "In Progress" ? 'orange' : 'red',
                }
              }
            }
            console.log(rowInfo);
            return {};
        }

        const ExportCSV = (csvData, fileName) => {

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xlsx';
            console.log('Download Called')
            const exportToCSV = (csvData, fileName) => {
                console.log(csvData)
                console.log(fileName)
                const ws = XLSX.utils.json_to_sheet(csvData.productList);
                const ws1 = XLSX.utils.json_to_sheet(csvData.sellerList);
                const ws2 = XLSX.utils.json_to_sheet(csvData.productSalesEstimatesList);
                const ws3 = XLSX.utils.json_to_sheet(csvData.top100productAnalysisList);
                const ws4 = XLSX.utils.json_to_sheet(csvData.jobSummaryData);                
                const ws5 = XLSX.utils.json_to_sheet(csvData.jobHistoryData); 
                
                const wb = { Sheets: {'jobSummaryData':ws4, 'productList': ws,'sellerList': ws1,'productSalesEstimatesList':ws2,'top100productAnalysisList': ws3,'jobHistoryData':ws5 }, SheetNames: ['jobSummaryData','productList','sellerList','productSalesEstimatesList','top100productAnalysisList','jobHistoryData'] };                
                
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {type: fileType});
                console.log('before save')
                FileSaver.saveAs(data, 'MarketResearchData' + fileExtension);   
                               
                };           
                exportToCSV(csvData, fileName); 
            }              

        
    const columns = [
        {
            Header: "id",
            accessor: "id",
            Cell: cellInfo => <Button className="mt-1" color="link" onClick={() => handleSubmit(cellInfo.value)}>{cellInfo.value}</Button>
            
        },
        {
            Header: "Rerun",
            accessor: "id",
            Cell: cellInfo => <Button className="mt-1" color="link" onClick={() => handleRerunJob(cellInfo.value)}>Rerun</Button>
        },
        {
            Header: "Job Name",
            accessor: "jobName",
        },      
       
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Summary",
            accessor: "summary",
            Cell: cellInfo => (               
                    <Button
                        color={"link"}
                        onClick={() => {
                            setSummary(cellInfo.value)    
                            setIsOpen(!isOpen)                       
                        }}
                    >
                        View 
                    </Button>
                
            )
        },
        {
            Header: "Estimated Pages",
            accessor: "estimated_Pages",
        },
        {
            Header: "Estimated Products",
            accessor: "estimated_Items",
        },
        {
            Header: "Actual  Pages",
            accessor: "actual_Pages",
        },
        {
            Header: "Actual Products",
            accessor: "actual_Items",
        },
      
        {
            Header: "Creation",
            accessor: "creation_date",
        },     
        {
            Header: "Completion",
            accessor: "completion_time",
        },   
       
        
    ];


    return (
        <Fragment>
        <ReactTable
            columns={columns}
            data={props.data}
            getTrProps={getTrProps}           
            style={{
                height: "800px" // This will force the table body to overflow and scroll, since there is not enough room
            }}
            className="-striped -highlight -fixed"
            noDataText="No Data found."
        />
       
       <CustomModal
                header={"Market Research Product Category Job Summary"}
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
                footer={(
                    <>
                       <Button
                            color={"link"}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            Cancel
                        </Button>
                       
                    </>
                )}
            >
                <Label> <p> <pre style={{ whiteSpace: "pre-wrap" }}>{summary}</pre> </p></Label>                
            </CustomModal>
        </Fragment>       
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,
    researchProductCategoryData : state.profile.researchProductCategoryData,   

})

export default connect(mapStateToProps)(LeadGenJobTables)
