import axios from 'axios';
import { CURRENT_API_URL } from "../../../Utils/Constants";
import notify from '../../../Notifications/ToastifyActions';


function UpdateSKUForAsinStoreService(token,asin,profileId) {
    notify.NotifySuccess("Skus are getting updated for selected asin store combination.Please wait till success or error message.");
    return axios.post(`${CURRENT_API_URL}/sellerPartner/UpdateSKUForAsinStore`, {
        asin: asin,
        profileId: profileId,      
    }, {
        headers: {
            'Authorization': token,
        },
    }).then(() => {
        notify.NotifySuccess("Skus have been updated for selected asin.");
        return true;
    }).catch(() => {
        notify.NotifyError("There was a problem while updating skus for a selected asin, please try again.");
        return false;
    });
}

export default UpdateSKUForAsinStoreService;