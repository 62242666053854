import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import CustomModal from "../../../../Components/Modals/CustomModal";
import {Button, Col, Input, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import AddProductImg from "../../../../../designUtils/img/Add Product Image.png";
import ParentChildImg from "../../../../../designUtils/img/Parent-Child Image.png";
import OneClickImg from "../../../../../designUtils/img/1 click campaign image.png";
import ProductPerformanceImg from "../../../../../designUtils/img/Product perf image.png";
import LoadingButton from "../../../../Components/Buttons/LoadingButton";
import LWAButton from "../../Buttons/LWAButton";


function TutorialModal(props) {

    const {
        isAuth,
        needsAdvertisingAPIAuth,
        handleAuth,
        handleEmailSubmit,
        handleConfirmation,
    } = props;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const [isEmailFieldVisible, setIsEmailFieldVisible] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (isAuth && needsAdvertisingAPIAuth) {
            handleAuth();
        }
    }, [isAuth, handleAuth, needsAdvertisingAPIAuth])

    const onEmailSubmit = async () => {
        setLoading(true);
        await handleEmailSubmit(email);
        setLoading(false);
    }

    const step0 = () => (
        <Row>
            <Col className="text-center" md="12">
                <h1 className="mt-4 mb-5 text-dark">Welcome!</h1>
                <h5 className="mb-3 text-dark">Let's get started</h5>
            </Col>
        </Row>
    )

    const step1 = () => (
        <Row>
            <Col className="text-center" md="12" style={{display: "flex", flexDirection: "column"}}>
                <h5 className="mb-3 pt-1 text-brand-blue-darker">Add your products by ASIN</h5>
                <img style={{alignSelf: "center"}} src={AddProductImg} />
            </Col>
        </Row>
    );

    const step1_2 = () => (
        <Row>
            <Col className="text-center" md="12" style={{display: "flex", flexDirection: "column"}}>
                <h5 className={"mb-2 text-brand-blue-darker"}>View product families grouped by variation</h5>
                <img style={{alignSelf: "center"}} src={ParentChildImg} />
            </Col>
        </Row>
    )

    const step2 = () => (
        <>
            <Row className="mb-4">
                <Col className="text-center" md="12">
                    <h5 className="text-brand-blue-darker">Easily generate campaigns with the 1-Click Campaign builder</h5>
                </Col>
            </Row>
            <Row>
                <Col md="5">
                    <img src={OneClickImg} />
                </Col>
                <Col className="pt-3" md="7">
                    <ul className="tutorialCard pl-3 mr-5">
                        <li className="tutorialCard">
                            <p style={{fontSize: "medium"}}>Confirm your SKU and budget to launch live campaigns with 1 click</p>
                        </li>

                        <li className="tutorialCard">
                            <p style={{fontSize: "medium"}}>1-Click Campaigns are automatically managed by Simpliworks and optimized to maximize return on ad spend (ROAS)</p>
                        </li>
                        <li className="tutorialCard">
                            <p style={{fontSize: "medium"}}>Campaign management can be paused and resumed at any time</p>
                        </li>
                    </ul>
                </Col>
            </Row>
        </>
    );

    const step3 = () => (
        <Row>
            <Col md={"12"} className="text-center mb-2">
                <h5 className="mb-2 text-brand-blue-darker">View product-level performance and rank data at a glance</h5>
            </Col>
            <Col md="12" style={{display: "flex", flexDirection: "column"}}>
                <img style={{alignSelf: "center"}} src={ProductPerformanceImg} />
            </Col>
        </Row>
    );

    const step4 = () => (
        <Row>
            <Col md={"12"} className={"text-center mb-4"}>
                <h2 className="m-4 text-brand-blue-darker">You're almost there!</h2>
                <h5 className={"m-4"}>
                    Login with Amazon Seller Central Account so we can do the heavy lifting and you can worry about the more important things
                </h5>
                <LWAButton />
            </Col>
        </Row>
    )

    const step5 = () => (
        <Row>
            <Col md={"12"} className={"text-center mb-4"}>
                <h2 className="mb-4 text-brand-blue-darker">You're all set!</h2>
                <h5 className={"mb-4"}>Select one of our free or paid plans to launch your first campaign!</h5>
                <Button color={"success"} onClick={() => handleConfirmation()}>Compare Plans</Button>
            </Col>
            {/*<Col md={"12"} className={"text-center mb-2"}>*/}
            {/*    <Button*/}
            {/*        color={"link"}*/}
            {/*        onClick={() => setIsEmailFieldVisible(!isEmailFieldVisible)}*/}
            {/*    >*/}
            {/*        Already have a subscription?*/}
            {/*    </Button>*/}
            {/*</Col>*/}
            {/*{*/}
            {/*    isEmailFieldVisible &&*/}
            {/*    <Col md={{size: 8, offset: 2}} className="mt-3">*/}
            {/*        <Label for={"email"}>Enter email used for subscription</Label>*/}
            {/*        <InputGroup>*/}
            {/*            <Input*/}
            {/*                id={"email"}*/}
            {/*                value={email}*/}
            {/*                onChange={(e) => setEmail(e.target.value)}*/}
            {/*                placeholder={"Email"}*/}
            {/*                type={"email"}*/}
            {/*            />*/}
            {/*            <InputGroupAddon addonType={"append"}>*/}
            {/*                <LoadingButton*/}
            {/*                    color={"brand-blue-dark"}*/}
            {/*                    loading={loading}*/}
            {/*                    onClick={() => onEmailSubmit()}*/}
            {/*                >*/}
            {/*                    Submit*/}
            {/*                </LoadingButton>*/}
            {/*            </InputGroupAddon>*/}

            {/*        </InputGroup>*/}
            {/*    </Col>*/}
            {/*}*/}
        </Row>
    );

    const finalStep = () => {
        if (needsAdvertisingAPIAuth) {
            return step4();
        } else {
            return step5();
        }
    }

    const slides = [step0(), step1(), step1_2(), step2(), step3(), finalStep()];

    return (
        <CustomModal
            isOpen={false}
            toggle={props.toggle}
            size={"lg"}
        >
            <Slider {...settings}>
                {
                    slides.map(slide => (
                        // <div>
                        //     <div className="slider-item">{slide}</div>
                        // </div>
                        <div>{slide}</div>
                    ))
                }
            </Slider>
        </CustomModal>
    )
}

export default TutorialModal;