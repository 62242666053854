import React from 'react';
import ReactTable from "react-table";


function AdvertisingDataStatusTable(props) {
    const columns = [
        {
            Header: "Store",
            columns: [
                {
                    Header: "Name",
                    accessor: "storeName",
                },
                {
                    Header: "ID",
                    accessor: "scopeId",
                },
            ],
        },
        {
            Header: "Advertising Data Report",
            columns: [
                {
                    Header: "Report Date",
                    accessor: "reportDate",
                    Cell: cellInfo => `${new Date(cellInfo.value).toLocaleDateString('en-US',
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        }
                    )}`,
                },
                {
                    Header: "Report Collected?",
                    accessor: "reportRetrieved",
                    Cell: cellInfo => cellInfo.value ? "Yes" : "No",
                },
                {
                    Header: "Date Requested",
                    accessor: "requestedDate",
                    Cell: cellInfo => `${new Date(cellInfo.value).toLocaleDateString('en-US',
                        {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        }
                    )}`,
                },
                {
                    Header: "Days Reported",
                    accessor: "daysBack",
                    Cell: cellInfo => `${cellInfo.value} day${cellInfo.value !== 1 ? "s" : ""}`
                },
            ],
        },
    ];


    return (
        <ReactTable
            columns={columns}
            data={props.data}
            className="-striped -highlight -fixed"
            noDataText={"No data found."}
            style={{height: "500px"}}
        />
    )
}

export default AdvertisingDataStatusTable;