import axios from 'axios';
import { LogoutUser } from '../../Redux/Actions/Actions'
import actionType from "../../Redux/Actions/ActionTypes";
import { CURRENT_API_URL } from "../../Utils/Constants";


function LogoutService(token) {
    // localStorage.removeItem('currentComponent');
    return function(dispatch) {

        dispatch({
            type: actionType.LOGOUT_BEGIN,
        });

        axios.post(`${CURRENT_API_URL}/account/logout`, {}, {
            headers: {
                'Authorization': token,
            }
        }).then(res => {
            if (res.status === 204) {
                dispatch(LogoutUser());
            }
        })
        .catch(error => {
            if (!error.response && error.request) {
                return dispatch({
                    type: actionType.LOGOUT_FAILED_NO_CONNECTION,
                });
            } else {
                return dispatch({
                    type: actionType.LOGOUT_FAILED_UNKNOWN_ERROR,
                });
            }
            }
        );
    }
}

export default LogoutService;
