import axios from 'axios';

import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from '../../../../Notifications/ToastifyActions';


function DeleteReadyCampaignService(token, name, collectionID) {
    return axios.post(`${CURRENT_API_URL}/campaign/deleteLaunchpadCampaign`, {
        collectionID: collectionID,
        name: name,
    }, {
        headers: {
            'Authorization': token,
        },
    })
        .then(res => {
            notify.NotifySuccess("Your campaign was successfully deleted.");
            return true;
        })
        .catch(() => {
            notify.NotifyError("There was a problem deleting your campaign. Please try again later.");
            return false;
        })
}

export default DeleteReadyCampaignService;