import React, {Fragment, useState, useCallback, useEffect} from "react";
import {Row, Col, ListGroup, ListGroupItem, Button} from "reactstrap";
import { useDropzone } from "react-dropzone";
import LoadingButton from "./Buttons/LoadingButton";


function FileDropzone(props) {

    const { singleFile } = props;
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        if (singleFile) {
            setFiles([...acceptedFiles])
        } else {
            setFiles([...files, ...acceptedFiles])
        }
    }, [singleFile, files, setFiles]);


    const removeFile = (idx) => {
        let newFiles = [...files];
        newFiles.splice(idx, 1);
        setFiles(newFiles);
    }


    const {
        rejectedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        ...props.options,
    });

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <div className="dropzone-wrapper dropzone-wrapper-sm">
                        <div {...getRootProps({className: "dropzone"})}>
                            <input {...getInputProps()} />
                            <div className="dropzone-content">
                                <p>
                                    Try dropping some files here, or click to select files
                                    to upload.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
                { files.length !== 0 &&
                    <Col className="mt-3" md="12">
                        <b className="mb-2 d-block">Accepted Files</b>
                        <ListGroup>
                            {files.map((file, idx) => (
                                <ListGroupItem key={file.name}>
                                    <span>{file.name} - {file.size} bytes</span>
                                    <Button className="ml-3" outline color="danger" onClick={() => removeFile(idx)}>Remove</Button>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>
                }
                { rejectedFiles.length !== 0 &&
                    <Col className="mt-3" md="12">
                        <b className="mb-2 d-block">Rejected Files</b>
                        <ListGroup>
                            {rejectedFiles.map(file => (
                                <ListGroupItem key={file.name}>
                                    <span>{file.name} - {file.size} bytes</span>
                                    {/*<Button className="ml-3" outline color="danger" onClick={() => rejectedFiles.splice(idx, 1)}>Remove</Button>*/}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>
                }
            </Row>
            <Row className="mt-3">
                <Col md={{size: 3}}>
                    {/*<Button color="success" disabled={files.length === 0} onClick={() => props.onSubmit(files)}>Upload</Button>*/}
                    <LoadingButton color="success" loading={props.loading} disabled={files.length === 0} onClick={() => props.onSubmit(files)}>Upload</LoadingButton>
                </Col>
            </Row>
        </Fragment>
    )
}

export default FileDropzone;