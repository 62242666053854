import React, {useState} from 'react';
import {Button, Card, CardBody, CardTitle, Row, Col} from "reactstrap";
import { DropdownList } from "react-widgets";
import UpdateProductStageService from '../../../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/ProductStageServices';
import { connect } from 'react-redux';


function ProductStageCard(props) {

    const {       
        asin,
        productStage,
    } = props;


    const [newStage, setNewStage] = useState(productStage);
    const [selectedAsin, setSelectedAsin] = useState(asin);
   
    const handleSubmit = () => {
       
        if (asin, newStage){
           UpdateProductStageService(props.token, selectedAsin, newStage);
          
        }       
    }
    
    let stages = [
        {
            id: "swarm",
            value: "Swarm",
        },
        {
            id: "defense",
            value: "Defense",
        },
        {
            id: "explore",
            value: "Explore",
        },
        {
            id: "winning",
            value: "Winning",
        },
    ];



    return (
        <Card>
            <CardBody>
                <CardTitle>Product Stage</CardTitle>
               <Row>    
               <Col className="mb-3" md="12">
                        <DropdownList
                            data={stages}
                            dataKey='id'
                            value={newStage}
                            textField="value"
                            onChange={value => setNewStage(value.value)}
                            placeholder="Select Stage"
                        />
                    </Col>
                </Row>    
                <Row>
                <Button
                    className="ml-4"
                    style={{display: "inline-block"}}
                    color={"brand-blue-dark"}
                    onClick={() =>handleSubmit()}                    
                >
                    Update
                </Button>
                </Row>

            </CardBody>
        </Card>
    )
}

const mapStateToProps = state => ({
    token: state.loggedUser.token,      

})

export default connect(mapStateToProps)(ProductStageCard)
