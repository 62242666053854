import React, { Fragment, useState } from 'react';
import { Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import CampaignTargetedProductModal from "../../../../Components/Modals/CampaignTargetedProductModal";


function CampaignTargetedProductCard(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Fragment>
            <Card>
                <CardHeader>Targeted Products</CardHeader>
                <CardBody>
                    <ListGroup>
                        {props.targetedProducts.slice(0, 3).map((item, i) => (
                            <ListGroupItem key={i}>{item.asin}</ListGroupItem>
                        ))}
                        <ListGroupItem>...</ListGroupItem>
                    </ListGroup>
                    <Button
                        color="link"
                        onClick={() => toggle()}
                    >
                        Manage Targeted Products {'>'}
                    </Button>
                </CardBody>
            </Card>
            <CampaignTargetedProductModal isOpen={isOpen} toggle={toggle} />
        </Fragment>
    );
}

export default CampaignTargetedProductCard;