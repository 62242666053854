import React, {Fragment} from 'react';
import {
    Card, CardBody, Row, Col, Container,
} from 'reactstrap';
import { connect } from 'react-redux';

import CreateCampaignForm from "./Components/CreateCampaignForm";
import ProductInfoStep from "./Components/FormWizardSteps/ProductInfoStep";
import AddToCartStep from "./Components/FormWizardSteps/AddToCartStep";
import SelectCampaignStep from "./Components/FormWizardSteps/SelectCampaignStep";


const steps = [
    {
        name: 'Select a Campaign',
        id: 'selectCampaign',
        fields: ['productASIN', 'advertisingObjective'],
        component: <SelectCampaignStep/>
    },
    {
        name: 'Campaign Details',
        id: 'productInfo',
        fields: ['sellerProfileId', 'dailyBudget', 'bidPriceExact', 'bidPricePhrase', 'productSKU', 'marketplace', 'productTargeting'],
        component: <ProductInfoStep/>
    },
    {
        name: 'Build Campaign',
        id: 'addToCart',
        fields: [],
        component: <AddToCartStep/>
    }
];

function NewCampaignPage(props) {

    return (
        <Fragment>
                <div>
                    <Container fluid>
                        <Row>
                            <Col md="12" className="mbg-3">
                                <h4 className="mb-2">New Campaign</h4>
                                { props.adProfiles.length === 0 &&
                                    <p className="invalid-feedback" style={{display: 'block', fontSize: '100%'}}>
                                        We could not find any profile IDs associated with your Amazon Seller account,
                                        please create a profile in Amazon Seller Central.
                                    </p>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3" style={{maxWidth: "1000px"}}>
                                    <CardBody>
                                        <div className="forms-wizard-horizontal">
                                            <CreateCampaignForm showNavigation={true} steps={steps}/>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        sellerProfileIdList: state.loggedUser.sellerProfileIdList,
        adProfiles: state.accountOverview.landing.adProfiles,
    }
}

export default connect(mapStateToProps)(NewCampaignPage);
