import axios from 'axios';

import { CURRENT_API_URL } from "../../../../Utils/Constants";
import notify from "../../../../Notifications/ToastifyActions";
import actionType from '../../../../Redux/Actions/ActionTypes';
import { LoadCampaignData } from "../../../../Redux/Actions/Actions";


function CampaignOverviewService(token, name, campaignID) {
    return function(dispatch) {
        dispatch({ type: actionType.LOAD_CAMPAIGN_DATA_BEGIN });

        axios.post(`${CURRENT_API_URL}/campaign/info`, {
            name: name,
            campaignID: campaignID
        }, {
            headers: {
                'Authorization': token,
            }
        })
            .then(res => {
                console.log(res.data)
                dispatch(LoadCampaignData(
                    res.data.campaignOverview,
                    res.data.campaignType,
                    res.data.keywordInfo,
                    res.data.productInfo,
                    {
                        clicks: res.data.clickData,
                        conversions: res.data.conversionData,
                        impressions: res.data.impressionData,
                        sales: res.data.salesData,
                        spend: res.data.spendData,
                        unitsSold: res.data.unitsSoldData,
                    },
                ));
        })
            .catch(() => {
                dispatch({ type: actionType.LOAD_CAMPAIGN_DATA_FAILED });
                notify.NotifyError('We\'ve encountered an error while retrieving your campaign details, please try again later.')
        });
    }
}

export default CampaignOverviewService;
